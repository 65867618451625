var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseWindowPage", {
    attrs: { trackCancel: _vm.track + "::Close" },
    on: {
      evtCancel: function($event) {
        _vm.cancelDialogShown = true
      }
    },
    scopedSlots: _vm._u(
      [
        _vm.readyFlag
          ? {
              key: "title",
              fn: function() {
                return [
                  _vm._v(_vm._s(_vm.$t("biz.sensors.setup.found.foundtitle")))
                ]
              },
              proxy: true
            }
          : {
              key: "title",
              fn: function() {
                return [_vm._v(_vm._s(_vm.$t("biz.sensors.setup.found.title")))]
              },
              proxy: true
            },
        _vm.readyFlag
          ? {
              key: "subtitle",
              fn: function() {
                return [
                  _c("div", {
                    staticClass: "font-weight-black",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("biz.sensors.setup.found.subtitle")
                      )
                    }
                  }),
                  _c("v-flex", {
                    staticClass: "pt-2",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("biz.sensors.setup.found.description")
                      )
                    }
                  })
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "content",
          fn: function() {
            return [
              _c(
                "v-container",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.readyFlag,
                      expression: "!readyFlag"
                    }
                  ],
                  staticStyle: { "min-height": "300px" },
                  attrs: { "fill-height": "", fluid: "", "justify-center": "" }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { color: "secondary", indeterminate: "", size: "36" }
                  }),
                  _c(
                    "span",
                    { staticClass: "ml-3", staticStyle: { color: "primary" } },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("biz.sensors.setup.found.searchsensor"))
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.readyFlag,
                      expression: "readyFlag"
                    }
                  ],
                  staticClass: "mt-1",
                  staticStyle: { "max-height": "300px" }
                },
                [
                  _c(
                    "v-list",
                    [
                      _vm._l(_vm.foundSensors, function(sensor) {
                        return [
                          _c(
                            "v-list-tile",
                            {
                              key: sensor.sensorId,
                              class: _vm.highlightSelected(sensor),
                              attrs: { color: "neutral", flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.selectSensor(sensor)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "pa-3",
                                          attrs: {
                                            xs3: "",
                                            "align-center": "",
                                            "justify-center": ""
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              _vm._s(_vm.getSensorIcon(sensor))
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs8: "",
                                            column: "",
                                            "ma-2": "",
                                            "align-start": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticStyle: {
                                                "font-size": "15px",
                                                "font-weight": "bold"
                                              },
                                              attrs: { "align-start": "" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.getSensorId(sensor))
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                                color: "bus_grey"
                                              },
                                              attrs: { "align-start": "" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "biz.sensors.setup.found.sensortype"
                                                  )
                                                ) +
                                                  ": " +
                                                  _vm._s(
                                                    _vm.getSensorType(sensor)
                                                  )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c("SensorAssigned", {
                attrs: {
                  dialogShown: _vm.assignedShown,
                  sensorId: _vm.initialSensorId,
                  gatewayId: _vm.gateway.gatewayId
                }
              }),
              _c("SensorError", {
                attrs: {
                  sensorId: _vm.errorSensorId,
                  gatewaySerialNumber: _vm.gateway.serialNumber,
                  dialogShown: _vm.errorShown,
                  errorType: _vm.errorType
                },
                on: {
                  evtAbort: function($event) {
                    return _vm.onAbort()
                  }
                }
              }),
              _c("OkCancelDialog", {
                attrs: {
                  dialogShown: _vm.cancelDialogShown,
                  title: _vm.$t("biz.setup.abort.title"),
                  message: _vm.$t("biz.setup.abort.description"),
                  track: _vm.track + "::AbortSetup"
                },
                on: {
                  evtOkPressed: function($event) {
                    return _vm.onAbort()
                  },
                  evtCancelPressed: function($event) {
                    _vm.cancelDialogShown = false
                  }
                }
              })
            ]
          },
          proxy: true
        },
        _vm.readyFlag
          ? {
              key: "helplink",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: _vm.track + "::SensorNotFound",
                          expression: "track + '::SensorNotFound'"
                        }
                      ],
                      staticStyle: { "margin-left": "25px" },
                      attrs: { depressed: "", color: "secondary", outline: "" },
                      on: {
                        click: function($event) {
                          return _vm.showSensorNotFoundDialog()
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "pr-1", attrs: { color: "secondary" } },
                        [_vm._v("help_outline")]
                      ),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("biz.sensors.setup.found.findhelp")) +
                          "\n    "
                      )
                    ],
                    1
                  ),
                  _c("SensorNotFoundDialog", {
                    attrs: { dialogShown: _vm.wrongSensor },
                    on: {
                      evtClose: function($event) {
                        _vm.wrongSensor = false
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }