// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MeasureSiteSiteInformation } from './';

/**
 * @export
 * @interface SaveApplianceRequest
 */
export interface SaveApplianceRequest {
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  additionalInformation?: string;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  addressLine1?: string;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  addressLine2?: string;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  alias?: string;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  articleNumber: string;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  city?: string;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  compartmentType?: SaveApplianceRequestCompartmentTypeEnum;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  inventoryNumber?: string;
  /**
   * @type {number}
   * @memberof SaveApplianceRequest
   */
  latitude?: number;
  /**
   * @type {number}
   * @memberof SaveApplianceRequest
   */
  longitude?: number;
  /**
   * @type {number}
   * @memberof SaveApplianceRequest
   */
  modbusAddress: number;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  modelDescription: string;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  name?: string;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  phone?: string;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  serialNumber: string;
  /**
   * @type {MeasureSiteSiteInformation}
   * @memberof SaveApplianceRequest
   */
  siteInformation?: MeasureSiteSiteInformation;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  stateRegion?: string;
  /**
   * @type {string}
   * @memberof SaveApplianceRequest
   */
  zipCode?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SaveApplianceRequestCompartmentTypeEnum {
  COOLER = 'COOLER',
  FREEZER = 'FREEZER'
}
