// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MeasureSiteAddress, ZoneInfo } from './';

/**
 * @export
 * @interface UpdateMeasureSiteRequest
 */
export interface UpdateMeasureSiteRequest {
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  additionalInformation?: string;
  /**
   * @type {MeasureSiteAddress}
   * @memberof UpdateMeasureSiteRequest
   */
  address?: MeasureSiteAddress;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  addressLine1?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  addressLine2?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  building?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  city?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  comment?: string;
  /**
   * @type {ZoneInfo}
   * @memberof UpdateMeasureSiteRequest
   */
  coolerZone?: ZoneInfo;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  countryIsoCode?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  department?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  externalId?: string;
  /**
   * @type {ZoneInfo}
   * @memberof UpdateMeasureSiteRequest
   */
  freezerZone?: ZoneInfo;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  inventoryNumber?: string;
  /**
   * @type {number}
   * @memberof UpdateMeasureSiteRequest
   */
  latitude?: number;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  level?: string;
  /**
   * @type {number}
   * @memberof UpdateMeasureSiteRequest
   */
  longitude?: number;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  manufacturer?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  mappingType?: string;
  /**
   * @type {boolean}
   * @memberof UpdateMeasureSiteRequest
   */
  measureSiteAddress?: boolean;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  model?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  name?: string;
  /**
   * @type {Date}
   * @memberof UpdateMeasureSiteRequest
   */
  orderDate?: Date;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  phone?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  room?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  serialNumber?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  stateRegion?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  virtualFridgeId?: string;
  /**
   * @type {string}
   * @memberof UpdateMeasureSiteRequest
   */
  zipCode?: string;
}
