// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserProfile } from './';

/**
 * @export
 * @interface SensorAlarmProfileRequest
 */
export interface SensorAlarmProfileRequest {
  /**
   * @type {boolean}
   * @memberof SensorAlarmProfileRequest
   */
  batteryAlarmActive?: boolean;
  /**
   * @type {boolean}
   * @memberof SensorAlarmProfileRequest
   */
  doorAlarmActive?: boolean;
  /**
   * @type {number}
   * @memberof SensorAlarmProfileRequest
   */
  doorOpenedAlarmDelayInSeconds?: number;
  /**
   * @type {string}
   * @memberof SensorAlarmProfileRequest
   */
  gatewayId?: string;
  /**
   * @type {number}
   * @memberof SensorAlarmProfileRequest
   */
  maxTemperature?: number;
  /**
   * @type {number}
   * @memberof SensorAlarmProfileRequest
   */
  minBatteryLevel?: number;
  /**
   * @type {number}
   * @memberof SensorAlarmProfileRequest
   */
  minTemperature?: number;
  /**
   * @type {boolean}
   * @memberof SensorAlarmProfileRequest
   */
  reminderActive?: boolean;
  /**
   * @type {number}
   * @memberof SensorAlarmProfileRequest
   */
  reminderPeriodInSeconds?: number;
  /**
   * @type {string}
   * @memberof SensorAlarmProfileRequest
   */
  sensorId?: string;
  /**
   * @type {boolean}
   * @memberof SensorAlarmProfileRequest
   */
  temperatureAlarmActive?: boolean;
  /**
   * @type {string}
   * @memberof SensorAlarmProfileRequest
   */
  thingId?: string;
  /**
   * @type {boolean}
   * @memberof SensorAlarmProfileRequest
   */
  unavailabilityAlarmActive?: boolean;
  /**
   * @type {Array<UserProfile>}
   * @memberof SensorAlarmProfileRequest
   */
  userNotifications?: Array<UserProfile>;
}
