var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "500px" },
      model: {
        value: _vm.editorShown,
        callback: function($$v) {
          _vm.editorShown = $$v
        },
        expression: "editorShown"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-window",
            {
              attrs: { touchless: "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-window-item",
                { attrs: { value: _vm.startStep } },
                [
                  _c("BaseWindowItem", {
                    attrs: { trackCancel: "AutomatedReportSettings::Close" },
                    on: {
                      evtCancel: function($event) {
                        return _vm.close()
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v(_vm._s(_vm.$t("biz.report.reports")))]
                        },
                        proxy: true
                      },
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "biz.report.reportsettings.description"
                                  )
                                )
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  "font-weight": "bold"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("biz.settings.selectappliances")
                                  )
                                )
                              ]
                            ),
                            _c("v-autocomplete", {
                              staticClass: "defaulttextarea selectedtextarea",
                              attrs: {
                                color: "secondary",
                                multiple: "",
                                chips: "",
                                "menu-props": {
                                  closeOnContentClick: true
                                },
                                items: _vm.applianceZonesForReports,
                                "item-text": "name",
                                "item-value": "virtualFridgeId",
                                placeholder: _vm.$t(
                                  "biz.settings.selectappliances"
                                )
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var item = ref.item
                                    var index = ref.index
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            selected: item.selected,
                                            "text-color": "white"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.onDeSelectingAppliance(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getIcon(
                                                    item.applianceType
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(item.name))
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.editableData.virtualFridgeIds,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editableData,
                                    "virtualFridgeIds",
                                    $$v
                                  )
                                },
                                expression: "editableData.virtualFridgeIds"
                              }
                            }),
                            _vm.editableData.virtualFridgeIds &&
                            _vm.editableData.virtualFridgeIds.length > 0
                              ? _c(
                                  "v-flex",
                                  { attrs: { "mt-3": "" } },
                                  [
                                    _c("p", { staticClass: "bus_normaltext" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "biz.report.reportsettings.deleteautoreportsettings"
                                          )
                                        )
                                      )
                                    ]),
                                    _c(
                                      "v-btn",
                                      {
                                        directives: [
                                          {
                                            name: "track",
                                            rawName: "v-track",
                                            value:
                                              "AutomatedReportSettings::DeleteAutoReportSettings",
                                            expression:
                                              "'AutomatedReportSettings::DeleteAutoReportSettings'"
                                          }
                                        ],
                                        staticStyle: { "margin-left": "0px" },
                                        attrs: {
                                          depressed: "",
                                          color: "secondary",
                                          outline: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.deleteAutoReportShown = true
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "biz.report.reportsettings.deletereport"
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { attrs: { value: _vm.startStep + 1 } },
                [
                  _c("BaseWindowItem", {
                    attrs: { trackCancel: "AutomatedReportSettings::Close" },
                    on: {
                      evtCancel: function($event) {
                        return _vm.close()
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v(_vm._s(_vm.$t("biz.report.reports")))]
                        },
                        proxy: true
                      },
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("biz.report.reportsettings.timezone")
                                )
                              )
                            ]),
                            _c("TimeZoneSelector", {
                              attrs: {
                                initialTimezoneString:
                                  _vm.editableData.timezone,
                                allowTimeZoneSelection: true
                              },
                              on: { evtTimeZoneChange: _vm.onTimezoneChange }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "biz.report.reportsettings.frequencyandtime"
                                  )
                                )
                              )
                            ]),
                            _c(
                              "v-radio-group",
                              {
                                staticClass: "mt-0",
                                attrs: { column: "", mandatory: true },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "biz.report.reportsettings.frequency"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.editableData.frequency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editableData, "frequency", $$v)
                                  },
                                  expression: "editableData.frequency"
                                }
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    label: _vm.$t("general.repetition.monthly"),
                                    value:
                                      _vm.ReportSettingsFrequencyEnum.MONTHLY,
                                    color: "secondary"
                                  }
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    label: _vm.$t("general.repetition.weekly"),
                                    value:
                                      _vm.ReportSettingsFrequencyEnum.WEEKLY,
                                    color: "secondary"
                                  }
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    label: _vm.$t("general.repetition.daily"),
                                    value:
                                      _vm.ReportSettingsFrequencyEnum.DAILY,
                                    color: "secondary"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-radio-group",
                              {
                                attrs: { mandatory: true },
                                on: {
                                  change: function($event) {
                                    return _vm.onPeriodSelectionChanged()
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-layout",
                                          { attrs: { column: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticStyle: {
                                                  "font-weight": "bold"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "biz.report.reportsettings.firstreport"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { staticClass: "bus_normaltext" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "biz.report.reportsettings.firstreportdesc"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.period,
                                  callback: function($$v) {
                                    _vm.period = $$v
                                  },
                                  expression: "period"
                                }
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    label: _vm.$t("general.today"),
                                    value: _vm.ReportSettingsPeriodEnum.TODAY,
                                    color: "secondary"
                                  }
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    label: _vm.$t(
                                      "biz.report.reportsettings.fromDate"
                                    ),
                                    color: "secondary",
                                    value:
                                      _vm.ReportSettingsPeriodEnum.TIME_PERIOD
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { staticClass: "ml-3", attrs: { xs8: "" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-content-click": false,
                                      "nudge-right": 40,
                                      transition: "scale-transition",
                                      "offset-y": "",
                                      "min-width": "auto"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "default selected",
                                                    attrs: {
                                                      placeholder: _vm.$t(
                                                        "biz.report.reportsettings.fromDate"
                                                      ),
                                                      "append-icon": _vm.icon,
                                                      readonly: "",
                                                      disabled: !_vm.isDateDisabled(),
                                                      clearable: _vm.iconHelper.clearableIcon(
                                                        _vm.startDate
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.formattedStartDate,
                                                      callback: function($$v) {
                                                        _vm.formattedStartDate = $$v
                                                      },
                                                      expression:
                                                        "formattedStartDate"
                                                    }
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.menu,
                                      callback: function($$v) {
                                        _vm.menu = $$v
                                      },
                                      expression: "menu"
                                    }
                                  },
                                  [
                                    _c("v-date-picker", {
                                      attrs: {
                                        "no-title": "",
                                        min: _vm.minDate,
                                        locale: _vm.$i18n.locale
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.onFilterDate()
                                        },
                                        input: function($event) {
                                          _vm.menu = false
                                        }
                                      },
                                      model: {
                                        value: _vm.startDate,
                                        callback: function($$v) {
                                          _vm.startDate = $$v
                                        },
                                        expression: "startDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-radio-group",
                              {
                                staticClass: "mt-0",
                                attrs: { mandatory: false, column: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "biz.report.selectreportlanguage"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.editableData.language,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editableData, "language", $$v)
                                  },
                                  expression: "editableData.language"
                                }
                              },
                              _vm._l(_vm.reportLanguages, function(
                                reportLanguage
                              ) {
                                return _c("v-radio", {
                                  key: reportLanguage.code,
                                  attrs: {
                                    color: "secondary",
                                    label: reportLanguage.name,
                                    value: reportLanguage.code
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { attrs: { value: _vm.startStep + 2 } },
                [
                  _c("BaseWindowItem", {
                    attrs: { trackCancel: "AutomatedReportsSettings::Close" },
                    on: { evtCancel: _vm.close },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v(_vm._s(_vm.$t("biz.report.reports")))]
                        },
                        proxy: true
                      },
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("biz.report.reporttype")))
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c(
                              "v-radio-group",
                              {
                                attrs: { mandatory: true, row: "" },
                                model: {
                                  value: _vm.editableData.reportVariant,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableData,
                                      "reportVariant",
                                      $$v
                                    )
                                  },
                                  expression: "editableData.reportVariant"
                                }
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    label: _vm.$t("biz.report.tempconformity"),
                                    value: "TEMPCONFORMITY",
                                    color: "secondary"
                                  }
                                }),
                                _c("v-radio", {
                                  attrs: {
                                    label: _vm.$t("biz.report.haccp"),
                                    value: "HACCP",
                                    color: "secondary"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.editableData.reportVariant === "TEMPCONFORMITY"
                              ? _c("p", {
                                  staticClass: "bus_normaltext",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "biz.report.dataprovided.tempconformity"
                                      )
                                    )
                                  }
                                })
                              : _vm._e(),
                            _vm.editableData.reportVariant === "HACCP"
                              ? _c("p", {
                                  staticClass: "bus_normaltext",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t("biz.report.dataprovided.haccp")
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { attrs: { value: _vm.startStep + 3 } },
                [
                  _c("BaseWindowItem", {
                    attrs: { trackCancel: "AutomatedReportsSettings::Close" },
                    on: { evtCancel: _vm.close },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v(_vm._s(_vm.$t("biz.report.reports")))]
                        },
                        proxy: true
                      },
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _vm.editableData.virtualFridgeIds &&
                            _vm.editableData.virtualFridgeIds.length > 0
                              ? _c("div", [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.$t("biz.report.reportname"))
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _vm.editableData.virtualFridgeIds &&
                            _vm.editableData.virtualFridgeIds.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0px",
                                          "font-weight": "bold"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("general.communication.name")
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-text-field", {
                                      staticClass: "default selected",
                                      attrs: {
                                        clearable: _vm.iconHelper.clearableIcon(
                                          _vm.editableData.name
                                        ),
                                        placeholder: _vm.$t(
                                          "general.communication.name"
                                        ),
                                        mandatory: true
                                      },
                                      model: {
                                        value: _vm.editableData.name,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editableData,
                                            "name",
                                            $$v
                                          )
                                        },
                                        expression: "editableData.name"
                                      }
                                    }),
                                    _vm.editableData.virtualFridgeIds &&
                                    _vm.editableData.virtualFridgeIds.length > 0
                                      ? _c("div", [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "margin-top": "5px",
                                                "margin-bottom": "10px",
                                                "font-weight": "bold"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.emailRecipientTitle
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "bus_normaltext",
                                              staticStyle: {
                                                "margin-bottom": "15px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "biz.report.addrecipientdesc"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0px",
                                          "font-weight": "bold"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "general.communication.emailaddress"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-text-field", {
                                      staticClass:
                                        "default selected errorborder",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "general.communication.email"
                                        ),
                                        rules: _vm.emailRules,
                                        clearable: _vm.iconHelper.clearableIcon(
                                          _vm.editableData.recipientEmail
                                        ),
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.editableData.recipientEmail,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editableData,
                                            "recipientEmail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "editableData.recipientEmail"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c("p", { staticClass: "bus_normaltext" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "biz.report.reportsettings.savewithoutappliances"
                                        )
                                      )
                                    )
                                  ])
                                ])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("BackNextMenu", {
            attrs: {
              stepCount: _vm.stepCount,
              step: _vm.step,
              previousDisabled: _vm.step === 1,
              nextDisabled: !_vm.isNextEnabled,
              stepNextExemptions: [2],
              stepPreviousExemptions: [3]
            },
            on: {
              "update:step": function($event) {
                _vm.step = $event
              },
              evtNextStepExemption: _vm.handleNextStep,
              evtPreviousStepExemption: _vm.handlePreviousStep
            },
            scopedSlots: _vm._u([
              {
                key: "finalStep",
                fn: function() {
                  return [
                    _vm.step === _vm.stepCount
                      ? _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: "AutomatedReportsSettings::Save",
                                expression: "'AutomatedReportsSettings::Save'"
                              }
                            ],
                            attrs: {
                              depressed: "",
                              disabled: !_vm.isSaveEnabled,
                              color: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.saveSettings()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("general.save")) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("OkCancelDialog", {
            attrs: {
              dialogShown: _vm.deleteAutoReportShown,
              title: _vm.$t("biz.report.reportsettings.deletereport"),
              message: _vm.$t(
                "biz.report.reportsettings.deleteautoreportmessage"
              ),
              track: "AutomatedReportSettings::DeleteReportDialog"
            },
            on: {
              evtOkPressed: function($event) {
                return _vm.confirmDeleteAutoSettings()
              },
              evtCancelPressed: function($event) {
                return _vm.cancelDeleteProcessAutoSettings()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }