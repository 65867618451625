import { render, staticRenderFns } from "./EditCloudAlarms.vue?vue&type=template&id=620d4c7d&"
import script from "./EditCloudAlarms.ts?vue&type=script&lang=ts&"
export * from "./EditCloudAlarms.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/hau-bus-ui_master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('620d4c7d')) {
      api.createRecord('620d4c7d', component.options)
    } else {
      api.reload('620d4c7d', component.options)
    }
    module.hot.accept("./EditCloudAlarms.vue?vue&type=template&id=620d4c7d&", function () {
      api.rerender('620d4c7d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/smartmodulealarms/cloudalarms/EditCloudAlarms.vue"
export default component.exports