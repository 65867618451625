// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
  AssignmentResultResponse,
  ErrorDto,
  LicenseOverviewResponse,
  UnassignmentResultResponse,
  VoucherResultResponse
} from '../models';

export interface AssignLicenseUsingPUTRequest {
  entitlementId: string;
  virtualFridgeId: string;
}

export interface RedeemVoucherUsingPOSTRequest {
  voucherCode: string;
  virtualFridgeId?: string;
}

export interface UnassignLicenseUsingPUTRequest {
  virtualFridgeId: string;
}

/**
 * no description
 */
export class LicenseControllerApi extends BaseAPI {
  /**
   * assignLicense
   * Assigns the license by entitlement to the VFID. Returns a new LicenseOverviewResponse within.
   */
  assignLicenseUsingPUT = (requestParameters: AssignLicenseUsingPUTRequest): Observable<AssignmentResultResponse> => {
    throwIfRequired(requestParameters, 'entitlementId', 'assignLicenseUsingPUT');
    throwIfRequired(requestParameters, 'virtualFridgeId', 'assignLicenseUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AssignmentResultResponse>({
      path: '/admin/license/assign/{entitlementId}/{virtualFridgeId}'
        .replace('{entitlementId}', encodeURI(requestParameters.entitlementId))
        .replace('{virtualFridgeId}', encodeURI(requestParameters.virtualFridgeId)),
      method: 'PUT',
      headers
    });
  };

  /**
   * getLicenseOverview
   * Gets the license overview for the user\'s appliances.
   */
  getLicenseOverviewUsingGET = (): Observable<LicenseOverviewResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<LicenseOverviewResponse>({
      path: '/license/overview',
      method: 'GET',
      headers
    });
  };

  /**
   * redeemVoucher
   * Redeems the given voucher code and assigns the license to the VFID. Returns a new LicenseOverviewResponse within.
   */
  redeemVoucherUsingPOST = (requestParameters: RedeemVoucherUsingPOSTRequest): Observable<VoucherResultResponse> => {
    throwIfRequired(requestParameters, 'voucherCode', 'redeemVoucherUsingPOST');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.virtualFridgeId && { virtualFridgeId: requestParameters.virtualFridgeId })
    };

    return this.request<VoucherResultResponse>({
      path: '/admin/license/redeem/{voucherCode}'.replace('{voucherCode}', encodeURI(requestParameters.voucherCode)),
      method: 'POST',
      headers,
      query
    });
  };

  /**
   * unassignLicense
   * Unassigns the license by VFID. Returns a new LicenseOverviewResponse within.
   */
  unassignLicenseUsingPUT = (
    requestParameters: UnassignLicenseUsingPUTRequest
  ): Observable<UnassignmentResultResponse> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'unassignLicenseUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<UnassignmentResultResponse>({
      path: '/admin/license/unassign/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'PUT',
      headers
    });
  };
}
