var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sensorbanner", class: _vm.color },
    [
      _c("v-icon", { staticClass: "icon" }, [_vm._v(_vm._s(_vm.icon))]),
      _c("p", { staticClass: "header" }, [_vm._v(_vm._s(_vm.getSensorName()))]),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isStateOK(),
              expression: "isStateOK()"
            }
          ],
          staticClass: "status",
          attrs: { column: "" }
        },
        [
          _c(
            "p",
            {
              staticStyle: {
                "align-self": "flex-end",
                "padding-right": "15px",
                "padding-top": "5px",
                "margin-bottom": "5px !important"
              }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.strengthText) + "\n      "),
              _c("v-icon", { staticStyle: { "padding-left": "5px" } }, [
                _vm._v(_vm._s(_vm.strengthIcon))
              ])
            ],
            1
          ),
          _c("p", { style: _vm.temperatureStyling }, [
            _vm._v(_vm._s(_vm.sensorValue))
          ])
        ]
      ),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isStateOK(),
              expression: "!isStateOK()"
            }
          ],
          staticClass: "status",
          attrs: { column: "" }
        },
        [
          _c(
            "p",
            {
              staticStyle: {
                "align-self": "flex-end",
                "padding-right": "15px",
                "padding-top": "5px",
                "margin-bottom": "5px !important"
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.sensorStateText) + "\n    ")]
          ),
          _c("p", { style: _vm.temperatureStyling }, [
            _vm._v(_vm._s(_vm.sensorValue))
          ])
        ]
      ),
      _vm.sensorState && _vm.licensed
        ? _c("p", { staticClass: "update" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("general.actualization")) +
                ": " +
                _vm._s(_vm.$format.dateTimeShort(_vm.since)) +
                "\n  "
            )
          ])
        : _vm._e(),
      _c("span", { staticClass: "hint" }, [
        _vm._v(_vm._s(_vm.$t("biz.testo.hint")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }