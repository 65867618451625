var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", xs12: "", "mb-2": "" } },
            [
              _c("v-flex", { attrs: { xs7: "" } }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "14px",
                      color: "black",
                      "margin-bottom": "5px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("biz.settings.language.langtitle")))]
                ),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "font-size": "14px",
                      opacity: "0.54",
                      color: "var(--v-bus_black-base)",
                      "margin-top": "-2px"
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("biz.settings.language.langtext")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c(
                "v-flex",
                { attrs: { xs5: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-end": "",
                        "justify-center": "",
                        column: "",
                        "fill-height": "",
                        "mt-3": ""
                      }
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.languageName))]),
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "margin-right": "0px",
                            "margin-top": "10px"
                          },
                          attrs: {
                            outline: "",
                            color: "secondary",
                            depressed: "",
                            flat: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showLanguageSelection = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("biz.settings.language.changelang"))
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("SimpleDialog", {
        attrs: {
          dialogShown: _vm.showLanguageSelection,
          title: _vm.$t("biz.settings.language.setuptitle"),
          message: _vm.languageChangeMessage,
          track: "LanguageSetup::LanguageSelection",
          buttonExit: false
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [
                  _c(
                    "v-layout",
                    { attrs: { row: "", xs12: "", "mb-5": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: { xs7: "" }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "black",
                                "margin-bottom": "5px"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("biz.settings.language.langtitle")
                                )
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticStyle: {
                            "margin-left": "-10px",
                            "margin-top": "-10px",
                            "margin-bottom": "-15px"
                          },
                          attrs: { xs5: "" }
                        },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { mandatory: false },
                              model: {
                                value: _vm.language,
                                callback: function($$v) {
                                  _vm.language = $$v
                                },
                                expression: "language"
                              }
                            },
                            _vm._l(_vm.availableLanguages, function(
                              availableLanguage
                            ) {
                              return _c("v-radio", {
                                key: availableLanguage.code,
                                attrs: {
                                  color: "secondary",
                                  label: availableLanguage.name,
                                  value: availableLanguage,
                                  disabled: _vm.isLanguageMissing(
                                    availableLanguage
                                  )
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            _vm.isAdmin
              ? {
                  key: "buttons",
                  fn: function() {
                    return [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value:
                                "LanguageSetup::OrganizationLanguageChange",
                              expression:
                                "'LanguageSetup::OrganizationLanguageChange'"
                            }
                          ],
                          attrs: {
                            outline: "",
                            color: "secondary",
                            depressed: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.saveOrganizationLanguage()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("biz.settings.language.changelangallusers")
                            )
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value: "LanguageSetup::LocalLanguageChange",
                              expression: "'LanguageSetup::LocalLanguageChange'"
                            }
                          ],
                          attrs: { depressed: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.savePersonalLanguage()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("biz.settings.language.changelang"))
                          )
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              : {
                  key: "buttons",
                  fn: function() {
                    return [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value: "LanguageSetup::LocalLanguageChange",
                              expression: "'LanguageSetup::LocalLanguageChange'"
                            }
                          ],
                          attrs: { depressed: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.savePersonalLanguage()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("general.ok")))]
                      )
                    ]
                  },
                  proxy: true
                }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }