// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserProfile } from './';

/**
 * @export
 * @interface ApplianceAlarmProfileUpdateRequest
 */
export interface ApplianceAlarmProfileUpdateRequest {
  /**
   * @type {boolean}
   * @memberof ApplianceAlarmProfileUpdateRequest
   */
  alarmActivatedByUser?: boolean;
  /**
   * @type {number}
   * @memberof ApplianceAlarmProfileUpdateRequest
   */
  alarmDelay?: number;
  /**
   * @type {string}
   * @memberof ApplianceAlarmProfileUpdateRequest
   */
  alarmProfileId?: string;
  /**
   * @type {number}
   * @memberof ApplianceAlarmProfileUpdateRequest
   */
  repetitionTime?: number;
  /**
   * @type {number}
   * @memberof ApplianceAlarmProfileUpdateRequest
   */
  temperatureHigherLimit?: number;
  /**
   * @type {number}
   * @memberof ApplianceAlarmProfileUpdateRequest
   */
  temperatureLowerLimit?: number;
  /**
   * @type {Array<UserProfile>}
   * @memberof ApplianceAlarmProfileUpdateRequest
   */
  userProfiles?: Array<UserProfile>;
  /**
   * @type {string}
   * @memberof ApplianceAlarmProfileUpdateRequest
   */
  virtualFridgeId?: string;
}
