var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { slot: "header", "pb-2": "" }, slot: "header" },
        [
          _c(
            "v-flex",
            { staticStyle: { "align-self": "center" }, attrs: { xs4: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { staticStyle: { float: "left" }, attrs: { xs2: "" } },
                    [
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "Learn::GoToDocumentation",
                                  expression: "'Learn::GoToDocumentation'"
                                }
                              ],
                              attrs: { flat: "", icon: "", to: "Documentation" }
                            },
                            [
                              _c(
                                "v-avatar",
                                { attrs: { color: "secondary", size: "40" } },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "white" } },
                                    [_vm._v("keyboard_arrow_left")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticStyle: { "align-self": "center" },
                      attrs: { xs10: "" }
                    },
                    [
                      _c("v-layout", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("biz.help.tutorialvideos")))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-container",
        { attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" } },
        [
          _c("CriticalDevicesPage", {
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "v-card",
                      { staticClass: "pa-5" },
                      [
                        _c(
                          "v-layout",
                          { attrs: { "justify-start": "", "align-start": "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("Videos", {
                                  attrs: { readVideos: _vm.getVideos() }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }