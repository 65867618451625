// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OnboardedAppliance, Thing } from './';

/**
 * @export
 * @interface GatewayConfigurationResponse
 */
export interface GatewayConfigurationResponse {
  /**
   * @type {Array<OnboardedAppliance>}
   * @memberof GatewayConfigurationResponse
   */
  configuredAppliances?: Array<OnboardedAppliance>;
  /**
   * @type {Array<OnboardedAppliance>}
   * @memberof GatewayConfigurationResponse
   */
  disconnectedAppliances?: Array<OnboardedAppliance>;
  /**
   * @type {string}
   * @memberof GatewayConfigurationResponse
   */
  gatewayId?: string;
  /**
   * @type {Array<Thing>}
   * @memberof GatewayConfigurationResponse
   */
  newAppliances?: Array<Thing>;
  /**
   * @type {Date}
   * @memberof GatewayConfigurationResponse
   */
  scannedAt?: Date;
  /**
   * @type {boolean}
   * @memberof GatewayConfigurationResponse
   */
  scanning?: boolean;
}
