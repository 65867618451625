var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.dataReady
    ? _c(
        "v-layout",
        { attrs: { "fill-height": "", row: "", wrap: "" } },
        [
          _c(
            "v-layout",
            {
              staticStyle: { height: "65vh" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.loading")))
              ])
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-layout",
        { attrs: { "fill-height": "", row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { sm12: "", lg5: "", "pr-0": "" } },
            [
              _c(
                "v-container",
                { attrs: { "pr-0": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("Manufacturer", {
                            attrs: {
                              extendedGatewayState: _vm.extendedGatewayState,
                              track: "GatewayInfo::Manufacturer"
                            },
                            on: { evtSave: _vm.saveBsdbName }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticStyle: { "margin-top": "52px" } },
                        [
                          _c("Network", {
                            attrs: {
                              extendedGatewayState: _vm.extendedGatewayState
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { sm12: "", lg7: "" } },
            [
              _c(
                "v-container",
                { staticStyle: { "padding-left": "28px" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("Connections", {
                            attrs: {
                              selectedGateway: _vm.selectedGateway,
                              extendedGatewayState: _vm.extendedGatewayState
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }