// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface HistorySensorAlarm
 */
export interface HistorySensorAlarm {
  /**
   * @type {number}
   * @memberof HistorySensorAlarm
   */
  alarmDurationInSeconds?: number;
  /**
   * @type {Date}
   * @memberof HistorySensorAlarm
   */
  alarmEndedTimestamp?: Date;
  /**
   * @type {string}
   * @memberof HistorySensorAlarm
   */
  alarmId?: string;
  /**
   * @type {number}
   * @memberof HistorySensorAlarm
   */
  alarmProfileDoorOpeningDurationInSeconds?: number;
  /**
   * @type {Date}
   * @memberof HistorySensorAlarm
   */
  alarmStartedTimestamp?: Date;
  /**
   * @type {string}
   * @memberof HistorySensorAlarm
   */
  alarmType?: string;
  /**
   * @type {number}
   * @memberof HistorySensorAlarm
   */
  maxAlarmTemperature?: number;
  /**
   * @type {number}
   * @memberof HistorySensorAlarm
   */
  maxTemperatureThreshold?: number;
  /**
   * @type {number}
   * @memberof HistorySensorAlarm
   */
  minAlarmTemperature?: number;
  /**
   * @type {number}
   * @memberof HistorySensorAlarm
   */
  minTemperatureThreshold?: number;
}
