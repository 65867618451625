var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("g", [
            _c("g", { staticStyle: { fill: "var(--v-bus_red-base)" } }, [
              _c("circle", { attrs: { cx: "16", cy: "16", r: "16" } })
            ]),
            _c(
              "g",
              {
                staticStyle: { fill: "var(--v-bus_white-base)" },
                attrs: { transform: "translate(4.000000, 4.000000)" }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M11,2 C12.6568542,2 14,3.34314575 14,5 L14.0011995,13.0005351 C15.2149639,13.9127966 16,15.3647208 16,17 C16,19.7614237 13.7614237,22 11,22 C8.23857625,22 6,19.7614237 6,17 C6,15.3642694 6.7854695,13.9119951 7.99980567,12.9997798 L8,5 C8,3.34314575 9.34314575,2 11,2 Z M19.4622192,7 C19.7592273,7 20,7.2428116 20,7.54233488 L20,12.4576515 C20,12.5845234 19.9558933,12.7073801 19.8753539,12.8048459 C19.6852139,13.034946 19.3461083,13.0660348 19.1179404,12.8742847 L16.1935154,10.4166264 C16.1685285,10.3956276 16.1454821,10.3723861 16.1246596,10.3471875 C15.9345197,10.1170874 15.9653474,9.77511014 16.1935154,9.58336001 L19.1179404,7.12570168 C19.2145876,7.04448026 19.3364127,7 19.4622192,7 Z M11,4 C10.4477153,4 10,4.44771525 10,5 L10,9 L12,9 L12,5 C12,4.44771525 11.5522847,4 11,4 Z"
                  }
                })
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }