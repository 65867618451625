var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700px" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-3" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", nowrap: "" } },
            [
              _vm.editingColleague
                ? _c("v-card-title", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.colleagueTitle))
                  ])
                : _c("v-card-title", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("general.menus.settings.phonenumber.number")
                      )
                    )
                  ]),
              _c(
                "v-layout",
                { staticStyle: { "justify-content": "flex-end" } },
                [
                  _c("Cancel", {
                    attrs: {
                      cancelDisabled: _vm.cancelDisabled,
                      track: _vm.track + "::PhoneNumberEditDialog"
                    },
                    on: {
                      evtCancel: function($event) {
                        return _vm.onEditCancel()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "p",
            { staticStyle: { "margin-bottom": "0px", "font-weight": "bold" } },
            [_vm._v(_vm._s(_vm.$t("general.communication.mobile")))]
          ),
          _c("v-text-field", {
            staticClass: "default selected errorborder",
            attrs: {
              clearable: _vm.IconHelper.clearableIcon(_vm.mobileEditField),
              type: "string",
              rules: _vm.phoneNumberRules,
              placeholder: _vm.$t("general.communication.mobile"),
              required: ""
            },
            model: {
              value: _vm.mobileEditField,
              callback: function($$v) {
                _vm.mobileEditField = $$v
              },
              expression: "mobileEditField"
            }
          }),
          _c(
            "v-layout",
            { staticStyle: { "justify-content": "flex-end" } },
            [
              _c("SaveCancel", {
                attrs: {
                  saveDisabled: _vm.saveDisabled,
                  cancelDisabled: _vm.cancelDisabled,
                  track: _vm.track + "::PhoneNumberEditDialog",
                  icon: false
                },
                on: {
                  evtSave: function($event) {
                    return _vm.onPhoneNumberSave()
                  },
                  evtCancel: function($event) {
                    return _vm.onEditCancel()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }