// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ReportRequestData } from './';

/**
 * @export
 * @interface MultipleReportRequest
 */
export interface MultipleReportRequest {
  /**
   * @type {Date}
   * @memberof MultipleReportRequest
   */
  end?: Date;
  /**
   * @type {boolean}
   * @memberof MultipleReportRequest
   */
  isManuallyCreated?: boolean;
  /**
   * @type {string}
   * @memberof MultipleReportRequest
   */
  language?: string;
  /**
   * @type {string}
   * @memberof MultipleReportRequest
   */
  reportName?: string;
  /**
   * @type {Array<ReportRequestData>}
   * @memberof MultipleReportRequest
   */
  reportRequestDataList?: Array<ReportRequestData>;
  /**
   * @type {Date}
   * @memberof MultipleReportRequest
   */
  start?: Date;
  /**
   * @type {string}
   * @memberof MultipleReportRequest
   */
  timezone?: string;
}
