// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApplianceGroup } from './';

/**
 * @export
 * @interface ApplianceGroupsResponse
 */
export interface ApplianceGroupsResponse {
  /**
   * @type {ApplianceGroup}
   * @memberof ApplianceGroupsResponse
   */
  defaultGroup?: ApplianceGroup;
  /**
   * @type {Array<ApplianceGroup>}
   * @memberof ApplianceGroupsResponse
   */
  groups?: Array<ApplianceGroup>;
}
