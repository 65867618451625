var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.systemalarm")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", {
              staticClass: "bus_normaltext",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t(
                    "biz.sensors.alarmsetting.bsdbunavailability.description"
                  )
                )
              }
            })
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { "pt-3": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.name")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.editableData.gatewayName))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.delaytime")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$tc(
                      "general.duration.minute",
                      _vm.editableData.bsdbAlarmDelay / 1000 / 60
                    )
                  ) +
                  "\n      "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { staticStyle: { "margin-bottom": "8px" } },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: "BsdbUnavailableAlarm::ToSCHAlarmSettings",
                  expression: "'BsdbUnavailableAlarm::ToSCHAlarmSettings'"
                }
              ],
              attrs: { depressed: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.gotoSCHAlarmSettings()
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("biz.dashboard.alarms.alarmsettings")) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }