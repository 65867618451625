var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.applianceinfo.address")))
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "align-items: end", attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { editDisabled: _vm.isDisabled(), track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.addressline1")))
            ]),
            !_vm.isMeasureSiteAddress
              ? _c(
                  "p",
                  { staticStyle: { color: "var(--v-bus_yellow-base)" } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("biz.applianceinfo.companyaddress")) +
                        "\n      "
                    )
                  ]
                )
              : _vm._e(),
            _vm.isMeasureSiteAddress
              ? _c(
                  "p",
                  { staticStyle: { color: "var(--v-bus_yellow-base)" } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("biz.applianceinfo.measuresiteaddress")) +
                        "\n      "
                    )
                  ]
                )
              : _vm._e()
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.editableData.address.addressLine1
                      ? _vm.editableData.address.addressLine1
                      : _vm.SHOW_BLANK
                  ) +
                  "\n      "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.addressline2")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.editableData.address.addressLine2
                      ? _vm.editableData.address.addressLine2
                      : _vm.SHOW_BLANK
                  ) +
                  "\n      "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.zipcode")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.address.zipCode
                    ? _vm.editableData.address.zipCode
                    : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.city")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.address.city
                    ? _vm.editableData.address.city
                    : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.country")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.editableData.address.countryIsoCode
                      ? _vm.editableData.address.countryIsoCode
                      : _vm.SHOW_BLANK
                  ) +
                  "\n      "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700px" },
          model: {
            value: _vm.editorShown,
            callback: function($$v) {
              _vm.editorShown = $$v
            },
            expression: "editorShown"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.applianceinfo.address")))
              ]),
              _c(
                "v-layout",
                { attrs: { column: "", "pa-3": "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticStyle: { "justify-content": "space-between" },
                      attrs: { row: "" }
                    },
                    [
                      _c("v-layout", { attrs: { column: "" } }, [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "margin-bottom": "0px",
                              "font-weight": "bold"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("biz.applianceinfo.addressline1"))
                            )
                          ]
                        ),
                        _vm.isMeasureSiteAddress
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  color: "var(--v-bus_yellow-base)"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "biz.applianceinfo.deleteaddressdesc"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm.isMeasureSiteAddress
                        ? _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "Address::DeleteMeasureSiteAddress",
                                  expression:
                                    "'Address::DeleteMeasureSiteAddress'"
                                }
                              ],
                              attrs: {
                                depressed: "",
                                color: "secondary",
                                outline: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showDeleteAddressDialog()
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: { size: "20px" }
                                },
                                [_vm._v("$vuetify.icons.bus_delete")]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("biz.applianceinfo.deleteaddress")
                                  ) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.address.addressLine1
                      ),
                      placeholder: _vm.$t("biz.applianceinfo.addressline1"),
                      rules: _vm.addressRules,
                      type: "string",
                      required: ""
                    },
                    model: {
                      value: _vm.editableDataLocal.address.addressLine1,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.editableDataLocal.address,
                          "addressLine1",
                          $$v
                        )
                      },
                      expression: "editableDataLocal.address.addressLine1"
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.addressline2")))]
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.address.addressLine2
                      ),
                      placeholder: _vm.$t("biz.applianceinfo.addressline2"),
                      rules: _vm.addressRules,
                      type: "string",
                      required: ""
                    },
                    model: {
                      value: _vm.editableDataLocal.address.addressLine2,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.editableDataLocal.address,
                          "addressLine2",
                          $$v
                        )
                      },
                      expression: "editableDataLocal.address.addressLine2"
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.zipcode")))]
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.address.zipCode
                      ),
                      placeholder: _vm.$t("biz.applianceinfo.zipcode"),
                      rules: _vm.zipcodeRules,
                      required: ""
                    },
                    model: {
                      value: _vm.editableDataLocal.address.zipCode,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal.address, "zipCode", $$v)
                      },
                      expression: "editableDataLocal.address.zipCode"
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.city")))]
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.address.city
                      ),
                      placeholder: _vm.$t("biz.applianceinfo.city"),
                      rules: _vm.addressRules,
                      type: "string",
                      required: ""
                    },
                    model: {
                      value: _vm.editableDataLocal.address.city,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal.address, "city", $$v)
                      },
                      expression: "editableDataLocal.address.city"
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.country")))]
                  ),
                  _c("v-select", {
                    staticClass: "default selected",
                    attrs: {
                      chips: "",
                      items: _vm.supportedCountries,
                      placeholder: _vm.$t("biz.applianceinfo.country"),
                      "menu-props": { bottom: true, offsetY: true },
                      "item-text": "name",
                      "full-width": "",
                      "return-object": ""
                    },
                    model: {
                      value: _vm.supportedCountry,
                      callback: function($$v) {
                        _vm.supportedCountry = $$v
                      },
                      expression: "supportedCountry"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("SaveCancel", {
                    attrs: {
                      saveDisabled: _vm.saveDisabled,
                      track: _vm.track + "::SaveCancelAddressFields",
                      icon: false
                    },
                    on: {
                      evtSave: function($event) {
                        return _vm.onSave()
                      },
                      evtCancel: function($event) {
                        return _vm.onCancel()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("SimpleDialog", {
        attrs: {
          dialogShown: _vm.showDeleteAddressConfirmationDialog,
          title: _vm.$t("biz.applianceinfo.deleteaddressdialogtitle")
        },
        on: {
          evtClose: function($event) {
            return _vm.onClosePressed()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("biz.applianceinfo.deleteaddressdialogdesc1"))
                  )
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("biz.applianceinfo.deleteaddressdialogdesc2"))
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "buttons",
            fn: function() {
              return [
                _c("v-spacer"),
                _c("OkCancel", {
                  attrs: {
                    track: _vm.track + "::DeleteMeasureSiteAddressDialog"
                  },
                  on: {
                    evtOk: function($event) {
                      return _vm.confirmDeleteAddress()
                    },
                    evtCancel: function($event) {
                      return _vm.cancelPressed()
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }