var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: "SmartModuleSetup::Close",
          dialogShown: _vm.cancelDialogShown
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("biz.onboarding.applianceonboarding.title1"))
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("v-layout", { attrs: { column: "" } }, [
                  _c("div", { staticClass: "bus_subheading" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("biz.onboarding.applianceonboarding.title1")
                      )
                    )
                  ]),
                  _c("div", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("biz.onboarding.applianceonboarding.text1")
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _c("v-layout", { attrs: { column: "", "mt-5": "" } }, [
                  _c("div", { staticClass: "bus_subheading2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "biz.onboarding.applianceonboarding.connectwithnetwork"
                        )
                      )
                    )
                  ]),
                  _c("div", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("biz.onboarding.applianceonboarding.text2")
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _c(
                  "v-layout",
                  {
                    attrs: {
                      row: "",
                      nowrap: "",
                      "align-center": "",
                      "justify-center": "",
                      "mt-4": ""
                    }
                  },
                  [
                    _c("v-hover", {
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var hover = ref.hover
                            return _c(
                              "v-card",
                              {
                                class: "elevation-" + (hover ? 12 : 2),
                                staticStyle: {
                                  cursor: "pointer",
                                  margin: "20px"
                                },
                                attrs: {
                                  height: "190px",
                                  width: "200px",
                                  color: "secondary",
                                  flat: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.setNetworkType(true)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "align-center": "",
                                      "justify-center": ""
                                    }
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: require("@/assets/wifi-onboarding.png"),
                                        "max-width": "150"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { "mt-2": "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "font-weight-bold",
                                        staticStyle: { color: "white" },
                                        attrs: { "justify-center": "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("biz.setup.typenetwork.wlan")
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }
                        }
                      ])
                    }),
                    _c("v-hover", {
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var hover = ref.hover
                            return _c(
                              "v-card",
                              {
                                class: "elevation-" + (hover ? 12 : 2),
                                staticStyle: {
                                  cursor: "pointer",
                                  margin: "20px"
                                },
                                attrs: {
                                  height: "190px",
                                  width: "200px",
                                  color: "secondary",
                                  flat: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.setNetworkType(false)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "align-center": "",
                                      "justify-center": ""
                                    }
                                  },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: require("@/assets/lan-onboarding.png"),
                                        "max-width": "150"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { "mt-2": "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "font-weight-bold",
                                        staticStyle: { color: "white" },
                                        attrs: { "justify-center": "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("biz.setup.typenetwork.lan")
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }