var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18px",
        height: "18px",
        version: "1.1",
        viewBox: "0 0 18 18",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            "fill-rule": "evenodd",
            stroke: "none",
            "stroke-width": "1",
            transform: "matrix(0.9, 0, 0, 0.818182, -1.8, -0.818182)"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-secondary-base)" },
            attrs: {
              "fill-rule": "nonzero",
              d:
                "M17,4 L21,4 C21.5522847,4 22,4.44771525 22,5 C22,5.55228475 21.5522847,6 21,6 L20.0000853,6 C20.0000412,6.0359233 19.9980665,6.07220263 19.9940717,6.10872659 L18.3415547,21.2174532 C18.2306091,22.231813 17.3738205,23 16.3534113,23 L7.64658866,23 C6.6261795,23 5.76939089,22.231813 5.65844528,21.2174532 L4.00592831,6.10872659 C4.0019335,6.07220263 3.99995877,6.0359233 3.99991468,6 L3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 L7,4 L7,3 C7,1.8954305 7.8954305,1 9,1 L15,1 C16.1045695,1 17,1.8954305 17,3 L17,4 Z M15,4 L15,3 L9,3 L9,4 L15,4 Z M17.9940363,6 L6.00596366,6 L7.64658866,21 L16.3534113,21 L17.9940363,6 L17.9940363,6 Z"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }