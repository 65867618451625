var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { lazy: "", "max-width": "500px" },
      model: {
        value: _vm.editorShown,
        callback: function($$v) {
          _vm.editorShown = $$v
        },
        expression: "editorShown"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-layout",
            { attrs: { "fill-height": "", column: "", "pa-3": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-card-title", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("biz.appliancehistory.selectforcsv")))
                  ])
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-radio-group",
                    {
                      staticClass: "ml-3",
                      model: {
                        value: _vm.singleSelected,
                        callback: function($$v) {
                          _vm.singleSelected = $$v
                        },
                        expression: "singleSelected"
                      }
                    },
                    [
                      _vm.isModbusFridge
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "bus_text mb-2",
                                staticStyle: { "font-weight": "bold" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("biz.general.items.device"))
                                )
                              ]
                            ),
                            _c("v-radio", {
                              key: _vm.applianceZone.name,
                              attrs: {
                                color: "secondary",
                                label: _vm.applianceZone.name,
                                value: _vm.applianceZone,
                                disabled: _vm.isApplianceDisabled()
                              }
                            })
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "bus_text mb-2",
                          staticStyle: { "font-weight": "bold" }
                        },
                        [_vm._v(_vm._s(_vm.$t("general.sensors")))]
                      ),
                      _vm._l(_vm.sensors, function(sensor) {
                        return _c("v-radio", {
                          key: sensor.thingId,
                          attrs: {
                            color: "secondary",
                            label: sensor.name,
                            value: sensor,
                            disabled: _vm.isSensorDisabled(sensor)
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("OkCancel", {
                    attrs: { track: _vm.track, icon: false },
                    on: {
                      evtOk: function($event) {
                        return _vm.onSaveSelection()
                      },
                      evtCancel: function($event) {
                        return _vm.onCancelSelection()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }