// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ZoneAlarmSettingsResponse } from './';

/**
 * @export
 * @interface SmartModuleAlarmSettingsResponse
 */
export interface SmartModuleAlarmSettingsResponse {
  /**
   * @type {boolean}
   * @memberof SmartModuleAlarmSettingsResponse
   */
  enabled?: boolean;
  /**
   * @type {number}
   * @memberof SmartModuleAlarmSettingsResponse
   */
  offlineAlarmDelayInSeconds?: number;
  /**
   * @type {boolean}
   * @memberof SmartModuleAlarmSettingsResponse
   */
  offlineAlarmEnabled?: boolean;
  /**
   * @type {string}
   * @memberof SmartModuleAlarmSettingsResponse
   */
  serialNumber?: string;
  /**
   * @type {string}
   * @memberof SmartModuleAlarmSettingsResponse
   */
  virtualFridgeId?: string;
  /**
   * @type {Array<ZoneAlarmSettingsResponse>}
   * @memberof SmartModuleAlarmSettingsResponse
   */
  zoneAlarmSettingsResponses?: Array<ZoneAlarmSettingsResponse>;
}
