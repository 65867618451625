// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SmartModuleZoneTemperature } from './';

/**
 * @export
 * @interface SmartModuleZoneState
 */
export interface SmartModuleZoneState {
  /**
   * @type {Array<string>}
   * @memberof SmartModuleZoneState
   */
  alarmTypes?: Array<string>;
  /**
   * @type {string}
   * @memberof SmartModuleZoneState
   */
  doorState?: string;
  /**
   * @type {string}
   * @memberof SmartModuleZoneState
   */
  state?: string;
  /**
   * @type {SmartModuleZoneTemperature}
   * @memberof SmartModuleZoneState
   */
  temperature?: SmartModuleZoneTemperature;
  /**
   * @type {string}
   * @memberof SmartModuleZoneState
   */
  thingId?: string;
}
