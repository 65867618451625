var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c("BaseWindowPage", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(_vm.$t("biz.onboarding.smartmodule.wifireset.title"))
                )
              ]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  { attrs: { "align-center": "", "justify-center": "" } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("biz.onboarding.smartmodule.wifireset.text")
                        ) +
                        "\n      "
                    )
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  {
                    staticStyle: { height: "32vh" },
                    attrs: {
                      column: "",
                      "align-center": "",
                      "justify-center": ""
                    }
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: {
                        indeterminate: "",
                        color: "secondary",
                        size: "48"
                      }
                    }),
                    _c("p", { staticClass: "bus_normaltext mt-5" }, [
                      _vm._v(_vm._s(_vm.$t("general.actions.loading")))
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("OkDialog", {
        attrs: {
          dialogShown: _vm.allDoneDialogShown,
          title: _vm.$t("biz.onboarding.smartmodule.wifireset.alldonetitle"),
          message: _vm.$t("biz.onboarding.smartmodule.wifireset.alldonetext"),
          track: _vm.track + "::AllDone"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.allDoneConfirmed()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }