// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import { ErrorDto, Message, MessageDescription, MessageStats, ServiceEmailRequest } from '../models';

export interface DownloadMessageUsingGETRequest {
  messagename: string;
}

export interface SendServiceEmailUsingPOSTRequest {
  serviceEmailRequest: ServiceEmailRequest;
}

/**
 * no description
 */
export class MessagingControllerApi extends BaseAPI {
  /**
   * downloadMessage
   * Gets the current messages for the user.
   */
  downloadMessageUsingGET = (requestParameters: DownloadMessageUsingGETRequest): Observable<Message> => {
    throwIfRequired(requestParameters, 'messagename', 'downloadMessageUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Message>({
      path: '/messaging/message/{messagename}'.replace('{messagename}', encodeURI(requestParameters.messagename)),
      method: 'GET',
      headers
    });
  };

  /**
   * getMessageStats
   * Gets the message stats (unread messages count and read messages count
   */
  getMessageStatsUsingGET = (): Observable<MessageStats> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<MessageStats>({
      path: '/messaging/messagestats',
      method: 'GET',
      headers
    });
  };

  /**
   * listMessages
   * Gets the current messages for the user.
   */
  listMessagesUsingGET = (): Observable<Array<MessageDescription>> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Array<MessageDescription>>({
      path: '/messaging/list',
      method: 'GET',
      headers
    });
  };

  /**
   * sendServiceEmail
   * Sends a service request by email.
   */
  sendServiceEmailUsingPOST = (requestParameters: SendServiceEmailUsingPOSTRequest): Observable<boolean> => {
    throwIfRequired(requestParameters, 'serviceEmailRequest', 'sendServiceEmailUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/messaging/serviceEmail',
      method: 'POST',
      headers,
      body: requestParameters.serviceEmailRequest
    });
  };
}
