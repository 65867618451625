var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700px", "min-width": "600px" },
      model: {
        value: _vm.openExternalApplianceDialog,
        callback: function($$v) {
          _vm.openExternalApplianceDialog = $$v
        },
        expression: "openExternalApplianceDialog"
      }
    },
    [
      _c("BaseWindowItem", {
        attrs: { trackCancel: "MeasureSiteExternalAppliance::Close" },
        on: {
          evtCancel: function($event) {
            return _vm.closeDialog()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "biz.sensors.setup.measuresite.datacards.titleexternal"
                    )
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("div", { staticClass: "font-weight-black" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "biz.sensors.setup.measuresite.datacards.properties"
                      )
                    )
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "description",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "biz.sensors.setup.measuresite.datacards.descriptionexternal"
                    )
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-layout",
                      [
                        _c(
                          "v-layout",
                          { attrs: { column: "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { "mr-4": "" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "bus_required_field" },
                                  [
                                    _vm._v(
                                      "* " + _vm._s(_vm.$t("general.required"))
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("biz.applianceinfo.name"))
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass:
                                    "default selected errorborder mb-3",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForExternalAppliance.name
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.sensors.setup.properties.name"
                                    ),
                                    rules: _vm.nameRules,
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForExternalAppliance.name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForExternalAppliance,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForExternalAppliance.name"
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("biz.applianceinfo.model"))
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected mb-3",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForExternalAppliance.model
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.applianceinfo.model"
                                    ),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForExternalAppliance.model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForExternalAppliance,
                                        "model",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForExternalAppliance.model"
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("biz.applianceinfo.manufacturer")
                                      )
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected mb-3",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForExternalAppliance
                                        .manufacture
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.applianceinfo.manufacturer"
                                    ),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForExternalAppliance
                                        .manufacture,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForExternalAppliance,
                                        "manufacture",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForExternalAppliance.manufacture"
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("general.comment")))]
                                ),
                                _c("v-textarea", {
                                  staticClass:
                                    "defaulttextarea selectedtextarea mb-3",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForExternalAppliance.comment
                                    ),
                                    placeholder: _vm.$t("general.comment"),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForExternalAppliance.comment,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForExternalAppliance,
                                        "comment",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForExternalAppliance.comment"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-layout",
                          { attrs: { column: "" } },
                          [
                            _c(
                              "v-flex",
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("biz.applianceinfo.building")
                                      )
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected mb-3",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForExternalAppliance.building
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.applianceinfo.building"
                                    ),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForExternalAppliance
                                        .building,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForExternalAppliance,
                                        "building",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForExternalAppliance.building"
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("biz.applianceinfo.department")
                                      )
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected mb-3",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForExternalAppliance
                                        .department
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.applianceinfo.department"
                                    ),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForExternalAppliance
                                        .department,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForExternalAppliance,
                                        "department",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForExternalAppliance.department"
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("biz.applianceinfo.level"))
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected mb-3",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForExternalAppliance.level
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.applianceinfo.floor"
                                    ),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForExternalAppliance.level,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForExternalAppliance,
                                        "level",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForExternalAppliance.level"
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("biz.applianceinfo.room"))
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected mb-3",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForExternalAppliance
                                        .roomNumber
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.applianceinfo.room"
                                    ),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForExternalAppliance
                                        .roomNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForExternalAppliance,
                                        "roomNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForExternalAppliance.roomNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("p", [
                      _vm._v(
                        "*" +
                          _vm._s(
                            _vm.$t("biz.sensors.setup.measuresite.mandatory")
                          )
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  {
                    staticStyle: { "justify-content": "flex-end" },
                    attrs: { "mb-3": "" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "MeasureSiteExternalAppliance::Next",
                            expression: "'MeasureSiteExternalAppliance::Next'"
                          }
                        ],
                        attrs: {
                          depressed: "",
                          disabled: !_vm.localDataForExternalAppliance.name,
                          color: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showGroupDialog()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.next")))]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("ChooseGroupDialog", {
        attrs: { openGroupDialog: _vm.openGroupDialog },
        on: { evtGroupDialogSave: _vm.groupSelected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }