// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SmartModuleLaunchResponse
 */
export interface SmartModuleLaunchResponse {
  /**
   * @type {string}
   * @memberof SmartModuleLaunchResponse
   */
  error?: SmartModuleLaunchResponseErrorEnum;
  /**
   * @type {boolean}
   * @memberof SmartModuleLaunchResponse
   */
  successfulLaunch?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum SmartModuleLaunchResponseErrorEnum {
  ALREADYONBOARDEDERROR = 'SMART_MODULE_FRIDGE_ALREADY_ONBOARDED_ERROR',
  NOTONLINEERROR = 'SMART_MODULE_FRIDGE_NOT_ONLINE_ERROR',
  ONLINESINCEPERIODEXCEEDEDERROR = 'SMART_MODULE_FRIDGE_ONLINE_SINCE_PERIOD_EXCEEDED_ERROR'
}
