var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { staticClass: "mb-3" },
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.applianceinfo.manuals")))
              ]),
              _vm.modelDescription
                ? _c("p", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("biz.general.items.model")) +
                        ": " +
                        _vm._s(_vm.modelDescription) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _vm.articleNumber
                ? _c("p", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.general.items.article")) +
                        ": " +
                        _vm._s(_vm.articleNumber)
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      ),
      _vm.hasInstructionManuals()
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                [
                  _c("p", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.applianceinfo.instructionmanuals"))
                    )
                  ]),
                  _vm._l(_vm.instructionManualLinks, function(manual) {
                    return _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "Manuals::OpenInstruction",
                            expression: "'Manuals::OpenInstruction'"
                          }
                        ],
                        key: manual,
                        staticStyle: { "margin-left": "0px" },
                        attrs: {
                          depressed: "",
                          outline: "",
                          color: "secondary",
                          href: manual,
                          target: "_blank"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: { color: "secondary" }
                          },
                          [_vm._v("help_outline")]
                        ),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("biz.applianceinfo.clickmanual")) +
                            "\n      "
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasInstallationManuals()
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                [
                  _c("p", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.applianceinfo.installationmanuals"))
                    )
                  ]),
                  _vm._l(_vm.installationManualLinks, function(manual) {
                    return _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "Manuals::OpenInstallation",
                            expression: "'Manuals::OpenInstallation'"
                          }
                        ],
                        key: manual,
                        staticStyle: { "margin-left": "0px" },
                        attrs: {
                          depressed: "",
                          outline: "",
                          color: "secondary",
                          href: manual,
                          target: "_blank"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: { color: "secondary" }
                          },
                          [_vm._v("help_outline")]
                        ),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("biz.applianceinfo.clickmanual")) +
                            "\n      "
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.hasDocuments()
        ? _c(
            "v-layout",
            [
              _c("v-flex", [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.$t("biz.applianceinfo.nomanuals")))
                ])
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }