var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px", persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _c("div", { staticClass: "title" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("general.menus.settings.reminder.header")))
              ])
            ])
          ]),
          _c("v-card-text", { staticClass: "bus_normaltext" }, [
            _c("p", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("general.menus.settings.reminder.text")
                )
              }
            }),
            _c("a", { attrs: { href: "/generalsettings" } }, [
              _vm._v(
                _vm._s(
                  _vm.$t("general.menus.settings.reminder.generalsettings")
                )
              )
            ]),
            _vm.admin
              ? _c("div", [
                  !_vm.hasValidSmsLicense()
                    ? _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "general.menus.settings.reminder.textlicenseadmin"
                            )
                          )
                        }
                      })
                    : _vm._e()
                ])
              : _c("div", [
                  !_vm.hasValidSmsLicense()
                    ? _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "general.menus.settings.reminder.textlicense"
                            )
                          )
                        }
                      })
                    : _vm._e()
                ])
          ]),
          _c(
            "div",
            { staticStyle: { "margin-left": "16px" } },
            [
              _c("v-checkbox", {
                attrs: {
                  color: "secondary",
                  label: _vm.$t("general.dontshow")
                },
                model: {
                  value: _vm.hidePhoneNumberReminder,
                  callback: function($$v) {
                    _vm.hidePhoneNumberReminder = $$v
                  },
                  expression: "hidePhoneNumberReminder"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _vm.isAdminUser && !_vm.hasValidSmsLicense()
                ? _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: _vm.track + "::BuyLicenses",
                          expression: "track + '::BuyLicenses'"
                        }
                      ],
                      staticStyle: {
                        "margin-left": "1px",
                        "padding-left": "5px"
                      },
                      attrs: { color: "secondary", outline: "" },
                      on: {
                        click: function($event) {
                          _vm.buyLicenseDialog = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v(_vm._s(_vm.getRightArrowIcon()))
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("biz.settings.buylicenses")) +
                          "\n      "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::Close",
                      expression: "track + '::Close'"
                    }
                  ],
                  attrs: { depressed: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("general.ok")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("BuyLicenseDialog", {
        attrs: { showDialog: _vm.buyLicenseDialog },
        on: {
          evtClose: function($event) {
            _vm.buyLicenseDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }