var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dataReady && _vm.dataIsMissing
        ? _c(
            "v-layout",
            { staticClass: "warningtip", attrs: { "fill-height": "" } },
            [
              _c("DataMissingDisplay", {
                attrs: { interestedIn: _vm.pageRouteHelper.ALARM_PROFILES },
                on: {
                  evtProfilesData: function($event) {
                    _vm.dataIsMissing = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        { attrs: { "fill-height": "" } },
        [
          !_vm.dataReady
            ? _c(
                "v-layout",
                {
                  staticStyle: { height: "65vh" },
                  attrs: {
                    column: "",
                    "align-center": "",
                    "justify-center": ""
                  }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "secondary", size: "48" }
                  }),
                  _c("p", { staticClass: "bus_normaltext mt-5" }, [
                    _vm._v(_vm._s(_vm.$t("general.actions.loading")))
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.dataReady && _vm.alarmProfileCount > 0
            ? _c(
                "v-layout",
                {
                  staticStyle: { "max-height": "1500px", width: "100%" },
                  attrs: { "fill-height": "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "listborder", attrs: { xs3: "" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c("v-text-field", {
                            staticClass: "default selected",
                            attrs: {
                              clearable: _vm.iconHelper.clearableIcon(
                                _vm.searchString
                              ),
                              placeholder: _vm.$t("general.search") + "..."
                            },
                            scopedSlots: _vm._u(
                              [
                                !_vm.iconHelper.clearableIcon(_vm.searchString)
                                  ? {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-icon",
                                            { staticClass: "searchicon" },
                                            [_vm._v("search")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  : null
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.searchString,
                              callback: function($$v) {
                                _vm.searchString = $$v
                              },
                              expression: "searchString"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list",
                        {
                          staticClass: "scroll-y profilelist",
                          attrs: { "two-line": "" }
                        },
                        [
                          _c("p", { staticClass: "bus_propertytitle" }, [
                            _vm._v(_vm._s(_vm.$t("biz.general.items.devices")))
                          ]),
                          _vm._l(
                            _vm.filteredProfiles(
                              _vm.searchString,
                              "CLOUDPROFILE"
                            ),
                            function(item) {
                              return [
                                _vm.isCloudProfileSelected(item)
                                  ? _c(
                                      "v-list-tile",
                                      {
                                        key: item.virtualFridgeId,
                                        staticClass: "bus_selectedgroup"
                                      },
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.name)
                                              }
                                            }),
                                            _c("v-list-tile-sub-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.model)
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-list-tile",
                                      {
                                        key: item.virtualFridgeId,
                                        staticClass: "bus_unselectedgroup",
                                        staticStyle: { "padding-top": "1px" },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectAlarmProfile(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.name)
                                              }
                                            }),
                                            _c("v-list-tile-sub-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.model)
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                              ]
                            }
                          ),
                          _vm._l(
                            _vm.filteredProfiles(
                              _vm.searchString,
                              "SMARTMODULEPROFILE"
                            ),
                            function(item) {
                              return [
                                _vm.isSmartModuleProfileSelected(item)
                                  ? _c(
                                      "v-list-tile",
                                      {
                                        key: item.virtualFridgeId,
                                        staticClass: "bus_selectedgroup"
                                      },
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.name)
                                              }
                                            }),
                                            _c("v-list-tile-sub-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.model)
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-list-tile",
                                      {
                                        key: item.virtualFridgeId,
                                        staticClass: "bus_unselectedgroup",
                                        staticStyle: { "padding-top": "1px" },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectAlarmProfile(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.name)
                                              }
                                            }),
                                            _c("v-list-tile-sub-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.model)
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                              ]
                            }
                          ),
                          _c("p", { staticClass: "bus_propertytitle mt-3" }, [
                            _vm._v(_vm._s(_vm.$t("biz.group.bsdbs")))
                          ]),
                          _vm._l(
                            _vm.filteredProfiles(
                              _vm.searchString,
                              "GATEWAYPROFILE"
                            ),
                            function(item) {
                              return [
                                _vm.isGatewayProfileSelected(item)
                                  ? _c(
                                      "v-list-tile",
                                      {
                                        key: item.gatewayId,
                                        staticClass: "bus_selectedgroup"
                                      },
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.name)
                                              }
                                            }),
                                            _c("v-list-tile-sub-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.model)
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-list-tile",
                                      {
                                        key: item.gatewayId,
                                        staticClass: "bus_unselectedgroup",
                                        staticStyle: { "padding-top": "1px" },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectAlarmProfile(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.name)
                                              }
                                            }),
                                            _c("v-list-tile-sub-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.model)
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                              ]
                            }
                          ),
                          _vm.hasCompanySensors
                            ? _c(
                                "p",
                                { staticClass: "bus_propertytitle mt-3" },
                                [_vm._v(_vm._s(_vm.$t("general.sensors")))]
                              )
                            : _vm._e(),
                          _vm.hasCompanySensors
                            ? _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.filteredProfiles(
                                      _vm.searchString,
                                      "SENSORPROFILE"
                                    ),
                                    function(item) {
                                      return [
                                        _vm.isSensorProfileSelected(item)
                                          ? _c(
                                              "v-list-tile",
                                              {
                                                key: item.sensorId,
                                                staticClass: "bus_selectedgroup"
                                              },
                                              [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c("v-list-tile-title", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.name
                                                        )
                                                      }
                                                    }),
                                                    _c(
                                                      "v-list-tile-sub-title",
                                                      {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getSensorType(
                                                              item.sensorType
                                                            )
                                                          )
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-list-tile",
                                              {
                                                key: item.sensorId,
                                                staticClass:
                                                  "bus_unselectedgroup",
                                                staticStyle: {
                                                  "padding-top": "1px"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.selectAlarmProfile(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c("v-list-tile-title", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.name
                                                        )
                                                      }
                                                    }),
                                                    _c(
                                                      "v-list-tile-sub-title",
                                                      {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.getSensorType(
                                                              item.sensorType
                                                            )
                                                          )
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs9: "" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", "ml-1": "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "bus_propertytitle",
                                  attrs: { grow: "" }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.selectedAlarmProfile.name) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-layout",
                        {
                          staticStyle: { "max-height": "85%" },
                          attrs: {
                            "fill-height": "",
                            column: "",
                            "pt-4": "",
                            "px-4": "",
                            "mb-4": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { xs12: "" }
                            },
                            [
                              _vm.isCloudProfileSelected()
                                ? _c("CloudAlarmDetails", {
                                    attrs: {
                                      virtualFridgeId:
                                        _vm.selectedAlarmProfile
                                          .virtualFridgeId,
                                      gatewayId:
                                        _vm.selectedAlarmProfile.gatewayId,
                                      selectedFridgeType:
                                        _vm.selectedFridgeType,
                                      track: _vm.track + "::CloudAlarmDetails"
                                    },
                                    on: {
                                      evtAlarmProfileCreated: function($event) {
                                        return _vm.loadAlarmProfiles()
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm.isSmartModuleProfileSelected()
                                ? _c("SmartModuleAlarmDetails", {
                                    attrs: {
                                      virtualFridgeId:
                                        _vm.selectedAlarmProfile
                                          .virtualFridgeId,
                                      selectedFridgeType:
                                        _vm.selectedFridgeType,
                                      track:
                                        _vm.track + "::SmartModuleAlarmDetails"
                                    },
                                    on: {
                                      evtSmartModuleProfileUpdated:
                                        _vm.refreshSmartModuleProfile
                                    }
                                  })
                                : _vm._e(),
                              _vm.isGatewayProfileSelected()
                                ? _c("GatewayAlarmDetails", {
                                    ref: "gatewayDetails",
                                    attrs: {
                                      alarmProfileId:
                                        _vm.selectedAlarmProfile.alarmProfileId,
                                      serialNumber:
                                        _vm.selectedAlarmProfile.serialNumber,
                                      name: _vm.selectedAlarmProfile.name,
                                      model: _vm.selectedAlarmProfile.model,
                                      gatewayId:
                                        _vm.selectedAlarmProfile.gatewayId,
                                      track: _vm.track + "::GatewayAlarmDetails"
                                    },
                                    on: {
                                      evtDefaultProfile:
                                        _vm.setGatewayProfileDefault
                                    }
                                  })
                                : _vm._e(),
                              _vm.isSensorProfileSelected()
                                ? _c("SensorAlarmDetails", {
                                    attrs: {
                                      currentSensor: _vm.selectedSensor,
                                      track: _vm.track + "::SensorAlarmDetails"
                                    },
                                    on: {
                                      evtAlarmProfileCreated: function($event) {
                                        return _vm.loadAlarmProfiles()
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }