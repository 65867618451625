import { render, staticRenderFns } from "./IconAlarm.vue?vue&type=template&id=645e4584&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/hau-bus-ui_master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('645e4584')) {
      api.createRecord('645e4584', component.options)
    } else {
      api.reload('645e4584', component.options)
    }
    module.hot.accept("./IconAlarm.vue?vue&type=template&id=645e4584&", function () {
      api.rerender('645e4584', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/general/icons/alarm/IconAlarm.vue"
export default component.exports