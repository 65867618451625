// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Point, TempSensorAlarmPoint } from './';

/**
 * @export
 * @interface TempSensorDataPointsObject
 */
export interface TempSensorDataPointsObject {
  /**
   * @type {Array<Point>}
   * @memberof TempSensorDataPointsObject
   */
  alarmCount?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof TempSensorDataPointsObject
   */
  alarmProfileCount?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof TempSensorDataPointsObject
   */
  averageTemperature?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof TempSensorDataPointsObject
   */
  highTemperature?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof TempSensorDataPointsObject
   */
  lowTemperature?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof TempSensorDataPointsObject
   */
  maxTemperature?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof TempSensorDataPointsObject
   */
  minTemperature?: Array<Point>;
  /**
   * @type {Array<TempSensorAlarmPoint>}
   * @memberof TempSensorDataPointsObject
   */
  tempSensorAlarms?: Array<TempSensorAlarmPoint>;
  /**
   * @type {string}
   * @memberof TempSensorDataPointsObject
   */
  thingId?: string;
}
