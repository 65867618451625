var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      !_vm.dataReady
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "85vh" },
              attrs: {
                slot: "content",
                column: "",
                xs12: "",
                "align-center": "",
                "justify-center": ""
              },
              slot: "content"
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "60" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.loading")))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady
        ? _c(
            "v-layout",
            {
              staticClass: "title",
              attrs: { slot: "header", "py-3": "" },
              slot: "header"
            },
            [_vm._v(_vm._s(_vm.$t("biz.main.home")))]
          )
        : _vm._e(),
      _vm.dataReady ? _c("v-divider") : _vm._e(),
      _vm.dataReady
        ? _c(
            "v-container",
            {
              staticStyle: { "max-width": "1400px" },
              attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" }
            },
            [
              _c(
                "v-layout",
                { attrs: { "fill-height": "", column: "" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "resize",
                          rawName: "v-resize",
                          value: _vm.onResize,
                          expression: "onResize"
                        }
                      ],
                      ref: "criticals"
                    },
                    [
                      _c("CriticalDevices", {
                        attrs: {
                          horizontal: true,
                          widthCritical: _vm.widthCritical
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { "px-0": "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          staticStyle: { height: "50%", "max-height": "50%" },
                          attrs: { wrap: "", row: "" }
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: { "min-height": "450px" },
                              attrs: { xs12: "" }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { height: "100%" } },
                                [_c("Devices")],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticStyle: { "min-height": "450px" },
                              attrs: { xs12: "", md6: "" }
                            },
                            [
                              _c(
                                "v-card",
                                { attrs: { height: "100%" } },
                                [_c("Alarms")],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticStyle: { "min-height": "450px" },
                              attrs: { xs12: "", md6: "" }
                            },
                            [
                              !_vm.hasCompanySensors
                                ? _c(
                                    "v-card",
                                    { attrs: { height: "100%" } },
                                    [_c("Heartbeat")],
                                    1
                                  )
                                : _c(
                                    "v-card",
                                    { attrs: { height: "100%" } },
                                    [_c("SensorHeartbeat")],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }