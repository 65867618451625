var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c("BaseWindowPage", {
        attrs: { trackCancel: "SetupNetwork::Close" },
        on: {
          evtCancel: function($event) {
            _vm.cancelDialogShown = true
          }
        },
        scopedSlots: _vm._u(
          [
            !_vm.connectionSelected
              ? {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(_vm._s(_vm.$t("biz.setup.typenetwork.title")))
                    ]
                  },
                  proxy: true
                }
              : null,
            !_vm.connectionSelected
              ? {
                  key: "content",
                  fn: function() {
                    return [
                      _c("p", {
                        staticClass: "bus_normaltext mb-5",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("biz.setup.typenetwork.text1")
                          )
                        }
                      }),
                      _c("h3", {
                        staticClass: "mb-2",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("biz.setup.typenetwork.title2")
                          )
                        }
                      }),
                      _c("p", {
                        staticClass: "bus_normaltext mb-1",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("biz.setup.typenetwork.text2")
                          )
                        }
                      }),
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            nowrap: "",
                            "align-center": "",
                            "justify-center": "",
                            "mt-5": ""
                          }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                margin: "20px"
                              },
                              attrs: {
                                hover: "",
                                height: "190px",
                                width: "200px",
                                color: "secondary",
                                flat: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setNetworkType(true)
                                }
                              }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "align-center": "",
                                    "justify-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-img",
                                    {
                                      attrs: {
                                        src: require("@/assets/wifi-onboarding.png"),
                                        "max-width": "150"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            slot: "placeholder",
                                            "fill-height": "",
                                            "align-center": "",
                                            "justify-center": "",
                                            "ma-0": ""
                                          },
                                          slot: "placeholder"
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color: "grey lighten-5"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { "mt-2": "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "font-weight-bold",
                                      staticStyle: { color: "white" },
                                      attrs: { "justify-center": "" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("biz.setup.typenetwork.wlan")
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                margin: "20px"
                              },
                              attrs: {
                                hover: "",
                                height: "190px",
                                width: "200px",
                                color: "secondary",
                                flat: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setNetworkType(false)
                                }
                              }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "align-center": "",
                                    "justify-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-img",
                                    {
                                      attrs: {
                                        src: require("@/assets/lan-onboarding.png"),
                                        "max-width": "150"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            slot: "placeholder",
                                            "fill-height": "",
                                            "align-center": "",
                                            "justify-center": "",
                                            "ma-0": ""
                                          },
                                          slot: "placeholder"
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color: "grey lighten-5"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { "mt-2": "" } },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass: "font-weight-bold",
                                      staticStyle: { color: "white" },
                                      attrs: { "justify-center": "" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("biz.setup.typenetwork.lan")
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.cancelDialogShown,
          title: _vm.$t("biz.setup.abort.title"),
          message: _vm.$t("biz.setup.abort.description"),
          track: "SetupNetwork::AbortSetup"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmAbort()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelAbort()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }