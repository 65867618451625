var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "justify-center": "" } },
    [
      _c("AlarmList", {
        attrs: {
          selectedGateway: _vm.selectedGateway,
          singleDeviceSelected: _vm.singleDeviceSelected,
          showDeviceFilter: false,
          track: "GatewayAlarms::AlarmList"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }