var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "icons/24/ic_telemetry_not_available_red_bg",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("circle", {
            staticStyle: { fill: "var(--v-bus_red-base)" },
            attrs: { cx: "16", cy: "16", r: "16" }
          }),
          _c("path", {
            staticStyle: { fill: "var(--v-bus_white-base)" },
            attrs: {
              d:
                "M 11.283 11.793 L 11.283 20.442 L 8.924 20.442 L 8.924 11.793 L 11.283 11.793 Z M 15.214 7.075 L 15.214 20.442 L 12.855 20.442 L 12.855 7.075 L 15.214 7.075 Z M 19.146 16.668 L 19.146 20.442 L 16.787 20.442 L 16.787 16.668 L 19.146 16.668 Z M 23.077 14.938 L 23.077 20.442 L 20.718 20.442 L 20.718 14.938 L 23.077 14.938 Z M 7.351 24.924 L 7.351 22.407 L 24.65 22.407 L 24.65 24.924 L 7.351 24.924 Z"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }