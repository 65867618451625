var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticStyle: { "padding-bottom": "30px" }, attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.main.notifications")))
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "align-items: end", attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c("v-flex", [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.alarms.notificationtext")))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", "pt-3": "" } },
        [
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.communication.email")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs8: "" } },
            _vm._l(_vm.emailList, function(item, index) {
              return _c("p", { key: index, staticClass: "bus_normaltext" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(item.fullName) +
                    ", " +
                    _vm._s(item.email) +
                    "\n      "
                )
              ])
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.communication.sms")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs8: "" } },
            _vm._l(_vm.smsList, function(item, index) {
              return _c("div", { key: index }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(item.fullName) + ", " + _vm._s(item.mobile))
                ]),
                !item.mobileValid || !item.mobile
                  ? _c("p", {
                      class: _vm.getMobileInvalidClass(item),
                      domProps: {
                        innerHTML: _vm._s(_vm.getMobileInvalidText(item))
                      },
                      on: {
                        click: function($event) {
                          return _vm.routeToSettings(item)
                        }
                      }
                    })
                  : _vm._e()
              ])
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "" } },
        [
          _c("v-flex", { attrs: { xs4: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.communication.voicecall")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs8: "" } },
            _vm._l(_vm.voiceCallList, function(item, index) {
              return _c("div", { key: index }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(item.fullName) + ", " + _vm._s(item.mobile))
                ]),
                !item.mobileValid || !item.mobile
                  ? _c("p", {
                      class: _vm.getMobileInvalidClass(item),
                      domProps: {
                        innerHTML: _vm._s(_vm.getMobileInvalidText(item))
                      },
                      on: {
                        click: function($event) {
                          return _vm.routeToSettings(item)
                        }
                      }
                    })
                  : _vm._e()
              ])
            }),
            0
          )
        ],
        1
      ),
      _vm.isFullAdminUser && !_vm.hasValidAddonLicense
        ? _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c("v-flex", { attrs: { grow: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.$t("general.communication.licenses")))
                ])
              ]),
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: _vm.track + "::BuyLicense",
                          expression: "track + '::BuyLicense'"
                        }
                      ],
                      staticClass: "pl-1",
                      attrs: { color: "secondary", outline: "", depressed: "" },
                      on: {
                        click: function($event) {
                          _vm.buyLicenseDialog = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v(_vm._s(_vm.getRightArrowIcon()))
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("biz.settings.buylicenses")) +
                          "\n      "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("AlarmNotificationDialog", {
        attrs: {
          editorShown: _vm.editorShown,
          allUsers: _vm.allUsers,
          editableUsers: _vm.editableUsers,
          disabledTabs: _vm.disabledTabs,
          track: _vm.track + "::AlarmNotificationDialog"
        },
        on: {
          evtEditorClose: function($event) {
            return _vm.closeEditor()
          },
          evtEditorSave: _vm.saveUsers
        }
      }),
      _c("BuyLicenseDialog", {
        attrs: { showDialog: _vm.buyLicenseDialog },
        on: {
          evtClose: function($event) {
            _vm.buyLicenseDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }