var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", nowrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(
                  _vm._s(_vm.$t("biz.sensors.sensorinfo.manufacturertitle"))
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", nowrap: "", "pt-3": "", "pb-3": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.sensors.sensorinfo.poweredby")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.sensors.sensorinfo.testo")))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", nowrap: "", "pb-3": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.sensors.sensorinfo.identification")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.sensorId))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { row: "", nowrap: "", "pb-3": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.sensors.sensorinfo.sensortype")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.sensorType))
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }