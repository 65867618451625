var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: {
      title: _vm.dialogTitle,
      dialogShown: _vm.dialogShown,
      track: "SensorAssigned"
    },
    on: {
      evtClose: function($event) {
        return _vm.confirmAbort()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("p", [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.dialogMessage) } })
            ]),
            _vm.assignedGateway
              ? _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("biz.sensors.sensorassigneddialog.assignedsch")
                    ) +
                      ": " +
                      _vm._s(_vm.assignedGateway)
                  )
                ])
              : _vm._e(),
            _vm.assignedMeasureSite
              ? _c("p", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t(
                          "biz.sensors.sensorassigneddialog.assignedmeasuresite"
                        )
                      ) +
                      ": " +
                      _vm._s(_vm.assignedMeasureSite) +
                      "\n    "
                  )
                ])
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "buttons",
        fn: function() {
          return [
            !_vm.sameGateway
              ? _c(
                  "v-btn",
                  {
                    attrs: { depressed: "", color: "secondary", outline: "" },
                    on: {
                      click: function($event) {
                        return _vm.showSensorPairedDialog()
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "pr-1", attrs: { color: "secondary" } },
                      [_vm._v("help_outline")]
                    ),
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t("biz.sensors.sensorassigneddialog.resetdevice")
                        ) +
                        "\n    "
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-layout",
              { attrs: { row: "", nowrap: "" } },
              [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: "SensorAssigned::ToDashboard",
                        expression: "'SensorAssigned::ToDashboard'"
                      }
                    ],
                    attrs: { depressed: "", outline: "", color: "secondary" },
                    on: {
                      click: function($event) {
                        return _vm.toDashboard()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("general.home.goto")))]
                ),
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: "SensorAssigned::ToSensor",
                        expression: "'SensorAssigned::ToSensor'"
                      }
                    ],
                    attrs: { depressed: "", color: "secondary" },
                    on: {
                      click: function($event) {
                        return _vm.toSensor()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "biz.sensors.sensorassigneddialog.sensorproperties"
                        )
                      )
                    )
                  ]
                ),
                _c("SensorPairedDialog", {
                  attrs: { dialogShown: _vm.showSensorPaired },
                  on: {
                    evtClose: function($event) {
                      _vm.showSensorPaired = false
                    }
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }