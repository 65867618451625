var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: {
      title: _vm.$t("biz.settings.buylicenses"),
      dialogShown: _vm.showDialog
    },
    on: {
      evtClose: function($event) {
        return _vm.closeDialog()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("p", [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("biz.settings.buylicensedesc")) + " "),
                _c("br"),
                _c("br")
              ]),
              _c("span", [_vm._v(_vm._s(_vm.$t("biz.help.servicetext2")))])
            ]),
            _vm.contactsShown
              ? _c("ContactInfo", {
                  attrs: {
                    serialNumber: _vm.serialNumber,
                    buyLicenseSupport: true
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "buttons",
        fn: function() {
          return [
            _c(
              "v-layout",
              [
                _c(
                  "v-flex",
                  { staticStyle: { "text-align": "end" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticStyle: {
                          "margin-left": "5px",
                          "margin-bottom": "5px"
                        },
                        attrs: {
                          href: _vm.landingPageLink,
                          target: "_blank",
                          color: "primary",
                          depressed: ""
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("biz.main.buylicensehere")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }