var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c(
        "div",
        { staticClass: "window-container" },
        [
          _c(
            "v-window",
            {
              staticStyle: { height: "inherit" },
              attrs: { touchless: "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 1 } },
                [
                  _c("BaseWindowPage", {
                    attrs: {
                      trackCancel: "WifiResetStepper::Close",
                      dialogShown: _vm.cancelDialogShown,
                      dialogType: _vm.dialogType
                    },
                    on: {
                      evtCancel: function($event) {
                        return _vm.abortProcess()
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v(_vm._s(_vm.$t("biz.wifireset.title")))]
                        },
                        proxy: true
                      },
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "bus_subheading" }, [
                              _vm._v(_vm._s(_vm.$t("biz.wifireset.title")))
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.$t("biz.wifireset.text1"))
                              }
                            }),
                            _c(
                              "v-img",
                              {
                                staticClass: "bus_img_center pt-5",
                                attrs: {
                                  contain: "",
                                  src: require("@/assets/wifiReset1.png"),
                                  "max-height": "370"
                                }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      slot: "placeholder",
                                      "fill-height": "",
                                      "align-center": "",
                                      "justify-center": "",
                                      "ma-0": ""
                                    },
                                    slot: "placeholder"
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "grey lighten-5"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 2 } },
                [
                  _c("BaseWindowPage", {
                    attrs: {
                      trackCancel: "WifiResetStepper::Close",
                      dialogShown: _vm.cancelDialogShown,
                      dialogType: _vm.dialogType
                    },
                    on: {
                      evtCancel: function($event) {
                        return _vm.abortProcess()
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v(_vm._s(_vm.$t("biz.wifireset.title")))]
                        },
                        proxy: true
                      },
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "bus_subheading" }, [
                              _vm._v(_vm._s(_vm.$t("biz.wifireset.title")))
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.$t("biz.wifireset.text2"))
                              }
                            }),
                            _c(
                              "v-img",
                              {
                                staticClass: "bus_img_center",
                                attrs: {
                                  src: require("@/assets/bsdb-setup-lan2.png")
                                }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      slot: "placeholder",
                                      "fill-height": "",
                                      "align-center": "",
                                      "justify-center": "",
                                      "ma-0": ""
                                    },
                                    slot: "placeholder"
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "grey lighten-5"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 3 } },
                [
                  _c("BaseWindowPage", {
                    attrs: {
                      trackCancel: "WifiResetStepper::Close",
                      dialogShown: _vm.cancelDialogShown,
                      dialogType: _vm.dialogType
                    },
                    on: {
                      evtCancel: function($event) {
                        return _vm.abortProcess()
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [_vm._v(_vm._s(_vm.$t("biz.wifireset.title")))]
                        },
                        proxy: true
                      },
                      {
                        key: "description",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "bus_subheading" }, [
                              _vm._v(_vm._s(_vm.$t("biz.wifireset.title")))
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("biz.setup.establish_wan.text")
                                )
                              }
                            }),
                            _c("v-flex", {
                              staticClass: "bus_normaltext",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("biz.setup.establish_wan.text2")
                                )
                              }
                            }),
                            _c(
                              "v-layout",
                              { attrs: { "justify-center": "" } },
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/sc-label-help.png"),
                                    "max-height": "450",
                                    "max-width": "400"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "helplink",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track",
                                    value: "WifiResetStepper::HelpNetwork",
                                    expression:
                                      "'WifiResetStepper::HelpNetwork'"
                                  }
                                ],
                                staticStyle: { "margin-left": "25px" },
                                attrs: {
                                  depressed: "",
                                  color: "secondary",
                                  outline: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showNetworkHelpDialog = !_vm.showNetworkHelpDialog
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-1",
                                    attrs: { color: "secondary" }
                                  },
                                  [_vm._v("help_outline")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "biz.setup.establish_wan.nonetwork"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "navigation-place" },
            [
              _c("v-footer", { attrs: { color: "white" } }, [
                _c(
                  "div",
                  {
                    staticClass: "navigation-content",
                    attrs: { "grid-list-lg": "" }
                  },
                  [
                    _c("BackNextMenu", {
                      attrs: { stepCount: 3, step: _vm.step },
                      on: {
                        "update:step": function($event) {
                          _vm.step = $event
                        },
                        evtPrevious: _vm.previousStep
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "finalStep",
                          fn: function() {
                            return [
                              _c("Next", {
                                attrs: {
                                  icon: false,
                                  track: "WifiResetStepper::Next"
                                },
                                on: {
                                  evtNext: function($event) {
                                    return _vm.nextExternalStep()
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("SimpleDialog", {
        attrs: {
          dialogShown: _vm.showNetworkHelpDialog,
          title: _vm.$t("biz.setup.networkhelpdialog.title"),
          message: _vm.$t("biz.setup.networkhelpdialog.text"),
          track: "WifiResetStepper::CloseNetworkHelpDialog"
        },
        on: {
          evtClose: function($event) {
            _vm.showNetworkHelpDialog = false
          }
        }
      }),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.cancelDialogShown,
          title: _vm.$t("biz.setup.abort.title"),
          message: _vm.$t("biz.setup.abort.description"),
          track: "WifiResetStepper::AbortSetup"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmResetAbort()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelAbort()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }