var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      _c(
        "v-layout",
        {
          staticClass: "title",
          attrs: { slot: "header", "py-3": "" },
          slot: "header"
        },
        [_vm._v(_vm._s(_vm.$t("biz.main.news")))]
      ),
      _c("v-divider"),
      _c(
        "v-container",
        { attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" } },
        [
          _c("CriticalDevicesPage", {
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "v-layout",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.messagesLoading,
                            expression: "messagesLoading"
                          }
                        ],
                        staticStyle: { height: "85vh" },
                        attrs: {
                          column: "",
                          "align-center": "",
                          "justify-center": ""
                        }
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            indeterminate: "",
                            color: "secondary",
                            size: "48"
                          }
                        }),
                        _c("p", { staticClass: "bus_normaltext mt-5" }, [
                          _vm._v(_vm._s(_vm.$t("general.actions.loading")))
                        ])
                      ],
                      1
                    ),
                    _vm.dataIsMissing
                      ? _c(
                          "v-layout",
                          {
                            staticStyle: { height: "65vh" },
                            attrs: {
                              column: "",
                              "align-center": "",
                              "justify-center": ""
                            }
                          },
                          [
                            _c("DataMissingDisplay", {
                              attrs: {
                                interestedIn: _vm.pageRouteHelper.MESSAGES
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-layout",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !_vm.messagesLoading && !_vm.dataIsMissing,
                                expression: "!messagesLoading && !dataIsMissing"
                              }
                            ],
                            attrs: { row: "" }
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                staticStyle: {
                                  "border-right":
                                    "1px solid var(--v-neutral20-base)"
                                },
                                attrs: { xs4: "", "pr-0": "" }
                              },
                              [
                                _c(
                                  "v-card-title",
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { column: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "pa-0",
                                            attrs: { xs4: "" }
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticClass: "default selected",
                                              attrs: {
                                                clearable: _vm.iconHelper.clearableIcon(
                                                  _vm.searchString
                                                ),
                                                placeholder:
                                                  _vm.$t("general.search") +
                                                  "..."
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  !_vm.iconHelper.clearableIcon(
                                                    _vm.searchString
                                                  )
                                                    ? {
                                                        key: "append",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "searchicon"
                                                              },
                                                              [_vm._v("search")]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    : null
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.searchString,
                                                callback: function($$v) {
                                                  _vm.searchString = $$v
                                                },
                                                expression: "searchString"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c("MessageList", {
                                  ref: "messageList",
                                  attrs: {
                                    messages: _vm.filteredMessages,
                                    edit: false,
                                    selectedMessages: []
                                  },
                                  on: {
                                    evtSelected: _vm.changeDisplayedMessage
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-divider", { attrs: { vertical: "" } }),
                            _c(
                              "v-flex",
                              { attrs: { xs8: "", flat: "", "ma-4": "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.hasMessages,
                                        expression: "!hasMessages"
                                      }
                                    ],
                                    staticStyle: {
                                      "font-size": "30px",
                                      color: "LightGray"
                                    },
                                    attrs: {
                                      column: "",
                                      "align-center": "",
                                      "justify-center": "",
                                      "fill-height": ""
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { size: "200" } }, [
                                      _vm._v("$vuetify.icons.bus_envelope")
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("general.nodata")))
                                    ])
                                  ],
                                  1
                                ),
                                _c("MessagesEntry", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.hasMessages,
                                      expression: "hasMessages"
                                    }
                                  ],
                                  attrs: { message: _vm.displayedMessage }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }