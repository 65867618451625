// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApplianceInfo } from './';

/**
 * @export
 * @interface Manuals
 */
export interface Manuals {
  /**
   * @type {ApplianceInfo}
   * @memberof Manuals
   */
  applianceInfo?: ApplianceInfo;
  /**
   * @type {Array<string>}
   * @memberof Manuals
   */
  installationManuals?: Array<string>;
  /**
   * @type {Array<string>}
   * @memberof Manuals
   */
  instructionManuals?: Array<string>;
}
