var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-actions",
    { staticClass: "justify-space-between pb-4" },
    [
      _c(
        "v-btn",
        {
          attrs: {
            depressed: "",
            color: "secondary",
            outline: "",
            disabled: _vm.isPreviousDisabled()
          },
          on: {
            click: function($event) {
              return _vm.previousStep()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("general.back")))]
      ),
      _vm.stepCount > 1 && _vm.isShown
        ? _c(
            "v-item-group",
            _vm._l(_vm.stepCount, function(n) {
              return _c(
                "v-item",
                { key: n },
                [
                  _vm.currentStep === n
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "pa-1",
                          staticStyle: { "vertical-align": "middle" },
                          attrs: { size: "14" }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.IconHelper.getStepperSelectedStepIcon()
                              ) +
                              "\n      "
                          )
                        ]
                      )
                    : _c(
                        "v-icon",
                        {
                          staticClass: "pa-1",
                          staticStyle: { "vertical-align": "middle" },
                          attrs: { size: "14" }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.IconHelper.getStepperUnselectedStepIcon()
                              ) +
                              "\n      "
                          )
                        ]
                      )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.currentStep < _vm.stepCount
        ? _c(
            "v-btn",
            {
              attrs: {
                depressed: "",
                disabled: _vm.isNextDisabled(),
                color: "primary",
                value: _vm.currentStep < _vm.stepCount
              },
              on: {
                click: function($event) {
                  return _vm.nextStep()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("general.next")))]
          )
        : _vm._t("finalStep")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }