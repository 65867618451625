// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
  /**
   * @type {string}
   * @memberof UserResponse
   */
  companyId?: string;
  /**
   * @type {string}
   * @memberof UserResponse
   */
  country?: string;
  /**
   * @type {string}
   * @memberof UserResponse
   */
  firstName?: string;
  /**
   * @type {boolean}
   * @memberof UserResponse
   */
  internalUser?: boolean;
  /**
   * @type {string}
   * @memberof UserResponse
   */
  lastName?: string;
  /**
   * @type {string}
   * @memberof UserResponse
   */
  mail?: string;
  /**
   * @type {string}
   * @memberof UserResponse
   */
  mobile?: string;
  /**
   * @type {boolean}
   * @memberof UserResponse
   */
  mobileValid?: boolean;
  /**
   * @type {string}
   * @memberof UserResponse
   */
  telephoneNumber?: string;
  /**
   * @type {boolean}
   * @memberof UserResponse
   */
  telephoneNumberValid?: boolean;
  /**
   * @type {string}
   * @memberof UserResponse
   */
  userPrincipalName?: string;
}
