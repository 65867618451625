var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      !_vm.dataReady
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "85vh" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.loading")))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady
        ? _c(
            "v-flex",
            { attrs: { xs12: "", "pt-4": "", "pb-3": "", "pl-4": "" } },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.settings.usermanagement")))
              ])
            ]
          )
        : _vm._e(),
      _vm.dataReady
        ? _c(
            "v-layout",
            { staticClass: "userlistcard", attrs: { "fill-height": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "leftpanel", attrs: { xs3: "", "pr-0": "" } },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-text-field", {
                        staticClass: "default selected",
                        attrs: {
                          clearable: _vm.iconHelper.clearableIcon(
                            _vm.searchString
                          ),
                          placeholder: _vm.$t("general.search") + "..."
                        },
                        scopedSlots: _vm._u(
                          [
                            !_vm.iconHelper.clearableIcon(_vm.searchString)
                              ? {
                                  key: "append",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        { staticClass: "searchicon" },
                                        [_vm._v("search")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.searchString,
                          callback: function($$v) {
                            _vm.searchString = $$v
                          },
                          expression: "searchString"
                        }
                      })
                    ],
                    1
                  ),
                  _c("h4", { staticClass: "ml-3 mb-3 mt-0" }, [
                    _vm._v(
                      _vm._s(_vm.$t("general.colleagues")) +
                        " (" +
                        _vm._s(_vm.colleagueList.length) +
                        ")"
                    )
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-list",
                    {
                      staticClass: "scroll-y",
                      staticStyle: { "padding-left": "15px" },
                      attrs: { "two-line": "" }
                    },
                    [
                      _vm._l(_vm.filteredColleagues(_vm.searchString), function(
                        item
                      ) {
                        return [
                          _c(
                            "v-list-tile",
                            {
                              key: "permission_" + item.upn,
                              class: _vm.colleagueHighlight(item),
                              staticStyle: {
                                "margin-top": "1px",
                                "padding-left": "10px"
                              },
                              attrs: { id: item.upn },
                              on: {
                                click: function($event) {
                                  return _vm.selectColleague(item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(item.fullName))
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tc(
                                          "biz.settings.appliancesassigned",
                                          _vm.getAppliancesByVirtualFridgeIds(
                                            item.permittedDevices
                                          ).length
                                        )
                                      )
                                    )
                                  ]),
                                  _c("v-list-tile-sub-title", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getUserSubtitle(item)
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectedColleague !== null,
                      expression: "selectedColleague !== null"
                    }
                  ],
                  staticClass: "rightpanel mt-4",
                  attrs: { xs9: "" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("div", { staticClass: "bus_propertytitle" }, [
                        _vm._v(_vm._s(_vm.$t("biz.settings.userinformation")))
                      ]),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "bus_normaltext",
                              attrs: { xs2: "" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("general.communication.name"))
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "bus_normaltext",
                              attrs: { xs10: "" }
                            },
                            [_vm._v(_vm._s(_vm.selectedColleague.fullName))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "bus_normaltext",
                              attrs: { xs2: "" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("general.communication.email"))
                              )
                            ]
                          ),
                          _c("v-flex", { attrs: { xs10: "" } }, [
                            _c(
                              "span",
                              {
                                staticClass: "bus_normaltext",
                                attrs: {
                                  href: "mailto:" + _vm.selectedColleague.email
                                }
                              },
                              [_vm._v(_vm._s(_vm.selectedColleague.email))]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "bus_normaltext",
                              attrs: { xs2: "" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("general.communication.mobile"))
                              )
                            ]
                          ),
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                xs7: "",
                                column: "",
                                "mt-0": "",
                                "ml-0": ""
                              }
                            },
                            [
                              _c("v-flex", { staticClass: "bus_normaltext" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getMobileText(_vm.selectedColleague)
                                  )
                                )
                              ]),
                              !_vm.selectedColleague.mobileValid ||
                              !_vm.selectedColleague.mobile
                                ? _c("p", {
                                    class: _vm.getMobileInvalidClass(
                                      _vm.selectedColleague
                                    ),
                                    staticStyle: { "margin-left": "8px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getMobileInvalidText(
                                          _vm.selectedColleague
                                        )
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-flex",
                            {
                              staticClass: "align-items: end",
                              attrs: { shrink: "" }
                            },
                            [
                              _vm.isAdminUser
                                ? _c("Edit", {
                                    attrs: {
                                      track:
                                        "UserPermissions::ShowPermissionsDialog"
                                    },
                                    on: {
                                      evtEdit: function($event) {
                                        return _vm.showPhoneNumberEditor()
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "bus_normaltext",
                              attrs: { xs2: "" }
                            },
                            [_vm._v(_vm._s(_vm.$t("general.role")))]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "bus_normaltext",
                              attrs: { xs10: "" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.selectedColleague.isAdmin
                                    ? _vm.$t("general.usertypes.admin")
                                    : _vm.$t("general.usertypes.user")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value: "UserPermissions::ShowPermissionsDialog",
                              expression:
                                "'UserPermissions::ShowPermissionsDialog'"
                            }
                          ],
                          staticClass: "mt-2 ml-0",
                          attrs: {
                            disabled: _vm.selectedColleague.isAdmin,
                            outline: "",
                            color: "secondary",
                            depressed: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.permissionDialogShown = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("biz.settings.editcolleagues")))]
                      )
                    ],
                    1
                  ),
                  _vm.selectedColleague.permittedDevices.length > 0
                    ? _c("div", { staticClass: "expansioncontainer" }, [
                        _c(
                          "div",
                          {
                            staticClass: "mb-2 bus_propertytitle",
                            staticStyle: { height: "40px" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("biz.settings.permittedappliances"))
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "pa-2 expansionpanel" },
                          [
                            _c(
                              "v-expansion-panel",
                              {
                                staticStyle: {
                                  border: "1px solid var(--v-neutral20-base)"
                                }
                              },
                              _vm._l(
                                _vm.groupsWithPermittedAppliances,
                                function(group) {
                                  return _c(
                                    "v-expansion-panel-content",
                                    { key: group.name },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "header" },
                                          slot: "header"
                                        },
                                        [
                                          group.defaultGroup
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "biz.groupeditor.default"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(_vm._s(group.name))
                                              ])
                                        ]
                                      ),
                                      _c("v-divider"),
                                      _vm._l(
                                        _vm.userAppliancesInGroup(group),
                                        function(app, i) {
                                          return _c(
                                            "v-card",
                                            {
                                              key: "" + app.name + i,
                                              staticClass: "panelinside"
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                { staticClass: "ml-4 my-2" },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        column: "",
                                                        shrink: ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-flex", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "font-weight-bold"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(app.name) +
                                                                " (" +
                                                                _vm._s(
                                                                  _vm.getMeasureSiteType(
                                                                    app
                                                                  )
                                                                ) +
                                                                ")"
                                                            )
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticStyle: {
                                                              color:
                                                                "var(--v-bus_grey-base)"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(app.model)
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _vm.getSensorsByVirtualFridgeIds(
                                                        app.virtualFridgeIds
                                                      ).length > 0
                                                        ? _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "pt-0",
                                                              staticStyle: {
                                                                color:
                                                                  "var(--v-bus_grey-base)"
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$tc(
                                                                      "biz.sensors.sensors",
                                                                      _vm.getSensorsByVirtualFridgeIds(
                                                                        app.virtualFridgeIds
                                                                      ).length
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              _c("br"),
                                                              _vm._l(
                                                                _vm.getSensorsByVirtualFridgeIds(
                                                                  app.virtualFridgeIds
                                                                ),
                                                                function(
                                                                  sensor
                                                                ) {
                                                                  return _c(
                                                                    "span",
                                                                    {
                                                                      key:
                                                                        sensor.sensorId
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          sensor.name
                                                                        ) + " "
                                                                      ),
                                                                      _c("br")
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                }
                              ),
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c("PermissionsDialog", {
                    attrs: {
                      dialogShown: _vm.permissionDialogShown,
                      applianceGroups: _vm.applianceGroups,
                      selectedUserUPN: _vm.selectedColleague.upn,
                      userAppliancePermissions:
                        _vm.selectedColleague.permittedDevices,
                      track: "UserPermissions::PermissionsDialog"
                    },
                    on: {
                      evtSave: function($event) {
                        return _vm.updatePermissionChanges()
                      },
                      evtClose: function($event) {
                        _vm.permissionDialogShown = false
                      }
                    }
                  }),
                  _c("PhoneNumberEditDialog", {
                    attrs: {
                      dialogShown: _vm.phoneNumberEditorShown,
                      upn: _vm.selectedColleague.upn,
                      mobilePhoneNumber: _vm.selectedColleague.mobile,
                      track: "GeneralSettings"
                    },
                    on: {
                      evtClose: function($event) {
                        return _vm.updatePhoneChanges()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }