var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", nowrap: "" } },
        [
          _c("Cancel", {
            staticStyle: { "margin-right": "10px" },
            attrs: {
              cancelDisabled: _vm.cancelDisabled,
              track: _vm.track,
              icon: false
            },
            on: { evtCancel: _vm.onCancelPressed }
          }),
          _c("Ok", {
            attrs: {
              okDisabled: _vm.okDisabled,
              track: _vm.track,
              icon: false
            },
            on: { evtOk: _vm.onOkPressed }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }