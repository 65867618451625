import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import IconAlarm from '@/components/general/icons/alarm/IconAlarm.vue';
import IconNewAlarm from '@/components/general/icons/alarm/IconNewAlarm.vue';

// Dashboard/Navigation Bar
import IconNavigationMenu from '@/components/general/icons/navigation/IconMenu.vue';
import IconNavigationDashboard from '@/components/general/icons/navigation/IconDashboard.vue';
import IconNavigationAlarm from '@/components/general/icons/navigation/IconAlarm.vue';
import IconNavigationMessage from '@/components/general/icons/navigation/IconMessage.vue';
import IconNavigationReport from '@/components/general/icons/navigation/IconReport.vue';
import IconNavigationAppliance from '@/components/general/icons/navigation/IconAppliance.vue';
import IconNavigationEditor from '@/components/general/icons/navigation/IconEditor.vue';
import IconNavigationSettings from '@/components/general/icons/navigation/IconSettings.vue';
import IconNavigationHelp from '@/components/general/icons/navigation/IconHelp.vue';
import IconNavigationUser from '@/components/general/icons/navigation/IconUser.vue';

// Sensor
import IconSensorDoor from '@/components/general/icons/sensors/IconSensorDoor.vue';
import IconSensorDoorWhite from '@/components/general/icons/sensors/IconSensorDoorWhite.vue';
import IconSensorTemperature from '@/components/general/icons/sensors/IconSensorTemperature.vue';
import IconSensorTemperatureWhite from '@/components/general/icons/sensors/IconSensorTemperatureWhite.vue';
import IconSensorBadStrength from '@/components/general/icons/sensors/IconSensorBadStrength.vue';
import IconSensorGoodStrength from '@/components/general/icons/sensors/IconSensorGoodStrength.vue';
import IconSensorVeryGoodStrength from '@/components/general/icons/sensors/IconSensorVeryGoodStrength.vue';
import IconSensorBatteryGood from '@/components/general/icons/sensors/IconSensorBatteryGood.vue';
import IconSensorBatteryMedium from '@/components/general/icons/sensors/IconSensorBatteryMedium.vue';
import IconSensorBatteryLow from '@/components/general/icons/sensors/IconSensorBatteryLow.vue';
import IconSensorFlag from '@/components/general/icons/sensors/IconSensorFlag.vue';
// Alarm Icons on and off
import IconAlarmTemperatureOn from '@/components/general/icons/alarm/IconAlarmTemperatureOn.vue';
import IconAlarmTemperatureOff from '@/components/general/icons/alarm/IconAlarmTemperatureOff.vue';

import IconAlarmHeartbeatOn from '@/components/general/icons/alarm/IconAlarmHeartbeatOn.vue';
import IconAlarmHeartbeatOff from '@/components/general/icons/alarm/IconAlarmHeartbeatOff.vue';

import IconAlarmCloudOn from '@/components/general/icons/alarm/IconAlarmCloudOn.vue';
import IconAlarmCloudOff from '@/components/general/icons/alarm/IconAlarmCloudOff.vue';

import IconAlarmDoorOn from '@/components/general/icons/alarm/IconAlarmDoorOn.vue';
import IconAlarmDoorOff from '@/components/general/icons/alarm/IconAlarmDoorOff.vue';

import IconAlarmPowerOn from '@/components/general/icons/alarm/IconAlarmPowerOn.vue';
import IconAlarmPowerOff from '@/components/general/icons/alarm/IconAlarmPowerOff.vue';

import IconAlarmDeviceOn from '@/components/general/icons/alarm/IconAlarmDeviceOn.vue';
import IconAlarmDeviceOff from '@/components/general/icons/alarm/IconAlarmDeviceOff.vue';

import IconAlarmBatteryOn from '@/components/general/icons/alarm/IconAlarmBatteryOn.vue';
import IconAlarmBatteryOff from '@/components/general/icons/alarm/IconAlarmBatteryOff.vue';

import IconAlarmBatteryPowerOn from '@/components/general/icons/alarm/IconAlarmBatteryPowerOn.vue';
import IconAlarmBatteryPowerOff from '@/components/general/icons/alarm/IconAlarmBatteryPowerOff.vue';

// Icons are shown on the help screen
import IconHelpAlarmDevice from '@/components/general/icons/alarm/help/IconAlarmDevice.vue';
import IconHelpAlarmDoor from '@/components/general/icons/alarm/help/IconAlarmDoor.vue';
import IconHelpAlarmHeartbeat from '@/components/general/icons/alarm/help/IconAlarmHeartbeat.vue';
import IconHelpAlarmPower from '@/components/general/icons/alarm/help/IconAlarmPower.vue';
import IconHelpAlarmTemperature from '@/components/general/icons/alarm/help/IconAlarmTemperature.vue';
import IconHelpAlarmBattery from '@/components/general/icons/alarm/help/IconAlarmBattery.vue';
import IconHelpAlarmBatteryPower from '@/components/general/icons/alarm/help/IconAlarmBatteryPower.vue';
import IconHelpAlarmCloud from '@/components/general/icons/alarm/help/IconAlarmCloud.vue';

import IconSmartCoolingHub from '@/components/general/icons/IconSmartCoolingHub.vue';
import IconSmartCoolingHubWhite from '@/components/general/icons/IconSmartCoolingHubWhite.vue';
import IconService from '@/components/general/icons/IconService.vue';
import IconServiceRequest from '@/components/general/icons/IconServiceRequest.vue';
import IconServiceRequest2 from '@/components/general/icons/IconServiceRequest2.vue';
import IconDoc from '@/components/general/icons/IconDoc.vue';
import IconFaq from '@/components/general/icons/IconFaq.vue';
import IconAdd from '@/components/general/icons/IconAdd.vue';
import IconMove from '@/components/general/icons/IconMove.vue';
import IconHelp from '@/components/general/icons/IconHelp.vue';
import IconStepperSelected from '@/components/general/icons/IconStepperSelected.vue';
import IconStepperUnselected from '@/components/general/icons/IconStepperUnselected.vue';

// Measure site icons
import IconMeasureSite from '@/components/general/icons/measuresites/IconMeasureSite.vue';
import IconMeasureSiteWhite from '@/components/general/icons/measuresites/IconMeasureSiteWhite.vue';
import IconMeasureSiteTemperature from '@/components/general/icons/measuresites/IconMeasureSiteTemperature.vue';
import IconMeasureSiteTemperatureWhite from '@/components/general/icons/measuresites/IconMeasureSiteTemperatureWhite.vue';

import IconRoom from '@/components/general/icons/measuresites/IconRoom.vue';
import IconRoomSmall from '@/components/general/icons/measuresites/IconRoomSmall.vue';
import IconRoomWhite from '@/components/general/icons/measuresites/IconRoomWhite.vue';
import IconRoomWhiteSmall from '@/components/general/icons/measuresites/IconRoomWhiteSmall.vue';
import IconStandIn from '@/components/general/icons/measuresites/IconStandIn.vue';
import IconStandInWhite from '@/components/general/icons/measuresites/IconStandInWhite.vue';
import IconLHOAppliance from '@/components/general/icons/IconLHOAppliance.vue';
import IconLHOApplianceWhite from '@/components/general/icons/IconLHOApplianceWhite.vue';
import IconLHORoom from '@/components/general/icons/IconLHORoom.vue';

import IconDelete from '@/components/general/icons/IconDelete.vue';
import IconDeleteOutline from '@/components/general/icons/IconDeleteOutline.vue';
import IconNotFullyOnBoarded from '@/components/general/icons/IconNotFullyOnBoarded.vue';

import IconSend from '@/components/general/icons/IconSend.vue';
import IconChevronLeft from '@/components/general/icons/IconChevronLeft.vue';
import IconChevronRight from '@/components/general/icons/IconChevronRight.vue';

import IconExpand from '@/components/general/icons/IconExpand.vue';
import IconVideo from '@/components/general/icons/IconVideo.vue';
import IconEnvelope from '@/components/general/icons/IconEnvelope.vue';
import IconSave from '@/components/general/icons/IconSave.vue';
import IconExclamationTriangle from '@/components/general/icons/IconExclamationTriangle.vue';

import IconApplianceConnectGroup from '@/components/general/icons/IconApplianceConnectGroup.vue';

import IconAppliance from '@/components/general/icons/IconAppliance.vue';
import IconApplianceWhite from '@/components/general/icons/IconApplianceWhite.vue';
import IconApplianceBig from '@/components/general/icons/IconApplianceBig.vue';
import IconApplianceDisconnected from '@/components/general/icons/IconApplianceDisconnected.vue';
import IconDeviceOk from '@/components/general/icons/IconDeviceOk.vue';
import IconDeviceAssign from '@/components/general/icons/IconDeviceAssign.vue';
import IconDeviceReset from '@/components/general/icons/IconDeviceReset.vue';

import IconInternalActionLink from '@/components/general/icons/configurable/IconInternalActionLink.vue';
import IconActionLink from '@/components/general/icons/general/IconActionLink.vue';
import IconReload from '@/components/general/icons/general/IconReload.vue';

import IconComment from '@/components/general/icons/IconComment.vue';

import IconStatusOff from '@/components/general/icons/general/IconStatusOff.vue';
import IconStatusOn from '@/components/general/icons/general/IconStatusOn.vue';

import IconNetworkHigh from '@/components/general/icons/network/IconNetworkHigh.vue';
import IconNetworkMed from '@/components/general/icons/network/IconNetworkMed.vue';
import IconNetworkLow from '@/components/general/icons/network/IconNetworkLow.vue';

import IconUnlicensed from '@/components/general/icons/IconUnlicensed';

import IconUser from '@/components/general/icons/IconUser';
import IconUserBig from '@/components/general/icons/IconUserBig';

import IconDefrost from '@/components/general/icons/IconDefrost';

import IconLicense from '@/components/general/icons/IconLicense';

import IconSettingsBig from '@/components/general/icons/IconSettingsBig';
import IconCog from '@/components/general/icons/IconCog';

import IconLink from '@/components/general/icons/IconLink';

import IconPrint from '@/components/general/icons/IconPrint';
import IconDownload from '@/components/general/icons/IconDownload';
import IconClose from '@/components/general/icons/IconClose';

import IconConnectivityOk from '@/components/general/icons/IconConnectivityOk';
import IconConnectivityError from '@/components/general/icons/IconConnectivityError';

import IconBarChart from '@/components/general/icons/IconBarChart';
import IconPieChart from '@/components/general/icons/IconPieChart';

//General icons
import IconMinus from '@/components/general/icons/IconMinus';
import IconPlus from '@/components/general/icons/IconPlus';
import IconMinusDisabled from '@/components/general/icons/IconMinusDisabled';
import IconPlusDisabled from '@/components/general/icons/IconPlusDisabled';
import IconApplianceDisabled from '@/components/general/icons/IconApplianceDisabled';
import IconApplianceEnabled from '@/components/general/icons/IconApplianceEnabled';
import IconDoorEnabled from '@/components/general/icons/IconDoorEnabled';
import IconDoorDisabled from '@/components/general/icons/IconDoorDisabled';
import IconTemperatureEnabled from '@/components/general/icons/IconTemperatureEnabled';
import IconTemperatureDisabled from '@/components/general/icons/IconTemperatureDisabled';
import IconConnectionErrorHelp from '@/components/general/icons/IconConnectionErrorHelp.vue';

// Data missing display
import IconApplianceMissing from '@/components/general/icons/datamissing/IconApplianceMissing';
import IconContentMissing from '@/components/general/icons/datamissing/IconContentMissing';
import IconHubMissing from '@/components/general/icons/datamissing/IconHubMissing';
import IconStatusMissing from '@/components/general/icons/datamissing/IconStatusMissing';
import IconNoContentSad from '@/components/general/icons/datamissing/IconNoContentSad';

Vue.use(Vuetify, {
  options: {
    customProperties: true
  },
  //All 'neutral' color definitions has new names start with 'steel' in corporative design page
  // https://liebherr.invisionapp.com/console/SmartMonitoring-1.0-ckenle8d120mb01x6axauw0ry/cl2rnersh012x01yd1m99g19m/play
  theme: {
    primary: '#2E8DE5',
    secondary: '#003259',
    error: '#fe655c',
    bus_green: '#43A017',
    bus_red: '#E52828',
    bus_yellow: '#ffcc00',
    bus_white: '#ffffff',
    bus_black: '#000000',
    bus_light_grey: '#888E94',
    bus_grey: '#737373',
    neutral: '#F0F3F6',
    neutral20: '#E5E8ED',
    neutral40: '#BDC4CA',
    neutral80: '#51585D',
    steel50: '#9EA4AB',
    steel80: '#51585D',
    clicked_link: '#954F72',
    overlay_background: '#181F3D',
    history_red: '#AD0B37',
    history_green: '#5DBF90',
    history_blue: '#3B6579',
    history_door: '#8BB2C2',
    history_yellow: '#FFD000',
    history_yellow_dark: '#B09000'
  },
  icons: {
    // Navigation Bar
    bus_navigation_menu: {
      component: IconNavigationMenu
    },
    bus_navigation_dashboard: {
      component: IconNavigationDashboard
    },
    bus_navigation_alarm: {
      component: IconNavigationAlarm
    },
    bus_navigation_message: {
      component: IconNavigationMessage
    },
    bus_navigation_report: {
      component: IconNavigationReport
    },
    bus_navigation_appliance: {
      component: IconNavigationAppliance
    },
    bus_navigation_editor: {
      component: IconNavigationEditor
    },
    bus_navigation_settings: {
      component: IconNavigationSettings
    },
    bus_navigation_help: {
      component: IconNavigationHelp
    },
    bus_navigation_user: {
      component: IconNavigationUser
    },

    // Use "bus" as prefix
    // Alarms, subfolder: alarm
    bus_alarm_temperature_on: {
      component: IconAlarmTemperatureOn
    },
    bus_alarm_temperature_off: {
      component: IconAlarmTemperatureOff
    },

    bus_alarm_heartbeat_on: {
      component: IconAlarmHeartbeatOn
    },
    bus_alarm_heartbeat_off: {
      component: IconAlarmHeartbeatOff
    },

    bus_alarm_battery_on: {
      component: IconAlarmBatteryOn
    },
    bus_alarm_battery_off: {
      component: IconAlarmBatteryOff
    },

    bus_alarm_battery_power_on: {
      component: IconAlarmBatteryPowerOn
    },
    bus_alarm_battery_power_off: {
      component: IconAlarmBatteryPowerOff
    },

    bus_alarm_cloud_on: {
      component: IconAlarmCloudOn
    },
    bus_alarm_cloud_off: {
      component: IconAlarmCloudOff
    },

    bus_alarm_door_on: {
      component: IconAlarmDoorOn
    },
    bus_alarm_door_off: {
      component: IconAlarmDoorOff
    },

    bus_alarm_device_on: {
      component: IconAlarmDeviceOn
    },
    bus_alarm_device_off: {
      component: IconAlarmDeviceOff
    },

    bus_alarm_power_on: {
      component: IconAlarmPowerOn
    },
    bus_alarm_power_off: {
      component: IconAlarmPowerOff
    },

    bus_alarm_new: {
      component: IconNewAlarm
    },
    bus_alarm: {
      component: IconAlarm
    },

    // Sensor
    bus_sensor_door: {
      component: IconSensorDoor
    },
    bus_sensor_door_white: {
      component: IconSensorDoorWhite
    },
    bus_sensor_temperature: {
      component: IconSensorTemperature
    },
    bus_sensor_temperature_white: {
      component: IconSensorTemperatureWhite
    },
    bus_sensor_strength_bad: {
      component: IconSensorBadStrength
    },
    bus_sensor_strength_good: {
      component: IconSensorGoodStrength
    },
    bus_sensor_strength_verygood: {
      component: IconSensorVeryGoodStrength
    },
    bus_sensor_battery_good: {
      component: IconSensorBatteryGood
    },
    bus_sensor_battery_medium: {
      component: IconSensorBatteryMedium
    },
    bus_sensor_battery_low: {
      component: IconSensorBatteryLow
    },

    // Help icons
    bus_help_alarm_device: {
      component: IconHelpAlarmDevice
    },
    bus_help_alarm_power: {
      component: IconHelpAlarmPower
    },
    bus_help_alarm_door: {
      component: IconHelpAlarmDoor
    },
    bus_help_alarm_heartbeat: {
      component: IconHelpAlarmHeartbeat
    },
    bus_help_alarm_temperature: {
      component: IconHelpAlarmTemperature
    },
    bus_help_alarm_battery: {
      component: IconHelpAlarmBattery
    },
    bus_help_alarm_battery_power: {
      component: IconHelpAlarmBatteryPower
    },
    bus_help_alarm_cloud: {
      component: IconHelpAlarmCloud
    },

    // Standard
    bus_add: {
      component: IconAdd
    },
    bus_move: {
      component: IconMove
    },
    bus_stepper_selected: {
      component: IconStepperSelected
    },
    bus_stepper_unselected: {
      component: IconStepperUnselected
    },
    // Measure sites
    bus_room: {
      component: IconRoom
    },
    bus_room_small: {
      component: IconRoomSmall
    },
    bus_room_white: {
      component: IconRoomWhite
    },
    bus_room_white_small: {
      component: IconRoomWhiteSmall
    },
    bus_measuresite_standin: {
      component: IconStandIn
    },
    bus_measuresite_standin_white: {
      component: IconStandInWhite
    },
    bus_measuresite: {
      component: IconMeasureSite
    },
    bus_measuresite_white: {
      component: IconMeasureSiteWhite
    },
    bus_measuresite_temperature: {
      component: IconMeasureSiteTemperature
    },
    bus_measuresite_temperature_white: {
      component: IconMeasureSiteTemperatureWhite
    },
    bus_delete: {
      component: IconDelete
    },

    bus_delete_outline: {
      component: IconDeleteOutline
    },

    bus_send: {
      component: IconSend
    },

    bus_chevron_left: {
      component: IconChevronLeft
    },

    bus_chevron_right: {
      component: IconChevronRight
    },

    bus_expand: {
      component: IconExpand
    },

    bus_video: {
      component: IconVideo
    },

    bus_envelope: {
      component: IconEnvelope
    },

    bus_save: {
      component: IconSave
    },

    bus_exclamation_triangle: {
      component: IconExclamationTriangle
    },

    bus_not_fully_onboarded: {
      component: IconNotFullyOnBoarded
    },

    bus_appgroup_connect: {
      component: IconApplianceConnectGroup
    },

    bus_service: {
      component: IconService
    },
    bus_service_request: {
      component: IconServiceRequest
    },
    bus_doc: {
      component: IconDoc
    },
    bus_faq: {
      component: IconFaq
    },

    bus_sensor_flag: {
      component: IconSensorFlag
    },

    bus_devicestate_all_ok: {
      component: IconDeviceOk
    },
    bus_devicestate_assign_ok: {
      component: IconDeviceAssign
    },
    bus_device_reset: {
      component: IconDeviceReset
    },
    bus_unlicensed: {
      component: IconUnlicensed
    },
    bus_plus: {
      component: IconPlus
    },
    bus_minus: {
      component: IconMinus
    },
    bus_plus_disabled: {
      component: IconPlusDisabled
    },
    bus_minus_disabled: {
      component: IconMinusDisabled
    },
    bus_appliance_disabled: {
      component: IconApplianceDisabled
    },
    bus_appliance_enabled: {
      component: IconApplianceEnabled
    },
    bus_door_disabled: {
      component: IconDoorDisabled
    },
    bus_door_enabled: {
      component: IconDoorEnabled
    },
    bus_temperature_disabled: {
      component: IconTemperatureDisabled
    },
    bus_temperature_enabled: {
      component: IconTemperatureEnabled
    },

    // Connectivity page icons
    bus_connectivity_ok: {
      component: IconConnectivityOk
    },
    bus_connectivity_error: {
      component: IconConnectivityError
    },

    // Sub folder: general
    bus_general_gateway: {
      component: IconSmartCoolingHub
    },
    bus_general_gateway_white: {
      component: IconSmartCoolingHubWhite
    },
    bus_general_internal_link: {
      component: IconInternalActionLink
    },
    bus_general_link: {
      component: IconActionLink
    },
    bus_general_reload: {
      component: IconReload
    },

    bus_general_status_on: {
      component: IconStatusOn
    },
    bus_general_status_off: {
      component: IconStatusOff
    },

    bus_comment: {
      component: IconComment
    },

    bus_network_high: {
      component: IconNetworkHigh
    },
    bus_network_med: {
      component: IconNetworkMed
    },
    bus_network_low: {
      component: IconNetworkLow
    },

    bus_appliance: {
      component: IconAppliance
    },
    bus_appliance_disconnected: {
      component: IconApplianceDisconnected
    },
    bus_appliance_white: {
      component: IconApplianceWhite
    },
    bus_appliance_big: {
      component: IconApplianceBig
    },
    bus_lho_appliance: {
      component: IconLHOAppliance
    },
    bus_lho_appliance_white: {
      component: IconLHOApplianceWhite
    },
    bus_lho_room: {
      component: IconLHORoom
    },
    bus_user: {
      component: IconUser
    },
    bus_user_big: {
      component: IconUserBig
    },
    bus_defrost: {
      component: IconDefrost
    },
    bus_license: {
      component: IconLicense
    },
    bus_settings_big: {
      component: IconSettingsBig
    },

    bus_cog: {
      component: IconCog
    },
    bus_link: {
      component: IconLink
    },
    bus_print: {
      component: IconPrint
    },
    bus_download: {
      component: IconDownload
    },
    bus_close: {
      component: IconClose
    },

    bus_chart_bar: {
      component: IconBarChart
    },
    bus_chart_pie: {
      component: IconPieChart
    },

    // Data missing display
    bus_datamissing_appliance: {
      component: IconApplianceMissing
    },
    bus_datamissing_content: {
      component: IconContentMissing
    },
    bus_datamissing_hub: {
      component: IconHubMissing
    },
    bus_datamissing_status: {
      component: IconStatusMissing
    },
    bus_datamissing_no_content: {
      component: IconNoContentSad
    },
    bus_connection_error_help: {
      component: IconConnectionErrorHelp
    },
    bus_service_request_2: {
      component: IconServiceRequest2
    },

    bus_help_icon: {
      component: IconHelp
    }
  }
});
