var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-space-between": "", wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.applianceinfo.siteinfo")))
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "align-items: end", attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { editDisabled: !_vm.licensed, track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.building")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.building
                    ? _vm.editableData.building
                    : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.department")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.department
                    ? _vm.editableData.department
                    : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.floor")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.level
                    ? _vm.editableData.level
                    : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.room")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.room ? _vm.editableData.room : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700px" },
          model: {
            value: _vm.editorShown,
            callback: function($$v) {
              _vm.editorShown = $$v
            },
            expression: "editorShown"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.applianceinfo.siteinfo")))
              ]),
              _c(
                "v-layout",
                { attrs: { column: "", "pa-3": "" } },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.building")))]
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.building
                      ),
                      placeholder: _vm.$t("biz.applianceinfo.building"),
                      rules: _vm.organizationRules,
                      type: "string",
                      required: ""
                    },
                    model: {
                      value: _vm.editableDataLocal.building,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal, "building", $$v)
                      },
                      expression: "editableDataLocal.building"
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.department")))]
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.department
                      ),
                      placeholder: _vm.$t("biz.applianceinfo.department"),
                      rules: _vm.organizationRules,
                      type: "string",
                      required: ""
                    },
                    model: {
                      value: _vm.editableDataLocal.department,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal, "department", $$v)
                      },
                      expression: "editableDataLocal.department"
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.floor")))]
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.level
                      ),
                      placeholder: _vm.$t("biz.applianceinfo.floor"),
                      rules: _vm.organizationRules,
                      type: "string",
                      required: ""
                    },
                    model: {
                      value: _vm.editableDataLocal.level,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal, "level", $$v)
                      },
                      expression: "editableDataLocal.level"
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.room")))]
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.room
                      ),
                      placeholder: _vm.$t("biz.applianceinfo.room"),
                      rules: _vm.organizationRules,
                      type: "string",
                      required: ""
                    },
                    model: {
                      value: _vm.editableDataLocal.room,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal, "room", $$v)
                      },
                      expression: "editableDataLocal.room"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("SaveCancel", {
                    attrs: {
                      saveDisabled: _vm.saveDisabled,
                      track: _vm.track,
                      icon: false
                    },
                    on: {
                      evtSave: function($event) {
                        return _vm.onSave()
                      },
                      evtCancel: function($event) {
                        return _vm.onCancel()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }