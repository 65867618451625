var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FilterMenu", {
    attrs: {
      filterFlag: _vm.filter,
      canApply: _vm.checkSelectedValue,
      track: "ReporterFilter::FilterMenu"
    },
    on: { evtApplyFilter: _vm.onApplyFilter },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _vm._v(_vm._s(_vm.$t("biz.report.filter." + _vm.selectedValue)))
          ]
        },
        proxy: true
      },
      {
        key: "subTitle",
        fn: function() {
          return [_vm._v(_vm._s(_vm.checkedReporters))]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "v-flex",
              {
                staticClass: "ma-3",
                staticStyle: {
                  "font-weight": "bold",
                  "text-transform": "uppercase"
                }
              },
              [_vm._v(_vm._s(_vm.$t("biz.report.filter.reporter")))]
            ),
            _c(
              "v-radio-group",
              {
                staticClass: "ma-2 scroll-y",
                staticStyle: { "max-height": "400px !important" },
                attrs: { mandatory: false },
                model: {
                  value: _vm.selectedValue,
                  callback: function($$v) {
                    _vm.selectedValue = $$v
                  },
                  expression: "selectedValue"
                }
              },
              [
                _c("v-radio", {
                  staticClass: "pa-2",
                  attrs: {
                    color: "secondary",
                    label: _vm.$t("biz.report.filter.allreporters"),
                    value: _vm.allReporters
                  }
                }),
                _c("v-radio", {
                  staticClass: "pa-2",
                  attrs: {
                    color: "secondary",
                    label: _vm.$t("biz.report.filter.selectedreporters"),
                    value: _vm.selectedReporters
                  }
                }),
                _vm._l(_vm.reporterFilterItems, function(user) {
                  return _c(
                    "v-list-tile",
                    { key: user },
                    [
                      _c("v-checkbox", {
                        staticClass: "pl-4",
                        attrs: {
                          label: user,
                          color: "secondary",
                          disabled: _vm.checkDisabled,
                          value: user
                        },
                        model: {
                          value: _vm.selectedReporterList,
                          callback: function($$v) {
                            _vm.selectedReporterList = $$v
                          },
                          expression: "selectedReporterList"
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }