var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%" } },
    [
      _c(
        "v-layout",
        {
          class: { "pl-4": _vm.extraPadding, "pt-2": _vm.extraPadding },
          attrs: { column: "", "fill-height": "" }
        },
        [
          !_vm.dataReady
            ? _c(
                "v-layout",
                {
                  staticStyle: { height: "65vh" },
                  attrs: {
                    column: "",
                    "align-center": "",
                    "justify-center": ""
                  }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "secondary", size: "48" }
                  }),
                  _c("p", { staticClass: "bus_normaltext mt-5" }, [
                    _vm._v(_vm._s(_vm.$t("general.actions.loading")))
                  ])
                ],
                1
              )
            : _c(
                "v-container",
                { staticStyle: { "max-width": "none" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "pb-3": "" } },
                    [
                      _vm.sensorCount > 0
                        ? _c(
                            "v-card-title",
                            { staticClass: "bus_propertytitle" },
                            [_vm._v(_vm._s(_vm.$t("general.sensors")))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-layout", { attrs: { column: "" } }, [
                    !_vm.hasAlarmProfile
                      ? _c("div", { staticClass: "mb-3 bus_text" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "biz.sensors.alarmsetting.general.measuresitehint"
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("v-layout", [
                    _vm.sensorCount > 0
                      ? _c("div", { staticClass: "mb-3 bus_text" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "biz.sensors.alarmsetting.general.assignedsensors"
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _vm.sensorCount > 0
                        ? _c(
                            "v-list",
                            {
                              staticStyle: { "margin-left": "-8px" },
                              attrs: { "two-line": "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs3: "", "offset-xs1": "" } },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "bus_text",
                                          staticStyle: {
                                            "font-weight": "bold",
                                            "margin-left": "5px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("biz.general.sensor"))
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("v-flex", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "bus_text",
                                        staticStyle: {
                                          "font-weight": "bold",
                                          "margin-left": "2px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "biz.dashboard.alarms.status"
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("v-flex", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "bus_text",
                                        staticStyle: {
                                          "font-weight": "bold",
                                          "margin-left": "10px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("general.comment")) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              ),
                              _vm._l(_vm.sensors, function(sensor) {
                                return [
                                  _c(
                                    "v-list-tile",
                                    {
                                      key: sensor.sensorId,
                                      on: {
                                        click: function($event) {
                                          return _vm.gotoSensorAlarmProfile(
                                            sensor
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs1: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.SensorHelper.getSensorIcon(
                                                      sensor
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("v-flex", { attrs: { xs3: "" } }, [
                                            _c("div", {
                                              staticClass: "bus_text",
                                              domProps: {
                                                innerHTML: _vm._s(sensor.name)
                                              }
                                            }),
                                            _c("div", {
                                              staticClass: "bus_text",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  sensor.sensorId
                                                )
                                              }
                                            })
                                          ]),
                                          _c("v-flex", { attrs: { xs4: "" } }, [
                                            _c("div", {
                                              staticClass: "bus_text",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "biz.dashboard.alarms.status"
                                                  ) +
                                                    ": " +
                                                    _vm.getStateForSensor(
                                                      sensor
                                                    )
                                                )
                                              }
                                            }),
                                            _c("div", {
                                              staticClass: "bus_text",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.getSensorAlarmProfileInfoText(
                                                    sensor
                                                  )
                                                )
                                              }
                                            })
                                          ]),
                                          _c("v-flex", { attrs: { xs4: "" } }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "bus_text",
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.routeToSensorProperties(
                                                      sensor.sensorId
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      sensor.description
                                                        ? sensor.description
                                                        : "-"
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }