var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        !_vm.priority ? "secondarytwozonebanner" : "twozonebanner",
        _vm.color
      ],
      on: {
        click: function($event) {
          return _vm.toSelectedZone()
        }
      }
    },
    [
      _c("v-icon", { staticClass: "icon", style: _vm.iconStyle }, [
        _vm._v(_vm._s(_vm.icon))
      ]),
      _c("p", { staticClass: "header" }, [_vm._v(_vm._s(_vm.name))]),
      _vm.licensed
        ? _c("p", { staticClass: "text" }, [
            _vm._v("\n    " + _vm._s(_vm.state) + "\n  ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }