// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrganizationGroups } from './';

/**
 * @export
 * @interface OrganizationSettings
 */
export interface OrganizationSettings {
  /**
   * @type {Date}
   * @memberof OrganizationSettings
   */
  companyCreated?: Date;
  /**
   * @type {OrganizationGroups}
   * @memberof OrganizationSettings
   */
  groups?: OrganizationGroups;
  /**
   * @type {Date}
   * @memberof OrganizationSettings
   */
  groupsChanged?: Date;
  /**
   * @type {string}
   * @memberof OrganizationSettings
   */
  language?: OrganizationSettingsLanguageEnum;
  /**
   * @type {Date}
   * @memberof OrganizationSettings
   */
  languageChanged?: Date;
  /**
   * @type {string}
   * @memberof OrganizationSettings
   */
  organizationArea?: string;
  /**
   * @type {Date}
   * @memberof OrganizationSettings
   */
  organizationAreaChanged?: Date;
  /**
   * @type {boolean}
   * @memberof OrganizationSettings
   */
  valid?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum OrganizationSettingsLanguageEnum {
  DeDE = 'deDE',
  EnUS = 'enUS',
  EnGB = 'enGB',
  FrFR = 'frFR',
  EsES = 'esES'
}
