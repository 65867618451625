// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ReportRequestData
 */
export interface ReportRequestData {
  /**
   * @type {string}
   * @memberof ReportRequestData
   */
  applianceName?: string;
  /**
   * @type {boolean}
   * @memberof ReportRequestData
   */
  createMeasureSiteReport?: boolean;
  /**
   * @type {Array<string>}
   * @memberof ReportRequestData
   */
  sensorThingIds?: Array<string>;
  /**
   * @type {Array<string>}
   * @memberof ReportRequestData
   */
  smartModuleThingIds?: Array<string>;
  /**
   * @type {string}
   * @memberof ReportRequestData
   */
  virtualFridgeId?: string;
}
