// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
  AlarmCommentAddRequest,
  AlarmCommentsResponse,
  ApplianceAlarmProfileDetailsResponse,
  ApplianceAlarmProfileListResponse,
  ApplianceAlarmProfileUpdateRequest,
  ContinuationAlarmToken,
  ErrorDto,
  FilteredMergedAlarmsResponse,
  GatewayAlarmProfile,
  GatewayAlarmProfileUpdateRequest,
  MergedAlarmStatsResponse,
  MergedAlarmsResponse,
  SensorAlarmProfileRequest,
  SensorAlarmProfileResponse,
  SmartModuleAlarmSettingsRequest,
  SmartModuleAlarmSettingsResponse,
  SmartModuleSystemSettingsRequest,
  SmartModuleZoneSettingsRequest,
  UUIDListRequest
} from '../models';

export interface AddAlarmCommentUsingPOSTRequest {
  alarmCommentAddRequest: AlarmCommentAddRequest;
}

export interface AddGatewayOrSensorAlarmCommentUsingPOSTRequest {
  alarmCommentAddRequest: AlarmCommentAddRequest;
}

export interface AddSmartModuleAlarmCommentUsingPOSTRequest {
  alarmCommentAddRequest: AlarmCommentAddRequest;
}

export interface CreateApplianceAlarmProfileDetailsUsingPOSTRequest {
  gatewayId: string;
  virtualFridgeId: string;
  applianceAlarmProfileUpdateRequest: ApplianceAlarmProfileUpdateRequest;
}

export interface CreateSensorAlarmProfileUsingPOSTRequest {
  sensorAlarmProfileRequest: SensorAlarmProfileRequest;
}

export interface CreateSmartModuleAlarmProfileUsingPUTRequest {
  virtualFridgeId: string;
  smartModuleAlarmSettingsRequest: SmartModuleAlarmSettingsRequest;
}

export interface GetAlarmCommentsUsingGETRequest {
  alarmId: string;
}

export interface GetAllAlarmCommentsUsingPOSTRequest {
  uUIDListRequest: UUIDListRequest;
}

export interface GetApplianceAlarmProfileDetailsUsingGETRequest {
  gatewayId: string;
  virtualFridgeId: string;
}

export interface GetFilteredMergedAlarmListUsingPUTRequest {
  filters: Array<string>;
  continuationAlarmToken: ContinuationAlarmToken;
  from?: Date;
  gatewayId?: string;
  groupIds?: Array<string>;
  selected?: boolean;
  sensorThingId?: string;
  smVfId?: string;
  to?: Date;
  vfId?: string;
}

export interface GetGatewayOrSensorAlarmCommentsByListUsingPOSTRequest {
  uUIDListRequest: UUIDListRequest;
}

export interface GetGatewayOrSensorAlarmCommentsUsingGETRequest {
  alarmId: string;
}

export interface GetGatewayProfileDetailsUsingGETRequest {
  gatewayId: string;
}

export interface GetMergedAlarmListUsingGETRequest {
  from: number;
  to: number;
}

export interface GetSensorAlarmProfileUsingGETRequest {
  thingId: string;
}

export interface GetSmartModuleAlarmCommentsByListUsingPOSTRequest {
  uUIDListRequest: UUIDListRequest;
}

export interface GetSmartModuleAlarmCommentsUsingGETRequest {
  alarmId: string;
}

export interface GetSmartModuleAlarmsSettingsUsingGETRequest {
  virtualFridgeId: string;
}

export interface SetDeviceAlarmReadUsingPUTRequest {
  alarmId: string;
  alarmType: string;
}

export interface SetDeviceAlarmUnReadUsingPUTRequest {
  alarmId: string;
  alarmType: string;
}

export interface SetGatewayAlarmReadUsingPUTRequest {
  alarmid: string;
}

export interface SetGatewayAlarmUnReadUsingPUTRequest {
  alarmid: string;
}

export interface SetSensorAlarmReadUsingPUTRequest {
  alarmid: string;
}

export interface SetSensorAlarmUnReadUsingPUTRequest {
  alarmid: string;
}

export interface SetSmartModuleAlarmReadUsingPUTRequest {
  alarmId: string;
}

export interface SetSmartModuleAlarmUnReadUsingPUTRequest {
  alarmId: string;
}

export interface UpdateApplianceAlarmProfileUsingPUTRequest {
  applianceAlarmProfileUpdateRequest: ApplianceAlarmProfileUpdateRequest;
}

export interface UpdateGatewayProfileUsingPUTRequest {
  gatewayAlarmProfileUpdateRequest: GatewayAlarmProfileUpdateRequest;
}

export interface UpdateSensorAlarmProfileUsingPUTRequest {
  sensorAlarmProfileRequest: SensorAlarmProfileRequest;
}

export interface UpdateSmartModuleCloudAlarmsSettingsUsingPUTRequest {
  virtualFridgeId: string;
  smartModuleZoneSettingsRequest: Array<SmartModuleZoneSettingsRequest>;
}

export interface UpdateSmartModuleSystemSettingsUsingPUTRequest {
  virtualFridgeId: string;
  smartModuleSystemSettingsRequest: SmartModuleSystemSettingsRequest;
}

/**
 * no description
 */
export class AlarmControllerApi extends BaseAPI {
  /**
   * addAlarmComment
   * Adds a new comment to an alarm.
   */
  addAlarmCommentUsingPOST = (requestParameters: AddAlarmCommentUsingPOSTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmCommentAddRequest', 'addAlarmCommentUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/devicealarm/alarmComment',
      method: 'POST',
      headers,
      body: requestParameters.alarmCommentAddRequest
    });
  };

  /**
   * addGatewayOrSensorAlarmComment
   * Adds a new comment to an gateway or sensor alarm.
   */
  addGatewayOrSensorAlarmCommentUsingPOST = (
    requestParameters: AddGatewayOrSensorAlarmCommentUsingPOSTRequest
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmCommentAddRequest', 'addGatewayOrSensorAlarmCommentUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/telemetryalarm/alarmComment',
      method: 'POST',
      headers,
      body: requestParameters.alarmCommentAddRequest
    });
  };

  /**
   * addSmartModuleAlarmComment
   * Adds a new comment to an gateway or sensor alarm.
   */
  addSmartModuleAlarmCommentUsingPOST = (
    requestParameters: AddSmartModuleAlarmCommentUsingPOSTRequest
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmCommentAddRequest', 'addSmartModuleAlarmCommentUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/smartmodulealarm/alarmComment',
      method: 'POST',
      headers,
      body: requestParameters.alarmCommentAddRequest
    });
  };

  /**
   * createApplianceAlarmProfileDetails
   */
  createApplianceAlarmProfileDetailsUsingPOST = (
    requestParameters: CreateApplianceAlarmProfileDetailsUsingPOSTRequest
  ): Observable<ApplianceAlarmProfileDetailsResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'createApplianceAlarmProfileDetailsUsingPOST');
    throwIfRequired(requestParameters, 'virtualFridgeId', 'createApplianceAlarmProfileDetailsUsingPOST');
    throwIfRequired(
      requestParameters,
      'applianceAlarmProfileUpdateRequest',
      'createApplianceAlarmProfileDetailsUsingPOST'
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ApplianceAlarmProfileDetailsResponse>({
      path: '/alarmprofile/{virtualFridgeId}/{gatewayId}'
        .replace('{gatewayId}', encodeURI(requestParameters.gatewayId))
        .replace('{virtualFridgeId}', encodeURI(requestParameters.virtualFridgeId)),
      method: 'POST',
      headers,
      body: requestParameters.applianceAlarmProfileUpdateRequest
    });
  };

  /**
   * createSensorAlarmProfile
   * Creates alarm profile to a sensor.
   */
  createSensorAlarmProfileUsingPOST = (
    requestParameters: CreateSensorAlarmProfileUsingPOSTRequest
  ): Observable<SensorAlarmProfileResponse> => {
    throwIfRequired(requestParameters, 'sensorAlarmProfileRequest', 'createSensorAlarmProfileUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SensorAlarmProfileResponse>({
      path: '/alarmprofile/sensors',
      method: 'POST',
      headers,
      body: requestParameters.sensorAlarmProfileRequest
    });
  };

  /**
   * createSmartModuleAlarmProfile
   * Create SmartModule alarm profile.
   */
  createSmartModuleAlarmProfileUsingPUT = (
    requestParameters: CreateSmartModuleAlarmProfileUsingPUTRequest
  ): Observable<SmartModuleAlarmSettingsResponse> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'createSmartModuleAlarmProfileUsingPUT');
    throwIfRequired(requestParameters, 'smartModuleAlarmSettingsRequest', 'createSmartModuleAlarmProfileUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SmartModuleAlarmSettingsResponse>({
      path: '/admin/smartmodule/alarmssettings/create/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'PUT',
      headers,
      body: requestParameters.smartModuleAlarmSettingsRequest
    });
  };

  /**
   * getAlarmComments
   * Gets the existing comments on an alarm.
   */
  getAlarmCommentsUsingGET = (
    requestParameters: GetAlarmCommentsUsingGETRequest
  ): Observable<AlarmCommentsResponse> => {
    throwIfRequired(requestParameters, 'alarmId', 'getAlarmCommentsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AlarmCommentsResponse>({
      path: '/devicealarm/alarmComment/{alarmId}'.replace('{alarmId}', encodeURI(requestParameters.alarmId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getAlarmProfilesForList
   * Gets the alarm profiles for list.
   */
  getAlarmProfilesForListUsingGET = (): Observable<ApplianceAlarmProfileListResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ApplianceAlarmProfileListResponse>({
      path: '/alarmprofilesforlist',
      method: 'GET',
      headers
    });
  };

  /**
   * getAllAlarmComments
   * Gets the existing comments on a list of alarms.
   */
  getAllAlarmCommentsUsingPOST = (
    requestParameters: GetAllAlarmCommentsUsingPOSTRequest
  ): Observable<AlarmCommentsResponse> => {
    throwIfRequired(requestParameters, 'uUIDListRequest', 'getAllAlarmCommentsUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AlarmCommentsResponse>({
      path: '/devicealarm/alarmComments',
      method: 'POST',
      headers,
      body: requestParameters.uUIDListRequest
    });
  };

  /**
   * getApplianceAlarmProfileDetails
   * Gets the alarm profile details.
   */
  getApplianceAlarmProfileDetailsUsingGET = (
    requestParameters: GetApplianceAlarmProfileDetailsUsingGETRequest
  ): Observable<ApplianceAlarmProfileDetailsResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'getApplianceAlarmProfileDetailsUsingGET');
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getApplianceAlarmProfileDetailsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ApplianceAlarmProfileDetailsResponse>({
      path: '/alarmprofile/{virtualFridgeId}/{gatewayId}'
        .replace('{gatewayId}', encodeURI(requestParameters.gatewayId))
        .replace('{virtualFridgeId}', encodeURI(requestParameters.virtualFridgeId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getFilteredMergedAlarmList
   * Gets list with all alarms combined or just for single types. They can be filtered.
   */
  getFilteredMergedAlarmListUsingPUT = (
    requestParameters: GetFilteredMergedAlarmListUsingPUTRequest
  ): Observable<FilteredMergedAlarmsResponse> => {
    throwIfRequired(requestParameters, 'filters', 'getFilteredMergedAlarmListUsingPUT');
    throwIfRequired(requestParameters, 'continuationAlarmToken', 'getFilteredMergedAlarmListUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.filters && { filters: requestParameters.filters }),
      ...(requestParameters.from && { from: (requestParameters.from as any).toISOString() }),
      ...(requestParameters.gatewayId && { gatewayId: requestParameters.gatewayId }),
      ...(requestParameters.groupIds && { groupIds: requestParameters.groupIds }),
      ...(requestParameters.selected && { selected: requestParameters.selected }),
      ...(requestParameters.sensorThingId && { sensorThingId: requestParameters.sensorThingId }),
      ...(requestParameters.smVfId && { smVfId: requestParameters.smVfId }),
      ...(requestParameters.to && { to: (requestParameters.to as any).toISOString() }),
      ...(requestParameters.vfId && { vfId: requestParameters.vfId })
    };

    return this.request<FilteredMergedAlarmsResponse>({
      path: '/filteredmergedalarms',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.continuationAlarmToken
    });
  };

  /**
   * getGatewayOrSensorAlarmCommentsByList
   * Gets the existing comments on a list of gateway or sensor alarms.
   */
  getGatewayOrSensorAlarmCommentsByListUsingPOST = (
    requestParameters: GetGatewayOrSensorAlarmCommentsByListUsingPOSTRequest
  ): Observable<AlarmCommentsResponse> => {
    throwIfRequired(requestParameters, 'uUIDListRequest', 'getGatewayOrSensorAlarmCommentsByListUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AlarmCommentsResponse>({
      path: '/telemetryalarm/alarmComments',
      method: 'POST',
      headers,
      body: requestParameters.uUIDListRequest
    });
  };

  /**
   * getGatewayOrSensorAlarmComments
   * Gets the existing comments on an gateway or sensor alarm.
   */
  getGatewayOrSensorAlarmCommentsUsingGET = (
    requestParameters: GetGatewayOrSensorAlarmCommentsUsingGETRequest
  ): Observable<AlarmCommentsResponse> => {
    throwIfRequired(requestParameters, 'alarmId', 'getGatewayOrSensorAlarmCommentsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AlarmCommentsResponse>({
      path: '/telemetryalarm/alarmComment/{alarmId}'.replace('{alarmId}', encodeURI(requestParameters.alarmId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getGatewayProfileDetails
   * Gets the gateway profile details.
   */
  getGatewayProfileDetailsUsingGET = (
    requestParameters: GetGatewayProfileDetailsUsingGETRequest
  ): Observable<GatewayAlarmProfile> => {
    throwIfRequired(requestParameters, 'gatewayId', 'getGatewayProfileDetailsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<GatewayAlarmProfile>({
      path: '/gatewayprofile/{gatewayId}'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getMergedAlarmList
   * Gets a device alarm list by virtualFridgeIds list, the request can specify the number of alarms fetched.
   */
  getMergedAlarmListUsingGET = (
    requestParameters: GetMergedAlarmListUsingGETRequest
  ): Observable<MergedAlarmsResponse> => {
    throwIfRequired(requestParameters, 'from', 'getMergedAlarmListUsingGET');
    throwIfRequired(requestParameters, 'to', 'getMergedAlarmListUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<MergedAlarmsResponse>({
      path: '/mergedalarms/{from}/{to}'
        .replace('{from}', encodeURI(requestParameters.from))
        .replace('{to}', encodeURI(requestParameters.to)),
      method: 'GET',
      headers
    });
  };

  /**
   * getMergedAlarmStats
   * Gets the current device and gateway alarm statistics object.
   */
  getMergedAlarmStatsUsingGET = (): Observable<MergedAlarmStatsResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<MergedAlarmStatsResponse>({
      path: '/mergedalarms/stats',
      method: 'GET',
      headers
    });
  };

  /**
   * getSensorAlarmProfile
   * Gets the sensors alarm profile.
   */
  getSensorAlarmProfileUsingGET = (
    requestParameters: GetSensorAlarmProfileUsingGETRequest
  ): Observable<SensorAlarmProfileResponse> => {
    throwIfRequired(requestParameters, 'thingId', 'getSensorAlarmProfileUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SensorAlarmProfileResponse>({
      path: '/alarmprofile/sensor/{thingId}'.replace('{thingId}', encodeURI(requestParameters.thingId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getSmartModuleAlarmCommentsByList
   * Gets the existing comments on a list of gateway or sensor alarms.
   */
  getSmartModuleAlarmCommentsByListUsingPOST = (
    requestParameters: GetSmartModuleAlarmCommentsByListUsingPOSTRequest
  ): Observable<AlarmCommentsResponse> => {
    throwIfRequired(requestParameters, 'uUIDListRequest', 'getSmartModuleAlarmCommentsByListUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AlarmCommentsResponse>({
      path: '/smartmodulealarm/alarmComments',
      method: 'POST',
      headers,
      body: requestParameters.uUIDListRequest
    });
  };

  /**
   * getSmartModuleAlarmComments
   * Gets the existing comments on an gateway or sensor alarm.
   */
  getSmartModuleAlarmCommentsUsingGET = (
    requestParameters: GetSmartModuleAlarmCommentsUsingGETRequest
  ): Observable<AlarmCommentsResponse> => {
    throwIfRequired(requestParameters, 'alarmId', 'getSmartModuleAlarmCommentsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AlarmCommentsResponse>({
      path: '/smartmodulealarm/{alarmId}/alarmComment'.replace('{alarmId}', encodeURI(requestParameters.alarmId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getSmartModuleAlarmsSettings
   * Gets SmartModule alarm profile.
   */
  getSmartModuleAlarmsSettingsUsingGET = (
    requestParameters: GetSmartModuleAlarmsSettingsUsingGETRequest
  ): Observable<SmartModuleAlarmSettingsResponse> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getSmartModuleAlarmsSettingsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SmartModuleAlarmSettingsResponse>({
      path: '/smartmodule/alarmsettings/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * setDeviceAlarmRead
   * Sets the passed-in device alarm to state \'read\'.
   */
  setDeviceAlarmReadUsingPUT = (requestParameters: SetDeviceAlarmReadUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmId', 'setDeviceAlarmReadUsingPUT');
    throwIfRequired(requestParameters, 'alarmType', 'setDeviceAlarmReadUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/devicealarm/setread/{alarmId}/{alarmType}'
        .replace('{alarmId}', encodeURI(requestParameters.alarmId))
        .replace('{alarmType}', encodeURI(requestParameters.alarmType)),
      method: 'PUT',
      headers
    });
  };

  /**
   * setDeviceAlarmUnRead
   * Sets the passed-in device alarm to state \'unread\'.
   */
  setDeviceAlarmUnReadUsingPUT = (requestParameters: SetDeviceAlarmUnReadUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmId', 'setDeviceAlarmUnReadUsingPUT');
    throwIfRequired(requestParameters, 'alarmType', 'setDeviceAlarmUnReadUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/devicealarm/setunread/{alarmId}/{alarmType}'
        .replace('{alarmId}', encodeURI(requestParameters.alarmId))
        .replace('{alarmType}', encodeURI(requestParameters.alarmType)),
      method: 'PUT',
      headers
    });
  };

  /**
   * setGatewayAlarmRead
   * Sets the passed-in gateway alarm to state \'read\'.
   */
  setGatewayAlarmReadUsingPUT = (requestParameters: SetGatewayAlarmReadUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmid', 'setGatewayAlarmReadUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/gatewayalarm/setread/{alarmid}'.replace('{alarmid}', encodeURI(requestParameters.alarmid)),
      method: 'PUT',
      headers
    });
  };

  /**
   * setGatewayAlarmUnRead
   * Sets the passed-in gateway alarm to state \'unread\'.
   */
  setGatewayAlarmUnReadUsingPUT = (requestParameters: SetGatewayAlarmUnReadUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmid', 'setGatewayAlarmUnReadUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/gatewayalarm/setunread/{alarmid}'.replace('{alarmid}', encodeURI(requestParameters.alarmid)),
      method: 'PUT',
      headers
    });
  };

  /**
   * setSensorAlarmRead
   * Sets the passed-in sensor alarm to state \'read\'.
   */
  setSensorAlarmReadUsingPUT = (requestParameters: SetSensorAlarmReadUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmid', 'setSensorAlarmReadUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/sensoralarm/setread/{alarmid}'.replace('{alarmid}', encodeURI(requestParameters.alarmid)),
      method: 'PUT',
      headers
    });
  };

  /**
   * setSensorAlarmUnRead
   * Sets the passed-in sensor alarm to state \'unread\'.
   */
  setSensorAlarmUnReadUsingPUT = (requestParameters: SetSensorAlarmUnReadUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmid', 'setSensorAlarmUnReadUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/sensoralarm/setunread/{alarmid}'.replace('{alarmid}', encodeURI(requestParameters.alarmid)),
      method: 'PUT',
      headers
    });
  };

  /**
   * setSmartModuleAlarmRead
   * Sets the passed-in device alarm to state \'read\'.
   */
  setSmartModuleAlarmReadUsingPUT = (requestParameters: SetSmartModuleAlarmReadUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmId', 'setSmartModuleAlarmReadUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/smartmodulealarm/{alarmId}/setread'.replace('{alarmId}', encodeURI(requestParameters.alarmId)),
      method: 'PUT',
      headers
    });
  };

  /**
   * setSmartModuleAlarmUnRead
   * Sets the passed-in device alarm to state \'unread\'.
   */
  setSmartModuleAlarmUnReadUsingPUT = (
    requestParameters: SetSmartModuleAlarmUnReadUsingPUTRequest
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'alarmId', 'setSmartModuleAlarmUnReadUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/smartmodulealarm/{alarmId}/setunread'.replace('{alarmId}', encodeURI(requestParameters.alarmId)),
      method: 'PUT',
      headers
    });
  };

  /**
   * updateApplianceAlarmProfile
   * Updates an alarm profile.
   */
  updateApplianceAlarmProfileUsingPUT = (
    requestParameters: UpdateApplianceAlarmProfileUsingPUTRequest
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'applianceAlarmProfileUpdateRequest', 'updateApplianceAlarmProfileUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/alarmprofile/virtualfridge',
      method: 'PUT',
      headers,
      body: requestParameters.applianceAlarmProfileUpdateRequest
    });
  };

  /**
   * updateGatewayProfile
   * Updates an gateway profile.
   */
  updateGatewayProfileUsingPUT = (requestParameters: UpdateGatewayProfileUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'gatewayAlarmProfileUpdateRequest', 'updateGatewayProfileUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/alarmprofile/gateway',
      method: 'PUT',
      headers,
      body: requestParameters.gatewayAlarmProfileUpdateRequest
    });
  };

  /**
   * updateSensorAlarmProfile
   * Updates an existing alarm profile of a sensor.
   */
  updateSensorAlarmProfileUsingPUT = (
    requestParameters: UpdateSensorAlarmProfileUsingPUTRequest
  ): Observable<SensorAlarmProfileResponse> => {
    throwIfRequired(requestParameters, 'sensorAlarmProfileRequest', 'updateSensorAlarmProfileUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SensorAlarmProfileResponse>({
      path: '/admin/alarmprofile/sensors',
      method: 'PUT',
      headers,
      body: requestParameters.sensorAlarmProfileRequest
    });
  };

  /**
   * updateSmartModuleCloudAlarmsSettings
   * Update SmartModule zone additional cloud alarms.
   */
  updateSmartModuleCloudAlarmsSettingsUsingPUT = (
    requestParameters: UpdateSmartModuleCloudAlarmsSettingsUsingPUTRequest
  ): Observable<boolean> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'updateSmartModuleCloudAlarmsSettingsUsingPUT');
    throwIfRequired(
      requestParameters,
      'smartModuleZoneSettingsRequest',
      'updateSmartModuleCloudAlarmsSettingsUsingPUT'
    );

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/smartmodule/alarmsettings/cloudalarms/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'PUT',
      headers,
      body: requestParameters.smartModuleZoneSettingsRequest
    });
  };

  /**
   * updateSmartModuleSystemSettings
   * Update SmartModule alarm profile - system unavailability.
   */
  updateSmartModuleSystemSettingsUsingPUT = (
    requestParameters: UpdateSmartModuleSystemSettingsUsingPUTRequest
  ): Observable<boolean> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'updateSmartModuleSystemSettingsUsingPUT');
    throwIfRequired(requestParameters, 'smartModuleSystemSettingsRequest', 'updateSmartModuleSystemSettingsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/smartmodule/alarmsettings/system/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'PUT',
      headers,
      body: requestParameters.smartModuleSystemSettingsRequest
    });
  };
}
