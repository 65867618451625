var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", fullscreen: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-container",
            { staticStyle: { display: "flex", "min-height": "100vh" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "align-center": "",
                    "justify-center": "",
                    column: ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "justify-center align-center",
                      attrs: { shrink: "" }
                    },
                    [
                      _c("v-img", {
                        staticClass: "bus_liebherr_logo",
                        attrs: {
                          id: "logo",
                          src: require("@/assets/logo_ci_liebherr.svg"),
                          contain: ""
                        }
                      }),
                      _c(
                        "h1",
                        {
                          staticStyle: {
                            "text-align": "center",
                            color: "secondary"
                          }
                        },
                        [_vm._v(_vm._s(_vm.maintenanceTitle))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "justify-center align-center",
                      attrs: { shrink: "" }
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          width: "800px",
                          src: require("@/assets/maintenance.png")
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "justify-center align-center",
                      attrs: { shrink: "" }
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticStyle: {
                            "text-align": "center",
                            color: "secondary"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.maintenanceMessage) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }