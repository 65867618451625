var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { staticClass: "mb-2" }, [
        _vm._v(_vm._s(_vm.$t("biz.help.softwarelicense.SCHLicenses")))
      ]),
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "biz.help.softwarelicense.SCHLicensehelper.overview.headline"
            )
          )
        )
      ]),
      _c("p", { staticClass: "bus_normaltext mr-1 mb-2" }, [
        _vm._v(
          _vm._s(
            _vm.$t("biz.help.softwarelicense.SCHLicensehelper.overview.text")
          )
        )
      ]),
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "biz.help.softwarelicense.SCHLicensehelper.disclaimer.headline"
            )
          )
        )
      ]),
      _c("p", { staticClass: "bus_normaltext mr-1 mb-2" }, [
        _vm._v(
          _vm._s(
            _vm.$t("biz.help.softwarelicense.SCHLicensehelper.disclaimer.text")
          )
        )
      ]),
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "biz.help.softwarelicense.SCHLicensehelper.acknowledgements.headline"
            )
          )
        )
      ]),
      _c("p", {
        staticClass: "bus_normaltext mr-1 mb-2",
        domProps: {
          innerHTML: _vm._s(
            _vm.$t(
              "biz.help.softwarelicense.SCHLicensehelper.acknowledgements.text"
            )
          )
        }
      }),
      _c("strong", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "biz.help.softwarelicense.SCHLicensehelper.sourcecode.headline"
            )
          )
        )
      ]),
      _c("p", { staticClass: "bus_normaltext mr-1 mb-2" }, [
        _vm._v(
          _vm._s(
            _vm.$t("biz.help.softwarelicense.SCHLicensehelper.sourcecode.text")
          )
        )
      ]),
      _c(
        "v-layout",
        { attrs: { column: "", "mr-2": "" } },
        [
          _c(
            "v-layout",
            { staticStyle: { "align-items": "center" }, attrs: { row: "" } },
            [
              _c(
                "p",
                {
                  staticClass: "mb-0",
                  staticStyle: { "justify-content": "flex-start" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("biz.help.softwarelicense.SCHLicensetexts"))
                  )
                ]
              ),
              _c("v-spacer"),
              _c(
                "a",
                {
                  staticStyle: { "text-decoration": "none" },
                  attrs: { target: "_blank", href: _vm.getSCHLicensesLink() }
                },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "SCHLicenses::DownloadLicenses",
                          expression: "'SCHLicenses::DownloadLicenses'"
                        }
                      ],
                      staticClass: "ma-0",
                      attrs: { depressed: "", color: "secondary", outline: "" }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v(_vm._s(_vm.getRightArrowIcon()))
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.download")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { staticStyle: { "align-items": "center" }, attrs: { row: "" } },
            [
              _c(
                "p",
                {
                  staticClass: "mb-0",
                  staticStyle: { "justify-content": "flex-start" }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("biz.help.softwarelicense.SCHLicensedownload")
                    )
                  )
                ]
              ),
              _c("v-spacer"),
              _c(
                "a",
                {
                  staticStyle: { "text-decoration": "none" },
                  attrs: { target: "_blank", href: _vm.getSCHSoftwareLink() }
                },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "SCHLicenses::DownloadSources",
                          expression: "'SCHLicenses::DownloadSources'"
                        }
                      ],
                      staticClass: "ma-0",
                      attrs: { depressed: "", color: "secondary", outline: "" }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v(_vm._s(_vm.getRightArrowIcon()))
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.download")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }