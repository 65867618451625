var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.criticalDevicesPage
        ? _c(
            "v-card",
            {
              staticClass: "tilecard",
              staticStyle: { height: "60px" },
              attrs: { flat: "", color: _vm.getSensorColor(true) },
              on: {
                click: function($event) {
                  return _vm.toSensor()
                }
              }
            },
            [
              _c(
                "v-card-text",
                {
                  staticClass: "justify-center",
                  staticStyle: { padding: "10px" }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", nowrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [_c("v-icon", [_vm._v(_vm._s(_vm.getSensorIcon()))])],
                        1
                      ),
                      _c("v-flex", { style: _vm.getTextColor() }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "shorttext",
                                rawName: "v-shorttext",
                                value: 160,
                                expression: "160"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getName()) +
                                "\n          "
                            )
                          ]
                        )
                      ]),
                      _c(
                        "v-flex",
                        { style: _vm.getTextColor(), attrs: { shrink: "" } },
                        [_vm._v(_vm._s(_vm.getSensorValue(true)))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "text-truncate",
                  style: _vm.getSensorIdColor(),
                  attrs: { "pr-1": "" }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("general.id")) +
                      ": " +
                      _vm._s(_vm.getSensorId()) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            {
              on: {
                click: function($event) {
                  return _vm.toSensor()
                }
              }
            },
            [
              _c("CardTileLayout", {
                attrs: { licensed: true, twoZone: false, hasSensors: false },
                scopedSlots: _vm._u([
                  {
                    key: "main",
                    fn: function() {
                      return [
                        _c("v-layout", { attrs: { row: "" } }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.toSensor()
                                }
                              }
                            },
                            [
                              _c("MainTileLayout", {
                                attrs: { licensed: true },
                                scopedSlots: _vm._u([
                                  {
                                    key: "type",
                                    fn: function() {
                                      return [
                                        _vm.sensor.sensorType === "DOOR"
                                          ? _c(
                                              "v-flex",
                                              {
                                                class: _vm.getSensorColor(
                                                  false
                                                ),
                                                attrs: {
                                                  "align-center": "",
                                                  "justify-center": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticStyle: {
                                                      height: "30px",
                                                      width: "30px"
                                                    },
                                                    attrs: { size: "30" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getSensorDoorIcon()
                                                        )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-flex",
                                              {
                                                class: _vm.getSensorColor(),
                                                attrs: {
                                                  "align-center": "",
                                                  "justify-center": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$format.temperature(
                                                        _vm.getTemperature()
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "info",
                                    fn: function() {
                                      return [
                                        _c("InfoTile", {
                                          attrs: {
                                            title: _vm.getSensorName(),
                                            alertText: _vm.getSensorValue(false)
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "alerticon",
                                    fn: function() {
                                      return [
                                        _c("v-icon", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getSensorAlarmIcon(_vm.sensor)
                                            )
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }