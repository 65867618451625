// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ServiceEmailRequest
 */
export interface ServiceEmailRequest {
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  backendVersion?: string;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  customerEmail?: string;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  customerName?: string;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  customerTelephone?: string;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  errorMessage?: string;
  /**
   * @type {Date}
   * @memberof ServiceEmailRequest
   */
  errorTime?: Date;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  frontendVersion?: string;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  gatewayId?: string;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  message?: string;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  recipientCountryCode?: string;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  requestType?: ServiceEmailRequestRequestTypeEnum;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  sensorId?: string;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  serialNumber?: string;
  /**
   * @type {string}
   * @memberof ServiceEmailRequest
   */
  virtualFridgeId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ServiceEmailRequestRequestTypeEnum {
  TECHNICALSUPPORT = 'TECHNICAL_SUPPORT',
  SPAREPARTS = 'SPARE_PARTS',
  PRODUCTINFORMATION = 'PRODUCT_INFORMATION',
  DIAGNOSIS = 'DIAGNOSIS',
  OTHER = 'OTHER'
}
