var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-bus_white-base)" },
            attrs: {
              d:
                "M 31 24.5 C 31.552 24.5 32 24.948 32 25.5 C 32 26.013 31.614 26.436 31.117 26.494 L 31 26.5 L 1 26.5 C 0.448 26.5 0 26.053 0 25.5 C 0 24.987 0.386 24.565 0.883 24.507 L 1 24.5 L 31 24.5 Z M 31 14.5 C 31.552 14.5 32 14.948 32 15.5 C 32 16.013 31.614 16.436 31.117 16.494 L 31 16.5 L 1 16.5 C 0.448 16.5 0 16.053 0 15.5 C 0 14.987 0.386 14.565 0.883 14.507 L 1 14.5 L 31 14.5 Z M 31 5.5 C 31.552 5.5 32 5.948 32 6.5 C 32 7.013 31.614 7.436 31.117 7.494 L 31 7.5 L 1 7.5 C 0.448 7.5 0 7.053 0 6.5 C 0 5.987 0.386 5.565 0.883 5.507 L 1 5.5 L 31 5.5 Z"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }