var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "74px",
        height: "74px",
        viewBox: "0 0 74 74",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            transform: "matrix(1, 0, 0, 1, 0.000001, -0.999999)"
          }
        },
        [
          _c(
            "g",
            {
              staticStyle: { fill: "var(--v-secondary-base)" },
              attrs: { transform: "translate(26.000000, 21.000000)" }
            },
            [
              _c("path", {
                staticStyle: { fill: "var(--v-bus_white-base)" },
                attrs: {
                  d:
                    "M18,0 C20.209139,0 22,1.790861 22,4 L22,27 C22,28.0139269 21.6227502,28.9397423 21.0009561,29.6447407 L21,31 C21,32.6568542 19.6568542,34 18,34 L18,34 L4,34 C2.34314575,34 1,32.6568542 1,31 L1,31 L0.999943032,29.6457598 C0.377613544,28.9406347 0,28.0144155 0,27 L0,4 C0,1.790861 1.790861,0 4,0 L18,0 Z M19,31 L3,31 C3,31.5522847 3.44771525,32 4,32 L4,32 L18,32 C18.5522847,32 19,31.5522847 19,31 L19,31 Z M20,12 L2,12 L2,27 C2,28.0543618 2.81587779,28.9181651 3.85073766,28.9945143 L4,29 L18,29 C19.0543618,29 19.9181651,28.1841222 19.9945143,27.1492623 L20,27 L20,12 Z M5,14 C5.51283584,14 5.93550716,14.3860402 5.99327227,14.8833789 L6,15 L6,26 C6,26.5522847 5.55228475,27 5,27 C4.48716416,27 4.06449284,26.6139598 4.00672773,26.1166211 L4,26 L4,15 C4,14.4477153 4.44771525,14 5,14 Z M18,2 L4,2 C2.9456382,2 2.08183488,2.81587779 2.00548574,3.85073766 L2,4 L2,10 L20,10 L20,4 C20,2.9456382 19.1841222,2.08183488 18.1492623,2.00548574 L18,2 Z M5,4 C5.51283584,4 5.93550716,4.38604019 5.99327227,4.88337887 L6,5 L6,7 C6,7.55228475 5.55228475,8 5,8 C4.48716416,8 4.06449284,7.61395981 4.00672773,7.11662113 L4,7 L4,5 C4,4.44771525 4.44771525,4 5,4 Z"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }