var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "34px",
        height: "34px",
        viewBox: "0 0 34 34",
        version: "1.1"
      }
    },
    [
      _c("g", [
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d:
              "M 14.5 14 L 14.5 21.5 L 0.500919 21.5 L 0.500919 0.5 L 14.5 0.5 L 14.5 3.000919 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 7.499081 0.5 L 7.499081 21.5 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 5.5 12.999081 L 5.5 15.999081 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 5.5 6.999081 L 5.5 9.999081 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 9.500919 12.999081 L 9.500919 15.999081 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 0.500919 11.449449 L 7.860294 11.449449 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 14.511029 5.840993 L 14.511029 11.780331 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d:
              "M 16.289522 4.059743 L 14.511029 5.840993 L 12.729779 4.059743 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d:
              "M 16.289522 13.550551 L 14.511029 11.780331 L 12.729779 13.550551 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 11.941176 7.318934 L 17.080882 10.29136 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 11.290441 4.889706 L 11.941176 7.318934 L 9.509191 7.969669 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 19.51011 9.640625 L 17.080882 10.29136 L 17.72886 12.720588 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 11.941176 10.29136 L 17.080882 7.318934 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d:
              "M 9.509191 9.640625 L 11.941176 10.29136 L 11.290441 12.720588 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        }),
        _c("path", {
          staticStyle: {
            fill: "none",
            "stroke-width": "1",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(0%, 0%, 0%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d: "M 17.72886 4.889706 L 17.080882 7.318934 L 19.51011 7.969669 ",
            transform: "matrix(1.416667,0,0,1.416667,2.833333,1.416667)"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }