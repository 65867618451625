// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface Message
 */
export interface Message {
  /**
   * @type {string}
   * @memberof Message
   */
  body?: string;
  /**
   * @type {string}
   * @memberof Message
   */
  footer?: string;
  /**
   * @type {number}
   * @memberof Message
   */
  image?: number;
  /**
   * @type {string}
   * @memberof Message
   */
  name?: string;
  /**
   * @type {Date}
   * @memberof Message
   */
  senddate?: Date;
  /**
   * @type {string}
   * @memberof Message
   */
  sendername?: string;
  /**
   * @type {string}
   * @memberof Message
   */
  title?: string;
}
