









import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import IconBase from './IconBase';

@Component({
  components: { IconBase }
})
export default class IconInternalActionLink extends Vue {}
