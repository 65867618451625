var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "pa-2": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { attrs: { shrink: "", "pb-0": "" } },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.general.items.unreadalarms")))
              ])
            ],
            1
          ),
          _vm.dataIsMissing
            ? _c(
                "v-flex",
                { staticStyle: { "margin-top": "80px" } },
                [
                  _c("DataMissingDisplay", {
                    attrs: {
                      interestedIn: _vm.pageRouteHelper.ALARM_DASHBOARD
                    },
                    on: {
                      evtAlarmsDashboardData: function($event) {
                        _vm.dataIsMissing = false
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-layout",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.stateOkay && !_vm.dataIsMissing,
                  expression: "!stateOkay && !dataIsMissing"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { xs12: "", row: "", shrink: "" }
            },
            [
              _c("v-flex", { attrs: { xs1: "", "ml-3": "" } }, [
                _vm._v(_vm._s(_vm.$t("general.type")))
              ]),
              _c("v-flex", { attrs: { xs5: "" } }, [
                _vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))
              ]),
              _c(
                "v-flex",
                {
                  staticStyle: { "margin-left": "-5px" },
                  attrs: { xs3: "", "pl-0": "" }
                },
                [_vm._v(_vm._s(this.$t("biz.dashboard.alarms.minmaxalarm")))]
              ),
              _c("v-flex", { attrs: { xs3: "", "pl-0": "" } }, [
                _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.alarmdate")))
              ])
            ],
            1
          ),
          _c("v-divider", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.dataIsMissing,
                expression: "!dataIsMissing"
              }
            ]
          }),
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.stateOkay && !_vm.dataIsMissing,
                  expression: "!stateOkay && !dataIsMissing"
                }
              ],
              staticClass: "scroll-y",
              staticStyle: { position: "relative" },
              attrs: { "px-0": "", "pt-0": "", grow: "" }
            },
            [
              _c(
                "v-layout",
                {
                  staticStyle: { position: "absolute", width: "100%" },
                  attrs: { xs12: "", column: "", wrap: "" }
                },
                [
                  _c(
                    "v-list",
                    {
                      staticStyle: {
                        "margin-top": "5px",
                        "margin-left": "16px"
                      },
                      attrs: { "two-line": "" }
                    },
                    _vm._l(_vm.alarms, function(alarm) {
                      return _c(
                        "v-list-tile",
                        {
                          key: alarm.alarmId,
                          staticStyle: { border: "none", cursor: "pointer" },
                          on: {
                            click: function($event) {
                              return _vm.routeToDevice(alarm)
                            }
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs1: "" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  class: _vm.getRowFont(alarm),
                                  attrs: { large: "" }
                                },
                                [_vm._v(_vm._s(_vm.getStateAlarmIcon(alarm)))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              class: _vm.getRowFont(alarm),
                              staticStyle: {
                                "margin-left": "3px",
                                "font-size": "14px"
                              },
                              attrs: { xs5: "" }
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "mb-0 mt-3 text-truncate" },
                                [_vm._v(_vm._s(alarm.deviceName))]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass: "caption",
                                  staticStyle: { color: "bus_grey" }
                                },
                                [_vm._v(_vm._s(_vm.getStateAlarmText(alarm)))]
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "text-truncate pb-3 pt-0 !important caption",
                              class: _vm.getRowFont(alarm),
                              staticStyle: { "padding-left": "5px" },
                              attrs: { xs3: "" }
                            },
                            [_vm._v(_vm._s(_vm.getMinMaxAlarmText(alarm)))]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "text-truncate pb-3 pt-0 !important caption",
                              class: _vm.getRowFont(alarm),
                              staticStyle: { "padding-left": "10px" },
                              attrs: { xs3: "" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$format.dateTimeShort(alarm.alarmTime)
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.dataIsMissing,
                  expression: "!dataIsMissing"
                }
              ],
              staticStyle: { "border-top": "1px solid lightgrey" },
              attrs: { shrink: "" }
            },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: "Alarms::ShowAllAlarms",
                      expression: "'Alarms::ShowAllAlarms'"
                    }
                  ],
                  staticStyle: { float: "right" },
                  attrs: { depressed: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.showAllAlarms()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.showall")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }