var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-layout", { attrs: { column: "", "fill-height": "" } }, [
    _c(
      "div",
      { staticClass: "window-container" },
      [
        _c(
          "v-window",
          {
            staticStyle: { height: "inherit" },
            attrs: { touchless: "" },
            model: {
              value: _vm.step,
              callback: function($$v) {
                _vm.step = $$v
              },
              expression: "step"
            }
          },
          [
            _c(
              "v-window-item",
              { staticStyle: { height: "inherit" }, attrs: { value: 1 } },
              [
                _c("ModbusInfoStep", {
                  attrs: {
                    title: _vm.$t("biz.onboarding.smartmodule.infotitle"),
                    track: _vm.track + "::ModbusInfoStep",
                    applianceInfo: _vm.applianceInfo,
                    onboardingFlow: _vm.ONBOARD_SMART_MODULE,
                    readyFlag: _vm.readyFlag
                  },
                  on: {
                    evtCancel: function($event) {
                      _vm.cancelDialogShown = true
                    },
                    "update:applianceInfo": function($event) {
                      _vm.applianceInfo = $event
                    },
                    "update:appliance-info": function($event) {
                      _vm.applianceInfo = $event
                    },
                    evtModbusInUse: _vm.applianceInUse,
                    evtValidSerialNumber: _vm.validSerialNumberInput,
                    evtSerialNumberProvided: _vm.onSerialNumberInputDone,
                    "update:readyFlag": function($event) {
                      _vm.readyFlag = $event
                    },
                    "update:ready-flag": function($event) {
                      _vm.readyFlag = $event
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "v-window-item",
              { staticStyle: { height: "inherit" }, attrs: { value: 2 } },
              [
                _c("SmartModuleConnectionSetup", {
                  on: {
                    evtCancel: function($event) {
                      _vm.cancelDialogShown = true
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "v-window-item",
              { staticStyle: { height: "inherit" }, attrs: { value: 3 } },
              [
                _c("SmartModuleNetworkSelector", {
                  on: {
                    evtCancel: function($event) {
                      _vm.cancelDialogShown = true
                    },
                    evtSelectedNetworkType: _vm.onNetworkTypeSelection
                  }
                })
              ],
              1
            ),
            _c(
              "v-window-item",
              { staticStyle: { height: "inherit" }, attrs: { value: 4 } },
              [
                _c("SmartModuleNetworkActivation", {
                  attrs: { isWiFi: _vm.isWiFiOnBoarding },
                  on: {
                    evtCancel: function($event) {
                      _vm.cancelDialogShown = true
                    }
                  }
                })
              ],
              1
            ),
            _vm.isWiFiOnBoarding
              ? _c(
                  "v-window-item",
                  { staticStyle: { height: "inherit" }, attrs: { value: 5 } },
                  [
                    _c("SmartModuleConnectAccessPoint", {
                      on: {
                        evtCancel: function($event) {
                          _vm.cancelDialogShown = true
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-window-item",
              { staticStyle: { height: "inherit" }, attrs: { value: 6 } },
              [
                _vm.smartModuleConnectionResponse
                  ? _c("SmartModuleConnectionStatus", {
                      attrs: {
                        onboardingFlow: _vm.onboardingFlow,
                        smartModuleConnectionResponse:
                          _vm.smartModuleConnectionResponse,
                        track: _vm.track + "::SmartModuleConnectionStatus"
                      },
                      on: {
                        evtCancel: function($event) {
                          _vm.cancelDialogShown = true
                        },
                        evtRescan: function($event) {
                          return _vm.reScanSmartModule()
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "navigation-place" },
          [
            _c("v-footer", { attrs: { color: "white" } }, [
              _c(
                "div",
                {
                  staticClass: "navigation-content",
                  attrs: { "grid-list-lg": "" }
                },
                [
                  _c("BackNextMenu", {
                    attrs: {
                      stepCount: 9,
                      step: _vm.step,
                      nextDisabled: !_vm.isNextEnabled,
                      previousDisabled: _vm.isPreviousDisabled,
                      stepNextExemptions: [5, 6, 7]
                    },
                    on: {
                      "update:step": function($event) {
                        _vm.step = $event
                      },
                      evtNextStepExemption: _vm.handleNextStep,
                      evtPrevious: _vm.previousStep
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        ),
        _c("OkCancelDialog", {
          attrs: {
            dialogShown: _vm.cancelDialogShown,
            title: _vm.$t("biz.setup.abort.title"),
            message: _vm.$t("biz.setup.abort.description"),
            track: "SmartModuleSetup::AbortSetup"
          },
          on: {
            evtOkPressed: function($event) {
              return _vm.confirmAbort()
            },
            evtCancelPressed: function($event) {
              return _vm.cancelAbort()
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }