// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SamplingRate } from './';

/**
 * @export
 * @interface Sensor
 */
export interface Sensor {
  /**
   * @type {string}
   * @memberof Sensor
   */
  description?: string;
  /**
   * @type {string}
   * @memberof Sensor
   */
  name?: string;
  /**
   * @type {SamplingRate}
   * @memberof Sensor
   */
  samplingRate?: SamplingRate;
  /**
   * @type {string}
   * @memberof Sensor
   */
  sensorId?: string;
  /**
   * @type {string}
   * @memberof Sensor
   */
  sensorType?: SensorSensorTypeEnum;
  /**
   * @type {string}
   * @memberof Sensor
   */
  thingId?: string;
  /**
   * @type {string}
   * @memberof Sensor
   */
  virtualFridgeId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SensorSensorTypeEnum {
  DOOR = 'DOOR',
  TEMPERATURE = 'TEMPERATURE'
}
