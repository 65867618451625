var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "align-start": "" } },
    [
      _c(
        "v-flex",
        { staticClass: "secondary-tilename", attrs: { "align-start": "" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "shorttext",
                  rawName: "v-shorttext",
                  value: 160,
                  expression: "160"
                }
              ]
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        ]
      ),
      _c(
        "v-flex",
        { staticClass: "secondary-alerttext", attrs: { "align-start": "" } },
        [_vm._v(_vm._s(_vm.alertText))]
      ),
      _c(
        "v-flex",
        {
          staticClass: "secondary-appliancestext",
          attrs: { "align-start": "" }
        },
        [_vm._v(_vm._s(_vm.applianceText))]
      ),
      _c(
        "v-flex",
        { staticClass: "secondary-sensorstext", attrs: { "align-start": "" } },
        [_vm._v(_vm._s(_vm.sensorText))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }