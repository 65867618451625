var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner", class: _vm.color },
    [
      _c("v-icon", { staticClass: "icon" }, [_vm._v(_vm._s(_vm.icon))]),
      _c(
        "div",
        { staticClass: "appliance" },
        [
          _c(
            "div",
            { staticClass: "header", style: _vm.smartModuleHeaderStyle },
            [_vm._v(_vm._s(_vm.applianceName))]
          ),
          _c(
            "v-layout",
            { staticClass: "appliancealarm", attrs: { row: "", "pr-2": "" } },
            [
              _c("v-icon", { attrs: { size: "35px" } }, [
                _vm._v(_vm._s(_vm.smartModuleAlarmIcon))
              ]),
              _c("div", { style: _vm.smartModuleTextStyle }, [
                _vm._v(_vm._s(_vm.smartModuleAlarmText))
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.licensed
        ? _c(
            "div",
            { staticClass: "zone" },
            [
              _c(
                "v-layout",
                {
                  staticStyle: {
                    "justify-content": "flex-end",
                    "margin-top": "4px",
                    "margin-right": "10px"
                  },
                  attrs: { row: "" }
                },
                _vm._l(_vm.zones, function(zone, index) {
                  return _c("div", { key: index }, [
                    _c("div", { staticClass: "zonealarm" }, [
                      _c("span", { style: _vm.getZoneStateStyle(zone) }, [
                        _vm._v(_vm._s(_vm.getAlarmZoneText(zone)))
                      ])
                    ])
                  ])
                }),
                0
              ),
              _c(
                "v-layout",
                {
                  staticStyle: {
                    "justify-content": "flex-end",
                    "margin-top": "4px",
                    "margin-right": "20px"
                  },
                  attrs: { row: "" }
                },
                _vm._l(_vm.zones, function(zone, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      { staticClass: "zonestate" },
                      [
                        _vm.hasZoneAlarm(zone)
                          ? _c(
                              "v-icon",
                              { staticStyle: { "align-self": "center" } },
                              [_vm._v(_vm._s(_vm.getZoneAlarmIcon(zone)))]
                            )
                          : _vm._e(),
                        _c("span", { style: _vm.getZoneStateStyle(zone) }, [
                          _vm._v(_vm._s(_vm.getSmartModuleApplianceState(zone)))
                        ])
                      ],
                      1
                    )
                  ])
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm.TypeChecker.isApplianceWithTemperature(_vm.appliance) && _vm.licensed
        ? _c("p", { staticClass: "update" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("general.actualization")) +
                ": " +
                _vm._s(_vm.$format.dateTimeShort(_vm.since)) +
                "\n  "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }