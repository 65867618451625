var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "", "fill-height": "" } },
    [
      _c(
        "v-carousel",
        {
          staticClass: "logincarousel",
          attrs: { height: "100vh", "hide-delimiters": "", "hide-controls": "" }
        },
        [
          _vm._l(_vm.items, function(item, i) {
            return _c(
              "v-carousel-item",
              {
                key: i,
                staticStyle: { transition: "all 1s ease" },
                attrs: { src: item.src }
              },
              [_c("v-img", { attrs: { height: "100vh" } })],
              1
            )
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "380px", persistent: "" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "rounded-card", attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "display-2 text-md-center" },
                    [_vm._v(_vm._s(_vm.$t("general.home.loginerror.title")))]
                  ),
                  _c("v-divider", { attrs: { light: "" } }),
                  _c("v-card-text", {
                    staticClass: "text-md-center",
                    domProps: { innerHTML: _vm._s(_vm.reason) }
                  }),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value: "LoginError::Logout",
                              expression: "'LoginError::Logout'"
                            }
                          ],
                          attrs: {
                            depressed: "",
                            round: "",
                            color: "secondary",
                            outline: "",
                            dark: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.doLogout()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("general.cancel")))]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value: "LoginError::RegisterCompany",
                              expression: "'LoginError::RegisterCompany'"
                            }
                          ],
                          attrs: {
                            depressed: "",
                            round: "",
                            color: "primary",
                            dark: "",
                            href: _vm.getCompanyRegistrationLink(),
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(_vm.registerButton) + "\n          ")]
                      ),
                      _vm.isTempAdminExpired()
                        ? _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "LoginError::ShowContacts",
                                  expression: "'LoginError::ShowContacts'"
                                }
                              ],
                              attrs: {
                                depressed: "",
                                round: "",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  _vm.contactShown = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("general.contacts")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("ContactInfoDialog", {
                attrs: {
                  dialogShown: _vm.contactShown,
                  track: "LoginError::Contacts"
                },
                on: {
                  evtClose: function($event) {
                    _vm.contactShown = false
                  }
                }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }