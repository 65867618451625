// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SensorState
 */
export interface SensorState {
  /**
   * @type {Array<string>}
   * @memberof SensorState
   */
  alarmTypes?: Array<string>;
  /**
   * @type {number}
   * @memberof SensorState
   */
  batteryLevel?: number;
  /**
   * @type {string}
   * @memberof SensorState
   */
  doorState?: SensorStateDoorStateEnum;
  /**
   * @type {string}
   * @memberof SensorState
   */
  gatewayId?: string;
  /**
   * @type {string}
   * @memberof SensorState
   */
  gatewayState?: string;
  /**
   * @type {string}
   * @memberof SensorState
   */
  sensorId?: string;
  /**
   * @type {string}
   * @memberof SensorState
   */
  sensorType?: SensorStateSensorTypeEnum;
  /**
   * @type {number}
   * @memberof SensorState
   */
  signalStrength?: number;
  /**
   * @type {Date}
   * @memberof SensorState
   */
  since?: Date;
  /**
   * @type {string}
   * @memberof SensorState
   */
  state?: string;
  /**
   * @type {number}
   * @memberof SensorState
   */
  temperature?: number;
  /**
   * @type {string}
   * @memberof SensorState
   */
  thingId?: string;
  /**
   * @type {string}
   * @memberof SensorState
   */
  virtualFridgeId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SensorStateDoorStateEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}
/**
 * @export
 * @enum {string}
 */
export enum SensorStateSensorTypeEnum {
  DOOR = 'DOOR',
  TEMPERATURE = 'TEMPERATURE'
}
