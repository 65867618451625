var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: _vm.track + "::Close",
          dialogShown: _vm.cancelDialogShown
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.$t("biz.onboarding.binderscan.applianceinfo.title")
                  ) + _vm._s(_vm.selectedGatewayName)
                )
              ]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t(
                        "biz.onboarding.binderscan.applianceinfo.ipdescription"
                      )
                    )
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  { attrs: { column: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs10: "" } },
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "margin-bottom": "0px",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("general.ipaddress")))]
                        ),
                        _c("v-text-field", {
                          staticClass: "default selected errorborder",
                          attrs: {
                            clearable: _vm.iconHelper.clearableIcon(
                              _vm.ipAddress
                            ),
                            placeholder: _vm.$t("general.ipaddress"),
                            rules: _vm.ipAddressRules,
                            "return-masked-value": "",
                            required: ""
                          },
                          model: {
                            value: _vm.ipAddress,
                            callback: function($$v) {
                              _vm.ipAddress = $$v
                            },
                            expression: "ipAddress"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.checked && !_vm.checking
                      ? _c(
                          "v-flex",
                          { staticClass: "pb-4", attrs: { "offset-xs1": "" } },
                          [
                            _c("v-img", {
                              staticClass: "mt-1",
                              attrs: {
                                src: require("@/assets/serialnumber_binder.png"),
                                contain: "",
                                "max-height": "300"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.checked
                      ? _c("SetupApplianceInfo", {
                          attrs: {
                            applianceInfo: _vm.applianceInfo
                              ? _vm.applianceInfo
                              : "",
                            serialNumber: _vm.serialNumber
                          }
                        })
                      : _vm._e(),
                    _vm.checked
                      ? _c("v-flex", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "biz.onboarding.binderscan.applianceinfo.description1"
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "v-layout",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.checked && _vm.checking,
                            expression: "!checked && checking"
                          }
                        ],
                        staticStyle: { height: "31vh" },
                        attrs: {
                          column: "",
                          "align-center": "",
                          "justify-center": ""
                        }
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            indeterminate: "",
                            color: "secondary",
                            size: "48"
                          }
                        }),
                        _c("p", { staticClass: "bus_normaltext mt-5" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "biz.onboarding.binderscan.applianceinfo.checking"
                              )
                            )
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("ErrorWithHelpDialog", {
                  attrs: {
                    dialogShown: _vm.applianceAlreadyTaken,
                    title: _vm.$t("biz.onboarding.stepper.applianceinusetitle"),
                    message: _vm.message,
                    serialNumber: _vm.serialNumber,
                    showGoToSmartMonitoring: _vm.showGoToSmartMonitoring,
                    track: _vm.track + "::AlreadyOnboarded"
                  },
                  on: {
                    evtCancelPressed: function($event) {
                      return _vm.closeApplianceInUseError()
                    },
                    evtRetryPressed: function($event) {
                      return _vm.retryWithNewSerialNumber()
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "helplink",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: _vm.track + "::HelpUserManual",
                            expression: "track + '::HelpUserManual'"
                          }
                        ],
                        attrs: {
                          depressed: "",
                          color: "secondary",
                          outline: ""
                        },
                        on: {
                          click: function($event) {
                            _vm.showManualsDialog = !_vm.showManualsDialog
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: { color: "secondary" }
                          },
                          [_vm._v("help_outline")]
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("biz.onboarding.manuals.helpertitle")
                            ) +
                            "\n        "
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("ApplianceManualsDialog", {
        attrs: {
          binderSetup: true,
          dialogShown: _vm.showManualsDialog,
          track: _vm.track + "::ApplianceManualsDialog"
        },
        on: {
          evtClosed: function($event) {
            _vm.showManualsDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }