var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedApplianceGroup
    ? _c(
        "v-dialog",
        {
          attrs: { value: _vm.dialogShown, persistent: "", "max-width": "700" }
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "", tile: "" } },
            [
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  _c("v-card-title", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("biz.settings.permittedappliances")))
                  ]),
                  _c("v-text-field", {
                    staticClass: "default selected",
                    staticStyle: {
                      "margin-left": "10px",
                      "margin-right": "10px"
                    },
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(_vm.searchString),
                      placeholder: _vm.$t("general.search") + "..."
                    },
                    scopedSlots: _vm._u(
                      [
                        !_vm.iconHelper.clearableIcon(_vm.searchString)
                          ? {
                              key: "append",
                              fn: function() {
                                return [
                                  _c("v-icon", { staticClass: "searchicon" }, [
                                    _vm._v("search")
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.searchString,
                      callback: function($$v) {
                        _vm.searchString = $$v
                      },
                      expression: "searchString"
                    }
                  }),
                  _c("v-divider"),
                  _c(
                    "v-flex",
                    { staticClass: "dialogcontainer" },
                    [
                      _c(
                        "v-list",
                        { staticClass: "grouplist pl-1 mr-1" },
                        [
                          _vm._l(_vm.applianceGroups, function(group, index) {
                            return [
                              _c(
                                "v-list-tile",
                                {
                                  key: index,
                                  class: _vm.groupHighlight(group),
                                  staticStyle: { "margin-top": "1px" },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectApplianceGroup(group)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-title", [
                                        group.defaultGroup
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "biz.groupeditor.default"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(_vm._s(group.name))
                                            ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      ),
                      _vm.selectedApplianceGroup.appliances.length === 0
                        ? _c("div", [
                            _c("p", { staticClass: "ma-3 pa-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("biz.settings.noapplianceingroup")
                                )
                              )
                            ])
                          ])
                        : _c(
                            "v-list",
                            {
                              staticClass: "appliancelist pa-0",
                              attrs: { dense: "" }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "mt-3",
                                      staticStyle: {
                                        "font-weight": "bold",
                                        "text-transform": "uppercase",
                                        "margin-left": "3px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "biz.settings.selectappliances"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _c("v-checkbox", {
                                    key: _vm.selectedApplianceGroup.groupId,
                                    attrs: {
                                      color: "secondary",
                                      value: _vm.selectedApplianceGroup.groupId,
                                      label: _vm.$t(
                                        "biz.settings.selectallappliances"
                                      ),
                                      indeterminate: _vm.isSelectedGroupIndeterminate()
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.selectUnselectAllAppliances()
                                      }
                                    },
                                    model: {
                                      value: _vm.selectedGroups,
                                      callback: function($$v) {
                                        _vm.selectedGroups = $$v
                                      },
                                      expression: "selectedGroups"
                                    }
                                  }),
                                  _c("v-divider", { staticClass: "mb-3" })
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.getFilteredAppliances(
                                  _vm.selectedApplianceGroup.appliances,
                                  _vm.searchString
                                ),
                                function(app, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "listpermappl" },
                                    [
                                      _c("v-checkbox", {
                                        key: app.name,
                                        class: _vm.userHasAppliancePermission(
                                          app
                                        ),
                                        staticStyle: {
                                          height: "65px",
                                          "padding-top": "5px"
                                        },
                                        attrs: {
                                          value: app,
                                          color: "secondary"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        column: "",
                                                        "fill-height": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(app.name)
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "appliancemodel"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(app.model)
                                                          )
                                                        ]
                                                      ),
                                                      _vm.getSensorsByVirtualFridgeId(
                                                        app.virtualFridgeId
                                                      ).length > 0
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--v-bus_grey-base)"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    _vm.$tc(
                                                                      "biz.sensors.sensors",
                                                                      _vm.getSensorsByVirtualFridgeId(
                                                                        app.virtualFridgeId
                                                                      ).length
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.selected,
                                          callback: function($$v) {
                                            _vm.selected = $$v
                                          },
                                          expression: "selected"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }
                              )
                            ],
                            2
                          )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c("SaveCancel", {
                        attrs: {
                          saveDisabled: _vm.saveDisabled,
                          track: _vm.track,
                          icon: false
                        },
                        on: {
                          evtSave: function($event) {
                            return _vm.onSave()
                          },
                          evtCancel: function($event) {
                            return _vm.onCancel()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }