var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.applianceinfo.properties")))
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "align-items: end", attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { editDisabled: _vm.isDisabled(), track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.name))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.timezone.title")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.timezone))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { wrap: "", "pt-3": "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("general.manufacturer")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.model")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.model))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.serialnumber")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.serialNumber))
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.version")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.softwareVersion))
            ])
          ])
        ],
        1
      ),
      _vm.isSoftwareOutdated
        ? _c(
            "v-layout",
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("p", { staticClass: "bus_warningtext" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("biz.onboarding.start.outdatedsoftwarewarning")
                      ) +
                      "\n      "
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.isSoftwareOutdated
        ? _c(
            "v-layout",
            [
              _c(
                "v-flex",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value:
                            "ManufacturerGatewayInfo::UpdateOutdatedSoftware",
                          expression:
                            "'ManufacturerGatewayInfo::UpdateOutdatedSoftware'"
                        }
                      ],
                      staticClass: "ml-0",
                      attrs: { depressed: "", color: "secondary", outline: "" },
                      on: {
                        click: function($event) {
                          return _vm.openSoftwareUpdateDialog()
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "pr-1", attrs: { color: "secondary" } },
                        [_vm._v(_vm._s(_vm.helpIcon))]
                      ),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("biz.onboarding.start.updatesoftware")
                          ) +
                          "\n      "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700px" },
          model: {
            value: _vm.editorShown,
            callback: function($$v) {
              _vm.editorShown = $$v
            },
            expression: "editorShown"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))
              ]),
              _c(
                "v-layout",
                { attrs: { column: "", "pa-3": "" } },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))]
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(_vm.editableName),
                      placeholder: _vm.$t("biz.applianceinfo.name"),
                      type: "string",
                      required: "",
                      rules: _vm.bsdbNameRules
                    },
                    model: {
                      value: _vm.editableName,
                      callback: function($$v) {
                        _vm.editableName = $$v
                      },
                      expression: "editableName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("SaveCancel", {
                    attrs: {
                      track: _vm.track,
                      icon: false,
                      saveDisabled: _vm.saveDisabled
                    },
                    on: {
                      evtSave: function($event) {
                        return _vm.onSave()
                      },
                      evtCancel: function($event) {
                        return _vm.onCancel()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("BsdbSoftwareUpdateDialog", {
        directives: [
          {
            name: "track",
            rawName: "v-track",
            value: "OnboardingLanding::SoftwareUpdateDialog",
            expression: "'OnboardingLanding::SoftwareUpdateDialog'"
          }
        ],
        attrs: {
          dialogShown: _vm.showSoftwareUpdateDialog,
          selectedGateway: _vm.selectedGateway
        },
        on: {
          evtClose: function($event) {
            _vm.showSoftwareUpdateDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }