var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      _vm.loading
        ? _c(
            "v-layout",
            {
              staticStyle: { "font-size": "30px", color: "LightGray" },
              attrs: {
                slot: "content",
                column: "",
                xs12: "",
                "align-center": "",
                "justify-center": "",
                "fill-height": ""
              },
              slot: "content"
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.waiting")))
              ])
            ],
            1
          )
        : [
            _c(
              "v-layout",
              { attrs: { slot: "header" }, slot: "header" },
              [
                _c(
                  "v-flex",
                  {
                    staticStyle: { "align-self": "center" },
                    attrs: { xs4: "" }
                  },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: "Sensor::GoBack",
                                expression: "'Sensor::GoBack'"
                              }
                            ],
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { flat: "", icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.goBack()
                              }
                            }
                          },
                          [
                            _c(
                              "v-avatar",
                              { attrs: { color: "secondary", size: "40" } },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "white" } },
                                  [_vm._v("keyboard_arrow_left")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          {
                            staticClass: "px-2",
                            staticStyle: { "align-self": "center" }
                          },
                          [
                            _c("v-layout", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.$t("biz.groups.pagename")))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-divider"),
            _c(
              "v-container",
              { attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" } },
              [
                _c("CriticalDevicesPage", {
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _c(
                            "v-layout",
                            { attrs: { column: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", lg10: "" } },
                                [
                                  _vm.gateway
                                    ? _c("SecondaryGatewayBanner", {
                                        attrs: { gateway: _vm.gateway }
                                      })
                                    : _vm._e(),
                                  _vm.applianceZone
                                    ? _c(
                                        "v-layout",
                                        { attrs: { row: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { grow: "" } },
                                            [
                                              _c("SecondaryMeasureSiteBanner", {
                                                attrs: {
                                                  applianceZone:
                                                    _vm.applianceZone
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.hasTwoZones
                                            ? _c(
                                                "v-flex",
                                                {
                                                  staticClass:
                                                    "hidden-sm-and-down",
                                                  attrs: { shrink: "" }
                                                },
                                                [
                                                  _c("SecondZoneBanner", {
                                                    attrs: {
                                                      isPrimary: false,
                                                      applianceZone:
                                                        _vm.applianceZone
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("SensorBanner", {
                                    attrs: {
                                      sensor: _vm.sensor,
                                      licensed: _vm.licensed,
                                      track: "Sensor::Banner"
                                    }
                                  }),
                                  _c(
                                    "v-card",
                                    { attrs: { "fill-height": "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { row: "", "fill-height": "" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            { staticClass: "pb-0" },
                                            [
                                              _c(
                                                "v-tabs",
                                                {
                                                  attrs: {
                                                    slot: "extension",
                                                    color: "transparent"
                                                  },
                                                  slot: "extension",
                                                  model: {
                                                    value: _vm.selectedTab,
                                                    callback: function($$v) {
                                                      _vm.selectedTab = $$v
                                                    },
                                                    expression: "selectedTab"
                                                  }
                                                },
                                                [
                                                  _vm.licensed
                                                    ? _c(
                                                        "v-tab",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "track",
                                                              rawName:
                                                                "v-track",
                                                              value:
                                                                "Sensor::SensorAlarms",
                                                              expression:
                                                                "'Sensor::SensorAlarms'"
                                                            }
                                                          ],
                                                          attrs: {
                                                            to: {
                                                              name:
                                                                "SensorAlarms"
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "general.alarms"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-tab",
                                                        {
                                                          attrs: {
                                                            disabled: true
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "general.alarms"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                  _vm.licensed
                                                    ? _c(
                                                        "v-tab",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "track",
                                                              rawName:
                                                                "v-track",
                                                              value:
                                                                "Sensor::SensorAlarmProfile",
                                                              expression:
                                                                "'Sensor::SensorAlarmProfile'"
                                                            }
                                                          ],
                                                          attrs: {
                                                            to: {
                                                              name:
                                                                "SensorAlarmProfile"
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "general.alarmsetting"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-tab",
                                                        {
                                                          attrs: {
                                                            disabled: true
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "general.alarmsetting"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                  _c(
                                                    "v-tab",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "track",
                                                          rawName: "v-track",
                                                          value:
                                                            "Sensor::SensorInfo",
                                                          expression:
                                                            "'Sensor::SensorInfo'"
                                                        }
                                                      ],
                                                      attrs: {
                                                        to: {
                                                          name: "SensorInfo"
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "general.properties"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              directives: [
                                                {
                                                  name: "track",
                                                  rawName: "v-track",
                                                  value:
                                                    "Sensor::SendServiceRequest",
                                                  expression:
                                                    "'Sensor::SendServiceRequest'"
                                                }
                                              ],
                                              staticClass: "mt-3",
                                              attrs: {
                                                depressed: "",
                                                outline: "",
                                                color: "secondary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openServiceDialog()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { size: "20px" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.IconHelper.getServiceRequest2Icon()
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "biz.help.servicedetails.title"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.isAdminUser
                                            ? _c(
                                                "v-btn",
                                                {
                                                  directives: [
                                                    {
                                                      name: "track",
                                                      rawName: "v-track",
                                                      value: "Sensor::Delete",
                                                      expression:
                                                        "'Sensor::Delete'"
                                                    }
                                                  ],
                                                  staticClass: "mt-3",
                                                  attrs: {
                                                    depressed: "",
                                                    outline: "",
                                                    color: "secondary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.checkDeleteSensor()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { size: "20px" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "$vuetify.icons.bus_delete"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "biz.sensors.sensorinfo.deletesensor"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        {
                                          staticStyle: {
                                            "max-height": "85%",
                                            width: "100%"
                                          },
                                          attrs: {
                                            "fill-height": "",
                                            "ml-0": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticStyle: {
                                                "max-width": "none"
                                              },
                                              attrs: {
                                                "fill-height": "",
                                                "ma-0": ""
                                              }
                                            },
                                            [
                                              _c("router-view", {
                                                attrs: {
                                                  gatewayId: _vm.gatewayId,
                                                  currentSensor:
                                                    _vm.currentSensor,
                                                  sensorId: _vm.sensorId
                                                },
                                                on: {
                                                  evtSamplingRateError:
                                                    _vm.onSamplingRateError
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ],
      _c("SensorError", {
        attrs: {
          sensorId: _vm.sensorId,
          gatewaySerialNumber: _vm.gatewayId,
          dialogShown: _vm.errorShown,
          errorType: _vm.errorType
        },
        on: {
          evtRetry: function($event) {
            return _vm.onDeleteConfirm()
          },
          evtAbort: function($event) {
            return _vm.onDeleteCancel()
          }
        }
      }),
      _c("DeleteDialog", {
        attrs: {
          dialogShown: _vm.showDeleteConfirmationDialog,
          title: _vm.$t("biz.sensors.sensorinfo.deletesensortitle"),
          message: _vm.$t("biz.sensors.sensorinfo.deletesensortext"),
          track: "Sensor::AskDelete"
        },
        on: {
          evtDeletePressed: function($event) {
            return _vm.onDeleteConfirm()
          },
          evtCancelPressed: function($event) {
            return _vm.onDeleteCancel()
          }
        }
      }),
      _c("OkDialog", {
        attrs: {
          dialogShown: _vm.showDeletedDialog,
          title: _vm.$t("biz.sensors.sensorinfo.deletedtitle"),
          message: _vm.$t("biz.sensors.sensorinfo.deletedmessage"),
          track: "Sensor::DeleteSuccess"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.goToDevices()
          }
        }
      }),
      _c("ErrorWithHelpDialog", {
        attrs: {
          dialogShown: _vm.showDeleteError,
          title: _vm.$t("biz.onboarding.error.deletetitle"),
          message: _vm.$t("biz.sensors.sensorinfo.deleteerrortext"),
          sensorId: _vm.sensorId,
          track: "Sensor::DeleteFailed"
        },
        on: {
          evtCancelPressed: function($event) {
            _vm.showDeleteError = false
          },
          evtRetryPressed: function($event) {
            return _vm.onDeleteConfirm()
          }
        }
      }),
      _c("ServiceDialog", {
        attrs: {
          dialogShown: _vm.showServiceDialog,
          selectedSensor: _vm.sensor,
          track: "Sensor::SensorServiceRequest"
        },
        on: {
          "update:dialogShown": function($event) {
            _vm.showServiceDialog = $event
          },
          "update:dialog-shown": function($event) {
            _vm.showServiceDialog = $event
          },
          evtServiceDialogClose: function($event) {
            return _vm.onServiceDialogClose()
          }
        }
      }),
      _c("SimpleDialog", {
        attrs: {
          title: _vm.$t("biz.help.servicedetails.title"),
          message: _vm.$t("biz.help.servicedetails.testoinfo"),
          track: "Testo::SensorRequest",
          dialogShown: _vm.sensorRequestInfoFlag
        },
        on: {
          evtClose: function($event) {
            return _vm.onServiceDialogClose()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }