var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", lazy: "", "max-width": "750px" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        { staticStyle: { overflow: "hidden" }, attrs: { height: "600px" } },
        [
          _c(
            "v-layout",
            { attrs: { "fill-height": "", column: "" } },
            [
              _c(
                "v-layout",
                {
                  staticStyle: { "border-bottom": "solid 1px neutral20" },
                  attrs: { row: "" }
                },
                [
                  _c("v-card-title", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.help.softwarelicense.licensestitle"))
                    )
                  ]),
                  _c("v-spacer"),
                  _vm.closeable
                    ? _c("Cancel", {
                        attrs: {
                          icon: "true",
                          track: '"LicensesDialog::Close"'
                        },
                        on: {
                          evtCancel: function($event) {
                            return _vm.close()
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  staticStyle: { "min-height": "0px" },
                  attrs: { row: "", "fill-height": "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "leftpanel", attrs: { xs5: "" } },
                    [
                      _c(
                        "v-list",
                        {
                          staticStyle: {
                            "padding-left": "15px",
                            overflow: "auto"
                          }
                        },
                        [
                          _c(
                            "v-list-tile",
                            {
                              key: "group0",
                              class: _vm.highlight(0),
                              on: {
                                click: function($event) {
                                  return _vm.selectLicenseGroup(0)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "biz.help.softwarelicense.SMLicenses"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-tile",
                            {
                              key: "group1",
                              class: _vm.highlight(1),
                              on: {
                                click: function($event) {
                                  return _vm.selectLicenseGroup(1)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "biz.help.softwarelicense.SMBackendLicenses"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list-tile",
                            {
                              key: "group2",
                              class: _vm.highlight(2),
                              on: {
                                click: function($event) {
                                  return _vm.selectLicenseGroup(2)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "biz.help.softwarelicense.SCHLicenses"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.isAuthenticated
                            ? _c(
                                "v-list-tile",
                                {
                                  key: "group3",
                                  class: _vm.highlight(3),
                                  on: {
                                    click: function($event) {
                                      return _vm.selectLicenseGroup(3)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.help.softwarelicense.versioninfo"
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-flex",
                    { staticClass: "rightpanel", attrs: { xs7: "" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "license-detail-list",
                          attrs: { id: "rightpanel" }
                        },
                        [
                          _vm.selectedLicenseIndex < 2
                            ? _vm._l(_vm.allLicenses, function(item, index) {
                                return _c(
                                  "div",
                                  { key: "license" + index },
                                  [
                                    _c("h3", { staticClass: "mb-2" }, [
                                      _vm._v(_vm._s(item.name))
                                    ]),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.help.softwarelicense.libraryversion"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(
                                        "\n                  : " +
                                          _vm._s(item.comment) +
                                          "\n                "
                                      )
                                    ]),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.help.softwarelicense.librarylink"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(
                                        "\n                  : " +
                                          _vm._s(item.link) +
                                          "\n                "
                                      )
                                    ]),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.help.softwarelicense.licensetype"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(
                                        "\n                  : " +
                                          _vm._s(item.licenseType) +
                                          "\n                "
                                      )
                                    ]),
                                    _c("p", { staticClass: "mb-0" }, [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.help.softwarelicense.licenseperiod"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(
                                        "\n                  : " +
                                          _vm._s(item.licensePeriod) +
                                          "\n                "
                                      )
                                    ]),
                                    index >= _vm.allLicenses.length - 1
                                      ? _c("v-spacer", { staticClass: "mb-4" })
                                      : _c("v-divider", {
                                          staticClass: "mt-2 mb-2"
                                        })
                                  ],
                                  1
                                )
                              })
                            : _vm.selectedLicenseIndex === 2
                            ? [_c("SCHLicenses")]
                            : [_c("VersionInfo")]
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }