import { render, staticRenderFns } from "./IconAlarmTemperatureOn.vue?vue&type=template&id=473a9685&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/hau-bus-ui_master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('473a9685')) {
      api.createRecord('473a9685', component.options)
    } else {
      api.reload('473a9685', component.options)
    }
    module.hot.accept("./IconAlarmTemperatureOn.vue?vue&type=template&id=473a9685&", function () {
      api.rerender('473a9685', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/general/icons/alarm/IconAlarmTemperatureOn.vue"
export default component.exports