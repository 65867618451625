var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "windowpage" }, [
    _c("div", { staticClass: "header-container" }, [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "logo" },
          [
            _c("v-img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.dialogShown,
                  expression: "!dialogShown"
                }
              ],
              staticClass: "logo-img",
              attrs: {
                src: require("@/assets/logo_ci_liebherr.svg"),
                contain: ""
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "windowtitle" },
          [
            _vm._t("title"),
            _vm.readyFlag
              ? _c("Cancel", {
                  staticStyle: { float: "right" },
                  attrs: {
                    icon: false,
                    dialogType: _vm.dialogType,
                    track: _vm.trackCancel
                  },
                  on: {
                    evtCancel: function($event) {
                      return _vm.cancelProcess()
                    }
                  }
                })
              : _vm._e()
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "topline" },
        [
          _c(
            "v-flex",
            { staticClass: "bus_normaltext tiny" },
            [_vm._t("description")],
            2
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "content-container" }, [
      _c("div", { staticClass: "content" }, [
        !_vm.finishStep
          ? _c(
              "div",
              {
                staticClass: "content scroll-y",
                staticStyle: { width: "650px", margin: "auto" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tiny",
                    staticStyle: { width: "625px", margin: "auto" },
                    attrs: { "grid-list-lg": "" }
                  },
                  [
                    _c(
                      "v-flex",
                      { staticClass: "bus_normaltext" },
                      [_vm._t("subtitle")],
                      2
                    ),
                    _c(
                      "v-flex",
                      { staticClass: "bus_normaltext pt-2 pb-1" },
                      [_vm._t("content")],
                      2
                    )
                  ],
                  1
                )
              ]
            )
          : _c("div", { staticClass: "finishcontent" }, [_vm._t("finish")], 2)
      ]),
      !_vm.finishStep
        ? _c(
            "div",
            { staticClass: "bottomline" },
            [
              _c(
                "v-flex",
                { staticClass: "tiny", attrs: { "grid-list-lg": "" } },
                [_vm._t("helplink")],
                2
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }