// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface HistoryDoorOpening
 */
export interface HistoryDoorOpening {
  /**
   * @type {Date}
   * @memberof HistoryDoorOpening
   */
  doorClosedTimestamp?: Date;
  /**
   * @type {Date}
   * @memberof HistoryDoorOpening
   */
  doorOpenedTimestamp?: Date;
  /**
   * @type {number}
   * @memberof HistoryDoorOpening
   */
  openDurationMillis?: number;
  /**
   * @type {string}
   * @memberof HistoryDoorOpening
   */
  thingId?: string;
  /**
   * @type {string}
   * @memberof HistoryDoorOpening
   */
  virtualFridgeId?: string;
}
