var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%" } },
    [
      _c("AlarmList", {
        attrs: {
          selectedApplianceZone: _vm.selectedApplianceZone,
          initialTimezoneString: _vm.initialTimezoneString,
          singleDeviceSelected: _vm.singleDeviceSelected,
          showDeviceFilter: true,
          csvFileName: _vm.csvFileName(),
          track: "ApplianceAlarms::AlarmList"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }