var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    { attrs: { bottom: "" } },
    [
      _c(
        "v-btn",
        {
          staticStyle: { "pointer-events": "none" },
          attrs: { slot: "activator", depressed: "", icon: "" },
          slot: "activator"
        },
        [
          _c(
            "v-badge",
            {
              attrs: {
                color: "bus_yellow",
                overlap: "",
                "v-model": _vm.missingShown
              }
            },
            [
              _vm.isAdminUser && _vm.missingCount > 0 && _vm.showIcon
                ? _c("span", { attrs: { slot: "badge" }, slot: "badge" }, [
                    _vm._v(_vm._s(_vm.$format.badgeNumber(_vm.missingCount)))
                  ])
                : _vm._e(),
              _c("v-icon", { class: _vm.getIconClass() }, [
                _vm._v("$vuetify.icons.bus_navigation_settings")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("span", [_vm._v(_vm._s(_vm.$t("biz.general.tooltip.settings")))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }