// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { LogEvent, LoggableContentMetadata } from './';

/**
 * @export
 * @interface LoggableContent
 */
export interface LoggableContent {
  /**
   * @type {Array<LogEvent>}
   * @memberof LoggableContent
   */
  events: Array<LogEvent>;
  /**
   * @type {LoggableContentMetadata}
   * @memberof LoggableContent
   */
  meta: LoggableContentMetadata;
}
