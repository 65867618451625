var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.cloudalarm")))
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "align-items: end", attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs9: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.clouddescription")))
            ])
          ]),
          _c("v-flex", { attrs: { xs3: "" } }, [
            _vm.editableDataLocal.alarmActivatedByUser
              ? _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v("(" + _vm._s(_vm.$t("general.activated")) + ")")
                ])
              : _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v("(" + _vm._s(_vm.$t("general.deactivated")) + ")")
                ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { "pt-3": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.mintemperature")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _vm.editableDataLocal.alarmActivatedByUser
              ? _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.editableDataLocal.temperatures[0]) +
                      "°C\n      "
                  )
                ])
              : _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.SHOW_BLANK))
                ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.maxtemperature")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _vm.editableDataLocal.alarmActivatedByUser
              ? _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.editableDataLocal.temperatures[1]) +
                      "°C\n      "
                  )
                ])
              : _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.SHOW_BLANK))
                ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.delaytime")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _vm.editableDataLocal.alarmActivatedByUser
              ? _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$tc(
                          "general.duration.minute",
                          _vm.editableDataLocal.cloudAlarmDelay
                        )
                      ) +
                      "\n      "
                  )
                ])
              : _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.SHOW_BLANK))
                ])
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700px" },
          model: {
            value: _vm.editorShown,
            callback: function($$v) {
              _vm.editorShown = $$v
            },
            expression: "editorShown"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.cloudalarm")))
              ]),
              _c("EditCloudAlarm", {
                attrs: {
                  editableData: _vm.editableDataLocal,
                  profileDetails: _vm.profileDetails
                },
                on: {
                  "update:editableData": function($event) {
                    _vm.editableDataLocal = $event
                  },
                  "update:editable-data": function($event) {
                    _vm.editableDataLocal = $event
                  }
                }
              }),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("SaveCancel", {
                    attrs: {
                      saveDisabled: _vm.saveDisabled,
                      track: _vm.track + "::SaveCancel",
                      icon: false
                    },
                    on: {
                      evtSave: function($event) {
                        return _vm.onSave()
                      },
                      evtCancel: function($event) {
                        return _vm.onCancel()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }