var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "secondary-tilecard" },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "fill-height": "" } },
        [
          _c("v-flex", [_vm._t("info")], 2),
          _c(
            "v-flex",
            { class: _vm.secondaryAlertIconClass },
            [_vm._t("alerticon")],
            2
          ),
          _c(
            "v-flex",
            { class: _vm.secondaryTypeIconClass },
            [_vm._t("type")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }