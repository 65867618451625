var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.licenseState !== _vm.LicenseState.LICENSED
    ? _c(
        "div",
        [
          _c("CardTileLayout", {
            attrs: { licensed: false },
            scopedSlots: _vm._u(
              [
                {
                  key: "main",
                  fn: function() {
                    return [
                      _c("v-layout", { attrs: { row: "" } }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.toMeasureSite(_vm.mainApplianceZone)
                              }
                            }
                          },
                          [
                            _c("MainTileLayout", {
                              attrs: { licensed: false, hasSensors: true },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "type",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "tile",
                                            attrs: {
                                              "align-center": "",
                                              "justify-center": ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.IconHelper.getUnlicensedIcon()
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "info",
                                    fn: function() {
                                      return [
                                        _vm.licenseState ===
                                        _vm.LicenseState.LOCKED
                                          ? _c("InfoTile", {
                                              attrs: {
                                                title: _vm.getThingTitle(
                                                  _vm.mainApplianceZone.name
                                                ),
                                                alertText: _vm.getLockedApplianceAlert(),
                                                sensorText: _vm.sensorText
                                              }
                                            })
                                          : _c("InfoTile", {
                                              attrs: {
                                                title: _vm.getThingTitle(
                                                  _vm.mainApplianceZone.name
                                                ),
                                                alertText: _vm.getUnlicensedApplianceAlert(),
                                                sensorText: _vm.sensorText
                                              }
                                            })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1794963326
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "setup",
                  fn: function() {
                    return [
                      _vm.licenseState !== _vm.LicenseState.LOCKED
                        ? _c(
                            "v-card",
                            {
                              staticStyle: { height: "42px" },
                              attrs: { flat: "" },
                              on: { click: _vm.activateLicense }
                            },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "setuporactivelicense" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.vouchers.activatelicense")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              962369860
            )
          })
        ],
        1
      )
    : _c(
        "div",
        [
          _c("CardTileLayout", {
            attrs: { licensed: true, measureSite: _vm.measureSite },
            scopedSlots: _vm._u([
              {
                key: "main",
                fn: function() {
                  return [
                    _c(
                      "v-layout",
                      {
                        attrs: { row: "" },
                        on: {
                          click: function($event) {
                            return _vm.toMeasureSite(_vm.mainApplianceZone)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("MainTileLayout", {
                              attrs: {
                                licensed: true,
                                padding: false,
                                hasSensors: true
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "type",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-flex",
                                          {
                                            class: _vm.getColor(),
                                            attrs: {
                                              "align-center": "",
                                              "justify-center": ""
                                            }
                                          },
                                          [
                                            _vm.showTemperature(
                                              _vm.mainApplianceZone
                                            )
                                              ? _c(
                                                  "v-flex",
                                                  {
                                                    class: _vm.getApplianceClass(
                                                      _vm.mainApplianceZone
                                                    ),
                                                    attrs: {
                                                      "align-center": "",
                                                      "justify-center": ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getApplianceTemperature(
                                                          _vm.mainApplianceZone
                                                        )
                                                      ) + "\n                "
                                                    )
                                                  ]
                                                )
                                              : _vm.isRoom(
                                                  _vm.mainApplianceZone
                                                )
                                              ? _c(
                                                  "v-icon",
                                                  { staticClass: "roomicon" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.IconHelper.getRoomIcon()
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm.isOtherMeasureSite(
                                                  _vm.mainApplianceZone
                                                )
                                              ? _c(
                                                  "v-icon",
                                                  { staticClass: "roomicon" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.IconHelper.getLHOApplianceIcon()
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "info",
                                    fn: function() {
                                      return [
                                        _c("InfoTile", {
                                          attrs: {
                                            title: _vm.getThingTitle(
                                              _vm.mainApplianceZone.name
                                            ),
                                            alertText: _vm.getCriticalApplianceAlert(
                                              _vm.mainApplianceZone
                                            ),
                                            sensorText: _vm.sensorText
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  !_vm.isSmartModule(_vm.mainApplianceZone)
                                    ? {
                                        key: "alerticon",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getAlarmIcon(
                                                    _vm.mainApplianceZone
                                                  )
                                                )
                                              )
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "alertcount" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getAlarmTypeCount(
                                                      _vm.mainApplianceZone
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    : null
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "sensors",
                fn: function() {
                  return [
                    _c("SensorTileList", {
                      attrs: { sensors: _vm.measureSiteSensors }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }