var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      class: _vm.appropriateCard,
      staticStyle: { width: "100%", "max-width": "100%" },
      attrs: { flat: "" }
    },
    [
      _c(
        "v-layout",
        { staticStyle: { margin: "1px" }, attrs: { row: "" } },
        [_c("v-flex", [_vm._t("main")], 2)],
        1
      ),
      _vm._t("sensors")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }