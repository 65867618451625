var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FilterMenu", {
    attrs: {
      filterFlag: _vm.filteringForDate,
      track: "PeriodFilter::FilterMenu"
    },
    on: { evtApplyFilter: _vm.onApplyFilter },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.period.title")))]
        },
        proxy: true
      },
      {
        key: "subTitle",
        fn: function() {
          return [_vm._v(_vm._s(_vm.filterSubtitle))]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "v-flex",
              {
                staticClass: "ma-3",
                staticStyle: {
                  "font-weight": "bold",
                  "text-transform": "uppercase"
                }
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("biz.dashboard.alarms.period.filtertitle"))
                )
              ]
            ),
            _c("DatePeriod", {
              ref: "datePeriod",
              attrs: {
                includeToday: true,
                defaultStartPeriod: _vm.startPeriod
              },
              on: { evtDateChanged: _vm.onFilterDate }
            }),
            _c(
              "v-btn",
              {
                directives: [
                  {
                    name: "track",
                    rawName: "v-track",
                    value: "PeriodFilter::ResetFilter",
                    expression: "'PeriodFilter::ResetFilter'"
                  }
                ],
                staticClass: "mt-0",
                attrs: { color: "secondary", outline: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.clearFilter()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.period.clear")))]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }