// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SmartModuleConnectionResponse
 */
export interface SmartModuleConnectionResponse {
  /**
   * @type {boolean}
   * @memberof SmartModuleConnectionResponse
   */
  connected?: boolean;
  /**
   * @type {Date}
   * @memberof SmartModuleConnectionResponse
   */
  connectionStateSince?: Date;
  /**
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  ipAddress?: string;
  /**
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  macAddress?: string;
  /**
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  networkType?: SmartModuleConnectionResponseNetworkTypeEnum;
  /**
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  smartModuleFridgeSerialNumber?: string;
  /**
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  smartModuleId?: string;
  /**
   * @type {string}
   * @memberof SmartModuleConnectionResponse
   */
  smartModuleSoftwareVersion?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SmartModuleConnectionResponseNetworkTypeEnum {
  LAN = 'LAN',
  WLAN = 'WLAN',
  WIFI = 'WIFI',
  UNKNOWN = 'UNKNOWN'
}
