var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "v-flex",
            { staticStyle: { "align-self": "center" }, attrs: { xs2: "" } },
            [
              _c("v-layout", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.general.items.alarms")))
              ])
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticStyle: { "align-self": "center" },
              attrs: { xs4: "", md5: "", lg6: "" }
            },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    slot: "extension",
                    color: "transparent",
                    centered: ""
                  },
                  slot: "extension"
                },
                [
                  _c(
                    "v-tab",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "Alarm::Overview",
                          expression: "'Alarm::Overview'"
                        }
                      ],
                      attrs: { to: "alarmOverview" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("biz.dashboard.alarms.alarmoverview"))
                      )
                    ]
                  ),
                  _c(
                    "v-tab",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "Alarm::Profiles",
                          expression: "'Alarm::Profiles'"
                        }
                      ],
                      attrs: { to: "alarmProfile" }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("biz.dashboard.alarms.alarmsettings"))
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" } },
        [
          _c("CriticalDevicesPage", {
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [_c("router-view")]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }