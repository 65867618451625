var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SimpleDialog", {
        attrs: {
          title: _vm.$t("biz.onboarding.scan.nolicensetitle"),
          message: _vm.$t("biz.licenseassignment.subtitle2"),
          track: "AssignLicenseDialog::Close",
          dialogShown: _vm.showAssignLicenseDialog
        },
        on: {
          evtClose: function($event) {
            return _vm.onCancel()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("AssignmentList", {
                  ref: "assignmentList",
                  attrs: {
                    unLicensedAppliances: _vm.unLicensedAppliances,
                    unAssignedLicenses: _vm.unAssignedLicenses,
                    applianceSelectionDisabled: _vm.applianceSelectionDisabled
                  },
                  on: {
                    evtLicenseSelected: _vm.onLicenseSelected,
                    evtApplianceSelected: _vm.onApplianceSelected
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "buttons",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  { attrs: { row: "", flex: "", "justify-end": "" } },
                  [
                    _c("Cancel", {
                      attrs: { icon: false, track: _vm.track },
                      on: {
                        evtCancel: function($event) {
                          return _vm.onCancel()
                        }
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: _vm.track + "::AssignLicense",
                            expression: "track + '::AssignLicense'"
                          }
                        ],
                        attrs: {
                          color: "primary",
                          depressed: "",
                          disabled: _vm.isAssignDisabled
                        },
                        on: {
                          click: function($event) {
                            return _vm.onLicenseAssign()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.actions.assign")) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("OkDialog", {
        attrs: {
          title: _vm.$t("biz.licenseassignment.responsehints.title"),
          message: _vm.licenseAssignmentStatusMessage,
          dialogShown: _vm.showAssignLicenseStatusDialog,
          track: _vm.track + "::LicenseKeyAssignment"
        },
        on: {
          evtOkPressed: function($event) {
            _vm.showAssignLicenseStatusDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }