// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface PatchAutoReport
 */
export interface PatchAutoReport {
  /**
   * @type {string}
   * @memberof PatchAutoReport
   */
  op?: string;
  /**
   * @type {string}
   * @memberof PatchAutoReport
   */
  path?: string;
  /**
   * @type {string}
   * @memberof PatchAutoReport
   */
  value?: string;
  /**
   * @type {Array<string>}
   * @memberof PatchAutoReport
   */
  vfids?: Array<string>;
}
