var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { width: "100%" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      _c(
        "v-layout",
        {
          attrs: {
            "align-start": "",
            "pt-2": "",
            "pl-4": "",
            "pr-3": "",
            "pb-2": ""
          }
        },
        [
          _c(
            "v-flex",
            { staticStyle: { width: "100%" } },
            [
              _c("SensorAlarmDetails", {
                attrs: {
                  currentSensor: _vm.currentSensor,
                  track: _vm.track + "::SensorAlarmDetails"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }