var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "80px",
        height: "80px",
        viewBox: "0 0 80 80",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("g", { attrs: { transform: "translate(11.000000, 2.000000)" } }, [
            _c("path", {
              staticStyle: { fill: "var(--v-secondary-base)" },
              attrs: {
                d:
                  "M39.1818182,59.00436 L39.1818182,62 C39.1818182,63.6568542 37.8386724,65 36.1818182,65 L5.81818182,65 C4.16132757,65 2.81818182,63.6568542 2.81818182,62 L2.81818182,59.00436 C1.18595695,58.5003005 3.41358492e-15,56.9795569 0,55.1818182 L0,4 C-2.705415e-16,1.790861 1.790861,1.29399067e-15 4,8.8817842e-16 L38,0 C40.209139,-4.05812251e-16 42,1.790861 42,4 L42,55.1818182 C42,56.9795569 40.814043,58.5003005 39.1818182,59.00436 L39.1818182,59.00436 Z M2,20 L40,20 L40,4 C40,2.8954305 39.1045695,2 38,2 L4,2 C2.8954305,2 2,2.8954305 2,4 L2,20 Z M2,22 L2,55.1818182 C2,56.2863877 2.8954305,57.1818182 4,57.1818182 L38,57.1818182 C39.1045695,57.1818182 40,56.2863877 40,55.1818182 L40,22 L2,22 Z M5.54545455,6 C5.54545455,5.44771525 5.9931698,5 6.54545455,5 C7.0977393,5 7.54545455,5.44771525 7.54545455,6 L7.54545455,16 C7.54545455,16.5522847 7.0977393,17 6.54545455,17 C5.9931698,17 5.54545455,16.5522847 5.54545455,16 L5.54545455,6 Z M5.54545455,26 C5.54545455,25.4477153 5.9931698,25 6.54545455,25 C7.0977393,25 7.54545455,25.4477153 7.54545455,26 L7.54545455,53 C7.54545455,53.5522847 7.0977393,54 6.54545455,54 C5.9931698,54 5.54545455,53.5522847 5.54545455,53 L5.54545455,26 Z M4.81818182,62 C4.81818182,62.5522847 5.26589707,63 5.81818182,63 L36.1818182,63 C36.7341029,63 37.1818182,62.5522847 37.1818182,62 L37.1818182,59.2727273 L4.81818182,59.2727273 L4.81818182,62 Z",
                "fill-rule": "nonzero"
              }
            }),
            _c(
              "g",
              { attrs: { transform: "translate(25.000000, 41.000000)" } },
              [
                _c("circle", {
                  staticStyle: { fill: "var(--v-secondary-base)" },
                  attrs: { cx: "17", cy: "17", r: "17" }
                }),
                _c(
                  "g",
                  {
                    staticStyle: { fill: "var(--v-bus_white-base)" },
                    attrs: { transform: "translate(5.000000, 5.000000)" }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M7.20269391,7.20269391 C8.01829453,3.64986122 11.1996931,1 15,1 C19.418278,1 23,4.581722 23,9 C23,12.8003069 20.3501388,15.9817055 16.7973061,16.7973061 C15.9817055,20.3501388 12.8003069,23 9,23 C4.581722,23 1,19.418278 1,15 C1,11.1996931 3.64986122,8.01829453 7.20269391,7.20269391 L7.20269391,7.20269391 Z M9.33892471,7.00704963 C13.4876106,7.17995766 16.8200423,10.5123894 16.9929504,14.6610753 C19.3270169,13.8393962 21,11.6150712 21,9 C21,5.6862915 18.3137085,3 15,3 C12.3849288,3 10.1606038,4.67298315 9.33892471,7.00704963 L9.33892471,7.00704963 Z M7.00704963,9.33892471 C4.67298315,10.1606038 3,12.3849288 3,15 C3,18.3137085 5.6862915,21 9,21 C11.6150712,21 13.8393962,19.3270169 14.6610753,16.9929504 C10.5123894,16.8200423 7.17995766,13.4876106 7.00704963,9.33892471 L7.00704963,9.33892471 Z M9,9 C9,12.3137085 11.6862915,15 15,15 C15,11.6862915 12.3137085,9 9,9 Z"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }