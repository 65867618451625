var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700px", "min-width": "600px" },
      model: {
        value: _vm.openGroupDialog,
        callback: function($$v) {
          _vm.openGroupDialog = $$v
        },
        expression: "openGroupDialog"
      }
    },
    [
      _c("BaseWindowItem", {
        attrs: { buttonExit: false },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(_vm._s(_vm.$t("biz.onboarding.stepper.groupheader")))
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("SimpleGroupEditor", {
                  on: {
                    evtGroupSelected: _vm.groupSelected,
                    evtGroupCreating: function($event) {
                      _vm.groupCreating = true
                    }
                  }
                }),
                _c(
                  "v-layout",
                  {
                    staticClass: "mt-4 mb-4",
                    staticStyle: { "justify-content": "flex-end" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "ChooseGroupDialog::Close",
                            expression: "'ChooseGroupDialog::Close'"
                          }
                        ],
                        attrs: {
                          depressed: "",
                          color: "primary",
                          disabled: _vm.groupCreating
                        },
                        on: {
                          click: function($event) {
                            return _vm.closeDialog()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.save")))]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }