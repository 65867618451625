var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.shownAsDialog
        ? _c(
            "v-layout",
            { attrs: { column: "", "align-center": "" } },
            [
              _c("v-flex", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("general.contactinfo.contactservice")
                  )
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { column: "", "align-center": "" } },
        [_c("v-flex", { domProps: { innerHTML: _vm._s(_vm.userCountry) } })],
        1
      ),
      _vm.buyLicenseSupport && _vm.supportInfo
        ? _c(
            "v-layout",
            { attrs: { column: "", "align-center": "" } },
            _vm._l(_vm.supportInfo.license, function(item, index) {
              return _c(
                "v-flex",
                { key: index, attrs: { "pa-0": "", "ma-0": "" } },
                [
                  _vm.isMail(item)
                    ? _c("a", { attrs: { href: _vm.getHrefTag(item) } }, [
                        _vm._v(_vm._s(item))
                      ])
                    : _c("v-flex", {
                        attrs: { "pa-0": "", "ma-0": "" },
                        domProps: { innerHTML: _vm._s(item) }
                      })
                ],
                1
              )
            }),
            1
          )
        : _vm.supportInfo
        ? _c(
            "v-layout",
            { attrs: { column: "", "align-center": "" } },
            _vm._l(_vm.supportInfo.contact, function(item, index) {
              return _c(
                "v-flex",
                { key: index, attrs: { "pa-0": "", "ma-0": "" } },
                [
                  _vm.isMail(item)
                    ? _c("a", { attrs: { href: _vm.getHrefTag(item) } }, [
                        _vm._v(_vm._s(item))
                      ])
                    : _c("v-flex", {
                        attrs: { "pa-0": "", "ma-0": "" },
                        domProps: { innerHTML: _vm._s(item) }
                      })
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.deviceIdsShown
        ? _c(
            "v-card",
            {
              staticClass: "mt-3 pa-2 mb-3",
              attrs: { flat: "", color: "neutral" }
            },
            [
              _c("v-flex", {
                attrs: { "pb-3": "" },
                domProps: {
                  innerHTML: _vm._s(_vm.$t("general.contactinfo.provideinfo"))
                }
              }),
              _vm.smartCoolingHubId
                ? _c("v-flex", [
                    _vm._v(
                      _vm._s(_vm.$t("biz.general.smartcoolinghubid")) +
                        ": " +
                        _vm._s(_vm.smartCoolingHubId)
                    )
                  ])
                : _vm._e(),
              _vm.sensorId
                ? _c("v-flex", [
                    _vm._v(
                      _vm._s(_vm.$t("biz.general.sensorid")) +
                        ": " +
                        _vm._s(_vm.sensorId)
                    )
                  ])
                : _vm._e(),
              _vm.serialNumber
                ? _c("v-flex", [
                    _vm._v(
                      _vm._s(_vm.$t("general.contactinfo.serialnumber")) +
                        ": " +
                        _vm._s(_vm.serialNumber)
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }