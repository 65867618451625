var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: "SmartModuleConnectionStatus::Close",
          dialogShown: _vm.cancelDialogShown
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(_vm._s(_vm.$t("biz.smartmoduleinfo.connectivity.title")))
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                !_vm.isLoading && !_vm.connectionErrorInfo
                  ? _c(
                      "div",
                      [
                        _c("h3", { staticStyle: { color: "black" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "biz.smartmoduleinfo.connectivity.subtitle"
                              )
                            )
                          )
                        ]),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "biz.smartmoduleinfo.connectivity.description"
                              )
                            )
                          )
                        ]),
                        _c(
                          "v-card",
                          { staticClass: "card" },
                          [
                            _c(
                              "v-layout",
                              { staticClass: "row", attrs: { row: "" } },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    staticStyle: {
                                      "text-transform": "uppercase"
                                    },
                                    attrs: { xs8: "" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("general.ipaddress")))]
                                ),
                                _c("v-flex", { attrs: { xs4: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.connectionResponse.ipAddress)
                                  )
                                ])
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "v-layout",
                              { staticClass: "row", attrs: { row: "" } },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    staticStyle: {
                                      "text-transform": "uppercase"
                                    },
                                    attrs: { xs8: "" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("general.mac")))]
                                ),
                                _c("v-flex", { attrs: { xs4: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("general.checkmacaddress"))
                                  )
                                ])
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "v-layout",
                              { staticClass: "row", attrs: { row: "" } },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    staticStyle: {
                                      "text-transform": "uppercase"
                                    },
                                    attrs: { xs8: "" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("biz.bsdbinfo.connectionstate")
                                      )
                                    )
                                  ]
                                ),
                                _c("v-flex", { attrs: { xs4: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.connectionResponse.connected
                                        ? _vm.$t("general.connected")
                                        : _vm.$t("general.unconnected")
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        !_vm.connectionResponse.connected
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "track",
                                        rawName: "v-track",
                                        value:
                                          "SmartModuleConnectionStatus::RequestStatus",
                                        expression:
                                          "'SmartModuleConnectionStatus::RequestStatus'"
                                      }
                                    ],
                                    attrs: { outline: "", color: "secondary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.requestConnectionStateAgain()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t(
                                            "biz.smartmoduleinfo.connectivity.requeststatusagain"
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "track",
                                        rawName: "v-track",
                                        value:
                                          "SmartModuleConnectionStatus::ConnectionError",
                                        expression:
                                          "'SmartModuleConnectionStatus::ConnectionError'"
                                      }
                                    ],
                                    attrs: { outline: "", color: "secondary" },
                                    on: {
                                      click: function($event) {
                                        _vm.connectionErrorInfo = true
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        _vm._s(_vm.connectionErrorHelpIcon)
                                      )
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "biz.smartmoduleinfo.connectivity.connectionerror"
                                          )
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isLoading && !_vm.connectionErrorInfo
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "bus_subheading" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "biz.smartmoduleinfo.connectivity.connectionstatusrequested"
                                )
                              ) +
                              "\n        "
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "bus_normaltext",
                            staticStyle: { padding: "12px 0 12px 0" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "biz.smartmoduleinfo.connectivity.checkconnectionstatusdesc"
                                  )
                                ) +
                                "\n        "
                            )
                          ]
                        ),
                        _c("v-progress-linear", {
                          attrs: {
                            "buffer-value": 100,
                            height: 10,
                            value: _vm.progressValue,
                            color: "primary"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.connectionErrorInfo && !_vm.isLoading
                  ? _c(
                      "v-dialog",
                      {
                        attrs: { persistent: "", "max-width": "600px" },
                        model: {
                          value: _vm.connectionErrorInfo,
                          callback: function($$v) {
                            _vm.connectionErrorInfo = $$v
                          },
                          expression: "connectionErrorInfo"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("BaseWindowItem", {
                              attrs: {
                                trackCancel:
                                  "SmartModuleConnectionStatus::Close",
                                dialogShown: _vm.cancelDialogShown
                              },
                              on: {
                                evtCancel: function($event) {
                                  return _vm.onClosePressed()
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.smartmoduleinfo.connectivity.title"
                                            )
                                          )
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "content",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-layout",
                                          { attrs: { column: "" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "bus_subheading pb-2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "biz.smartmoduleinfo.connectivity.subtitle"
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "bus_normaltext pa-1"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "biz.smartmoduleinfo.connectivity.connectionerrordescription"
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            ),
                                            _vm.isWiFiOnboardingFlow
                                              ? _c("div", {
                                                  staticClass: "bus_normaltext",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$t(
                                                        "biz.smartmoduleinfo.connectivity.connectionrequirementwifi"
                                                      )
                                                    )
                                                  }
                                                })
                                              : _c("div", {
                                                  staticClass: "bus_normaltext",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$t(
                                                        "biz.smartmoduleinfo.connectivity.connectionrequirementlan"
                                                      )
                                                    )
                                                  }
                                                }),
                                            _vm.isWiFiOnboardingFlow
                                              ? _c(
                                                  "div",
                                                  {
                                                    attrs: {
                                                      "align-center": "",
                                                      "justify-center": "",
                                                      "mt-1": ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-img", {
                                                      attrs: {
                                                        src: require("@/assets/wifi_error@1x.png")
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _c("v-img", {
                                                      attrs: {
                                                        src: require("@/assets/lan_error_basic@1x.png")
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content":
                                                    "space-between",
                                                  "margin-top": "15px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "track",
                                                        rawName: "v-track",
                                                        value:
                                                          _vm.track +
                                                          "::ContactInfo",
                                                        expression:
                                                          "track + '::ContactInfo'"
                                                      }
                                                    ],
                                                    attrs: {
                                                      depressed: "",
                                                      outline: "",
                                                      color: "secondary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.onContactInfoPressed()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "mr-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.connectionErrorHelpIcon
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "general.contacts"
                                                            )
                                                          )
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "track",
                                                        rawName: "v-track",
                                                        value:
                                                          _vm.track + "::Close",
                                                        expression:
                                                          "track + '::Close'"
                                                      }
                                                    ],
                                                    attrs: {
                                                      depressed: "",
                                                      color: "primary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.onClosePressed()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("general.close")
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _vm.showContactInfo
                                          ? _c(
                                              "div",
                                              [
                                                _c("ContactInfo", {
                                                  attrs: {
                                                    serialNumber:
                                                      _vm.serialNumber
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                2135130464
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }