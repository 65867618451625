var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { attrs: { row: "", "ml-2": "" } },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.help.privacypolicy")))
              ]),
              _c("v-spacer"),
              _c("Cancel", {
                attrs: { icon: "true", track: '"PrivacyPolicyDialog::Close"' },
                on: {
                  evtCancel: function($event) {
                    return _vm.close()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-layout",
            {
              staticStyle: { height: "500px" },
              attrs: { row: "", "ml-3": "" }
            },
            [
              _c("iframe", {
                staticStyle: { width: "100%", height: "100%", border: "0" },
                attrs: {
                  title: _vm.$t("biz.help.privacypolicy"),
                  src: _vm.localizedFileName,
                  sandbox: ""
                }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }