// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface MasterDataApplianceInfoResponse
 */
export interface MasterDataApplianceInfoResponse {
  /**
   * @type {string}
   * @memberof MasterDataApplianceInfoResponse
   */
  articleNumber?: string;
  /**
   * @type {boolean}
   * @memberof MasterDataApplianceInfoResponse
   */
  free?: boolean;
  /**
   * @type {string}
   * @memberof MasterDataApplianceInfoResponse
   */
  modelDescription?: string;
  /**
   * @type {string}
   * @memberof MasterDataApplianceInfoResponse
   */
  organizationId?: string;
  /**
   * @type {string}
   * @memberof MasterDataApplianceInfoResponse
   */
  pictureUrl?: string;
  /**
   * @type {boolean}
   * @memberof MasterDataApplianceInfoResponse
   */
  twoZoneModel?: boolean;
}
