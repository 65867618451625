var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", lazy: "", "max-width": "550px" },
      model: {
        value: _vm.editorShown,
        callback: function($$v) {
          _vm.editorShown = $$v
        },
        expression: "editorShown"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-layout",
            { attrs: { "fill-height": "", column: "" } },
            [
              _c(
                "v-window",
                {
                  attrs: { touchless: "" },
                  model: {
                    value: _vm.step,
                    callback: function($$v) {
                      _vm.step = $$v
                    },
                    expression: "step"
                  }
                },
                [
                  _c(
                    "v-window-item",
                    { attrs: { value: _vm.startStep } },
                    [
                      _c("BaseWindowItem", {
                        attrs: { trackCancel: "CreateReport_Step1::Close" },
                        on: { evtCancel: _vm.close },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(_vm._s(_vm.$t("biz.report.reports")))
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "description",
                            fn: function() {
                              return [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.report.reportexplanation")
                                    )
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "content",
                            fn: function() {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("biz.report.selectappliance")
                                      )
                                    )
                                  ]
                                ),
                                _c("v-select", {
                                  staticClass: "default selected",
                                  attrs: {
                                    chips: "",
                                    "return-object": "",
                                    loading: !_vm.getAppliancesLoaded.value,
                                    readonly: _vm.isSelectDisabled(),
                                    items: _vm.appliancesForReports,
                                    "item-text": "name",
                                    "item-value": "virtualFridgeId",
                                    "menu-props": {
                                      bottom: true,
                                      offsetY: true
                                    },
                                    placeholder: _vm.$t("general.appliance"),
                                    "full-width": ""
                                  },
                                  model: {
                                    value: _vm.selectedAppliance,
                                    callback: function($$v) {
                                      _vm.selectedAppliance = $$v
                                    },
                                    expression: "selectedAppliance"
                                  }
                                }),
                                _vm.applianceReportSelectionVisible
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            label: _vm.selectedAppliance.name,
                                            color: "secondary"
                                          },
                                          model: {
                                            value: _vm.applianceReportSelected,
                                            callback: function($$v) {
                                              _vm.applianceReportSelected = $$v
                                            },
                                            expression:
                                              "applianceReportSelected"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.sensors.length > 0
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "bus_normaltext pt-2 pb-3 pr-4"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("biz.report.selectsensors")
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.sensors, function(sensor) {
                                  return _c("v-checkbox", {
                                    key: sensor.sensorId,
                                    staticStyle: {
                                      "margin-top": "0px",
                                      "padding-top": "0px"
                                    },
                                    attrs: {
                                      label: sensor.name,
                                      value: sensor.thingId,
                                      color: "secondary"
                                    },
                                    model: {
                                      value: _vm.selectedSensors,
                                      callback: function($$v) {
                                        _vm.selectedSensors = $$v
                                      },
                                      expression: "selectedSensors"
                                    }
                                  })
                                }),
                                _vm.hasLcvTwoZones
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            value: _vm.coolerCompartmentValue,
                                            label: _vm.coolerCompartmentName,
                                            color: "secondary"
                                          },
                                          model: {
                                            value: _vm.reportZones,
                                            callback: function($$v) {
                                              _vm.reportZones = $$v
                                            },
                                            expression: "reportZones"
                                          }
                                        }),
                                        _vm.zoneSensors(
                                          _vm.coolerCompartmentValue
                                        ).length > 0
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "bus_normaltext pt-2 pb-3 pr-4"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "biz.report.selectsensors"
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          _vm.zoneSensors(
                                            _vm.coolerCompartmentValue
                                          ),
                                          function(sensor) {
                                            return _c("v-checkbox", {
                                              key: sensor.sensorId,
                                              staticStyle: {
                                                "margin-top": "0px",
                                                "padding-top": "0px"
                                              },
                                              attrs: {
                                                label: sensor.name,
                                                value: sensor.thingId,
                                                color: "secondary"
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedCoolerSensors,
                                                callback: function($$v) {
                                                  _vm.selectedCoolerSensors = $$v
                                                },
                                                expression:
                                                  "selectedCoolerSensors"
                                              }
                                            })
                                          }
                                        ),
                                        _c("v-checkbox", {
                                          attrs: {
                                            value: _vm.freezerCompartmentValue,
                                            label: _vm.freezerCompartmentName,
                                            color: "secondary"
                                          },
                                          model: {
                                            value: _vm.reportZones,
                                            callback: function($$v) {
                                              _vm.reportZones = $$v
                                            },
                                            expression: "reportZones"
                                          }
                                        }),
                                        _vm.zoneSensors(
                                          _vm.freezerCompartmentValue
                                        ).length > 0
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "bus_normaltext pt-2 pb-3 pr-4"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "biz.report.selectsensors"
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          _vm.zoneSensors(
                                            _vm.freezerCompartmentValue
                                          ),
                                          function(sensor) {
                                            return _c("v-checkbox", {
                                              key: sensor.sensorId,
                                              staticStyle: {
                                                "margin-top": "0px",
                                                "padding-top": "0px"
                                              },
                                              attrs: {
                                                label: sensor.name,
                                                value: sensor.thingId,
                                                color: "secondary"
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedFreezerSensors,
                                                callback: function($$v) {
                                                  _vm.selectedFreezerSensors = $$v
                                                },
                                                expression:
                                                  "selectedFreezerSensors"
                                              }
                                            })
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm.smartModuleZonesCount > 1
                                  ? _c(
                                      "div",
                                      _vm._l(_vm.smartModuleZones, function(
                                        zone
                                      ) {
                                        return _c("v-checkbox", {
                                          key: zone.zoneIndex,
                                          staticStyle: {
                                            "margin-top": "0px",
                                            "padding-top": "0px"
                                          },
                                          attrs: {
                                            label: zone.zonePosition,
                                            value: zone.thingId,
                                            color: "secondary"
                                          },
                                          model: {
                                            value: _vm.reportZones,
                                            callback: function($$v) {
                                              _vm.reportZones = $$v
                                            },
                                            expression: "reportZones"
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    { attrs: { value: _vm.startStep + 1 } },
                    [
                      _c("BaseWindowItem", {
                        attrs: { trackCancel: "CreateReport_Step2::Close" },
                        on: { evtCancel: _vm.close },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _vm._v(_vm._s(_vm.$t("biz.report.reports")))
                                ]
                              },
                              proxy: true
                            },
                            !_vm.cannotCreateReport()
                              ? {
                                  key: "description",
                                  fn: function() {
                                    return [
                                      _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.$t("biz.report.timerange"))
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              : null,
                            _vm.cannotCreateReport()
                              ? {
                                  key: "content",
                                  fn: function() {
                                    return [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "bus_normaltext pt-2 pb-3 pr-4"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "biz.report.cannotcreatereport"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : {
                                  key: "content",
                                  fn: function() {
                                    return [
                                      _c("TimeZoneSelector", {
                                        attrs: {
                                          initialTimezoneString:
                                            _vm.ianaTimezone
                                        },
                                        on: {
                                          evtTimeZoneChange:
                                            _vm.onTimezoneChange
                                        }
                                      }),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: {
                                            mandatory: false,
                                            column: ""
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "general.timeperiod"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ]),
                                          model: {
                                            value: _vm.editableData.period,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editableData,
                                                "period",
                                                $$v
                                              )
                                            },
                                            expression: "editableData.period"
                                          }
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t(
                                                "biz.report.monthrange"
                                              ),
                                              color: "secondary",
                                              value: "month_filter"
                                            }
                                          }),
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "ml-4 mt-0",
                                              attrs: {
                                                mandatory: false,
                                                column: "",
                                                disabled: _vm.isDateDisabled()
                                              },
                                              model: {
                                                value: _vm.monthPeriod,
                                                callback: function($$v) {
                                                  _vm.monthPeriod = $$v
                                                },
                                                expression: "monthPeriod"
                                              }
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: _vm.beforePrevious,
                                                  value: "beforePrevious",
                                                  color: "secondary"
                                                }
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: _vm.previous,
                                                  value: "previous",
                                                  color: "secondary"
                                                }
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  disabled: _vm.firstDayOfMonth,
                                                  label: _vm.currentMonth,
                                                  value: "current",
                                                  color: "secondary"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("v-radio", {
                                            attrs: {
                                              color: "secondary",
                                              label: _vm.$t(
                                                "biz.dashboard.alarms.period.titlelist"
                                              ),
                                              value: "date_filter"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "ml-3",
                                          staticStyle: {
                                            "margin-top": "-30px"
                                          },
                                          attrs: { xs8: "" }
                                        },
                                        [
                                          _c("DatePeriod", {
                                            attrs: {
                                              includeToday: false,
                                              defaultStartPeriod:
                                                _vm.startPeriod,
                                              periodDisabled: !_vm.isDateDisabled()
                                            },
                                            on: {
                                              evtDateChanged: _vm.onReportDates
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "mt-0",
                                          attrs: {
                                            mandatory: false,
                                            column: ""
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "biz.report.selectreportlanguage"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ]),
                                          model: {
                                            value: _vm.language,
                                            callback: function($$v) {
                                              _vm.language = $$v
                                            },
                                            expression: "language"
                                          }
                                        },
                                        _vm._l(_vm.reportLanguages, function(
                                          reportLanguage
                                        ) {
                                          return _c("v-radio", {
                                            key: reportLanguage.code,
                                            attrs: {
                                              color: "secondary",
                                              label: reportLanguage.name,
                                              value: reportLanguage
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    { attrs: { value: _vm.startStep + 2 } },
                    [
                      _c("BaseWindowItem", {
                        attrs: { trackCancel: "CreateReport_Step3::Close" },
                        on: { evtCancel: _vm.close },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(_vm._s(_vm.$t("biz.report.reports")))
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "description",
                            fn: function() {
                              return [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("biz.report.reporttype"))
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "content",
                            fn: function() {
                              return [
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: { mandatory: false, row: "" },
                                    model: {
                                      value: _vm.editableData.reportType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editableData,
                                          "reportType",
                                          $$v
                                        )
                                      },
                                      expression: "editableData.reportType"
                                    }
                                  },
                                  [
                                    !_vm.isFoodLicenseUser
                                      ? _c("v-radio", {
                                          attrs: {
                                            label: _vm.$t(
                                              "biz.report.tempconformity"
                                            ),
                                            value: "TEMPCONFORMITY",
                                            color: "secondary"
                                          }
                                        })
                                      : _vm._e(),
                                    _c("v-radio", {
                                      attrs: {
                                        label: _vm.$t("biz.report.haccp"),
                                        value: "HACCP",
                                        color: "secondary"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.editableData.reportType ===
                                  "TEMPCONFORMITY" && !_vm.isFoodLicenseUser
                                  ? _c("p", {
                                      staticClass: "bus_normaltext",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "biz.report.dataprovided.tempconformity"
                                          )
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm.editableData.reportType === "HACCP"
                                  ? _c("p", {
                                      staticClass: "bus_normaltext",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "biz.report.dataprovided.haccp"
                                          )
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    { attrs: { value: _vm.startStep + 3 } },
                    [
                      _c("BaseWindowItem", {
                        attrs: { trackCancel: "CreateReport_Step4::Close" },
                        on: { evtCancel: _vm.close },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(_vm._s(_vm.$t("biz.report.reports")))
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "description",
                            fn: function() {
                              return [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("biz.report.reportname"))
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "content",
                            fn: function() {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("general.communication.name")
                                      )
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected",
                                  attrs: {
                                    clearable: _vm.getClearableIcon(
                                      _vm.editableData.reportName
                                    ),
                                    placeholder: _vm.$t(
                                      "general.communication.name"
                                    )
                                  },
                                  model: {
                                    value: _vm.editableData.reportName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.editableData,
                                        "reportName",
                                        $$v
                                      )
                                    },
                                    expression: "editableData.reportName"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("BackNextMenu", {
            attrs: {
              stepCount: _vm.stepCount,
              step: _vm.step,
              previousDisabled: _vm.step === 1,
              nextDisabled: _vm.isNextDisabled
            },
            on: {
              "update:step": function($event) {
                _vm.step = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "finalStep",
                fn: function() {
                  return [
                    _vm.step === _vm.stepCount
                      ? _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: "CreateReport::Create",
                                expression: "'CreateReport::Create'"
                              }
                            ],
                            attrs: {
                              depressed: "",
                              disabled: _vm.isCreateDisabled,
                              color: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.createReport()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("general.create")))]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }