var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "28px",
        height: "28px",
        viewBox: "0 0 28 28",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            transform: "translate(2,1.9878314)"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-secondary-base)" },
            attrs: {
              d:
                "M5,0 L17.1314829,0 C17.6837677,-1.08250786e-15 18.1314829,0.44771525 18.1314829,1 C18.1314829,1.19742499 18.0730449,1.39043268 17.9635332,1.5547002 L15.6666667,5 L17.9635332,8.4452998 C18.2698857,8.90482849 18.1457118,9.52569784 17.6861831,9.83205029 C17.5219156,9.94156197 17.3289079,10 17.1314829,10 L5,10 C4.44771525,10 4,9.55228475 4,9 L4,1 C4,0.44771525 4.44771525,1.01453063e-16 5,0 Z M1,0 C1.55228475,-1.01453063e-16 2,0.44771525 2,1 L2,20 C2,20.5522847 1.55228475,21 1,21 C0.44771525,21 6.76353751e-17,20.5522847 0,20 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }