var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: {
      title: _vm.$t("biz.alarmshistory.title"),
      track: "ApplianceHistory::AlarmHistory::Close",
      dialogShown: _vm.dialogShown
    },
    on: {
      evtClose: function($event) {
        return _vm.onClosePressed()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "additionalcontent",
        fn: function() {
          return [
            _c(
              "v-layout",
              { staticClass: "body-2", attrs: { row: "" } },
              [
                _c("v-flex", { attrs: { xs6: "" } }, [
                  _vm._v(_vm._s(_vm.$t("biz.alarmshistory.device")) + ":")
                ]),
                _c("v-flex", [_vm._v(_vm._s(_vm.deviceName))])
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("v-data-table", {
              attrs: {
                headers: _vm.headers,
                items: _vm.alarmsHistoryData,
                "hide-actions": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "items",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm.isFridgeAlarms
                        ? _c(
                            "tr",
                            { staticStyle: { "border-bottom": "none" } },
                            [
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticStyle: { "margin-left": "-10px" },
                                      attrs: { row: "", nowrap: "" }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getAlarmIconByType(
                                              item.alarmType,
                                              "Off"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.getAlarmTextByType(
                                              item.alarmType
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("td", [
                                _vm._v(_vm._s(_vm.getMinMaxAlarmText(item)))
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getDurationInSeconds(
                                      item.alarmDurationInSeconds
                                    )
                                  )
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$format.dateTimeShort(
                                      item.alarmStartedTimestamp
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _c(
                            "tr",
                            { staticStyle: { "border-bottom": "none" } },
                            [
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticStyle: { "margin-left": "-10px" },
                                      attrs: { row: "", nowrap: "" }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getAlarmIconByType(
                                              item.alarmType,
                                              "Off"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.getAlarmTextByType(
                                              item.alarmType
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("td", [
                                _vm._v(_vm._s(_vm.getMinMaxAlarmText(item)))
                              ]),
                              item.alarmDurationInSeconds
                                ? _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getDurationInSeconds(
                                          item.alarmDurationInSeconds
                                        )
                                      )
                                    )
                                  ])
                                : _c("td", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.getDurationInSeconds(
                                            item.alarmProfileDoorOpeningDurationInSeconds
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$format.dateTimeShort(
                                      item.alarmStartedTimestamp
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }