var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.systemalarm")))
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "align-items: end", attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(_vm.$t("biz.dashboard.alarms.systemalarmdescription"))
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs9: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(_vm.$t("biz.dashboard.alarms.heartbeatdescription"))
              )
            ])
          ]),
          _c("v-flex", { attrs: { xs3: "" } }, [
            _vm.editableData.alarmActivated
              ? _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.$t("general.activated")))
                ])
              : _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.$t("general.deactivated")))
                ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { "pt-3": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.delaytime")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _vm.editableData.alarmActivated
              ? _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$tc(
                          "general.duration.minute",
                          _vm.editableData.gatewayAlarmDelay
                        )
                      ) +
                      "\n      "
                  )
                ])
              : _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.SHOW_BLANK))
                ])
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700px" },
          model: {
            value: _vm.editorShown,
            callback: function($$v) {
              _vm.editorShown = $$v
            },
            expression: "editorShown"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.systemalarm")))
              ]),
              _c(
                "v-layout",
                { attrs: { column: "", "pa-3": "" } },
                [
                  _c("p", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("biz.dashboard.alarms.systemalarmdescription")
                      )
                    )
                  ]),
                  _c("v-checkbox", {
                    attrs: {
                      color: "secondary",
                      label: _vm.$t("biz.dashboard.alarms.unavailabilitydelay")
                    },
                    model: {
                      value: _vm.editableDataLocal.alarmActivated,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal, "alarmActivated", $$v)
                      },
                      expression: "editableDataLocal.alarmActivated"
                    }
                  }),
                  _c(
                    "label",
                    { staticStyle: { "font-size": "12px", color: "gray" } },
                    [_vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.delaytime")))]
                  ),
                  _c("BasicSlider", {
                    attrs: {
                      sliderValue: _vm.editableDataLocal.gatewayAlarmDelay,
                      active: _vm.editableDataLocal.alarmActivated,
                      minValue: _vm.minDelayTime,
                      maxValue: _vm.maxDelayTime,
                      step: 1
                    },
                    on: {
                      "update:sliderValue": function($event) {
                        return _vm.$set(
                          _vm.editableDataLocal,
                          "gatewayAlarmDelay",
                          $event
                        )
                      },
                      "update:slider-value": function($event) {
                        return _vm.$set(
                          _vm.editableDataLocal,
                          "gatewayAlarmDelay",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("SaveCancel", {
                    attrs: {
                      saveDisabled: _vm.saveDisabled,
                      track: _vm.track,
                      icon: false
                    },
                    on: {
                      evtSave: function($event) {
                        return _vm.onSave()
                      },
                      evtCancel: function($event) {
                        return _vm.onCancel()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }