// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Appliance } from './';

/**
 * @export
 * @interface ApplianceGroup
 */
export interface ApplianceGroup {
  /**
   * @type {Array<Appliance>}
   * @memberof ApplianceGroup
   */
  appliances?: Array<Appliance>;
  /**
   * @type {boolean}
   * @memberof ApplianceGroup
   */
  defaultGroup?: boolean;
  /**
   * @type {string}
   * @memberof ApplianceGroup
   */
  groupId?: string;
  /**
   * @type {string}
   * @memberof ApplianceGroup
   */
  name?: string;
}
