// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrganizationGroup } from './';

/**
 * @export
 * @interface OrganizationGroups
 */
export interface OrganizationGroups {
  /**
   * @type {OrganizationGroup}
   * @memberof OrganizationGroups
   */
  defaultGroup?: OrganizationGroup;
  /**
   * @type {Array<OrganizationGroup>}
   * @memberof OrganizationGroups
   */
  groups?: Array<OrganizationGroup>;
}
