// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AlarmProfileListItem
 */
export interface AlarmProfileListItem {
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  alarmProfileId?: string;
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  alarmProfileType?: AlarmProfileListItemAlarmProfileTypeEnum;
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  cloudFridgeType?: AlarmProfileListItemCloudFridgeTypeEnum;
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  gatewayId?: string;
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  model?: string;
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  name?: string;
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  sensorId?: string;
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  sensorType?: AlarmProfileListItemSensorTypeEnum;
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  serialNumber?: string;
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  thingId?: string;
  /**
   * @type {string}
   * @memberof AlarmProfileListItem
   */
  virtualFridgeId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum AlarmProfileListItemAlarmProfileTypeEnum {
  GATEWAYPROFILE = 'GATEWAYPROFILE',
  CLOUDPROFILE = 'CLOUDPROFILE',
  SENSORPROFILE = 'SENSORPROFILE',
  SMARTMODULEPROFILE = 'SMARTMODULEPROFILE'
}
/**
 * @export
 * @enum {string}
 */
export enum AlarmProfileListItemCloudFridgeTypeEnum {
  ROOM = 'ROOM',
  MODBUSFRIDGE = 'MODBUS_FRIDGE',
  NOTCONNECTABLEFRIDGE = 'NOT_CONNECTABLE_FRIDGE',
  THIRDPARTYFRIDGE = 'THIRD_PARTY_FRIDGE',
  BINDERFRIDGE = 'BINDER_FRIDGE',
  SMARTMODULEFRIDGE = 'SMART_MODULE_FRIDGE'
}
/**
 * @export
 * @enum {string}
 */
export enum AlarmProfileListItemSensorTypeEnum {
  DOOR = 'DOOR',
  TEMPERATURE = 'TEMPERATURE'
}
