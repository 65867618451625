var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseWindowPage", {
    attrs: {
      trackCancel: _vm.track + "::Close",
      dialogShown: _vm.cancelDialogShown
    },
    on: {
      evtCancel: function($event) {
        return _vm.abortProcess()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(_vm._s(_vm.$t("biz.onboarding.stepper.header")))]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "v-layout",
              { attrs: { "fill-height": "", row: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { "fill-height": "", xs5: "" } },
                  [
                    _c(
                      "v-img",
                      {
                        staticClass: "mr-5",
                        attrs: { src: _vm.pictureUrl, contain: "" }
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              slot: "placeholder",
                              "fill-height": "",
                              "align-center": "",
                              "justify-center": "",
                              "pa-0": ""
                            },
                            slot: "placeholder"
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "secondary" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { "fill-height": "", xs7: "" } },
                  [
                    _c("div", { staticClass: "ml-1" }, [
                      _c(
                        "p",
                        {
                          staticClass: "bus_normaltext text-justify mb-2 mr-1"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("biz.onboarding.scan.enterinfotext"))
                          )
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.zonesModel,
                            expression: "!zonesModel"
                          }
                        ],
                        staticClass: "bus_required_field"
                      },
                      [_vm._v("* " + _vm._s(_vm.$t("general.required")))]
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.zonesModel,
                            expression: "!zonesModel"
                          }
                        ],
                        staticStyle: {
                          "margin-bottom": "0px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))]
                    ),
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.zonesModel,
                          expression: "!zonesModel"
                        }
                      ],
                      staticClass: "default selected errorborder mb-3",
                      staticStyle: { height: "65px" },
                      attrs: {
                        rules: _vm.applianceNameRules,
                        type: "string",
                        clearable: _vm.iconHelper.clearableIcon(_vm.name),
                        placeholder: _vm.$t("biz.applianceinfo.name")
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "margin-bottom": "0px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.building")))]
                    ),
                    _c("v-text-field", {
                      staticClass: "default selected mb-3",
                      staticStyle: { height: "65px" },
                      attrs: {
                        clearable: _vm.iconHelper.clearableIcon(_vm.building),
                        placeholder: _vm.$t("biz.applianceinfo.building")
                      },
                      model: {
                        value: _vm.building,
                        callback: function($$v) {
                          _vm.building = $$v
                        },
                        expression: "building"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "margin-bottom": "0px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.department")))]
                    ),
                    _c("v-text-field", {
                      staticClass: "default selected mb-3",
                      staticStyle: { height: "65px" },
                      attrs: {
                        clearable: _vm.iconHelper.clearableIcon(_vm.department),
                        placeholder: _vm.$t("biz.applianceinfo.department")
                      },
                      model: {
                        value: _vm.department,
                        callback: function($$v) {
                          _vm.department = $$v
                        },
                        expression: "department"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "margin-bottom": "0px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.floor")))]
                    ),
                    _c("v-text-field", {
                      staticClass: "default selected mb-3",
                      staticStyle: { height: "65px" },
                      attrs: {
                        clearable: _vm.iconHelper.clearableIcon(_vm.level),
                        placeholder: _vm.$t("biz.applianceinfo.floor")
                      },
                      model: {
                        value: _vm.level,
                        callback: function($$v) {
                          _vm.level = $$v
                        },
                        expression: "level"
                      }
                    }),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "margin-bottom": "0px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.room")))]
                    ),
                    _c("v-text-field", {
                      staticClass: "default selected mb-3",
                      staticStyle: { height: "65px" },
                      attrs: {
                        clearable: _vm.iconHelper.clearableIcon(_vm.room),
                        placeholder: _vm.$t("biz.applianceinfo.room")
                      },
                      model: {
                        value: _vm.room,
                        callback: function($$v) {
                          _vm.room = $$v
                        },
                        expression: "room"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }