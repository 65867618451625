var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gatewaybanner",
      class: [_vm.isConnected ? "bus_noncritical" : "bus_critical"]
    },
    [
      _c("v-icon", { staticClass: "icon" }, [_vm._v(_vm._s(_vm.icon))]),
      _c("p", { staticClass: "header" }, [_vm._v(_vm._s(_vm.gateway.name))]),
      _c("p", { staticClass: "update" }, [
        _vm._v(
          _vm._s(_vm.$t("general.actualization")) +
            ": " +
            _vm._s(_vm.$format.dateTimeShort(_vm.since))
        )
      ]),
      _c("p", { staticClass: "status" }, [_vm._v(_vm._s(_vm.state))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }