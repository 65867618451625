var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "pa-3": "" } },
    [
      !_vm.isFoodLicenseUser
        ? _c("v-checkbox", {
            attrs: {
              color: "secondary",
              label: _vm.$t("biz.dashboard.alarms.cloudalarmactive")
            },
            model: {
              value: _vm.editableData.alarmActivatedByUser,
              callback: function($$v) {
                _vm.$set(_vm.editableData, "alarmActivatedByUser", $$v)
              },
              expression: "editableData.alarmActivatedByUser"
            }
          })
        : _vm._e(),
      !_vm.isFoodLicenseUser
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("biz.dashboard.alarms.temperaturedescription")
                    )
                  )
                ]),
                _c("p", { staticClass: "pt-3 mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("biz.appliancehistory.temperature")))
                ])
              ]),
              _c("RangeSlider", {
                attrs: {
                  minSliderValue: _vm.editableData.temperatures[0],
                  maxSliderValue: _vm.editableData.temperatures[1],
                  active: _vm.editableData.alarmActivatedByUser,
                  minValue: _vm.getMinAlarmTemp(),
                  maxValue: _vm.getMaxAlarmTemp(),
                  step: 0.1
                },
                on: {
                  "update:minSliderValue": function($event) {
                    return _vm.$set(_vm.editableData.temperatures, 0, $event)
                  },
                  "update:min-slider-value": function($event) {
                    return _vm.$set(_vm.editableData.temperatures, 0, $event)
                  },
                  "update:maxSliderValue": function($event) {
                    return _vm.$set(_vm.editableData.temperatures, 1, $event)
                  },
                  "update:max-slider-value": function($event) {
                    return _vm.$set(_vm.editableData.temperatures, 1, $event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c("p", { staticClass: "bus_normaltext" }, [
          _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.delaydescription")))
        ]),
        _c("p", { staticClass: "pt-3 mb-0" }, [
          _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.delaytime")))
        ])
      ]),
      _c("BasicSlider", {
        attrs: {
          sliderValue: _vm.editableData.cloudAlarmDelay,
          active: _vm.editableData.alarmActivatedByUser,
          minValue: _vm.minDelayTime,
          maxValue: _vm.maxDelayTime,
          step: 1
        },
        on: {
          "update:sliderValue": function($event) {
            return _vm.$set(_vm.editableData, "cloudAlarmDelay", $event)
          },
          "update:slider-value": function($event) {
            return _vm.$set(_vm.editableData, "cloudAlarmDelay", $event)
          }
        }
      }),
      _c(
        "v-layout",
        [
          _c("v-flex", [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.cloudalarmhelper")))
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }