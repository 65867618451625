var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        lazy: "",
        "max-width": "600px",
        "max-height": "80%"
      },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { attrs: { id: "layout", "fill-height": "", column: "" } },
            [
              _c(
                "v-form",
                {
                  ref: "serviceform",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-window",
                    {
                      attrs: { touchless: "" },
                      model: {
                        value: _vm.step,
                        callback: function($$v) {
                          _vm.step = $$v
                        },
                        expression: "step"
                      }
                    },
                    [
                      _c(
                        "v-window-item",
                        { attrs: { value: 1 } },
                        [
                          _c("BaseWindowItem", {
                            attrs: { trackCancel: _vm.track + "::Step1" },
                            on: {
                              evtCancel: function($event) {
                                _vm.cancelDialogShown = true
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("biz.help.servicedetails.title")
                                      )
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "description",
                                fn: function() {
                                  return [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t("biz.help.servicedetails.text")
                                        )
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "content",
                                fn: function() {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0px",
                                          "font-weight": "bold"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.general.items.devicename"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      staticClass: "default selected",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        chips: "",
                                        "return-object": "",
                                        items: _vm.allDevices,
                                        "item-text": "name",
                                        readonly:
                                          _vm.deviceSelectReadonlyStatus,
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        placeholder: _vm.$t(
                                          "biz.general.items.devicename"
                                        ),
                                        "full-width": ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.onChangeSelectedItem(
                                            _vm.selectedDevice
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.selectedDevice,
                                        callback: function($$v) {
                                          _vm.selectedDevice = $$v
                                        },
                                        expression: "selectedDevice"
                                      }
                                    }),
                                    _vm.hasModelSelected
                                      ? _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "0px",
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "biz.applianceinfo.model"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.hasModelSelected
                                      ? _c("v-text-field", {
                                          staticClass: "default selected",
                                          staticStyle: { "font-size": "14px" },
                                          attrs: {
                                            value:
                                              _vm.selectedDevice.model +
                                              " " +
                                              _vm.addedIdentifier,
                                            readonly: "",
                                            placeholder: _vm.$t(
                                              "biz.general.items.model"
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0px",
                                          "font-weight": "bold"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.help.servicedetails.type"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      staticClass: "default selected",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        chips: "",
                                        "return-object": "",
                                        items: _vm.requestTypes,
                                        "item-text": "name",
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        placeholder: _vm.$t(
                                          "biz.help.servicedetails.type"
                                        ),
                                        "full-width": ""
                                      },
                                      model: {
                                        value: _vm.type,
                                        callback: function($$v) {
                                          _vm.type = $$v
                                        },
                                        expression: "type"
                                      }
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0px",
                                          "font-weight": "bold"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "general.communication.message"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-textarea", {
                                      staticClass:
                                        "defaulttextarea selectedtextarea errorbordertextarea",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        clearable: _vm.iconHelper.clearableIcon(
                                          _vm.messageText
                                        ),
                                        placeholder: _vm.$t(
                                          "general.communication.message"
                                        ),
                                        rules: _vm.messageRules,
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.messageText,
                                        callback: function($$v) {
                                          _vm.messageText = $$v
                                        },
                                        expression: "messageText"
                                      }
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0px",
                                          "font-weight": "bold"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("biz.applianceinfo.country")
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      staticClass: "default selected",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        chips: "",
                                        items: _vm.supportedCountries,
                                        "item-value": "value",
                                        "item-text": "name",
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true
                                        },
                                        placeholder: _vm.$t(
                                          "biz.applianceinfo.country"
                                        ),
                                        "return-object": "",
                                        "full-width": ""
                                      },
                                      model: {
                                        value: _vm.supportedCountry,
                                        callback: function($$v) {
                                          _vm.supportedCountry = $$v
                                        },
                                        expression: "supportedCountry"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-window-item",
                        { attrs: { value: 2 } },
                        [
                          _c("BaseWindowItem", {
                            attrs: { trackCancel: _vm.track + "::Step2" },
                            on: {
                              evtCancel: function($event) {
                                _vm.cancelDialogShown = true
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("biz.help.servicedetails.title")
                                      )
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "content",
                                fn: function() {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0px",
                                          "font-weight": "bold"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("general.communication.name")
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-text-field", {
                                      staticClass:
                                        "default selected errorborder",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        clearable: _vm.iconHelper.clearableIcon(
                                          _vm.name
                                        ),
                                        placeholder: _vm.$t(
                                          "general.communication.name"
                                        ),
                                        rules: _vm.nameRules,
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.name,
                                        callback: function($$v) {
                                          _vm.name = $$v
                                        },
                                        expression: "name"
                                      }
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0px",
                                          "font-weight": "bold"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "general.communication.email"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-text-field", {
                                      staticClass:
                                        "default selected errorborder",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        clearable: _vm.iconHelper.clearableIcon(
                                          _vm.email
                                        ),
                                        placeholder: _vm.$t(
                                          "general.communication.email"
                                        ),
                                        rules: _vm.emailRules,
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.email,
                                        callback: function($$v) {
                                          _vm.email = $$v
                                        },
                                        expression: "email"
                                      }
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "margin-bottom": "0px",
                                          "font-weight": "bold"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "general.communication.phone"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-text-field", {
                                      staticClass:
                                        "default selected errorborder",
                                      staticStyle: { "font-size": "14px" },
                                      attrs: {
                                        clearable: _vm.iconHelper.clearableIcon(
                                          _vm.phone
                                        ),
                                        placeholder: _vm.$t(
                                          "general.communication.phone"
                                        ),
                                        rules: _vm.phoneRules
                                      },
                                      model: {
                                        value: _vm.phone,
                                        callback: function($$v) {
                                          _vm.phone = $$v
                                        },
                                        expression: "phone"
                                      }
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticStyle: { "font-size": "14px" },
                                        attrs: { color: "secondary" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "general.contactinfo.dataagreement"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("BackNextMenu", {
            attrs: {
              step: _vm.step,
              nextDisabled: _vm.messageText === "",
              previousDisabled: _vm.step === 1
            },
            on: {
              "update:step": function($event) {
                _vm.step = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "finalStep",
                fn: function() {
                  return [
                    _c("Send", {
                      attrs: {
                        icon: false,
                        sendDisabled: !_vm.valid || _vm.isSending,
                        track: _vm.track + "::FinishServiceRequest"
                      },
                      on: {
                        evtSend: function($event) {
                          return _vm.sendServiceRequest()
                        }
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.cancelDialogShown,
          title: _vm.$t("biz.help.servicedetails.abort.title"),
          message: _vm.$t("biz.help.servicedetails.abort.description"),
          track: _vm.track + "::AbortDialog"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmAbort()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelAbort()
          }
        }
      }),
      _c("OkDialog", {
        attrs: {
          dialogShown: _vm.saveDialog,
          title: _vm.$t("biz.help.servicedetails.savesuccesstitle"),
          message: _vm.$t("biz.help.servicedetails.savesuccessmessage"),
          track: _vm.track + "::ServiceRequestSaved"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.close()
          }
        }
      }),
      _c("ServiceHelpDialog", {
        attrs: { dialogShown: _vm.serviceHelpShown },
        on: {
          evtClose: function($event) {
            return _vm.close()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }