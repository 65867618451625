var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", [
        _c("path", {
          staticStyle: {
            "paint-order": "stroke",
            fill: "var(--v-secondary-base)"
          },
          attrs: {
            d:
              "M 2 4 L 22 4 C 23.105 4 24 4.895 24 6 L 24 18 C 24 19.105 23.105 20 22 20 L 2 20 C 0.895 20 0 19.105 0 18 L 0 6 C 0 4.895 0.895 4 2 4 Z",
            "fill-rule": "nonzero",
            stroke: "none",
            "stroke-width": "1"
          }
        }),
        _c("path", {
          staticStyle: { fill: "var(--v-secondary-base)" },
          attrs: {
            d:
              "M 2 5 C 1.448 5 1 5.448 1 6 L 1 18 C 1 18.552 1.448 19 2 19 L 22 19 C 22.552 19 23 18.552 23 18 L 23 6 C 23 5.448 22.552 5 22 5 L 2 5 Z",
            "fill-rule": "nonzero",
            stroke: "none",
            "stroke-width": "1"
          }
        }),
        _c("path", {
          staticStyle: { fill: "var(--v-bus_white-base)" },
          attrs: {
            d:
              "M 9.22 8.72 C 10.113 8.72 10.743 9.113 11.11 9.9 C 11.383 10.487 11.52 11.33 11.52 12.43 C 11.52 13.53 11.383 14.373 11.11 14.96 C 10.743 15.747 10.113 16.14 9.22 16.14 C 8.327 16.14 7.697 15.747 7.33 14.96 C 7.057 14.373 6.92 13.53 6.92 12.43 C 6.92 11.33 7.057 10.487 7.33 9.9 C 7.697 9.113 8.327 8.72 9.22 8.72 Z",
            "fill-rule": "nonzero",
            stroke: "none",
            "stroke-width": "1"
          }
        }),
        _c("path", {
          staticStyle: { fill: "var(--v-bus_white-base)" },
          attrs: {
            d:
              "M 12.58 16 L 12.58 8.86 L 13.72 8.86 L 16.08 14.82 L 16.1 14.82 L 16.1 8.86 L 16.98 8.86 L 16.98 16 L 15.77 16 L 13.48 10.22 L 13.46 10.22 L 13.46 16 L 12.58 16 Z",
            "fill-rule": "nonzero",
            stroke: "none",
            "stroke-width": "1"
          }
        }),
        _c("path", {
          staticStyle: { fill: "var(--v-secondary-base)" },
          attrs: {
            d:
              "M 9.22 9.44 C 8.66 9.44 8.277 9.733 8.07 10.32 C 7.917 10.753 7.84 11.457 7.84 12.43 C 7.84 13.403 7.917 14.107 8.07 14.54 C 8.277 15.127 8.66 15.42 9.22 15.42 C 9.78 15.42 10.163 15.127 10.37 14.54 C 10.523 14.107 10.6 13.403 10.6 12.43 C 10.6 11.457 10.523 10.753 10.37 10.32 C 10.163 9.733 9.78 9.44 9.22 9.44 Z",
            "fill-rule": "nonzero",
            stroke: "none",
            "stroke-width": "1"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }