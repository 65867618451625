var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c("v-card-title", { staticClass: "title ml-2" }, [
                _vm._v(_vm._s(_vm.$t("biz.help.legalnotice.title")))
              ]),
              _c("v-spacer"),
              _c("Cancel", {
                attrs: { icon: "true", track: '"LegalNoticeDialog::Close"' },
                on: {
                  evtCancel: function($event) {
                    return _vm.close()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(_vm._s(_vm.$t("biz.help.legalnotice.companyName")))
              ]),
              _c(
                "v-flex",
                { staticClass: "mt-2" },
                [
                  _c("v-layout", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.help.legalnotice.companyAddress"))
                    )
                  ]),
                  _c("v-layout", { staticClass: "bus_normaltext" }, [
                    _vm._v(_vm._s(_vm.companyTown))
                  ]),
                  _c("v-layout", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.help.legalnotice.companyCountry"))
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "mt-4" },
                [
                  _c("v-layout", { staticClass: "bus_normaltext" }, [
                    _vm._v(_vm._s(_vm.companyTel))
                  ]),
                  _c("v-layout", { staticClass: "bus_normaltext" }, [
                    _vm._v(_vm._s(_vm.companyFax))
                  ]),
                  _c("v-layout", { staticClass: "bus_normaltext" }, [
                    _vm._v(_vm._s(_vm.companyEmail))
                  ])
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "mt-4" },
                [
                  _c("v-layout", { attrs: { row: "" } }, [
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("biz.help.legalnotice.registrationText"))
                      )
                    ]),
                    _c("span", { staticClass: "bus_normaltext pl-1" }, [
                      _vm._v(
                        _vm._s(_vm.$t("biz.help.legalnotice.registrationPlace"))
                      )
                    ])
                  ]),
                  _c("v-layout", { attrs: { row: "" } }, [
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("biz.help.legalnotice.regNumberText"))
                      )
                    ]),
                    _c("span", { staticClass: "bus_normaltext pl-1" }, [
                      _vm._v(_vm._s(_vm.regNumber))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "mt-4" },
                [
                  _c("v-layout", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(_vm._s(_vm.$t("biz.help.legalnotice.executiveText")))
                  ]),
                  _c("v-layout", { staticClass: "bus_normaltext" }, [
                    _vm._v(_vm._s(_vm.executiveDirectors))
                  ])
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "mt-4" },
                [
                  _c("v-layout", { staticStyle: { "font-weight": "bold" } }, [
                    _vm._v(_vm._s(_vm.$t("biz.help.legalnotice.vatText")))
                  ]),
                  _c("v-layout", { staticClass: "bus_normaltext" }, [
                    _vm._v(_vm._s(_vm.vatNumber))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }