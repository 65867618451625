var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "80px",
        height: "80px",
        version: "1.1",
        viewBox: "0 0 80 80"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            "fill-rule": "evenodd",
            stroke: "none",
            "stroke-width": "1"
          }
        },
        [
          _c("g", { attrs: { transform: "translate(11.000000, 2.000000)" } }, [
            _c("g", [
              _c("path", {
                staticStyle: { fill: "var(--v-secondary-base)" },
                attrs: {
                  "fill-rule": "nonzero",
                  d:
                    "M39.1818182,59.00436 L39.1818182,62 C39.1818182,63.6568542 37.8386724,65 36.1818182,65 L5.81818182,65 C4.16132757,65 2.81818182,63.6568542 2.81818182,62 L2.81818182,59.00436 C1.18595695,58.5003005 3.41358492e-15,56.9795569 0,55.1818182 L0,4 C-2.705415e-16,1.790861 1.790861,1.29399067e-15 4,8.8817842e-16 L38,0 C40.209139,-4.05812251e-16 42,1.790861 42,4 L42,55.1818182 C42,56.9795569 40.814043,58.5003005 39.1818182,59.00436 L39.1818182,59.00436 Z M2,20 L40,20 L40,4 C40,2.8954305 39.1045695,2 38,2 L4,2 C2.8954305,2 2,2.8954305 2,4 L2,20 Z M2,22 L2,55.1818182 C2,56.2863877 2.8954305,57.1818182 4,57.1818182 L38,57.1818182 C39.1045695,57.1818182 40,56.2863877 40,55.1818182 L40,22 L2,22 Z M5.54545455,6 C5.54545455,5.44771525 5.9931698,5 6.54545455,5 C7.0977393,5 7.54545455,5.44771525 7.54545455,6 L7.54545455,16 C7.54545455,16.5522847 7.0977393,17 6.54545455,17 C5.9931698,17 5.54545455,16.5522847 5.54545455,16 L5.54545455,6 Z M5.54545455,26 C5.54545455,25.4477153 5.9931698,25 6.54545455,25 C7.0977393,25 7.54545455,25.4477153 7.54545455,26 L7.54545455,53 C7.54545455,53.5522847 7.0977393,54 6.54545455,54 C5.9931698,54 5.54545455,53.5522847 5.54545455,53 L5.54545455,26 Z M4.81818182,62 C4.81818182,62.5522847 5.26589707,63 5.81818182,63 L36.1818182,63 C36.7341029,63 37.1818182,62.5522847 37.1818182,62 L37.1818182,59.2727273 L4.81818182,59.2727273 L4.81818182,62 Z"
                }
              }),
              _c(
                "g",
                { attrs: { transform: "translate(25.000000, 41.000000)" } },
                [
                  _c("circle", {
                    staticStyle: { fill: "var(--v-secondary-base)" },
                    attrs: { cx: "17", cy: "17", r: "17" }
                  }),
                  _c(
                    "g",
                    {
                      staticStyle: { fill: "var(--v-bus_white-base)" },
                      attrs: { transform: "translate(9.000000, 10.000000)" }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M5.80786352,11.4603136 L15.237669,0.352812711 C15.5951006,-0.0682110959 16.2261635,-0.119762709 16.6471873,0.237668961 C17.0682111,0.59510063 17.1197627,1.22616348 16.762331,1.64718729 L5.81713648,14.5396864 L0.241580517,8.05176674 C-0.118380316,7.63290323 -0.0706302578,7.00154135 0.348233257,6.64158052 C0.767096771,6.28161968 1.39845865,6.32936974 1.75841948,6.74823326 L5.80786352,11.4603136 Z",
                          transform:
                            "translate(8.500001, 7.269833) scale(-1, -1) rotate(-180.000000) translate(-8.500001, -7.269833) "
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }