var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticStyle: { margin: "0px 20px" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _vm._l(_vm.videos, function(video) {
            return [
              video.type === "YOUTUBE"
                ? _c("VideoItem", { key: video.id, attrs: { video: video } })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }