// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ReportDetails
 */
export interface ReportDetails {
  /**
   * @type {Date}
   * @memberof ReportDetails
   */
  createdAt?: Date;
  /**
   * @type {string}
   * @memberof ReportDetails
   */
  deviceName?: string;
  /**
   * @type {string}
   * @memberof ReportDetails
   */
  downloadUri?: string;
  /**
   * @type {boolean}
   * @memberof ReportDetails
   */
  isManuallyCreated?: boolean;
  /**
   * @type {string}
   * @memberof ReportDetails
   */
  reportId?: string;
  /**
   * @type {string}
   * @memberof ReportDetails
   */
  reportName?: string;
  /**
   * @type {Date}
   * @memberof ReportDetails
   */
  requestedAt?: Date;
  /**
   * @type {string}
   * @memberof ReportDetails
   */
  status?: ReportDetailsStatusEnum;
  /**
   * @type {string}
   * @memberof ReportDetails
   */
  timezone?: string;
  /**
   * @type {string}
   * @memberof ReportDetails
   */
  userName?: string;
  /**
   * @type {string}
   * @memberof ReportDetails
   */
  virtualFridgeId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ReportDetailsStatusEnum {
  REQUESTED = 'REQUESTED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  DELETED = 'DELETED'
}
