var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SimpleDialog", {
        attrs: { dialogShown: _vm.hubCheckRunning, buttonExit: false },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-container",
                  {
                    staticStyle: { height: "38vh" },
                    attrs: {
                      "mb-4": "",
                      "fill-height": "",
                      fluid: "",
                      "justify-center": ""
                    }
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: {
                        color: "secondary",
                        indeterminate: "",
                        size: "36"
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "ml-3",
                        staticStyle: { color: "primary" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("biz.onboarding.error.updatecheck"))
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("SimpleDialog", {
        attrs: {
          dialogShown: _vm.showSchUpdateNeeded,
          title: _vm.$t("biz.onboarding.error.updatetitle"),
          message: _vm.$t("biz.onboarding.error.updatemessage"),
          track: "track + '::UpdateNeededError'"
        },
        on: {
          evtClose: function($event) {
            return _vm.onErrorOkay()
          }
        }
      }),
      _c("SimpleDialog", {
        attrs: {
          dialogShown: _vm.showSchOffline,
          title: _vm.$t("biz.onboarding.error.updateofflinetitle"),
          message: _vm.$t("biz.onboarding.scan.scanerror"),
          track: "track + '::HubOfflineError'"
        },
        on: {
          evtClose: function($event) {
            return _vm.onErrorOkay()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }