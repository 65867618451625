var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.showUnAssignLicenseDialog,
          title: _vm.$t("biz.licenseremoval.removelicensetitle"),
          message: _vm.$t("biz.licenseremoval.removelicensedesc"),
          okDisabled: _vm.unassignOkDisabled,
          track: _vm.track + "::AskUnAssignLicense"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmUnassignLicense()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelUnassignLicense()
          }
        }
      }),
      _c("OkDialog", {
        attrs: {
          title: _vm.$t("biz.licenseremoval.responsehints.title"),
          message: _vm.statusMessage,
          dialogShown: _vm.showUnAssignStatusDialog,
          track: _vm.track + "::UnAssignLicenseStatus"
        },
        on: {
          evtOkPressed: function($event) {
            _vm.showUnAssignStatusDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }