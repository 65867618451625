var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SetupPage", {
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("LicensesDialog", {
              attrs: { dialogShown: true, closeable: false }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }