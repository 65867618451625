// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface MergedAlarm
 */
export interface MergedAlarm {
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  alarmId?: string;
  /**
   * @type {Date}
   * @memberof MergedAlarm
   */
  alarmTime?: Date;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  alarmType?: string;
  /**
   * @type {number}
   * @memberof MergedAlarm
   */
  commentCount?: number;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  deviceName?: string;
  /**
   * @type {number}
   * @memberof MergedAlarm
   */
  deviceSetTemperature?: number;
  /**
   * @type {number}
   * @memberof MergedAlarm
   */
  duration?: number;
  /**
   * @type {Date}
   * @memberof MergedAlarm
   */
  endTime?: Date;
  /**
   * @type {Array<string>}
   * @memberof MergedAlarm
   */
  errorCodes?: Array<string>;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  gatewayId?: string;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  id?: string;
  /**
   * @type {number}
   * @memberof MergedAlarm
   */
  maxAlarmTemperature?: number;
  /**
   * @type {number}
   * @memberof MergedAlarm
   */
  maxAlarmTemperatureThreshold?: number;
  /**
   * @type {number}
   * @memberof MergedAlarm
   */
  minAlarmTemperature?: number;
  /**
   * @type {number}
   * @memberof MergedAlarm
   */
  minAlarmTemperatureThreshold?: number;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  model?: string;
  /**
   * @type {boolean}
   * @memberof MergedAlarm
   */
  readstate?: boolean;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  sensorId?: string;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  serialNumber?: string;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  source?: MergedAlarmSourceEnum;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  state?: MergedAlarmStateEnum;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  task?: string;
  /**
   * @type {string}
   * @memberof MergedAlarm
   */
  virtualFridgeId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum MergedAlarmSourceEnum {
  APPLIANCE = 'APPLIANCE',
  SMARTMODULE = 'SMARTMODULE',
  GATEWAY = 'GATEWAY',
  SENSOR = 'SENSOR'
}
/**
 * @export
 * @enum {string}
 */
export enum MergedAlarmStateEnum {
  On = 'On',
  Off = 'Off'
}
