var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c(
        "div",
        { staticClass: "window-container" },
        [
          _c(
            "v-window",
            {
              staticStyle: { height: "inherit" },
              attrs: { touchless: "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 1 } },
                [
                  _c("BaseWindowPage", {
                    attrs: { trackCancel: _vm.track + "::CheckingHub" },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "biz.setup.sch_serialnumber.bsdbwifichange.title"
                                )
                              )
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "subtitle",
                        fn: function() {
                          return [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  "align-center": "",
                                  "justify-center": ""
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "biz.setup.sch_serialnumber.bsdbwifichange.text"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c(
                              "v-layout",
                              {
                                staticStyle: { height: "32vh" },
                                attrs: {
                                  column: "",
                                  "align-center": "",
                                  "justify-center": ""
                                }
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "secondary",
                                    size: "48"
                                  }
                                }),
                                _c(
                                  "p",
                                  { staticClass: "bus_normaltext mt-5" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("general.actions.loading"))
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 2 } },
                [
                  _c("BaseWindowPage", {
                    attrs: {
                      trackCancel: _vm.track + "::NameAssignmentCancel"
                    },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "biz.setup.sch_serialnumber.bsdbname.title"
                                )
                              )
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "subtitle",
                        fn: function() {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "biz.setup.sch_serialnumber.bsdbname.text"
                                )
                              )
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c("v-text-field", {
                              staticClass: "default selected errorborder",
                              attrs: {
                                clearable: _vm.IconHelper.clearableIcon(
                                  _vm.name
                                ),
                                placeholder: _vm.$t("biz.applianceinfo.name"),
                                rules: _vm.bsdbNameRules,
                                type: "string",
                                required: ""
                              },
                              model: {
                                value: _vm.name,
                                callback: function($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name"
                              }
                            }),
                            _c(
                              "div",
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { column: "", xs12: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticStyle: {
                                              "margin-top": "5px"
                                            },
                                            attrs: { xs10: "" }
                                          },
                                          [
                                            _c("p", {
                                              staticClass: "bus_normaltext",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.$t(
                                                    "biz.onboarding.start.timezonetext"
                                                  )
                                                )
                                              }
                                            })
                                          ]
                                        ),
                                        _c("TimeZoneSelector", {
                                          on: {
                                            evtTimeZoneChange:
                                              _vm.onTimeZoneChanged
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "navigation-place" },
            [
              _c("v-footer", { attrs: { color: "white" } }, [
                _c(
                  "div",
                  {
                    staticClass: "navigation-content",
                    attrs: { "grid-list-lg": "" }
                  },
                  [
                    _c("BackNextMenu", {
                      attrs: {
                        stepCount: 3,
                        nextDisabled: _vm.nextDisabled,
                        stepNextExemptions: [3],
                        step: _vm.step
                      },
                      on: {
                        evtNextStepExemption: _vm.handleNextStep,
                        "update:step": function($event) {
                          _vm.step = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "finalStep",
                          fn: function() {
                            return [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value:
                                        _vm.track + "::GoToOnboardingLanding",
                                      expression:
                                        "track + '::GoToOnboardingLanding'"
                                    }
                                  ],
                                  attrs: {
                                    depressed: "",
                                    disabled: _vm.nextDisabled,
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.gotoOnboardingLanding()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.setup.sch_connected.complete")
                                    )
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("OkDialog", {
        attrs: {
          dialogShown: _vm.allDoneDialogShown,
          title: _vm.$t(
            "biz.setup.sch_serialnumber.bsdbwifichange.alldonetitle"
          ),
          message: _vm.$t(
            "biz.setup.sch_serialnumber.bsdbwifichange.alldonetext"
          ),
          track: _vm.track + "::AllDone"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.allDoneConfirmed()
          }
        }
      }),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.cancelDialogShown,
          title: _vm.$t("biz.setup.abort.title"),
          message: _vm.$t("biz.setup.abort.description"),
          track: _vm.track + "::AbortNameEntry"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmAbortNameEntry()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelAbortNameEntry()
          }
        }
      }),
      _c("BsdbNotFoundDialog", {
        attrs: { sensorOnboarding: false, dialogShown: _vm.showBsdbNotFound },
        on: {
          evtClose: function($event) {
            return _vm.afterBsdbNotFound()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }