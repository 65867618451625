var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: "SetupLanding::AddBsdb",
              expression: "'SetupLanding::AddBsdb'"
            }
          ],
          staticClass: "tilecard",
          staticStyle: { display: "flex", "flex-direction": "column" },
          attrs: { to: "SetupNetwork" }
        },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-lg": "" } },
            [
              _c("v-flex", { staticStyle: { filter: "invert(1)" } }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("biz.onboarding.welcome.setup.bsdbtitle"))
                  )
                ])
              ]),
              _c(
                "v-flex",
                {
                  staticStyle: {
                    filter: "invert(1)",
                    "margin-top": "20px",
                    "margin-bottom": "20px"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("biz.onboarding.welcome.setup.bsdbdescription")
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: "SetupLanding::AddAppliance",
              expression: "'SetupLanding::AddAppliance'"
            }
          ],
          staticClass: "tilecard",
          staticStyle: { display: "flex", "flex-direction": "column" },
          attrs: { to: "ModbusSetup" }
        },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-lg": "" } },
            [
              _c("v-flex", { staticStyle: { filter: "invert(1)" } }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("biz.onboarding.welcome.setup.appliancestitle")
                    )
                  )
                ])
              ]),
              _c(
                "v-flex",
                {
                  staticStyle: {
                    filter: "invert(1)",
                    "margin-top": "20px",
                    "margin-bottom": "20px"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "biz.onboarding.welcome.setup.appliancesdescription"
                      )
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.isProdEnvironment
        ? _c(
            "v-card",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: "WelcomeContent::OnBoardSmartModule",
                  expression: "'WelcomeContent::OnBoardSmartModule'"
                }
              ],
              staticClass: "tilecard",
              staticStyle: { display: "flex", "flex-direction": "column" },
              attrs: { to: "setupSm" }
            },
            [
              _c(
                "v-container",
                { attrs: { "grid-list-lg": "" } },
                [
                  _c("v-flex", { staticStyle: { filter: "invert(1)" } }, [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$t("biz.onboarding.smartmodule.title")))
                    ])
                  ]),
                  _c(
                    "v-flex",
                    {
                      staticStyle: {
                        filter: "invert(1)",
                        "margin-top": "24px",
                        "margin-bottom": "100px"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("biz.onboarding.smartmodule.description"))
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }