var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showType
    ? _c(
        "div",
        { class: _vm.appropriateTile },
        [
          _c(
            "v-layout",
            { attrs: { row: "", "fill-height": "" } },
            [
              _c("v-flex", { attrs: { "pa-0": "" } }, [_vm._t("type")], 2),
              _c("v-flex", [_vm._t("info")], 2),
              _c(
                "v-flex",
                { staticClass: "alerticon" },
                [_vm._t("alerticon")],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "v-layout",
            { attrs: { row: "", "fill-height": "" } },
            [
              _c("v-flex", [_vm._t("info")], 2),
              _c(
                "v-flex",
                { staticClass: "alerticon" },
                [_vm._t("alerticon")],
                2
              ),
              _c(
                "v-flex",
                { staticClass: "type", attrs: { "pa-0": "" } },
                [_vm._t("type")],
                2
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }