var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.wizard
        ? _c(
            "div",
            [
              _c(
                "v-layout",
                { attrs: { column: "", "pa-3": "" } },
                [
                  _c("p", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.sensors.creationwizard.edittempdesc"))
                    )
                  ]),
                  _c("v-checkbox", {
                    staticStyle: { "padding-top": "40px" },
                    attrs: {
                      color: "secondary",
                      label: _vm.$t(
                        "biz.sensors.alarmsetting.temperature.activecheck"
                      )
                    },
                    model: {
                      value: _vm.editableDataLocal.temperatureAlarmActive,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.editableDataLocal,
                          "temperatureAlarmActive",
                          $$v
                        )
                      },
                      expression: "editableDataLocal.temperatureAlarmActive"
                    }
                  }),
                  _c("p", { staticClass: "bus_normaltext" }, [
                    _vm._v(_vm._s(_vm.$t("general.temperature")))
                  ]),
                  _c("RangeSlider", {
                    attrs: {
                      minSliderValue:
                        _vm.editableDataLocal.minTemperatureThreshold,
                      maxSliderValue:
                        _vm.editableDataLocal.maxTemperatureThreshold,
                      minValue: _vm.LOW_TEMPERATURE,
                      maxValue: _vm.HIGH_TEMPERATURE,
                      active: _vm.editableDataLocal.temperatureAlarmActive,
                      step: 0.5
                    },
                    on: {
                      "update:minSliderValue": function($event) {
                        return _vm.$set(
                          _vm.editableDataLocal,
                          "minTemperatureThreshold",
                          $event
                        )
                      },
                      "update:min-slider-value": function($event) {
                        return _vm.$set(
                          _vm.editableDataLocal,
                          "minTemperatureThreshold",
                          $event
                        )
                      },
                      "update:maxSliderValue": function($event) {
                        return _vm.$set(
                          _vm.editableDataLocal,
                          "maxTemperatureThreshold",
                          $event
                        )
                      },
                      "update:max-slider-value": function($event) {
                        return _vm.$set(
                          _vm.editableDataLocal,
                          "maxTemperatureThreshold",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "700px" },
              model: {
                value: _vm.editorShown,
                callback: function($$v) {
                  _vm.editorShown = $$v
                },
                expression: "editorShown"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-3" },
                [
                  _c("v-card-title", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("biz.sensors.alarmsetting.temperature.title")
                      )
                    )
                  ]),
                  _c(
                    "v-layout",
                    { attrs: { column: "", "pa-3": "" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          color: "secondary",
                          label: _vm.$t(
                            "biz.sensors.alarmsetting.temperature.activecheck"
                          )
                        },
                        model: {
                          value: _vm.editableDataLocal.temperatureAlarmActive,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.editableDataLocal,
                              "temperatureAlarmActive",
                              $$v
                            )
                          },
                          expression: "editableDataLocal.temperatureAlarmActive"
                        }
                      }),
                      _c("p", { staticClass: "bus_normaltext pb-3" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "biz.sensors.alarmsetting.temperature.editdescription1"
                            )
                          )
                        )
                      ]),
                      _c("p", { staticClass: "bus_normaltext" }, [
                        _vm._v(_vm._s(_vm.$t("general.temperature")))
                      ]),
                      _c("RangeSlider", {
                        attrs: {
                          minSliderValue:
                            _vm.editableDataLocal.minTemperatureThreshold,
                          maxSliderValue:
                            _vm.editableDataLocal.maxTemperatureThreshold,
                          minValue: _vm.LOW_TEMPERATURE,
                          maxValue: _vm.HIGH_TEMPERATURE,
                          active: _vm.editableDataLocal.temperatureAlarmActive,
                          step: 0.5
                        },
                        on: {
                          "update:minSliderValue": function($event) {
                            return _vm.$set(
                              _vm.editableDataLocal,
                              "minTemperatureThreshold",
                              $event
                            )
                          },
                          "update:min-slider-value": function($event) {
                            return _vm.$set(
                              _vm.editableDataLocal,
                              "minTemperatureThreshold",
                              $event
                            )
                          },
                          "update:maxSliderValue": function($event) {
                            return _vm.$set(
                              _vm.editableDataLocal,
                              "maxTemperatureThreshold",
                              $event
                            )
                          },
                          "update:max-slider-value": function($event) {
                            return _vm.$set(
                              _vm.editableDataLocal,
                              "maxTemperatureThreshold",
                              $event
                            )
                          }
                        }
                      }),
                      _c("v-flex"),
                      _c("p", { staticClass: "bus_normaltext pt-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "biz.sensors.alarmsetting.temperature.editdescription3"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c("SaveCancel", {
                        attrs: {
                          saveDisabled: _vm.saveDisabled,
                          track: _vm.track,
                          icon: false
                        },
                        on: {
                          evtSave: function($event) {
                            return _vm.onSave()
                          },
                          evtCancel: function($event) {
                            return _vm.onCancel()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }