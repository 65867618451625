var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        [
          _c(
            "v-content",
            [
              _vm.unsupportedBrowser
                ? _c("SupportedBrowsersDialog", {
                    attrs: { dialogShown: _vm.unsupportedBrowser }
                  })
                : _vm.maintenance
                ? _c("MaintenanceDialog", {
                    attrs: {
                      dialogShown: _vm.maintenance,
                      data: _vm.maintenanceObject
                    }
                  })
                : _c("router-view")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }