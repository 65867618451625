var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SimpleDialog", {
        attrs: {
          title: _vm.title,
          dialogShown: _vm.dialogShown,
          track: "ApplianceError"
        },
        on: {
          evtClose: function($event) {
            return _vm.closeErrorDialog()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "additionalcontent",
            fn: function() {
              return [_vm._v(_vm._s(_vm.subtitle))]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.messageText) }
                  })
                ]),
                _vm.contactsShown
                  ? _c("ContactInfo", {
                      attrs: {
                        smartCoolingHubId: _vm.gatewaySerialNumber,
                        ipAddress: _vm.ipAddress,
                        serialNumber: _vm.serialNumber
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "buttons",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: "ApplianceError::Help",
                        expression: "'ApplianceError::Help'"
                      }
                    ],
                    attrs: { depressed: "", outline: "", color: "secondary" },
                    on: {
                      click: function($event) {
                        return _vm.onHelpPressed()
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "pl-0", attrs: { color: "secondary" } },
                      [_vm._v("help_outline")]
                    ),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.help")) +
                        "\n      "
                    )
                  ],
                  1
                ),
                _c("v-spacer"),
                _vm.showApplianceButton
                  ? _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "ApplianceError::Help",
                            expression: "'ApplianceError::Help'"
                          }
                        ],
                        attrs: { depressed: "", color: "secondary" },
                        on: {
                          click: function($event) {
                            return _vm.routeToAppliance()
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "biz.onboarding.binderscan.error.showappliance"
                            )
                          )
                        )
                      ]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }