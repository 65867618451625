var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { width: "100%" },
      attrs: {
        "align-start": "",
        "fill-height": "",
        column: "",
        "pl-4": "",
        "pr-0": "",
        "py-3": ""
      }
    },
    [
      _c(
        "v-flex",
        { staticStyle: { width: "100%" }, attrs: { xs12: "" } },
        [
          _c("GatewayAlarmDetails", {
            ref: "gatewayDetails",
            attrs: {
              alarmProfileId: _vm.selectedProfile.alarmProfileId,
              serialNumber: _vm.selectedProfile.serialNumber,
              name: _vm.selectedProfile.name,
              model: _vm.selectedProfile.model,
              gatewayId: _vm.selectedProfile.gatewayId,
              track: "GatewayAlarmProfile::GatewayAlarmDetails"
            },
            on: { evtDefaultChanged: _vm.defaultChanged }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }