var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "30px",
        height: "30px",
        version: "1.1",
        viewBox: "0 0 30 30",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("path", {
        staticStyle: { fill: "var(--v-secondary-base)" },
        attrs: {
          "fill-rule": "evenodd",
          stroke: "none",
          "stroke-width": "1",
          d:
            "M 14.863 15 C 17.824 15 20.501 16.208 22.432 18.159 L 20.541 20.03 C 19.093 18.567 17.084 17.663 14.863 17.663 C 12.696 17.663 10.729 18.527 9.289 19.928 L 7.433 18.023 C 9.351 16.152 11.973 15 14.863 15 Z M 14.863 20.324 C 16.343 20.324 17.683 20.929 18.648 21.904 L 14.863 25.649 L 11.147 21.835 C 12.106 20.9 13.418 20.324 14.863 20.324 Z M 14.863 9.675 C 19.304 9.675 23.321 11.486 26.215 14.411 L 24.323 16.284 C 21.91 13.847 18.563 12.339 14.863 12.339 C 11.25 12.339 7.973 13.778 5.574 16.115 L 3.717 14.208 C 6.593 11.404 10.527 9.675 14.863 9.675 Z M 14.863 4.351 C 20.784 4.351 26.14 6.767 30 10.666 L 28.106 12.54 C 24.729 9.127 20.045 7.014 14.863 7.014 C 9.804 7.014 5.216 9.028 1.858 12.301 L 0 10.394 C 3.839 6.654 9.082 4.351 14.863 4.351 Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }