var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-3" },
    [
      _c("v-card-title", { staticClass: "title" }, [_vm._t("title")], 2),
      _c("p", { staticClass: "bus_normaltext pa-3" }, [
        _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.temperaturedescription")))
      ]),
      _c("p", { staticClass: "bus_normaltext pa-3" }, [
        _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.delaydescription")))
      ]),
      _vm._l(_vm.cloudAlarmsLocal, function(item, index) {
        return _c(
          "v-layout",
          { key: index, attrs: { column: "", "pa-3": "" } },
          [
            _vm.cloudAlarmsLocal.length > 1
              ? _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("general.zone")) + " " + _vm._s(index + 1)
                  )
                ])
              : _vm._e(),
            _c("v-checkbox", {
              attrs: {
                color: "secondary",
                label: _vm.$t("biz.dashboard.alarms.cloudalarmactive")
              },
              on: {
                change: function($event) {
                  return _vm.onCheckboxChange()
                }
              },
              model: {
                value: item.enabled,
                callback: function($$v) {
                  _vm.$set(item, "enabled", $$v)
                },
                expression: "item.enabled"
              }
            }),
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "" } },
              [
                _c("v-flex", { attrs: { xs12: "" } }, [
                  _c("p", { staticClass: "pt-3 mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("biz.appliancehistory.temperature")))
                  ])
                ]),
                _c("RangeSlider", {
                  attrs: {
                    minSliderValue: _vm.limits(index)[0],
                    maxSliderValue: _vm.limits(index)[1],
                    active: item.enabled,
                    minValue: _vm.getMinAlarmTemp,
                    maxValue: _vm.getMaxAlarmTemp,
                    step: 0.1
                  },
                  on: {
                    "update:minSliderValue": function($event) {
                      _vm.$set(_vm.limits(index), 0, $event)
                    },
                    "update:min-slider-value": function($event) {
                      _vm.$set(_vm.limits(index), 0, $event)
                    },
                    "update:maxSliderValue": function($event) {
                      _vm.$set(_vm.limits(index), 1, $event)
                    },
                    "update:max-slider-value": function($event) {
                      _vm.$set(_vm.limits(index), 1, $event)
                    }
                  }
                })
              ],
              1
            ),
            _c("v-flex", { attrs: { xs12: "" } }, [
              _c("p", { staticClass: "pt-3 mb-0" }, [
                _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.delaytime")))
              ])
            ]),
            _c("BasicSlider", {
              attrs: {
                sliderValue: _vm.zoneDelay(index)[0],
                active: item.enabled,
                minValue: _vm.minDelayTime,
                maxValue: _vm.maxDelayTime,
                step: 1
              },
              on: {
                "update:sliderValue": function($event) {
                  _vm.$set(_vm.zoneDelay(index), 0, $event)
                },
                "update:slider-value": function($event) {
                  _vm.$set(_vm.zoneDelay(index), 0, $event)
                }
              }
            })
          ],
          1
        )
      }),
      _c(
        "v-layout",
        [
          _c("v-flex", [
            _c("p", { staticClass: "bus_normaltext pa-3" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.cloudalarmhelper")))
            ])
          ])
        ],
        1
      ),
      _vm.dialogShown
        ? _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("SaveCancel", {
                attrs: {
                  saveDisabled: _vm.saveDisabled,
                  track: _vm.track + "::SaveCancel",
                  icon: false
                },
                on: {
                  evtSave: function($event) {
                    return _vm.onSave()
                  },
                  evtCancel: function($event) {
                    return _vm.onCancel()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }