// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface MeasureSiteAddress
 */
export interface MeasureSiteAddress {
  /**
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  additionalInformation?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  addressLine1?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  addressLine2?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  city?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  countryIsoCode?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  externalId?: string;
  /**
   * @type {number}
   * @memberof MeasureSiteAddress
   */
  latitude?: number;
  /**
   * @type {number}
   * @memberof MeasureSiteAddress
   */
  longitude?: number;
  /**
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  mappingType?: string;
  /**
   * @type {boolean}
   * @memberof MeasureSiteAddress
   */
  measureSiteAddress?: boolean;
  /**
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  phone?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  stateRegion?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteAddress
   */
  zipCode?: string;
}
