// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MeasureSite } from './';

/**
 * @export
 * @interface AllMeasureSitesResponse
 */
export interface AllMeasureSitesResponse {
  /**
   * @type {Array<MeasureSite>}
   * @memberof AllMeasureSitesResponse
   */
  connectables?: Array<MeasureSite>;
  /**
   * @type {Array<MeasureSite>}
   * @memberof AllMeasureSitesResponse
   */
  notConnectables?: Array<MeasureSite>;
  /**
   * @type {Array<MeasureSite>}
   * @memberof AllMeasureSitesResponse
   */
  rooms?: Array<MeasureSite>;
  /**
   * @type {Array<MeasureSite>}
   * @memberof AllMeasureSitesResponse
   */
  smartModuleFridges?: Array<MeasureSite>;
  /**
   * @type {Array<MeasureSite>}
   * @memberof AllMeasureSitesResponse
   */
  thirdParties?: Array<MeasureSite>;
}
