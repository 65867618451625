var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "", "pa-0": "", "mx-0": "" } },
        [
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.sessionTimedOut,
                  expression: "!sessionTimedOut"
                }
              ],
              staticClass: "routerview"
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "maincard",
                  attrs: { width: "100%", height: "100%" }
                },
                [_c("router-view", { key: _vm.$route.name })],
                1
              ),
              _c("UserNotificationHandler"),
              _c("MobileLicenseWarningDialog", {
                attrs: {
                  dialogShown: _vm.showLicenseWarningDialog,
                  unlicensedCount: _vm.devicesMissingLicenseCount,
                  track: "MobileHomePage::MobileLicenseWarningDialog"
                },
                on: {
                  evtClose: function($event) {
                    return _vm.closeLicenseWarningDialog()
                  }
                }
              }),
              _c("AccountWarningDialog", {
                attrs: {
                  dialogShown: _vm.showAccountWarningDialog,
                  track: "MobileHomePage::AccountWarningDialog"
                },
                on: {
                  evtClose: function($event) {
                    return _vm.closeAccountWarningDialog()
                  }
                }
              }),
              _c("MobileMissingPhoneDialog", {
                attrs: {
                  dialogShown: _vm.showMissingPhoneDialog,
                  admin: _vm.isAdminUser,
                  track: "MobileHomePage::MobileMissingPhoneDialog"
                },
                on: {
                  evtClose: function($event) {
                    return _vm.closeMissingPhoneDialog()
                  }
                }
              }),
              _c("CookieDialog", {
                attrs: {
                  dialogShown: _vm.showCookieSettingsDialog,
                  track: "MobileHomePage::CookieDialog"
                },
                on: {
                  evtClose: function($event) {
                    return _vm.closeCookieSettingsDialog()
                  }
                }
              }),
              _c("TermsOfUseAcceptanceDialog", {
                attrs: {
                  acceptedVersion: _vm.acceptedTermsOfUseVersion,
                  currentVersion: _vm.currentTermsOfUseVersion,
                  dialogShown: _vm.showTermsOfUseDialog,
                  track: "MobileHomePage::TermsOfUseAcceptanceDialog"
                },
                on: {
                  evtCloseAgree: function($event) {
                    return _vm.closeTermsOfUseDialogAgree()
                  },
                  evtCloseReject: function($event) {
                    return _vm.closeTermsOfUseDialogReject()
                  }
                }
              })
            ],
            1
          ),
          _c("MobileNavigationDrawer"),
          _c("TimeoutDialog", { attrs: { dialogShown: _vm.sessionTimedOut } }),
          _c("v-img", {
            staticClass: "bus_liebherr_logo_mobile",
            attrs: {
              id: "logo",
              src: require("@/assets/logo_ci_liebherr.svg"),
              contain: ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }