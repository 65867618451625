var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pt-5 pl-5 pr-5" },
    [
      _c(
        "v-layout",
        { attrs: { row: "", xs12: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs3: "" } },
            [
              _c(
                "v-icon",
                { staticStyle: { "margin-top": "5px" }, attrs: { size: "80" } },
                [_vm._v(_vm._s(_vm.getConfigureIcon()))]
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs9: "" } },
            [
              _c("v-flex", { staticClass: "title mb-3 ml-1" }, [
                _vm._v(_vm._s(_vm.$t("biz.onboarding.welcome.header")))
              ]),
              _c("v-flex", { staticClass: "ml-1" }, [
                _c("div", {
                  staticClass: "bus_normaltext",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("biz.onboarding.welcome.myliebherr.description")
                    )
                  }
                })
              ]),
              _c("v-flex", { staticClass: "pt-3" }, [_c("WelcomeContent")], 1),
              _c(
                "v-flex",
                { staticClass: "pl-1" },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "DeviceManagement::OpenSmartMonitoringManual",
                          expression:
                            "'DeviceManagement::OpenSmartMonitoringManual'"
                        }
                      ],
                      attrs: {
                        outline: "",
                        color: "secondary",
                        depressed: "",
                        href: _vm.smartMonitoringManual,
                        target: "_blank"
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "margin-left": "-15px",
                            "margin-right": "7px"
                          },
                          attrs: { color: "secondary" }
                        },
                        [_vm._v(_vm._s(_vm.getRightArrowIcon()))]
                      ),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("biz.help.smartmonitoringmanual")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }