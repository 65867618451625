var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: _vm.track + "::Close",
          dialogShown: _vm.cancelDialogShown
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(_vm._s(_vm.defaultTitle))]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t(
                        "biz.onboarding.binderscan.applianceinfo.description"
                      )
                    )
                  }
                }),
                _c(
                  "v-layout",
                  { attrs: { column: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "", nowrap: "" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: _vm.ValidationHelper.MASK_APPLIANCE_SERIAL,
                              expression:
                                "ValidationHelper.MASK_APPLIANCE_SERIAL"
                            }
                          ],
                          staticClass: "default selected errorborder",
                          attrs: {
                            clearable: _vm.iconHelper.clearableIcon(
                              _vm.serialNumber
                            ),
                            placeholder: _vm.$t("biz.general.items.serial"),
                            rules: _vm.serialNumberRules,
                            required: ""
                          },
                          model: {
                            value: _vm.serialNumber,
                            callback: function($$v) {
                              _vm.serialNumber = $$v
                            },
                            expression: "serialNumber"
                          }
                        }),
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: _vm.track + "::CheckModbusAppliance",
                                expression: "track + '::CheckModbusAppliance'"
                              }
                            ],
                            attrs: {
                              depressed: "",
                              color: "primary",
                              disabled: !_vm.isValidFridgeSerialNumber
                            },
                            on: {
                              click: function($event) {
                                return _vm.checkAppliance()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.check")) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  { attrs: { column: "" } },
                  [
                    _c(
                      "v-layout",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.readyFlag && !_vm.checking,
                            expression: "!readyFlag && !checking"
                          }
                        ],
                        attrs: { row: "", "offset-xs1": "" }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { "mt-3": "", "pt-3": "", "pl-3": "" } },
                          [
                            _c("v-img", {
                              attrs: {
                                src: require("@/assets/serialnumber.png"),
                                contain: ""
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          [
                            _c("v-img", {
                              attrs: {
                                src: require("@/assets/serialnumberult.png"),
                                contain: "",
                                "max-height": "45vh",
                                "min-height": "320"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-layout",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.readyFlag && _vm.checking,
                            expression: "!readyFlag && checking"
                          }
                        ],
                        staticStyle: { "min-height": "400px" },
                        attrs: {
                          "fill-height": "",
                          fluid: "",
                          "justify-center": ""
                        }
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            indeterminate: "",
                            color: "secondary",
                            size: "48"
                          }
                        }),
                        _c("p", { staticClass: "bus_normaltext mt-5" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "biz.onboarding.binderscan.applianceinfo.checking"
                              )
                            )
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { "ml-1": "" } },
                      [
                        _vm.readyFlag
                          ? _c("SetupApplianceInfo", {
                              attrs: {
                                applianceInfo: _vm.applianceInfo
                                  ? _vm.applianceInfo
                                  : "",
                                serialNumber: _vm.serialNumber
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "helplink",
            fn: function() {
              return [
                _c(
                  "v-flex",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.readyFlag && !_vm.checking,
                        expression: "readyFlag && !checking"
                      }
                    ],
                    staticClass: "bus_normaltext",
                    attrs: { "ml-2": "" }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "biz.onboarding.binderscan.applianceinfo.description1"
                          )
                        ) +
                        "\n      "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("ErrorWithHelpDialog", {
        attrs: {
          dialogShown: _vm.applianceIsUsedError || _vm.applianceNotConnectable,
          title: _vm.$t("biz.onboarding.stepper.applianceinusetitle"),
          message: _vm.errorMessage,
          serialNumber: _vm.serialNumber,
          track: _vm.track + "::AlreadyOnboarded"
        },
        on: {
          evtCancelPressed: function($event) {
            return _vm.closeApplianceInUseError()
          },
          evtRetryPressed: function($event) {
            return _vm.closeApplianceInUseError()
          }
        }
      }),
      _c("OkDialog", {
        attrs: {
          title: _vm.$t("biz.onboarding.stepper.notfoundtitle"),
          message: _vm.$t("biz.onboarding.stepper.notfoundmessage"),
          dialogShown: _vm.applianceNotFoundDialog,
          track: _vm.track + "::ApplianceNotFound"
        },
        on: {
          evtOkPressed: function($event) {
            _vm.applianceNotFoundDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }