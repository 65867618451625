var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    [
      _c(
        "v-layout",
        {
          staticStyle: { "max-width": "60%" },
          attrs: { column: "", "align-start": "" }
        },
        [
          _vm.isMeasureSiteVoucherMode
            ? _c(
                "p",
                { staticClass: "mb-3", staticStyle: { color: "secondary" } },
                [_vm._v(_vm._s(_vm.$t("biz.vouchers.redeemhint")))]
              )
            : _c(
                "p",
                { staticClass: "mb-3", staticStyle: { color: "secondary" } },
                [_vm._v(_vm._s(_vm.$t("biz.vouchers.redeemcompanyhint")))]
              ),
          _c(
            "p",
            { staticStyle: { "margin-bottom": "0px", "font-weight": "bold" } },
            [_vm._v(_vm._s(_vm.$t("biz.vouchers.redeemvoucher")))]
          ),
          _c("v-text-field", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.ValidationHelper.MASK_VOUCHER_CODE,
                expression: "ValidationHelper.MASK_VOUCHER_CODE"
              }
            ],
            staticClass: "default selected errorborder",
            staticStyle: { width: "60%" },
            attrs: {
              clearable: _vm.iconHelper.clearableIcon(_vm.voucherCode),
              rules: _vm.voucherCodeRules,
              placeholder: _vm.$t("biz.vouchers.licensekey")
            },
            model: {
              value: _vm.voucherCode,
              callback: function($$v) {
                _vm.voucherCode = $$v
              },
              expression: "voucherCode"
            }
          }),
          _vm.isActivating
            ? _c(
                "v-layout",
                { attrs: { "ml-1": "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "secondary" }
                  }),
                  _c(
                    "p",
                    {
                      staticClass: "ml-3 mt-1",
                      staticStyle: { color: "secondary" }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.vouchers.activating")))]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: "RedeemVoucher::RedeemVoucherKey",
                  expression: "'RedeemVoucher::RedeemVoucherKey'"
                }
              ],
              staticClass: "mt-1 ml-0",
              attrs: {
                color: "primary",
                depressed: "",
                disabled: !_vm.redeemVoucherBtnEnabled
              },
              on: {
                click: function($event) {
                  return _vm.redeemVoucher()
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("biz.vouchers.activatevoucherkey")) +
                  "\n    "
              )
            ]
          ),
          _vm.isMeasureSiteVoucherMode
            ? _c(
                "v-layout",
                { attrs: { row: "", flex: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "RedeemVoucher::AssignExistingLicenses",
                          expression: "'RedeemVoucher::AssignExistingLicenses'"
                        }
                      ],
                      staticClass: "mt-1 ml-0",
                      attrs: {
                        color: "primary",
                        disabled: _vm.isActivating,
                        depressed: ""
                      },
                      on: { click: _vm.openLicenseAssignmentDialog }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("biz.vouchers.assignexistinglicenses")
                          ) +
                          "\n      "
                      )
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "RedeemVoucher::BuyLicense",
                          expression: "'RedeemVoucher::BuyLicense'"
                        }
                      ],
                      staticClass: "mt-1 ml-0",
                      attrs: {
                        disabled: _vm.isActivating,
                        color: "secondary",
                        outline: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.buyLicenseDialog = true
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("biz.settings.buylicenses")) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("OkDialog", {
        attrs: {
          title: _vm.$t("biz.vouchers.resultheader"),
          message: _vm.redeemVoucherStatusMessage,
          dialogShown: _vm.showRedeemVoucherStatusDialog,
          track: "RedeemVoucher::LicenseKeyAssignment"
        },
        on: {
          evtOkPressed: function($event) {
            _vm.showRedeemVoucherStatusDialog = false
          }
        }
      }),
      _c("ErrorWithHelpDialog", {
        attrs: {
          title: _vm.$t("biz.vouchers.resultheaderfailure"),
          message: _vm.redeemVoucherStatusMessage,
          dialogShown: _vm.showRedeemVoucherErrorDialog,
          showOkay: true,
          track: "RedeemVoucher::RedeemVoucherError"
        },
        on: {
          evtOkPressed: function($event) {
            _vm.showRedeemVoucherErrorDialog = false
          }
        }
      }),
      _c("AssignLicenseDialog", {
        attrs: {
          showAssignLicenseDialog: _vm.showAssignLicenseDialog,
          unAssignedLicenses: _vm.unAssignedLicenses,
          unLicensedAppliances: [_vm.selectedAppliance],
          applianceSelectionDisabled: true,
          virtualFridgeId: _vm.virtualFridgeId,
          track: "RedeemVoucher::OpenAssignExistingLicensesDialog"
        },
        on: {
          evtCancel: function($event) {
            _vm.showAssignLicenseDialog = false
          },
          evtLicenseAssign: function($event) {
            return _vm.onLicenseAssigned()
          }
        }
      }),
      _c("BuyLicenseDialog", {
        attrs: {
          showDialog: _vm.buyLicenseDialog,
          serialNumber: _vm.serialNumber
        },
        on: {
          evtClose: function($event) {
            _vm.buyLicenseDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }