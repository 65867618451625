var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs8: "", "pa-3": "", "ma-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-1": "" } },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track + "::DeleteReport",
                  expression: "track + '::DeleteReport'"
                }
              ],
              staticClass: "hidden-md-and-down button ma-0 pa-0 mr-2 px-2",
              attrs: {
                depressed: "",
                outline: "",
                color: "secondary",
                width: "30",
                disabled: _vm.noReportsGenerated
              },
              on: {
                click: function($event) {
                  _vm.deleteReportShown = true
                }
              }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  style: _vm.getIconStyle,
                  attrs: { color: _vm.iconColor }
                },
                [_vm._v(_vm._s(_vm.getDeleteIcon()))]
              ),
              _vm._v("\n      " + _vm._s(_vm.$t("general.delete")) + "\n    ")
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track + "::DeleteReport",
                  expression: "track + '::DeleteReport'"
                }
              ],
              staticClass: "hidden-lg-and-up ma-0 pa-0 mr-2",
              attrs: {
                icon: "",
                depressed: "",
                disabled: _vm.noReportsGenerated
              },
              on: {
                click: function($event) {
                  _vm.deleteReportShown = true
                }
              }
            },
            [
              _c(
                "v-icon",
                { style: _vm.getIconStyle, attrs: { color: _vm.iconColor } },
                [_vm._v(_vm._s(_vm.getDeleteIcon()))]
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track + "::DownloadReports",
                  expression: "track + '::DownloadReports'"
                }
              ],
              staticClass: "hidden-md-and-down button ma-0 pa-0 mr-2 px-2",
              attrs: {
                depressed: "",
                outline: "",
                color: "secondary",
                width: "30",
                disabled: _vm.noReportsGenerated
              },
              on: {
                click: function($event) {
                  return _vm.downloadReports()
                }
              }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  style: _vm.getIconStyle,
                  attrs: { color: _vm.iconColor, size: "20" }
                },
                [_vm._v(_vm._s(_vm.getDownloadIcon()))]
              ),
              _vm._v(
                "\n      PDF " + _vm._s(_vm.$t("general.download")) + "\n    "
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track + "::DownloadReports",
                  expression: "track + '::DownloadReports'"
                }
              ],
              staticClass: "hidden-lg-and-up ma-0 pa-0 mr-2",
              attrs: {
                icon: "",
                depressed: "",
                disabled: _vm.noReportsGenerated
              },
              on: {
                click: function($event) {
                  return _vm.downloadReports()
                }
              }
            },
            [
              _c(
                "v-icon",
                {
                  style: _vm.getIconStyle,
                  attrs: { color: _vm.iconColor, size: "20" }
                },
                [_vm._v(_vm._s(_vm.getDownloadIcon()))]
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track + "::PrintReport",
                  expression: "track + '::PrintReport'"
                }
              ],
              staticClass: "hidden-md-and-down button ma-0 pa-0 mr-2 px-2",
              attrs: {
                depressed: "",
                outline: "",
                color: "secondary",
                width: "30",
                disabled: _vm.noReportsGenerated
              },
              on: {
                click: function($event) {
                  return _vm.printReport()
                }
              }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  style: _vm.getIconStyle,
                  attrs: { color: _vm.iconColor, size: "20" }
                },
                [_vm._v(_vm._s(_vm.getPrintIcon()))]
              ),
              _vm._v("\n      " + _vm._s(_vm.$t("general.print")) + "\n    ")
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track + "::PrintReport",
                  expression: "track + '::PrintReport'"
                }
              ],
              staticClass: "hidden-lg-and-up ma-0 pa-0 mr-2",
              attrs: {
                icon: "",
                depressed: "",
                flat: "",
                width: "30",
                disabled: _vm.noReportsGenerated
              },
              on: {
                click: function($event) {
                  return _vm.printReport()
                }
              }
            },
            [
              _c(
                "v-icon",
                {
                  style: _vm.getIconStyle,
                  attrs: { color: _vm.iconColor, size: "20" }
                },
                [_vm._v(_vm._s(_vm.getPrintIcon()))]
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track + "::CreateReport",
                  expression: "track + '::CreateReport'"
                }
              ],
              staticClass: "ma-0 pa-0 px-3 mr-4",
              attrs: { depressed: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.createReportShown = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("biz.report.newreport")))]
          )
        ],
        1
      ),
      _c(
        "v-container",
        [
          _vm.noReportsGenerated
            ? _c(
                "v-layout",
                {
                  staticStyle: { height: "85vh" },
                  attrs: {
                    column: "",
                    "align-center": "",
                    "justify-center": ""
                  }
                },
                [
                  _c("v-icon", { attrs: { size: "72" } }, [
                    _vm._v(_vm._s(_vm.getNoReportsIcon()))
                  ]),
                  _c("h3", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("biz.report.noreportstitle"))
                    }
                  }),
                  _c("p", {
                    staticClass: "bus_normaltext mt-2",
                    staticStyle: { "text-align": "center" },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("biz.report.noreportsdesc"))
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.noReportsGenerated
            ? _c(
                "v-card",
                [
                  _vm.previewFlag
                    ? _c(
                        "v-flex",
                        [
                          _c(
                            "v-layout",
                            { attrs: { "justify-end": "" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  directives: [
                                                    {
                                                      name: "track",
                                                      rawName: "v-track",
                                                      value:
                                                        "ReportPreview::OpenPdfFullScreen",
                                                      expression:
                                                        "'ReportPreview::OpenPdfFullScreen'"
                                                    }
                                                  ],
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openPdfFullScreen()
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "$vuetify.icons.bus_expand"
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2465217420
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("biz.report.fullscreen"))
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                position: "relative",
                                overflow: "hidden"
                              }
                            },
                            [
                              _c("canvas", {
                                staticStyle: {
                                  width: "100%",
                                  margin: "auto",
                                  display: "block"
                                },
                                attrs: { id: "pdf-preview" }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    : !_vm.isFailed()
                    ? _c(
                        "v-layout",
                        {
                          staticStyle: { height: "85vh" },
                          attrs: {
                            column: "",
                            "align-center": "",
                            "justify-center": ""
                          }
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              color: "secondary",
                              size: "48"
                            }
                          }),
                          _c("p", { staticClass: "bus_normaltext mt-5" }, [
                            _vm._v(_vm._s(_vm.$t("biz.report.generating1")))
                          ]),
                          _c("p", { staticClass: "bus_normaltext" }, [
                            _vm._v(_vm._s(_vm.$t("biz.report.generating2")))
                          ])
                        ],
                        1
                      )
                    : _c(
                        "v-layout",
                        {
                          staticClass: "bus_normaltext mt-5",
                          staticStyle: { height: "85vh" },
                          attrs: {
                            column: "",
                            "align-center": "",
                            "justify-center": ""
                          }
                        },
                        [
                          _c("OkDialog", {
                            attrs: {
                              title: _vm.$t(
                                "general.messages.reportfailed.title"
                              ),
                              message: _vm.$t(
                                "general.messages.reportfailed.content"
                              ),
                              dialogShown: _vm.dialogShown,
                              track: "Reports::CreateReport::Failed"
                            },
                            on: {
                              evtOkPressed: function($event) {
                                return _vm.onCloseReportFailed()
                              }
                            }
                          })
                        ],
                        1
                      )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-layout",
            { attrs: { "align-center": "", "justify-center": "", "pa-4": "" } },
            [
              _vm.selectedReport
                ? _c("v-pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hasPages,
                        expression: "hasPages"
                      }
                    ],
                    key: _vm.selectedReport.reportId,
                    attrs: { length: _vm.pageCount },
                    model: {
                      value: _vm.pageNumber,
                      callback: function($$v) {
                        _vm.pageNumber = $$v
                      },
                      expression: "pageNumber"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("CreateReport", {
        attrs: {
          editorShown: _vm.createReportShown,
          selectedApplianceZone: _vm.selectedApplianceZone
        },
        on: {
          evtClose: function($event) {
            return _vm.closeDialog()
          },
          evtReportsCreated: _vm.reportsCreated
        }
      }),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.deleteReportShown,
          title: _vm.$t("biz.report.deletereport.title"),
          message: _vm.$t("biz.report.deletereport.message"),
          track: _vm.track + "::DeleteReportDialog"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmDeleteReport()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelDeleteReport()
          }
        }
      }),
      _c("ReportPreviewFullScreen", {
        attrs: {
          dialogPdfShown: _vm.dialogPdfShown,
          loadedPdf: _vm.loadedPdf,
          pageCount: _vm.pageCount,
          selectedReport: _vm.selectedReport
        },
        on: {
          evtClose: function($event) {
            return _vm.closePdfFullScreen()
          },
          evtDelete: function($event) {
            return _vm.deleteReport()
          },
          evtPrint: function($event) {
            return _vm.printReport()
          },
          evtDownload: function($event) {
            return _vm.downloadReport()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }