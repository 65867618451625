var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c("SessionRenewDialog", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sessionRenewShown,
            expression: "sessionRenewShown"
          }
        ],
        attrs: { dialogShown: _vm.sessionRenewShown },
        on: {
          evtClose: function($event) {
            return _vm.onClose()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "window-container" },
        [
          _c(
            "v-window",
            {
              staticStyle: { height: "inherit" },
              attrs: { touchless: "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 1 } },
                [
                  _c("NetworkConnectionStep", {
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      },
                      evtPrevious: _vm.previousStep
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 2 } },
                [
                  _c("CheckNetworkAddressStep", {
                    attrs: { track: _vm.track + "::CheckNetworkAddressStep" },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 3 } },
                [
                  _c("SelectBsdbStep", {
                    attrs: { track: _vm.track + "::SelectBsdbStep" },
                    on: {
                      evtGatewaySelected: _vm.onGatewaySelected,
                      evtBsdbNotFound: function($event) {
                        _vm.showBsdbNotFound = true
                      },
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 4 } },
                [
                  _c("BinderInfoStep", {
                    attrs: {
                      track: _vm.track + "::BinderInfoStep",
                      applianceAlreadyTaken: _vm.applianceAlreadyTaken,
                      ipAddress: _vm.ipAddress,
                      applianceInfo: _vm.applianceInfo,
                      serialNumber: _vm.serialNumber,
                      selectedGatewayName: _vm.selectedGateway.name,
                      readyFlag: _vm.readyFlag
                    },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      },
                      "update:applianceAlreadyTaken": function($event) {
                        _vm.applianceAlreadyTaken = $event
                      },
                      "update:appliance-already-taken": function($event) {
                        _vm.applianceAlreadyTaken = $event
                      },
                      "update:ipAddress": function($event) {
                        _vm.ipAddress = $event
                      },
                      "update:ip-address": function($event) {
                        _vm.ipAddress = $event
                      },
                      "update:applianceInfo": function($event) {
                        _vm.applianceInfo = $event
                      },
                      "update:appliance-info": function($event) {
                        _vm.applianceInfo = $event
                      },
                      evtCloseError: function($event) {
                        return _vm.closeErrorDialog()
                      },
                      evtRetry: function($event) {
                        return _vm.retryOnboarding()
                      },
                      "update:readyFlag": function($event) {
                        _vm.readyFlag = $event
                      },
                      "update:ready-flag": function($event) {
                        _vm.readyFlag = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 5 } },
                [
                  _c("FindBinderStep", {
                    attrs: {
                      gateway: _vm.selectedGateway,
                      ipAddress: _vm.ipAddress,
                      step: _vm.step,
                      serialNumber: _vm.serialNumber,
                      track: _vm.track + "::FindSensorStep",
                      selectedGatewayName: _vm.selectedGateway.name
                    },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      },
                      evtHideErrorDialog: function($event) {
                        return _vm.changeSteps()
                      },
                      evtBinderFound: _vm.binderWasFound
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 6 } },
                [
                  _c("ConfigureDeviceStep", {
                    attrs: {
                      track: _vm.track + "::ConfigureDeviceStep",
                      applianceInfo: _vm.applianceInfo,
                      name: _vm.name,
                      building: _vm.building,
                      department: _vm.department,
                      level: _vm.level,
                      room: _vm.room,
                      flow: _vm.ONBOARD_BINDER
                    },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      },
                      evtHideErrorDialog: function($event) {
                        return _vm.changeSteps()
                      },
                      "update:name": function($event) {
                        _vm.name = $event
                      },
                      "update:building": function($event) {
                        _vm.building = $event
                      },
                      "update:department": function($event) {
                        _vm.department = $event
                      },
                      "update:level": function($event) {
                        _vm.level = $event
                      },
                      "update:room": function($event) {
                        _vm.room = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 7 } },
                [
                  _c("GroupSelectionStep", {
                    attrs: {
                      track: _vm.track + "::GroupSelectionStep",
                      groupId: _vm.groupId
                    },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      },
                      "update:groupId": function($event) {
                        _vm.groupId = $event
                      },
                      "update:group-id": function($event) {
                        _vm.groupId = $event
                      },
                      evtFinishStep: _vm.finishStep
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "navigation-place" },
            [
              _c("v-footer", { attrs: { color: "white" } }, [
                _c(
                  "div",
                  {
                    staticClass: "navigation-content",
                    attrs: { "grid-list-lg": "" }
                  },
                  [
                    _c("BackNextMenu", {
                      attrs: {
                        stepCount: 7,
                        step: _vm.step,
                        stepNextExemptions: [5, 7],
                        nextDisabled: _vm.nextDisabled,
                        previousDisabled: _vm.isPreviousDisabled
                      },
                      on: {
                        "update:step": function($event) {
                          _vm.step = $event
                        },
                        evtNextStepExemption: _vm.handleNextStep,
                        evtPrevious: _vm.previousStep
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "finalStep",
                          fn: function() {
                            return [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value:
                                        _vm.track + "::FinishBinderOnboarding",
                                      expression:
                                        "track + '::FinishBinderOnboarding'"
                                    }
                                  ],
                                  attrs: { depressed: "", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.finishStep()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("general.finish")))]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.cancelDialogShown,
          title: _vm.$t("biz.setup.abort.title"),
          message: _vm.$t("biz.setup.abort.description"),
          track: _vm.track + "::AbortSetup"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmAbort()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelAbort()
          }
        }
      }),
      !_vm.isAdminUser
        ? _c("OkDialog", {
            attrs: {
              title: _vm.$t("biz.onboarding.nopermission.title"),
              message: _vm.$t("biz.onboarding.nopermission.message"),
              dialogWidth: "400px",
              dialogShown: _vm.showNoPermission,
              track: _vm.track + "::PermissionDeny"
            },
            on: {
              evtOkPressed: function($event) {
                return _vm.goToHome()
              }
            }
          })
        : _vm._e(),
      _c("BsdbNotFoundDialog", {
        attrs: {
          showBsdbNotFound: _vm.showBsdbNotFound,
          sensorOnboarding: true,
          dialogShown: _vm.showBsdbNotFound
        },
        on: {
          evtClose: function($event) {
            _vm.showBsdbNotFound = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }