// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RegisteredBinderAppliance } from './';

/**
 * @export
 * @interface RegisteredBinderAppliancesResponse
 */
export interface RegisteredBinderAppliancesResponse {
  /**
   * @type {Array<RegisteredBinderAppliance>}
   * @memberof RegisteredBinderAppliancesResponse
   */
  appliances?: Array<RegisteredBinderAppliance>;
}
