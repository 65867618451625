var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.selectedTab === 0 && _vm.isApplianceWithTemperature
        ? _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.tableData,
              pagination: _vm.historyPagination,
              "hide-actions": ""
            },
            on: {
              "update:pagination": function($event) {
                _vm.historyPagination = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "headers",
                  fn: function(props) {
                    return [
                      _c(
                        "tr",
                        _vm._l(props.headers, function(header, index) {
                          return _c(
                            "th",
                            {
                              key: header.text,
                              staticStyle: { "text-align": "justify" },
                              attrs: { id: index }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(header.text) +
                                  "\n          "
                              ),
                              _vm.showHeaderHelpIcon(header.value)
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openHelpDialog(
                                            header.value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "$vuetify.icons.bus_navigation_help"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  }
                },
                {
                  key: "items",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("tr", { staticStyle: { "border-bottom": "none" } }, [
                        _c("td", { style: _vm.getStatusColor(item) }, [
                          _vm._v(_vm._s(item.status) + " °C")
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(item.min) + "°C / " + _vm._s(item.max) + "°C"
                          )
                        ]),
                        _c(
                          "td",
                          [
                            item.alarmCount > 0 || item.deviceAlarms.length > 0
                              ? _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "track",
                                        rawName: "v-track",
                                        value:
                                          "ApplianceHistory::OpenAlarmHistoryInfo",
                                        expression:
                                          "'ApplianceHistory::OpenAlarmHistoryInfo'"
                                      }
                                    ],
                                    attrs: {
                                      slot: "activator",
                                      depressed: "",
                                      icon: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.showAlarmHistoryDetails(
                                          item.deviceAlarms
                                        )
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [
                                    _c("v-badge", {
                                      staticStyle: { left: "-12px !important" },
                                      attrs: { color: "bus_red" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "badge",
                                            fn: function() {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    item.alarmCount != 0
                                                      ? item.alarmCount
                                                      : item.deviceAlarms.length
                                                  ) + "\n              "
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.smartModuleFridge
                          ? _c(
                              "td",
                              [
                                item.doorOpeningsArray.length > 0
                                  ? _c(
                                      "v-badge",
                                      {
                                        staticClass: "comments-badge",
                                        staticStyle: {
                                          "font-weight": "normal"
                                        },
                                        attrs: {
                                          overlap: "",
                                          color: "secondary"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "badge",
                                              fn: function() {
                                                return [
                                                  item.doorOpeningsArray
                                                    .length > 1
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$format.smallBadgeNumber(
                                                              item
                                                                .doorOpeningsArray
                                                                .length
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      item.doorOpeningsArray
                                                        .length > 0
                                                        ? _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "track",
                                                                    rawName:
                                                                      "v-track",
                                                                    value:
                                                                      "HistoryTable::DoorOpeningsHistoryInfo",
                                                                    expression:
                                                                      "'HistoryTable::DoorOpeningsHistoryInfo'"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "mx-0",
                                                                attrs: {
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.showDoorOpeningDetails(
                                                                      item.doorOpeningsArray
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    size: "30"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "$vuetify.icons.bus_alarm_door_on"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "biz.appliancehistory.dooropenings"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _c(
                              "td",
                              [
                                item.dooropenings > 0
                                  ? _c(
                                      "v-badge",
                                      {
                                        staticStyle: {
                                          "font-weight": "normal"
                                        },
                                        attrs: {
                                          overlap: "",
                                          color: "secondary"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "badge",
                                              fn: function() {
                                                return [
                                                  item.dooropenings > 1
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$format.smallBadgeNumber(
                                                              item.dooropenings
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      item.dooropenings > 0
                                                        ? _c(
                                                            "div",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mx-0",
                                                                attrs: {
                                                                  icon: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    size: "30"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "$vuetify.icons.bus_alarm_door_on"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "biz.appliancehistory.dooropenings"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                        _c(
                          "td",
                          [
                            item.defrost
                              ? _c(
                                  "v-icon",
                                  { staticStyle: { top: "-5px !important" } },
                                  [_vm._v("$vuetify.icons.bus_defrost")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("td", [
                          _vm._v(_vm._s(_vm.$format.dateTime(item.date)))
                        ])
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              386507764
            )
          })
        : _vm._e(),
      (_vm.isModbusOrBinderFridge &&
        _vm.selectedTab != 0 &&
        _vm.isTempSensor) ||
      (!_vm.isModbusOrBinderFridge &&
        _vm.allSensors.length > 0 &&
        _vm.isTempSensor)
        ? _c("v-data-table", {
            attrs: {
              headers: _vm.tempSensorHeaders,
              items: _vm.tempSensorTableData,
              pagination: _vm.historyPagination,
              "hide-actions": ""
            },
            on: {
              "update:pagination": function($event) {
                _vm.historyPagination = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "headers",
                  fn: function(props) {
                    return [
                      _c(
                        "tr",
                        _vm._l(props.headers, function(header, index) {
                          return _c(
                            "th",
                            {
                              key: header.text,
                              staticStyle: { "text-align": "justify" },
                              attrs: { id: index }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(header.text) +
                                  "\n          "
                              ),
                              _vm.showHeaderHelpIcon(header.value)
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openHelpDialog(
                                            header.value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "$vuetify.icons.bus_navigation_help"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  }
                },
                {
                  key: "items",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("tr", { staticStyle: { "border-bottom": "none" } }, [
                        _c("td", { style: _vm.getStatusColor(item) }, [
                          _vm._v(_vm._s(item.status) + " °C")
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(item.min) + "°C / " + _vm._s(item.max) + "°C"
                          )
                        ]),
                        _c(
                          "td",
                          [
                            item.alarmCount > 0
                              ? _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "track",
                                        rawName: "v-track",
                                        value:
                                          "ApplianceHistory::OpenAlarmHistoryInfo",
                                        expression:
                                          "'ApplianceHistory::OpenAlarmHistoryInfo'"
                                      }
                                    ],
                                    attrs: {
                                      slot: "activator",
                                      depressed: "",
                                      icon: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.readSensorAlarmsHistory(
                                          item.alarms,
                                          item.thingId,
                                          item.to,
                                          item.sensorType
                                        )
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [
                                    _c("v-badge", {
                                      staticStyle: { left: "-12px !important" },
                                      attrs: { color: "bus_red" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "badge",
                                            fn: function() {
                                              return [
                                                _vm._v(_vm._s(item.alarmCount))
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("td", [
                          _vm._v(_vm._s(_vm.getSensorLimitText(item)))
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.$format.dateTime(item.date)))
                        ])
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              3737532101
            )
          })
        : _vm._e(),
      (_vm.isModbusOrBinderFridge &&
        _vm.selectedTab != 0 &&
        !_vm.isTempSensor) ||
      (!_vm.isModbusOrBinderFridge &&
        _vm.allSensors.length > 0 &&
        !_vm.isTempSensor)
        ? _c("v-data-table", {
            attrs: {
              headers: _vm.doorSensorHeaders,
              items: _vm.doorSensorTableData,
              pagination: _vm.historyPagination,
              "hide-actions": ""
            },
            on: {
              "update:pagination": function($event) {
                _vm.historyPagination = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "headers",
                  fn: function(props) {
                    return [
                      _c(
                        "tr",
                        _vm._l(props.headers, function(header, index) {
                          return _c(
                            "th",
                            {
                              key: header.text,
                              staticStyle: { "text-align": "justify" },
                              attrs: { id: index }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(header.text) +
                                  "\n          "
                              ),
                              _vm.showHeaderHelpIcon(header.value)
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openDescription(
                                            header.value
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "$vuetify.icons.bus_navigation_help"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  }
                },
                {
                  key: "items",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("tr", { staticStyle: { "border-bottom": "none" } }, [
                        _c("td", [
                          _vm._v(_vm._s(_vm.getDuration(item.duration)))
                        ]),
                        _c(
                          "td",
                          [
                            item.dooropenings > 0
                              ? _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "track",
                                        rawName: "v-track",
                                        value:
                                          "ApplianceHistory::OpenAlarmHistoryInfo",
                                        expression:
                                          "'ApplianceHistory::OpenAlarmHistoryInfo'"
                                      }
                                    ],
                                    attrs: {
                                      slot: "activator",
                                      depressed: "",
                                      icon: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.readSensorAlarmsHistory(
                                          item.alarms,
                                          item.thingId,
                                          item.to,
                                          item.sensorType
                                        )
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [
                                    item.dooropenings > 1
                                      ? _c("v-badge", {
                                          staticStyle: {
                                            top: "-5px !important"
                                          },
                                          attrs: { color: "bus_yellow" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "badge",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(item.dooropenings)
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      : _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              top: "-5px !important"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "$vuetify.icons.bus_alarm_door_on"
                                            )
                                          ]
                                        )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("td", [_vm._v(_vm._s(_vm.getDuration(item.delay)))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.$format.date(item.from)) +
                              " " +
                              _vm._s(_vm.$format.time(item.from))
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.$format.date(item.to)) +
                              " " +
                              _vm._s(_vm.$format.time(item.to))
                          )
                        ])
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              4041693441
            )
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "text-xs-center pt-2" },
        [
          _vm.pages > 1
            ? _c("v-pagination", {
                attrs: { length: _vm.pages },
                model: {
                  value: _vm.historyPagination.page,
                  callback: function($$v) {
                    _vm.$set(_vm.historyPagination, "page", $$v)
                  },
                  expression: "historyPagination.page"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("AlarmsHistoryDialog", {
        attrs: {
          alarmsHistory: _vm.alarmsHistory,
          isFridgeAlarms: _vm.fridgeAlarms,
          dialogShown: _vm.alarmsHistoryShown,
          deviceName: _vm.deviceName
        },
        on: {
          evtClose: function($event) {
            return _vm.onClose()
          }
        }
      }),
      _c("DoorOpeningsHistoryDialog", {
        attrs: {
          selectedDoorOpeningEntry: _vm.selectedDoorOpeningEntry,
          dialogShown: _vm.showDetails
        },
        on: {
          evtClose: function($event) {
            _vm.showDetails = false
          }
        }
      }),
      _c("SimpleDialog", {
        attrs: {
          title: _vm.helpDialogTitle,
          track: "HistoryTable::TableHeaderDescription",
          dialogShown: _vm.showHeaderDescription
        },
        on: {
          evtClose: function($event) {
            return _vm.closeHelpDialog()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  { attrs: { row: "", "mb-4": "" } },
                  [
                    _c(
                      "v-flex",
                      [
                        _c(
                          "v-icon",
                          {
                            staticStyle: { "margin-right": "15px" },
                            attrs: { size: "50" }
                          },
                          [_vm._v("$vuetify.icons.bus_navigation_help")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        staticStyle: { "align-self": "center" },
                        attrs: { "pt-1": "" }
                      },
                      [
                        _c("p", { staticStyle: { "margin-bottom": "0px" } }, [
                          _vm._v(_vm._s(_vm.helpDialogMessage))
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }