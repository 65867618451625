import { render, staticRenderFns } from "./AssignmentList.vue?vue&type=template&id=c3e3c0b4&"
import script from "./AssignmentList.ts?vue&type=script&lang=ts&"
export * from "./AssignmentList.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/hau-bus-ui_master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c3e3c0b4')) {
      api.createRecord('c3e3c0b4', component.options)
    } else {
      api.reload('c3e3c0b4', component.options)
    }
    module.hot.accept("./AssignmentList.vue?vue&type=template&id=c3e3c0b4&", function () {
      api.rerender('c3e3c0b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/general/dialogs/AssignmentList.vue"
export default component.exports