var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "1200px" },
          model: {
            value: _vm.editorShown,
            callback: function($$v) {
              _vm.editorShown = $$v
            },
            expression: "editorShown"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "scroll-y", staticStyle: { position: "relative" } },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _c("v-divider"),
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticStyle: { "min-width": "200px" },
                      attrs: { xs3: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "", "fill-height": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-list",
                                {
                                  staticClass: "scroll-y ml-3 mt-2",
                                  staticStyle: { height: "65vh" }
                                },
                                [
                                  _c(
                                    "v-list-tile",
                                    {
                                      class: _vm.formatSelectedGroup(
                                        _vm.tmpGroups.defaultGroup
                                      ),
                                      on: {
                                        click: function($event) {
                                          return _vm.changeSelect(
                                            _vm.tmpGroups.defaultGroup
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-tile-title", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("biz.groupeditor.default")
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.tmpGroups.groups, function(group) {
                                    return _c(
                                      "v-list-tile",
                                      {
                                        key: group.groupId,
                                        class: _vm.formatSelectedGroup(group),
                                        staticStyle: { "margin-top": "1px" },
                                        on: {
                                          click: function($event) {
                                            return _vm.changeSelect(group)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-tile-title", {
                                          domProps: {
                                            textContent: _vm._s(group.name)
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-footer",
                            { attrs: { color: "white" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value: "GroupEditor::CreateGroup",
                                      expression: "'GroupEditor::CreateGroup'"
                                    }
                                  ],
                                  staticStyle: {
                                    "margin-left": "15px",
                                    "margin-bottom": "15px"
                                  },
                                  attrs: { color: "secondary", outline: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.createGroup()
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(_vm._s(_vm.getAddIcon()))
                                  ]),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("biz.groupeditor.newgroup")
                                      ) +
                                      "\n              "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c(
                    "v-flex",
                    { attrs: { xs9: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "margin-bottom": "0px",
                                "margin-left": "15px",
                                "margin-top": "15px",
                                "font-weight": "bold"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("biz.groupeditor.groupname")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _c(
                            "v-layout",
                            {
                              staticStyle: {
                                "background-color": "white",
                                "justify-content": "space-between"
                              },
                              attrs: { "ml-3": "", row: "" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "default selected errorborder",
                                attrs: {
                                  clearable: _vm.iconHelper.clearableIcon(
                                    _vm.groupName
                                  ),
                                  readonly: _vm.isDisable(),
                                  placeholder: _vm.$t(
                                    "biz.groupeditor.groupname"
                                  ),
                                  rules: _vm.groupNameRules,
                                  type: "string",
                                  required: ""
                                },
                                model: {
                                  value: _vm.groupName,
                                  callback: function($$v) {
                                    _vm.groupName = $$v
                                  },
                                  expression: "groupName"
                                }
                              }),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value: "GroupEditor::DeleteGroup",
                                      expression: "'GroupEditor::DeleteGroup'"
                                    }
                                  ],
                                  attrs: {
                                    depressed: "",
                                    disabled: _vm.isDisable(),
                                    color: "secondary",
                                    outline: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteGroup()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      style: _vm.deleteIconStyle
                                    },
                                    [_vm._v(_vm._s(_vm.getDeleteIcon()))]
                                  ),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("biz.groupeditor.removegroup")
                                      ) +
                                      "\n              "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("AssignedAppliances", {
                            attrs: {
                              selectedGroup: _vm.selectedGroup,
                              groups: _vm.tmpGroups.groups
                            },
                            on: {
                              evtMove: _vm.onMove,
                              evtDeleteAppliance: _vm.onDeleteAppliance
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list",
                [
                  _c(
                    "v-layout",
                    { attrs: { "ma-2": "", "justify-end": "" } },
                    [
                      _c("SaveCancel", {
                        attrs: {
                          saveDisabled: _vm.saveDisabled,
                          track: "GroupEditor::SaveChanges",
                          icon: false
                        },
                        on: {
                          evtSave: function($event) {
                            return _vm.onSave()
                          },
                          evtCancel: function($event) {
                            return _vm.onCancel()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.confirmShown,
          title: _vm.$t("biz.groupeditor.deletegroup.title"),
          message: _vm.$t("biz.groupeditor.deletegroup.message"),
          track: "GroupEditor::DeleteGroup"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.onConfirmDeleteGroup()
          },
          evtCancelPressed: function($event) {
            return _vm.onCancelDeleteGroup()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }