var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "28px",
        height: "28px",
        viewBox: "0 0 28 28",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            transform: "matrix(1.38763, 0, 0, 1.38763, -2.404173, -2.898952)"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-secondary-base)" },
            attrs: {
              d:
                "M10.8217129,5.62980883 L3.49744338,12.3437225 C3.09032439,12.7169149 2.45775702,12.689412 2.08456461,12.282293 C1.71137221,11.875174 1.73887514,11.2426067 2.14599413,10.8694142 L11.8217188,2 L21.4974434,10.8694142 C21.9045624,11.2426067 21.9320653,11.875174 21.5588729,12.282293 C21.1856805,12.689412 20.5531131,12.7169149 20.1459941,12.3437225 L12.8217129,5.62979803 L12.8217129,21.1782871 C12.8217129,21.7305719 12.3739976,22.1782871 11.8217129,22.1782871 C11.2694281,22.1782871 10.8217129,21.7305719 10.8217129,21.1782871 L10.8217129,5.62980883 Z",
              transform:
                "translate(11.821719, 12.089144) scale(-1, 1) rotate(-90.000000) translate(-11.821719, -12.089144) "
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }