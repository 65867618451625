var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%" } },
    [
      _c("AlarmList", {
        attrs: {
          selectedSensor: _vm.currentSensor,
          singleDeviceSelected: _vm.singleDeviceSelected,
          showDeviceFilter: false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }