var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "", height: "100%" } },
    [
      !_vm.dataReady
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "65vh" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.loading")))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady && !_vm.profileDetails.alarmProfileId
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs10: "", "pl-4": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "subheading" },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { "justify-space-between": "", wrap: "" }
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "bus_propertytitle" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.dashboard.alarms.nosettings")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady && _vm.profileDetails.alarmProfileId
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs10: "", "pl-4": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "bus_propertytitle" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "biz.dashboard.alarms.deviceinformation"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(_vm._s(_vm.name))
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.model")))
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(_vm._s(_vm.model))
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(
                                _vm._s(_vm.$t("biz.applianceinfo.serialnumber"))
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(_vm._s(_vm.serialNumber))
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { staticStyle: { "padding-top": "52px" } },
                        [
                          _c("Unavailability", {
                            attrs: {
                              editableData: _vm.editableData,
                              profileDetails: _vm.profileDetails,
                              track: _vm.track + "::Unavailability"
                            },
                            on: { evtSave: _vm.saveChanges }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  false
                    ? _c(
                        "v-layout",
                        [
                          _c(
                            "v-flex",
                            { staticStyle: { "padding-top": "52px" } },
                            [
                              _c("RepetitionPeriod", {
                                attrs: {
                                  editableData: _vm.editableData,
                                  track: _vm.track + "::RepetitionPeriod"
                                },
                                on: { evtSave: _vm.saveChanges }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { staticStyle: { "padding-top": "52px" } },
                        [
                          _c("AlarmNotification", {
                            attrs: {
                              allUsers: _vm.allUsers,
                              editableData: _vm.editableData,
                              track: _vm.track + "::AlarmNotification"
                            },
                            on: { evtSave: _vm.saveChanges }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }