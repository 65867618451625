var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CardTileLayout", {
    attrs: { licensed: true, twoZone: false, hasSensors: false },
    scopedSlots: _vm._u([
      {
        key: "main",
        fn: function() {
          return [
            _c("v-layout", { attrs: { row: "" } }, [
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.toGateway()
                    }
                  }
                },
                [
                  _c("MainTileLayout", {
                    attrs: { licensed: true },
                    scopedSlots: _vm._u([
                      {
                        key: "type",
                        fn: function() {
                          return [
                            _c(
                              "v-flex",
                              {
                                class: _vm.gatewayColor,
                                attrs: {
                                  "align-center": "",
                                  "justify-center": ""
                                }
                              },
                              [_c("v-icon", [_vm._v(_vm._s(_vm.gatewayIcon))])],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "info",
                        fn: function() {
                          return [
                            _c("InfoTile", {
                              attrs: {
                                title: _vm.gatewayName,
                                alertText: _vm.gatewayState,
                                sensorText: _vm.sensorText,
                                applianceText: _vm.applianceText
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }