// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DataPointsObject, DoorSensorDataPointsObject, TempSensorDataPointsObject } from './';

/**
 * @export
 * @interface HistoryResponse
 */
export interface HistoryResponse {
  /**
   * @type {DataPointsObject}
   * @memberof HistoryResponse
   */
  applianceData?: DataPointsObject;
  /**
   * @type {Array<DoorSensorDataPointsObject>}
   * @memberof HistoryResponse
   */
  doorSensorData?: Array<DoorSensorDataPointsObject>;
  /**
   * @type {Array<TempSensorDataPointsObject>}
   * @memberof HistoryResponse
   */
  tempSensorData?: Array<TempSensorDataPointsObject>;
}
