var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px", persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { attrs: { id: "layout", "fill-height": "", column: "" } },
            [
              _c("BaseWindowItem", {
                attrs: { trackCancel: "track + '::Cancel'" },
                on: {
                  evtCancel: function($event) {
                    return _vm.cancel()
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(_vm._s(_vm.$t("general.area.header")))]
                    },
                    proxy: true
                  },
                  {
                    key: "description",
                    fn: function() {
                      return [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("general.area.message")))
                        ]),
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("general.area.message2")))
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "v-radio-group",
                          {
                            staticClass: "ml-2 scroll-y",
                            staticStyle: { "max-height": "400px !important" },
                            attrs: { mandatory: false },
                            model: {
                              value: _vm.area,
                              callback: function($$v) {
                                _vm.area = $$v
                              },
                              expression: "area"
                            }
                          },
                          [
                            _c("v-radio", {
                              staticClass: "pl-2",
                              attrs: {
                                color: "secondary",
                                label: _vm.$t("general.area.optiondoctor"),
                                value: "Doctor"
                              }
                            }),
                            _c("v-radio", {
                              staticClass: "pl-2",
                              attrs: {
                                color: "secondary",
                                value: "Laboratory",
                                label: _vm.$t("general.area.optionlab")
                              }
                            }),
                            _c("v-radio", {
                              staticClass: "pl-2",
                              attrs: {
                                color: "secondary",
                                value: "Pharmacy",
                                label: _vm.$t("general.area.optionpharmacy")
                              }
                            }),
                            _c("v-radio", {
                              staticClass: "pl-2",
                              attrs: {
                                color: "secondary",
                                value: "Healthcare",
                                label: _vm.$t("general.area.optionhealthcare")
                              }
                            }),
                            _c("v-radio", {
                              staticClass: "pl-2",
                              attrs: {
                                color: "secondary",
                                value: "Dealer",
                                label: _vm.$t("general.area.optiondealer")
                              }
                            }),
                            _c("v-radio", {
                              staticClass: "pl-2",
                              attrs: {
                                color: "secondary",
                                label: _vm.$t("general.area.optionother"),
                                value: "optionother"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          {
                            staticClass: "ml-5",
                            staticStyle: {
                              "margin-top": "-20px",
                              "margin-bottom": "-30px"
                            },
                            attrs: { xs8: "" }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "default selected",
                              attrs: {
                                clearable: _vm.IconHelper.clearableIcon(
                                  _vm.area
                                ),
                                placeholder:
                                  _vm.$t("general.area.placeholder") + "...",
                                disabled: _vm.isOtherFieldDisabled()
                              },
                              model: {
                                value: _vm.otherAreaText,
                                callback: function($$v) {
                                  _vm.otherAreaText = $$v
                                },
                                expression: "otherAreaText"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "helplink",
                    fn: function() {
                      return [
                        _c(
                          "v-flex",
                          { staticClass: "bus_normaltext pt-2 pl-4 pr-4" },
                          [
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("general.area.response")))
                            ])
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: "OrganizationAreaDialog::Submit",
                      expression: "'OrganizationAreaDialog::Submit'"
                    }
                  ],
                  attrs: {
                    depressed: "",
                    color: "primary",
                    disabled: _vm.submitDisabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.onSubmitPressed()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("general.submit")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }