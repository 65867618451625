var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-top": "10px" } },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          pagination: _vm.licensePagination,
          "hide-actions": ""
        },
        on: {
          "update:pagination": function($event) {
            _vm.licensePagination = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "headers",
            fn: function(props) {
              return [
                _c(
                  "tr",
                  _vm._l(props.headers, function(header) {
                    return _c(
                      "td",
                      {
                        key: header.text,
                        staticStyle: { color: "bus_grey", padding: "0 0px" }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(header.text) + "\n        "
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            }
          },
          {
            key: "items",
            fn: function(props) {
              return [
                _c(
                  "tr",
                  {
                    staticStyle: { border: "none", color: "bus_grey" },
                    attrs: { active: _vm.getHighlightedRow(props.selected) }
                  },
                  [
                    _c(
                      "td",
                      { staticStyle: { width: "25%", padding: "0 0px" } },
                      [
                        _c("div", { staticStyle: { "padding-left": "10px" } }, [
                          _vm._v(_vm._s(_vm.getLicenseName(props.item)))
                        ])
                      ]
                    ),
                    _c("td", { staticStyle: { width: "20%" } }, [
                      _vm._v(_vm._s(_vm.getValidUntil(props.item)))
                    ]),
                    _c(
                      "td",
                      { staticStyle: { width: "20%", padding: "0 0px" } },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c("div", [
                                        _c(
                                          "a",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                color:
                                                  "var(--v-secondary-base) !important",
                                                "text-decoration": "none"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.routeToDevice(
                                                    props.item
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getMeasureSiteName(
                                                  props.item
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "biz.dashboard.alarms.showdevicedetails"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "pl-0", staticStyle: { width: "15%" } },
                      [
                        _c(
                          "v-chip",
                          {
                            attrs: {
                              outline: "",
                              label: "",
                              color: _vm.getStatusColor(props.item)
                            }
                          },
                          [_vm._v(_vm._s(_vm.getStatus(props.item)))]
                        )
                      ],
                      1
                    ),
                    _vm.assigned
                      ? _c(
                          "td",
                          {
                            staticClass: "pl-0",
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c("v-layout", { attrs: { row: "" } }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "track",
                                                          rawName: "v-track",
                                                          value:
                                                            "LicensesOverview::OpenUnassignmentDialog",
                                                          expression:
                                                            "'LicensesOverview::OpenUnassignmentDialog'"
                                                        }
                                                      ],
                                                      attrs: {
                                                        color: "primary",
                                                        depressed: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.openUnassignmentDialog(
                                                            props.item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.actions.unassign"
                                                        )
                                                      ) + "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("general.actions.unassign")
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      : _c(
                          "td",
                          {
                            staticClass: "pl-0",
                            staticStyle: { width: "20%" }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "track",
                                                        rawName: "v-track",
                                                        value:
                                                          "LicensesOverview::OpenAssignmentDialog",
                                                        expression:
                                                          "'LicensesOverview::OpenAssignmentDialog'"
                                                      }
                                                    ],
                                                    staticClass: "mx-0",
                                                    attrs: {
                                                      color: "primary",
                                                      depressed: "",
                                                      disabled: _vm.isAssignDisabled(
                                                        props.item
                                                      )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.openAssignmentDialog(
                                                          props.item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "general.actions.assign"
                                                      )
                                                    ) + "\n                "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("general.actions.assign"))
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _vm.pages > 1
            ? _c("v-pagination", {
                attrs: { length: _vm.pages },
                model: {
                  value: _vm.licensePagination.page,
                  callback: function($$v) {
                    _vm.$set(_vm.licensePagination, "page", $$v)
                  },
                  expression: "licensePagination.page"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("AssignLicenseDialog", {
        attrs: {
          showAssignLicenseDialog: _vm.showAssignLicenseDialog,
          entitlementId: _vm.entitlementId,
          unLicensedAppliances: _vm.unlicensedAppliances,
          virtualFridgeId: _vm.assigneeId,
          track: "LicensesOverview::AssignmentDialog"
        },
        on: {
          evtCancel: function($event) {
            return _vm.closeAssignmentDialog()
          },
          evtLicenseAssign: function($event) {
            return _vm.assignLicense()
          }
        }
      }),
      _c("UnassignLicenseDialog", {
        attrs: {
          showUnAssignLicenseDialog: _vm.showUnAssignLicenseDialog,
          virtualFridgeId: _vm.assigneeId,
          track: "'License::UnassignLicenseDialog'"
        },
        on: {
          evtConfirm: function($event) {
            return _vm.unassignLicense()
          },
          evtCancel: function($event) {
            _vm.showUnAssignLicenseDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }