var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "", height: "100%" } },
    [
      !_vm.dataReady
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "65vh" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.loading")))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady && !_vm.profileDetails.enabled
        ? _c(
            "v-layout",
            { attrs: { column: "", "mt-4": "" } },
            [
              _c("p", { staticClass: "ml-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "biz.dashboard.alarms.alarmsettingcreate.explanation"
                    )
                  )
                )
              ]),
              _c(
                "v-flex",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        color: "primary",
                        track: _vm.track + "::ShowCreationWizard"
                      },
                      on: {
                        click: function($event) {
                          return _vm.showCreationWizard()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.create")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dataReady && _vm.profileDetails.enabled,
              expression: "dataReady && profileDetails.enabled"
            }
          ],
          attrs: { column: "" }
        },
        [
          _c(
            "v-layout",
            { attrs: { "ma-3": "" } },
            [
              _c("FixedTemperatureData", {
                attrs: {
                  zonesSettings: _vm.profileDetails.zoneAlarmSettingsResponses
                }
              })
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { "ma-3": "" } },
            [
              _c("CloudAlarms", {
                attrs: { cloudAlarms: _vm.smartModuleCloudAlarms },
                on: { evtCloudAlarmsSave: _vm.saveCloudChanges }
              })
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { "ma-3": "" } },
            [
              _c("Unavailability", {
                attrs: {
                  editableData: _vm.editableData,
                  track: _vm.track + "::Unavailability"
                },
                on: { evtSave: _vm.saveSystemChanges },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "biz.dashboard.alarms.smartmodulesystemalarm"
                            )
                          )
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "text",
                    fn: function() {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "biz.dashboard.alarms.smartmodulesystemalarmtext"
                            )
                          )
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "description",
                    fn: function() {
                      return [
                        _c("v-flex", { attrs: { xs9: "" } }, [
                          _c("p", { staticClass: "bus_normaltext" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "biz.dashboard.alarms.heartbeatdescription"
                                )
                              )
                            )
                          ])
                        ]),
                        _c("v-flex", { attrs: { xs3: "" } }, [
                          _vm.profileDetails.offlineAlarmEnabled
                            ? _c("p", { staticClass: "bus_normaltext" }, [
                                _vm._v(_vm._s(_vm.$t("general.activated")))
                              ])
                            : _c("p", { staticClass: "bus_normaltext" }, [
                                _vm._v(_vm._s(_vm.$t("general.deactivated")))
                              ])
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "description1",
                    fn: function() {
                      return [
                        _c("v-flex", { attrs: { xs6: "" } }, [
                          _c("p", { staticClass: "bus_normaltext" }, [
                            _vm._v(
                              _vm._s(_vm.$t("biz.dashboard.alarms.delaytime"))
                            )
                          ])
                        ]),
                        _c("v-flex", { attrs: { xs6: "" } }, [
                          _vm.profileDetails.offlineAlarmEnabled
                            ? _c(
                                "p",
                                {
                                  staticClass: "bus_normaltext",
                                  staticStyle: { "margin-left": "30px" }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tc(
                                          "general.duration.minute",
                                          _vm.editableData.offlineAlarmDelay
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _c("p", { staticClass: "bus_normaltext" }, [
                                _vm._v(_vm._s(_vm.SHOW_BLANK))
                              ])
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("AlarmProfileCreationWizard", {
        attrs: {
          profileDetails: _vm.profileDetails,
          showWizard: _vm.creationWizardShown,
          track: _vm.track + "::SmartModuleAlarmProfileCreationWizard"
        },
        on: {
          evtCloseWizard: function($event) {
            _vm.creationWizardShown = false
          },
          evtSmartModuleProfileUpdated: _vm.refreshAlarmProfile,
          evtDefaultProfile: _vm.onDefaultProfileDetails
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }