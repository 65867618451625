import { render, staticRenderFns } from "./TermsOfUseAcceptanceDialog.vue?vue&type=template&id=185a01ba&"
import script from "./TermsOfUseAcceptanceDialog.ts?vue&type=script&lang=ts&"
export * from "./TermsOfUseAcceptanceDialog.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/hau-bus-ui_master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('185a01ba')) {
      api.createRecord('185a01ba', component.options)
    } else {
      api.reload('185a01ba', component.options)
    }
    module.hot.accept("./TermsOfUseAcceptanceDialog.vue?vue&type=template&id=185a01ba&", function () {
      api.rerender('185a01ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/general/dialogs/TermsOfUseAcceptanceDialog.vue"
export default component.exports