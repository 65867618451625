var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-navigation-drawer" },
    [
      _c(
        "v-bottom-nav",
        {
          staticClass: "primary",
          attrs: { value: _vm.drawer, dark: "", fixed: "" }
        },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: "MobileNavigation::Dashboard",
                  expression: "'MobileNavigation::Dashboard'"
                }
              ],
              staticClass: "navigationButton",
              on: { click: _vm.showDashboard }
            },
            [_c("v-icon", [_vm._v("$vuetify.icons.bus_navigation_dashboard")])],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: "MobileNavigation::Alarms",
                  expression: "'MobileNavigation::Alarms'"
                }
              ],
              on: { click: _vm.showAlarms }
            },
            [
              _c("v-icon", { staticClass: "navigationButton" }, [
                _vm._v("$vuetify.icons.bus_navigation_alarm")
              ]),
              _c(
                "v-badge",
                {
                  staticClass: "mt-1",
                  attrs: { color: "bus_red", "v-model": _vm.alarmsShown }
                },
                [
                  _vm.unreadAlarmCount > 0
                    ? _c(
                        "span",
                        {
                          staticStyle: { "font-size": "12px" },
                          attrs: { slot: "badge" },
                          slot: "badge"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$format.badgeNumber(_vm.unreadAlarmCount)
                            )
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: "MobileNavigation::UserMenu",
                  expression: "'MobileNavigation::UserMenu'"
                }
              ],
              on: { click: _vm.showUserMenu }
            },
            [_c("v-icon", [_vm._v("$vuetify.icons.bus_navigation_user")])],
            1
          )
        ],
        1
      ),
      _c("MobileUserMenu", {
        attrs: { menuShown: _vm.userMenuShown },
        on: {
          evtClose: function($event) {
            return _vm.closeUserMenu()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }