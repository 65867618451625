var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _vm.sessionRenewShown
        ? _c("SessionRenewDialog", {
            attrs: { dialogShown: _vm.sessionRenewShown },
            on: {
              evtClose: function($event) {
                return _vm.onClose()
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "window-container" },
        [
          _c(
            "v-window",
            {
              staticStyle: { height: "inherit" },
              attrs: { touchless: "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 1 } },
                [
                  _c("BaseWindowPage", {
                    attrs: { trackCancel: _vm.track + "::NetworkConnection" },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _vm._v(
                              _vm._s(_vm.$t("biz.setup.establish_wan.title"))
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "subtitle",
                        fn: function() {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("biz.setup.establish_wan.text")
                                )
                              }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c("p", {
                              staticClass: "bus_normaltext mb-3",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("biz.setup.establish_wan.text2")
                                )
                              }
                            }),
                            _c(
                              "v-container",
                              { attrs: { "mr-4": "" } },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { "justify-end": "" } },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: require("@/assets/sc-label-help.png"),
                                        "max-height": "500",
                                        "max-width": "450"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "helplink",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track",
                                    value:
                                      _vm.track + "::HelpNetworkConnection",
                                    expression:
                                      "track + '::HelpNetworkConnection'"
                                  }
                                ],
                                staticStyle: { "margin-left": "25px" },
                                attrs: {
                                  depressed: "",
                                  color: "secondary",
                                  outline: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showNetworkHelpDialog = !_vm.showNetworkHelpDialog
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-1",
                                    attrs: { color: "secondary" }
                                  },
                                  [_vm._v("help_outline")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "biz.setup.establish_wan.nonetwork"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "navigation-place" },
            [
              _c("v-footer", { attrs: { color: "white" } }, [
                _c(
                  "div",
                  {
                    staticClass: "navigation-content",
                    attrs: { "grid-list-lg": "" }
                  },
                  [
                    _c("BackNextMenu", {
                      attrs: { stepCount: 1, step: _vm.step },
                      on: {
                        "update:step": function($event) {
                          _vm.step = $event
                        },
                        evtPrevious: _vm.previousStep
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "finalStep",
                          fn: function() {
                            return [
                              _c("Next", {
                                attrs: { icon: false, track: _vm.track },
                                on: {
                                  evtNext: function($event) {
                                    return _vm.openBsdbOnboarding()
                                  }
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("SimpleDialog", {
        attrs: {
          dialogShown: _vm.showNetworkHelpDialog,
          title: _vm.$t("biz.setup.networkhelpdialog.title"),
          message: _vm.$t("biz.setup.networkhelpdialog.text"),
          track: _vm.track + "::CloseNetworkHelpDialog"
        },
        on: {
          evtClose: function($event) {
            _vm.showNetworkHelpDialog = false
          }
        }
      }),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.cancelDialogShown,
          title: _vm.$t("biz.setup.abort.title"),
          message: _vm.$t("biz.setup.abort.description"),
          track: _vm.track + "::AbortSetup"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmAbort()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelAbort()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }