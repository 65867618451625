// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AlarmProfileListItem } from './';

/**
 * @export
 * @interface ApplianceAlarmProfileListResponse
 */
export interface ApplianceAlarmProfileListResponse {
  /**
   * @type {Array<AlarmProfileListItem>}
   * @memberof ApplianceAlarmProfileListResponse
   */
  cloudAlarmProfiles?: Array<AlarmProfileListItem>;
  /**
   * @type {Array<AlarmProfileListItem>}
   * @memberof ApplianceAlarmProfileListResponse
   */
  gatewayAlarmProfiles?: Array<AlarmProfileListItem>;
  /**
   * @type {Array<AlarmProfileListItem>}
   * @memberof ApplianceAlarmProfileListResponse
   */
  sensorAlarmProfiles?: Array<AlarmProfileListItem>;
  /**
   * @type {Array<AlarmProfileListItem>}
   * @memberof ApplianceAlarmProfileListResponse
   */
  smartModuleAlarmProfiles?: Array<AlarmProfileListItem>;
}
