var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-space-between": "", wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.sensors.sensorinfo.properties")))
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { editDisabled: !_vm.licensed, track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.sensors.sensorinfo.name")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.name ? _vm.editableData.name : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.comment")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.description
                    ? _vm.editableData.description
                    : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700px" },
          model: {
            value: _vm.editorShown,
            callback: function($$v) {
              _vm.editorShown = $$v
            },
            expression: "editorShown"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.sensors.sensorinfo.properties")))
              ]),
              _c(
                "v-flex",
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "", "pt-3": "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("div", { staticClass: "bus_required_field" }, [
                            _vm._v("* " + _vm._s(_vm.$t("general.required")))
                          ]),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "margin-bottom": "0px",
                                "font-weight": "bold"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("biz.sensors.setup.properties.name")
                                )
                              )
                            ]
                          ),
                          _c("v-text-field", {
                            staticClass: "default selected errorborder",
                            attrs: {
                              clearable: _vm.iconHelper.clearableIcon(
                                _vm.sensorName
                              ),
                              rules: _vm.sensorNameRules,
                              placeholder: _vm.$t(
                                "biz.sensors.sensorinfo.name"
                              ),
                              type: "string",
                              required: ""
                            },
                            model: {
                              value: _vm.sensorName,
                              callback: function($$v) {
                                _vm.sensorName = $$v
                              },
                              expression: "sensorName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "margin-bottom": "0px",
                                "font-weight": "bold"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("general.comment")))]
                          ),
                          _c("v-textarea", {
                            staticClass: "defaulttextarea selectedtextarea",
                            staticStyle: { "font-size": "14px" },
                            attrs: {
                              placeholder: _vm.$t("general.comment"),
                              clearable: _vm.iconHelper.clearableIcon(
                                _vm.sensorComment
                              )
                            },
                            model: {
                              value: _vm.sensorComment,
                              callback: function($$v) {
                                _vm.sensorComment = $$v
                              },
                              expression: "sensorComment"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("SaveCancel", {
                    attrs: {
                      saveDisabled: _vm.saveDisabled,
                      track: _vm.track,
                      icon: false
                    },
                    on: {
                      evtSave: function($event) {
                        return _vm.onSave()
                      },
                      evtCancel: function($event) {
                        return _vm.onCancel()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }