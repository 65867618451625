var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            transform: "matrix(1, 0, 0, 1, -0.028532, 3)"
          }
        },
        [
          _c(
            "g",
            { attrs: { transform: "translate(-1498.000000, -30.000000)" } },
            [
              _c(
                "g",
                { attrs: { transform: "translate(1261.000000, 19.000000)" } },
                [
                  _c("g", [
                    _c(
                      "g",
                      {
                        attrs: { transform: "translate(237.000000, 8.000000)" }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              transform: "translate(0.257177, 3.000000)"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                staticStyle: {
                                  fill: "var(--v-secondary-base)"
                                },
                                attrs: {
                                  transform: "translate(2.142823, 0.000000)"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M9.6,16 C13.4659932,16 16.6,12.8659932 16.6,9 C16.6,5.13400675 13.4659932,2 9.6,2 C5.73400675,2 2.6,5.13400675 2.6,9 C2.6,12.8659932 5.73400675,16 9.6,16 Z M9.6,18 C4.62943725,18 0.6,13.9705627 0.6,9 C0.6,4.02943725 4.62943725,0 9.6,0 C14.5705627,0 18.6,4.02943725 18.6,9 C18.6,13.9705627 14.5705627,18 9.6,18 Z"
                                  }
                                })
                              ]
                            ),
                            _c("path", {
                              staticStyle: { fill: "var(--v-secondary-base)" },
                              attrs: {
                                d:
                                  "M20.5673298,8.35437194 L23.5274827,14.5978692 L21.7203102,15.4546817 L19.5217685,10.81756 L14.6588668,12.4572768 L14.0198387,10.5621138 L20.5673298,8.35437194 Z M1.82251276,2.54531826 L4.02105451,7.18244 L8.88395617,5.54272316 L9.52298427,7.43788623 L2.97549321,9.64562806 L0.0153403117,3.40213081 L1.82251276,2.54531826 Z"
                              }
                            })
                          ]
                        ),
                        _c(
                          "g",
                          {
                            staticStyle: { fill: "var(--v-secondary-base)" },
                            attrs: {
                              transform:
                                "translate(11.800000, 11.500000) scale(-1, -1) translate(-11.800000, -11.500000) translate(1.800000, 2.000000)"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  transform: "translate(0.142823, 0.000000)"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M9.6,16 C13.4659932,16 16.6,12.8659932 16.6,9 C16.6,5.13400675 13.4659932,2 9.6,2 C5.73400675,2 2.6,5.13400675 2.6,9 C2.6,12.8659932 5.73400675,16 9.6,16 Z M9.6,18 C4.62943725,18 0.6,13.9705627 0.6,9 C0.6,4.02943725 4.62943725,0 9.6,0 C14.5705627,0 18.6,4.02943725 18.6,9 C18.6,13.9705627 14.5705627,18 9.6,18 Z"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }