var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "380px", persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-card", attrs: { flat: "" } },
        [
          _c("v-card-text", { staticClass: "display-1 text-xs-center" }, [
            _vm._v(_vm._s(_vm.$t("general.home.timeoutDialog.title")))
          ]),
          _c("v-divider", { attrs: { light: "" } }),
          _c("v-card-text", { staticClass: "text-xs-center" }, [
            _vm._v(_vm._s(_vm.$t("general.home.timeoutDialog.message")))
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: "TimeoutDialog::ToLogin",
                      expression: "'TimeoutDialog::ToLogin'"
                    }
                  ],
                  attrs: {
                    block: "",
                    round: "",
                    depressed: "",
                    color: "primary",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.doLogin()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("general.home.loginDialog.loginStart")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }