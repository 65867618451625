var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px", persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _c("div", { staticClass: "title" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("general.home.loginDialog.loginText")))
              ])
            ])
          ]),
          _c("v-card-text", { staticClass: "bus_normaltext" }, [
            _c("p", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("general.license.tempadmin"))
              }
            })
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("Ok", {
                attrs: { track: _vm.track, icon: false },
                on: {
                  evtOk: function($event) {
                    return _vm.closeDialog()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }