var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "card mt-2",
      on: {
        click: function($event) {
          return _vm.onShowMorePressed()
        }
      }
    },
    [
      _c("v-flex", { staticClass: "text" }, [
        _vm._v("+" + _vm._s(_vm.substract))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }