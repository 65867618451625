var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-secondary-base)" },
            attrs: {
              d:
                "M 26.409 0.024 C 27.478 0.024 28.345 0.891 28.345 1.96 L 28.345 30.038 C 28.345 31.108 27.478 31.975 26.409 31.975 L 5.592 31.975 C 4.523 31.975 3.656 31.108 3.656 30.038 L 3.656 1.96 C 3.656 0.891 4.523 0.024 5.592 0.024 L 26.409 0.024 Z M 26.409 1.476 L 5.592 1.476 C 5.325 1.476 5.108 1.693 5.108 1.96 L 5.108 30.038 C 5.108 30.306 5.325 30.523 5.592 30.523 L 26.409 30.523 C 26.676 30.523 26.893 30.306 26.893 30.038 L 26.893 1.96 C 26.893 1.693 26.676 1.476 26.409 1.476 Z M 23.02 26.892 C 23.02 27.293 22.695 27.618 22.294 27.618 L 9.707 27.618 C 9.306 27.618 8.981 27.293 8.981 26.892 C 8.981 26.491 9.306 26.166 9.707 26.166 L 22.294 26.166 C 22.695 26.166 23.02 26.491 23.02 26.892 Z M 23.02 23.987 C 23.02 24.388 22.695 24.713 22.294 24.713 L 9.707 24.713 C 9.306 24.713 8.981 24.388 8.981 23.987 C 8.981 23.586 9.306 23.261 9.707 23.261 L 22.294 23.261 C 22.695 23.261 23.02 23.586 23.02 23.987 Z M 23.02 21.083 C 23.02 21.484 22.695 21.809 22.294 21.809 L 9.707 21.809 C 9.306 21.809 8.981 21.484 8.981 21.083 C 8.981 20.681 9.306 20.356 9.707 20.356 L 22.294 20.356 C 22.695 20.356 23.02 20.681 23.02 21.083 Z M 16.243 4.381 C 19.669 4.381 22.455 7.119 22.534 10.526 L 22.536 10.674 L 22.536 11.239 C 22.536 11.729 22.139 12.127 21.649 12.127 L 20.832 12.127 L 20.832 12.127 L 20.821 12.25 C 20.572 15.182 18.114 17.452 15.153 17.452 C 12.012 17.452 9.465 14.905 9.465 11.764 C 9.465 8.803 11.735 6.345 14.667 6.096 L 14.79 6.085 L 14.79 5.268 C 14.79 4.778 15.188 4.381 15.678 4.381 L 16.243 4.381 L 16.243 4.381 Z M 14.79 7.543 C 12.621 7.727 10.918 9.546 10.918 11.764 C 10.918 14.103 12.814 15.999 15.153 15.999 C 17.371 15.999 19.19 14.296 19.374 12.127 L 15.678 12.127 C 15.188 12.127 14.79 11.729 14.79 11.239 L 14.79 7.543 L 14.79 7.543 Z M 16.243 5.833 L 16.243 9.222 L 16.242 9.222 L 16.243 10.674 L 21.084 10.674 C 21.084 8.046 18.989 5.907 16.378 5.835 L 16.243 5.833 Z",
              "fill-rule": "nonzero"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }