var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs6: "" } },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "", wrap: "", "ma-2": "" } },
            [
              _c("v-card-title", { staticClass: "title pl-0" }, [
                _vm._v(_vm._s(_vm.getVideoTitle()))
              ]),
              _c("youtube", {
                staticStyle: { width: "100%" },
                attrs: { "video-id": _vm.id }
              }),
              _c(
                "v-card-text",
                {
                  staticClass: "subheading pl-0",
                  attrs: { "text-xs-justify": "" }
                },
                [_vm._v(_vm._s(_vm.getVideoDescription()))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }