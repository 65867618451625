var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _vm.isAdmin && _vm.needsLicense && !_vm.isLocked
        ? _c(
            "v-layout",
            { attrs: { wrap: "", row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                    _vm._v(_vm._s(_vm.$t("biz.applianceinfo.enterlicenseinfo")))
                  ])
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-layout",
            { attrs: { wrap: "", row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                    _vm._v(_vm._s(_vm.$t("biz.applianceinfo.licenseinfo")))
                  ])
                ],
                1
              )
            ],
            1
          ),
      _vm.isLocked
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    _vm._s(_vm.$t("biz.help.softwarelicense.lockedlicense"))
                  )
                ])
              ]),
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "License::CustomerService",
                          expression: "'License::CustomerService'"
                        }
                      ],
                      staticStyle: { "margin-left": "0px" },
                      attrs: { depressed: "", color: "secondary", outline: "" },
                      on: {
                        click: function($event) {
                          _vm.contactShown = true
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "pr-1", attrs: { color: "secondary" } },
                        [_vm._v("help_outline")]
                      ),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("biz.help.servicedetails.customerservice")
                          ) +
                          "\n      "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isAdmin && _vm.needsLicense && !_vm.isLocked
        ? _c(
            "v-layout",
            [
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("RedeemVoucher", {
                    attrs: {
                      virtualFridgeId: _vm.virtualFridgeId,
                      serialNumber: _vm.serialNumber
                    },
                    on: {
                      evtRedeemVoucher: function($event) {
                        return _vm.onVoucherRedeem()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("p", { staticClass: "bus_normaltext" }, [
                      _vm._v(_vm._s(_vm.$t("biz.applianceinfo.license")))
                    ])
                  ]),
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("p", { staticClass: "bus_normaltext" }, [
                      _vm._v(_vm._s(_vm.licenseName))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("p", { staticClass: "bus_normaltext" }, [
                      _vm._v(_vm._s(_vm.$t("biz.applianceinfo.start")))
                    ])
                  ]),
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("p", { staticClass: "bus_normaltext" }, [
                      _vm._v(_vm._s(_vm.licenseStartDate))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("p", { staticClass: "bus_normaltext" }, [
                      _vm._v(_vm._s(_vm.$t("biz.applianceinfo.end")))
                    ])
                  ]),
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("p", { staticClass: "bus_normaltext" }, [
                      _vm._v(_vm._s(_vm.licenseEndDate))
                    ])
                  ])
                ],
                1
              ),
              _vm.voucherCode
                ? _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _c("p", { staticClass: "bus_normaltext" }, [
                          _vm._v(_vm._s(_vm.$t("biz.applianceinfo.code")))
                        ])
                      ]),
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _c("p", { staticClass: "bus_normaltext" }, [
                          _vm._v(_vm._s(_vm.voucherCode))
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin && !_vm.needsLicense && !_vm.isLocked
                ? _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "License::RemoveLicense",
                                  expression: "'License::RemoveLicense'"
                                }
                              ],
                              staticClass: "mt-1 ml-0",
                              attrs: { color: "primary", depressed: "" },
                              on: {
                                click: function($event) {
                                  _vm.showUnAssignLicenseDialog = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("biz.vouchers.removelicense")) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin && _vm.isLicenseExpiringSoon
                ? _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c("v-flex", [
                        _c("p", { staticClass: "bus_warningtext" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$tc(
                                "biz.vouchers.licenseexpirywarning",
                                _vm.daysBeforeLicenseExpiry
                              )
                            )
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "bus_propertytitle" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("biz.vouchers.renewlicense"))
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c("RedeemVoucher", {
                            attrs: {
                              virtualFridgeId: _vm.virtualFridgeId,
                              serialNumber: _vm.serialNumber
                            },
                            on: {
                              evtRedeemVoucher: function($event) {
                                return _vm.onVoucherRedeem()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
      _c("ContactInfoDialog", {
        attrs: { dialogShown: _vm.contactShown, track: "License::Contacts" },
        on: {
          evtClose: function($event) {
            _vm.contactShown = false
          }
        }
      }),
      _c("UnassignLicenseDialog", {
        attrs: {
          showUnAssignLicenseDialog: _vm.showUnAssignLicenseDialog,
          virtualFridgeId: _vm.virtualFridgeId,
          track: "'License::UnassignLicenseDialog'"
        },
        on: {
          evtConfirm: function($event) {
            return _vm.onUnassignLicense()
          },
          evtCancel: function($event) {
            _vm.showUnAssignLicenseDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }