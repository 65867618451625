var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "v-flex",
            { staticStyle: { "align-self": "center" }, attrs: { xs4: "" } },
            [
              _c(
                "v-layout",
                { class: _vm.getHeaderClass(), attrs: { row: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.backBtnFlag,
                          expression: "backBtnFlag"
                        },
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "SettingBase::GoBack",
                          expression: "'SettingBase::GoBack'"
                        }
                      ],
                      staticClass: "pr-2",
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { flat: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.goBack()
                        }
                      }
                    },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { color: "secondary", size: "40" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "white" } },
                            [_vm._v("keyboard_arrow_left")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "py-2",
                      staticStyle: { "align-self": "center" }
                    },
                    [
                      _c("v-layout", { staticClass: "title" }, [
                        _vm._v(" " + _vm._s(_vm.$t("biz.main.settings")) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-container",
        { attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" } },
        [
          _c("CriticalDevicesPage", {
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [_c("router-view")]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }