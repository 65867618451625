var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "48px",
        height: "48px",
        viewBox: "0 0 48 48",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            transform: "matrix(1.5, 0, 0, 1.5, 0, 0)"
          }
        },
        [
          _c("g", [
            _c("g", { staticStyle: { fill: "var(--v-bus_yellow-base)" } }, [
              _c("circle", { attrs: { cx: "16", cy: "16", r: "16" } })
            ]),
            _c(
              "g",
              {
                staticStyle: { fill: "var(--v-bus_white-base)" },
                attrs: { transform: "translate(4.000000, 4.000000)" }
              },
              [
                _c(
                  "g",
                  { attrs: { transform: "translate(3.000000, 8.000000)" } },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M18,0 L18,9 L2,9 L2,7 L0,7 L0,2 L2,2 L2,0 L18,0 Z M4,2 L4,7 L11,7 L11,2 L4,2 Z"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }