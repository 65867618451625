var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: _vm.track + "::Close",
          dialogShown: _vm.cancelDialogShown
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(_vm._s(_vm.$t("biz.setup.appliances_sch.title")))]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("biz.setup.appliances_sch.text"))
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-container",
                  { attrs: { "ma-2": "" } },
                  [
                    _c(
                      "v-img",
                      {
                        staticClass: "bus_img_center",
                        attrs: {
                          contain: "",
                          src: require("@/assets/appliance_wiring_extended_d_dark_comp@1x.png")
                        }
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              slot: "placeholder",
                              "fill-height": "",
                              "align-center": "",
                              "justify-center": "",
                              "ma-0": ""
                            },
                            slot: "placeholder"
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                indeterminate: "",
                                color: "grey lighten-5"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "helplink",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  { attrs: { row: "" } },
                  [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: _vm.track + "::HelpUserManual",
                            expression: "track + '::HelpUserManual'"
                          }
                        ],
                        attrs: {
                          depressed: "",
                          color: "secondary",
                          outline: ""
                        },
                        on: {
                          click: function($event) {
                            _vm.showManualsDialog = true
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: { color: "secondary" }
                          },
                          [_vm._v("help_outline")]
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("biz.onboarding.manuals.helpertitle")
                            ) +
                            "\n        "
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: _vm.track + "::ShowTutorialVideos",
                            expression: "track + '::ShowTutorialVideos'"
                          }
                        ],
                        attrs: {
                          depressed: "",
                          color: "secondary",
                          outline: ""
                        },
                        on: { click: _vm.showMultipleTutorial }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: { color: "secondary" }
                          },
                          [_vm._v("help_outline")]
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("biz.onboarding.tutorials.tutorialtitle")
                            ) +
                            "\n        "
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("ApplianceManualsDialog", {
        attrs: {
          dialogShown: _vm.showManualsDialog,
          track: _vm.track + "::ApplianceManualsDialog"
        },
        on: {
          evtClosed: function($event) {
            _vm.showManualsDialog = false
          }
        }
      }),
      _vm.showVideosDialog
        ? _c("InstallationVideoDialog", {
            attrs: {
              dialogShown: _vm.showVideosDialog,
              showSingleInstallationVideo: _vm.showSingleInstallationVideo,
              showMultipleInstallationVideo: _vm.showMultipleInstallationVideo,
              track: _vm.track + "::InstallationVideoDialog"
            },
            on: {
              evtClose: function($event) {
                _vm.showVideosDialog = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }