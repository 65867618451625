var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "", "max-width": "700px" } },
    [
      _c(
        "v-layout",
        {
          attrs: { "align-center": "", "fill-height": "", row: "", "pl-4": "" }
        },
        [
          _c(
            "v-layout",
            { staticClass: "title pt-3", attrs: { wrap: "" } },
            [_vm._t("title")],
            2
          ),
          _c("Cancel", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.buttonExit,
                expression: "buttonExit"
              }
            ],
            attrs: { track: _vm.trackCancel },
            on: {
              evtCancel: function($event) {
                return _vm.cancelProcess()
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "70vh",
            "overflow-y": "auto",
            "min-height": "400px"
          },
          attrs: { "grid-list-lg": "" }
        },
        [
          _c(
            "v-flex",
            { staticClass: "subtitle-1 pt-3 pl-4 pr-4" },
            [_vm._t("subtitle")],
            2
          ),
          _c(
            "v-flex",
            { staticClass: "bus_normaltext pt-2 pb-1 pl-4 pr-4" },
            [_vm._t("description")],
            2
          ),
          _c("v-flex", { staticClass: "pl-4 pr-4" }, [_vm._t("content")], 2)
        ],
        1
      ),
      _c("v-flex", [_vm._t("helplink")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }