var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: "SmartModuleWiFiReset::Close",
          dialogShown: _vm.cancelDialogShown,
          dialogType: _vm.dialogType
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("biz.wifireset.resetconnection")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "description",
            fn: function() {
              return [
                _c("span", { staticClass: "bus_subheading" }, [
                  _vm._v(_vm._s(_vm.$t("biz.wifireset.resetconnection")))
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", {
                  staticStyle: { "margin-top": "10px" },
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("biz.onboarding.smartmodule.resetwifisubtitle")
                    )
                  }
                }),
                _c(
                  "v-tabs",
                  {
                    attrs: { centered: "" },
                    model: {
                      value: _vm.selectedTab,
                      callback: function($$v) {
                        _vm.selectedTab = $$v
                      },
                      expression: "selectedTab"
                    }
                  },
                  [
                    _c(
                      "v-tab",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "SmartModuleWiFiReset::ColorDisplay",
                            expression: "'SmartModuleWiFiReset::ColorDisplay'"
                          }
                        ],
                        key: "1"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("biz.onboarding.smartmodule.colordisplay")
                          )
                        )
                      ]
                    ),
                    _c(
                      "v-tab",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "SmartModuleWiFiReset::Monochrome",
                            expression: "'SmartModuleWiFiReset::Monochrome'"
                          }
                        ],
                        key: "2"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "biz.onboarding.smartmodule.monochromedisplay"
                            )
                          )
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.selectedTab,
                      callback: function($$v) {
                        _vm.selectedTab = $$v
                      },
                      expression: "selectedTab"
                    }
                  },
                  [
                    _c(
                      "v-tab-item",
                      { key: "1" },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "bus_normaltext" },
                              [
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "biz.onboarding.smartmodule.resetwifidesc"
                                      )
                                    )
                                  }
                                })
                              ]
                            ),
                            _c(
                              "v-img",
                              {
                                staticClass: "bus_img_center pt-5",
                                attrs: {
                                  contain: "",
                                  "max-height": "400px",
                                  src: require("@/assets/reset_wifi_advanced@3x.png")
                                }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      slot: "placeholder",
                                      "fill-height": "",
                                      "align-center": "",
                                      "justify-center": "",
                                      "ma-0": ""
                                    },
                                    slot: "placeholder"
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      staticStyle: {
                                        color: "var(--v-neutral-base)"
                                      },
                                      attrs: { indeterminate: "" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tab-item",
                      { key: "2" },
                      [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "bus_normaltext" },
                              [
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(
                                        "biz.onboarding.smartmodule.resetwifidesc"
                                      )
                                    )
                                  }
                                })
                              ]
                            ),
                            _c(
                              "v-img",
                              {
                                staticClass: "bus_img_center pt-5",
                                attrs: {
                                  contain: "",
                                  "max-height": "400px",
                                  src: require("@/assets/reset_wifi@3x.png")
                                }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      slot: "placeholder",
                                      "fill-height": "",
                                      "align-center": "",
                                      "justify-center": "",
                                      "ma-0": ""
                                    },
                                    slot: "placeholder"
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      staticStyle: {
                                        color: "var(--v-neutral-base)"
                                      },
                                      attrs: { indeterminate: "" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "navigation-place" },
        [
          _c("v-footer", { attrs: { color: "white" } }, [
            _c(
              "div",
              {
                staticClass: "navigation-content",
                attrs: { "grid-list-lg": "" }
              },
              [
                _c("BackNextMenu", {
                  attrs: { stepCount: 1, step: _vm.step },
                  on: {
                    "update:step": function($event) {
                      _vm.step = $event
                    },
                    evtPrevious: _vm.previousStep
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "finalStep",
                      fn: function() {
                        return [
                          _c("Next", {
                            attrs: {
                              icon: false,
                              track: "SmartModuleWiFiReset::Next"
                            },
                            on: {
                              evtNext: function($event) {
                                return _vm.handleSmartModuleWifiReset()
                              }
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.cancelDialogShown,
          title: _vm.$t("biz.onboarding.smartmodule.skipwifireset"),
          message: _vm.$t("biz.onboarding.smartmodule.skipwifiresetdesc"),
          track: "SmartModuleWiFiReset::AbortSetup"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmResetAbort()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelAbort()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }