var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700px" },
      model: {
        value: _vm.editorShown,
        callback: function($$v) {
          _vm.editorShown = $$v
        },
        expression: "editorShown"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-3" },
        [
          _c("v-card-title", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.reminder.title")))
          ]),
          _c(
            "v-layout",
            { attrs: { column: "", "pa-3": "" } },
            [
              _c("v-checkbox", {
                attrs: {
                  color: "secondary",
                  label: _vm.$t("biz.dashboard.alarms.reminder.activecheck")
                },
                model: {
                  value: _vm.editableDataLocal.reminderActive,
                  callback: function($$v) {
                    _vm.$set(_vm.editableDataLocal, "reminderActive", $$v)
                  },
                  expression: "editableDataLocal.reminderActive"
                }
              }),
              _c(
                "label",
                { staticStyle: { "font-size": "12px", color: "gray" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("biz.dashboard.alarms.reminder.reminderperiod")
                    )
                  )
                ]
              ),
              _c("BasicSlider", {
                attrs: {
                  sliderValue: _vm.reminder,
                  active: _vm.editableDataLocal.reminderActive,
                  minValue: _vm.MIN_REMINDER,
                  maxValue: _vm.MAX_REMINDER,
                  step: 1
                },
                on: {
                  "update:sliderValue": function($event) {
                    _vm.reminder = $event
                  },
                  "update:slider-value": function($event) {
                    _vm.reminder = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("SaveCancel", {
                attrs: {
                  saveDisabled: _vm.saveDisabled,
                  track: _vm.track,
                  icon: false
                },
                on: {
                  evtSave: function($event) {
                    return _vm.onSave()
                  },
                  evtCancel: function($event) {
                    return _vm.onCancel()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }