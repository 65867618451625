var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-3" },
    [
      _c("v-card-title", { staticClass: "title" }, [_vm._t("title")], 2),
      _c(
        "v-layout",
        { attrs: { column: "", "pa-3": "" } },
        [
          _c("v-flex", { attrs: { xs8: "" } }, [
            _c(
              "p",
              { staticClass: "bus_normaltext" },
              [_vm._t("description")],
              2
            )
          ]),
          _c("v-checkbox", {
            attrs: {
              color: "secondary",
              label: _vm.$t(
                "biz.sensors.alarmsetting.sensorunavailability.activecheck"
              )
            },
            on: {
              change: function($event) {
                return _vm.onCheckboxChange()
              }
            },
            model: {
              value: _vm.editableDataLocal.offlineAlarmEnabled,
              callback: function($$v) {
                _vm.$set(_vm.editableDataLocal, "offlineAlarmEnabled", $$v)
              },
              expression: "editableDataLocal.offlineAlarmEnabled"
            }
          }),
          _c("label", { staticStyle: { "font-size": "12px", color: "gray" } }, [
            _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.delaytime")))
          ]),
          _c("BasicSlider", {
            attrs: {
              sliderValue: _vm.editableDataLocal.offlineAlarmDelay,
              active: _vm.editableDataLocal.offlineAlarmEnabled,
              minValue: _vm.minDelayTime,
              maxValue: _vm.maxDelayTime,
              step: 1
            },
            on: {
              "update:sliderValue": function($event) {
                return _vm.$set(
                  _vm.editableDataLocal,
                  "offlineAlarmDelay",
                  $event
                )
              },
              "update:slider-value": function($event) {
                return _vm.$set(
                  _vm.editableDataLocal,
                  "offlineAlarmDelay",
                  $event
                )
              }
            }
          })
        ],
        1
      ),
      _vm.dialogShown
        ? _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("SaveCancel", {
                attrs: {
                  saveDisabled: _vm.saveDisabled,
                  track: _vm.track + "::SaveCancel",
                  icon: false
                },
                on: {
                  evtSave: function($event) {
                    return _vm.onSave()
                  },
                  evtCancel: function($event) {
                    return _vm.onCancel()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }