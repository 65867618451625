var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.licenseState !== _vm.LicenseState.LICENSED
    ? _c(
        "div",
        [
          _c("MobileCardTileLayout", {
            attrs: { licensed: false, twoZone: false, hasSensors: false },
            scopedSlots: _vm._u(
              [
                {
                  key: "main",
                  fn: function() {
                    return [
                      _c("v-layout", { attrs: { row: "" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: { width: "100%" },
                            on: {
                              click: function($event) {
                                return _vm.toDeviceAlarmList(
                                  _vm.mainApplianceZone
                                )
                              }
                            }
                          },
                          [
                            _c("MainTileLayout", {
                              attrs: { licensed: false, hasSensors: false },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "type",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "tile",
                                            attrs: {
                                              "align-center": "",
                                              "justify-center": ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.IconHelper.getUnlicensedIcon()
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "info",
                                    fn: function() {
                                      return [
                                        _vm.licenseState ===
                                        _vm.LicenseState.LOCKED
                                          ? _c("InfoTile", {
                                              attrs: {
                                                title: _vm.getApplianceName(
                                                  _vm.mainApplianceZone
                                                ),
                                                alertText: _vm.getLockedApplianceAlert()
                                              }
                                            })
                                          : _c("InfoTile", {
                                              attrs: {
                                                title: _vm.getApplianceName(
                                                  _vm.mainApplianceZone
                                                ),
                                                alertText: _vm.getUnlicensedApplianceAlert()
                                              }
                                            })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                2424295006
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2331947870
            )
          })
        ],
        1
      )
    : _c(
        "div",
        [
          _c("MobileCardTileLayout", {
            attrs: { licensed: true, twoZone: true, hasSensors: false },
            scopedSlots: _vm._u([
              {
                key: "main",
                fn: function() {
                  return [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-space-between": ""
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.toDeviceAlarmList(
                                  _vm.mainApplianceZone
                                )
                              }
                            }
                          },
                          [
                            _c("MainTileLayout", {
                              attrs: { licensed: true, hasSensors: false },
                              scopedSlots: _vm._u([
                                {
                                  key: "type",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-flex",
                                        {
                                          class: _vm.getApplianceClass(
                                            _vm.mainApplianceZone
                                          ),
                                          attrs: {
                                            "align-center": "",
                                            "justify-center": ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.getApplianceTemperature(
                                                  _vm.mainApplianceZone
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "info",
                                  fn: function() {
                                    return [
                                      _c("InfoTile", {
                                        attrs: {
                                          title: _vm.getApplianceName(
                                            _vm.mainApplianceZone
                                          ),
                                          alertText: _vm.getCriticalApplianceAlert(
                                            _vm.mainApplianceZone
                                          )
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "alerticon",
                                  fn: function() {
                                    return [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getAlarmIcon(
                                              _vm.mainApplianceZone
                                            )
                                          )
                                        )
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "alertcount" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getAlarmTypeCount(
                                                _vm.mainApplianceZone
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            })
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.toDeviceAlarmList(
                                  _vm.secondaryApplianceZone
                                )
                              }
                            }
                          },
                          [
                            _c("MainTileLayout", {
                              attrs: { hasSensors: false },
                              scopedSlots: _vm._u([
                                {
                                  key: "type",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-flex",
                                        {
                                          class: _vm.getApplianceClass(
                                            _vm.secondaryApplianceZone
                                          ),
                                          attrs: {
                                            "align-center": "",
                                            "justify-center": ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.getApplianceTemperature(
                                                  _vm.secondaryApplianceZone
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "info",
                                  fn: function() {
                                    return [
                                      _c("InfoTile", {
                                        attrs: {
                                          title: _vm.getApplianceName(
                                            _vm.secondaryApplianceZone
                                          ),
                                          alertText: _vm.getCriticalApplianceAlert(
                                            _vm.secondaryApplianceZone
                                          )
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "alerticon",
                                  fn: function() {
                                    return [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getAlarmIcon(
                                              _vm.secondaryApplianceZone
                                            )
                                          )
                                        )
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "alertcount" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getAlarmTypeCount(
                                                _vm.secondaryApplianceZone
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }