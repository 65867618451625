var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "", "fill-height": "", "ml-0": "" } },
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", "ma-0": "" } },
        [
          _c(
            "v-flex",
            { attrs: { "pt-4": "" } },
            [
              _c("License", {
                attrs: {
                  virtualFridgeId: _vm.virtualFridgeId,
                  serialNumber: _vm.selectedAppliance.serialNumber
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }