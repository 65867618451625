var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("g", [
            _c("g", { staticStyle: { fill: "var(--v-secondary-base)" } }, [
              _c("circle", { attrs: { cx: "16", cy: "16", r: "16" } })
            ]),
            _c("g", { attrs: { transform: "translate(4.000000, 4.000000)" } }, [
              _c(
                "g",
                { attrs: { transform: "translate(1.000000, 1.000000)" } },
                [
                  _c("path", {
                    staticStyle: { fill: "var(--v-bus_white-base)" },
                    attrs: {
                      d:
                        "M11.52145,1.06444754 C11.6568159,1.14717114 11.7705582,1.26091347 11.8532818,1.39627936 L21.070225,16.47855 C21.3582139,16.9498045 21.2096477,17.5652929 20.7383932,17.8532818 C20.5813853,17.9492311 20.4009479,18 20.2169432,18 L1.78305684,18 C1.23077209,18 0.783056839,17.5522847 0.783056839,17 C0.783056839,16.8159952 0.833825725,16.6355579 0.929775006,16.47855 L10.1467182,1.39627936 C10.4347071,0.92502482 11.0501955,0.776458652 11.52145,1.06444754 Z M11.05,13 C10.3596441,13 9.8,13.5596441 9.8,14.25 C9.8,14.9403559 10.3596441,15.5 11.05,15.5 C11.7403559,15.5 12.3,14.9403559 12.3,14.25 C12.3,13.5596441 11.7403559,13 11.05,13 Z M12,6 L10,6 L10,11 L12,11 L12,6 Z"
                    }
                  }),
                  _c("g", {
                    attrs: { transform: "translate(9.300000, 6.000000)" }
                  })
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }