// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface HistoryDeviceAlarm
 */
export interface HistoryDeviceAlarm {
  /**
   * @type {number}
   * @memberof HistoryDeviceAlarm
   */
  alarmDurationInSeconds?: number;
  /**
   * @type {Date}
   * @memberof HistoryDeviceAlarm
   */
  alarmEndedTimestamp?: Date;
  /**
   * @type {string}
   * @memberof HistoryDeviceAlarm
   */
  alarmId?: string;
  /**
   * @type {Date}
   * @memberof HistoryDeviceAlarm
   */
  alarmStartedTimestamp?: Date;
  /**
   * @type {string}
   * @memberof HistoryDeviceAlarm
   */
  alarmType?: string;
  /**
   * @type {number}
   * @memberof HistoryDeviceAlarm
   */
  maxTemperatureThreshold?: number;
  /**
   * @type {number}
   * @memberof HistoryDeviceAlarm
   */
  minTemperatureThreshold?: number;
  /**
   * @type {number}
   * @memberof HistoryDeviceAlarm
   */
  temperature?: number;
  /**
   * @type {boolean}
   * @memberof HistoryDeviceAlarm
   */
  temperatureAlarmActive?: boolean;
  /**
   * @type {Date}
   * @memberof HistoryDeviceAlarm
   */
  timestamp?: Date;
}
