var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-3" },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-flex", { attrs: { "py-4": "", "pl-3": "" } }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("biz.settings.generalsettings")))
                ])
              ]),
              _c(
                "v-layout",
                { attrs: { column: "", "ma-2": "" } },
                [
                  _c("v-flex", [
                    _c("h3", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("general.menus.settings.phonenumber.header")
                        )
                      )
                    ]),
                    _c("p", {
                      staticClass: "bus_normaltext pt-2 pb-1",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("general.menus.settings.phonenumber.hint1")
                        )
                      }
                    }),
                    _c("p", { staticClass: "bus_normaltext pt-1" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("general.menus.settings.phonenumber.hint2")
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c("v-flex", { attrs: { grow: "" } }, [
                        _c("h3", { staticClass: "ml-2 pt-1" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "general.menus.settings.phonenumber.number"
                              )
                            ) +
                              ": " +
                              _vm._s(_vm.userMobile)
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        {
                          staticClass: "align-items: end",
                          attrs: { shrink: "" }
                        },
                        [
                          _c("Edit", {
                            attrs: {
                              track: "GeneralSettings::OpenPhoneNumberEditor"
                            },
                            on: {
                              evtEdit: function($event) {
                                return _vm.showPhoneNumberEditor()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.mobilePhone && !_vm.isUserMobileValid
                    ? _c("div", [
                        _c("p", { staticClass: "bus_warningtext ml-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.phoneInvalidText) +
                              "\n          "
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "", "ma-3": "" } },
                [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.$t("general.menus.usermenu.separator")))
                  ]),
                  _c("p", {
                    staticClass: "bus_normaltext mt-1",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("general.menus.usermenu.csvseparatortext")
                      )
                    }
                  }),
                  _c(
                    "v-radio-group",
                    {
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.csvSeparator,
                        callback: function($$v) {
                          _vm.csvSeparator = $$v
                        },
                        expression: "csvSeparator"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          color: "secondary",
                          label: _vm.$t("general.menus.usermenu.comma"),
                          value: "Comma"
                        }
                      }),
                      _c("v-radio", {
                        attrs: {
                          color: "secondary",
                          label: _vm.$t("general.menus.usermenu.semicolon"),
                          value: "Semicolon"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isAdminUser
            ? _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "", "ma-3": "" } },
                    [
                      _c("h3", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("general.menus.usermenu.logo.logoheader")
                          )
                        )
                      ]),
                      _c("p", {
                        staticClass: "bus_normaltext mt-1 mb-2",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "general.menus.usermenu.logo.logodescription"
                            )
                          )
                        }
                      }),
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c("v-flex", [
                            _vm.currentImage
                              ? _c("img", {
                                  staticClass: "companyimage",
                                  attrs: {
                                    alt: _vm.$t(
                                      "general.menus.usermenu.logo.logoheader"
                                    ),
                                    src: _vm.currentImage
                                  }
                                })
                              : _vm._e()
                          ]),
                          _c(
                            "v-flex",
                            { attrs: { "pl-0": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value:
                                        "GeneralSettings::OpenUploadDialog",
                                      expression:
                                        "'GeneralSettings::OpenUploadDialog'"
                                    }
                                  ],
                                  attrs: {
                                    depressed: "",
                                    color: "secondary",
                                    outline: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openUploadDialog()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "general.menus.usermenu.logo.change"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "", "ma-3": "" } },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.$t("biz.onboarding.welcome.myliebherr.title"))
                    )
                  ]),
                  _c("p", {
                    staticClass: "bus_normaltext mt-1",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("biz.onboarding.welcome.myliebherr.accountdesc")
                      )
                    }
                  }),
                  _c(
                    "v-flex",
                    { attrs: { "pl-0": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value: "GeneralSettings::GoToMyLiebherr",
                              expression: "'GeneralSettings::GoToMyLiebherr'"
                            }
                          ],
                          staticClass: "mt-3 ml-0",
                          attrs: {
                            slot: "activator",
                            depressed: "",
                            color: "secondary",
                            href: _vm.getMyLiebherrLink(),
                            outline: "",
                            target: "_blank"
                          },
                          slot: "activator"
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(_vm.getRightArrowIcon()))
                          ]),
                          _vm._v("MyLiebherr\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ImageUploadDialog", {
        attrs: {
          dialogShown: _vm.uploadDialogVisible,
          track: "GeneralSettings::ImageUpload"
        },
        on: {
          evtCancel: function($event) {
            return _vm.onUploadCancelPressed()
          },
          evtSave: function($event) {
            return _vm.onUploadSavePressed()
          }
        }
      }),
      _c("PhoneNumberEditDialog", {
        attrs: {
          dialogShown: _vm.phoneNumberEditorShown,
          upn: _vm.getCurrentUserUpn,
          mobilePhoneNumber: _vm.getCurrentUserMobile,
          track: "GeneralSettings::PhoneNumberEditor"
        },
        on: {
          evtClose: function($event) {
            return _vm.onPhoneNumberEditorClose()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }