var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { viewBox: "0 0 30 5", xmlns: "http://www.w3.org/2000/svg" } },
    [
      _c("rect", {
        staticStyle: { fill: "var(--v-primary-base)" },
        attrs: { x: "0", y: "0", width: "30", height: "5" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }