var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "", height: "100%" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.devicealarm")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", {
              staticClass: "bus_normaltext",
              domProps: { innerHTML: _vm._s(_vm.deviceAlarmDescription) }
            })
          ])
        ],
        1
      ),
      _vm.zonesSettings
        ? _c(
            "div",
            [
              _c(
                "v-layout",
                { attrs: { "pt-3": "", "justify-space-between": "" } },
                [
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("p", { staticClass: "bus_normaltext" })
                  ]),
                  _vm.zonesSettings.length > 1
                    ? _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        _vm._l(_vm.zonesSettings, function(item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass: "bus_normaltext",
                              staticStyle: { margin: "25px" },
                              attrs: { row: "", nowrap: "" }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("general.zone")) +
                                  " " +
                                  _vm._s(index + 1) +
                                  "\n        "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { "pt-3": "", "justify-space-between": "" } },
                [
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("p", { staticClass: "bus_normaltext" }, [
                      _vm._v(
                        _vm._s(_vm.$t("biz.dashboard.alarms.settemperature"))
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    _vm._l(_vm.zonesSettings, function(item, index) {
                      return _c(
                        "span",
                        {
                          key: index,
                          staticClass: "bus_normaltext",
                          staticStyle: { margin: "30px" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatTemperature(
                                item.deviceTemperatureAlarmSettingsResponse
                                  .setTemperature.celsius
                              )
                            )
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { "justify-space-between": "" } },
                [
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("p", { staticClass: "bus_normaltext" }, [
                      _vm._v(
                        _vm._s(_vm.$t("biz.dashboard.alarms.mintemperature"))
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    _vm._l(_vm.zonesSettings, function(item, index) {
                      return _c(
                        "span",
                        {
                          key: index,
                          staticClass: "bus_normaltext",
                          staticStyle: { margin: "30px" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatTemperature(
                                  item.deviceTemperatureAlarmSettingsResponse
                                    .lowerTemperatureAlarmLimit.celsius
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { "justify-space-between": "" } },
                [
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c("p", { staticClass: "bus_normaltext" }, [
                      _vm._v(
                        _vm._s(_vm.$t("biz.dashboard.alarms.maxtemperature"))
                      )
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    _vm._l(_vm.zonesSettings, function(item, index) {
                      return _c(
                        "span",
                        {
                          key: index,
                          staticClass: "bus_normaltext",
                          staticStyle: { margin: "30px" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatTemperature(
                                  item.deviceTemperatureAlarmSettingsResponse
                                    .upperTemperatureAlarmLimit.celsius
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "v-layout",
                { staticStyle: { "font-size": "15px", color: "LightGray" } },
                [
                  _c("p", { staticClass: "bus_normaltext mt-5" }, [
                    _vm._v(_vm._s(_vm.$t("general.nodata")))
                  ])
                ]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }