var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { attrs: { flat: "", height: "40", width: "auto" } },
    [
      _c("v-spacer"),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: "LegalDocumentsBar::LicenseOfLibraries",
              expression: "'LegalDocumentsBar::LicenseOfLibraries'"
            }
          ],
          attrs: { depressed: "", color: "secondary", flat: "" },
          on: {
            click: function($event) {
              _vm.showLegalNoticeDialog = true
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("biz.help.legalnotice.title")))]
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: "LegalDocumentsBar::PrivacyPolicy",
              expression: "'LegalDocumentsBar::PrivacyPolicy'"
            }
          ],
          attrs: { depressed: "", color: "secondary", flat: "" },
          on: {
            click: function($event) {
              _vm.showPrivacyPolicyDialog = true
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("biz.help.privacypolicy")))]
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: "LegalDocumentsBar::TermsOfUse",
              expression: "'LegalDocumentsBar::TermsOfUse'"
            }
          ],
          attrs: { depressed: "", color: "secondary", flat: "" },
          on: {
            click: function($event) {
              _vm.showTermsOfUseDialog = true
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("biz.help.termsofuse")))]
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: "LegalDocumentsBar::LicenseConditions",
              expression: "'LegalDocumentsBar::LicenseConditions'"
            }
          ],
          attrs: { depressed: "", color: "secondary", flat: "" },
          on: {
            click: function($event) {
              _vm.showLicenseConditionsDialog = true
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("biz.help.licenseconditions")))]
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: "LegalDocumentsBar::LicenseOfLibraries",
              expression: "'LegalDocumentsBar::LicenseOfLibraries'"
            }
          ],
          attrs: { depressed: "", color: "secondary", flat: "" },
          on: {
            click: function($event) {
              _vm.showLicenseDialog = true
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("biz.help.softwarelicense.licensestitle")))]
      ),
      _c("v-spacer"),
      _vm.showTermsOfUseDialog
        ? _c("TermsOfUseDialog", {
            attrs: { dialogShown: _vm.showTermsOfUseDialog },
            on: {
              evtClose: function($event) {
                _vm.showTermsOfUseDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.showLicenseConditionsDialog
        ? _c("LicenseConditionsDialog", {
            attrs: { dialogShown: _vm.showLicenseConditionsDialog },
            on: {
              evtClose: function($event) {
                _vm.showLicenseConditionsDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.showPrivacyPolicyDialog
        ? _c("PrivacyPolicyDialog", {
            attrs: { dialogShown: _vm.showPrivacyPolicyDialog },
            on: {
              evtClose: function($event) {
                _vm.showPrivacyPolicyDialog = false
              }
            }
          })
        : _vm._e(),
      _c("LicensesDialog", {
        attrs: { dialogShown: _vm.showLicenseDialog },
        on: {
          evtClose: function($event) {
            _vm.showLicenseDialog = false
          }
        }
      }),
      _c("LegalNoticeDialog", {
        attrs: { dialogShown: _vm.showLegalNoticeDialog },
        on: {
          evtClose: function($event) {
            _vm.showLegalNoticeDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }