var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    { attrs: { bottom: "" } },
    [
      _c(
        "v-btn",
        {
          staticStyle: { "pointer-events": "none" },
          attrs: { slot: "activator", depressed: "", icon: "" },
          slot: "activator"
        },
        [
          _c(
            "v-badge",
            {
              staticClass: "mt-1",
              attrs: {
                color: "blue lighten-1",
                overlap: "",
                "v-model": _vm.messagesShown
              }
            },
            [
              _vm.unreadMessagesCount > 0 && _vm.showIcon
                ? _c(
                    "span",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { slot: "badge" },
                      slot: "badge"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$format.badgeNumber(_vm.unreadMessagesCount))
                      )
                    ]
                  )
                : _vm._e(),
              _c("v-icon", { class: _vm.getIconClass() }, [
                _vm._v("$vuetify.icons.bus_navigation_message")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("span", [_vm._v(_vm._s(_vm.$t("biz.general.tooltip.messages")))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }