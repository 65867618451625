var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      !_vm.selectedGateway || _vm.deleting
        ? _c(
            "v-layout",
            {
              staticStyle: { "font-size": "30px", color: "LightGray" },
              attrs: {
                slot: "content",
                column: "",
                xs12: "",
                "align-center": "",
                "justify-center": "",
                "fill-height": ""
              },
              slot: "content"
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.waiting")))
              ])
            ],
            1
          )
        : [
            _c(
              "v-layout",
              { attrs: { slot: "header" }, slot: "header" },
              [
                _c(
                  "v-flex",
                  {
                    staticStyle: { "align-self": "center" },
                    attrs: { xs4: "" }
                  },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: "Gateway::GoBack",
                                expression: "'Gateway::GoBack'"
                              }
                            ],
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { flat: "", icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.goBack()
                              }
                            }
                          },
                          [
                            _c(
                              "v-avatar",
                              { attrs: { color: "secondary", size: "40" } },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "white" } },
                                  [_vm._v("keyboard_arrow_left")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          {
                            staticClass: "px-2",
                            staticStyle: { "align-self": "center" }
                          },
                          [
                            _c("v-layout", { staticClass: "title" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("biz.groups.pagename"))
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-spacer")
              ],
              1
            ),
            _c("v-divider"),
            _vm.selectedGateway
              ? _c(
                  "v-container",
                  {
                    attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" }
                  },
                  [
                    _c("CriticalDevicesPage", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "content",
                            fn: function() {
                              return [
                                _c("ServiceDialog", {
                                  attrs: {
                                    dialogShown: _vm.showServiceDialog,
                                    selectedGateway: _vm.selectedGateway,
                                    track: "Gateway::ServiceRequest"
                                  },
                                  on: {
                                    "update:dialogShown": function($event) {
                                      _vm.showServiceDialog = $event
                                    },
                                    "update:dialog-shown": function($event) {
                                      _vm.showServiceDialog = $event
                                    },
                                    evtServiceDialogClose: function($event) {
                                      return _vm.onServiceDialogClose()
                                    }
                                  }
                                }),
                                _c(
                                  "v-layout",
                                  { attrs: { column: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { md12: "", lg10: "" } },
                                      [
                                        _c("GatewayBanner", {
                                          attrs: {
                                            gateway: _vm.selectedGateway
                                          }
                                        }),
                                        _c(
                                          "v-card",
                                          { attrs: { "fill-height": "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: { "fill-height": "" }
                                              },
                                              [
                                                _c(
                                                  "v-card-title",
                                                  { staticClass: "pb-0" },
                                                  [
                                                    _c(
                                                      "v-tabs",
                                                      {
                                                        attrs: {
                                                          slot: "extension",
                                                          color: "transparent"
                                                        },
                                                        slot: "extension"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tab",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "track",
                                                                rawName:
                                                                  "v-track",
                                                                value:
                                                                  "Gateway::GatewayAlarms",
                                                                expression:
                                                                  "'Gateway::GatewayAlarms'"
                                                              }
                                                            ],
                                                            attrs: {
                                                              to: {
                                                                name:
                                                                  "GatewayAlarms",
                                                                params: {
                                                                  serialNumber:
                                                                    _vm.serialNumber
                                                                }
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "biz.general.items.alarms"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tab",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "track",
                                                                rawName:
                                                                  "v-track",
                                                                value:
                                                                  "Gateway::GatewayProfile",
                                                                expression:
                                                                  "'Gateway::GatewayProfile'"
                                                              }
                                                            ],
                                                            attrs: {
                                                              to: {
                                                                name:
                                                                  "GatewayAlarmProfile",
                                                                params: {
                                                                  serialNumber:
                                                                    _vm.serialNumber
                                                                }
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "biz.dashboard.alarms.alarmsettings"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-tab",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "track",
                                                                rawName:
                                                                  "v-track",
                                                                value:
                                                                  "Gateway::GatewayInfo",
                                                                expression:
                                                                  "'Gateway::GatewayInfo'"
                                                              }
                                                            ],
                                                            attrs: {
                                                              to: {
                                                                name:
                                                                  "GatewayInfo",
                                                                params: {
                                                                  serialNumber:
                                                                    _vm.serialNumber
                                                                }
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.properties"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "track",
                                                        rawName: "v-track",
                                                        value:
                                                          "Gateway::SendServiceRequest",
                                                        expression:
                                                          "'Gateway::SendServiceRequest'"
                                                      }
                                                    ],
                                                    staticClass: "mt-3",
                                                    attrs: {
                                                      depressed: "",
                                                      outline: "",
                                                      color: "secondary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.showServiceDialog = true
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: { size: "20px" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.IconHelper.getServiceRequest2Icon()
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      { staticClass: "action" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "biz.help.servicedetails.title"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm.isAdminUser
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "track",
                                                            rawName: "v-track",
                                                            value:
                                                              "Gateway::DeleteSmartCoolingHub",
                                                            expression:
                                                              "'Gateway::DeleteSmartCoolingHub'"
                                                          }
                                                        ],
                                                        staticClass: "mt-3",
                                                        attrs: {
                                                          depressed: "",
                                                          outline: "",
                                                          color: "secondary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.checkDeleteGateway()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-1",
                                                            attrs: {
                                                              size: "20px"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "$vuetify.icons.bus_delete"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "action"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "biz.bsdbinfo.delete"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              {
                                                staticStyle: {
                                                  "max-height": "85%",
                                                  width: "100%"
                                                },
                                                attrs: {
                                                  "fill-height": "",
                                                  "ml-0": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-container",
                                                  {
                                                    staticStyle: {
                                                      "max-width": "none"
                                                    },
                                                    attrs: {
                                                      "fill-height": "",
                                                      "ma-0": ""
                                                    }
                                                  },
                                                  [_c("router-view")],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2607483072
                      )
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.showDeleteConfirmationDialog,
          title: _vm.deleteConfirmationTitle,
          message: _vm.deleteConfirmationMessage,
          track: "Gateway::DeleteConfirmationDialog"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.deleteGateway()
          },
          evtCancelPressed: function($event) {
            _vm.showDeleteConfirmationDialog = false
          }
        }
      }),
      _c("OkDialog", {
        attrs: {
          dialogShown: _vm.showDeleteBlockDialog,
          title: _vm.$t("biz.bsdbinfo.deletewithsensorstitle"),
          message: _vm.$t("biz.bsdbinfo.deletewithsensorsmessage"),
          track: "Gateway::DeleteFailedSensor"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.stayHere()
          }
        }
      }),
      _c("OkDialog", {
        attrs: {
          dialogShown: _vm.showDeletedDialog,
          title: _vm.$t("biz.bsdbinfo.deletedtitle"),
          message: _vm.$t("biz.bsdbinfo.deletedmessage"),
          track: "Gateway::DeleteSuccess"
        },
        on: { evtOkPressed: _vm.goToBsdbs }
      }),
      _c("ErrorWithHelpDialog", {
        attrs: {
          dialogShown: _vm.showDeleteError,
          title: _vm.$t("biz.onboarding.error.deletetitle"),
          message: _vm.$t("biz.bsdbinfo.deleteerrormessage"),
          smartCoolingHubId: _vm.selectedGateway.gatewayId,
          track: "DeleteFailedGateway"
        },
        on: {
          evtCancelPressed: function($event) {
            _vm.showDeleteError = false
          },
          evtRetryPressed: function($event) {
            return _vm.deleteGateway()
          }
        }
      }),
      _c("ServiceDialog", {
        attrs: {
          dialogShown: _vm.showServiceDialog,
          selectedGateway: _vm.selectedGateway,
          track: "Gateway::SendServiceRequest"
        },
        on: {
          evtServiceDialogClose: function($event) {
            _vm.showServiceDialog = false
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }