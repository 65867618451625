var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseWindowPage", {
    attrs: { trackCancel: _vm.track + "::Close" },
    on: {
      evtCancel: function($event) {
        _vm.cancelDialogShown = true
      }
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(_vm._s(_vm.sensorStatusTitle))]
        },
        proxy: true
      },
      {
        key: "subtitle",
        fn: function() {
          return undefined
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "v-container",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.readyFlag,
                    expression: "!readyFlag"
                  }
                ],
                staticStyle: { "min-height": "300px" },
                attrs: { "fill-height": "", fluid: "", "justify-center": "" }
              },
              [
                _c("v-progress-circular", {
                  attrs: { color: "secondary", indeterminate: "", size: "36" }
                }),
                _c(
                  "span",
                  { staticClass: "ml-3", staticStyle: { color: "primary" } },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("biz.sensors.setup.place.loadingsensor"))
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "v-container",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.readyFlag,
                    expression: "readyFlag"
                  }
                ]
              },
              [
                _c(
                  "v-flex",
                  {
                    staticClass: "mt-1",
                    staticStyle: { "min-height": "300px" }
                  },
                  [
                    _c("v-flex", { staticClass: "pb-2" }, [
                      _vm._v(_vm._s(_vm.$t("biz.sensors.setup.place.subtitle")))
                    ]),
                    _c(
                      "v-card",
                      { attrs: { color: "neutral", flat: "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "pa-3",
                                attrs: {
                                  xs1: "",
                                  "align-center": "",
                                  "justify-center": ""
                                }
                              },
                              [_c("v-icon", [_vm._v(_vm._s(_vm.sensorIcon))])],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs11: "",
                                  column: "",
                                  "ma-2": "",
                                  "align-start": ""
                                }
                              },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    staticStyle: {
                                      "font-size": "15px",
                                      "font-weight": "bold"
                                    },
                                    attrs: { "align-start": "" }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { row: "", nowrap: "" } },
                                      [
                                        _c("v-flex", { attrs: { xs4: "" } }, [
                                          _vm._v(_vm._s(_vm.sensorId))
                                        ]),
                                        _c("v-spacer"),
                                        _c(
                                          "v-layout",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              color: "primary"
                                            },
                                            attrs: { "justify-end": "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "biz.sensors.setup.place.signal_strength"
                                                  )
                                                ) +
                                                ":\n                    " +
                                                _vm._s(_vm.stateText) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      color: "bus_grey"
                                    },
                                    attrs: { "align-start": "" }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { row: "", nowrap: "" } },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "biz.sensors.setup.found.sensortype"
                                              )
                                            ) +
                                            ": " +
                                            _vm._s(_vm.sensorType) +
                                            "\n                  "
                                        ),
                                        _c("v-spacer"),
                                        _c("v-icon", [
                                          _vm._v(_vm._s(_vm.stateIcon))
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    !_vm.signalStrengthFine
                      ? _c(
                          "p",
                          {
                            staticClass: "bus_normaltext",
                            staticStyle: {
                              color: "bus_red",
                              "text-align": "left"
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "biz.sensors.setup.place.badsignaltext"
                                  )
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    !_vm.batteryLevelFine
                      ? _c(
                          "p",
                          {
                            staticClass: "bus_normaltext",
                            staticStyle: {
                              color: "bus_red",
                              "text-align": "left"
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "biz.sensors.setup.place.lowbatterytext"
                                  )
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.isTemperatureSensor()
                      ? _c(
                          "v-flex",
                          {
                            staticClass: "pt-2 pb-2",
                            attrs: { "offset-xs1": "" }
                          },
                          [
                            _c("v-img", {
                              staticClass: "mt-1",
                              attrs: {
                                "max-height": "30vh",
                                src: require("@/assets/temperatureSensor.png"),
                                contain: ""
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "v-flex",
                          {
                            staticClass: "pt-2 pb-2",
                            attrs: { "offset-xs1": "" }
                          },
                          [
                            _c("v-img", {
                              staticClass: "mt-1",
                              attrs: {
                                "max-height": "30vh",
                                src: require("@/assets/doorSensor.png"),
                                contain: ""
                              }
                            })
                          ],
                          1
                        )
                  ],
                  1
                ),
                _c("v-flex", {
                  staticClass: "font-weight-black pt-1 pb-2",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("biz.sensors.setup.place.sampling")
                    )
                  }
                }),
                _c("v-flex", {
                  staticClass: "bus_normaltext pb-4",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("biz.sensors.setup.place.samplingtext")
                    )
                  }
                }),
                _c("BasicSlider", {
                  attrs: {
                    sliderValue: _vm.samplingRate,
                    active: true,
                    minValue: _vm.sensorHelper.DEFAULT_SAMPLING_RATE_MINUTES,
                    maxValue: _vm.sensorHelper.MAX_SAMPLING_RATE_MINUTES,
                    step: 1
                  },
                  on: {
                    "update:sliderValue": function($event) {
                      _vm.samplingRate = $event
                    },
                    "update:slider-value": function($event) {
                      _vm.samplingRate = $event
                    }
                  }
                })
              ],
              1
            ),
            _c("SensorError", {
              attrs: {
                sensorId: _vm.sensor.sensorId,
                gatewaySerialNumber: _vm.gateway.serialNumber,
                dialogShown: _vm.errorShown,
                errorType: _vm.errorType
              },
              on: {
                evtAbort: function($event) {
                  return _vm.onAbort()
                }
              }
            }),
            _c("OkCancelDialog", {
              attrs: {
                dialogShown: _vm.cancelDialogShown,
                title: _vm.$t("biz.setup.abort.title"),
                message: _vm.$t("biz.setup.abort.description"),
                track: _vm.track + "::AbortSetup"
              },
              on: {
                evtOkPressed: function($event) {
                  return _vm.onAbort()
                },
                evtCancelPressed: function($event) {
                  _vm.cancelDialogShown = false
                }
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "helplink",
        fn: function() {
          return undefined
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }