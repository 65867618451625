var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "bottomcard", attrs: { "pa-0": "" } },
    [
      _vm.filteredSensors && _vm.filteredSensors.length > 0
        ? _c(
            "v-layout",
            {
              staticStyle: { margin: "-8px 0px 0px 0px" },
              attrs: { row: "", nowrap: "" }
            },
            _vm._l(_vm.filteredSensors, function(item) {
              return _c(
                "v-flex",
                {
                  key: item.sensorId,
                  attrs: { "px-1": "", "pt-2": "", grow: "" }
                },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-card",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        class: _vm.getSensorColor(item),
                                        staticStyle: { height: "42px" },
                                        attrs: { flat: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.toSensorAlarmList(item)
                                          }
                                        }
                                      },
                                      "v-card",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-card-text",
                                      {
                                        staticClass: "justify-center",
                                        staticStyle: {
                                          padding: "8px 8px 8px 0px"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { row: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                style: _vm.width,
                                                attrs: { shrink: "" }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getSensorIcon(item)
                                                    )
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm.filteredSensors.length === 1
                                              ? _c(
                                                  "v-flex",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "shorttext",
                                                        rawName: "v-shorttext",
                                                        value: 200,
                                                        expression: "200"
                                                      }
                                                    ],
                                                    style: _vm.getTextColor(
                                                      item
                                                    )
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getThingTitle(
                                                          item.name
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("v-spacer"),
                                            _c(
                                              "v-flex",
                                              {
                                                style: _vm.getValueColor(item),
                                                attrs: { shrink: "" }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getSensorValue(item)
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_c("span", [_vm._v(_vm._s(item.name))])]
                  )
                ],
                1
              )
            }),
            1
          )
        : _c(
            "v-layout",
            {
              staticStyle: { margin: "-8px 0px 0px 0px" },
              attrs: { row: "", wrap: "" }
            },
            [
              _c(
                "v-flex",
                { attrs: { "px-1": "", "pt-2": "", grow: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "nosensors",
                      staticStyle: { height: "42px" },
                      attrs: { flat: "" }
                    },
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass: "justify-center font-weight-regular",
                          staticStyle: { padding: "10px" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$tc(
                                "biz.sensors.sensors",
                                _vm.measureSiteSensors.length
                              )
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }