var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "", row: "", wrap: "" } },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.dataReady,
              expression: "!dataReady"
            }
          ],
          staticStyle: { height: "65vh" },
          attrs: { column: "", "align-center": "", "justify-center": "" }
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "secondary", size: "48" }
          }),
          _c("p", { staticClass: "bus_normaltext mt-5" }, [
            _vm._v(_vm._s(_vm.$t("general.actions.loading")))
          ])
        ],
        1
      ),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dataReady,
              expression: "dataReady"
            }
          ],
          attrs: { sm12: "", lg6: "", "pr-0": "" }
        },
        [
          _c(
            "v-container",
            { attrs: { "pr-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _vm.isModbusAppliance ||
                      _vm.isBinderAppliance ||
                      _vm.isSmartModuleAppliance
                        ? _c("Properties", {
                            attrs: {
                              editableData: _vm.applianceInfo,
                              licensed: _vm.isApplianceLicensed(
                                _vm.selectedApplianceZone.appliance
                              ),
                              track: "ApplianceInfo::ModbusProperties"
                            },
                            on: { evtSave: _vm.saveApplianceInfo }
                          })
                        : _vm._e(),
                      _vm.hasNetworkData
                        ? _c("Network", {
                            staticStyle: { "margin-top": "52px" },
                            attrs: { applianceInfo: _vm.applianceInfo }
                          })
                        : _vm._e(),
                      _vm.isRoom
                        ? _c("RoomProperties", {
                            attrs: {
                              editableData: _vm.applianceInfo,
                              licensed: _vm.isApplianceLicensed(
                                _vm.selectedApplianceZone.appliance
                              ),
                              track: "ApplianceInfo::RoomProperties"
                            },
                            on: { evtSave: _vm.saveApplianceInfo }
                          })
                        : _vm._e(),
                      _vm.isNotConnectable
                        ? _c("NotConnectableProperties", {
                            attrs: {
                              editableData: _vm.applianceInfo,
                              licensed: _vm.isApplianceLicensed(
                                _vm.selectedApplianceZone.appliance
                              ),
                              track: "ApplianceInfo::NotConnectableProperties"
                            },
                            on: { evtSave: _vm.saveApplianceInfo }
                          })
                        : _vm._e(),
                      _vm.isThirdParty
                        ? _c("ThirdPartyProperties", {
                            attrs: {
                              editableData: _vm.applianceInfo,
                              licensed: _vm.isApplianceLicensed(
                                _vm.selectedApplianceZone.appliance
                              ),
                              track: "ApplianceInfo::ThirdPartyProperties"
                            },
                            on: { evtSave: _vm.saveApplianceInfo }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticStyle: { "margin-top": "52px" } },
                    [
                      _c("Address", {
                        attrs: {
                          editableData: _vm.applianceInfo,
                          licensed: _vm.isApplianceLicensed(
                            _vm.selectedApplianceZone.appliance
                          ),
                          track: "ApplianceInfo::Address"
                        },
                        on: {
                          evtSave: _vm.saveApplianceInfo,
                          evtUpdateAddress: _vm.updateAddress
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticStyle: { "margin-top": "52px" },
                      attrs: { "pb-4": "" }
                    },
                    [
                      _c("Location", {
                        attrs: {
                          editableData: _vm.applianceInfo,
                          licensed: _vm.isApplianceLicensed(
                            _vm.selectedApplianceZone.appliance
                          ),
                          track: "ApplianceInfo::Location"
                        },
                        on: { evtSave: _vm.saveApplianceInfo }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dataReady,
              expression: "dataReady"
            }
          ],
          attrs: { sm12: "", lg6: "" }
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _vm.isModbusAppliance ||
                  _vm.isNotConnectable ||
                  _vm.isBinderAppliance
                    ? _c(
                        "v-flex",
                        [
                          _c("EditableDataManualsWrapper", {
                            attrs: {
                              editableData: _vm.applianceInfo,
                              track: "ApplianceInfo::Manuals"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.isSmartModuleAppliance && _vm.isSmartModuleOnBoardedByWiFi
                ? _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "bus_propertytitle" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("biz.wifireset.wificonnection"))
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "ApplianceInfo::WiFiReset",
                                  expression: "'ApplianceInfo::WiFiReset'"
                                }
                              ],
                              staticStyle: { "margin-left": "0px" },
                              attrs: {
                                depressed: "",
                                outline: "",
                                color: "secondary"
                              },
                              on: { click: _vm.smartModuleWiFiReset }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("biz.wifireset.resetconnection"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }