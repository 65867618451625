// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DoorSensorAlarmPoint, Point } from './';

/**
 * @export
 * @interface DoorSensorDataPointsObject
 */
export interface DoorSensorDataPointsObject {
  /**
   * @type {Array<Point>}
   * @memberof DoorSensorDataPointsObject
   */
  alarmCount?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof DoorSensorDataPointsObject
   */
  doorClosed?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof DoorSensorDataPointsObject
   */
  doorOpenSince?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof DoorSensorDataPointsObject
   */
  doorOpened?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof DoorSensorDataPointsObject
   */
  doorOpening?: Array<Point>;
  /**
   * @type {Array<DoorSensorAlarmPoint>}
   * @memberof DoorSensorDataPointsObject
   */
  doorSensorAlarms?: Array<DoorSensorAlarmPoint>;
  /**
   * @type {Array<Point>}
   * @memberof DoorSensorDataPointsObject
   */
  setDelay?: Array<Point>;
  /**
   * @type {string}
   * @memberof DoorSensorDataPointsObject
   */
  thingId?: string;
}
