// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface OrganizationImageResponse
 */
export interface OrganizationImageResponse {
  /**
   * @type {string}
   * @memberof OrganizationImageResponse
   */
  fileExtension?: string;
  /**
   * @type {string}
   * @memberof OrganizationImageResponse
   */
  fileFormat?: string;
  /**
   * @type {string}
   * @memberof OrganizationImageResponse
   */
  imageBase64Encoded?: string;
  /**
   * @type {string}
   * @memberof OrganizationImageResponse
   */
  uri?: string;
}
