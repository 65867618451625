import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import {
  ApplianceResponse,
  ApplianceResponseMeasureSiteTypeEnum,
  ApplianceResponseNetworkTypeEnum
} from '@/api/models';

@Component
export default class Network extends Vue {
  /**
   * Text that is shown when no data available
   *
   * @private
   * @type {string}
   * @memberof Network
   */
  private readonly SHOW_BLANK: string = '-';

  /**
   * Extended gateway state.
   *
   * @private
   * @type {GatewayState}
   * @memberof Network
   */
  @Prop({ default: null })
  private applianceInfo: ApplianceResponse;

  /**
   * Computed property for the connection type, either WLAN or LAN ... probably.
   *
   * @readonly
   * @type {string}
   * @memberof Network
   */
  get connectionType(): string {
    switch (this.applianceInfo?.networkType) {
      case ApplianceResponseNetworkTypeEnum.WIFI:
      case ApplianceResponseNetworkTypeEnum.WLAN:
        return String(this.$t('general.wlan'));
      case ApplianceResponseNetworkTypeEnum.LAN:
        return String(this.$t('general.lan'));
      case ApplianceResponseNetworkTypeEnum.UNKNOWN:
      default:
        return this.SHOW_BLANK;
    }
  }

  /**
   * Computed property for the IP address.
   *
   * @readonly
   * @type {string}
   * @memberof Network
   */
  get ipAddress(): string {
    if (this.applianceInfo?.ipAddress) {
      return this.applianceInfo.ipAddress;
    }

    return this.SHOW_BLANK;
  }

  /**
   * Computed property for the MAC address.
   *
   * @readonly
   * @type {string}
   * @memberof Network
   */
  get macAddress(): string {
    if (this.applianceInfo?.measureSiteType === ApplianceResponseMeasureSiteTypeEnum.SMARTMODULEFRIDGE) {
      return String(this.$t('general.checkmacaddress'));
    } else {
      if (this.applianceInfo?.macAddress) {
        return this.applianceInfo.macAddress;
      }
    }

    return this.SHOW_BLANK;
  }
}
