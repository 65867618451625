var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseWindowPage", {
    attrs: { trackCancel: _vm.track + "::Close" },
    on: {
      evtCancel: function($event) {
        _vm.cancelDialogShown = true
      }
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(_vm._s(_vm.$t("biz.sensors.setup.found.title")))]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "v-container",
              {
                staticStyle: { "min-height": "300px" },
                attrs: { "fill-height": "", "justify-center": "", fluid: "" }
              },
              [
                _c("v-progress-circular", {
                  attrs: { color: "secondary", indeterminate: "", size: "36" }
                }),
                _c(
                  "span",
                  { staticClass: "ml-3", staticStyle: { color: "primary" } },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("biz.sensors.setup.pair_step.searchsensor"))
                    )
                  ]
                )
              ],
              1
            ),
            _c("SensorError", {
              attrs: {
                gatewaySerialNumber: _vm.selectedGatewaySerial,
                sensorId: _vm.errorSensorId,
                dialogShown: _vm.errorShown,
                errorType: _vm.errorType
              },
              on: {
                evtAbort: function($event) {
                  return _vm.onAbort()
                },
                evtRetry: function($event) {
                  return _vm.onRetry()
                }
              }
            }),
            _c("OkCancelDialog", {
              attrs: {
                dialogShown: _vm.cancelDialogShown,
                title: _vm.$t("biz.setup.abort.title"),
                message: _vm.$t("biz.setup.abort.description"),
                track: _vm.track + "::AbortSetup"
              },
              on: {
                evtOkPressed: function($event) {
                  return _vm.onAbort()
                },
                evtCancelPressed: function($event) {
                  _vm.cancelDialogShown = false
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }