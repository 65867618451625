var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: _vm.track + "::Close",
          dialogShown: _vm.cancelDialogShown
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "biz.onboarding.binderscan.checknetworkaddress.title"
                    )
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t(
                        "biz.onboarding.binderscan.checknetworkaddress.text"
                      )
                    )
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-img",
                  {
                    staticClass: "bus_img_center",
                    attrs: {
                      "max-height": "440px",
                      contain: "",
                      src: require("@/assets/binder_appliance.png")
                    }
                  },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          slot: "placeholder",
                          "fill-height": "",
                          "align-center": "",
                          "justify-center": "",
                          "ma-0": ""
                        },
                        slot: "placeholder"
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "grey lighten-5" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "helplink",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: _vm.track + "::HelpUserManual",
                            expression: "track + '::HelpUserManual'"
                          }
                        ],
                        attrs: {
                          depressed: "",
                          color: "secondary",
                          outline: ""
                        },
                        on: {
                          click: function($event) {
                            _vm.showManualsDialog = !_vm.showManualsDialog
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: { color: "secondary" }
                          },
                          [_vm._v("help_outline")]
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("biz.onboarding.manuals.helpertitle")
                            ) +
                            "\n        "
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("ApplianceManualsDialog", {
        attrs: {
          binderSetup: true,
          dialogShown: _vm.showManualsDialog,
          track: _vm.track + "::ApplianceManualsDialog"
        },
        on: {
          evtClosed: function($event) {
            _vm.showManualsDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }