var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "90px",
        height: "90px",
        viewBox: "0 0 90 90",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            transform: "matrix(3.75, 0, 0, 3.75, 16.875, 0)"
          }
        },
        [
          _c(
            "g",
            {
              staticStyle: { fill: "var(--v-neutral40-base)" },
              attrs: { transform: "translate(-23.000000, -334.000000)" }
            },
            [
              _c(
                "g",
                { attrs: { transform: "translate(0.000000, 316.000000)" } },
                [
                  _c(
                    "g",
                    { attrs: { transform: "translate(16.000000, 16.000000)" } },
                    [
                      _c(
                        "g",
                        {
                          attrs: { transform: "translate(2.000000, 2.000000)" }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M8,20 L17,20 C17.5522847,20 18,19.5522847 18,19 L18,9 L7,9 L7,19 C7,19.5522847 7.44771525,20 8,20 Z M18.9901492,21.2448845 C18.8655498,22.7872018 17.574407,24 16,24 L9,24 C7.425593,24 6.13445021,22.7872018 6.0098508,21.2448845 C5.39038643,20.6953083 5,19.893244 5,19 L5,3 C5,1.34314575 6.34314575,3.04359188e-16 8,0 L17,0 C18.6568542,-3.04359188e-16 20,1.34314575 20,3 L20,19 C20,19.893244 19.6096136,20.6953083 18.9901492,21.2448845 L18.9901492,21.2448845 Z M7,7 L18,7 L18,3 C18,2.44771525 17.5522847,2 17,2 L8,2 C7.44771525,2 7,2.44771525 7,3 L7,7 Z M8,4 C8,3.44771525 8.44771525,3 9,3 C9.55228475,3 10,3.44771525 10,4 L10,5 C10,5.55228475 9.55228475,6 9,6 C8.44771525,6 8,5.55228475 8,5 L8,4 Z M8,11 C8,10.4477153 8.44771525,10 9,10 C9.55228475,10 10,10.4477153 10,11 L10,18 C10,18.5522847 9.55228475,19 9,19 C8.44771525,19 8,18.5522847 8,18 L8,11 Z"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }