var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("g", [
            _c("g", { staticStyle: { fill: "var(--v-bus_yellow-base)" } }, [
              _c("circle", { attrs: { cx: "16", cy: "16", r: "16" } })
            ]),
            _c(
              "g",
              {
                staticStyle: { fill: "var(--v-bus_white-base)" },
                attrs: { transform: "scale(0.5,0.5)" }
              },
              [
                _c(
                  "g",
                  { attrs: { transform: "translate(10.000000, 10.000000)" } },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M32,4 C33.0479005,4 33.9175777,4.79713488 33.9944742,5.82182602 L34,5.96969697 L34,13.6060606 C34,14.1583454 33.5522847,14.6060606 33,14.6060606 C32.4871642,14.6060606 32.0644928,14.2200204 32.0067277,13.7226817 L32,13.6060606 L32,6 L10,6 L10,15 L20,15 C20.5522847,15 21,15.4477153 21,16 C21,16.5128358 20.6139598,16.9355072 20.1166211,16.9932723 L20,17 L10,17 L10,36 L32,36 L32,32.0909091 C32,31.5780733 32.3860402,31.1554019 32.8833789,31.0976368 L33,31.0909091 C33.5128358,31.0909091 33.9355072,31.4769493 33.9932723,31.974288 L34,32.0909091 L34,36.030303 C34,36.7698922 33.5882963,37.4090985 32.9827081,37.746045 C32.9944883,37.8279676 33,37.9133401 33,38 L33,38 L33,39 C33,40.1045695 32.1045695,41 31,41 L31,41 L11,41 C9.8954305,41 9,40.1045695 9,39 L9,39 L9,38 C9,37.913684 9.005468,37.8286451 9.0160765,37.7452109 C8.45236442,37.4316428 8.05627746,36.8544701 8.00552579,36.178174 L8,36.030303 L8,5.96969697 C8,4.92462537 8.82204535,4.07998723 9.85157869,4.00536118 L10,4 L32,4 Z M31,38 L11,38 L11,39 L31,39 L31,38 Z M12.5,19.5 C13.0128358,19.5 13.4355072,19.8860402 13.4932723,20.3833789 L13.5,20.5 L13.5,33 C13.5,33.5522847 13.0522847,34 12.5,34 C11.9871642,34 11.5644928,33.6139598 11.5067277,33.1166211 L11.5,33 L11.5,20.5 C11.5,19.9477153 11.9477153,19.5 12.5,19.5 Z M12.5,8 C13.0128358,8 13.4355072,8.38604019 13.4932723,8.88337887 L13.5,9 L13.5,12 C13.5,12.5522847 13.0522847,13 12.5,13 C11.9871642,13 11.5644928,12.6139598 11.5067277,12.1166211 L11.5,12 L11.5,9 C11.5,8.44771525 11.9477153,8 12.5,8 Z",
                        "fill-rule": "nonzero"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M45,16 L45,29 L22.25,29 L22.25,25.75 L19,25.75 L19,19.25 L22.25,19.25 L22.25,16 L45,16 Z M23.875,17.625 L23.875,27.375 L36.875,27.375 L36.875,17.625 L23.875,17.625 Z"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }