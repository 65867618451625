var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "80px",
        height: "80px",
        viewBox: "0 0 80 80",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            { attrs: { transform: "translate(-951.000000, -481.000000)" } },
            [
              _c(
                "g",
                { attrs: { transform: "translate(951.000000, 481.000000)" } },
                [
                  _c("path", {
                    staticStyle: { fill: "var(--v-secondary-base)" },
                    attrs: {
                      d:
                        "M40,35.0588235 C41.3807119,35.0588235 42.6307119,35.6118835 43.5355339,36.5060606 L40,40 L36.4644661,36.5060606 C37.3692881,35.6118835 38.6192881,35.0588235 40,35.0588235 Z M40,30.1176471 C42.7614237,30.1176471 45.2614237,31.2237671 47.0710678,33.0121212 L45.303803,34.7595872 C43.9465303,33.4180299 42.0713232,32.5882353 40,32.5882353 C37.9289322,32.5882353 36.0539322,33.4178253 34.6966991,34.7590909 L32.9299324,33.0111329 C34.7394974,31.2233596 37.239085,30.1176471 40,30.1176471 Z M40,25.1764706 C44.1426438,25.1764706 47.8930558,26.8360578 50.6076007,29.5191692 L48.8388348,31.2651515 C46.5767797,29.0297089 43.4517797,27.6470588 40,27.6470588 C36.5484741,27.6470588 33.4236798,29.0295056 31.1616642,31.2646585 L29.3933983,29.5181818 C32.1078644,26.8356506 35.8578644,25.1764706 40,25.1764706 Z M40,20.2352941 C45.5228475,20.2352941 50.5228475,22.4475342 54.1421356,26.0242424 L52.3753683,27.7722001 C49.2084122,24.6419998 44.833,22.7058824 40,22.7058824 C35.1675084,22.7058824 30.7925084,24.6415924 27.6256313,27.7712121 L25.8578644,26.0242424 C29.4771525,22.4475342 34.4771525,20.2352941 40,20.2352941 Z"
                    }
                  }),
                  _c("rect", {
                    staticStyle: { fill: "var(--v-bus_white-base)" },
                    attrs: {
                      stroke: "var(--v-secondary-base)",
                      "stroke-width": "2",
                      x: "4.5",
                      y: "46.1553447",
                      width: "72.25",
                      height: "16.897977",
                      rx: "2"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      stroke: "var(--v-secondary-base)",
                      "stroke-width": "2",
                      x: "9.48214286",
                      y: "50.9599151",
                      width: "62.2857143",
                      height: "12.0934066",
                      rx: "2"
                    }
                  }),
                  _c("line", {
                    attrs: {
                      x1: "9.44642857",
                      y1: "51.0809815",
                      x2: "4.14285714",
                      y2: "45.795954",
                      stroke: "var(--v-secondary-base)"
                    }
                  }),
                  _c("line", {
                    attrs: {
                      x1: "71.8035714",
                      y1: "50.7606768",
                      x2: "77.1071429",
                      y2: "45.795954",
                      stroke: "var(--v-secondary-base)"
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }