var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "secondarymeasuresitebanner",
      class: _vm.color,
      on: {
        click: function($event) {
          return _vm.toMeasureSite()
        }
      }
    },
    [
      _c("v-icon", { staticClass: "icon", style: _vm.iconStyle }, [
        _vm._v(_vm._s(_vm.icon))
      ]),
      _c("p", { staticClass: "header" }, [
        _vm._v(_vm._s(_vm.applianceZone.name))
      ]),
      _c("p", { staticClass: "status" }, [_vm._v(_vm._s(_vm.state))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }