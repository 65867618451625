var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      !_vm.selectedAppliance || _vm.deleting
        ? _c(
            "v-layout",
            {
              staticStyle: { "font-size": "30px", color: "LightGray" },
              attrs: {
                slot: "content",
                column: "",
                xs12: "",
                "align-center": "",
                "justify-center": "",
                "fill-height": ""
              },
              slot: "content"
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.waiting")))
              ])
            ],
            1
          )
        : [
            _c(
              "v-layout",
              { attrs: { slot: "header" }, slot: "header" },
              [
                _c(
                  "v-flex",
                  {
                    staticStyle: { "align-self": "center" },
                    attrs: { xs4: "" }
                  },
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: "Appliance::GoBack",
                                expression: "'Appliance::GoBack'"
                              }
                            ],
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { flat: "", icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.goBack()
                              }
                            }
                          },
                          [
                            _c(
                              "v-avatar",
                              { attrs: { color: "secondary", size: "40" } },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "white" } },
                                  [_vm._v("keyboard_arrow_left")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          {
                            staticClass: "px-2",
                            staticStyle: { "align-self": "center" }
                          },
                          [
                            _c("v-layout", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.$t("biz.groups.pagename")))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-divider"),
            _c(
              "v-container",
              { attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" } },
              [
                _c("CriticalDevicesPage", {
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _c(
                            "v-layout",
                            { attrs: { column: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", lg10: "" } },
                                [
                                  _vm.gateway
                                    ? _c("SecondaryGatewayBanner", {
                                        attrs: { gateway: _vm.gateway }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { grow: "" } },
                                        [
                                          _vm.isSmartModuleAppliance
                                            ? _c("SmartModuleBanner", {
                                                attrs: {
                                                  appliance:
                                                    _vm.selectedAppliance
                                                }
                                              })
                                            : _c("MeasureSiteBanner", {
                                                attrs: {
                                                  applianceZone: _vm.primaryZone
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm.hasTwoZones
                                        ? _c(
                                            "v-flex",
                                            {
                                              staticClass: "hidden-sm-and-down",
                                              attrs: { shrink: "" }
                                            },
                                            [
                                              _c("SecondZoneBanner", {
                                                attrs: {
                                                  isPrimary: true,
                                                  applianceZone:
                                                    _vm.secondaryZone
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm.measureSiteSensors.length > 0
                                    ? _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        _vm._l(_vm.measureSiteSensors, function(
                                          sensor
                                        ) {
                                          return _c(
                                            "v-flex",
                                            {
                                              key: sensor.sensorId,
                                              staticStyle: { width: "0" },
                                              attrs: { grow: "", "pt-0": "" }
                                            },
                                            [
                                              _vm.hasCompanySensors
                                                ? _c("SecondarySensorBanner", {
                                                    attrs: {
                                                      id: sensor.sensorId,
                                                      sensor: sensor,
                                                      flag: "APPLIANCE"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-card",
                                    { attrs: { "fill-height": "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "header",
                                          staticStyle: { width: "100%" },
                                          attrs: { "fill-height": "" }
                                        },
                                        [
                                          _c(
                                            "v-card-title",
                                            { staticClass: "pb-0" },
                                            [
                                              _c(
                                                "v-tabs",
                                                {
                                                  attrs: {
                                                    slot: "extension",
                                                    color: "transparent"
                                                  },
                                                  slot: "extension"
                                                },
                                                [
                                                  !_vm.isFoodLicenseUser
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "v-tabs__div"
                                                        },
                                                        [
                                                          _vm.licensed
                                                            ? _c(
                                                                "v-tab",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "track",
                                                                      rawName:
                                                                        "v-track",
                                                                      value:
                                                                        "Appliance::ApplianceHistory",
                                                                      expression:
                                                                        "'Appliance::ApplianceHistory'"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    to: {
                                                                      name:
                                                                        "ApplianceHistory"
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "biz.main.history"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _c(
                                                                "v-tab",
                                                                {
                                                                  attrs: {
                                                                    disabled: true
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "biz.main.history"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.licensed &&
                                                  !_vm.isTempAdminUser
                                                    ? _c(
                                                        "v-tab",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "track",
                                                              rawName:
                                                                "v-track",
                                                              value:
                                                                "Appliance::ApplianceReports",
                                                              expression:
                                                                "'Appliance::ApplianceReports'"
                                                            }
                                                          ],
                                                          attrs: {
                                                            to: {
                                                              name:
                                                                "ApplianceReports"
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "biz.report.reports"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-tab",
                                                        {
                                                          attrs: {
                                                            disabled: true
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "biz.report.reports"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                  _vm.licensed
                                                    ? _c(
                                                        "v-tab",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "track",
                                                              rawName:
                                                                "v-track",
                                                              value:
                                                                "Appliance::ApplianceAlarms",
                                                              expression:
                                                                "'Appliance::ApplianceAlarms'"
                                                            }
                                                          ],
                                                          attrs: {
                                                            to: {
                                                              name:
                                                                "ApplianceAlarms"
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "biz.general.items.alarms"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-tab",
                                                        {
                                                          attrs: {
                                                            disabled: true
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "biz.general.items.alarms"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                  _vm.licensed
                                                    ? [
                                                        _vm.hasAlarmProfile
                                                          ? _c(
                                                              "v-tab",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "track",
                                                                    rawName:
                                                                      "v-track",
                                                                    value:
                                                                      "Appliance::ApplianceAlarmProfile",
                                                                    expression:
                                                                      "'Appliance::ApplianceAlarmProfile'"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  to: {
                                                                    name:
                                                                      "ApplianceAlarmProfile"
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "biz.dashboard.alarms.alarmsettings"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _c(
                                                              "v-tab",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "track",
                                                                    rawName:
                                                                      "v-track",
                                                                    value:
                                                                      "Appliance::MeasureSiteAlarmProfile",
                                                                    expression:
                                                                      "'Appliance::MeasureSiteAlarmProfile'"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  to: {
                                                                    name:
                                                                      "MeasureSiteAlarmProfile"
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "biz.dashboard.alarms.alarmsettings"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                      ]
                                                    : _c(
                                                        "v-tab",
                                                        {
                                                          attrs: {
                                                            disabled: true
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "biz.dashboard.alarms.alarmsettings"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                  _c(
                                                    "v-tab",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "track",
                                                          rawName: "v-track",
                                                          value:
                                                            "Appliance::ApplianceInfo",
                                                          expression:
                                                            "'Appliance::ApplianceInfo'"
                                                        }
                                                      ],
                                                      attrs: {
                                                        to: {
                                                          name: "ApplianceInfo"
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "general.properties"
                                                          )
                                                        ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-tab",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "track",
                                                          rawName: "v-track",
                                                          value:
                                                            "Appliance::LicenseInfo",
                                                          expression:
                                                            "'Appliance::LicenseInfo'"
                                                        }
                                                      ],
                                                      attrs: {
                                                        to: {
                                                          name: "LicenseInfo"
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "biz.help.softwarelicense.licenseinfo"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-spacer"),
                                          _vm.TypeChecker.isLiebherrAppliance(
                                            _vm.primaryZone
                                          )
                                            ? _c(
                                                "v-btn",
                                                {
                                                  directives: [
                                                    {
                                                      name: "track",
                                                      rawName: "v-track",
                                                      value:
                                                        "Appliance::SendServiceRequest",
                                                      expression:
                                                        "'Appliance::SendServiceRequest'"
                                                    }
                                                  ],
                                                  staticClass: "mt-3",
                                                  attrs: {
                                                    depressed: "",
                                                    outline: "",
                                                    color: "secondary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.showServiceDialog = true
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { size: "20px" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.IconHelper.getServiceRequest2Icon()
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "action" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "biz.help.servicedetails.title"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isAdminUser
                                            ? _c(
                                                "v-btn",
                                                {
                                                  directives: [
                                                    {
                                                      name: "track",
                                                      rawName: "v-track",
                                                      value:
                                                        "Appliance::DeleteMeasureSite",
                                                      expression:
                                                        "'Appliance::DeleteMeasureSite'"
                                                    }
                                                  ],
                                                  staticClass: "mt-3",
                                                  attrs: {
                                                    depressed: "",
                                                    outline: "",
                                                    color: "secondary",
                                                    disabled: _vm.isDeleteDisabled()
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.checkDeleteMeasureSite()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: { size: "20px" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "$vuetify.icons.bus_delete"
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "action" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "biz.applianceinfo.delete"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        {
                                          staticStyle: {
                                            "max-height": "85%",
                                            width: "100%"
                                          },
                                          attrs: {
                                            "fill-height": "",
                                            "ml-0": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              staticStyle: {
                                                "max-width": "none"
                                              },
                                              attrs: {
                                                "fill-height": "",
                                                "ma-0": ""
                                              }
                                            },
                                            [
                                              _c("router-view", {
                                                attrs: {
                                                  selectedAppliance:
                                                    _vm.selectedAppliance,
                                                  selectedApplianceZone:
                                                    _vm.primaryZone,
                                                  alarmTime: _vm.alarmTime,
                                                  virtualFridgeId:
                                                    _vm.virtualFridgeId,
                                                  extraPadding: true
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ],
      _c("OkDialog", {
        attrs: {
          title: _vm.$t("biz.applianceinfo.deletewarningtitle"),
          message: _vm.$t("biz.applianceinfo.deletewarningmessage"),
          dialogShown: _vm.showDeleteWarningDialog,
          track: "Appliance::DeleteErrorSensor"
        },
        on: {
          evtOkPressed: function($event) {
            _vm.showDeleteWarningDialog = false
          }
        }
      }),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.showDeleteConfirmationDialog,
          title: _vm.$t("biz.onboarding.scan.deletetitle"),
          message: _vm.$t("biz.onboarding.scan.deletetext"),
          track: "Appliance::AskDelete"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.deleteMeasureSite()
          },
          evtCancelPressed: function($event) {
            _vm.showDeleteConfirmationDialog = false
          }
        }
      }),
      _c("OkDialog", {
        attrs: {
          dialogShown: _vm.showDeletedDialog,
          title: _vm.$t("biz.applianceinfo.deletedtitle"),
          message: _vm.$t("biz.applianceinfo.deletedmessage"),
          track: "Appliance::Deleted"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.goToMeasureSites()
          }
        }
      }),
      _c("ErrorWithHelpDialog", {
        attrs: {
          dialogShown: _vm.showDeleteError,
          title: _vm.$t("biz.onboarding.error.deletetitle"),
          message: _vm.$t("biz.onboarding.error.deletemessage"),
          serialNumber: _vm.errorMeasureSiteId,
          track: "Appliance::DeleteErrorAppliance"
        },
        on: {
          evtCancelPressed: function($event) {
            _vm.showDeleteError = false
          },
          evtRetryPressed: function($event) {
            return _vm.deleteMeasureSite()
          }
        }
      }),
      _vm.TypeChecker.isLiebherrAppliance(_vm.primaryZone)
        ? _c("ServiceDialog", {
            attrs: {
              dialogShown: _vm.showServiceDialog,
              selectedApplianceZone: _vm.primaryZone,
              track: "MeasureSiteBanner::ApplianceServiceRequest"
            },
            on: {
              evtServiceDialogClose: function($event) {
                _vm.showServiceDialog = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }