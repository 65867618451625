// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AutomatedReportSettingsResponse
 */
export interface AutomatedReportSettingsResponse {
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsResponse
   */
  createdBy?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsResponse
   */
  frequency?: AutomatedReportSettingsResponseFrequencyEnum;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsResponse
   */
  internalOrganizationId?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsResponse
   */
  language?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsResponse
   */
  name?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsResponse
   */
  profileId?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsResponse
   */
  recipientEmail?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsResponse
   */
  reportVariant?: AutomatedReportSettingsResponseReportVariantEnum;
  /**
   * @type {Date}
   * @memberof AutomatedReportSettingsResponse
   */
  startDate?: Date;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsResponse
   */
  timezone?: string;
  /**
   * @type {Array<string>}
   * @memberof AutomatedReportSettingsResponse
   */
  virtualFridgeIds?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum AutomatedReportSettingsResponseFrequencyEnum {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly'
}
/**
 * @export
 * @enum {string}
 */
export enum AutomatedReportSettingsResponseReportVariantEnum {
  HACCP = 'HACCP',
  TEMPCONFORMITY = 'TEMPCONFORMITY'
}
