var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            transform: "matrix(0.911381, 0, 0, 0.911381, -0.404833, 0.050832)"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-secondary-base)" },
            attrs: {
              d:
                "M16,31 C16,32.1045695 16.8954305,33 18,33 C19.1045695,33 20,32.1045695 20,31 L16,31 Z M18,2 C17.1991713,2 16.4881195,2.47513622 16.171521,3.18617923 L16.1135509,3.33146238 L15.7989953,4.21941864 L14.9140706,4.54240453 C11.7561898,5.69498953 9.48298582,8.53383383 9.06847715,11.8829245 L9.03675124,12.1797267 L9,12.579 L9,20 C9,23.2331267 7.70205466,26.1840851 5.19400925,28.7955845 L4.993,29 L31.006,29 L30.8059409,28.7955696 C28.3908171,26.2807945 27.0978027,23.4512423 27.0053368,20.3580713 L27,20 L27,12.59 L26.9632488,12.1797267 C26.6589126,8.80158293 24.4672412,5.89937889 21.3627141,4.64962775 L21.0864411,4.5436739 L20.2009363,4.22072097 L19.8863738,3.33220098 C19.6061237,2.5406005 18.8542629,2 18,2 Z M18,35 C15.790861,35 14,33.209139 14,31 L2.99996364,31.0000366 C2.44766879,31.0000366 1.99994536,30.5523131 1.99994536,30.0000183 L1.99994536,29.9337302 C1.99994536,29.3574232 2.24852166,28.8091263 2.68200589,28.4293616 C5.56066863,25.9074375 7,23.0976503 7,20 L7,12 L7.04481833,12.000274 C7.43328616,7.6882647 10.3101072,4.09373883 14.228344,2.6636339 C14.7779505,1.11215481 16.2590964,-2.13162821e-14 18,-2.13162821e-14 C19.7409036,-2.13162821e-14 21.2220495,1.11215481 21.7717095,2.66473611 C25.6898928,4.09373883 28.5667138,7.6882647 28.9551817,12.000274 L29,12 L29,20 C29,23.0976472 30.4393127,25.9074318 33.3179381,28.4293539 C33.7514083,28.8091166 34,29.3574026 34,29.9336977 L34,30.0000183 C34,30.5523131 33.5522766,31.0000366 32.9999817,31.0000366 L22,31 C22,33.209139 20.209139,35 18,35 Z",
              "fill-rule": "nonzero"
            }
          }),
          _c("g", { attrs: { transform: "translate(14.000000, 31.000000)" } })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }