var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "v-flex",
            { staticStyle: { "align-self": "center" }, attrs: { xs2: "" } },
            [
              _c("v-layout", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.groups.pagename")))
              ])
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticStyle: { "align-self": "center" },
              attrs: { xs4: "", md5: "", lg6: "" }
            },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    slot: "extension",
                    color: "transparent",
                    centered: ""
                  },
                  slot: "extension",
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "Groups::MeasureSites",
                          expression: "'Groups::MeasureSites'"
                        }
                      ],
                      key: 0,
                      on: {
                        click: function($event) {
                          return _vm.showCustomerGroups()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.groups.measuresites")))]
                  ),
                  _c(
                    "v-tab",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "Groups::SmartCoolingHubs",
                          expression: "'Groups::SmartCoolingHubs'"
                        }
                      ],
                      key: 1,
                      on: {
                        click: function($event) {
                          return _vm.showStructure()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.group.structure")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.numberOfAppliances > 0
        ? _c(
            "v-flex",
            { staticStyle: { "align-self": "center" }, attrs: { shrink: "" } },
            [
              _c(
                "v-layout",
                { attrs: { "justify-end": "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticStyle: { "align-self": "center" },
                      attrs: { "pb-2": "", "pr-4": "" }
                    },
                    [
                      _vm.activeTab === 0
                        ? _c("v-text-field", {
                            staticClass: "default selected",
                            attrs: {
                              "background-color": "neutral",
                              clearable: _vm.iconHelper.clearableIcon(
                                _vm.searchString
                              ),
                              placeholder: _vm.$t("general.search") + "..."
                            },
                            scopedSlots: _vm._u(
                              [
                                !_vm.iconHelper.clearableIcon(_vm.searchString)
                                  ? {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-icon",
                                            { staticClass: "searchicon" },
                                            [_vm._v("search")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  : null
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.searchString,
                              callback: function($$v) {
                                _vm.searchString = $$v
                              },
                              expression: "searchString"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { "fill-height": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", "fill-height": "" } },
            [_c("router-view", { attrs: { searchString: _vm.searchString } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }