// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApplianceZone } from './';

/**
 * @export
 * @interface Appliance
 */
export interface Appliance {
  /**
   * @type {string}
   * @memberof Appliance
   */
  applianceType: ApplianceApplianceTypeEnum;
  /**
   * @type {string}
   * @memberof Appliance
   */
  gatewayId?: string;
  /**
   * @type {boolean}
   * @memberof Appliance
   */
  lcvAppliance?: boolean;
  /**
   * @type {string}
   * @memberof Appliance
   */
  model?: string;
  /**
   * @type {boolean}
   * @memberof Appliance
   */
  multiZone?: boolean;
  /**
   * @type {string}
   * @memberof Appliance
   */
  name?: string;
  /**
   * @type {string}
   * @memberof Appliance
   */
  onboardingState: ApplianceOnboardingStateEnum;
  /**
   * @type {string}
   * @memberof Appliance
   */
  serialNumber?: string;
  /**
   * @type {boolean}
   * @memberof Appliance
   */
  smartModuleAppliance?: boolean;
  /**
   * @type {Array<string>}
   * @memberof Appliance
   */
  thingIds?: Array<string>;
  /**
   * @type {string}
   * @memberof Appliance
   */
  timezone?: string;
  /**
   * @type {Array<string>}
   * @memberof Appliance
   */
  virtualFridgeIds?: Array<string>;
  /**
   * @type {Array<ApplianceZone>}
   * @memberof Appliance
   */
  zones: Array<ApplianceZone>;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplianceApplianceTypeEnum {
  ROOM = 'ROOM',
  MODBUSFRIDGE = 'MODBUS_FRIDGE',
  NOTCONNECTABLEFRIDGE = 'NOT_CONNECTABLE_FRIDGE',
  THIRDPARTYFRIDGE = 'THIRD_PARTY_FRIDGE',
  BINDERFRIDGE = 'BINDER_FRIDGE',
  SMARTMODULEFRIDGE = 'SMART_MODULE_FRIDGE'
}
/**
 * @export
 * @enum {string}
 */
export enum ApplianceOnboardingStateEnum {
  VOID = 'VOID',
  AWAITONBOARDING = 'AWAIT_ONBOARDING',
  ONBOARDINGSTARTED = 'ONBOARDING_STARTED',
  ONBOARDED = 'ONBOARDED'
}
