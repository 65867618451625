var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: _vm.track + "::Close",
          dialogShown: _vm.cancelDialogShown,
          readyFlag: _vm.readyFlag
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.readyFlag
              ? {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm.$t("biz.onboarding.connectappliance.title")
                        ) + _vm._s(_vm.selectedGatewayName)
                      )
                    ]
                  },
                  proxy: true
                }
              : null,
            _vm.readyFlag
              ? {
                  key: "subtitle",
                  fn: function() {
                    return [
                      _c("div", {
                        staticClass: "font-weight-black",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "biz.onboarding.connectappliance.description"
                            )
                          )
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "content",
              fn: function() {
                return [
                  _c(
                    "v-container",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.readyFlag,
                          expression: "!readyFlag"
                        }
                      ],
                      staticStyle: { "min-height": "400px" },
                      attrs: {
                        "fill-height": "",
                        fluid: "",
                        "justify-center": ""
                      }
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          color: "secondary",
                          indeterminate: "",
                          size: "36"
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "ml-3",
                          staticStyle: { color: "primary" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "biz.onboarding.binderscan.findBinder.searchbinder"
                              )
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.readyFlag,
                          expression: "readyFlag"
                        }
                      ],
                      staticClass: "mt-1",
                      staticStyle: { "min-height": "400px" }
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "margin-bottom": "0px",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("general.ipaddress"))
                                      )
                                    ]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "default selected",
                                    attrs: {
                                      clearable: _vm.iconHelper.clearableIcon(
                                        _vm.ipAddress
                                      ),
                                      placeholder: _vm.$t("general.ipaddress"),
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.ipAddress,
                                      callback: function($$v) {
                                        _vm.ipAddress = $$v
                                      },
                                      expression: "ipAddress"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "margin-bottom": "0px",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("general.mac")))]
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "default selected ml-1",
                                    attrs: {
                                      clearable: _vm.iconHelper.clearableIcon(
                                        _vm.macAddress
                                      ),
                                      placeholder: _vm.$t("general.mac"),
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.macAddress,
                                      callback: function($$v) {
                                        _vm.macAddress = $$v
                                      },
                                      expression: "macAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("ApplianceError", {
                    attrs: {
                      ipAddress: _vm.ipAddress,
                      gatewaySerialNumber: _vm.gateway.serialNumber,
                      serialNumber: _vm.serialNumber,
                      usedVirtualFridgeId: _vm.usedVirtualFridgeId,
                      dialogShown: _vm.errorShown,
                      errorType: _vm.errorType
                    },
                    on: {
                      evtHideErrorDialog: function($event) {
                        return _vm.closeErrorDialog()
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }