var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tileType === _vm.TileTypeEnum.GATEWAY
    ? _c("v-flex", [_c("GatewayItem", { attrs: { gateway: _vm.gateway } })], 1)
    : _vm.tileType === _vm.TileTypeEnum.MEASURE_SITE && _vm.hasTwoZones
    ? _c(
        "v-flex",
        [
          !_vm.hasSensors
            ? _c("DeviceTwoZoneItem", {
                attrs: { mainMeasureSite: _vm.measureSite }
              })
            : _c("MeasureSiteTwoZoneItem", {
                attrs: { mainMeasureSite: _vm.measureSite }
              })
        ],
        1
      )
    : _vm.tileType === _vm.TileTypeEnum.MEASURE_SITE && _vm.isSmartModule
    ? _c(
        "v-flex",
        [
          _c("SmartModuleItem", {
            attrs: { hasSensors: _vm.hasSensors, measureSite: _vm.measureSite }
          })
        ],
        1
      )
    : _c(
        "v-flex",
        [
          !_vm.hasSensors
            ? _c("DeviceItem", {
                attrs: { applianceZone: _vm.measureSite.zones[0] }
              })
            : _c("MeasureSiteItem", { attrs: { measureSite: _vm.measureSite } })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }