var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "", column: "" } },
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "" } },
        [_vm._t("content")],
        2
      ),
      _c(
        "v-footer",
        { attrs: { height: "60" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                "ml-5": "",
                "mr-5": "",
                "align-center": "",
                "fill-height": ""
              }
            },
            [_vm._t("footer")],
            2
          )
        ],
        1
      ),
      _vm._t("dialogs")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }