var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.dataReady && !_vm.loadingMoreAlarms,
              expression: "!dataReady && !loadingMoreAlarms"
            }
          ],
          staticStyle: { height: "65vh" },
          attrs: { column: "", "align-center": "", "justify-center": "" }
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "secondary", size: "48" }
          }),
          _c("p", { staticClass: "bus_normaltext mt-5" }, [
            _vm._v(_vm._s(_vm.$t("general.actions.loading")))
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.dataReady && _vm.loadingMoreAlarms,
              expression: "!dataReady && loadingMoreAlarms"
            }
          ],
          staticStyle: { height: "65vh" },
          attrs: { column: "", "align-center": "", "justify-center": "" }
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "secondary", size: "48" }
          }),
          _c("p", { staticClass: "bus_normaltext mt-5" }, [
            _vm._v(_vm._s(_vm.$t("general.actions.loadingmorealarms")))
          ])
        ],
        1
      ),
      _c("AlarmMenu", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dataReady,
            expression: "dataReady"
          }
        ],
        attrs: {
          csvDownloadEnabled: _vm.selectedAlarms.length > 0,
          showDeviceFilter: _vm.showDeviceFilter,
          selectedApplianceZone: _vm.selectedApplianceZone,
          initialTimezoneString: _vm.initialTimezoneString,
          startPeriod: _vm.startPeriod
        },
        on: {
          evtGenerateAlarmCsv: _vm.generateAlarmCsv,
          evtFilterDevices: _vm.onFilterDevices,
          evtFilterState: _vm.onFilterState,
          evtFilterDate: _vm.onFilterDate
        }
      }),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dataReady,
              expression: "dataReady"
            }
          ],
          attrs: { "pl-3": "", "py-0": "" }
        },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track + "::ShowAlarmHelpDialog",
                  expression: "track + '::ShowAlarmHelpDialog'"
                }
              ],
              attrs: { depressed: "", outline: "", color: "secondary" },
              on: {
                click: function($event) {
                  _vm.alarmHelpDialogVisible = true
                }
              }
            },
            [
              _c("v-icon", [_vm._v("help_outline")]),
              _vm._v(
                "\n       \n      " +
                  _vm._s(_vm.$t("biz.dashboard.alarms.showalarmtypes")) +
                  "\n    "
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dataReady,
              expression: "dataReady"
            }
          ],
          staticStyle: { "max-width": "none" },
          attrs: { "pt-1": "" }
        },
        [
          _c("v-data-table", {
            staticClass: "paddingless",
            attrs: {
              headers: _vm.headers,
              items: _vm.alarms,
              pagination: _vm.alarmPagination,
              "select-all": "",
              "hide-actions": "",
              "item-key": "alarmId"
            },
            on: {
              "update:pagination": function($event) {
                _vm.alarmPagination = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "headers",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      [
                        _c(
                          "td",
                          [
                            _c(
                              "v-flex",
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    "input-value": props.all,
                                    indeterminate: props.indeterminate,
                                    color: "secondary",
                                    "hide-details": ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.allAlarmsSelected()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._l(props.headers, function(header) {
                          return _c(
                            "td",
                            {
                              key: header.text,
                              staticStyle: { color: "bus_grey" }
                            },
                            [_vm._v(_vm._s(header.text))]
                          )
                        })
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      {
                        staticStyle: { border: "none" },
                        attrs: {
                          active: _vm.getHighlightedRow(
                            props.selected,
                            props.item
                          )
                        },
                        on: {
                          click: function($event) {
                            props.selected = !props.selected
                          }
                        }
                      },
                      [
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "5%" }
                          },
                          [
                            _c(
                              "v-flex",
                              [
                                _vm.notDiscardedAlarm(props.item)
                                  ? _c("v-checkbox", {
                                      attrs: {
                                        "input-value": props.selected,
                                        color: "secondary",
                                        "hide-details": ""
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "5%" }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                class: _vm.getIconStyling(props.item),
                                attrs: { large: "" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.getStateAlarmIcon(props.item))
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "15%" }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.getPrefixDiscardedAlarm(props.item)
                                ) +
                                  " " +
                                  _vm._s(_vm.getStateAlarmText(props.item))
                              )
                            ])
                          ]
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c("div", [
                                            _c(
                                              "a",
                                              _vm._g(
                                                {
                                                  staticStyle: {
                                                    color:
                                                      "var(--v-secondary-base) !important",
                                                    "text-decoration": "none"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.routeToDevice(
                                                        props.item
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      props.item.deviceName
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "a",
                                              _vm._g(
                                                {
                                                  staticStyle: {
                                                    color:
                                                      "var(--v-secondary-base) !important",
                                                    "text-decoration": "none"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.routeToMeasureSite(
                                                        props.item
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.getMeasureSiteName(
                                                        props.item
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "biz.dashboard.alarms.showdevicedetails"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "10%" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getApplianceSetTemperature(props.item)
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "10%" }
                          },
                          [_vm._v(_vm._s(_vm.getMinMaxAlarmText(props.item)))]
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "15%" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$format.dateTimeShort(
                                    props.item.alarmTime
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "15%" }
                          },
                          [_vm._v(_vm._s(_vm.getAlarmEndText(props.item)))]
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "10%" }
                          },
                          [
                            _vm.notDiscardedAlarm(props.item)
                              ? _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "track",
                                                                rawName:
                                                                  "v-track",
                                                                value:
                                                                  _vm.track +
                                                                  "::ShowAlarmDescription",
                                                                expression:
                                                                  "track + '::ShowAlarmDescription'"
                                                              }
                                                            ],
                                                            staticClass: "mx-0",
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.showDescriptionDialog(
                                                                  props.item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                size: "20",
                                                                color:
                                                                  "secondary"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "help_outline"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("biz.alarms.help")
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              props.item.readstate === true,
                                            expression:
                                              "props.item.readstate === true"
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "track",
                                                                rawName:
                                                                  "v-track",
                                                                value:
                                                                  _vm.track +
                                                                  "::ResetReadState",
                                                                expression:
                                                                  "track + '::ResetReadState'"
                                                              }
                                                            ],
                                                            staticClass: "mx-0",
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.changeReadState(
                                                                  props.item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                size: "20"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "$vuetify.icons.bus_alarm"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "biz.messages.markasunread"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              props.item.readstate !== true,
                                            expression:
                                              "props.item.readstate !== true"
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "track",
                                                                rawName:
                                                                  "v-track",
                                                                value:
                                                                  _vm.track +
                                                                  "::SetReadState",
                                                                expression:
                                                                  "track + '::SetReadState'"
                                                              }
                                                            ],
                                                            staticClass: "mx-0",
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.changeReadState(
                                                                  props.item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                size: "20"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "$vuetify.icons.bus_alarm_new"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "biz.messages.markasread"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-badge",
                                      {
                                        staticClass: "comments-badge",
                                        staticStyle: {
                                          "font-weight": "normal"
                                        },
                                        attrs: {
                                          overlap: "",
                                          color: "secondary"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "badge",
                                              fn: function() {
                                                return [
                                                  props.item.commentCount > 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$format.smallBadgeNumber(
                                                              _vm.getCommentCount(
                                                                props.item
                                                                  .commentCount,
                                                                props.item
                                                                  .alarmId
                                                              )
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            directives: [
                                                              {
                                                                name: "track",
                                                                rawName:
                                                                  "v-track",
                                                                value:
                                                                  _vm.track +
                                                                  "::ShowAlarmComments",
                                                                expression:
                                                                  "track + '::ShowAlarmComments'"
                                                              }
                                                            ],
                                                            staticClass: "mx-0",
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.showAlarmComments(
                                                                  props.item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                size: "20"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "$vuetify.icons.bus_comment"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("biz.alarms.comments")
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c("v-layout", [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          directives: [
                                                            {
                                                              name: "track",
                                                              rawName:
                                                                "v-track",
                                                              value:
                                                                _vm.track +
                                                                "::ShowAlarmDescription",
                                                              expression:
                                                                "track + '::ShowAlarmDescription'"
                                                            }
                                                          ],
                                                          staticClass: "mx-0",
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.showDescriptionDialog(
                                                                props.item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              size: "20",
                                                              color: "secondary"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "help_outline"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("biz.alarms.help"))
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                          ],
                          1
                        )
                      ]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedAlarms,
              callback: function($$v) {
                _vm.selectedAlarms = $$v
              },
              expression: "selectedAlarms"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dataReady,
              expression: "dataReady"
            }
          ],
          staticClass: "text-xs-center mb-4"
        },
        [
          _vm.pages > 1
            ? _c("v-pagination", {
                attrs: { length: _vm.pages },
                model: {
                  value: _vm.alarmPagination.page,
                  callback: function($$v) {
                    _vm.$set(_vm.alarmPagination, "page", $$v)
                  },
                  expression: "alarmPagination.page"
                }
              })
            : _vm._e(),
          _c("v-spacer"),
          _vm.showMore && _vm.onLastPage
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::LoadMoreAlarm",
                      expression: "track + '::LoadMoreAlarm'"
                    }
                  ],
                  attrs: { color: "primary", depressed: "" },
                  on: {
                    click: function($event) {
                      return _vm.loadMoreAlarms()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("biz.alarms.morealarms")))]
              )
            : _vm._e()
        ],
        1
      ),
      _c("CommentDialog", {
        attrs: {
          dialogShown: _vm.commentDialogVisible,
          comments: _vm.alarmComments,
          selectedAlarm: _vm.selectedAlarm,
          track: _vm.track + "::CommentDialog"
        },
        on: {
          evtClose: function($event) {
            return _vm.onClosePressed()
          },
          evtAdd: function($event) {
            return _vm.onCommentAddPressed()
          }
        }
      }),
      _c("NewCommentDialog", {
        attrs: {
          dialogAddShown: _vm.newCommentDialogVisible,
          selectedAlarm: _vm.selectedAlarm,
          track: _vm.track + "::NewComment"
        },
        on: {
          evtClose: function($event) {
            return _vm.onCancelPressed()
          },
          evtSave: _vm.onCommentSaveConfirm,
          evtErrorDialog: function($event) {
            return _vm.showErrorDialog()
          }
        }
      }),
      _c("AlarmDescriptionDialog", {
        attrs: {
          dialogShown: _vm.alarmDescriptionDialogVisible,
          alarm: _vm.alarm,
          track: _vm.track + "::AlarmDescriptionDialog"
        },
        on: {
          evtClose: function($event) {
            return _vm.onDescriptionDialogClose()
          }
        }
      }),
      _c("AlarmHelpDialog", {
        attrs: {
          track: _vm.track + "::AlarmHelpDialog",
          dialogShown: _vm.alarmHelpDialogVisible
        },
        on: {
          evtClose: function($event) {
            _vm.alarmHelpDialogVisible = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }