var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "700", persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSingleInstallationVideo,
              expression: "showSingleInstallationVideo"
            }
          ]
        },
        [
          _c("BaseWindowItem", {
            attrs: { trackCancel: "track + '::Close'" },
            on: { evtCancel: _vm.close },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _vm._v(
                      _vm._s(_vm.$t("biz.onboarding.tutorials.tutorialtitle"))
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "description",
                fn: function() {
                  return [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.singleInstallation.videoTitle))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.singleInstallation.videoDescription))
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "video",
                      {
                        staticClass: "my-2",
                        attrs: {
                          controls: "",
                          preload: "none",
                          poster: _vm.singleInstallation.preview,
                          id: "videoSingle"
                        }
                      },
                      [
                        _c("source", {
                          attrs: {
                            src: _vm.singleInstallation.link,
                            type: "video/mp4"
                          }
                        })
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMultipleInstallationVideo,
              expression: "showMultipleInstallationVideo"
            }
          ]
        },
        [
          _c("BaseWindowItem", {
            attrs: { trackCancel: "track + '::Close'" },
            on: { evtCancel: _vm.close },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _vm._v(
                      _vm._s(_vm.$t("biz.onboarding.tutorials.tutorialtitle"))
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "description",
                fn: function() {
                  return [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.multipleInstallation.videoTitle))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.multipleInstallation.videoDescription))
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "video",
                      {
                        staticClass: "my-2",
                        attrs: {
                          controls: "",
                          preload: "none",
                          poster: _vm.multipleInstallation.preview,
                          id: "videoMultiple"
                        }
                      },
                      [
                        _c("source", {
                          attrs: {
                            src: _vm.multipleInstallation.link,
                            type: "video/mp4"
                          }
                        })
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }