import { render, staticRenderFns } from "./ThirdPartyProperties.vue?vue&type=template&id=1c4b7cba&"
import script from "./ThirdPartyProperties.ts?vue&type=script&lang=ts&"
export * from "./ThirdPartyProperties.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/hau-bus-ui_master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c4b7cba')) {
      api.createRecord('1c4b7cba', component.options)
    } else {
      api.reload('1c4b7cba', component.options)
    }
    module.hot.accept("./ThirdPartyProperties.vue?vue&type=template&id=1c4b7cba&", function () {
      api.rerender('1c4b7cba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/applianceinfo/properties/ThirdPartyProperties.vue"
export default component.exports