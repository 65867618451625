var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    [
      _c(
        "v-flex",
        { staticClass: "ma-3" },
        [
          _c(
            "v-menu",
            {
              attrs: {
                "nudge-right": 40,
                transition: "scale-transition",
                "offset-y": "",
                disabled: _vm.periodDisabled
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-text-field",
                        _vm._g(
                          {
                            staticClass: "default selected",
                            attrs: {
                              value: _vm.formattedFromDate,
                              placeholder: _vm.$t(
                                "biz.dashboard.alarms.period.fromdate"
                              ),
                              "append-icon": _vm.fromIcon,
                              clearable: _vm.iconHelper.clearableIcon(
                                _vm.fromDateIsoString
                              ),
                              disabled: _vm.periodDisabled,
                              readonly: ""
                            },
                            on: {
                              "click:clear": function($event) {
                                return _vm.clearFromDate()
                              }
                            }
                          },
                          on
                        )
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("v-date-picker", {
                attrs: {
                  "no-title": "",
                  min: _vm.defaultStartPeriod,
                  max: _vm.endPeriod,
                  locale: _vm.$i18n.locale
                },
                on: {
                  input: function($event) {
                    return _vm.onDateChange()
                  }
                },
                model: {
                  value: _vm.fromDateIsoString,
                  callback: function($$v) {
                    _vm.fromDateIsoString = $$v
                  },
                  expression: "fromDateIsoString"
                }
              })
            ],
            1
          ),
          _c(
            "v-menu",
            {
              attrs: {
                "nudge-right": 40,
                transition: "scale-transition",
                "offset-y": "",
                disabled: _vm.periodDisabled
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-text-field",
                        _vm._g(
                          {
                            staticClass: "default selected",
                            attrs: {
                              value: _vm.formattedToDate,
                              placeholder: _vm.$t(
                                "biz.dashboard.alarms.period.todate"
                              ),
                              "append-icon": _vm.toIcon,
                              clearable: _vm.iconHelper.clearableIcon(
                                _vm.toDateIsoString
                              ),
                              disabled: _vm.periodDisabled,
                              readonly: ""
                            },
                            on: {
                              "click:clear": function($event) {
                                return _vm.clearToDate()
                              }
                            }
                          },
                          on
                        )
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("v-date-picker", {
                attrs: {
                  "no-title": "",
                  min: _vm.startPeriod,
                  max: _vm.maxDayIsoString,
                  locale: _vm.$i18n.locale
                },
                on: {
                  input: function($event) {
                    return _vm.onDateChange()
                  }
                },
                model: {
                  value: _vm.toDateIsoString,
                  callback: function($$v) {
                    _vm.toDateIsoString = $$v
                  },
                  expression: "toDateIsoString"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }