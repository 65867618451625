var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%" } },
    [
      _vm.dataIsMissing
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "65vh", width: "100%", margin: "0px" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("DataMissingDisplay", {
                attrs: { interestedIn: _vm.pageRouteHelper.ALARM_OVERVIEW },
                on: {
                  evtAlarmListData: function($event) {
                    _vm.dataIsMissing = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.dataIsMissing
        ? _c(
            "v-card",
            { staticClass: "px-2" },
            [
              _c("MobileAlarmList", {
                attrs: {
                  track: "MobileAlarmOverview::MobileAlarmList",
                  selectedAppliance: _vm.selectedAppliance,
                  selectedGateway: _vm.selectedGateway,
                  selectedSensor: _vm.selectedSensor,
                  singleDeviceSelected: _vm.singleDeviceSelected
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }