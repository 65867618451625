var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", [
        _c(
          "g",
          { attrs: { transform: "matrix(0.888889, 0, 0, 0.888889, 0, 0)" } },
          [
            _c("path", {
              staticStyle: { fill: "var(--v-secondary-base)" },
              attrs: {
                d:
                  "M 18 0 C 27.941 0 36 8.059 36 18 C 36 27.941 27.941 36 18 36 C 8.059 36 0 27.941 0 18 C 0 8.059 8.059 0 18 0 Z M 18 2 C 9.163 2 2 9.163 2 18 C 2 26.837 9.163 34 18 34 C 26.837 34 34 26.837 34 18 C 34 9.163 26.837 2 18 2 Z",
                id: "Oval",
                "fill-rule": "nonzero",
                stroke: "none",
                "stroke-width": "1"
              }
            }),
            _c(
              "g",
              {
                staticStyle: { stroke: "var(--v-bus_white-base)" },
                attrs: {
                  transform: "matrix(1, 0, 0, 1, 14, 8.5)",
                  "stroke-width": "2",
                  fill: "none",
                  "fill-rule": "evenodd"
                }
              },
              [
                _c("path", {
                  staticStyle: { stroke: "var(--v-secondary-base)" },
                  attrs: {
                    d:
                      "M0,4.06451613 C0,-1.35483871 9,-1.35483871 9,4.06451613 C9,8.45893104 3.6,7 3.6,13",
                    id: "Path-48",
                    "stroke-linecap": "round"
                  }
                }),
                _c("rect", {
                  staticStyle: {
                    stroke: "var(--v-secondary-base)",
                    fill: "var(--v-bus_white-base)"
                  },
                  attrs: {
                    x: "3",
                    y: "18.5",
                    width: "1",
                    height: "1",
                    rx: "0.5"
                  }
                })
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }