var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "div",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "secondarysensorbanner",
                      class: _vm.color,
                      on: {
                        click: function($event) {
                          return _vm.toSensor()
                        }
                      }
                    },
                    "div",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { staticClass: "icon mt-2" }, [
                    _vm._v(_vm._s(_vm.icon))
                  ]),
                  _c(
                    "v-flex",
                    {
                      staticClass: "header",
                      attrs: { "pa-0": "", "hidden-sm-and-down": "" }
                    },
                    [_vm._v(_vm._s(_vm.getSensorName()))]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "subheader",
                      attrs: { "pa-0": "", "hidden-sm-and-down": "" }
                    },
                    [_vm._v(_vm._s(_vm.getSensorId()))]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "status pt-2",
                      staticStyle: { "font-size": "15px" }
                    },
                    [_vm._v(_vm._s(_vm.sensorState))]
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [_c("span", [_vm._v(_vm._s(_vm.getSensorName()))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }