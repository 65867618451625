var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "", persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "helpdialog" },
        [
          _c(
            "v-layout",
            { attrs: { "mb-3": "", row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { "ma-3": "" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "display-2",
                      staticStyle: { color: "white", opacity: "0.6" }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.help.alarmtypes.title")))]
                  )
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::Close",
                      expression: "track + '::Close'"
                    }
                  ],
                  staticClass: "closebutton",
                  attrs: { depressed: "", flat: "", large: "" },
                  on: { click: _vm.close }
                },
                [_vm._v(_vm._s(_vm.$t("general.close")))]
              )
            ],
            1
          ),
          _c(
            "v-container",
            { attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    "fill-height": "",
                    row: "",
                    wrap: "",
                    "justify-start": "",
                    "align-start": ""
                  }
                },
                _vm._l(_vm.orderedAlarmTypes, function(alarmType) {
                  return _c(
                    "v-flex",
                    {
                      key: alarmType.index,
                      attrs: { xs12: "", md6: "", lg4: "", xl3: "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { "hidden-sm-and-down": "" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-4",
                              attrs: {
                                height: "32vh",
                                "min-height": "230px",
                                "min-width": "400px"
                              }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "justify-start": "",
                                    "align-start": "",
                                    row: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "", shrink: "" } },
                                    [
                                      _c("v-icon", { attrs: { size: "50" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getAlarmIcon(
                                              alarmType.definition
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-flex", { attrs: { xs10: "" } }, [
                                    _c("h3", { staticClass: "mb-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAlarmHeader(
                                            alarmType.definition
                                          )
                                        )
                                      )
                                    ]),
                                    _c("span", {
                                      staticClass: "bus_normaltext",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getAlarmDescription(
                                            alarmType.definition
                                          )
                                        )
                                      }
                                    })
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { "hidden-md-and-up": "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "pa-4", attrs: { width: "100%" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "justify-start": "",
                                    "align-start": "",
                                    row: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs2: "", shrink: "" } },
                                    [
                                      _c("v-icon", { attrs: { size: "50" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getAlarmIcon(
                                              alarmType.definition
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-flex", { attrs: { xs10: "" } }, [
                                    _c("h3", { staticClass: "mb-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAlarmHeader(
                                            alarmType.definition
                                          )
                                        )
                                      )
                                    ]),
                                    _c("span", {
                                      staticClass: "bus_normaltext",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getAlarmDescription(
                                            alarmType.definition
                                          )
                                        )
                                      }
                                    })
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }