var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "v-layout",
        { attrs: { column: "", "align-center": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "text-xs-center", attrs: { xs10: "" } },
            [
              _c("v-progress-linear", {
                attrs: {
                  "buffer-value": 100,
                  height: 10,
                  value: _vm.progressValue,
                  color: "white"
                }
              }),
              _c("p", {
                staticClass: "ma-3",
                staticStyle: { color: "white", "text-align": "center" },
                domProps: {
                  innerHTML: _vm._s(_vm.$t("biz.onboarding.start.establishing"))
                }
              })
            ],
            1
          ),
          _c("ErrorWithHelpDialog", {
            attrs: {
              dialogShown: _vm.errorShown,
              title: _vm.errorTitle,
              message: _vm.errorMessage,
              showGoToSmartMonitoring: !_vm.showRetry,
              smartCoolingHubId: _vm.gatewayId,
              track: "OnboardingLanding::OnboardingError" + _vm.error
            },
            on: {
              evtCancelPressed: _vm.cancelPressed,
              evtRetryPressed: _vm.retryPressed
            }
          })
        ],
        1
      )
    : _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "" } },
        [
          _vm.hasOutdatedSoftware(_vm.gatewayId)
            ? _c(
                "v-layout",
                { attrs: { column: "", "fill-height": "" } },
                [
                  _c("BaseWindowPage", {
                    attrs: {
                      finishStep: true,
                      trackCancel: "OnboardingLanding::Close"
                    },
                    on: {
                      evtCancel: function($event) {
                        return _vm.confirmAbort()
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(_vm.$t("biz.onboarding.start.title"))
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "finish",
                          fn: function() {
                            return [
                              _c("div", { staticClass: "finishstep-image" }, [
                                _c(
                                  "div",
                                  { staticClass: "finishstep-subtitle" },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticStyle: { "margin-bottom": "14px" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.onboarding.start.outdatedsoftware"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-flex", {
                                      staticStyle: {
                                        "padding-bottom": "200px"
                                      },
                                      attrs: { xs10: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "biz.onboarding.start.outdatedsoftwaredesc"
                                          )
                                        )
                                      }
                                    }),
                                    _c(
                                      "v-flex",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            directives: [
                                              {
                                                name: "track",
                                                rawName: "v-track",
                                                value:
                                                  "OnboardingLanding::OutdatedSoftwareServiceRequest",
                                                expression:
                                                  "'OnboardingLanding::OutdatedSoftwareServiceRequest'"
                                              }
                                            ],
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              depressed: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openServiceDialog()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "biz.help.servicedetails.start"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            directives: [
                                              {
                                                name: "track",
                                                rawName: "v-track",
                                                value:
                                                  "OnboardingLanding::UpdateOutdatedSoftware",
                                                expression:
                                                  "'OnboardingLanding::UpdateOutdatedSoftware'"
                                              }
                                            ],
                                            attrs: {
                                              depressed: "",
                                              color: "secondary",
                                              outline: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openSoftwareUpdateDialog()
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "pr-1",
                                                attrs: { color: "secondary" }
                                              },
                                              [_vm._v(_vm._s(_vm.helpIcon))]
                                            ),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "biz.onboarding.start.updatesoftware"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1766057128
                    )
                  })
                ],
                1
              )
            : _c(
                "v-layout",
                { attrs: { column: "", "fill-height": "" } },
                [
                  _c("BaseWindowPage", {
                    attrs: {
                      finishStep: true,
                      trackCancel: "OnboardingLanding::Close"
                    },
                    on: {
                      evtCancel: function($event) {
                        return _vm.confirmAbort()
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _vm._v(_vm._s(_vm.$t("biz.onboarding.start.title")))
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "finish",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "finishstep-image" }, [
                              _c(
                                "div",
                                { staticClass: "finishstep-subtitle" },
                                [
                                  _c("v-flex", {
                                    staticStyle: { "padding-bottom": "200px" },
                                    attrs: { xs10: "" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("biz.onboarding.start.text")
                                      )
                                    }
                                  }),
                                  _c(
                                    "v-flex",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          directives: [
                                            {
                                              name: "track",
                                              rawName: "v-track",
                                              value:
                                                "OnboardingLanding::ConfigureAppliances",
                                              expression:
                                                "'OnboardingLanding::ConfigureAppliances'"
                                            }
                                          ],
                                          staticStyle: {
                                            "margin-left": "25px",
                                            width: "200px"
                                          },
                                          attrs: {
                                            depressed: "",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.configureAppliances()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "biz.onboarding.start.configappliances"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    [
                                      _vm.hasSensors()
                                        ? _c(
                                            "v-btn",
                                            {
                                              directives: [
                                                {
                                                  name: "track",
                                                  rawName: "v-track",
                                                  value:
                                                    "OnboardingLanding::ConfigureSensors",
                                                  expression:
                                                    "'OnboardingLanding::ConfigureSensors'"
                                                }
                                              ],
                                              staticStyle: {
                                                "margin-left": "25px"
                                              },
                                              attrs: {
                                                depressed: "",
                                                color: "secondary",
                                                outline: "",
                                                to: "SetupSensor"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "biz.onboarding.start.configuresensors"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticStyle: { "padding-bottom": "20px" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          directives: [
                                            {
                                              name: "track",
                                              rawName: "v-track",
                                              value:
                                                "OnboardingLanding::ToDashboard",
                                              expression:
                                                "'OnboardingLanding::ToDashboard'"
                                            }
                                          ],
                                          staticStyle: {
                                            "margin-left": "25px"
                                          },
                                          attrs: {
                                            depressed: "",
                                            color: "secondary",
                                            href: "/",
                                            outline: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("general.home.goto"))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
          _c("ServiceDialog", {
            directives: [
              {
                name: "track",
                rawName: "v-track",
                value: "OnboardingLanding::StartServiceRequest",
                expression: "'OnboardingLanding::StartServiceRequest'"
              }
            ],
            attrs: {
              dialogShown: _vm.showServiceDialog,
              selectedGateway: _vm.selectedGateway,
              message: _vm.$t("biz.help.servicedetails.requestmessage")
            },
            on: {
              evtServiceDialogClose: function($event) {
                _vm.showServiceDialog = false
              }
            }
          }),
          _c("BsdbSoftwareUpdateDialog", {
            directives: [
              {
                name: "track",
                rawName: "v-track",
                value: "OnboardingLanding::SoftwareUpdateDialog",
                expression: "'OnboardingLanding::SoftwareUpdateDialog'"
              }
            ],
            attrs: {
              dialogShown: _vm.showSoftwareUpdateDialog,
              selectedGateway: _vm.selectedGateway
            },
            on: {
              evtClose: function($event) {
                _vm.showSoftwareUpdateDialog = false
              }
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }