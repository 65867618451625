var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "fill-height": "" } },
        [
          !_vm.isUserAuthenticated()
            ? _c("LoginDialog")
            : _c("div", [_c("MobileHomePage")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }