var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _vm.sessionRenewShown
        ? _c("SessionRenewDialog", {
            attrs: { dialogShown: _vm.sessionRenewShown },
            on: {
              evtClose: function($event) {
                return _vm.onCloseSessionRenew()
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "window-container" },
        [
          _c(
            "v-window",
            {
              staticStyle: { height: "inherit" },
              attrs: { touchless: "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 1 } },
                [
                  _c("BaseWindowPage", {
                    attrs: { trackCancel: _vm.track + "::RouterConnection" },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _vm._v(_vm._s(_vm.$t("biz.setup.sch_router.title")))
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "subtitle",
                        fn: function() {
                          return [
                            _vm._v(_vm._s(_vm.$t("biz.setup.sch_router.text")))
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c(
                              "v-container",
                              { attrs: { "mt-4": "" } },
                              [
                                _c(
                                  "v-img",
                                  {
                                    staticClass: "bus_img_center",
                                    attrs: {
                                      src: require("@/assets/bsdb-setup-lan1.png")
                                    }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        attrs: {
                                          slot: "placeholder",
                                          "fill-height": "",
                                          "align-center": "",
                                          "justify-center": "",
                                          "ma-0": ""
                                        },
                                        slot: "placeholder"
                                      },
                                      [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            indeterminate: "",
                                            color: "grey lighten-5"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 2 } },
                [
                  _c("BaseWindowPage", {
                    attrs: { trackCancel: _vm.track + "::InternetConnection" },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _vm._v(_vm._s(_vm.$t("biz.setup.sch_led.title")))
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "subtitle",
                        fn: function() {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("biz.setup.sch_led.text")
                                )
                              }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track",
                                    value: _vm.track + "::HelpNoLanConnection",
                                    expression:
                                      "track + '::HelpNoLanConnection'"
                                  }
                                ],
                                attrs: {
                                  depressed: "",
                                  color: "secondary",
                                  outline: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showNoLanConnectionDialog = !_vm.showNoLanConnectionDialog
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pr-1",
                                    attrs: { color: "secondary" }
                                  },
                                  [_vm._v("help_outline")]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "biz.onboarding.lan.nolanconnectiontitle"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-img",
                              {
                                staticClass: "bus_img_center mt-5",
                                attrs: {
                                  src: require("@/assets/bsdb-setup-lan2.png")
                                }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      slot: "placeholder",
                                      "fill-height": "",
                                      "align-center": "",
                                      "justify-center": "",
                                      "ma-0": ""
                                    },
                                    slot: "placeholder"
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "grey lighten-5"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c("BsdbLanNotConnectedDialog", {
                    attrs: { dialogShown: _vm.showNoLanConnectionDialog },
                    on: {
                      evtClose: function($event) {
                        _vm.showNoLanConnectionDialog = false
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 3 } },
                [
                  _c("BsdbLanData", {
                    attrs: {
                      errorMessages: _vm.error,
                      loadedSerialNumber: _vm.serialNumber,
                      loadedName: _vm.name
                    },
                    on: {
                      evtVerify: _vm.verifyData,
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 4 } },
                [
                  _c("BaseWindowPage", {
                    attrs: { trackCancel: _vm.track + "::EnterPassword" },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function() {
                          return [
                            _vm._v(
                              _vm._s(_vm.$t("biz.setup.sch_connected.title"))
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "subtitle",
                        fn: function() {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("biz.setup.sch_connected.text"))
                              )
                            ])
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "content",
                        fn: function() {
                          return [
                            _c("v-text-field", {
                              staticClass: "default selected",
                              staticStyle: { "margin-top": "30px" },
                              attrs: {
                                type: "Password",
                                clearable: _vm.IconHelper.clearableIcon(
                                  _vm.passCode
                                ),
                                placeholder: _vm.$t(
                                  "biz.setup.sch_connected.password"
                                ),
                                "error-messages": _vm.error,
                                required: ""
                              },
                              model: {
                                value: _vm.passCode,
                                callback: function($$v) {
                                  _vm.passCode = $$v
                                },
                                expression: "passCode"
                              }
                            }),
                            _c(
                              "v-img",
                              {
                                staticClass: "bus_img_center",
                                staticStyle: { width: "50%", height: "50%" },
                                attrs: {
                                  src: require("@/assets/bsdb_password.png"),
                                  contain: ""
                                }
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      slot: "placeholder",
                                      "fill-height": "",
                                      "align-center": "",
                                      "justify-center": "",
                                      "ma-0": ""
                                    },
                                    slot: "placeholder"
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "grey lighten-5"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "navigation-place" },
            [
              _c("v-footer", { attrs: { color: "white" } }, [
                _c(
                  "div",
                  {
                    staticClass: "navigation-content",
                    attrs: { "grid-list-lg": "" }
                  },
                  [
                    _c("BackNextMenu", {
                      attrs: {
                        stepCount: 4,
                        nextDisabled: _vm.nextDisabled,
                        stepNextExemptions: [3, 4],
                        step: _vm.step
                      },
                      on: {
                        evtPrevious: _vm.gotoPreviousStep,
                        evtNextStepExemption: _vm.handleNextStep,
                        "update:step": function($event) {
                          _vm.step = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "finalStep",
                          fn: function() {
                            return [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value: _vm.track + "::PasscodeCheck",
                                      expression: "track + '::PasscodeCheck'"
                                    }
                                  ],
                                  attrs: {
                                    depressed: "",
                                    disabled: _vm.nextDisabled,
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.checkPasscode()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.setup.sch_connected.complete")
                                    )
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.cancelDialogShown,
          title: _vm.$t("biz.setup.abort.title"),
          message: _vm.$t("biz.setup.abort.description"),
          track: _vm.track + "::AbortSetup"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmAbort()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelAbort()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }