var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            transform: "matrix(0.749995, 0, 0, 0.749995, -1.499911, -1.874947)"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-bus_white-base)" },
            attrs: {
              d:
                "M18,24 C27.7750262,24 33.2476219,26.9187177 33.9922779,32.8759653 C34.0607804,33.4239852 33.6720547,33.9237754 33.1240347,33.9922779 C32.5760148,34.0607804 32.0762246,33.6720547 32.0077221,33.1240347 C31.4190447,28.4146156 26.8916405,26 18,26 C9.1083595,26 4.58095527,28.4146156 3.99227788,33.1240347 C3.92377538,33.6720547 3.4239852,34.0607804 2.87596527,33.9922779 C2.32794533,33.9237754 1.93921963,33.4239852 2.00772212,32.8759653 C2.75237806,26.9187177 8.22497383,24 18,24 Z M18,3 C22.9705627,3 27,7.02943725 27,12 C27,16.9705627 22.9705627,21 18,21 C13.0294373,21 9,16.9705627 9,12 C9,7.02943725 13.0294373,3 18,3 Z M18,5 C14.1340068,5 11,8.13400675 11,12 C11,15.8659932 14.1340068,19 18,19 C21.8659932,19 25,15.8659932 25,12 C25,8.13400675 21.8659932,5 18,5 Z",
              "fill-rule": "nonzero"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }