// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ContinuationAlarmToken
 */
export interface ContinuationAlarmToken {
  /**
   * @type {number}
   * @memberof ContinuationAlarmToken
   */
  deviceAlarms?: number;
  /**
   * @type {number}
   * @memberof ContinuationAlarmToken
   */
  gatewayAlarms?: number;
  /**
   * @type {boolean}
   * @memberof ContinuationAlarmToken
   */
  more?: boolean;
  /**
   * @type {boolean}
   * @memberof ContinuationAlarmToken
   */
  moreDeviceAlarms?: boolean;
  /**
   * @type {boolean}
   * @memberof ContinuationAlarmToken
   */
  moreGatewayAlarms?: boolean;
  /**
   * @type {boolean}
   * @memberof ContinuationAlarmToken
   */
  moreSensorsAlarms?: boolean;
  /**
   * @type {boolean}
   * @memberof ContinuationAlarmToken
   */
  moreSmartModuleAlarms?: boolean;
  /**
   * @type {number}
   * @memberof ContinuationAlarmToken
   */
  sensorAlarms?: number;
  /**
   * @type {number}
   * @memberof ContinuationAlarmToken
   */
  smartModuleAlarms?: number;
}
