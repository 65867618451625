var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("g", [
            _c("g", { staticStyle: { fill: "var(--v-bus_red-base)" } }, [
              _c("circle", { attrs: { cx: "16", cy: "16", r: "16" } })
            ]),
            _c(
              "g",
              {
                staticStyle: { fill: "var(--v-bus_white-base)" },
                attrs: { transform: "translate(4.000000, 4.000000)" }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M15,3 C15.5522847,3 16,3.44771525 16,4 L16,7 L18,7 L18,9 L17,10 L17,13 C17,14.6568542 15.6568542,16 14,16 L13.5,16 L13.5,20 L10.5,20 L10.5,16 L10,16 C8.34314575,16 7,14.6568542 7,13 L7,10 L7.09090909,10 L6,9 L6,7 L8,7 L8,4 C8,3.44771525 8.44771525,3 9,3 C9.55228475,3 10,3.44771525 10,4 L10,7 L14,7 L14,4 C14,3.44771525 14.4477153,3 15,3 Z M12,10.5 C11.1715729,10.5 10.5,11.1715729 10.5,12 C10.5,12.8284271 11.1715729,13.5 12,13.5 C12.8284271,13.5 13.5,12.8284271 13.5,12 C13.5,11.1715729 12.8284271,10.5 12,10.5 Z"
                  }
                })
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }