var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "" },
          model: {
            value: _vm.menuShown,
            callback: function($$v) {
              _vm.menuShown = $$v
            },
            expression: "menuShown"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c("v-card-title", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("general.home.profile.title")))
                  ]),
                  _c("v-spacer"),
                  _c("Cancel", {
                    attrs: { track: "UserMenu" },
                    on: {
                      evtCancel: function($event) {
                        return _vm.closeMenu()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticStyle: { padding: "0px 16px" } },
                [
                  _c(
                    "v-list",
                    { attrs: { "three-line": "", subheader: "" } },
                    [
                      _c(
                        "v-list-tile",
                        { staticClass: "unicolorList", attrs: { avatar: "" } },
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("general.menus.usermenu.account")
                                  )
                                )
                              ]),
                              _c("v-list-tile-sub-title", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "general.menus.usermenu.accounttext"
                                      )
                                    ) +
                                    "\n                "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.userName) +
                                    "\n              "
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "MobileUserMenu::Logout",
                                  expression: "'MobileUserMenu::Logout'"
                                }
                              ],
                              staticClass: "ma-0",
                              attrs: { color: "primary", depressed: "" },
                              on: {
                                click: function($event) {
                                  return _vm.logout()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("general.home.profile.logout"))
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.showSessionRenewalCheck
                        ? _c(
                            "v-list-tile",
                            {
                              staticClass: "unicolorList",
                              attrs: { avatar: "" }
                            },
                            [
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("general.home.profile.session")
                                      )
                                    )
                                  ]),
                                  _c("v-list-tile-sub-title", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "general.home.profile.sessionrenew"
                                        )
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c("v-switch", {
                                    staticClass: "justify-end",
                                    attrs: { color: "secondary" },
                                    on: {
                                      change: function($event) {
                                        return _vm.toggleSessionRenewal()
                                      }
                                    },
                                    model: {
                                      value: _vm.sessionRenewal,
                                      callback: function($$v) {
                                        _vm.sessionRenewal = $$v
                                      },
                                      expression: "sessionRenewal"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("MobileLanguageSetup", {
                        attrs: { userSettings: _vm.userSettings }
                      }),
                      _c(
                        "v-list-tile",
                        { staticClass: "unicolorList", attrs: { avatar: "" } },
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("general.home.profile.optouttitle")
                                  )
                                )
                              ]),
                              _c("v-list-tile-sub-title", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "general.home.profile.optoutdescription"
                                    )
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "MobileUserMenu::ShowCookieDialog",
                                  expression:
                                    "'MobileUserMenu::ShowCookieDialog'"
                                }
                              ],
                              staticClass: "ma-0",
                              attrs: {
                                depressed: "",
                                color: "secondary",
                                outline: "",
                                target: "_blank"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showCookieDialog()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("general.home.profile.optoutbutton")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        {
                          staticClass: "unicolorList mt-2",
                          attrs: { avatar: "" }
                        },
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("general.home.profile.password")
                                  )
                                )
                              ]),
                              _c("v-list-tile-sub-title", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "general.home.profile.passdescription"
                                    )
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "MobileUserMenu::ChangePassword",
                                  expression: "'MobileUserMenu::ChangePassword'"
                                }
                              ],
                              staticClass: "ma-0",
                              attrs: {
                                depressed: "",
                                color: "secondary",
                                href: _vm.getNewPasswordLink(),
                                outline: "",
                                target: "_blank"
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v(_vm._s(_vm.getRightArrowIcon()))
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("general.home.profile.passbutton")
                                  ) +
                                  "\n            "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("CookieDialog", {
            attrs: {
              dialogShown: _vm.showCookieSettingsDialog,
              userSettingsProp: _vm.userSettings
            },
            on: {
              evtClose: function($event) {
                return _vm.closeCookieSettingsDialog()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }