import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import {
  BsdbControllerApi,
  CheckBsdbUsingPUTRequest,
  CheckGatewayResponseConfigurationStateEnum,
  TimeZone
} from '@/api';
import { IconHelper } from '@/components/general/icons/IconHelper';
import { ValidationHelper } from '@/components/validation/ValidationHelper';

import BackNextMenu from '@/components/general/menu/BackNextMenu';
import BaseWindowPage from '@/components/general/pages/BaseWindowPage';
import OkCancelDialog from '@/components/general/dialogs/OkCancelDialog';
import BsdbNotFoundDialog from '@/views/setup/onboarding/dialogs/BsdbNotFoundDialog';
import TimeoutUtil from '@/components/general/util/TimeoutUtil';
import OkDialog from '@/components/general/dialogs/OkDialog';

import { useApiStore } from '@/api/ApiStorePinia';
import TimeZoneSelector from '@/components/general/menu/TimeZoneSelector';

/**
 * Gives the user the chance to enter a name for the SCH that we received by serial number.
 * If the hub exists, we do nothing.
 * If it doesn't exist, we let the user specify a name and a timezone and go to OnboardingLanding.
 *
 * @export
 * @class SetupBsdbNameEntry
 * @extends {Vue}
 */
@Component({
  components: { BackNextMenu, BaseWindowPage, OkDialog, OkCancelDialog, BsdbNotFoundDialog, TimeZoneSelector }
})
export default class SetupBsdbNameEntry extends Vue {
  /**
   * The serial number of the hub.
   *
   * @type {string}
   * @memberof SetupBsdbNameEntry
   */
  @Prop()
  public serialNumber: string;

  /**
   * The track to send to GA.
   *
   * @private
   * @type {string}
   * @memberof SetupBsdbNameEntry
   */
  @Prop({ default: 'SetupBsdbNameEntry' })
  public track: string;

  /**
   * Controls the steps of creating report
   *
   * @type {number}
   * @memberof SetupBsdbNameEntry
   */
  private step: number = 1;

  /**
   * Controls the state of the next button.
   *
   * @private
   * @type {boolean}
   * @memberof SetupBsdbNameEntry
   */
  private nextDisabled: boolean = true;

  /**
   * Name field, edited by the user.
   *
   * @private
   * @type {string}
   * @memberof SetupBsdbNameEntry
   */
  private nameData: string = '';

  /**
   * The time zone selected by the user.
   *
   * @private
   * @type {TimeZone}
   * @memberof SetupBsdbNameEntry
   */
  private timezone: TimeZone = null;

  /**
   * Simple constant. The final step is two. User cannot go there,
   * we jump over to OnboardingLanding if he does.
   *
   * @private
   * @type {number}
   * @memberof SetupBsdbNameEntry
   */
  private readonly FINAL_STEP: number = 3;

  /**
   * The rules for the name.
   *
   * @memberof SetupBsdbNameEntry
   */
  private bsdbNameRules = ValidationHelper.nameRules;

  /**
   * Cancel dialog handling.
   *
   * @private
   * @type {boolean}
   * @memberof SetupBsdbNameEntry
   */
  private cancelDialogShown: boolean = false;

  /**
   * All done confirmation dialog control.
   *
   * @private
   * @type {boolean}
   * @memberof SetupBsdbNameEntry
   */
  private allDoneDialogShown: boolean = false;

  /**
   * If we cannot find the BSDB, then show an error.
   *
   * @private
   * @type {boolean}
   * @memberof SetupBsdbNameEntry
   */
  private showBsdbNotFound: boolean = false;

  /**
   * Reusable helper for waiting time counting
   *
   * @type {TimeoutUtil}
   * @memberof SetupBsdbNameEntry
   */
  private timeoutUtil: TimeoutUtil = new TimeoutUtil();

  /**
   * Hook, starts the check process.
   *
   * @memberof SetupBsdbNameEntry
   */
  public mounted(): void {
    this.nameData = this.serialNumber;
    this.step = 1;

    this.timeoutUtil.start(() => this.checkNameInputNeeded(), 3000, 'CheckName');
  }

  /**
   * Computed property that reflects the name.
   *
   * @readonly
   * @memberof SetupBsdbNameEntry
   */
  get name() {
    return this.nameData;
  }

  /**
   * Setter for the name of the hub.
   *
   * @memberof SetupBsdbNameEntry
   */
  set name(newValue: string) {
    this.nameData = newValue;

    this.nextDisabled = !ValidationHelper.isValidName(this.nameData);
  }

  /**
   * Checks whether a hub with that serial number is still known in our cloud.
   * If it is, then the user just performed a WiFi reset.
   * If not, it's an onboarding operation and we need a name.
   *
   * @private
   * @memberof SetupBsdbNameEntry
   */
  private checkNameInputNeeded(): void {
    const logger = this.$log.prefix('SetupBsdbNameEntry::checkNameInputNeeded');
    logger.debug('Checking whether name input is needed');

    const apiStore = useApiStore();
    const bsdbControllerApi: BsdbControllerApi = apiStore.getBsdbControllerApi;

    this.nextDisabled = true;

    const request: CheckBsdbUsingPUTRequest = {
      checkBsdbRequest: {
        passCode: undefined,
        serialNumber: this.serialNumber
      }
    };

    bsdbControllerApi
      .checkBsdbUsingPUT(request)
      .toPromise()
      .then(response => {
        if (response) {
          if (response.configurationState === CheckGatewayResponseConfigurationStateEnum.CONFIGURED) {
            // No input of the name needed. It was just a change of WiFi credentials
            // Go to home, we're done
            logger.debug('No name input needed');
            this.allDoneDialogShown = true;

            return;
          }

          if (response.configurationState === CheckGatewayResponseConfigurationStateEnum.AVAILABLE) {
            // Allow name input
            logger.debug('Name and timezone input needed');
            this.nextDisabled = false;
            this.step = 2;

            return;
          }

          this.showBsdbNotFound = true;
        }
      })
      .catch(reason => {
        logger.warn('Could not check need for name input', { reason: reason });
        this.gotoHome();
      });
  }

  /**
   * Called when the user selects a timezone.
   *
   * @param {TimeZone} timezone
   * @memberof SetupBsdbNameEntry
   */
  public onTimeZoneChanged(timezone: TimeZone) {
    this.timezone = timezone;
  }

  /**
   * Handles the final step of the flow. We login and do the rest there.
   *
   * @private
   * @param {number} step The step.
   * @memberof SetupBsdbNameEntry
   */
  private handleNextStep(step: number): void {
    if (step === this.FINAL_STEP) {
      this.gotoOnboardingLanding();
    }
  }

  /**
   * User aborts name entry, we use the default.
   *
   * @private
   * @memberof SetupBsdbNameEntry
   */
  private confirmAbortNameEntry(): void {
    this.nameData = this.serialNumber;
    this.gotoOnboardingLanding();
  }

  /**
   * User cancels the abort.
   *
   * @private
   * @memberof SetupBsdbNameEntry
   */
  private cancelAbortNameEntry(): void {
    this.cancelDialogShown = false;
  }

  /**
   * Called when the "all done" dialog is okay'ed. We just go to home.
   *
   * @private
   * @memberof SetupBsdbNameEntry
   */
  private allDoneConfirmed(): void {
    this.allDoneDialogShown = false;

    this.gotoHome();
  }

  /**
   * Goes to home.
   *
   * @private
   * @memberof SetupBsdbNameEntry
   */
  private gotoHome(): void {
    this.$router.replace({ name: 'Dashboard' });
  }

  /**
   * Goes to the onboarding landing with the onboarding data.
   *
   * @private
   * @memberof SetupBsdbNameEntry
   */
  private gotoOnboardingLanding(): void {
    const logger = this.$log.prefix('SetupBsdbNameEntry::gotoOnboardingLanding');
    logger.debug('Finishing the onboarding', {
      serialNumber: this.serialNumber,
      name: this.nameData,
      timezone: this.timezone
    });

    this.$router.replace({
      name: 'OnboardingLanding',
      params: { serialNumber: this.serialNumber, name: this.nameData, timezone: this.timezone.iana }
    });
  }

  /**
   * User closed the BSDB not found dialog.
   *
   * @private
   * @memberof SetupBsdbNameEntry
   */
  private afterBsdbNotFound(): void {
    this.showBsdbNotFound = false;

    this.gotoHome();
  }

  /**
   * Getter for the IconHelper.
   *
   * @readonly
   * @type {IconHelper}
   * @memberof SetupBsdbNameEntry
   */
  get IconHelper(): IconHelper {
    return IconHelper;
  }
}
