var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { sm12: "", md6: "", xl4: "" } },
    [
      _c(
        "v-card",
        { attrs: { height: _vm.cardHeight } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { style: _vm.layoutHeight },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "ma-2", attrs: { size: "80" } },
                        [_vm._t("icon")],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs9: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "mt-3 mb-2 ml-1",
                          staticStyle: { height: "40%" },
                          attrs: { column: "" }
                        },
                        [
                          _c("h3", [_vm._t("title")], 2),
                          _vm._t("extra"),
                          _c(
                            "v-flex",
                            {
                              staticClass: "bus_normaltext pb-3",
                              staticStyle: {
                                width: "310px",
                                "margin-left": "-8px"
                              }
                            },
                            [_vm._t("text")],
                            2
                          )
                        ],
                        2
                      ),
                      _c(
                        "v-layout",
                        {
                          staticStyle: { height: "60%", "margin-left": "-4px" },
                          attrs: { column: "" }
                        },
                        [
                          _vm.hasContent()
                            ? _c(
                                "v-flex",
                                {
                                  staticClass: "pb-3 pt-3",
                                  staticStyle: { width: "310px", height: "30%" }
                                },
                                [_vm._t("content")],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            {
                              staticClass: "pb-5 pt-5 ml-0 pl-0",
                              staticStyle: { width: "310px", height: "70%" }
                            },
                            [_vm._t("button")],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }