var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Cancel", {
        attrs: {
          cancelDisabled: _vm.cancelDisabled,
          track: _vm.track,
          icon: _vm.icon
        },
        on: {
          evtCancel: function($event) {
            return _vm.cancel()
          }
        }
      }),
      _c("Save", {
        attrs: {
          saveDisabled: _vm.saveDisabled,
          track: _vm.track,
          icon: _vm.icon
        },
        on: {
          evtSave: function($event) {
            return _vm.save()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }