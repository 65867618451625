// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Manuals, MeasureSiteAddress, ZoneInfo } from './';

/**
 * @export
 * @interface ApplianceResponse
 */
export interface ApplianceResponse {
  /**
   * @type {MeasureSiteAddress}
   * @memberof ApplianceResponse
   */
  address?: MeasureSiteAddress;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  articleNumber?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  building?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  comment?: string;
  /**
   * @type {ZoneInfo}
   * @memberof ApplianceResponse
   */
  coolerZone?: ZoneInfo;
  /**
   * @type {Date}
   * @memberof ApplianceResponse
   */
  created?: Date;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  department?: string;
  /**
   * @type {ZoneInfo}
   * @memberof ApplianceResponse
   */
  freezerZone?: ZoneInfo;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  gatewayId?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  inventoryNumber?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  ipAddress?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  level?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  macAddress?: string;
  /**
   * @type {Manuals}
   * @memberof ApplianceResponse
   */
  manuals?: Manuals;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  manufacturer?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  measureSiteType?: ApplianceResponseMeasureSiteTypeEnum;
  /**
   * @type {number}
   * @memberof ApplianceResponse
   */
  modbusAddress?: number;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  model?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  name?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  networkType?: ApplianceResponseNetworkTypeEnum;
  /**
   * @type {Date}
   * @memberof ApplianceResponse
   */
  orderDate?: Date;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  productImageUrl?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  room?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  serialNumber?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  softwareVersion?: string;
  /**
   * @type {string}
   * @memberof ApplianceResponse
   */
  virtualFridgeId?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplianceResponseMeasureSiteTypeEnum {
  ROOM = 'ROOM',
  MODBUSFRIDGE = 'MODBUS_FRIDGE',
  NOTCONNECTABLEFRIDGE = 'NOT_CONNECTABLE_FRIDGE',
  THIRDPARTYFRIDGE = 'THIRD_PARTY_FRIDGE',
  BINDERFRIDGE = 'BINDER_FRIDGE',
  SMARTMODULEFRIDGE = 'SMART_MODULE_FRIDGE'
}
/**
 * @export
 * @enum {string}
 */
export enum ApplianceResponseNetworkTypeEnum {
  LAN = 'LAN',
  WLAN = 'WLAN',
  WIFI = 'WIFI',
  UNKNOWN = 'UNKNOWN'
}
