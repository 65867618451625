var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.icon
    ? _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: _vm.track + "::Add",
              expression: "track + '::Add'"
            }
          ],
          attrs: {
            slot: "activator",
            depressed: "",
            disabled: _vm.addDisabled
          },
          on: {
            click: function($event) {
              return _vm.onAddPressed()
            }
          },
          slot: "activator"
        },
        [_c("v-icon", [_vm._v("$vuetify.icons.bus_plus")])],
        1
      )
    : _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: _vm.track + "::Add",
              expression: "track + '::Add'"
            }
          ],
          staticStyle: { "margin-left": "10px" },
          attrs: {
            slot: "activator",
            depressed: "",
            color: "primary",
            disabled: _vm.addDisabled
          },
          on: {
            click: function($event) {
              return _vm.onAddPressed()
            }
          },
          slot: "activator"
        },
        [_vm._v(_vm._s(_vm.$t("general.add")))]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }