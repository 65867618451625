var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    staticClass: "default selected",
    staticStyle: { "font-size": "14px" },
    attrs: {
      chips: "",
      "return-object": "",
      items: _vm.timezones,
      "item-text": "display",
      "full-width": "",
      "menu-props": { bottom: true, offsetY: true },
      disabled: _vm.disableTimeZoneSelection
    },
    model: {
      value: _vm.timezone,
      callback: function($$v) {
        _vm.timezone = $$v
      },
      expression: "timezone"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }