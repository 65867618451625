// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
  AssignedSensor,
  BsdbSensorsResponse,
  ErrorDto,
  MeasureSiteSensorsResponse,
  OrganizationSensorsResponse,
  SamplingRateRequest,
  SensorAlarmProfileInfo,
  SensorAssignmentRequest,
  SensorInfo,
  SensorMetadataRequest,
  SensorOffboardRequest,
  SensorStatesResponse
} from '../models';

export interface AssignSensorUsingPOSTRequest {
  gatewayId: string;
  sensorAssignmentRequest: SensorAssignmentRequest;
}

export interface GetAllBsdbsSensorsUsingGETRequest {
  gatewayIds: Array<string>;
  assignmentState?: GetAllBsdbsSensorsUsingGETAssignmentStateEnum;
}

export interface GetAllSensorsByGatewayIdUsingGETRequest {
  gatewayId: string;
  assignmentState?: GetAllSensorsByGatewayIdUsingGETAssignmentStateEnum;
}

export interface GetAllSensorsByOrganizationIdUsingGETRequest {
  organizationId: string;
  assignmentState?: GetAllSensorsByOrganizationIdUsingGETAssignmentStateEnum;
}

export interface GetAllSensorsByVirtualFridgeIdUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetAssignedSensorInfoUsingGETRequest {
  sensorId: string;
}

export interface GetSensorAlarmProfileInfoUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetSensorInfoUsingGETRequest {
  thingId: string;
}

export interface OffboardSensorUsingPOSTRequest {
  sensorOffboardRequest: SensorOffboardRequest;
}

export interface SetMetadataUsingPUTRequest {
  thingId: string;
  sensorMetadataRequest: SensorMetadataRequest;
}

export interface SetSamplingRateUsingPOSTRequest {
  gatewayId: string;
  samplingRateRequest: SamplingRateRequest;
}

export interface StartPairingModeUsingGETRequest {
  correlationId: string;
  gatewayId: string;
}

/**
 * no description
 */
export class SensorControllerApi extends BaseAPI {
  /**
   * assignSensor
   * Assigns sensor to measure site
   */
  assignSensorUsingPOST = (requestParameters: AssignSensorUsingPOSTRequest): Observable<boolean> => {
    throwIfRequired(requestParameters, 'gatewayId', 'assignSensorUsingPOST');
    throwIfRequired(requestParameters, 'sensorAssignmentRequest', 'assignSensorUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/gateways/{gatewayId}/assignSensor'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'POST',
      headers,
      body: requestParameters.sensorAssignmentRequest
    });
  };

  /**
   * getAllBsdbsSensors
   * Get all sensors for array of bsdbs
   */
  getAllBsdbsSensorsUsingGET = (
    requestParameters: GetAllBsdbsSensorsUsingGETRequest
  ): Observable<Array<BsdbSensorsResponse>> => {
    throwIfRequired(requestParameters, 'gatewayIds', 'getAllBsdbsSensorsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.assignmentState && { assignmentState: requestParameters.assignmentState }),
      ...(requestParameters.gatewayIds && { gatewayIds: requestParameters.gatewayIds })
    };

    return this.request<Array<BsdbSensorsResponse>>({
      path: '/sensors/allsensors',
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getAllSensorsByGatewayId
   * Get all sensors connected to selected bsdb
   */
  getAllSensorsByGatewayIdUsingGET = (
    requestParameters: GetAllSensorsByGatewayIdUsingGETRequest
  ): Observable<BsdbSensorsResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'getAllSensorsByGatewayIdUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.assignmentState && { assignmentState: requestParameters.assignmentState })
    };

    return this.request<BsdbSensorsResponse>({
      path: '/sensors/gateway/{gatewayId}/sensors'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getAllSensorsByOrganizationId
   * Get all sensors connected to organization
   */
  getAllSensorsByOrganizationIdUsingGET = (
    requestParameters: GetAllSensorsByOrganizationIdUsingGETRequest
  ): Observable<OrganizationSensorsResponse> => {
    throwIfRequired(requestParameters, 'organizationId', 'getAllSensorsByOrganizationIdUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.assignmentState && { assignmentState: requestParameters.assignmentState })
    };

    return this.request<OrganizationSensorsResponse>({
      path: '/sensors/organization/{organizationId}/sensors'.replace(
        '{organizationId}',
        encodeURI(requestParameters.organizationId)
      ),
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getAllSensorsByVirtualFridgeId
   * Get all sensors for a measuresite.
   */
  getAllSensorsByVirtualFridgeIdUsingGET = (
    requestParameters: GetAllSensorsByVirtualFridgeIdUsingGETRequest
  ): Observable<MeasureSiteSensorsResponse> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getAllSensorsByVirtualFridgeIdUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<MeasureSiteSensorsResponse>({
      path: '/sensors/measuresites/{virtualFridgeId}/sensors'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * getAssignedSensorInfo
   * Get assigned sensor info.
   */
  getAssignedSensorInfoUsingGET = (
    requestParameters: GetAssignedSensorInfoUsingGETRequest
  ): Observable<AssignedSensor> => {
    throwIfRequired(requestParameters, 'sensorId', 'getAssignedSensorInfoUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AssignedSensor>({
      path: '/admin/sensor/{sensorId}/assigned'.replace('{sensorId}', encodeURI(requestParameters.sensorId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getSensorAlarmProfileInfo
   * Get alarmprofile info for the sensors attached to the given measure site.
   */
  getSensorAlarmProfileInfoUsingGET = (
    requestParameters: GetSensorAlarmProfileInfoUsingGETRequest
  ): Observable<SensorAlarmProfileInfo> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getSensorAlarmProfileInfoUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SensorAlarmProfileInfo>({
      path: '/sensors/{virtualFridgeId}/alarmprofileinfo'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * getSensorInfo
   * Get common sensor info.
   */
  getSensorInfoUsingGET = (requestParameters: GetSensorInfoUsingGETRequest): Observable<SensorInfo> => {
    throwIfRequired(requestParameters, 'thingId', 'getSensorInfoUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SensorInfo>({
      path: '/admin/sensor/{thingId}/info'.replace('{thingId}', encodeURI(requestParameters.thingId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getSensorStates
   */
  getSensorStatesUsingGET = (): Observable<SensorStatesResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SensorStatesResponse>({
      path: '/sensors/sensorstates',
      method: 'GET',
      headers
    });
  };

  /**
   * offboardSensor
   * Create a new measure site for a non connectable appliance.
   */
  offboardSensorUsingPOST = (requestParameters: OffboardSensorUsingPOSTRequest): Observable<boolean> => {
    throwIfRequired(requestParameters, 'sensorOffboardRequest', 'offboardSensorUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/sensors/offboardSensor',
      method: 'POST',
      headers,
      body: requestParameters.sensorOffboardRequest
    });
  };

  /**
   * setMetadata
   * Sets sensor metadata
   */
  setMetadataUsingPUT = (requestParameters: SetMetadataUsingPUTRequest): Observable<boolean> => {
    throwIfRequired(requestParameters, 'thingId', 'setMetadataUsingPUT');
    throwIfRequired(requestParameters, 'sensorMetadataRequest', 'setMetadataUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/sensors/{thingId}/metadata'.replace('{thingId}', encodeURI(requestParameters.thingId)),
      method: 'PUT',
      headers,
      body: requestParameters.sensorMetadataRequest
    });
  };

  /**
   * setSamplingRate
   * Sets new sampling rate
   */
  setSamplingRateUsingPOST = (requestParameters: SetSamplingRateUsingPOSTRequest): Observable<boolean> => {
    throwIfRequired(requestParameters, 'gatewayId', 'setSamplingRateUsingPOST');
    throwIfRequired(requestParameters, 'samplingRateRequest', 'setSamplingRateUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/gateways/{gatewayId}/setSamplingRate'.replace(
        '{gatewayId}',
        encodeURI(requestParameters.gatewayId)
      ),
      method: 'POST',
      headers,
      body: requestParameters.samplingRateRequest
    });
  };

  /**
   * startPairingMode
   * Get pairing mode for sensor on gateway.
   */
  startPairingModeUsingGET = (requestParameters: StartPairingModeUsingGETRequest): Observable<boolean> => {
    throwIfRequired(requestParameters, 'correlationId', 'startPairingModeUsingGET');
    throwIfRequired(requestParameters, 'gatewayId', 'startPairingModeUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/admin/gateways/{gatewayId}/pair/{correlationId}'
        .replace('{correlationId}', encodeURI(requestParameters.correlationId))
        .replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers
    });
  };
}

/**
 * @export
 * @enum {string}
 */
export enum GetAllBsdbsSensorsUsingGETAssignmentStateEnum {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED'
}
/**
 * @export
 * @enum {string}
 */
export enum GetAllSensorsByGatewayIdUsingGETAssignmentStateEnum {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED'
}
/**
 * @export
 * @enum {string}
 */
export enum GetAllSensorsByOrganizationIdUsingGETAssignmentStateEnum {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED'
}
