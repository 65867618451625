var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "20px",
        height: "19px",
        viewBox: "0 0 20 19",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              staticStyle: { fill: "var(--v-secondary-base)" },
              attrs: { transform: "translate(-1471.000000, -475.000000)" }
            },
            [
              _c(
                "g",
                { attrs: { transform: "translate(92.000000, 109.000000)" } },
                [
                  _c(
                    "g",
                    { attrs: { transform: "translate(0.000000, 72.000000)" } },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            transform: "translate(24.000000, 272.000000)"
                          }
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                transform: "translate(1350.000000, 18.000000)"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform: "translate(2.000000, 2.000000)"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M17,11.4444444 C17,10.8921597 17.4477153,10.4444444 18,10.4444444 C18.5522847,10.4444444 19,10.8921597 19,11.4444444 L19,19 C19,20.1045695 18.1045695,21 17,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,7 C3,5.8954305 3.8954305,5 5,5 L12.5555556,5 C13.1078403,5 13.5555556,5.44771525 13.5555556,6 C13.5555556,6.55228475 13.1078403,7 12.5555556,7 L5,7 L5,19 L17,19 L17,11.4444444 Z M18.7279221,4 L15.0142136,4 C14.4619288,4 14.0142136,3.55228475 14.0142136,3 C14.0142136,2.44771525 14.4619288,2 15.0142136,2 L22.0142136,2 L22.0142136,9 C22.0142136,9.55228475 21.5664983,10 21.0142136,10 C20.4619288,10 20.0142136,9.55228475 20.0142136,9 L20.0142136,5.54213562 L13.5355339,12.0208153 C13.1450096,12.4113396 12.5118446,12.4113396 12.1213203,12.0208153 C11.7307961,11.630291 11.7307961,10.997126 12.1213203,10.6066017 L18.7279221,4 Z",
                                      id: "Shape"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }