var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              staticStyle: { fill: "var(--v-secondary-base)" },
              attrs: {
                transform: "translate(0.000000, 4.000000)",
                "fill-rule": "nonzero"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M2,1 C1.44771525,1 1,1.44771525 1,2 L1,14 C1,14.5522847 1.44771525,15 2,15 L22,15 C22.5522847,15 23,14.5522847 23,14 L23,2 C23,1.44771525 22.5522847,1 22,1 L2,1 Z M2,0 L22,0 C23.1045695,-2.02906125e-16 24,0.8954305 24,2 L24,14 C24,15.1045695 23.1045695,16 22,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M7.56,4.72 C8.4533378,4.72 9.0833315,5.1133294 9.45,5.9 C9.7233347,6.4866696 9.86,7.3299945 9.86,8.43 C9.86,9.5300055 9.7233347,10.3733304 9.45,10.96 C9.0833315,11.7466706 8.4533378,12.14 7.56,12.14 C6.6666622,12.14 6.0366685,11.7466706 5.67,10.96 C5.3966653,10.3733304 5.26,9.5300055 5.26,8.43 C5.26,7.3299945 5.3966653,6.4866696 5.67,5.9 C6.0366685,5.1133294 6.6666622,4.72 7.56,4.72 Z M7.56,5.44 C6.9999972,5.44 6.6166677,5.7333304 6.41,6.32 C6.2566659,6.7533355 6.18,7.4566618 6.18,8.43 C6.18,9.4033382 6.2566659,10.1066645 6.41,10.54 C6.6166677,11.1266696 6.9999972,11.42 7.56,11.42 C8.1200028,11.42 8.5033323,11.1266696 8.71,10.54 C8.8633341,10.1066645 8.94,9.4033382 8.94,8.43 C8.94,7.4566618 8.8633341,6.7533355 8.71,6.32 C8.5033323,5.7333304 8.1200028,5.44 7.56,5.44 Z M10.92,12 L10.92,4.86 L14.61,4.86 L14.61,5.64 L11.8,5.64 L11.8,7.88 L14.45,7.88 L14.45,8.66 L11.8,8.66 L11.8,12 L10.92,12 Z M15.36,12 L15.36,4.86 L19.05,4.86 L19.05,5.64 L16.24,5.64 L16.24,7.88 L18.89,7.88 L18.89,8.66 L16.24,8.66 L16.24,12 L15.36,12 Z"
                }
              })
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }