var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.criticalDevicesCount > 0
    ? _c("v-badge", { attrs: { color: "bus_red" } }, [
        _c(
          "span",
          {
            staticStyle: { "font-size": "12px" },
            attrs: { slot: "badge" },
            slot: "badge"
          },
          [_vm._v(_vm._s(_vm.$format.badgeNumber(_vm.criticalDevicesCount)))]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }