var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px", persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _c("div", { staticClass: "title" }, [
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("biz.onboarding.smartmodule.notonboardedtitle"))
                )
              ])
            ])
          ]),
          _c("v-card-text", { staticClass: "bus_normaltext" }, [
            _vm.incompleteSetupSmartModules &&
            _vm.incompleteSetupSmartModules.length > 1
              ? _c("div", [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("biz.onboarding.smartmodule.notonboardeddesc3")
                      )
                    }
                  }),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$tc(
                          "biz.onboarding.smartmodule.notonboardeddesc2",
                          2
                        )
                      )
                    )
                  ])
                ])
              : _c("div", [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("biz.onboarding.smartmodule.notonboardeddesc1")
                      )
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$tc(
                          "biz.onboarding.smartmodule.notonboardeddesc2",
                          1
                        )
                      )
                    )
                  ])
                ])
          ]),
          _c(
            "v-card-actions",
            [
              _vm.incompleteSetupSmartModules &&
              _vm.incompleteSetupSmartModules.length > 1
                ? _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value:
                                "SmartModuleWarningDialog::NavigateToMyDevices",
                              expression:
                                "'SmartModuleWarningDialog::NavigateToMyDevices'"
                            }
                          ],
                          attrs: { color: "secondary", outline: "" },
                          on: { click: _vm.navigateToMyDevices }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.getRightArrowIcon()))
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("biz.groups.pagename")) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value:
                                "SmartModuleWarningDialog::CompleteOnboarding",
                              expression:
                                "'SmartModuleWarningDialog::CompleteOnboarding'"
                            }
                          ],
                          attrs: { color: "secondary", outline: "" },
                          on: { click: _vm.completeOnboarding }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.getRightArrowIcon()))
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "biz.onboarding.smartmodule.continuetoonboarding"
                                )
                              ) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: "SmartModuleWarningDialog::Close",
                      expression: "'SmartModuleWarningDialog::Close'"
                    }
                  ],
                  attrs: { depressed: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onClosePressed()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("general.ok")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }