var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm.dataIsMissing
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "65vh" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("DataMissingDisplay", {
                attrs: { interestedIn: _vm.pageRouteHelper.ALARM_OVERVIEW },
                on: {
                  evtAlarmListData: function($event) {
                    _vm.dataIsMissing = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.dataIsMissing
        ? _c(
            "v-card",
            { staticClass: "px-2" },
            [
              _c("AlarmList", {
                attrs: { track: "AlarmOverview::AlarmList" },
                on: {
                  evtCommentError: function($event) {
                    _vm.showErrorDialog = true
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("OkDialog", {
        attrs: {
          title: _vm.$t("biz.dashboard.alarms.newcommenterrortitle"),
          message: _vm.$t("biz.dashboard.alarms.newcommenterrordesc"),
          dialogShown: _vm.showErrorDialog,
          track: "AlarmOverview::CreateCommentError"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.close()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }