var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasAppliances(),
              expression: "hasAppliances()"
            }
          ],
          attrs: {
            row: "",
            nowrap: "",
            "justify-space-between": "",
            "mt-3": "",
            "ml-3": ""
          }
        },
        [
          _c(
            "v-flex",
            {
              staticClass: "xs-8 mt-3",
              staticStyle: { "font-weight": "bold" }
            },
            [_vm._v(_vm._s(_vm.$t("biz.groupeditor.assignedappliances")))]
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: "AssignedAppliances::RemoveFromGroup",
                  expression: "'AssignedAppliances::RemoveFromGroup'"
                }
              ],
              attrs: {
                depressed: "",
                outline: "",
                color: "secondary",
                disabled: _vm.isRemoveDisabled()
              },
              on: {
                click: function($event) {
                  return _vm.removeAppliances()
                }
              }
            },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", style: _vm.removeIconStyle },
                [_vm._v(_vm._s(_vm.getRemoveApplianceIcon()))]
              ),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("biz.groupeditor.removeappliance")) +
                  "\n    "
              )
            ],
            1
          ),
          _c(
            "v-menu",
            {
              attrs: {
                transition: "slide-y-transition",
                "offset-y": "",
                disabled: _vm.isMoveDisabled()
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: "AssignedAppliances::MoveToGroup",
                                expression: "'AssignedAppliances::MoveToGroup'"
                              }
                            ],
                            attrs: {
                              outline: "",
                              color: "secondary",
                              disabled: _vm.isMoveDisabled()
                            }
                          },
                          on
                        ),
                        [
                          _c(
                            "v-layout",
                            { attrs: { "mr-2": "", row: "" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  style: _vm.moveIconStyle,
                                  attrs: { size: "20px", color: "secondary" }
                                },
                                [_vm._v(_vm._s(_vm.getMoveArrowIcon()))]
                              )
                            ],
                            1
                          ),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("biz.groupeditor.moveappliance")) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "mt-3 ml-2",
                      staticStyle: { "font-weight": "bold" }
                    },
                    [_vm._v(_vm._s(_vm.listTitle))]
                  ),
                  _vm._l(_vm.dropdownGroups, function(group) {
                    return _c(
                      "v-list-tile",
                      {
                        key: group.groupId,
                        staticStyle: {
                          "padding-left": "10px",
                          "padding-right": "10px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.moveToGroup(group)
                          }
                        }
                      },
                      [_c("v-list-tile-title", [_vm._v(_vm._s(group.name))])],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasAppliances(),
              expression: "!hasAppliances()"
            }
          ],
          attrs: {
            row: "",
            nowrap: "",
            "justify-space-between": "",
            "mt-3": "",
            "ml-3": ""
          }
        },
        [
          _c(
            "v-flex",
            {
              staticClass: "mt-3 xs12",
              staticStyle: { "font-weight": "bold" }
            },
            [_vm._v(_vm._s(_vm.$t("biz.groupeditor.assignedappliances")))]
          ),
          _c("v-btn", { attrs: { flat: "", disabled: true } })
        ],
        1
      ),
      _vm.hasAppliances()
        ? _c("v-data-table", {
            staticClass: "scroll-y",
            staticStyle: { height: "50vh", "margin-top": "10px" },
            attrs: {
              items: _vm.selectedGroup.appliances,
              "hide-actions": "",
              "hide-headers": ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "items",
                  fn: function(props) {
                    return [
                      _c(
                        "tr",
                        {
                          staticStyle: { border: "none" },
                          attrs: {
                            active: _vm.selectedAppliances.includes(props.item)
                          }
                        },
                        [
                          _c(
                            "td",
                            {
                              staticStyle: {
                                display: "flex, justify-content: center"
                              }
                            },
                            [
                              _c("v-checkbox", {
                                key: props.item.virtualFridgeId,
                                staticClass: "mb-1",
                                attrs: {
                                  value: props.item,
                                  "hide-details": "",
                                  color: "secondary"
                                },
                                model: {
                                  value: _vm.selectedAppliances,
                                  callback: function($$v) {
                                    _vm.selectedAppliances = $$v
                                  },
                                  expression: "selectedAppliances"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "v-flex",
                                [
                                  _c("v-list-tile-title", {
                                    domProps: {
                                      innerHTML: _vm._s(props.item.name)
                                    }
                                  }),
                                  props.item.model
                                    ? _c(
                                        "v-list-tile-title",
                                        { staticStyle: { color: "grey" } },
                                        [_vm._v(_vm._s(props.item.model))]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticStyle: { width: "100%" } },
                            [_c("v-spacer")],
                            1
                          ),
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row"
                                }
                              },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      transition: "slide-y-transition",
                                      "offset-y": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var menu = ref.on
                                            return [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var tooltip = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "track",
                                                                      rawName:
                                                                        "v-track",
                                                                      value:
                                                                        "AssignedAppliances::MoveToGroup",
                                                                      expression:
                                                                        "'AssignedAppliances::MoveToGroup'"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    disabled: _vm.isSingleMoveDisabled(),
                                                                    icon: "",
                                                                    flat: "",
                                                                    color:
                                                                      "secondary"
                                                                  }
                                                                },
                                                                Object.assign(
                                                                  {},
                                                                  tooltip,
                                                                  menu
                                                                )
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    style:
                                                                      _vm.singleMoveIconStyle,
                                                                    attrs: {
                                                                      size:
                                                                        "20px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.getMoveArrowIcon()
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "biz.groupeditor.moveappliance"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "mt-3 ml-2",
                                            staticStyle: {
                                              "font-weight": "bold"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.listTitle))]
                                        ),
                                        _vm._l(_vm.dropdownGroups, function(
                                          group
                                        ) {
                                          return _c(
                                            "v-list-tile",
                                            {
                                              key: group.groupId,
                                              staticStyle: {
                                                "padding-left": "10px",
                                                "padding-right": "10px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.singleMoveToGroup(
                                                    props.item,
                                                    group
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(_vm._s(group.name))
                                              ])
                                            ],
                                            1
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "track",
                                                        rawName: "v-track",
                                                        value:
                                                          "AssignedAppliances::RemoveFromGroup",
                                                        expression:
                                                          "'AssignedAppliances::RemoveFromGroup'"
                                                      }
                                                    ],
                                                    attrs: {
                                                      flat: "",
                                                      icon: "",
                                                      color: "secondary",
                                                      disabled: _vm.isSingleRemoveDisabled()
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      style:
                                                        _vm.singleRemoveIconStyle,
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.removeAppliance(
                                                            props.item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getRemoveApplianceIcon()
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "biz.groupeditor.removeappliance"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3210503144
            )
          })
        : _vm._e(),
      _c("EmptyGroup", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hasAppliances(),
            expression: "!hasAppliances()"
          }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }