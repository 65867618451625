var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _vm.messageHTML
        ? _c(
            "v-card",
            { staticClass: "xs12 ma-2", staticStyle: { height: "98%" } },
            [
              _c("iframe", {
                staticStyle: { border: "0" },
                attrs: {
                  width: "100%",
                  height: "100%",
                  title: "Message",
                  srcdoc: _vm.messageHTML,
                  sandbox: ""
                }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }