var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", persistent: "", lazy: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
          ]),
          _c("v-card-text", {
            staticClass: "bus_normaltext",
            domProps: { innerHTML: _vm._s(_vm.message) }
          }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("Ok", {
                attrs: { track: _vm.track },
                on: {
                  evtOk: function($event) {
                    return _vm.okPressed()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }