// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import {
  AccessCheckResponse,
  ColleagueResponse,
  ErrorDto,
  MobileNumberResponse,
  UserInfoResponse,
  UserMobileRequest,
  UserSettings
} from '../models';

export interface SetOtherUserMobileUsingPOSTRequest {
  userMobileRequest: UserMobileRequest;
}

export interface SetUserMobileUsingPOSTRequest {
  userMobileRequest: UserMobileRequest;
}

export interface StoreUserSettingsUsingPUTRequest {
  userSettings: UserSettings;
}

/**
 * no description
 */
export class UserControllerApi extends BaseAPI {
  /**
   * checkAccess
   * Checks whether the current user can access the application.
   */
  checkAccessUsingPOST = (): Observable<AccessCheckResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AccessCheckResponse>({
      path: '/access/check',
      method: 'POST',
      headers
    });
  };

  /**
   * checkUser
   * Gets the user info about the currently logged-in user. Creates the user and the organization if needed.
   */
  checkUserUsingPOST = (): Observable<UserInfoResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<UserInfoResponse>({
      path: '/user/check',
      method: 'POST',
      headers
    });
  };

  /**
   * getColleagues
   * Gets the colleagues information for a user
   */
  getColleaguesUsingGET = (): Observable<ColleagueResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ColleagueResponse>({
      path: '/user/colleagues',
      method: 'GET',
      headers
    });
  };

  /**
   * getUserSettings
   * Gets the user settings for the user specified in the UserContext.
   */
  getUserSettingsUsingGET = (): Observable<UserSettings> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<UserSettings>({
      path: '/user/settings',
      method: 'GET',
      headers
    });
  };

  /**
   * setOtherUserMobile
   * Add phone number to user profile.
   */
  setOtherUserMobileUsingPOST = (
    requestParameters: SetOtherUserMobileUsingPOSTRequest
  ): Observable<MobileNumberResponse> => {
    throwIfRequired(requestParameters, 'userMobileRequest', 'setOtherUserMobileUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<MobileNumberResponse>({
      path: '/admin/user/mobile',
      method: 'POST',
      headers,
      body: requestParameters.userMobileRequest
    });
  };

  /**
   * setUserMobile
   * Add phone number to user profile.
   */
  setUserMobileUsingPOST = (requestParameters: SetUserMobileUsingPOSTRequest): Observable<MobileNumberResponse> => {
    throwIfRequired(requestParameters, 'userMobileRequest', 'setUserMobileUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<MobileNumberResponse>({
      path: '/user/mobile',
      method: 'POST',
      headers,
      body: requestParameters.userMobileRequest
    });
  };

  /**
   * storeUserSettings
   * Stores the UserContext for the user.
   */
  storeUserSettingsUsingPUT = (requestParameters: StoreUserSettingsUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'userSettings', 'storeUserSettingsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/user/settings',
      method: 'PUT',
      headers,
      body: requestParameters.userSettings
    });
  };
}
