var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "", "pl-4": "", "pr-4": "" } },
    [
      _c("v-card-text", [
        _vm._v(_vm._s(_vm.$t("biz.general.items.statistics")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }