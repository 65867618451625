var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700px", "min-width": "600px", persistent: "" },
      model: {
        value: _vm.showWizard,
        callback: function($$v) {
          _vm.showWizard = $$v
        },
        expression: "showWizard"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "max-width": "700px", "min-width": "600px" } },
        [
          _c("BaseWindowItem", {
            attrs: { trackCancel: "AlarmProfileCreationWizard::CloseWizard" },
            on: {
              evtCancel: function($event) {
                return _vm.closeWizard()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c("div", { staticClass: "mb-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "biz.sensors.setup.measuresite.finishsetup.alarmsetting"
                          )
                        )
                      )
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "description",
                fn: function() {
                  return undefined
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "v-window",
                      {
                        model: {
                          value: _vm.step,
                          callback: function($$v) {
                            _vm.step = $$v
                          },
                          expression: "step"
                        }
                      },
                      [
                        _vm.temperatureSensor
                          ? _c(
                              "v-window-item",
                              { attrs: { value: 1 } },
                              [
                                _c("EditTemperatureAlarm", {
                                  attrs: {
                                    wizard: true,
                                    editableData: _vm.editableData
                                  },
                                  on: { evtEditableData: _vm.collectEditedData }
                                })
                              ],
                              1
                            )
                          : _c(
                              "v-window-item",
                              { attrs: { value: 1 } },
                              [
                                _c("EditDoorAlarmDelay", {
                                  attrs: {
                                    wizard: true,
                                    editableData: _vm.editableData
                                  },
                                  on: { evtEditableData: _vm.collectEditedData }
                                })
                              ],
                              1
                            ),
                        _c(
                          "v-window-item",
                          { attrs: { value: 2 } },
                          [
                            _c("EditSensorUnavailableAlarm", {
                              attrs: {
                                wizard: true,
                                editableData: _vm.editableData
                              },
                              on: { evtEditableData: _vm.collectEditedData }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-window-item",
                          { attrs: { value: 3 } },
                          [
                            _c("EditBatteryAlarmLevel", {
                              attrs: {
                                wizard: true,
                                editableData: _vm.editableData
                              },
                              on: { evtEditableData: _vm.collectEditedData }
                            }),
                            _c(
                              "div",
                              { staticClass: "ml-3" },
                              [
                                _c("p", { staticClass: "bus_normaltext" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "biz.sensors.creationwizard.editnotificationdesc"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]),
                                _c("v-checkbox", {
                                  attrs: {
                                    color: "secondary",
                                    label: _vm.$t(
                                      "biz.dashboard.alarms.alarmsettingcreate.editnotification"
                                    )
                                  },
                                  model: {
                                    value: _vm.addUserToNotifications,
                                    callback: function($$v) {
                                      _vm.addUserToNotifications = $$v
                                    },
                                    expression: "addUserToNotifications"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("BackNextMenu", {
            attrs: {
              stepCount: 3,
              step: _vm.step,
              previousDisabled: _vm.step === 1
            },
            on: {
              "update:step": function($event) {
                _vm.step = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "finalStep",
                fn: function() {
                  return [
                    _vm.step === 3
                      ? _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: _vm.track + "::CreateSensorAlarmProfile",
                                expression:
                                  "track + '::CreateSensorAlarmProfile'"
                              }
                            ],
                            attrs: { depressed: "", color: "secondary" },
                            on: {
                              click: function($event) {
                                return _vm.createSensorAlarmProfile()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("general.create")))]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }