var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    { attrs: { bottom: "" } },
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: "App::Devices",
              expression: "'App::Devices'"
            }
          ],
          staticStyle: { "margin-top": "0px", "pointer-events": "none" },
          attrs: { slot: "activator", depressed: "", icon: "" },
          slot: "activator"
        },
        [
          _c(
            "v-badge",
            {
              attrs: {
                color: "bus_red",
                overlap: "",
                "v-model": _vm.devicesShown
              }
            },
            [
              _vm.criticalDevicesCount > 0 && _vm.showIcon
                ? _c(
                    "span",
                    {
                      staticStyle: { "font-size": "12px" },
                      attrs: { slot: "badge" },
                      slot: "badge"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$format.badgeNumber(_vm.criticalDevicesCount)
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _c("v-icon", { class: _vm.getIconClass() }, [
                _vm._v("$vuetify.icons.bus_navigation_appliance")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("span", [_vm._v(_vm._s(_vm.$t("biz.general.tooltip.devices")))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }