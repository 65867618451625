var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.page !== _vm.REPORT_LIST,
            expression: "page !== REPORT_LIST"
          }
        ]
      },
      [
        _c(
          "v-btn",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.dialogPdfShown,
                expression: "!dialogPdfShown"
              },
              {
                name: "track",
                rawName: "v-track",
                value: "ReportActions::DeleteReport",
                expression: "'ReportActions::DeleteReport'"
              }
            ],
            attrs: {
              depressed: "",
              small: "",
              flat: "",
              disabled: !_vm.isAdmin()
            },
            on: {
              click: function($event) {
                return _vm.deleteReport()
              }
            }
          },
          [
            _c("v-icon", { class: _vm.iconClass }, [
              _vm._v(_vm._s(_vm.getDeleteIcon()))
            ]),
            _vm._v("\n      " + _vm._s(_vm.$t("general.delete")) + "\n    ")
          ],
          1
        ),
        _c(
          "v-btn",
          {
            directives: [
              {
                name: "track",
                rawName: "v-track",
                value: "ReportActions::PrintReport",
                expression: "'ReportActions::PrintReport'"
              }
            ],
            attrs: { depressed: "", flat: "", small: "" },
            on: {
              click: function($event) {
                return _vm.printReport()
              }
            }
          },
          [
            _c("v-icon", { class: _vm.iconClass }, [
              _vm._v(_vm._s(_vm.getPrintIcon()))
            ]),
            _vm._v("\n      " + _vm._s(_vm.$t("general.print")) + "\n    ")
          ],
          1
        ),
        _c(
          "v-btn",
          {
            directives: [
              {
                name: "track",
                rawName: "v-track",
                value: "ReportActions::DownloadReport",
                expression: "'ReportActions::DownloadReport'"
              }
            ],
            attrs: { depressed: "", flat: "", small: "" },
            on: {
              click: function($event) {
                return _vm.downloadReport()
              }
            }
          },
          [
            _c("v-icon", { class: _vm.iconClass }, [
              _vm._v(_vm._s(_vm.getDownloadIcon()))
            ]),
            _vm._v(
              "\n      PDF " + _vm._s(_vm.$t("general.download")) + "\n    "
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }