var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseWindowPage", {
        attrs: { trackCancel: _vm.track + "::Close" },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(_vm._s(_vm.$t("biz.sensors.setup.prepare_step.title")))
              ]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("div", {
                  staticClass: "font-weight-black",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("biz.sensors.setup.prepare_step.subtitle")
                    )
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "description",
            fn: function() {
              return [
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("biz.sensors.setup.prepare_step.description")
                    )
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  {
                    staticStyle: { "margin-left": "70px" },
                    attrs: { "justify-end": "" }
                  },
                  [
                    _c(
                      "v-img",
                      {
                        staticClass: "bus_img_center",
                        attrs: { src: require("@/assets/sensor_bsdb.png") }
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              slot: "placeholder",
                              "fill-height": "",
                              "align-center": "",
                              "justify-center": "",
                              "ma-0": ""
                            },
                            slot: "placeholder"
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                indeterminate: "",
                                color: "grey lighten-5"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "helplink",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: _vm.track + "::HelpSensorStartGuide",
                        expression: "track + '::HelpSensorStartGuide'"
                      }
                    ],
                    staticStyle: { "margin-left": "25px" },
                    attrs: {
                      depressed: "",
                      color: "secondary",
                      outline: "",
                      target: "_blank",
                      href: _vm.sensorManual
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "pr-1", attrs: { color: "secondary" } },
                      [_vm._v("help_outline")]
                    ),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("biz.sensors.setup.prepare_step.startguide")
                        ) +
                        "\n      "
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }