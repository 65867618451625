var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.licenseState !== _vm.LicenseState.LICENSED
    ? _c(
        "div",
        [
          _c("CardTileLayout", {
            attrs: { licensed: false, twoZone: false },
            scopedSlots: _vm._u(
              [
                {
                  key: "main",
                  fn: function() {
                    return [
                      _c("v-layout", { attrs: { row: "" } }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function($event) {
                                return _vm.toAppliance(_vm.mainApplianceZone)
                              }
                            }
                          },
                          [
                            _c("MainTileLayout", {
                              attrs: { licensed: false, hasSensors: false },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "type",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "tile",
                                            attrs: {
                                              "align-center": "",
                                              "justify-center": ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.IconHelper.getUnlicensedIcon()
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "info",
                                    fn: function() {
                                      return [
                                        _vm.licenseState ===
                                        _vm.LicenseState.LOCKED
                                          ? _c("InfoTile", {
                                              attrs: {
                                                title: _vm.getApplianceName(
                                                  _vm.mainMeasureSite
                                                ),
                                                alertText: _vm.getLockedApplianceAlert()
                                              }
                                            })
                                          : _c("InfoTile", {
                                              attrs: {
                                                title: _vm.getApplianceName(
                                                  _vm.mainMeasureSite
                                                ),
                                                alertText: _vm.getUnlicensedApplianceAlert()
                                              }
                                            })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                840846430
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "setup",
                  fn: function() {
                    return [
                      _vm.licenseState !== _vm.LicenseState.LOCKED
                        ? _c(
                            "v-card",
                            {
                              staticStyle: { height: "42px" },
                              attrs: { flat: "" },
                              on: { click: _vm.activateLicense }
                            },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "setuporactivelicense" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.vouchers.activatelicense")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1305204288
            )
          })
        ],
        1
      )
    : _c(
        "div",
        [
          _c("CardTileLayout", {
            attrs: { licensed: true, twoZone: true, hasSensors: false },
            scopedSlots: _vm._u([
              {
                key: "main",
                fn: function() {
                  return [
                    _c("v-layout", { attrs: { row: "" } }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toAppliance(_vm.mainApplianceZone)
                            }
                          }
                        },
                        [
                          _c("MainTileLayout", {
                            attrs: { licensed: true, hasSensors: false },
                            scopedSlots: _vm._u([
                              {
                                key: "type",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-flex",
                                      {
                                        class: _vm.getApplianceClass(
                                          _vm.mainApplianceZone
                                        ),
                                        attrs: {
                                          "align-center": "",
                                          "justify-center": ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.getApplianceTemperature(
                                                _vm.mainApplianceZone
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "info",
                                fn: function() {
                                  return [
                                    _c("InfoTile", {
                                      attrs: {
                                        title: _vm.getApplianceName(
                                          _vm.mainApplianceZone
                                        ),
                                        alertText: _vm.getCriticalApplianceAlert(
                                          _vm.mainApplianceZone
                                        )
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "alerticon",
                                fn: function() {
                                  return [
                                    _c("v-icon", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAlarmIcon(
                                            _vm.mainApplianceZone
                                          )
                                        )
                                      )
                                    ]),
                                    _c("span", { staticClass: "alertcount" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAlarmTypeCount(
                                            _vm.mainApplianceZone
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "twozone-divider" }),
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toAppliance(_vm.secondaryApplianceZone)
                            }
                          }
                        },
                        [
                          _c("SecondaryMainTileLayout", {
                            attrs: { hasSensors: false },
                            scopedSlots: _vm._u([
                              {
                                key: "type",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-flex",
                                      {
                                        class: _vm.getApplianceClass(
                                          _vm.secondaryApplianceZone
                                        ),
                                        staticStyle: { "margin-left": "25px" },
                                        attrs: {
                                          "align-center": "",
                                          "justify-center": ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.getApplianceTemperature(
                                                _vm.secondaryApplianceZone
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "info",
                                fn: function() {
                                  return [
                                    _c("SecondaryInfoTile", {
                                      attrs: {
                                        title: _vm.getApplianceName(
                                          _vm.secondaryApplianceZone
                                        ),
                                        alertText: _vm.getCriticalApplianceAlert(
                                          _vm.secondaryApplianceZone
                                        )
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "alerticon",
                                fn: function() {
                                  return [
                                    _c("v-icon", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAlarmIcon(
                                            _vm.secondaryApplianceZone
                                          )
                                        )
                                      )
                                    ]),
                                    _c("span", { staticClass: "alertcount" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAlarmTypeCount(
                                            _vm.secondaryApplianceZone
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }