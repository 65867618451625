var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "", height: "100%" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.cloudalarm")))
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "align-items: end", attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: {
                      track: _vm.track,
                      editDisabled: _vm.isFoodLicenseUser
                    },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs9: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.clouddescription")))
            ])
          ]),
          _c("v-flex", { attrs: { xs3: "" } }, [
            _vm.isZoneAlarmActivated()
              ? _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v("(" + _vm._s(_vm.$t("general.activated")) + ")")
                ])
              : _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v("(" + _vm._s(_vm.$t("general.deactivated")) + ")")
                ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { "pt-3": "", "justify-space-between": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" })
          ]),
          _vm.cloudAlarms.length > 1
            ? _c(
                "v-flex",
                { attrs: { xs6: "" } },
                _vm._l(_vm.cloudAlarms, function(item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      staticClass: "bus_normaltext",
                      staticStyle: { margin: "25px" },
                      attrs: { row: "", nowrap: "" }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.zone")) +
                          " " +
                          _vm._s(index + 1) +
                          "\n      "
                      )
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { "pt-3": "", "justify-space-between": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.mintemperature")))
            ])
          ]),
          _c(
            "v-flex",
            _vm._l(_vm.cloudAlarms, function(item, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "bus_normaltext",
                  staticStyle: { margin: "30px" }
                },
                [
                  item.enabled
                    ? _c("span", { staticClass: "bus_normaltext" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatTemperature(
                              item.lowerTemperatureAlarmLimit.celsius
                            )
                          )
                        )
                      ])
                    : _c("span", { staticClass: "bus_normaltext" }, [
                        _vm._v(_vm._s(_vm.SHOW_BLANK))
                      ])
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { "justify-space-between": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.maxtemperature")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs6: "" } },
            _vm._l(_vm.cloudAlarms, function(item, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "bus_normaltext",
                  staticStyle: { margin: "30px" }
                },
                [
                  item.enabled
                    ? _c("span", { staticClass: "bus_normaltext" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatTemperature(
                              item.upperTemperatureAlarmLimit.celsius
                            )
                          )
                        )
                      ])
                    : _c("span", { staticClass: "bus_normaltext" }, [
                        _vm._v(_vm._s(_vm.SHOW_BLANK))
                      ])
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-layout",
        { attrs: { "justify-space-between": "" } },
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.delaytime")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs6: "" } },
            _vm._l(_vm.cloudAlarms, function(item, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "bus_normaltext",
                  staticStyle: { margin: "30px" }
                },
                [
                  item.enabled
                    ? _c("span", { staticClass: "bus_normaltext" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$tc("general.duration.minute", item.alarmDelay)
                          )
                        )
                      ])
                    : _c("span", { staticClass: "bus_normaltext" }, [
                        _vm._v(_vm._s(_vm.SHOW_BLANK))
                      ])
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.editorShown,
            callback: function($$v) {
              _vm.editorShown = $$v
            },
            expression: "editorShown"
          }
        },
        [
          _c("EditCloudAlarms", {
            attrs: {
              dialogShown: _vm.editorShown,
              cloudAlarms: _vm.cloudAlarms,
              track: _vm.track + "::Editor"
            },
            on: { evtCancel: _vm.onCancel, evtCloudAlarmsSave: _vm.onSave },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.cloudalarm")))
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }