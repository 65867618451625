var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.dialogShown, persistent: "", "max-width": "700" } },
    [
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { "mb-3": "", row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { "mt-3": "" } },
                    [
                      _c("v-layout", { staticClass: "title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("biz.onboarding.bsdbdialog.bsdbnotfound")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "BsdbNotFoundDialog::Close",
                          expression: "'BsdbNotFoundDialog::Close'"
                        }
                      ],
                      attrs: { depressed: "", icon: "" },
                      on: { click: _vm.close }
                    },
                    [_c("v-icon", [_vm._v("$vuetify.icons.bus_close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-3", attrs: { flat: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "fill-height": "", column: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { "mt-3": "" } },
                        [
                          _c("p", {
                            staticClass: "bus_normaltext",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("biz.onboarding.bsdbdialog.text") +
                                  _vm.$t("biz.onboarding.bsdbdialog.point1") +
                                  _vm.$t("biz.onboarding.bsdbdialog.point2") +
                                  _vm.$t("biz.onboarding.bsdbdialog.point3") +
                                  _vm.$t("biz.onboarding.bsdbdialog.point4")
                              )
                            }
                          }),
                          _c("p", {
                            staticClass: "bus_normaltext",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("biz.onboarding.bsdbdialog.text1")
                              )
                            }
                          }),
                          _c(
                            "v-flex",
                            { attrs: { "offset-xs1": "", xs10: "" } },
                            [
                              _c("v-img", {
                                staticClass: "mt-5",
                                attrs: {
                                  src: require("@/assets/onboarding-error-1.png"),
                                  contain: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }