var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "", height: "100%" } },
    [
      !_vm.dataReady
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "65vh" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.loading")))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady && !_vm.profileDetails.alarmProfileId
        ? _c(
            "v-layout",
            { attrs: { column: "", "mt-4": "" } },
            [
              _c("p", { staticClass: "ml-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "biz.dashboard.alarms.alarmsettingcreate.explanation"
                    )
                  )
                )
              ]),
              _c(
                "v-flex",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        color: "primary",
                        track: _vm.track + "::ShowCreationWizard"
                      },
                      on: {
                        click: function($event) {
                          return _vm.showCreationWizard()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.create")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady && _vm.profileDetails.alarmProfileId
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs10: "", "pl-4": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "bus_propertytitle" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.dashboard.alarms.devicealarm")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("p", {
                              staticClass: "bus_normaltext",
                              domProps: {
                                innerHTML: _vm._s(_vm.deviceAlarmDescription)
                              }
                            })
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { "pt-3": "" } },
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("biz.dashboard.alarms.settemperature")
                                )
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(
                                _vm._s(_vm.profileDetails.setTemperature) + "°C"
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm.selectedFridgeType ===
                      _vm.MeasureSiteMeasureSiteTypeEnum.MODBUSFRIDGE
                        ? _c(
                            "v-layout",
                            [
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("p", { staticClass: "bus_normaltext" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "biz.dashboard.alarms.mintemperature"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("p", { staticClass: "bus_normaltext" }, [
                                  _vm._v(
                                    _vm._s(_vm.profileDetails.minLimit) + "°C"
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectedFridgeType ===
                      _vm.MeasureSiteMeasureSiteTypeEnum.MODBUSFRIDGE
                        ? _c(
                            "v-layout",
                            [
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("p", { staticClass: "bus_normaltext" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "biz.dashboard.alarms.maxtemperature"
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("p", { staticClass: "bus_normaltext" }, [
                                  _vm._v(
                                    _vm._s(_vm.profileDetails.maxLimit) + "°C"
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectedFridgeType ===
                      _vm.MeasureSiteMeasureSiteTypeEnum.MODBUSFRIDGE
                        ? _c(
                            "v-layout",
                            [
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("p", { staticClass: "bus_normaltext" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.dashboard.alarms.delaytime")
                                    )
                                  )
                                ])
                              ]),
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("p", { staticClass: "bus_normaltext" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tc(
                                        "general.duration.minute",
                                        _vm.profileDetails.deviceAlarmDelay
                                      )
                                    )
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      !_vm.isFoodLicenseUser
                        ? _c(
                            "v-flex",
                            { staticStyle: { "padding-top": "52px" } },
                            [
                              _c("CloudAlarm", {
                                attrs: {
                                  editableData: _vm.editableData,
                                  profileDetails: _vm.profileDetails,
                                  track: _vm.track + "::CloudAlarm"
                                },
                                on: { evtSave: _vm.saveChanges }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      staticStyle: { "padding-top": "36px" },
                      attrs: { column: "" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "bus_propertytitle" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.dashboard.alarms.systemalarm")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "biz.dashboard.alarms.systemalarmdescription"
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { xs9: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("biz.dashboard.alarms.deviceheartbeat")
                                )
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs3: "" } }, [
                            _vm.profileDetails.gatewayAlarmActivated
                              ? _c("p", { staticClass: "bus_normaltext" }, [
                                  _vm._v(
                                    "(" +
                                      _vm._s(_vm.$t("general.activated")) +
                                      ")"
                                  )
                                ])
                              : _c("p", { staticClass: "bus_normaltext" }, [
                                  _vm._v(
                                    "(" +
                                      _vm._s(_vm.$t("general.deactivated")) +
                                      ")"
                                  )
                                ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { attrs: { "pt-3": "" } },
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(_vm._s(_vm.profileDetails.gatewayName))
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(
                                _vm._s(_vm.$t("biz.dashboard.alarms.delaytime"))
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("p", { staticClass: "bus_normaltext" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$tc(
                                    "general.duration.minute",
                                    _vm.profileDetails.gatewayAlarmDelay
                                  )
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { staticStyle: { "margin-bottom": "8px" } },
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: _vm.track + "::ToSCHAlarmSettings",
                                  expression: "track + '::ToSCHAlarmSettings'"
                                }
                              ],
                              attrs: { depressed: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.gotoSCHAlarmSettings()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("biz.dashboard.alarms.alarmsettings")
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { staticStyle: { "padding-top": "52px" } },
                        [
                          _c("RepetitionPeriod", {
                            attrs: {
                              editableData: _vm.editableData,
                              profileDetails: _vm.profileDetails,
                              track: _vm.track + "::RepetitionPeriod"
                            },
                            on: { evtSave: _vm.saveChanges }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { staticStyle: { "padding-top": "42px" } },
                        [
                          _c("AlarmNotification", {
                            attrs: {
                              allUsers: _vm.allUsers,
                              editableData: _vm.editableData,
                              virtualFridgeId:
                                _vm.profileDetails.virtualFridgeId,
                              track: _vm.track + "::AlarmNotification"
                            },
                            on: { evtSave: _vm.saveChanges }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("AlarmProfileCreationWizard", {
        attrs: {
          showWizard: _vm.creationWizardShown,
          virtualFridgeId: _vm.virtualFridgeId,
          gatewayId: _vm.gatewayId,
          track: _vm.track + "::ApplianceAlarmProfileCreationWizard"
        },
        on: {
          evtCloseWizard: function($event) {
            _vm.creationWizardShown = false
          },
          evtApplianceProfileCreated: function($event) {
            return _vm.refreshAlarmProfiles()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }