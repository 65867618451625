var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-flex",
        { staticClass: "bus_normaltext", attrs: { "mb-2": "", "pl-0": "" } },
        [_vm._v(_vm._s(_vm.$t("biz.onboarding.stepper.foundappliance")))]
      ),
      _vm.applianceInfo.articleNumber
        ? _c(
            "v-layout",
            { attrs: { "fill-height": "", row: "" } },
            [
              _c(
                "v-img",
                { attrs: { src: _vm.pictureUrl, contain: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        slot: "placeholder",
                        "fill-height": "",
                        "ma-0": ""
                      },
                      slot: "placeholder"
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "secondary" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "applianceinfo",
                  attrs: { "fill-height": "", xs7: "" }
                },
                [
                  _c(
                    "v-layout",
                    {
                      staticStyle: { "row-gap": "1rem" },
                      attrs: { "fill-height": "", column: "" }
                    },
                    [
                      _c("div", { staticClass: "applianceinfoitem" }, [
                        _c("div", { staticClass: "bus_subheading2" }, [
                          _vm._v(_vm._s(_vm.$t("biz.general.items.model")))
                        ]),
                        _c("div", { staticClass: "bus_normaltext" }, [
                          _vm._v(_vm._s(_vm.modelDescription))
                        ])
                      ]),
                      _c("div", { staticClass: "applianceinfoitem" }, [
                        _c("div", { staticClass: "bus_subheading2" }, [
                          _vm._v(_vm._s(_vm.$t("biz.general.items.serial")))
                        ]),
                        _c("div", { staticClass: "bus_normaltext" }, [
                          _vm._v(_vm._s(_vm.serialNumber))
                        ])
                      ]),
                      _c("div", { staticClass: "applianceinfoitem" }, [
                        _c("div", { staticClass: "bus_subheading2" }, [
                          _vm._v(_vm._s(_vm.$t("biz.general.items.article")))
                        ]),
                        _c("div", { staticClass: "bus_normaltext" }, [
                          _vm._v(_vm._s(_vm.articleNumber))
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t(
                    "biz.onboarding.binderscan.applianceinfo.cannotfindappliance"
                  )
                ) +
                "\n  "
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }