var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showType
    ? _c(
        "v-layout",
        { attrs: { column: "", "align-start": "" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "shorttext",
                  rawName: "v-shorttext",
                  value: 260,
                  expression: "260"
                }
              ],
              staticClass: "smartmodule-tilename"
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _c(
            "v-flex",
            {
              staticClass: "smartmodule-alerttext",
              attrs: { "align-start": "", "pl-0": "", "pr-0": "" }
            },
            [_vm._v(_vm._s(_vm.alertText))]
          ),
          _c(
            "v-flex",
            {
              staticClass: "smartmodule-appliancestext",
              attrs: { "align-start": "", "pl-0": "", "pr-0": "" }
            },
            [_vm._v(_vm._s(_vm.applianceText))]
          ),
          _c(
            "v-flex",
            {
              staticClass: "smartmodule-sensorstext",
              attrs: { "align-start": "", "pl-0": "", "pr-0": "" }
            },
            [_vm._v(_vm._s(_vm.sensorText))]
          )
        ],
        1
      )
    : _c(
        "v-layout",
        { attrs: { column: "", "align-start": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "tilename", attrs: { "align-start": "" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "shorttext",
                      rawName: "v-shorttext",
                      value: 160,
                      expression: "160"
                    }
                  ]
                },
                [_vm._v(_vm._s(_vm.title))]
              )
            ]
          ),
          _c(
            "v-flex",
            { staticClass: "alerttext", attrs: { "align-start": "" } },
            [_vm._v(_vm._s(_vm.alertText))]
          ),
          _c(
            "v-flex",
            { staticClass: "appliancestext", attrs: { "align-start": "" } },
            [_vm._v(_vm._s(_vm.applianceText))]
          ),
          _c(
            "v-flex",
            { staticClass: "sensorstext", attrs: { "align-start": "" } },
            [_vm._v(_vm._s(_vm.sensorText))]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }