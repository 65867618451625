var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { attrs: { id: "layout", "fill-height": "", column: "" } },
            [
              _c("BaseWindowItem", {
                attrs: { trackCancel: "track + '::Cancel'" },
                on: {
                  evtCancel: function($event) {
                    return _vm.close()
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _vm._v(
                          _vm._s(_vm.$t("biz.help.customerfeedback.title"))
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "description",
                    fn: function() {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("biz.help.customerfeedback.text")
                            )
                          }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "v-form",
                          {
                            ref: "feedbackform",
                            model: {
                              value: _vm.valid,
                              callback: function($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid"
                            }
                          },
                          [
                            _c("v-select", {
                              staticClass: "default selected errorborder",
                              staticStyle: { "font-size": "14px" },
                              attrs: {
                                chips: "",
                                required: "",
                                rules: _vm.textRules,
                                "item-value": "name",
                                items: _vm.feedbackTypes,
                                "menu-props": { bottom: true, offsetY: true },
                                "item-text": "name",
                                placeholder: _vm.$t(
                                  "general.communication.subject"
                                ),
                                "full-width": ""
                              },
                              model: {
                                value: _vm.subject,
                                callback: function($$v) {
                                  _vm.subject = $$v
                                },
                                expression: "subject"
                              }
                            }),
                            _c("v-textarea", {
                              staticClass:
                                "defaulttextarea selectedtextarea errorbordertextarea",
                              staticStyle: { "font-size": "14px" },
                              attrs: {
                                clearable: _vm.iconHelper.clearableIcon(
                                  _vm.message
                                ),
                                rules: _vm.textRules,
                                placeholder: _vm.$t(
                                  "general.communication.message"
                                ),
                                required: ""
                              },
                              model: {
                                value: _vm.message,
                                callback: function($$v) {
                                  _vm.message = $$v
                                },
                                expression: "message"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end pl-4 pr-4 pb-4" },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { "margin-left": "0px" },
                  attrs: {
                    depressed: "",
                    color: "primary",
                    "v-track": _vm.track + "::SendFeedback"
                  },
                  on: {
                    click: function($event) {
                      return _vm.sendFeedbackRequest()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("general.send")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }