var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { slot: "header", "py-3": "" }, slot: "header" },
        [
          _c(
            "v-layout",
            { staticStyle: { "align-self": "center" }, attrs: { xs4: "" } },
            [
              _c("v-layout", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.main.help")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-container",
        { attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" } },
        [
          _c("CriticalDevicesPage", {
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "v-layout",
                      { attrs: { row: "", wrap: "" } },
                      [
                        _c("CardTileBase", {
                          attrs: { cardHeight: "420" },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function() {
                                return [_vm._v(_vm._s(_vm.getServiceIcon()))]
                              },
                              proxy: true
                            },
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.help.servicedetails.title")
                                    )
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "text",
                              fn: function() {
                                return [
                                  _vm._v(_vm._s(_vm.$t("biz.help.servicetext")))
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "button",
                              fn: function() {
                                return [
                                  _vm.installationServiceRequestButton
                                    ? _c(
                                        "v-btn",
                                        {
                                          directives: [
                                            {
                                              name: "track",
                                              rawName: "v-track",
                                              value:
                                                "HelpAndSupport::InstallationServiceRequest",
                                              expression:
                                                "'HelpAndSupport::InstallationServiceRequest'"
                                            }
                                          ],
                                          staticStyle: { "max-height": "94px" },
                                          attrs: {
                                            depressed: "",
                                            color: "primary",
                                            href: _vm.landingPageLink,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "biz.help.servicedetails.installationservice"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value:
                                            "HelpAndSupport::StartServiceRequest",
                                          expression:
                                            "'HelpAndSupport::StartServiceRequest'"
                                        }
                                      ],
                                      staticStyle: { "max-height": "94px" },
                                      attrs: {
                                        depressed: "",
                                        color: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.switchDocumentState(
                                            "Service"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "biz.help.servicedetails.start"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        }),
                        _c("CardTileBase", {
                          attrs: { cardHeight: "420" },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function() {
                                return [_vm._v(_vm._s(_vm.getDocIcon()))]
                              },
                              proxy: true
                            },
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _vm._v(
                                    _vm._s(_vm.$t("biz.main.documentation"))
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "text",
                              fn: function() {
                                return [
                                  _vm._v(_vm._s(_vm.$t("biz.help.doctext")))
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "button",
                              fn: function() {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value:
                                            "HelpAndSupport::SmartCoolingHubStartGuideOpen",
                                          expression:
                                            "'HelpAndSupport::SmartCoolingHubStartGuideOpen'"
                                        }
                                      ],
                                      staticStyle: {
                                        "max-height": "94px",
                                        "margin-top": "-20px"
                                      },
                                      attrs: {
                                        depressed: "",
                                        color: "secondary",
                                        outline: "",
                                        target: "_blank",
                                        href: _vm.smartCoolingHubManual
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          staticStyle: {
                                            "margin-left": "-12px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getRightArrowIcon())
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "biz.onboarding.welcome.setup.bsdbManual"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value:
                                            "HelpAndSupport::SmartMonitoringStartGuideOpen",
                                          expression:
                                            "'HelpAndSupport::SmartMonitoringStartGuideOpen'"
                                        }
                                      ],
                                      staticStyle: { "max-height": "94px" },
                                      attrs: {
                                        depressed: "",
                                        color: "secondary",
                                        outline: "",
                                        target: "_blank",
                                        href: _vm.smartMonitoringManual
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          staticStyle: {
                                            "margin-left": "-12px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getRightArrowIcon())
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "biz.help.smartmonitoringmanual"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value:
                                            "HelpAndSupport::SmartModuleGuideOpen",
                                          expression:
                                            "'HelpAndSupport::SmartModuleGuideOpen'"
                                        }
                                      ],
                                      staticStyle: { "max-height": "94px" },
                                      attrs: {
                                        depressed: "",
                                        color: "secondary",
                                        outline: "",
                                        target: "_blank",
                                        href: _vm.smartModuleManual
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          staticStyle: {
                                            "margin-left": "-12px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getRightArrowIcon())
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("biz.help.smartmodulemanual")
                                          ) +
                                          "\n              "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        }),
                        _c("CardTileBase", {
                          attrs: { cardHeight: "420" },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function() {
                                return [_vm._v(_vm._s(_vm.getFaqIcon()))]
                              },
                              proxy: true
                            },
                            {
                              key: "title",
                              fn: function() {
                                return [_vm._v(_vm._s(_vm.$t("biz.help.faq")))]
                              },
                              proxy: true
                            },
                            {
                              key: "text",
                              fn: function() {
                                return [
                                  _vm._v(_vm._s(_vm.$t("biz.help.faqtext")))
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "button",
                              fn: function() {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value: "HelpAndSupport::ShowFAQs",
                                          expression:
                                            "'HelpAndSupport::ShowFAQs'"
                                        }
                                      ],
                                      staticStyle: { "max-height": "94px" },
                                      attrs: {
                                        depressed: "",
                                        color: "secondary",
                                        outline: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.switchDocumentState("FAQs")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("biz.help.tothefaqs"))
                                      )
                                    ]
                                  ),
                                  _c("br"),
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value: "HelpAndSupport::ShowFeedback",
                                          expression:
                                            "'HelpAndSupport::ShowFeedback'"
                                        }
                                      ],
                                      attrs: {
                                        depressed: "",
                                        outline: "",
                                        color: "secondary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showFeedback()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "biz.help.customerfeedback.buttontext"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        }),
                        _c("CardTileBase", {
                          attrs: { cardHeight: "420" },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function() {
                                return [_vm._v("$vuetify.icons.bus_video")]
                              },
                              proxy: true
                            },
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _vm._v(
                                    _vm._s(_vm.$t("biz.help.tutorialvideos"))
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "text",
                              fn: function() {
                                return [
                                  _vm._v(_vm._s(_vm.$t("biz.help.videotext")))
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "button",
                              fn: function() {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value:
                                            "HelpAndSupport::StartWebBasedTraining",
                                          expression:
                                            "'HelpAndSupport::StartWebBasedTraining'"
                                        }
                                      ],
                                      staticStyle: { "max-height": "94px" },
                                      attrs: {
                                        depressed: "",
                                        color: "secondary",
                                        outline: "",
                                        href: _vm.openVideosApp(),
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          staticStyle: {
                                            "margin-left": "-12px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.getRightArrowIcon())
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("biz.help.watchvideos")
                                          ) +
                                          "\n              "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        }),
                        _c("CardTileBase", {
                          attrs: { cardHeight: "420" },
                          scopedSlots: _vm._u([
                            {
                              key: "icon",
                              fn: function() {
                                return [_vm._v(_vm._s(_vm.getDocIcon()))]
                              },
                              proxy: true
                            },
                            {
                              key: "title",
                              fn: function() {
                                return [
                                  _vm._v(_vm._s(_vm.$t("biz.help.legalsite")))
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "text",
                              fn: function() {
                                return [
                                  _vm._v(
                                    _vm._s(_vm.$t("biz.help.legalsitetext"))
                                  )
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "button",
                              fn: function() {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value:
                                            "HelpAndSupport::ShowPrivacyPolicy",
                                          expression:
                                            "'HelpAndSupport::ShowPrivacyPolicy'"
                                        }
                                      ],
                                      staticStyle: {
                                        "max-height": "94px",
                                        "margin-top": "-20px",
                                        position: "absolute"
                                      },
                                      attrs: {
                                        depressed: "",
                                        color: "secondary",
                                        outline: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.showPrivacyPolicyDialog = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("biz.help.privacypolicy"))
                                      )
                                    ]
                                  ),
                                  _c("br"),
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value:
                                            "HelpAndSupport::ShowTermsOfUse",
                                          expression:
                                            "'HelpAndSupport::ShowTermsOfUse'"
                                        }
                                      ],
                                      staticStyle: { "max-height": "94px" },
                                      attrs: {
                                        depressed: "",
                                        color: "secondary",
                                        outline: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.showTermsOfUseDialog = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("biz.help.termsofuse"))
                                      )
                                    ]
                                  ),
                                  _c("br"),
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value:
                                            "HelpAndSupport::ShowLicenseConditions",
                                          expression:
                                            "'HelpAndSupport::ShowLicenseConditions'"
                                        }
                                      ],
                                      staticStyle: { "max-height": "94px" },
                                      attrs: {
                                        depressed: "",
                                        color: "secondary",
                                        outline: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.showLicenseConditionsDialog = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("biz.help.licenseconditions")
                                        )
                                      )
                                    ]
                                  ),
                                  _c("br"),
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "track",
                                          rawName: "v-track",
                                          value:
                                            "HelpAndSupport::ShowSoftwareLicenses",
                                          expression:
                                            "'HelpAndSupport::ShowSoftwareLicenses'"
                                        }
                                      ],
                                      staticStyle: { "max-height": "94px" },
                                      attrs: {
                                        depressed: "",
                                        color: "secondary",
                                        outline: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.showLicenseDialog = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "biz.help.softwarelicense.licensestitle"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        }),
                        _c("FeedbackDialog", {
                          attrs: {
                            dialogShown: _vm.showFeedbackDialog,
                            track: "HelpAndSupport::FeedbackDialog"
                          },
                          on: {
                            evtFeedbackDialogClose: function($event) {
                              _vm.showFeedbackDialog = false
                            }
                          }
                        }),
                        _c("ServiceDialog", {
                          attrs: {
                            dialogShown: _vm.showServiceDialog,
                            track: "HelpAndSupport::ServiceRequest"
                          },
                          on: {
                            "update:dialogShown": function($event) {
                              _vm.showServiceDialog = $event
                            },
                            "update:dialog-shown": function($event) {
                              _vm.showServiceDialog = $event
                            },
                            evtServiceDialogClose: function($event) {
                              return _vm.onServiceDialogClose()
                            }
                          }
                        }),
                        _c("TermsOfUseDialog", {
                          attrs: { dialogShown: _vm.showTermsOfUseDialog },
                          on: {
                            evtClose: function($event) {
                              _vm.showTermsOfUseDialog = false
                            }
                          }
                        }),
                        _c("LicenseConditionsDialog", {
                          attrs: {
                            dialogShown: _vm.showLicenseConditionsDialog
                          },
                          on: {
                            evtClose: function($event) {
                              _vm.showLicenseConditionsDialog = false
                            }
                          }
                        }),
                        _c("PrivacyPolicyDialog", {
                          attrs: { dialogShown: _vm.showPrivacyPolicyDialog },
                          on: {
                            evtClose: function($event) {
                              _vm.showPrivacyPolicyDialog = false
                            }
                          }
                        }),
                        _c("LicensesDialog", {
                          attrs: { dialogShown: _vm.showLicenseDialog },
                          on: {
                            evtClose: function($event) {
                              _vm.showLicenseDialog = false
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }