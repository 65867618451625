// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SmartModuleRequest
 */
export interface SmartModuleRequest {
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  building?: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  comment?: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  countryIsoCode?: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  department?: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  inventoryNumber?: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  ipAddress: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  level?: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  macAddress: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  manufacturer?: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  model?: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  name: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  roomNumber?: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  serialNumber: string;
  /**
   * @type {string}
   * @memberof SmartModuleRequest
   */
  timezone: string;
}
