var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700px", "min-width": "600px" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c("BaseWindowItem", {
        attrs: { trackCancel: _vm.track + "::Close" },
        on: {
          evtCancel: function($event) {
            return _vm.closeDialog()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(_vm.$t("biz.sensors.setup.properties.addmeasuresite"))
                )
              ]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("div", { staticClass: "font-weight-black" }, [
                  _vm._v(
                    _vm._s(_vm.$t("biz.sensors.setup.measuresite.subtitle"))
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "description",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(_vm.$t("biz.sensors.setup.measuresite.description"))
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  { staticClass: "pl-2 mb-4", attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      "v-card",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: _vm.track + "::MeasureSiteRoom",
                            expression: "track + '::MeasureSiteRoom'"
                          }
                        ],
                        staticClass: "tilecard",
                        on: {
                          click: function($event) {
                            return _vm.openMeasureSiteRoom()
                          }
                        }
                      },
                      [
                        _c(
                          "v-container",
                          { attrs: { "grid-list-lg": "" } },
                          [
                            _c("v-flex", [
                              _c(
                                "h3",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "ml-2 mt-3 mr-2" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.IconHelper.getRoomWhiteSmallIcon()
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-flex",
                                        { staticStyle: { color: "white" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "biz.sensors.setup.measuresite.card1.title"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _c(
                              "v-flex",
                              {
                                staticStyle: {
                                  filter: "invert(1)",
                                  "margin-top": "20px",
                                  "margin-bottom": "20px"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "biz.sensors.setup.measuresite.card1.description"
                                    )
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: _vm.track + "::MeasureSiteLiebherrAppliance",
                            expression:
                              "track + '::MeasureSiteLiebherrAppliance'"
                          }
                        ],
                        staticClass: "tilecard",
                        on: {
                          click: function($event) {
                            return _vm.openMeasureSiteLiebherrAppliance()
                          }
                        }
                      },
                      [
                        _c(
                          "v-container",
                          { attrs: { "grid-list-lg": "" } },
                          [
                            _c("v-flex", [
                              _c(
                                "h3",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mt-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.IconHelper.getApplianceWhiteIcon()
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { staticStyle: { color: "white" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "biz.sensors.setup.measuresite.card2.title"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _c(
                              "v-flex",
                              {
                                staticStyle: {
                                  filter: "invert(1)",
                                  "margin-top": "20px",
                                  "margin-bottom": "20px"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "biz.sensors.setup.measuresite.card2.description"
                                    )
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: _vm.track + "::MeasureSiteExternalAppliance",
                            expression:
                              "track + '::MeasureSiteExternalAppliance'"
                          }
                        ],
                        staticClass: "tilecard",
                        on: {
                          click: function($event) {
                            return _vm.openMeasureSiteExternalAppliance()
                          }
                        }
                      },
                      [
                        _c(
                          "v-container",
                          { attrs: { "grid-list-lg": "" } },
                          [
                            _c("v-flex", [
                              _c(
                                "h3",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mt-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.IconHelper.getApplianceWhiteIcon()
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { staticStyle: { color: "white" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "biz.sensors.setup.measuresite.card3.title"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]),
                            _c(
                              "v-flex",
                              {
                                staticStyle: {
                                  filter: "invert(1)",
                                  "margin-top": "20px",
                                  "margin-bottom": "20px"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "biz.sensors.setup.measuresite.card3.description"
                                    )
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("MeasureSiteRoom", {
        attrs: { openRoomDialog: _vm.openRoomDialog },
        on: {
          evtCancel: function($event) {
            _vm.openRoomDialog = false
          },
          evtSave: _vm.measureSiteCreated
        }
      }),
      _c("MeasureSiteLiebherrAppliance", {
        attrs: { openLiebherrApplianceDialog: _vm.openLiebherrApplianceDialog },
        on: {
          evtCancel: function($event) {
            _vm.openLiebherrApplianceDialog = false
          },
          evtSave: _vm.measureSiteCreated
        }
      }),
      _c("MeasureSiteExternalAppliance", {
        attrs: { openExternalApplianceDialog: _vm.openExternalApplianceDialog },
        on: {
          evtCancel: function($event) {
            _vm.openExternalApplianceDialog = false
          },
          evtSave: _vm.measureSiteCreated
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }