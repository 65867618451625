var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "v-layout",
        { attrs: { column: "", "align-center": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "text-xs-center", attrs: { xs10: "" } },
            [
              _c("v-progress-linear", {
                attrs: {
                  "buffer-value": 100,
                  height: 10,
                  value: _vm.progressValue,
                  color: "white"
                }
              }),
              _c("p", {
                staticClass: "ma-3",
                staticStyle: { color: "white", "text-align": "center" },
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("biz.onboarding.start.smartmoduleestablishing")
                  )
                }
              })
            ],
            1
          ),
          _c("SmartModuleErrorDialog", {
            attrs: {
              dialogShown: _vm.errorShown,
              errorType: _vm.errorType,
              serialNumber: _vm.serialNumber,
              onboardingFlow: _vm.onboardingFlow,
              track: "SmartModuleLanding::OnboardingError::" + _vm.errorType
            },
            on: {
              evtClose: function($event) {
                return _vm.closeErrorDialog()
              },
              evtRetry: function($event) {
                return _vm.retryPressed()
              }
            }
          })
        ],
        1
      )
    : _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "" } },
        [
          _c("SmartModuleSetup", {
            attrs: {
              serialNumber: _vm.serialNumber,
              smartModuleConnectionResponse: _vm.smartModuleConnectionResponse,
              onboardingFlow: _vm.onboardingFlow,
              initialStep: _vm.step
            },
            on: {
              evtContinue: function($event) {
                return _vm.sendOnboardingRequest()
              },
              evtRetry: function($event) {
                return _vm.scanSmartModule()
              }
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }