import { render, staticRenderFns } from "./Unavailability.vue?vue&type=template&id=72213cdc&"
import script from "./Unavailability.ts?vue&type=script&lang=ts&"
export * from "./Unavailability.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/hau-bus-ui_master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72213cdc')) {
      api.createRecord('72213cdc', component.options)
    } else {
      api.reload('72213cdc', component.options)
    }
    module.hot.accept("./Unavailability.vue?vue&type=template&id=72213cdc&", function () {
      api.rerender('72213cdc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/alarms/unavailability/Unavailability.vue"
export default component.exports