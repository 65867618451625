var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "22px",
        height: "23px",
        viewBox: "0 0 22 23",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              staticStyle: { fill: "var(--v-secondary-base)" },
              attrs: { transform: "translate(-547.000000, -687.000000)" }
            },
            [
              _c(
                "g",
                { attrs: { transform: "translate(520.000000, 495.000000)" } },
                [
                  _c(
                    "g",
                    {
                      attrs: { transform: "translate(16.000000, 183.000000)" }
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: { transform: "translate(8.000000, 6.000000)" }
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                transform: "translate(2.000000, 2.000000)"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M17,1 C18.1045695,1 19,1.8954305 19,3 L19,6 L21,6 C22.1045695,6 23,6.8954305 23,8 L23,15 C23,16.1045695 22.1045695,17 21,17 L19,17 L19,22 C19,23.1045695 18.1045695,24 17,24 L7,24 C5.8954305,24 5,23.1045695 5,22 L5,17 L3,17 C1.8954305,17 1,16.1045695 1,15 L1,8 C1,6.8954305 1.8954305,6 3,6 L5,6 L5,3 C5,1.8954305 5.8954305,1 7,1 L17,1 Z M17,15 L7,15 L7,22 L17,22 L17,15 Z M21,8 L3,8 L3,15 L5,15 L5,13 L19,13 L19,15 L21,15 L21,8 Z M17.5,9 C18.3284271,9 19,9.67157288 19,10.5 C19,11.3284271 18.3284271,12 17.5,12 C16.6715729,12 16,11.3284271 16,10.5 C16,9.67157288 16.6715729,9 17.5,9 Z M17,3 L7,3 L7,6 L17,6 L17,3 Z"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }