var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "460px", persistent: "", lazy: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { height: "250px" } },
        [
          _c(
            "v-layout",
            { attrs: { "mb-3": "", row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { "mt-3": "", "ml-3": "" } },
                [
                  _c("v-layout", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("general.contactinfo.title")))
                  ])
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::Close",
                      expression: "track + '::Close'"
                    }
                  ],
                  attrs: { depressed: "", icon: "" },
                  on: { click: _vm.close }
                },
                [_c("v-icon", [_vm._v("$vuetify.icons.bus_close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { "pt-3": "", "ml-3": "" } },
            [_c("ContactInfo")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }