var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-bus_white-base)" },
            attrs: {
              d:
                "M12,2.44300047 C12.119912,2.44300047 12.2388464,2.46456739 12.3511234,2.50667129 L18.3511234,4.75667129 C18.7414272,4.90303519 19,5.27615591 19,5.69300047 L19,18.3069995 C19,18.7238441 18.7414272,19.0969648 18.3511234,19.2433287 L12.3511234,21.4933287 C11.8340031,21.6872488 11.2575909,21.4252433 11.0636708,20.908123 C11.0215669,20.7958459 11,20.6769115 11,20.5569995 L11,18.9990005 L6,19 C5.44771525,19 5,18.5522847 5,18 L5,6 C5,5.44771525 5.44771525,5 6,5 L11,4.99900047 L11,3.44300047 C11,2.89071572 11.4477153,2.44300047 12,2.44300047 Z M12,3.44300047 L12,20.5569995 L18,18.3069995 L18,5.69300047 L12,3.44300047 Z M13.5,10 C13.7761424,10 14,10.2238576 14,10.5 L14,13.5 C14,13.7761424 13.7761424,14 13.5,14 C13.2238576,14 13,13.7761424 13,13.5 L13,10.5 C13,10.2238576 13.2238576,10 13.5,10 Z"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }