var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px", persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _vm.versionHasChanged
              ? _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("general.acceptance.changedtermsofuse")))
                ])
              : _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("biz.help.termsofuse")))
                ])
          ]),
          _c("v-card-text", { staticClass: "bus_normaltext" }, [
            _vm._v(_vm._s(_vm.$t("general.acceptance.termsofuse")))
          ]),
          _c(
            "v-card-text",
            { staticClass: "bus_normaltext" },
            [
              _c(
                "v-layout",
                {
                  staticStyle: { height: "300px" },
                  attrs: { row: "", "ml-3": "" }
                },
                [
                  _c("iframe", {
                    staticStyle: { width: "100%", height: "100%", border: "0" },
                    attrs: {
                      src: _vm.localizedFileName,
                      title: _vm.$t("biz.help.termsofuse"),
                      sandbox: "allow-top-navigation allow-scripts allow-popups"
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "bus_normaltext" }, [
            _vm._v(_vm._s(_vm.$t("general.acceptance.acceptancemandatory")))
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::RejectTerms",
                      expression: "track + '::RejectTerms'"
                    }
                  ],
                  attrs: { color: "secondary", outline: "" },
                  on: {
                    click: function($event) {
                      return _vm.rejectTerms()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("general.acceptance.reject")))]
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::AcceptTerms",
                      expression: "track + '::AcceptTerms'"
                    }
                  ],
                  attrs: { depressed: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.acceptTerms()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("general.acceptance.accept")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }