// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface MeasureSiteRoomRequest
 */
export interface MeasureSiteRoomRequest {
  /**
   * @type {string}
   * @memberof MeasureSiteRoomRequest
   */
  building?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteRoomRequest
   */
  comment?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteRoomRequest
   */
  department?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteRoomRequest
   */
  level?: string;
  /**
   * @type {string}
   * @memberof MeasureSiteRoomRequest
   */
  name: string;
  /**
   * @type {string}
   * @memberof MeasureSiteRoomRequest
   */
  roomNumber?: string;
}
