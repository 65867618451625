var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "pa-3": "" } },
    [
      _c("v-checkbox", {
        attrs: {
          color: "secondary",
          label: _vm.$t("biz.dashboard.alarms.reminder.activecheck")
        },
        model: {
          value: _vm.editableData.repetitionEnabled,
          callback: function($$v) {
            _vm.$set(_vm.editableData, "repetitionEnabled", $$v)
          },
          expression: "editableData.repetitionEnabled"
        }
      }),
      _c("label", { staticStyle: { "font-size": "12px", color: "gray" } }, [
        _vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.reminder.reminderperiod")))
      ]),
      _c("BasicSlider", {
        attrs: {
          sliderValue: _vm.editableData.repetitionTime,
          active: _vm.editableData.repetitionEnabled,
          minValue: _vm.minRepetitionTime,
          maxValue: _vm.maxRepetitionTime,
          step: 1
        },
        on: {
          "update:sliderValue": function($event) {
            return _vm.$set(_vm.editableData, "repetitionTime", $event)
          },
          "update:slider-value": function($event) {
            return _vm.$set(_vm.editableData, "repetitionTime", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }