import { render, staticRenderFns } from "./AlarmDescriptionDialog.vue?vue&type=template&id=3dad7f76&"
import script from "./AlarmDescriptionDialog.ts?vue&type=script&lang=ts&"
export * from "./AlarmDescriptionDialog.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/hau-bus-ui_master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3dad7f76')) {
      api.createRecord('3dad7f76', component.options)
    } else {
      api.reload('3dad7f76', component.options)
    }
    module.hot.accept("./AlarmDescriptionDialog.vue?vue&type=template&id=3dad7f76&", function () {
      api.rerender('3dad7f76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/alarms/alarmslist/AlarmDescriptionDialog.vue"
export default component.exports