var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.icon
    ? _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: _vm.track + "::Next",
              expression: "track + '::Next'"
            }
          ],
          attrs: {
            slot: "activator",
            depressed: "",
            icon: "",
            disabled: _vm.nextDisabled
          },
          on: { click: _vm.onNextPressed },
          slot: "activator"
        },
        [_c("v-icon", [_vm._v("$vuetify.icons.bus_chevron_right")])],
        1
      )
    : _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: _vm.track + "::Next",
              expression: "track + '::Next'"
            }
          ],
          attrs: {
            slot: "activator",
            depressed: "",
            color: "primary",
            disabled: _vm.nextDisabled
          },
          on: { click: _vm.onNextPressed },
          slot: "activator"
        },
        [_vm._v(_vm._s(_vm.$t("general.next")))]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }