var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "mr-2": "" } },
    [
      _c("h3", { staticClass: "mb-2" }, [
        _vm._v(_vm._s(_vm.$t("biz.help.softwarelicense.versioninfo")))
      ]),
      _c(
        "v-layout",
        {
          staticStyle: { "align-items": "center" },
          attrs: { "pt-4": "", row: "" }
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-0",
              staticStyle: { "justify-content": "flex-start" }
            },
            [_vm._v(_vm._s(_vm.$t("biz.help.softwarelicense.versionfrontend")))]
          ),
          _c("v-spacer"),
          _c("p", { staticClass: "mb-0 mr-4" }, [
            _vm._v(_vm._s(_vm.getFrontendVersion))
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        {
          staticStyle: { "align-items": "center" },
          attrs: { "pt-3": "", row: "" }
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-0",
              staticStyle: { "justify-content": "flex-start" }
            },
            [_vm._v(_vm._s(_vm.$t("biz.help.softwarelicense.versionbackend")))]
          ),
          _c("v-spacer"),
          _c("p", { staticClass: "mb-0 mr-4" }, [
            _vm._v(_vm._s(_vm.getBackendVersion))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }