var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: { width: "80", height: "80", viewBox: "0 0 80 80", version: "1.1" }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-neutral40-base)" },
            attrs: {
              d:
                "M37.8125,58 L25.6853647,69.4137744 C25.2831909,69.7922909 24.6503165,69.7731129 24.2718,69.3709391 C24.0972139,69.1854414 24,68.940309 24,68.6855744 L24,58 L14,58 C11.790861,58 10,56.209139 10,54 L10,18 C10,15.790861 11.790861,14 14,14 L69,14 C71.209139,14 73,15.790861 73,18 L73,54 C73,56.209139 71.209139,58 69,58 L37.8125,58 Z M34,38 C35.1045695,38 36,37.1045695 36,36 C36,34.8954305 35.1045695,34 34,34 C32.8954305,34 32,34.8954305 32,36 C32,37.1045695 32.8954305,38 34,38 Z M42,38 C43.1045695,38 44,37.1045695 44,36 C44,34.8954305 43.1045695,34 42,34 C40.8954305,34 40,34.8954305 40,36 C40,37.1045695 40.8954305,38 42,38 Z M50,38 C51.1045695,38 52,37.1045695 52,36 C52,34.8954305 51.1045695,34 50,34 C48.8954305,34 48,34.8954305 48,36 C48,37.1045695 48.8954305,38 50,38 Z"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }