import { render, staticRenderFns } from "./MobileApp.vue?vue&type=template&id=918a50aa&scoped=true&"
import script from "./MobileApp.ts?vue&type=script&lang=ts&"
export * from "./MobileApp.ts?vue&type=script&lang=ts&"
import style0 from "./MobileApp.css?vue&type=style&index=0&id=918a50aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "918a50aa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/hau-bus-ui_master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('918a50aa')) {
      api.createRecord('918a50aa', component.options)
    } else {
      api.reload('918a50aa', component.options)
    }
    module.hot.accept("./MobileApp.vue?vue&type=template&id=918a50aa&scoped=true&", function () {
      api.rerender('918a50aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/mobile/MobileApp.vue"
export default component.exports