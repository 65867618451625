var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        height: "18",
        viewBox: "0 0 18 18",
        width: "18",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("circle", {
          staticStyle: { fill: "var(--v-bus_light_grey-base)" },
          attrs: { cx: "9", cy: "9", r: "9" }
        }),
        _c("path", {
          staticStyle: { fill: "var(--v-bus_white-base)" },
          attrs: {
            d: "m12.7058824 8.47058824v1.05882352h-7.41176475v-1.05882352z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }