var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: { width: "80", height: "80", viewBox: "0 0 80 80", version: "1.1" }
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: "path-1",
            d:
              "M4,0 L30.1498782,-3.55271368e-15 C31.1935828,-4.67143499e-15 32.1959518,0.407938996 32.9430485,1.13675016 L50.7931703,18.5499808 C51.5648508,19.3027741 52,20.3351822 52,21.4132307 L52,58 C52,60.209139 50.209139,62 48,62 L4,62 C1.790861,62 2.705415e-16,60.209139 0,58 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z M10,30 C9.44771525,30 9,30.4477153 9,31 C9,31.5522847 9.44771525,32 10,32 L34,32 C34.5522847,32 35,31.5522847 35,31 C35,30.4477153 34.5522847,30 34,30 L10,30 Z M10,36 C9.44771525,36 9,36.4477153 9,37 C9,37.5522847 9.44771525,38 10,38 L24,38 C24.5522847,38 25,37.5522847 25,37 C25,36.4477153 24.5522847,36 24,36 L10,36 Z M10,42 C9.44771525,42 9,42.4477153 9,43 C9,43.5522847 9.44771525,44 10,44 L28,44 C28.5522847,44 29,43.5522847 29,43 C29,42.4477153 28.5522847,42 28,42 L10,42 Z"
          }
        })
      ]),
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("g", { attrs: { transform: "translate(13.000000, 9.000000)" } }, [
            _c("mask", { attrs: { id: "mask-2", fill: "white" } }, [
              _c("use", { attrs: { "xlink:href": "#path-1" } })
            ]),
            _c("use", {
              staticStyle: { fill: "var(--v-neutral40-base)" },
              attrs: { "xlink:href": "#path-1" }
            }),
            _c("path", {
              staticStyle: { fill: "var(--v-bus_light_grey-base)" },
              attrs: {
                d:
                  "M31,0 L52,0 L52,21 L35,21 C32.790861,21 31,19.209139 31,17 L31,0 Z",
                opacity: "0.602097284",
                mask: "url(#mask-2)"
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }