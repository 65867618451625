var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "30px",
        height: "34px",
        viewBox: "0 0 30 34",
        version: "1.1"
      }
    },
    [
      _c("g", [
        _c("path", {
          staticStyle: {
            "fill-rule": "nonzero",
            fill: "rgb(100%, 100%, 100%)",
            "fill-opacity": "1",
            "stroke-width": "0.2",
            "stroke-linecap": "butt",
            "stroke-linejoin": "miter",
            stroke: "rgb(100%, 100%, 100%)",
            "stroke-opacity": "1",
            "stroke-miterlimit": "4"
          },
          attrs: {
            d:
              "M 21 0.000735294 L 21 4.225 L 19.95 4.225 L 19.95 1.048529 L 11.025 1.048529 L 11.025 29.401471 L 19.95 29.401471 L 19.95 17.324265 L 21 17.324265 L 21 30.449265 L 0.000000000000029865 30.449265 L 0.000000000000029865 0.000735294 Z M 9.975 14.173529 L 1.05 14.173529 L 1.05 29.401471 L 9.975 29.401471 Z M 8.925 15.225 L 8.925 19.423529 L 7.875 19.423529 L 7.875 15.225 Z M 13.125 15.225 L 13.125 19.423529 L 12.075 19.423529 L 12.075 15.225 Z M 22.144792 4.938235 L 22.888542 5.677206 L 21 7.566912 L 21 9.853676 L 22.979688 8.710294 L 23.66875 6.133088 L 24.682292 6.405147 L 24.073438 8.684559 L 26.355729 9.294853 L 26.082292 10.309559 L 23.504688 9.618382 L 21.525 10.761765 L 23.504688 11.905147 L 26.082292 11.217647 L 26.355729 12.228676 L 24.073438 12.838971 L 24.682292 15.122059 L 23.66875 15.390441 L 22.979688 12.813235 L 21 11.669853 L 21 13.956618 L 22.888542 15.846324 L 22.144792 16.588971 L 20.475 14.916176 L 18.805208 16.588971 L 18.061458 15.846324 L 19.95 13.956618 L 19.95 11.669853 L 17.970313 12.813235 L 17.28125 15.390441 L 16.267708 15.122059 L 16.876563 12.838971 L 14.594271 12.228676 L 14.867708 11.217647 L 17.445313 11.905147 L 19.425 10.761765 L 17.445313 9.618382 L 14.867708 10.309559 L 14.594271 9.294853 L 16.876563 8.684559 L 16.267708 6.405147 L 17.28125 6.133088 L 17.970313 8.710294 L 19.95 9.853676 L 19.95 7.566912 L 18.061458 5.677206 L 18.805208 4.938235 L 20.475 6.607353 Z M 9.975 1.048529 L 1.05 1.048529 L 1.05 13.125735 L 9.975 13.125735 Z M 8.925 7.875735 L 8.925 12.074265 L 7.875 12.074265 L 7.875 7.875735 Z M 8.925 7.875735 ",
            transform: "matrix(1.071429,0,0,1.0625,0.9375,0.823438)"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }