var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-5" },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            [
              _c("div", { staticClass: "title pb-5" }, [
                _vm._v(_vm._s(_vm.$t("biz.settings.licensemanagement")))
              ]),
              _c("h3", [_vm._v(_vm._s(_vm.$t("biz.settings.licenses")))]),
              _c("p", { staticClass: "bus_normaltext mt-4 mb-4" }, [
                _vm._v(_vm._s(_vm.licenseDescription))
              ]),
              _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("LicenseSettingsBadge", {
                    attrs: {
                      hiddenIfZero: false,
                      count: _vm.getLicensedCount,
                      color: "bus_green"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "bus_normaltext",
                      staticStyle: {
                        "margin-left": "28px",
                        "margin-top": "-15px"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("biz.settings.activelicense")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _c("LicensesOverview", {
                attrs: {
                  licenseDetails: _vm.getAssignedLicenses,
                  unlicensedAppliances: _vm.getAllUnlicensedAppliances
                },
                on: { evtOnLicenseChanged: _vm.onLicenseChanged }
              }),
              _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("LicenseSettingsBadge", {
                    attrs: {
                      hiddenIfZero: false,
                      count: _vm.getUnassignedLicenseCount,
                      color: "bus_green"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "bus_normaltext",
                      staticStyle: {
                        "margin-left": "28px",
                        "margin-top": "-15px"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("biz.settings.freelicense")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _c("LicensesOverview", {
                attrs: {
                  assigned: false,
                  licenseDetails: _vm.getUnassignedLicenses,
                  unlicensedAppliances: _vm.getAllUnlicensedAppliances
                },
                on: { evtOnLicenseChanged: _vm.onLicenseChanged }
              }),
              _c(
                "v-layout",
                { attrs: { column: "", "align-start": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "LicenseManagement::BuyLicense",
                          expression: "'LicenseManagement::BuyLicense'"
                        }
                      ],
                      staticClass: "ma-1",
                      attrs: { color: "secondary", outline: "", depressed: "" },
                      on: {
                        click: function($event) {
                          _vm.buyLicenseDialog = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "pr-2" }, [
                        _vm._v(_vm._s(_vm.getRightArrowIcon()))
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("biz.settings.buylicenses")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "LicenseManagement::CancelLicense",
                          expression: "'LicenseManagement::CancelLicense'"
                        }
                      ],
                      staticClass: "ma-1",
                      attrs: {
                        href: _vm.getCancelLicenseLink(),
                        target: "_blank",
                        color: "secondary",
                        outline: "",
                        depressed: ""
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "pr-2" }, [
                        _vm._v(_vm._s(_vm.getRightArrowIcon()))
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("biz.settings.cancellicenses")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("BuyLicenseDialog", {
                attrs: { showDialog: _vm.buyLicenseDialog },
                on: {
                  evtClose: function($event) {
                    _vm.buyLicenseDialog = false
                  }
                }
              }),
              _c("h3", { staticClass: "mt-5" }, [
                _vm._v(_vm._s(_vm.$t("biz.settings.addontitle")))
              ]),
              _vm.hasValidAddonLicense
                ? [
                    _c("p", { staticClass: "bus_normaltext mt-4 mb-4" }, [
                      _vm._v(_vm._s(_vm.$t("biz.settings.addonactive")))
                    ]),
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c("v-flex", { attrs: { xs6: "" } }, [
                          _c("p", { staticClass: "bus_normaltext pl-2" }, [
                            _vm._v(_vm._s(_vm.$t("biz.settings.addonstatus")))
                          ])
                        ]),
                        _vm.getAddonEndDate()
                          ? _c(
                              "div",
                              { staticClass: "bus_normaltext" },
                              [
                                _c("v-flex", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.settings.addonactivedate", {
                                        date: _vm.getAddonEndDate()
                                      })
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "bus_normaltext" },
                              [
                                _c("v-flex", [
                                  _vm._v(_vm._s(_vm.$t("general.active")))
                                ])
                              ],
                              1
                            )
                      ],
                      1
                    ),
                    _vm.isLicenseExpiringSoon
                      ? _c(
                          "v-layout",
                          { attrs: { column: "" } },
                          [
                            _c("v-flex", [
                              _c("p", { staticClass: "bus_warningtext" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tc(
                                      "biz.settings.addonexpirywarning",
                                      _vm.daysBeforeLicenseExpiry
                                    )
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "v-flex",
                              [
                                _c("RedeemVoucher", {
                                  attrs: { voucherMode: _vm.companyVoucherMode }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "track",
                                        rawName: "v-track",
                                        value:
                                          "LicenseManagement::BuyAddonLicense",
                                        expression:
                                          "'LicenseManagement::BuyAddonLicense'"
                                      }
                                    ],
                                    staticClass: "ma-1",
                                    attrs: {
                                      color: "secondary",
                                      outline: "",
                                      depressed: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.buyLicenseDialog = true
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { staticClass: "pr-2" }, [
                                      _vm._v(_vm._s(_vm.getRightArrowIcon()))
                                    ]),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("biz.settings.purchaseaddon")
                                        ) +
                                        "\n            "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              !_vm.hasValidAddonLicense
                ? [
                    _vm.hasNoAddonLicense
                      ? _c("p", { staticClass: "bus_normaltext mt-4 mb-4" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("biz.settings.addoninactive")) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm.hasExpiredAddonLicense
                      ? _c("p", { staticClass: "bus_normaltext mt-4 mb-4" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("biz.settings.addonrunout")) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _c("RedeemVoucher", {
                      attrs: { voucherMode: _vm.companyVoucherMode }
                    }),
                    _c(
                      "v-layout",
                      { attrs: { "pt-4": "", column: "", "align-start": "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: "LicenseManagement::BuyAddonLicense",
                                expression:
                                  "'LicenseManagement::BuyAddonLicense'"
                              }
                            ],
                            staticClass: "ma-1",
                            attrs: {
                              color: "secondary",
                              outline: "",
                              depressed: ""
                            },
                            on: {
                              click: function($event) {
                                _vm.buyLicenseDialog = true
                              }
                            }
                          },
                          [
                            _c("v-icon", { staticClass: "pr-2" }, [
                              _vm._v(_vm._s(_vm.getRightArrowIcon()))
                            ]),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("biz.settings.purchaseaddon")) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }