var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: {
      dialogWidth: "700px",
      title: _vm.$t("biz.sensors.setup.found.findhelp"),
      dialogShown: _vm.dialogShown,
      track: "SensorNotFoundDialog"
    },
    on: { evtClose: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("v-flex", {
              staticClass: "bus_normaltext",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("biz.sensors.sensornotfounddialog.text")
                )
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "buttons",
        fn: function() {
          return [_c("v-spacer")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }