// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HistoryDeviceAlarm, HistoryDoorOpening, Point } from './';

/**
 * @export
 * @interface DataPointsObject
 */
export interface DataPointsObject {
  /**
   * @type {Array<Point>}
   * @memberof DataPointsObject
   */
  alarmCount?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof DataPointsObject
   */
  averageTemperature?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof DataPointsObject
   */
  defrostState?: Array<Point>;
  /**
   * @type {Array<Array<HistoryDeviceAlarm>>}
   * @memberof DataPointsObject
   */
  deviceAlarms?: Array<Array<HistoryDeviceAlarm>>;
  /**
   * @type {Array<Point>}
   * @memberof DataPointsObject
   */
  doorOpenings?: Array<Point>;
  /**
   * @type {Array<Array<HistoryDoorOpening>>}
   * @memberof DataPointsObject
   */
  doorOpeningsArray?: Array<Array<HistoryDoorOpening>>;
  /**
   * @type {Array<Point>}
   * @memberof DataPointsObject
   */
  highTemperature?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof DataPointsObject
   */
  lowTemperature?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof DataPointsObject
   */
  maxTemperature?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof DataPointsObject
   */
  minTemperature?: Array<Point>;
  /**
   * @type {Array<Point>}
   * @memberof DataPointsObject
   */
  setTemperature?: Array<Point>;
}
