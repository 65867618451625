var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.dataReady && !_vm.loadingMoreAlarms,
              expression: "!dataReady && !loadingMoreAlarms"
            }
          ],
          staticStyle: { height: "65vh", width: "100%" },
          attrs: { column: "", "align-center": "", "justify-center": "" }
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "secondary", size: "48" }
          }),
          _c("p", { staticClass: "bus_normaltext mt-5" }, [
            _vm._v(_vm._s(_vm.$t("general.actions.loading")))
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.dataReady && _vm.loadingMoreAlarms,
              expression: "!dataReady && loadingMoreAlarms"
            }
          ],
          staticStyle: { height: "65vh", width: "100%" },
          attrs: { column: "", "align-center": "", "justify-center": "" }
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "secondary", size: "48" }
          }),
          _c("p", { staticClass: "bus_normaltext mt-5" }, [
            _vm._v(_vm._s(_vm.$t("general.actions.loadingmorealarms")))
          ])
        ],
        1
      ),
      _c(
        "v-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dataReady,
              expression: "dataReady"
            }
          ],
          staticStyle: { "max-width": "none" },
          attrs: { "pa-2": "" }
        },
        [
          _c("v-data-table", {
            staticClass: "paddingless",
            attrs: {
              headers: _vm.headers,
              items: _vm.alarms,
              pagination: _vm.alarmPagination,
              "select-all": "",
              "hide-actions": "",
              "item-key": "alarmId"
            },
            on: {
              "update:pagination": function($event) {
                _vm.alarmPagination = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "headers",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      _vm._l(props.headers, function(header) {
                        return _c(
                          "td",
                          {
                            key: header.text,
                            staticStyle: {
                              color: "bus_grey",
                              "text-align": "left",
                              "padding-right": "3px !important"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(header.text) +
                                "\n          "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                }
              },
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      {
                        class: _vm.getRowFont(props.item),
                        staticStyle: { border: "none" },
                        on: {
                          click: function($event) {
                            props.selected = !props.selected
                          }
                        }
                      },
                      [
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "15%", "text-align": "left" }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                class: _vm.getIconStyling(props.item),
                                attrs: { large: "" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.getStateAlarmIcon(props.item))
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: {
                              width: "30%",
                              "padding-right": "5px !important"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.getPrefixDiscardedAlarm(props.item)
                                ) +
                                  " " +
                                  _vm._s(_vm.getStateAlarmText(props.item))
                              )
                            ])
                          ]
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: { width: "25%" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "var(--v-secondary-base) !important",
                                  "text-decoration": "none"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(props.item.deviceName) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "td",
                          {
                            class: _vm.getRowFont(props.item),
                            staticStyle: {
                              width: "20%",
                              "padding-left": "5px !important"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$format.dateTimeShort(
                                    props.item.alarmTime
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "td",
                          { class: _vm.getRowFont(props.item) },
                          [
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: props.item.readstate === true,
                                    expression: "props.item.readstate === true"
                                  },
                                  {
                                    name: "track",
                                    rawName: "v-track",
                                    value: _vm.track + "::ResetReadState",
                                    expression: "track + '::ResetReadState'"
                                  }
                                ],
                                staticClass: "mx-0",
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.changeReadState(props.item)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("$vuetify.icons.bus_alarm")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: props.item.readstate !== true,
                                    expression: "props.item.readstate !== true"
                                  },
                                  {
                                    name: "track",
                                    rawName: "v-track",
                                    value: _vm.track + "::SetReadState",
                                    expression: "track + '::SetReadState'"
                                  }
                                ],
                                staticClass: "mx-0",
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.changeReadState(props.item)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("$vuetify.icons.bus_alarm_new")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedAlarms,
              callback: function($$v) {
                _vm.selectedAlarms = $$v
              },
              expression: "selectedAlarms"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dataReady,
              expression: "dataReady"
            }
          ],
          staticClass: "text-xs-center"
        },
        [
          _vm.pages > 1
            ? _c("v-pagination", {
                attrs: { length: _vm.pages },
                model: {
                  value: _vm.alarmPagination.page,
                  callback: function($$v) {
                    _vm.$set(_vm.alarmPagination, "page", $$v)
                  },
                  expression: "alarmPagination.page"
                }
              })
            : _vm._e(),
          _c("v-spacer"),
          _vm.showMore && _vm.onLastPage
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::LoadMoreAlarm",
                      expression: "track + '::LoadMoreAlarm'"
                    }
                  ],
                  attrs: { color: "primary", depressed: "" },
                  on: {
                    click: function($event) {
                      return _vm.loadMoreAlarms()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("biz.alarms.morealarms")))]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }