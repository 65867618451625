var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700px", "min-width": "600px" },
      model: {
        value: _vm.openRoomDialog,
        callback: function($$v) {
          _vm.openRoomDialog = $$v
        },
        expression: "openRoomDialog"
      }
    },
    [
      _c("BaseWindowItem", {
        attrs: { trackCancel: "MeasureSiteRoom::Close" },
        on: {
          evtCancel: function($event) {
            return _vm.closeDialog()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.$t("biz.sensors.setup.measuresite.datacards.titleroom")
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("div", { staticClass: "font-weight-black" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "biz.sensors.setup.measuresite.datacards.properties"
                      )
                    )
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "description",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "biz.sensors.setup.measuresite.datacards.descriptionroom"
                    )
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-layout",
                      { attrs: { column: "" } },
                      [
                        _c(
                          "v-flex",
                          [
                            _c("div", { staticClass: "bus_required_field" }, [
                              _vm._v("* " + _vm._s(_vm.$t("general.required")))
                            ]),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  "font-weight": "bold"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("biz.sensors.setup.properties.name")
                                  )
                                )
                              ]
                            ),
                            _c("v-text-field", {
                              staticClass: "default selected errorborder",
                              attrs: {
                                clearable: _vm.iconHelper.clearableIcon(
                                  _vm.localDataForRoom.name
                                ),
                                placeholder: _vm.$t(
                                  "biz.sensors.setup.properties.name"
                                ),
                                rules: _vm.nameRules,
                                type: "string",
                                required: ""
                              },
                              model: {
                                value: _vm.localDataForRoom.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.localDataForRoom, "name", $$v)
                                },
                                expression: "localDataForRoom.name"
                              }
                            }),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  "font-weight": "bold"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("biz.applianceinfo.building"))
                                )
                              ]
                            ),
                            _c("v-text-field", {
                              staticClass: "default selected",
                              attrs: {
                                clearable: _vm.iconHelper.clearableIcon(
                                  _vm.localDataForRoom.building
                                ),
                                placeholder: _vm.$t(
                                  "biz.applianceinfo.building"
                                ),
                                type: "string",
                                required: ""
                              },
                              model: {
                                value: _vm.localDataForRoom.building,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.localDataForRoom,
                                    "building",
                                    $$v
                                  )
                                },
                                expression: "localDataForRoom.building"
                              }
                            }),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  "font-weight": "bold"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("biz.applianceinfo.level"))
                                )
                              ]
                            ),
                            _c("v-text-field", {
                              staticClass: "default selected",
                              attrs: {
                                clearable: _vm.iconHelper.clearableIcon(
                                  _vm.localDataForRoom.level
                                ),
                                placeholder: _vm.$t("biz.applianceinfo.floor"),
                                type: "string",
                                required: ""
                              },
                              model: {
                                value: _vm.localDataForRoom.level,
                                callback: function($$v) {
                                  _vm.$set(_vm.localDataForRoom, "level", $$v)
                                },
                                expression: "localDataForRoom.level"
                              }
                            }),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  "font-weight": "bold"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.room")))]
                            ),
                            _c("v-text-field", {
                              staticClass: "default selected",
                              attrs: {
                                clearable: _vm.iconHelper.clearableIcon(
                                  _vm.localDataForRoom.roomNumberr
                                ),
                                placeholder: _vm.$t("biz.applianceinfo.room"),
                                type: "string",
                                required: ""
                              },
                              model: {
                                value: _vm.localDataForRoom.roomNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.localDataForRoom,
                                    "roomNumber",
                                    $$v
                                  )
                                },
                                expression: "localDataForRoom.roomNumber"
                              }
                            }),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  "font-weight": "bold"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("biz.applianceinfo.department"))
                                )
                              ]
                            ),
                            _c("v-text-field", {
                              staticClass: "default selected",
                              attrs: {
                                clearable: _vm.iconHelper.clearableIcon(
                                  _vm.localDataForRoom.department
                                ),
                                placeholder: _vm.$t(
                                  "biz.applianceinfo.department"
                                ),
                                type: "string",
                                required: ""
                              },
                              model: {
                                value: _vm.localDataForRoom.department,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.localDataForRoom,
                                    "department",
                                    $$v
                                  )
                                },
                                expression: "localDataForRoom.department"
                              }
                            }),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  "font-weight": "bold"
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("general.comment")))]
                            ),
                            _c("v-text-field", {
                              staticClass: "defaulttextarea selectedtextarea",
                              attrs: {
                                clearable: _vm.iconHelper.clearableIcon(
                                  _vm.localDataForRoom.comment
                                ),
                                placeholder: _vm.$t("general.comment"),
                                type: "string",
                                required: ""
                              },
                              model: {
                                value: _vm.localDataForRoom.comment,
                                callback: function($$v) {
                                  _vm.$set(_vm.localDataForRoom, "comment", $$v)
                                },
                                expression: "localDataForRoom.comment"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("p", [
                      _vm._v(
                        "*" +
                          _vm._s(
                            _vm.$t("biz.sensors.setup.measuresite.mandatory")
                          )
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  {
                    staticStyle: { "justify-content": "flex-end" },
                    attrs: { "mb-3": "" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "MeasureSiteRoom::Next",
                            expression: "'MeasureSiteRoom::Next'"
                          }
                        ],
                        attrs: {
                          depressed: "",
                          disabled: !_vm.localDataForRoom.name,
                          color: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showGroupDialog()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.next")))]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("ChooseGroupDialog", {
        attrs: { openGroupDialog: _vm.openGroupDialog },
        on: { evtGroupDialogSave: _vm.groupSelected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }