var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600px", persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("BaseWindowItem", {
            attrs: { trackCancel: _vm.track + "::Cancel", buttonExit: false },
            on: {
              evtCancel: function($event) {
                return _vm.close()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c("div", { staticClass: "mb-3" }, [
                      _vm._v(
                        _vm._s(_vm.$t("general.home.cookieDialog.cookieTitle"))
                      )
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "v-layout",
                      {
                        staticStyle: { height: "300px" },
                        attrs: { row: "", "ml-1": "", "mr-1": "" }
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("general.home.cookieDialog.cookieText")
                            )
                          }
                        })
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c(
            "v-card-actions",
            [
              _c(
                "v-layout",
                { attrs: { column: "", "ml-2": "", "mr-2": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { "mb-2": "", row: "", "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "secondary", flat: "" },
                          on: {
                            click: function($event) {
                              _vm.legalNoticeVisible = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("biz.help.legalnotice.title")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "secondary", flat: "" },
                          on: {
                            click: function($event) {
                              _vm.privacyPolicyVisible = true
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("biz.help.privacypolicy")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { large: "", color: "secondary", outline: "" },
                          on: {
                            click: function($event) {
                              return _vm.disableAnalytics()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("general.acceptance.reject")))]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { large: "", depressed: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.enableAnalytics()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("general.acceptance.accept")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("LegalNoticeDialog", {
        attrs: { dialogShown: _vm.legalNoticeVisible },
        on: {
          evtClose: function($event) {
            _vm.legalNoticeVisible = false
          }
        }
      }),
      _c("PrivacyPolicyDialog", {
        attrs: { dialogShown: _vm.privacyPolicyVisible },
        on: {
          evtClose: function($event) {
            _vm.privacyPolicyVisible = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }