var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "650" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { "mb-3": "", row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { "mt-3": "" } },
                    [
                      _c("v-layout", { staticClass: "title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("biz.onboarding.bsdbsoftwareupdate.title")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "BsdbSoftwareUpdateDialog::Close",
                          expression: "'BsdbSoftwareUpdateDialog::Close'"
                        }
                      ],
                      attrs: { depressed: "", icon: "" },
                      on: { click: _vm.close }
                    },
                    [_c("v-icon", [_vm._v("$vuetify.icons.bus_close")])],
                    1
                  )
                ],
                1
              ),
              _c("v-card", { staticClass: "mb-3", attrs: { flat: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    _vm._s(_vm.$t("biz.onboarding.bsdbsoftwareupdate.desc"))
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "pa-3" },
                  [
                    _c(
                      "v-layout",
                      { attrs: { "fill-height": "", column: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { "mt-3": "" } },
                          [
                            _c("p", {
                              staticClass: "bus_normaltext mb-2",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "biz.onboarding.bsdbsoftwareupdate.point1"
                                  )
                                )
                              }
                            }),
                            _c("div", { staticClass: "mb-2" }, [
                              _c("p", {
                                staticClass: "bus_normaltext mb-2",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t(
                                      "biz.onboarding.bsdbsoftwareupdate.point2"
                                    )
                                  )
                                }
                              }),
                              _c(
                                "a",
                                {
                                  staticStyle: { "padding-left": "4px" },
                                  attrs: {
                                    href: _vm.schSoftwareLink,
                                    download: "Hub_Software.zip"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("general.here")))]
                              ),
                              _c("br")
                            ]),
                            _c("p", {
                              staticClass: "bus_normaltext mb-2",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "biz.onboarding.bsdbsoftwareupdate.point3"
                                  )
                                )
                              }
                            }),
                            _c("p", {
                              staticClass: "bus_normaltext mb-2",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "biz.onboarding.bsdbsoftwareupdate.point4"
                                  )
                                )
                              }
                            }),
                            _c("p", {
                              staticClass: "bus_normaltext mb-2",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "biz.onboarding.bsdbsoftwareupdate.point5"
                                  )
                                )
                              }
                            }),
                            _c("p", {
                              staticClass: "bus_normaltext mb-2",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "biz.onboarding.bsdbsoftwareupdate.point6"
                                  )
                                )
                              }
                            }),
                            _c("p", {
                              staticClass: "bus_normaltext mb-2",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "biz.onboarding.bsdbsoftwareupdate.point7"
                                  )
                                )
                              }
                            }),
                            _c(
                              "v-flex",
                              { attrs: { "offset-xs1": "", xs10: "" } },
                              [
                                _c("v-img", {
                                  staticClass: "mt-5",
                                  attrs: {
                                    src: require("@/assets/smartcoolinghub_software_update@1x.png"),
                                    contain: ""
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticStyle: { display: "flex", "text-align": "right" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value:
                                "BsdbSoftwareUpdateDialog::StartServiceRequest",
                              expression:
                                "'BsdbSoftwareUpdateDialog::StartServiceRequest'"
                            }
                          ],
                          attrs: { depressed: "", color: "primary" },
                          on: {
                            click: function($event) {
                              _vm.showServiceDialog = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("biz.help.servicedetails.start")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "BsdbSoftwareUpdateDialog::Ok",
                          expression: "'BsdbSoftwareUpdateDialog::Ok'"
                        }
                      ],
                      staticStyle: { width: "15%" },
                      attrs: { outline: "", color: "secondary" },
                      on: { click: _vm.close }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.close")) + "\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ServiceDialog", {
        directives: [
          {
            name: "track",
            rawName: "v-track",
            value: "BsdbSoftwareUpdateDialog::StartServiceRequest",
            expression: "'BsdbSoftwareUpdateDialog::StartServiceRequest'"
          }
        ],
        attrs: {
          dialogShown: _vm.showServiceDialog,
          selectedGateway: _vm.selectedGateway,
          message: _vm.$t("biz.help.servicedetails.requestmessage")
        },
        on: {
          evtServiceDialogClose: function($event) {
            _vm.showServiceDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }