var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.wizard
        ? _c(
            "div",
            [
              _c(
                "v-layout",
                { attrs: { column: "", "pa-3": "" } },
                [
                  _c("p", { staticClass: "bus_normaltext" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.sensors.creationwizard.editdoordesc"))
                    )
                  ]),
                  _c("v-checkbox", {
                    staticStyle: { "padding-top": "40px" },
                    attrs: {
                      color: "secondary",
                      label: _vm.$t("biz.sensors.alarmsetting.door.activecheck")
                    },
                    model: {
                      value: _vm.editableDataLocal.doorAlarmActive,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal, "doorAlarmActive", $$v)
                      },
                      expression: "editableDataLocal.doorAlarmActive"
                    }
                  }),
                  _c("p", { staticClass: "bus_normaltext" }, [
                    _vm._v(_vm._s(_vm.$t("general.delaytime")))
                  ]),
                  _c("BasicSlider", {
                    attrs: {
                      sliderValue:
                        _vm.editableDataLocal.doorOpenedAlarmDelayInSeconds,
                      active: _vm.editableDataLocal.doorAlarmActive,
                      minValue: _vm.VALID_MIN_TIME_MINUTES,
                      maxValue: _vm.VALID_MAX_TIME_MINUTES,
                      specialSliderValues: _vm.specialSliderValues,
                      specialSlider: true
                    },
                    on: {
                      "update:sliderValue": function($event) {
                        return _vm.$set(
                          _vm.editableDataLocal,
                          "doorOpenedAlarmDelayInSeconds",
                          $event
                        )
                      },
                      "update:slider-value": function($event) {
                        return _vm.$set(
                          _vm.editableDataLocal,
                          "doorOpenedAlarmDelayInSeconds",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "700px" },
              model: {
                value: _vm.editorShown,
                callback: function($$v) {
                  _vm.editorShown = $$v
                },
                expression: "editorShown"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-3" },
                [
                  _c("v-card-title", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.sensors.alarmsetting.door.title"))
                    )
                  ]),
                  _c(
                    "v-layout",
                    { attrs: { column: "", "pa-3": "" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          color: "secondary",
                          label: _vm.$t(
                            "biz.sensors.alarmsetting.door.activecheck"
                          )
                        },
                        model: {
                          value: _vm.editableDataLocal.doorAlarmActive,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.editableDataLocal,
                              "doorAlarmActive",
                              $$v
                            )
                          },
                          expression: "editableDataLocal.doorAlarmActive"
                        }
                      }),
                      _c("p", { staticClass: "bus_normaltext" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "biz.sensors.alarmsetting.door.editdescription"
                            )
                          )
                        )
                      ]),
                      _c("p", { staticClass: "bus_normaltext" }, [
                        _vm._v(_vm._s(_vm.$t("general.delaytime")))
                      ]),
                      _c("BasicSlider", {
                        attrs: {
                          sliderValue:
                            _vm.editableDataLocal.doorOpenedAlarmDelayInSeconds,
                          active: _vm.editableDataLocal.doorAlarmActive,
                          minValue: _vm.VALID_MIN_TIME_MINUTES,
                          maxValue: _vm.VALID_MAX_TIME_MINUTES,
                          specialSliderValues: _vm.specialSliderValues,
                          specialSlider: true
                        },
                        on: {
                          "update:sliderValue": function($event) {
                            return _vm.$set(
                              _vm.editableDataLocal,
                              "doorOpenedAlarmDelayInSeconds",
                              $event
                            )
                          },
                          "update:slider-value": function($event) {
                            return _vm.$set(
                              _vm.editableDataLocal,
                              "doorOpenedAlarmDelayInSeconds",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c("SaveCancel", {
                        attrs: {
                          saveDisabled: _vm.saveDisabled,
                          track: _vm.track,
                          icon: false
                        },
                        on: {
                          evtSave: function($event) {
                            return _vm.onSave()
                          },
                          evtCancel: function($event) {
                            return _vm.onCancel()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }