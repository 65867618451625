var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.icon
    ? _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: _vm.track + "::Cancel",
              expression: "track + '::Cancel'"
            }
          ],
          staticStyle: { "border-radius": "40px" },
          attrs: {
            slot: "activator",
            depressed: "",
            flat: "",
            disabled: _vm.cancelDisabled,
            icon: ""
          },
          on: {
            click: function($event) {
              return _vm.onCancelPressed()
            }
          },
          slot: "activator"
        },
        [_c("v-icon", [_vm._v("$vuetify.icons.bus_close")])],
        1
      )
    : _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: _vm.track + "::Cancel",
              expression: "track + '::Cancel'"
            }
          ],
          staticClass: "secondary",
          style: _vm.buttonBorder,
          attrs: {
            slot: "activator",
            depressed: "",
            color: _vm.buttonColor,
            outline: "",
            disabled: _vm.cancelDisabled
          },
          on: {
            click: function($event) {
              return _vm.onCancelPressed()
            }
          },
          slot: "activator"
        },
        [_vm._v(_vm._s(_vm.buttonText))]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }