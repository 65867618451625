var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            staticStyle: { stroke: "var(--v-secondary-base)" },
            attrs: {
              d:
                "M11.8169372,4 C7.4419124,4.34788263 4,8.00846703 4,12.4729532 C4,14.7482677 4.89400455,16.8147762 6.34990825,18.3403734 M7.3528823,19.2379284 C8.78149021,20.3265503 10.5652574,20.9729532 12.5,20.9729532 C16.1146189,20.9729532 19.2022689,18.7167308 20.431456,15.5357801 M20.8467461,14.0880793 C20.9473324,13.565171 21,13.0252126 21,12.4729532 C21,8.03442728 17.5979995,4.39048119 13.2592878,4.00640418",
              "stroke-width": "2.5"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }