var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18px",
        height: "18px",
        viewBox: "0 0 18 18",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("circle", {
            staticStyle: { fill: "var(--v-bus_light_grey-base)" },
            attrs: { cx: "9", cy: "9", r: "9" }
          }),
          _c("path", {
            staticStyle: { fill: "var(--v-bus_white-base)" },
            attrs: {
              d:
                "M9.52941176,5.29411765 L9.52911765,8.47011765 L12.7058824,8.47058824 L12.7058824,9.52941176 L9.52911765,9.52911765 L9.52941176,12.7058824 L8.47058824,12.7058824 L8.47011765,9.52911765 L5.29411765,9.52941176 L5.29411765,8.47058824 L8.47011765,8.47011765 L8.47058824,5.29411765 L9.52941176,5.29411765 Z",
              "fill-rule": "nonzero"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }