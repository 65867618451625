var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "20px",
        height: "18px",
        viewBox: "0 0 20 18",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              attrs: {
                transform: "translate(-219.000000, -722.000000)",
                fill: "var(--v-bus_black-base)"
              }
            },
            [
              _c(
                "g",
                { attrs: { transform: "translate(166.000000, 89.000000)" } },
                [
                  _c(
                    "g",
                    {
                      attrs: { transform: "translate(16.000000, 595.000000)" }
                    },
                    [
                      _c("g", [
                        _c(
                          "g",
                          {
                            attrs: {
                              transform: "translate(10.000000, 10.000000)"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  transform: "translate(25.000000, 25.000000)"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M6,4 L6,5 L11,5 L11,4 L6,4 L6,4 Z M12,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,20 C22,20.5522847 21.5522847,21 21,21 L3,21 C2.44771525,21 2,20.5522847 2,20 L2,5 C2,4.44771525 2.44771525,4 3,4 L5,4 C5,3.44771525 5.44771525,3 6,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,4 Z M5,5 L3,5 L3,20 L21,20 L21,5 L12,5 L12,12.8257794 C12,13.3780641 11.5522847,13.8257794 11,13.8257794 C10.7612887,13.8257794 10.5304517,13.7403872 10.3492086,13.585036 L8.5,12 L6.65079137,13.585036 C6.23146553,13.9444581 5.60016555,13.8958966 5.2407434,13.4765707 C5.08539216,13.2953276 5,13.0644906 5,12.8257794 L5,5 Z M6,5 L6,12.8257794 L8.5,10.6829222 L11,12.8257794 L11,5 L6,5 Z M14.5,8 L18.5,8 C18.7761424,8 19,8.22385763 19,8.5 C19,8.77614237 18.7761424,9 18.5,9 L14.5,9 C14.2238576,9 14,8.77614237 14,8.5 C14,8.22385763 14.2238576,8 14.5,8 Z M14.5,12 L18.5,12 C18.7761424,12 19,12.2238576 19,12.5 C19,12.7761424 18.7761424,13 18.5,13 L14.5,13 C14.2238576,13 14,12.7761424 14,12.5 C14,12.2238576 14.2238576,12 14.5,12 Z M5.5,16 L18.5,16 C18.7761424,16 19,16.2238576 19,16.5 C19,16.7761424 18.7761424,17 18.5,17 L5.5,17 C5.22385763,17 5,16.7761424 5,16.5 C5,16.2238576 5.22385763,16 5.5,16 Z"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }