var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navigation-drawer" },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "primary with-space",
          attrs: {
            "mini-variant-width": "60",
            dark: "",
            "mini-variant": _vm.mini,
            fixed: "",
            stateless: "",
            temporary: !_vm.mini
          },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-toolbar",
            { staticClass: "transparent", attrs: { flat: "" } },
            [
              _c(
                "v-list",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-list-tile",
                    { staticClass: "unicolorList" },
                    [
                      _c(
                        "v-list-tile-action",
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "NavigationDrawer::SwitchMenu",
                                  expression: "'NavigationDrawer::SwitchMenu'"
                                }
                              ],
                              attrs: { depressed: "", icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.mini = !_vm.mini
                                }
                              }
                            },
                            [
                              _c("v-icon", [
                                _vm._v("$vuetify.icons.bus_navigation_menu")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-list-tile-content", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("general.home.title")))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list",
            { staticClass: "pt-0" },
            _vm._l(_vm.topFeatures, function(item) {
              return _c(
                "v-list-tile",
                {
                  key: item.title,
                  class: _vm.getFeatureClass(item),
                  attrs: {
                    to: item.premium ? "" : "/" + item.to,
                    disabled: item.disabled
                  },
                  on: { click: _vm.itemSelected }
                },
                [
                  _c(
                    "v-list-tile-action",
                    { staticStyle: { "min-width": "45px" } },
                    [
                      item.title === "alarm"
                        ? _c("AlarmIcon", {
                            attrs: {
                              showIcon: _vm.mini,
                              active: _vm.isFeatureActive(item)
                            }
                          })
                        : item.title === "news"
                        ? _c("MessageIcon", {
                            attrs: {
                              showIcon: _vm.mini,
                              active: _vm.isFeatureActive(item)
                            }
                          })
                        : item.title === "devices"
                        ? _c("ApplianceIcon", {
                            attrs: {
                              showIcon: _vm.mini,
                              active: _vm.isFeatureActive(item)
                            }
                          })
                        : _c("NavigationItem", {
                            attrs: {
                              feature: item,
                              active: _vm.isFeatureActive(item)
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile-content",
                    { staticStyle: { width: "200px" } },
                    [
                      _c(
                        "v-list-tile-title",
                        { style: _vm.getTextColor(item) },
                        [_vm._v(_vm._s(_vm.getText(item)))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile-content",
                    [
                      item.title === "alarm"
                        ? _c("AlarmBadge")
                        : item.title === "news"
                        ? _c("MessageBadge")
                        : item.title === "devices"
                        ? _c("ApplianceBadge")
                        : _vm._e()
                    ],
                    1
                  ),
                  item.premium
                    ? _c("v-list-tile-content", [
                        _c("span", { staticClass: "premium" }, [
                          _vm._v("Premium")
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-list",
            { staticClass: "bottom-navigation-area" },
            [
              _c(
                "v-list-tile",
                {
                  class: _vm.getFeatureClass(_vm.settingsFeature),
                  attrs: { to: "/" + _vm.settingsFeature.to },
                  on: { click: _vm.itemSelected }
                },
                [
                  _c(
                    "v-list-tile-action",
                    [
                      _c("LicenseSettingsIcon", {
                        attrs: {
                          count: _vm.unlicensedCount,
                          showIcon: _vm.mini,
                          active: _vm.isFeatureActive(_vm.settingsFeature)
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile-content",
                    { staticStyle: { width: "185px" } },
                    [
                      _c(
                        "v-list-tile-title",
                        { style: _vm.getTextColor(_vm.settingsFeature) },
                        [_vm._v(_vm._s(_vm.$t("biz.main.settings")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile-content",
                    [
                      _c("LicenseSettingsBadge", {
                        attrs: {
                          hiddenIfZero: true,
                          count: _vm.unlicensedCount
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-tile",
                {
                  staticClass: "unicolorList",
                  on: { click: _vm.showUserMenu }
                },
                [
                  _c(
                    "v-list-tile-action",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("v-icon", _vm._g({}, on), [
                                    _vm._v("$vuetify.icons.bus_navigation_user")
                                  ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("biz.general.tooltip.user")))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile-content",
                    [
                      _c(
                        "v-list-tile-title",
                        { staticStyle: { color: "white" } },
                        [_vm._v(_vm._s(_vm.userName))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-tile",
                {
                  class: _vm.getFeatureClass(_vm.helpFeature),
                  attrs: { to: "/" + _vm.helpFeature.to },
                  on: { click: _vm.itemSelected }
                },
                [
                  _c(
                    "v-list-tile-action",
                    [
                      _c("NavigationItem", {
                        attrs: {
                          feature: _vm.helpFeature,
                          active: _vm.isFeatureActive(_vm.helpFeature)
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-tile-content",
                    [
                      _c(
                        "v-list-tile-title",
                        { style: _vm.getTextColor(_vm.helpFeature) },
                        [_vm._v(_vm._s(_vm.$t("biz.main.help")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("UserMenu", {
        attrs: { menuShown: _vm.userMenuShown },
        on: {
          evtClose: function($event) {
            return _vm.closeUserMenu()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }