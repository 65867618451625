var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      directives: [
        {
          name: "track",
          rawName: "v-track",
          value: _vm.track + "::ShowEditor",
          expression: "track + '::ShowEditor'"
        }
      ],
      staticStyle: { "margin-top": "2px" },
      attrs: {
        slot: "activator",
        color: "secondary",
        outline: "",
        depressed: "",
        disabled: _vm.editDisabled
      },
      on: {
        click: function($event) {
          return _vm.onEditPressed()
        }
      },
      slot: "activator"
    },
    [
      _c("v-icon", { staticClass: "mr-2" }, [_vm._v("edit")]),
      _vm._v("\n  " + _vm._s(_vm.$t("general.edit")) + "\n")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }