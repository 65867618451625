var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "grid", "grid-template-columns": "0.5fr 2fr" } },
    [
      _c(
        "v-flex",
        {
          staticClass: "d-flex align-center bus_normaltext",
          staticStyle: { width: "295px" },
          attrs: { "ml-4": "", "mb-2": "", "mt-2": "", shrink: "" }
        },
        [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", {
            staticStyle: {
              "border-right": "1px solid var(--v-neutral20-base)"
            },
            attrs: { shrink: "", "pl-2": "" }
          }),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "", "ma-2": "", "pl-1": "" } },
            _vm._l(_vm.appliances, function(item) {
              return _c(
                "div",
                { key: item.virtualFridgeId },
                [
                  _c("ExtendableItem", {
                    attrs: {
                      measureSite: item,
                      hasSensors: _vm.hasCompanySensors
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }