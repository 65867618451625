var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { dark: "", fullscreen: "", persistent: "" },
      model: {
        value: _vm.dialogPdfShown,
        callback: function($$v) {
          _vm.dialogPdfShown = $$v
        },
        expression: "dialogPdfShown"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "card" },
        [
          _c(
            "v-layout",
            {
              staticClass: "header",
              attrs: { "mb-3": "", row: "", nowrap: "" }
            },
            [
              _c(
                "v-flex",
                {
                  staticStyle: { "margin-top": "30px" },
                  attrs: { shrink: "", "ml-4": "" }
                },
                [
                  _c("p", { staticClass: "title font-weight-light" }, [
                    _vm._v(_vm._s(_vm.getReportName()))
                  ])
                ]
              ),
              _c(
                "v-flex",
                { attrs: { "align-end": "", grow: "", "ma-3": "" } },
                [
                  _c("ReportActions", {
                    staticStyle: { float: "right" },
                    attrs: { dialogPdfShown: _vm.dialogPdfShown },
                    on: {
                      evtPrint: function($event) {
                        return _vm.printReport()
                      },
                      evtDownload: function($event) {
                        return _vm.downloadReport()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { staticStyle: { width: "150px" } },
                [
                  _c("v-img", {
                    staticClass: "overlay",
                    attrs: {
                      src: require("@/assets/logo_ci_liebherr.svg"),
                      contain: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "closebutton", attrs: { "justify-end": "" } },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: "ReportPreview::ReportPreviewFullScreen::Close",
                      expression:
                        "'ReportPreview::ReportPreviewFullScreen::Close'"
                    }
                  ],
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "bus_icon_whitening" }, [
                    _vm._v("$vuetify.icons.bus_close")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.pageCount, function(page) {
            return _c(
              "div",
              {
                key: page,
                staticStyle: {
                  position: "relative",
                  overflow: "hidden",
                  "padding-top": "100px"
                }
              },
              [
                _c("canvas", {
                  staticStyle: {
                    margin: "auto",
                    display: "block",
                    "margin-bottom": "50px",
                    width: "85%",
                    "max-width": "1200px"
                  },
                  attrs: { id: "pdf-preview" + page }
                })
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }