var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticStyle: { "justify-content": "flex-end" } },
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: _vm.track,
              expression: "track"
            }
          ],
          staticClass: "hidden-md-and-down",
          staticStyle: { color: "var(--v-bus_black-base) !important" },
          attrs: { depressed: "", flat: "", disabled: _vm.updateDisabled },
          on: {
            click: function($event) {
              return _vm.doUpdate()
            }
          }
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.updateDisabled,
                  expression: "!updateDisabled"
                }
              ],
              staticClass: "pr-1"
            },
            [_vm._v("$vuetify.icons.bus_general_reload")]
          ),
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("general.lastupdate")) +
              ": " +
              _vm._s(_vm.$format.time(_vm.lastUpdate)) +
              "\n  "
          )
        ],
        1
      ),
      _c(
        "v-tooltip",
        { attrs: { left: "" } },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track,
                  expression: "track"
                }
              ],
              staticClass: "hidden-lg-and-up",
              staticStyle: { color: "var(--v-bus_black-base) !important" },
              attrs: {
                slot: "activator",
                depressed: "",
                flat: "",
                disabled: _vm.updateDisabled
              },
              on: {
                click: function($event) {
                  return _vm.doUpdate()
                }
              },
              slot: "activator"
            },
            [
              _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.updateDisabled,
                      expression: "!updateDisabled"
                    }
                  ],
                  staticClass: "pr-1"
                },
                [_vm._v("$vuetify.icons.bus_general_reload")]
              ),
              _vm._v(
                "\n      " + _vm._s(_vm.$format.time(_vm.lastUpdate)) + "\n    "
              )
            ],
            1
          ),
          _c("span", [_vm._v(_vm._s(_vm.$t("general.lastupdate")))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }