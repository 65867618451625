var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", fluid: "", "pa-0": "", "mx-0": "" } },
        [
          _c("NavigationDrawer"),
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.sessionTimedOut,
                  expression: "!sessionTimedOut"
                }
              ],
              staticClass: "routerview"
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "maincard",
                  attrs: { width: "100%", height: "100%" }
                },
                [
                  _c("router-view", {
                    key:
                      _vm.$route.name + (_vm.$route.params.serialNumber || "")
                  })
                ],
                1
              ),
              _c("UserNotificationHandler"),
              _c("LicenseWarningDialog", {
                attrs: {
                  dialogShown: _vm.showLicenseWarningDialog,
                  unlicensedCount: _vm.devicesMissingLicenseCount,
                  track: "HomePage::LicenseWarningDialog"
                },
                on: {
                  evtClose: function($event) {
                    return _vm.closeLicenseWarningDialog()
                  }
                }
              }),
              _c("AccountWarningDialog", {
                attrs: {
                  dialogShown: _vm.showAccountWarningDialog,
                  track: "HomePage::AccountWarningDialog"
                },
                on: {
                  evtClose: function($event) {
                    return _vm.closeAccountWarningDialog()
                  }
                }
              }),
              _c("MissingPhoneDialog", {
                attrs: {
                  dialogShown: _vm.showMissingPhoneDialog,
                  userSettings: _vm.userSettings,
                  admin: _vm.isAdminUser,
                  track: "HomePage::MissingPhoneDialog"
                },
                on: {
                  evtClose: function($event) {
                    return _vm.closeMissingPhoneDialog()
                  }
                }
              }),
              _c("CookieDialog", {
                attrs: {
                  dialogShown: _vm.showCookieSettingsDialog,
                  track: "HomePage::CookieDialog"
                },
                on: {
                  evtClose: function($event) {
                    return _vm.closeCookieSettingsDialog()
                  }
                }
              }),
              _c("OrganizationAreaDialog", {
                attrs: {
                  dialogShown: _vm.showOrganizationAreaDialog,
                  track: "HomePage::OrganizationAreaDialog"
                },
                on: { evtClose: _vm.closeOrganizationAreaDialog }
              }),
              _c("TermsOfUseAcceptanceDialog", {
                attrs: {
                  acceptedVersion: _vm.acceptedTermsOfUseVersion,
                  currentVersion: _vm.currentTermsOfUseVersion,
                  dialogShown: _vm.showTermsOfUseDialog,
                  track: "HomePage::TermsOfUseAcceptanceDialog"
                },
                on: {
                  evtCloseAgree: function($event) {
                    return _vm.closeTermsOfUseDialogAgree()
                  },
                  evtCloseReject: function($event) {
                    return _vm.closeTermsOfUseDialogReject()
                  }
                }
              }),
              _c("SmartModuleWarningDialog", {
                attrs: {
                  dialogShown: _vm.showSmartModuleWarningDialog,
                  incompleteSetupSmartModules: _vm.incompleteSetupSmartModules
                },
                on: {
                  evtCompleteOnBoarding: function($event) {
                    return _vm.navigateToSmartModule()
                  },
                  evtClose: function($event) {
                    return _vm.closeSmartModuleDialog()
                  }
                }
              })
            ],
            1
          ),
          _c("TimeoutDialog", { attrs: { dialogShown: _vm.sessionTimedOut } }),
          _c("LastUpdateIndicator", { staticClass: "bus_update_indicator" }),
          _c("v-img", {
            staticClass: "bus_liebherr_logo",
            attrs: {
              id: "logo",
              src: require("@/assets/logo_ci_liebherr.svg"),
              contain: ""
            }
          })
        ],
        1
      ),
      _c("LegalDocumentsBar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }