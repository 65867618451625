var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "pl-2 pr-2 pb-2", attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs2: "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              !_vm.specialSlider
                ? _c("v-text-field", {
                    staticStyle: {
                      "text-align-last": "center",
                      "font-size": "14px"
                    },
                    attrs: {
                      "append-outer-icon": _vm.getIconPlus(),
                      "prepend-icon": _vm.getIconMinus(),
                      type: "number",
                      disabled: !_vm.active
                    },
                    on: {
                      "click:append-outer": _vm.incrementSliderValue,
                      "click:prepend": _vm.decrementSliderValue
                    },
                    model: {
                      value: _vm.localSliderValue,
                      callback: function($$v) {
                        _vm.localSliderValue = $$v
                      },
                      expression: "localSliderValue"
                    }
                  })
                : _c("v-text-field", {
                    staticStyle: {
                      "text-align-last": "center",
                      "font-size": "14px"
                    },
                    attrs: {
                      "append-outer-icon": _vm.getIconPlus(),
                      "prepend-icon": _vm.getIconMinus(),
                      type: "number",
                      disabled: !_vm.active
                    },
                    on: {
                      "click:append-outer": _vm.incrementSpecialSliderValue,
                      "click:prepend": _vm.decrementSpecialSliderValue
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "input",
                        fn: function(props) {
                          return [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.formatDoorDelay(props.value)) +
                                  "\n          "
                              )
                            ])
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.formatedSliderValue,
                      callback: function($$v) {
                        _vm.formatedSliderValue = $$v
                      },
                      expression: "formatedSliderValue"
                    }
                  }),
              _c(
                "label",
                {
                  staticStyle: {
                    "font-size": "14px",
                    "text-align": "center",
                    color: "gray",
                    "margin-top": "-25px"
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$tc("general.duration.minute", _vm.minValue))
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs8: "" } },
        [
          !_vm.specialSlider
            ? _c("v-slider", {
                staticClass: "pl-4 pr-4",
                attrs: {
                  disabled: !_vm.active,
                  min: _vm.minValue,
                  max: _vm.maxValue,
                  step: _vm.step,
                  "thumb-label": "always",
                  "always-dirty": ""
                },
                model: {
                  value: _vm.localSliderValue,
                  callback: function($$v) {
                    _vm.localSliderValue = $$v
                  },
                  expression: "localSliderValue"
                }
              })
            : _c("v-slider", {
                staticClass: "pl-4 pr-4",
                attrs: {
                  disabled: !_vm.active,
                  min: 0,
                  max: _vm.sliderValues.length - 1,
                  "thumb-label": "always",
                  "always-dirty": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "thumb-label",
                    fn: function(props) {
                      return [
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatValue(props.value)) +
                              "\n        "
                          )
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.index,
                  callback: function($$v) {
                    _vm.index = $$v
                  },
                  expression: "index"
                }
              })
        ],
        1
      ),
      _c(
        "v-flex",
        {
          staticStyle: {
            "text-align": "center",
            "font-size": "14px",
            margin: "auto"
          },
          attrs: { xs2: "" }
        },
        [
          _c("v-layout", { attrs: { column: "" } }, [
            _c(
              "label",
              {
                staticStyle: {
                  "font-size": "14px",
                  color: "gray",
                  "margin-top": "25px"
                }
              },
              [_vm._v(_vm._s(_vm.$tc("general.duration.minute", _vm.maxValue)))]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }