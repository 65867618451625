var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.loading,
          expression: "!loading"
        }
      ]
    },
    [
      _vm.hasCompanySensors
        ? _c(
            "v-layout",
            {
              staticStyle: { "margin-right": "80px" },
              attrs: { "justify-end": "" }
            },
            [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-right": "20px",
                    "margin-bottom": "-20px"
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("biz.appliancehistory.dooropeningsinfo.desc")
                    ) + ":"
                  )
                ]
              ),
              _c("v-badge", {
                staticStyle: { "font-weight": "normal", "margin-top": "5px" },
                attrs: { overlap: "", color: "secondary" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "badge",
                      fn: function() {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$format.smallBadgeNumber(
                                  _vm.countOfDoorOpenings
                                )
                              )
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3822663940
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("apexchart", {
        attrs: {
          id: "historyChart",
          type: "area",
          height: "500",
          options: _vm.detailedHistoryChartOptions,
          series: _vm.seriesForHistoryChart
        }
      }),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.seriesForHistoryChart.length > 1,
              expression: "seriesForHistoryChart.length > 1"
            }
          ],
          staticStyle: { "margin-bottom": "50px" },
          attrs: { "justify-center": "" }
        },
        [
          _c(
            "p",
            {
              staticClass: "bus_normaltext",
              staticStyle: {
                "margin-right": "20px",
                "margin-bottom": "-20px",
                "margin-top": "-20px"
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("biz.appliancehistory.legendhelptext")) +
                  "\n    "
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }