var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.wifiReset
    ? _c("SmartModuleResetLanding")
    : _c("SmartModuleLanding", {
        attrs: {
          serialNumber: _vm.serialNumber,
          onboardingFlow: _vm.onboardingFlow
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }