var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tileType === _vm.TileTypeEnum.GATEWAY
    ? _c(
        "v-flex",
        { staticStyle: { width: "100%" } },
        [_c("MobileGatewayItem", { attrs: { gateway: _vm.gateway } })],
        1
      )
    : _vm.tileType === _vm.TileTypeEnum.MEASURE_SITE &&
      !_vm.hasTwoZones &&
      !_vm.isSmartModule
    ? _c(
        "v-flex",
        { staticStyle: { width: "100%" } },
        [
          !_vm.hasSensors
            ? _c("MobileDeviceItem", {
                attrs: { applianceZone: _vm.measureSite.zones[0] }
              })
            : _c("MobileMeasureSiteItem", {
                attrs: { measureSite: _vm.measureSite }
              })
        ],
        1
      )
    : _vm.tileType === _vm.TileTypeEnum.MEASURE_SITE && _vm.hasTwoZones
    ? _c(
        "v-flex",
        { staticStyle: { width: "100%" } },
        [
          !_vm.hasSensors
            ? _c("MobileDeviceTwoZoneItem", {
                attrs: { mainApplianceZone: _vm.measureSite.zones[0] }
              })
            : _c("MobileMeasureSiteTwoZoneItem", {
                attrs: { mainApplianceZone: _vm.measureSite.zones[0] }
              })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }