var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "", "pa-2": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "justify-space-between": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs8: "", "pb-0": "" } },
                    [
                      _c("v-card-title", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("biz.general.items.mydevices")))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs4: "", "pb-0": "" } },
                    [
                      _c("v-text-field", {
                        staticClass: "default selected",
                        attrs: {
                          clearable: _vm.iconHelper.clearableIcon(
                            _vm.searchString
                          ),
                          placeholder: _vm.$t("biz.groups.search") + "..."
                        },
                        scopedSlots: _vm._u(
                          [
                            !_vm.iconHelper.clearableIcon(_vm.searchString)
                              ? {
                                  key: "append",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        { staticClass: "searchicon" },
                                        [_vm._v("search")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.searchString,
                          callback: function($$v) {
                            _vm.searchString = $$v
                          },
                          expression: "searchString"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.currentTab,
                    callback: function($$v) {
                      _vm.currentTab = $$v
                    },
                    expression: "currentTab"
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "Devices::MeasureSites",
                          expression: "'Devices::MeasureSites'"
                        }
                      ]
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.groups.measuresites")))]
                  ),
                  _c(
                    "v-tab",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "Devices::SmartCoolingHubs",
                          expression: "'Devices::SmartCoolingHubs'"
                        }
                      ]
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.group.bsdbs")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.allDevicesHidden && _vm.appliances.length != 0
            ? _c(
                "v-layout",
                {
                  attrs: {
                    column: "",
                    "align-center": "",
                    "justify-center": "",
                    "fill-height": ""
                  }
                },
                [
                  _c("v-icon", { attrs: { size: "50" } }, [
                    _vm._v(_vm._s(_vm.iconHelper.getApplianceMissingIcon()))
                  ]),
                  _vm.currentTab === 0
                    ? _c("p", { staticClass: "title mt-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("biz.nodatascreen.nomeasuresites.title")
                          )
                        )
                      ])
                    : _c("p", { staticClass: "title mt-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("biz.nodatascreen.mydevices.noschtitle")
                          )
                        )
                      ]),
                  _c("p", { staticClass: "subtitle" }, [
                    _vm._v(_vm._s(_vm.$t("biz.groups.filternoresult")))
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.dataIsMissing
            ? _c(
                "v-flex",
                { staticStyle: { "margin-top": "50px" } },
                [
                  _c("DataMissingDisplay", {
                    attrs: { interestedIn: _vm.selectedCurrentTab },
                    on: {
                      evtMeasureSitesData: function($event) {
                        _vm.applianceDataIsMissing = false
                      },
                      evtBsdbData: function($event) {
                        _vm.hubDataIsMissing = false
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            {
              staticClass: "scroll-y",
              staticStyle: { position: "relative" },
              attrs: { "px-0": "", "ml-2": "", grow: "" }
            },
            [
              !_vm.dataIsMissing
                ? _c(
                    "v-layout",
                    {
                      staticStyle: { position: "absolute", width: "100%" },
                      attrs: {
                        "pt-2": "",
                        row: "",
                        wrap: "",
                        "align-start": ""
                      }
                    },
                    _vm._l(_vm.filteredDevices, function(item) {
                      return _c(
                        "v-flex",
                        {
                          key: item.name,
                          staticClass: "pa-0",
                          attrs: { shrink: "" }
                        },
                        [
                          _vm.currentTab === 1
                            ? _c("ExtendableItem", {
                                attrs: {
                                  tileType: _vm.TileTypeEnum.GATEWAY,
                                  gateway: item
                                }
                              })
                            : _c("ExtendableItem", {
                                attrs: {
                                  measureSite: item,
                                  hasSensors: _vm.hasSensors
                                }
                              })
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          !_vm.dataIsMissing
            ? _c(
                "v-flex",
                {
                  staticStyle: { "border-top": "1px solid lightgrey" },
                  attrs: { shrink: "" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: _vm.currentButton.buttonTrack,
                          expression: "currentButton.buttonTrack"
                        }
                      ],
                      staticStyle: { float: "right" },
                      attrs: {
                        depressed: "",
                        color: "primary",
                        to: _vm.currentButton.buttonRoute
                      }
                    },
                    [_vm._v(_vm._s(_vm.currentButton.buttonText))]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }