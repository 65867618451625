var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "", column: "" } },
    [
      _c(
        "v-flex",
        [
          _c("p", { staticClass: "bus_normaltext mb-3" }, [
            _vm._v(_vm._s(_vm.$t("biz.onboarding.stepper.selectgroup")))
          ]),
          _c("v-select", {
            staticClass: "default selected",
            attrs: {
              chips: "",
              items: _vm.allGroups,
              "full-width": "",
              "menu-props": { bottom: true, offsetY: true },
              placeholder: _vm.$t("biz.applianceinfo.appliancegroup")
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(selection) {
                  return [
                    _vm._v(
                      _vm._s(
                        selection.item.defaultGroup
                          ? _vm.$t("biz.onboarding.stepper.nogroup")
                          : selection.item.name
                      )
                    )
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      _vm._s(
                        item.defaultGroup
                          ? _vm.$t("biz.onboarding.stepper.nogroup")
                          : item.name
                      )
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedGroup,
              callback: function($$v) {
                _vm.selectedGroup = $$v
              },
              expression: "selectedGroup"
            }
          })
        ],
        1
      ),
      _c("v-flex", [
        _c("p", { staticClass: "bus_normaltext mb-3" }, [
          _vm._v(_vm._s(_vm.$t("biz.onboarding.stepper.newgroup")))
        ])
      ]),
      _c(
        "v-flex",
        [
          _c(
            "v-layout",
            { staticStyle: { "margin-left": "-5px" }, attrs: { row: "" } },
            [
              _c(
                "v-form",
                {
                  ref: "groupForm",
                  staticStyle: { width: "100%", "padding-left": "5px" }
                },
                [
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    staticStyle: { height: "100px" },
                    attrs: {
                      width: "100%",
                      clearable: _vm.iconHelper.clearableIcon(_vm.newGroupName),
                      placeholder: _vm.$t("biz.groupeditor.groupname"),
                      rules: _vm.groupNameRules,
                      type: "string",
                      required: ""
                    },
                    model: {
                      value: _vm.newGroupName,
                      callback: function($$v) {
                        _vm.newGroupName = $$v
                      },
                      expression: "newGroupName"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: "SimpleGroupEditor::AddNewGroup",
                      expression: "'SimpleGroupEditor::AddNewGroup'"
                    }
                  ],
                  staticClass: "ma-2",
                  attrs: {
                    disabled: _vm.isGroupNameInvalid(),
                    depressed: "",
                    color: "primary"
                  },
                  on: { click: _vm.addNewGroup }
                },
                [_vm._v(_vm._s(_vm.$t("general.add")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }