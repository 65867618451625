var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-space-between": "", wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.sensors.sensorinfo.datatransfer")))
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { editDisabled: !_vm.licensed, track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        _vm.editorShown = true
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.sensors.sensorinfo.samplingrate")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.$tc("general.duration.minute", _vm.editIntervalInMinutes)
                )
              )
            ])
          ])
        ],
        1
      ),
      _c("SimpleDialog", {
        attrs: {
          dialogShown: _vm.editorShown,
          title: _vm.$t("biz.sensors.sensorinfo.properties"),
          buttonExit: false,
          message: _vm.$t("biz.sensors.setup.place.samplingtext"),
          track: _vm.track + "::Editor"
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c("BasicSlider", {
                  attrs: {
                    sliderValue: _vm.editIntervalInMinutes,
                    active: true,
                    minValue: _vm.sensorHelper.DEFAULT_SAMPLING_RATE_MINUTES,
                    maxValue: _vm.sensorHelper.MAX_SAMPLING_RATE_MINUTES,
                    step: 1
                  },
                  on: {
                    "update:sliderValue": function($event) {
                      _vm.editIntervalInMinutes = $event
                    },
                    "update:slider-value": function($event) {
                      _vm.editIntervalInMinutes = $event
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "buttons",
            fn: function() {
              return [
                _c("v-spacer"),
                _c("SaveCancel", {
                  attrs: {
                    saveDisabled: _vm.saveDisabled,
                    track: _vm.track,
                    icon: false
                  },
                  on: {
                    evtSave: function($event) {
                      return _vm.onSave()
                    },
                    evtCancel: function($event) {
                      return _vm.onCancel()
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }