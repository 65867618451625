var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.general.items.connecteddevices")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.measureSiteList.length <= 0
        ? _c("v-flex", { staticStyle: { "margin-left": "-8px" } }, [
            _vm._v(_vm._s(_vm.$t("biz.nodatascreen.nomeasuresites.title")))
          ])
        : _vm._l(_vm.measureSiteList, function(item) {
            return _c(
              "v-flex",
              {
                key: item.serialNumber,
                staticStyle: { "margin-left": "-15px" }
              },
              [
                _c("ExtendableItem", {
                  staticClass: "mt-3",
                  attrs: { measureSite: item, hasSensors: false }
                })
              ],
              1
            )
          }),
      _vm.hasCompanySensors
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                [
                  _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                    _vm._v(_vm._s(_vm.$t("biz.applianceinfo.connectedsensors")))
                  ])
                ],
                1
              ),
              _vm.sensorsList.length <= 0
                ? _c("v-flex", [
                    _vm._v(_vm._s(_vm.$t("biz.nodatascreen.nosensors.title")))
                  ])
                : _vm._e(),
              _vm._l(_vm.sensorsList, function(sensor) {
                return _c(
                  "v-flex",
                  { key: sensor.sensorId },
                  [_c("SensorItem", { attrs: { sensor: sensor } })],
                  1
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm.isWiFiConnection
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                [
                  _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                    _vm._v(_vm._s(_vm.$t("biz.wifireset.wificonnection")))
                  ])
                ],
                1
              ),
              _c(
                "v-flex",
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "Connections::WiFiReset",
                          expression: "'Connections::WiFiReset'"
                        }
                      ],
                      staticStyle: { "margin-left": "0px" },
                      attrs: { depressed: "", outline: "", color: "secondary" },
                      on: { click: _vm.resetWiFi }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.wifireset.resetconnection")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }