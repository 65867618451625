var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.icon
        ? _c(
            "v-tooltip",
            { attrs: { top: "" } },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::Send",
                      expression: "track + '::Send'"
                    }
                  ],
                  attrs: {
                    slot: "activator",
                    depressed: "",
                    icon: "",
                    disabled: _vm.sendDisabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.onSendPressed()
                    }
                  },
                  slot: "activator"
                },
                [_c("v-icon", [_vm._v("$vuetify.icons.bus_send")])],
                1
              ),
              _c("span", [_vm._v(_vm._s(_vm.$t("general.send")))])
            ],
            1
          )
        : _vm._e(),
      !_vm.icon
        ? _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track + "::Send",
                  expression: "track + '::Send'"
                }
              ],
              attrs: {
                slot: "activator",
                depressed: "",
                color: "primary",
                disabled: _vm.sendDisabled
              },
              on: {
                click: function($event) {
                  return _vm.onSendPressed()
                }
              },
              slot: "activator"
            },
            [_vm._v(_vm._s(_vm.$t("general.send")))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }