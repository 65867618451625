var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseWindowPage", {
        attrs: { trackCancel: _vm.track + "::Close" },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(_vm._s(_vm.$t("biz.sensors.setup.bsdb_step.title")))
              ]
            },
            proxy: true
          },
          {
            key: "description",
            fn: function() {
              return [
                _vm._v("\n      " + _vm._s(_vm.getDescription()) + "\n    ")
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-list",
                  { attrs: { "three-line": "" } },
                  [
                    _vm._l(_vm.gateways, function(item) {
                      return [
                        _c(
                          "v-list-tile",
                          {
                            key: item.gatewayId,
                            class: _vm.highlightSelected(item.gatewayId),
                            staticStyle: {
                              "padding-top": "1px",
                              "padding-left": "12px"
                            },
                            attrs: {
                              disabled:
                                _vm.isSensorLimitReached(item.gatewayId) ||
                                _vm.isGatewayOffline(item.gatewayId)
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.onGatewaySelection(item.gatewayId)
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-title", [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _vm.isGatewayOffline(item.gatewayId)
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-tile-sub-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticStyle: {
                                                  "vertical-align": "middle"
                                                },
                                                attrs: {
                                                  color: "orange",
                                                  size: "14"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "$vuetify.icons.bus_exclamation_triangle"
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t("general.offline")
                                                )
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          width: "100%",
                                          "justify-content": "space-between"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("v-list-tile-sub-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getGatewayApplianceText(
                                                    item
                                                  )
                                                )
                                              )
                                            ]),
                                            _c("v-list-tile-sub-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getGatewaySensorText(item)
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm.hasOutdatedSoftware(item.gatewayId)
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "track",
                                                        rawName: "v-track",
                                                        value:
                                                          "SelectBsdbStep::UpdateOutdatedSoftware",
                                                        expression:
                                                          "'SelectBsdbStep::UpdateOutdatedSoftware'"
                                                      }
                                                    ],
                                                    staticClass: "ml-0",
                                                    attrs: {
                                                      depressed: "",
                                                      color: "secondary",
                                                      outline: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openSoftwareUpdateDialog(
                                                          item.gateway
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pr-1",
                                                        attrs: {
                                                          color: "secondary"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.helpIcon)
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "biz.onboarding.start.updatesoftware"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    })
                  ],
                  2
                ),
                _c("CheckFirmwareDialog", {
                  attrs: {
                    hubCheckRunning: _vm.hubCheckRunning,
                    showSchUpdateNeeded: _vm.showSchUpdateNeeded,
                    showSchOffline: _vm.showSchOffline
                  }
                }),
                _c("ErrorWithHelpDialog", {
                  attrs: {
                    dialogShown: _vm.showScanError,
                    title: _vm.$t("biz.onboarding.error.scantitle"),
                    message: _vm.$t("biz.onboarding.error.scanmessage"),
                    track: "track + '::StartScanError'"
                  },
                  on: {
                    evtCancelPressed: function($event) {
                      return _vm.onErrorOkay()
                    },
                    evtRetryPressed: function($event) {
                      return _vm.retryScan()
                    }
                  }
                }),
                _c("BsdbSoftwareUpdateDialog", {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: "SelectBsdbStep::SoftwareUpdateDialog",
                      expression: "'SelectBsdbStep::SoftwareUpdateDialog'"
                    }
                  ],
                  attrs: {
                    dialogShown: _vm.showSoftwareUpdateDialog,
                    selectedGateway: _vm.selectedGateway
                  },
                  on: {
                    evtClose: function($event) {
                      _vm.showSoftwareUpdateDialog = false
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "helplink",
            fn: function() {
              return [
                _c(
                  "v-flex",
                  [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: _vm.track + "::HelpSmartcoolingHubNotFound",
                            expression:
                              "track + '::HelpSmartcoolingHubNotFound'"
                          }
                        ],
                        attrs: {
                          depressed: "",
                          color: "secondary",
                          outline: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.showBsdbNotFoundDialog()
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: { color: "secondary" }
                          },
                          [_vm._v("help_outline")]
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("biz.onboarding.bsdbdialog.bsdbnotfound")
                            ) +
                            "\n        "
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.selectedGateway &&
                _vm.hasOutdatedSoftware(_vm.selectedGateway.gatewayId)
                  ? _c("p", { staticClass: "bus_errortext pl-2" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "biz.onboarding.start.outdatedsoftwarewarning"
                            )
                          ) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }