// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import { ErrorDto, Manuals } from '../models';

export interface GetManualsUsingGETRequest {
  locale: string;
  serialNumber: string;
}

/**
 * no description
 */
export class DocumentationControllerApi extends BaseAPI {
  /**
   * getManuals
   * Gets the link to the manual based on the serial number and a locale.
   */
  getManualsUsingGET = (requestParameters: GetManualsUsingGETRequest): Observable<Manuals> => {
    throwIfRequired(requestParameters, 'locale', 'getManualsUsingGET');
    throwIfRequired(requestParameters, 'serialNumber', 'getManualsUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Manuals>({
      path: '/documentation/manuals/{locale}/{serialNumber}'
        .replace('{locale}', encodeURI(requestParameters.locale))
        .replace('{serialNumber}', encodeURI(requestParameters.serialNumber)),
      method: 'GET',
      headers
    });
  };
}
