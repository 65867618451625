var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "biz.sensors.alarmsetting.sensorunavailability.title"
                    )
                  )
                )
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "align-items: end", attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs9: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "biz.sensors.alarmsetting.sensorunavailability.description"
                  )
                )
              )
            ])
          ]),
          _c("v-flex", { attrs: { xs3: "" } }, [
            _vm.editableData.unavailabilityAlarmActive
              ? _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v("(" + _vm._s(_vm.$t("general.activated")) + ")")
                ])
              : _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v("(" + _vm._s(_vm.$t("general.deactivated")) + ")")
                ])
          ])
        ],
        1
      ),
      _c("EditSensorUnavailableAlarm", {
        attrs: {
          editorShown: _vm.editorShown,
          editableData: _vm.editableData,
          track: _vm.track + "::Editor"
        },
        on: {
          "update:editorShown": function($event) {
            _vm.editorShown = $event
          },
          "update:editor-shown": function($event) {
            _vm.editorShown = $event
          },
          evtSave: _vm.onSave
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }