var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-secondary-base)" },
            attrs: {
              d:
                "M6.33349529,8 L9.23776038,11.0131259 C9.62103544,11.4107673 9.60938923,12.0438251 9.21174784,12.4271002 C8.81410645,12.8103753 8.18104859,12.7987291 7.79777353,12.4010877 L2.63363063,7.04337678 L7.77445565,1.33105433 C8.14390414,0.920534836 8.77619312,0.887240206 9.18671262,1.2566887 C9.59723212,1.62613719 9.63052675,2.25842617 9.26107826,2.66894567 L6.263284,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,19 C22,20.1045695 21.1045695,21 20,21 L7,21 C6.44771525,21 6,20.5522847 6,20 C6,19.4477153 6.44771525,19 7,19 L20,19 L20,8 L6.33349529,8 Z"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }