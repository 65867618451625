var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      _c(
        "v-layout",
        {
          staticClass: "title",
          attrs: { slot: "header", "py-3": "" },
          slot: "header"
        },
        [_vm._v(_vm._s(_vm.$t("biz.general.items.alarms")))]
      ),
      _c("v-divider"),
      _c(
        "v-container",
        {
          attrs: {
            "px-0": "",
            "pt-4": "",
            "pb-2": "",
            "fill-height": "",
            "grid-list-lg": "",
            fluid: ""
          }
        },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }