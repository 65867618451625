var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", wrap: "" } },
    [
      _c("CardTileBase", {
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function() {
              return [_vm._v(_vm._s(_vm.getBigSettingIcon()))]
            },
            proxy: true
          },
          {
            key: "title",
            fn: function() {
              return [_vm._v(_vm._s(_vm.$t("biz.settings.generalsettings")))]
            },
            proxy: true
          },
          {
            key: "text",
            fn: function() {
              return [
                _vm._v(_vm._s(_vm.$t("biz.settings.generalsettingstext")))
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return undefined
            },
            proxy: true
          },
          {
            key: "button",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: "Settings::ToGeneralSettings",
                        expression: "'Settings::ToGeneralSettings'"
                      }
                    ],
                    staticStyle: { width: "90%" },
                    attrs: { depressed: "", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.toGeneralSettings()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("biz.settings.generalsettingsbutton")))]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.isAdminUser
        ? _c("CardTileBase", {
            scopedSlots: _vm._u(
              [
                {
                  key: "icon",
                  fn: function() {
                    return [_vm._v(_vm._s(_vm.getApplianceBigIcon()))]
                  },
                  proxy: true
                },
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(_vm._s(_vm.$t("biz.settings.devicemanagement")))
                    ]
                  },
                  proxy: true
                },
                {
                  key: "text",
                  fn: function() {
                    return [
                      _vm._v(
                        _vm._s(_vm.$t("biz.settings.devicemanagementdesc"))
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.$tc(
                              "biz.settings.appliancecount",
                              _vm.getAllApplianceIds.length
                            )
                          )
                        )
                      ]),
                      _c("div", { staticClass: "mt-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$tc(
                              "biz.settings.bsdbcount",
                              _vm.getBsdbHubs.length
                            )
                          )
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "button",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value: "Settings::ToDeviceManagement",
                              expression: "'Settings::ToDeviceManagement'"
                            }
                          ],
                          staticStyle: { width: "90%" },
                          attrs: { depressed: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toDeviceManagement()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("biz.settings.editdevices")))]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              136768195
            )
          })
        : _vm._e(),
      _vm.isAdminUser
        ? _c("CardTileBase", {
            scopedSlots: _vm._u(
              [
                {
                  key: "icon",
                  fn: function() {
                    return [_vm._v(_vm._s(_vm.getLicenseIcon()))]
                  },
                  proxy: true
                },
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(_vm._s(_vm.$t("biz.settings.licensemanagement")))
                    ]
                  },
                  proxy: true
                },
                {
                  key: "text",
                  fn: function() {
                    return [_vm._v(_vm._s(_vm.licenseDescription))]
                  },
                  proxy: true
                },
                _vm.isFullAdminUser
                  ? {
                      key: "content",
                      fn: function() {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$tc(
                                  "biz.settings.licensedcount",
                                  _vm.getLicensedCount
                                )
                              )
                            )
                          ]),
                          _c("div", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tc(
                                  "biz.settings.unassignedlicensescount",
                                  _vm.getUnassignedLicenseCount
                                )
                              )
                            )
                          ]),
                          _vm.getUnlicensedAppliancesCount > 0
                            ? _c(
                                "div",
                                { staticClass: "mt-1" },
                                [
                                  _c("LicenseSettingsBadge", {
                                    attrs: {
                                      hiddenIfZero: false,
                                      count: _vm.getUnlicensedAppliancesCount
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-left": "28px",
                                        "margin-top": "-15px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            _vm.$tc(
                                              "biz.settings.unlicensedappliances",
                                              _vm.getUnlicensedAppliancesCount
                                            )
                                          ) +
                                          "\n        "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "button",
                  fn: function() {
                    return [
                      _vm.isFullAdminUser
                        ? _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "Settings::ToLicenseManagement",
                                  expression: "'Settings::ToLicenseManagement'"
                                }
                              ],
                              staticStyle: { width: "90%" },
                              attrs: { depressed: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.toLicenseManagement()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("biz.settings.managelicenses"))
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _vm.isAdminUser
        ? _c("CardTileBase", {
            scopedSlots: _vm._u(
              [
                {
                  key: "icon",
                  fn: function() {
                    return [_vm._v(_vm._s(_vm.getBigUserIcon()))]
                  },
                  proxy: true
                },
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(_vm._s(_vm.$t("biz.settings.usermanagement")))
                    ]
                  },
                  proxy: true
                },
                {
                  key: "text",
                  fn: function() {
                    return [_vm._v(_vm._s(_vm.userManagementDescription))]
                  },
                  proxy: true
                },
                _vm.isFullAdminUser
                  ? {
                      key: "content",
                      fn: function() {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$tc(
                                  "biz.settings.admincount",
                                  _vm.adminCount
                                )
                              )
                            )
                          ]),
                          _c("div", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tc("biz.settings.usercount", _vm.userCount)
                              )
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  : null,
                _vm.isFullAdminUser
                  ? {
                      key: "button",
                      fn: function() {
                        return [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "Settings::ToUserPermissions",
                                  expression: "'Settings::ToUserPermissions'"
                                }
                              ],
                              staticStyle: { width: "90%" },
                              attrs: { depressed: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.toUserPermissions()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("biz.settings.editcolleagues"))
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "Settings::ToAddUser",
                                  expression: "'Settings::ToAddUser'"
                                }
                              ],
                              attrs: {
                                depressed: "",
                                color: "secondary",
                                outline: "",
                                href: _vm.getAddUserLink(),
                                target: "_blank"
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-1" }, [
                                _vm._v(_vm._s(_vm.getRightArrowIcon()))
                              ]),
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("biz.settings.adduser")) +
                                  "\n      "
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  : null
              ],
              null,
              true
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }