var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          staticStyle: { fill: "var(--v-bus_red-base)" },
          attrs: {
            transform: "matrix(1, 0, 0, 1, 0, 4)",
            stroke: "none",
            "stroke-width": "1",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-bus_green-base)" },
            attrs: {
              d:
                "M16,0 L16,8 L2,8 L2,6 L0,6 L0,2 L2,2 L2,0 L16,0 Z M15,1 L3,1 L3,7 L15,7 L15,1 Z"
            }
          })
        ]
      ),
      _c("rect", {
        staticStyle: { fill: "var(--v-bus_green-base)" },
        attrs: {
          x: "4",
          y: "6",
          width: "2.681",
          height: "4",
          stroke: "none",
          "stroke-width": "1",
          "fill-rule": "evenodd"
        }
      }),
      _c("rect", {
        staticStyle: { fill: "var(--v-bus_green-base)" },
        attrs: {
          x: "7.675",
          y: "6",
          width: "2.681",
          height: "4",
          stroke: "none",
          "stroke-width": "1",
          "fill-rule": "evenodd"
        }
      }),
      _c("rect", {
        staticStyle: { fill: "var(--v-bus_green-base)" },
        attrs: {
          x: "11.268",
          y: "6",
          width: "2.681",
          height: "4",
          stroke: "none",
          "stroke-width": "1",
          "fill-rule": "evenodd"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }