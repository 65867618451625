var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: {
      title: _vm.title,
      dialogShown: _vm.dialogShown,
      track: "SensorError"
    },
    on: {
      evtClose: function($event) {
        return _vm.confirmAbort()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "additionalcontent",
        fn: function() {
          return [_vm._v(_vm._s(_vm.subtitle))]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("p", [
              _c("span", { domProps: { innerHTML: _vm._s(_vm.messageText) } })
            ]),
            _vm.contactsShown
              ? _c("ContactInfo", {
                  attrs: {
                    smartCoolingHubId: _vm.gatewaySerialNumber,
                    sensorId: _vm.sensorId
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "buttons",
        fn: function() {
          return [
            _vm.showResetDialogButton
              ? _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: { depressed: "", color: "secondary", outline: "" },
                    on: {
                      click: function($event) {
                        return _vm.showSensorPairedDialog()
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "pr-1", attrs: { color: "secondary" } },
                      [_vm._v("help_outline")]
                    ),
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t("biz.sensors.sensorassigneddialog.resetdevice")
                        ) +
                        "\n    "
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-layout",
              { attrs: { row: "", nowrap: "" } },
              [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: "SensorError::Help",
                        expression: "'SensorError::Help'"
                      }
                    ],
                    attrs: { depressed: "", outline: "", color: "secondary" },
                    on: {
                      click: function($event) {
                        return _vm.onHelpPressed()
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "pl-0", attrs: { color: "secondary" } },
                      [_vm._v("help_outline")]
                    ),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.help")) +
                        "\n      "
                    )
                  ],
                  1
                ),
                _c("v-spacer"),
                _vm.isAlreadyAssigned()
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value:
                                  "SensorError::AlreadyAssignedSensor::ToDashboard",
                                expression:
                                  "'SensorError::AlreadyAssignedSensor::ToDashboard'"
                              }
                            ],
                            attrs: {
                              depressed: "",
                              outline: "",
                              color: "secondary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.toDashboard()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("general.home.goto")))]
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: "SensorError::AbortOnboardingSensor",
                                expression:
                                  "'SensorError::AbortOnboardingSensor'"
                              }
                            ],
                            attrs: {
                              depressed: "",
                              color: "secondary",
                              outline: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.confirmAbort()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("general.cancel")))]
                        ),
                        _vm.repairingDetected()
                          ? _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track",
                                    value:
                                      "SensorError::RepairingDetected::ToSetupLanding",
                                    expression:
                                      "'SensorError::RepairingDetected::ToSetupLanding'"
                                  }
                                ],
                                attrs: { depressed: "", color: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.setupLanding()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "biz.sensors.setup.error.assignmentstate.restart"
                                    )
                                  )
                                )
                              ]
                            )
                          : _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track",
                                    value: "SensorError::Retry",
                                    expression: "'SensorError::Retry'"
                                  }
                                ],
                                attrs: {
                                  depressed: "",
                                  color: "primary",
                                  disabled: !_vm.retryEnabled
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onRetryPressed()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("general.retry")))]
                            )
                      ],
                      1
                    ),
                _c("SensorPairedDialog", {
                  attrs: { dialogShown: _vm.showSensorPaired },
                  on: {
                    evtClose: function($event) {
                      _vm.showSensorPaired = false
                    }
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }