// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AddonLicenseStatus
 */
export interface AddonLicenseStatus {
  /**
   * @type {string}
   * @memberof AddonLicenseStatus
   */
  assigneeType?: string;
  /**
   * @type {Date}
   * @memberof AddonLicenseStatus
   */
  endDate?: Date;
  /**
   * @type {string}
   * @memberof AddonLicenseStatus
   */
  licenseId?: string;
  /**
   * @type {boolean}
   * @memberof AddonLicenseStatus
   */
  licenseValid?: boolean;
  /**
   * @type {Date}
   * @memberof AddonLicenseStatus
   */
  startDate?: Date;
}
