var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "", "fill-height": "" } },
    [
      _c(
        "v-carousel",
        {
          staticClass: "logincarousel",
          attrs: { height: "100vh", "hide-delimiters": "", "hide-controls": "" }
        },
        [
          _vm._l(_vm.items, function(item, i) {
            return _c(
              "v-carousel-item",
              {
                key: i,
                staticStyle: { transition: "all 1s ease" },
                attrs: { src: item.src }
              },
              [_c("v-img", { attrs: { height: "100vh" } })],
              1
            )
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "380px", persistent: "" },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "rounded-card", attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "display-1 text-xs-center" },
                    [_vm._v(_vm._s(_vm.$t("general.home.loginDialog.login")))]
                  ),
                  _c("v-divider", { attrs: { light: "" } }),
                  _c(
                    "v-card-text",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.starting,
                          expression: "starting"
                        }
                      ],
                      staticClass: "text-xs-center"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("general.home.loginDialog.logincheck"))
                      )
                    ]
                  ),
                  _c(
                    "v-card-text",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.login,
                          expression: "login"
                        }
                      ],
                      staticClass: "text-xs-center"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("general.home.loginDialog.loginText"))
                      )
                    ]
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.login,
                              expression: "login"
                            },
                            {
                              name: "track",
                              rawName: "v-track",
                              value: "LoginDialog::Login",
                              expression: "'LoginDialog::Login'"
                            }
                          ],
                          attrs: {
                            block: "",
                            round: "",
                            depressed: "",
                            color: "primary",
                            dark: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.doLogin()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("general.home.loginDialog.loginStart")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }