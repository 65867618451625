var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550px", persistent: "", lazy: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { attrs: { "mb-3": "", row: "" } },
            [
              _c(
                "v-flex",
                { attrs: { "mt-3": "", "ml-3": "" } },
                [
                  _c("v-layout", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.help.servicedetails.savefailuretitle"))
                    )
                  ])
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: "ServiceDialog::ServiceHelpDialog::Close",
                      expression: "'ServiceDialog::ServiceHelpDialog::Close'"
                    }
                  ],
                  attrs: { depressed: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.IconHelper.getCloseIcon()))])],
                1
              )
            ],
            1
          ),
          _c("v-card-text", [
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("biz.help.servicedetails.savefailuremessage"))
              )
            ])
          ]),
          _c("ContactInfo")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }