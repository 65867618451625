var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        { attrs: { top: "" } },
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: _vm.track + "::Submit",
                  expression: "track + '::Submit'"
                }
              ],
              attrs: {
                slot: "activator",
                depressed: "",
                color: "primary",
                disabled: _vm.submitDisabled
              },
              on: {
                click: function($event) {
                  return _vm.onSubmitPressed()
                }
              },
              slot: "activator"
            },
            [_vm._v(_vm._s(_vm.$t("general.submit")))]
          ),
          _c("span", [_vm._v(_vm._s(_vm.$t("general.submit")))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }