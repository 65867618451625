// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import { ErrorDto, SupportInfo } from '../models';

export interface GetSupportInfoUsingGETRequest {
  countryCode: string;
}

/**
 * no description
 */
export class SupportControllerApi extends BaseAPI {
  /**
   * getSupportInfo
   */
  getSupportInfoUsingGET = (requestParameters: GetSupportInfoUsingGETRequest): Observable<SupportInfo> => {
    throwIfRequired(requestParameters, 'countryCode', 'getSupportInfoUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SupportInfo>({
      path: '/support/info/{countryCode}'.replace('{countryCode}', encodeURI(requestParameters.countryCode)),
      method: 'GET',
      headers
    });
  };
}
