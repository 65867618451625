var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-card-title",
                { staticClass: "bus_propertytitle" },
                [_vm._t("title")],
                2
              )
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "align-items: end", attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [_vm._t("text")], 2)
          ])
        ],
        1
      ),
      _c("v-layout", { attrs: { xs12: "" } }, [_vm._t("description")], 2),
      _c("v-layout", { attrs: { xs12: "" } }, [_vm._t("description1")], 2),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.editorShown,
            callback: function($$v) {
              _vm.editorShown = $$v
            },
            expression: "editorShown"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c("EditUnavailability", {
                attrs: {
                  dialogShown: _vm.editorShown,
                  editableData: _vm.editableData,
                  track: _vm.track + "::Editor"
                },
                on: { evtCancel: _vm.onCancel, evtSave: _vm.onSave },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "biz.dashboard.alarms.smartmodulesystemalarm"
                            )
                          )
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "description",
                    fn: function() {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "biz.dashboard.alarms.smartmodulesystemalarmtext"
                              )
                            )
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }