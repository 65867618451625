var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", persistent: "", lazy: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _c(
              "h4",
              [
                _c("v-icon", { staticStyle: { color: "orange" } }, [
                  _vm._v("$vuetify.icons.bus_exclamation_triangle")
                ]),
                _vm._v("\n          " + _vm._s(_vm.dialogTitle) + "\n      ")
              ],
              1
            )
          ]),
          _c("v-card-text", [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.dialogMessage) } })
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::Help",
                      expression: "track + '::Help'"
                    }
                  ],
                  staticStyle: { "margin-left": "7px" },
                  attrs: { depressed: "", outline: "", color: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.onHelpPressed()
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "pl-0", attrs: { color: "secondary" } },
                    [_vm._v("help_outline")]
                  ),
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("general.help")) + "\n      "
                  )
                ],
                1
              ),
              _c("v-spacer"),
              !_vm.showOkay
                ? [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: _vm.track + "::Cancel",
                            expression: "track + '::Cancel'"
                          }
                        ],
                        attrs: {
                          depressed: "",
                          color: "secondary",
                          outline: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.onCancelPressed()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.cancel")))]
                    ),
                    _vm.showGoToSmartMonitoring
                      ? _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: _vm.track + "::GoHome",
                                expression: "track + '::GoHome'"
                              }
                            ],
                            attrs: { depressed: "", color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.goHome()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("general.home.goto")))]
                        )
                      : _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value: _vm.track + "::Retry",
                                expression: "track + '::Retry'"
                              }
                            ],
                            attrs: { depressed: "", color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.onRetryPressed()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("general.retry")))]
                        )
                  ]
                : [
                    _c("Ok", {
                      attrs: { track: _vm.track + "::Close" },
                      on: {
                        evtOk: function($event) {
                          return _vm.onOkPressed()
                        }
                      }
                    })
                  ]
            ],
            2
          ),
          _vm.helpShown
            ? _c(
                "div",
                [
                  _c("ContactInfo", {
                    attrs: {
                      smartCoolingHubId: _vm.smartCoolingHubId,
                      sensorId: _vm.sensorId,
                      serialNumber: _vm.serialNumber
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }