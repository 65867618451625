var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    { attrs: { bottom: "" } },
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: "NavigationItem::" + _vm.feature.title,
              expression: "'NavigationItem::' + feature.title"
            }
          ],
          staticStyle: { "pointer-events": "none" },
          attrs: { slot: "activator", depressed: "", icon: "" },
          slot: "activator"
        },
        [
          _c(
            "v-icon",
            { class: _vm.getIconClass(), style: _vm.getIconStyle() },
            [_vm._v(_vm._s(_vm.feature.icon))]
          )
        ],
        1
      ),
      _c("span", [_vm._v(_vm._s(_vm.getFeatureTooltip()))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }