var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: {
      title: _vm.$t("biz.dashboard.alarms.newcomment"),
      message: _vm.$t("biz.dashboard.alarms.createnew"),
      dialogShown: _vm.dialogAddShown,
      track: _vm.track
    },
    on: {
      evtClose: function($event) {
        return _vm.onClosePressed()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "v-flex",
              [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "margin-bottom": "0px",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("biz.alarms.reason")))]
                ),
                _c("v-select", {
                  staticClass: "default selected mb-3",
                  attrs: {
                    chips: "",
                    items: _vm.reasons,
                    placeholder: _vm.$t("biz.alarms.reason") + ": ",
                    "menu-props": { bottom: true, offsetY: true },
                    "item-text": "name",
                    "full-width": "",
                    "hide-details": ""
                  },
                  model: {
                    value: _vm.tmpReason,
                    callback: function($$v) {
                      _vm.tmpReason = $$v
                    },
                    expression: "tmpReason"
                  }
                })
              ],
              1
            ),
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "margin-bottom": "0px",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("biz.alarms.addcomment")))]
                ),
                _c("v-textarea", {
                  staticClass:
                    "defaulttextarea selectedtextarea errorbordertextarea",
                  attrs: {
                    clearable: _vm.iconHelper.clearableIcon(_vm.tmpComment),
                    placeholder: _vm.$t("biz.alarms.addcomment"),
                    "auto-grow": "",
                    rules: _vm.alarmCommentRules,
                    required: ""
                  },
                  model: {
                    value: _vm.tmpComment,
                    callback: function($$v) {
                      _vm.tmpComment = $$v
                    },
                    expression: "tmpComment"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "buttons",
        fn: function() {
          return [
            _c("v-spacer"),
            _c("Save", {
              staticClass: "buttons mr-3 mb-3",
              attrs: {
                icon: false,
                saveDisabled: _vm.saveDisabled,
                track: _vm.track
              },
              on: {
                evtSave: function($event) {
                  return _vm.onSavePressed()
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }