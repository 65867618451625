// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import {
  ErrorDto,
  HttpStatusCode,
  OrganizationImageResponse,
  OrganizationSettings,
  PermissionResponse,
  UpdatePermissionsRequest,
  VirtualFridgePermissionsResponse
} from '../models';

export interface GetPermissionsForVirtualFridgeIdUsingGETRequest {
  virtualFridgeId: string;
}

export interface StoreOrganizationAreaUsingPUTRequest {
  area: string;
}

export interface StoreOrganizationLanguageUsingPUTRequest {
  language: string;
}

export interface StoreOrganizationSettingsUsingPUTRequest {
  organizationSettings: OrganizationSettings;
}

export interface UpdateOrganizationImageUsingPOSTRequest {
  body: string;
}

export interface UpdatePermissionsUsingPUTRequest {
  updatePermissionsRequest: UpdatePermissionsRequest;
}

/**
 * no description
 */
export class OrganizationControllerApi extends BaseAPI {
  /**
   * deleteOrganizationImageUrl
   * Deletes organization image
   */
  deleteOrganizationImageUrlUsingDELETE = (): Observable<void> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/organization/organizationImage',
      method: 'DELETE',
      headers
    });
  };

  /**
   * getOrganizationAreaAction
   * Gets the business area action of an organization.
   */
  getOrganizationAreaActionUsingGET = (): Observable<string> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<string>({
      path: '/admin/organization/area',
      method: 'GET',
      headers
    });
  };

  /**
   * getOrganizationImageUrl
   * Gets metadata (link, image type ...) for a given Organization (taken from the user context).
   */
  getOrganizationImageUrlUsingGET = (): Observable<OrganizationImageResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<OrganizationImageResponse>({
      path: '/organization/organizationImage',
      method: 'GET',
      headers
    });
  };

  /**
   * getOrganizationSettings
   * Gets the Organization settings for the user specified in the UserContext.
   */
  getOrganizationSettingsUsingGET = (): Observable<OrganizationSettings> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<OrganizationSettings>({
      path: '/organization/settings',
      method: 'GET',
      headers
    });
  };

  /**
   * getPermissionsForVirtualFridgeId
   * Gets permissions for a virtualFridgeId
   */
  getPermissionsForVirtualFridgeIdUsingGET = (
    requestParameters: GetPermissionsForVirtualFridgeIdUsingGETRequest
  ): Observable<Array<VirtualFridgePermissionsResponse>> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getPermissionsForVirtualFridgeIdUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Array<VirtualFridgePermissionsResponse>>({
      path: '/admin/organization/permissions/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'GET',
      headers
    });
  };

  /**
   * getPermissions
   * Gets permissions for admin
   */
  getPermissionsUsingGET = (): Observable<Array<PermissionResponse>> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Array<PermissionResponse>>({
      path: '/admin/organization/permissions',
      method: 'GET',
      headers
    });
  };

  /**
   * storeOrganizationArea
   * Stores the business area for an organization.
   */
  storeOrganizationAreaUsingPUT = (
    requestParameters: StoreOrganizationAreaUsingPUTRequest
  ): Observable<HttpStatusCode> => {
    throwIfRequired(requestParameters, 'area', 'storeOrganizationAreaUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<HttpStatusCode>({
      path: '/admin/organization/area/{area}'.replace('{area}', encodeURI(requestParameters.area)),
      method: 'PUT',
      headers
    });
  };

  /**
   * storeOrganizationLanguage
   * Stores the language for an organization.
   */
  storeOrganizationLanguageUsingPUT = (
    requestParameters: StoreOrganizationLanguageUsingPUTRequest
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'language', 'storeOrganizationLanguageUsingPUT');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/organization/language/{language}'.replace('{language}', encodeURI(requestParameters.language)),
      method: 'PUT',
      headers
    });
  };

  /**
   * storeOrganizationSettings
   * Stores the settings for the organization.
   */
  storeOrganizationSettingsUsingPUT = (
    requestParameters: StoreOrganizationSettingsUsingPUTRequest
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'organizationSettings', 'storeOrganizationSettingsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/organization/settings',
      method: 'PUT',
      headers,
      body: requestParameters.organizationSettings
    });
  };

  /**
   * updateOrganizationImage
   * Updates the Image of the Organization of the currently logged in User
   */
  updateOrganizationImageUsingPOST = (requestParameters: UpdateOrganizationImageUsingPOSTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'body', 'updateOrganizationImageUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/organization/organizationImage',
      method: 'POST',
      headers,
      body: requestParameters.body as any
    });
  };

  /**
   * updatePermissions
   * Updates permissions. Only admin can call.
   */
  updatePermissionsUsingPUT = (requestParameters: UpdatePermissionsUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'updatePermissionsRequest', 'updatePermissionsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/organization/permissions',
      method: 'PUT',
      headers,
      body: requestParameters.updatePermissionsRequest
    });
  };
}
