var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        color: _vm.getColor(_vm.userNotification),
        bottom: "",
        timeout: 0,
        "multi-line": true
      },
      on: {
        input: function($event) {
          return _vm.removeCurrentNotification()
        }
      },
      model: {
        value: _vm.snackbarVisible,
        callback: function($$v) {
          _vm.snackbarVisible = $$v
        },
        expression: "snackbarVisible"
      }
    },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", "fill-height": "", row: "" } },
        [
          _vm.userNotification && !_vm.userNotification.isInternalError()
            ? _c(
                "v-layout",
                { attrs: { "align-center": "", "fill-height": "", row: "" } },
                [
                  _c("v-flex", { attrs: { grow: "" } }, [
                    _c(
                      "p",
                      {
                        staticClass: "subheading hidden-md-and-down",
                        staticStyle: { "margin-bottom": "0px" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.userNotification.notificationText) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "hidden-lg-and-up",
                        staticStyle: { "margin-bottom": "0px" }
                      },
                      [_vm._v(_vm._s(_vm.userNotification.notificationText))]
                    )
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _vm.userNotification.notificationLink
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                depressed: "",
                                flat: "",
                                color: "white",
                                href:
                                  _vm.userNotification.notificationLink
                                    .linkAddress,
                                target: "_blank"
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("$vuetify.icons.bus_general_link")
                              ]),
                              _c("div", { staticClass: "hidden-md-and-down" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.userNotification.notificationLink
                                      .linkText
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.userNotification && _vm.userNotification.isInternalError()
            ? _c(
                "v-layout",
                { attrs: { "align-center": "", "fill-height": "", row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { grow: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "subheading hidden-md-and-down",
                          staticStyle: { "margin-bottom": "0px" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("general.error") +
                                  " " +
                                  _vm.$t("general.errorext")
                              ) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "hidden-lg-and-up",
                          staticStyle: { "margin-bottom": "0px" }
                        },
                        [_vm._v(_vm._s(_vm.$t("general.error")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _vm.userNotification && _vm.userNotification.isInternalError()
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        color: _vm.getColor(_vm.userNotification)
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openServiceRequest()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.help.servicedetails.start")))]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.userNotification && _vm.userNotification.isConnectionError()
            ? _c(
                "v-layout",
                { attrs: { "align-center": "", "fill-height": "", row: "" } },
                [
                  _c("v-flex", { attrs: { grow: "" } }, [
                    _c(
                      "p",
                      {
                        staticClass: "subheading",
                        staticStyle: { "margin-bottom": "0px" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("biz.websocket.connectionlost")) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.userNotification && !_vm.userNotification.isConnectionError()
            ? _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeCurrentNotification()
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "bus_icon_whitening" }, [
                        _vm._v("$vuetify.icons.bus_close")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("ServiceDialog", {
        attrs: {
          dialogShown: _vm.showServiceDialog,
          errorMessage: _vm.errorMessage,
          errorTime: _vm.errorTime
        },
        on: {
          "update:dialogShown": function($event) {
            _vm.showServiceDialog = $event
          },
          "update:dialog-shown": function($event) {
            _vm.showServiceDialog = $event
          },
          evtServiceDialogClose: function($event) {
            return _vm.onServiceDialogClose()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }