var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "", "justify-space-between": "" } },
    [
      _c(
        "v-flex",
        { attrs: { "pl-3": "" } },
        [
          _vm.haveCriticals()
            ? _c(
                "v-layout",
                { attrs: { "mt-4": "", "mb-3": "" } },
                [
                  _vm.horizontal
                    ? _c(
                        "v-layout",
                        [
                          _vm._l(_vm.fewCriticals, function(item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _vm.TypeChecker.isApplianceZone(item)
                                  ? _c("DeviceItem", {
                                      key: item.virtualFridgeId,
                                      staticClass: "ma-2",
                                      attrs: {
                                        applianceZone: item,
                                        id: item.virtualFridgeId
                                      }
                                    })
                                  : _vm.TypeChecker.isSensor(item)
                                  ? _c("SensorItem", {
                                      key: item.sensorId,
                                      staticClass: "ma-2",
                                      attrs: {
                                        id: item.sensorId,
                                        sensor: item,
                                        criticalDevicesPage: true
                                      }
                                    })
                                  : _c("GatewayItem", {
                                      key: item.gatewayId,
                                      staticClass: "ma-2",
                                      attrs: { gateway: item }
                                    })
                              ],
                              1
                            )
                          }),
                          _c("v-spacer"),
                          _vm.fewCriticals === _vm.fews
                            ? _c("ItemMoreCriticals", {
                                attrs: {
                                  substract:
                                    _vm.allCriticals.length - _vm.fews.length
                                }
                              })
                            : _vm._e()
                        ],
                        2
                      )
                    : _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _vm._l(_vm.criticals, function(item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _vm.TypeChecker.isApplianceZone(item)
                                  ? _c("DeviceItem", {
                                      key: item.virtualFridgeId,
                                      staticClass: "mb-2 mt-2",
                                      attrs: {
                                        applianceZone: item,
                                        id: item.virtualFridgeId
                                      }
                                    })
                                  : _vm.TypeChecker.isSensor(item)
                                  ? _c("SensorItem", {
                                      key: item.sensorId,
                                      staticClass: "mt-2",
                                      attrs: {
                                        id: item.sensorId,
                                        sensor: item,
                                        criticalDevicesPage: true
                                      }
                                    })
                                  : _c("GatewayItem", {
                                      key: item.gatewayId,
                                      staticClass: "mb-2 mt-2",
                                      attrs: { gateway: item }
                                    })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.horizontal
                    ? _c(
                        "v-layout",
                        {
                          attrs: {
                            column: "",
                            "mt-5": "",
                            "ml-3": "",
                            "mb-5": "",
                            "align-center": ""
                          }
                        },
                        [
                          _c("v-icon", { attrs: { size: "80px" } }, [
                            _vm._v("$vuetify.icons.bus_devicestate_all_ok")
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("biz.criticals.noCritical")))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.horizontal
                    ? _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            "align-center": "",
                            "justify-center": "",
                            "mt-5": "",
                            "mb-2": ""
                          }
                        },
                        [
                          _c("v-icon", { attrs: { size: "80px" } }, [
                            _vm._v("$vuetify.icons.bus_devicestate_all_ok")
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$t("biz.criticals.noCritical")))
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
        ],
        1
      ),
      !_vm.horizontal
        ? _c(
            "v-btn",
            {
              directives: [
                {
                  name: "track",
                  rawName: "v-track",
                  value: "CriticalDevices::ShowAlarmHelpDialog",
                  expression: "'CriticalDevices::ShowAlarmHelpDialog'"
                }
              ],
              staticClass: "my-4 mx-0 px-0",
              attrs: { depressed: "", color: "secondary", outline: "" },
              on: {
                click: function($event) {
                  _vm.dialogShown = true
                }
              }
            },
            [
              _c("v-icon", [_vm._v("help_outline")]),
              _vm._v(
                "\n     \n    " +
                  _vm._s(_vm.$t("biz.dashboard.alarms.showalarmtypes")) +
                  "\n  "
              )
            ],
            1
          )
        : _vm._e(),
      _c("AlarmHelpDialog", {
        attrs: {
          track: "CriticalDevices::AlarmHelpDialog",
          dialogShown: _vm.dialogShown
        },
        on: {
          evtClose: function($event) {
            _vm.dialogShown = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }