// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { LicenseOverviewResponse } from './';

/**
 * @export
 * @interface VoucherResultResponse
 */
export interface VoucherResultResponse {
  /**
   * @type {LicenseOverviewResponse}
   * @memberof VoucherResultResponse
   */
  licenseOverviewResponse?: LicenseOverviewResponse;
  /**
   * @type {string}
   * @memberof VoucherResultResponse
   */
  result?: VoucherResultResponseResultEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum VoucherResultResponseResultEnum {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  ALREADYUSED = 'ALREADY_USED',
  ENTITLEMENTMISSING = 'ENTITLEMENT_MISSING'
}
