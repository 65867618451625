var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "pl-2 pr-2 pb-2", attrs: { row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        {
          staticStyle: {
            "text-align": "center",
            "font-size": "20px",
            margin: "auto"
          },
          attrs: { xs2: "" }
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c("v-text-field", {
                staticStyle: {
                  "text-align-last": "center",
                  "font-size": "14px"
                },
                attrs: {
                  "append-outer-icon": _vm.getIconPlus(),
                  "prepend-icon": _vm.getIconMinus(),
                  type: "number",
                  disabled: !_vm.active
                },
                on: {
                  "click:append-outer": _vm.incrementMin,
                  "click:prepend": _vm.decrementMin
                },
                model: {
                  value: _vm.min,
                  callback: function($$v) {
                    _vm.min = $$v
                  },
                  expression: "min"
                }
              }),
              _c(
                "label",
                {
                  staticStyle: {
                    "font-size": "14px",
                    "text-align": "center",
                    color: "gray",
                    "margin-top": "-25px"
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("general.min")) +
                      " " +
                      _vm._s(_vm.minValue) +
                      " °C"
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs8: "" } },
        [
          _c("v-range-slider", {
            staticClass: "pl-4 pr-4",
            attrs: {
              disabled: !_vm.active,
              min: _vm.minValue,
              max: _vm.maxValue,
              step: _vm.step,
              "thumb-label": "always",
              "always-dirty": ""
            },
            model: {
              value: _vm.sliderValues,
              callback: function($$v) {
                _vm.sliderValues = $$v
              },
              expression: "sliderValues"
            }
          })
        ],
        1
      ),
      _c(
        "v-flex",
        {
          staticStyle: {
            "text-align": "center",
            "font-size": "20px",
            margin: "auto"
          },
          attrs: { xs2: "" }
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c("v-text-field", {
                staticStyle: {
                  "text-align-last": "center",
                  "font-size": "14px"
                },
                attrs: {
                  "append-outer-icon": _vm.getIconPlus(),
                  "prepend-icon": _vm.getIconMinus(),
                  type: "number",
                  disabled: !_vm.active
                },
                on: {
                  "click:append-outer": _vm.incrementMax,
                  "click:prepend": _vm.decrementMax
                },
                model: {
                  value: _vm.max,
                  callback: function($$v) {
                    _vm.max = $$v
                  },
                  expression: "max"
                }
              }),
              _c(
                "label",
                {
                  staticStyle: {
                    "font-size": "14px",
                    color: "gray",
                    "margin-top": "-25px"
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("general.max")) +
                      " " +
                      _vm._s(_vm.maxValue) +
                      " °C"
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }