var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "setup-img", attrs: { "fill-height": "" } },
    [
      _c(
        "v-dialog",
        { attrs: { value: true, persistent: "", fullscreen: "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                "fill-height": "",
                "align-center": "",
                "justify-center": ""
              }
            },
            [_vm._t("content")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }