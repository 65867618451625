// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface LicenseInfo
 */
export interface LicenseInfo {
  /**
   * @type {string}
   * @memberof LicenseInfo
   */
  assigneeType?: string;
  /**
   * @type {Date}
   * @memberof LicenseInfo
   */
  endDate?: Date;
  /**
   * @type {string}
   * @memberof LicenseInfo
   */
  entitlementId?: string;
  /**
   * @type {string}
   * @memberof LicenseInfo
   */
  licenseType?: LicenseInfoLicenseTypeEnum;
  /**
   * @type {boolean}
   * @memberof LicenseInfo
   */
  locked?: boolean;
  /**
   * @type {string}
   * @memberof LicenseInfo
   */
  productId?: string;
  /**
   * @type {Date}
   * @memberof LicenseInfo
   */
  startDate?: Date;
  /**
   * @type {Array<string>}
   * @memberof LicenseInfo
   */
  virtualFridgeIds?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum LicenseInfoLicenseTypeEnum {
  TEMPORARY = 'TEMPORARY',
  UNKNOWN = 'UNKNOWN'
}
