var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: {
      title: _vm.$t("biz.appliancehistory.dooropeningsinfo.title"),
      track: "ApplianceHistory::AlarmHistory::Close",
      dialogShown: _vm.dialogShown
    },
    on: {
      evtClose: function($event) {
        return _vm.onClosePressed()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("v-data-table", {
              attrs: {
                headers: _vm.headers,
                items: _vm.selectedDoorOpeningEntry,
                "hide-actions": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "items",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("tr", { staticStyle: { "border-bottom": "none" } }, [
                        _c(
                          "td",
                          [
                            _c(
                              "v-layout",
                              {
                                staticStyle: { "margin-left": "-10px" },
                                attrs: { row: "", nowrap: "" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.doorOpenedTimestamp) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "v-layout",
                              {
                                staticStyle: { "margin-left": "-10px" },
                                attrs: { row: "", nowrap: "" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.doorClosedTimestamp) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "v-layout",
                              {
                                staticStyle: { "margin-left": "-10px" },
                                attrs: { row: "", nowrap: "" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formatDuration(
                                        item.openDurationMillis
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }