var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "", row: "" } },
    [
      _c(
        "v-flex",
        { attrs: { "fill-height": "", lg12: "", xl10: "" } },
        [_vm._t("content")],
        2
      ),
      _c(
        "v-flex",
        { attrs: { "fill-height": "", "ml-4": "", "hidden-lg-and-down": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-layout",
                {
                  staticStyle: { "font-weight": "bold" },
                  attrs: { "mt-4": "", "ml-2": "", row: "" }
                },
                [
                  _c(
                    "span",
                    { staticStyle: { "text-transform": "uppercase" } },
                    [_vm._v(_vm._s(_vm.$t("biz.group.activealarms")))]
                  )
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-layout",
                { attrs: { "justify-end": "" } },
                [
                  _c(
                    "v-tooltip",
                    { attrs: { top: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "track",
                              rawName: "v-track",
                              value: "CriticalDevicesPage::ShowAllAppliances",
                              expression:
                                "'CriticalDevicesPage::ShowAllAppliances'"
                            }
                          ],
                          attrs: {
                            slot: "activator",
                            depressed: "",
                            to: "customerGroups",
                            flat: "",
                            icon: ""
                          },
                          slot: "activator"
                        },
                        [
                          _c("v-icon", [
                            _vm._v("$vuetify.icons.bus_general_internal_link")
                          ])
                        ],
                        1
                      ),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("biz.criticals.showMoreAppliances"))
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("CriticalDevices")
        ],
        1
      ),
      _vm.numberOfCriticals > 0
        ? _c(
            "div",
            {
              staticClass: "drawer-btn hidden-xl-only",
              staticStyle: { position: "fixed" },
              on: {
                click: function($event) {
                  _vm.drawer = !_vm.drawer
                }
              }
            },
            [
              _c("v-icon", { attrs: { small: "", color: "white" } }, [
                _vm._v("$vuetify.icons.bus_chevron_left")
              ]),
              _c("p", { staticStyle: { color: "white" } }, [
                _vm._v(_vm._s(_vm.numberOfCriticals))
              ])
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "drawer-btn-noCriticals hidden-xl-only",
              staticStyle: { position: "fixed" },
              on: {
                click: function($event) {
                  _vm.drawer = !_vm.drawer
                }
              }
            },
            [
              _c("v-icon", { attrs: { small: "", color: "white" } }, [
                _vm._v("$vuetify.icons.bus_chevron_left")
              ]),
              _c("p", { staticStyle: { color: "white" } }, [
                _vm._v(_vm._s(_vm.numberOfCriticals))
              ])
            ],
            1
          ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "grey lighten-2",
          staticStyle: { "z-index": "10" },
          attrs: { width: "350", right: "", fixed: "", temporary: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-lg": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticStyle: { "font-weight": "bold" },
                      attrs: { "mt-4": "", "ml-2": "", row: "" }
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("biz.group.activealarms")))
                      ])
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-layout",
                    { attrs: { "justify-end": "" } },
                    [
                      _c(
                        "v-tooltip",
                        { attrs: { top: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value:
                                    "CriticalDevicesPage::ShowAllAppliances",
                                  expression:
                                    "'CriticalDevicesPage::ShowAllAppliances'"
                                }
                              ],
                              attrs: {
                                slot: "activator",
                                depressed: "",
                                to: "customerGroups",
                                flat: "",
                                icon: ""
                              },
                              slot: "activator"
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  "$vuetify.icons.bus_general_internal_link"
                                )
                              ])
                            ],
                            1
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("biz.criticals.showMoreAppliances"))
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("CriticalDevices", {
                on: { evtNumberCriticals: _vm.setNumberOfCriticals }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }