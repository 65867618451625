import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ValidationHelper } from '@/components/validation/ValidationHelper';

import SaveCancel from '@/components/general/menu/SaveCancel';
import Cancel from '@/components/general/buttons/Cancel';

import { UserControllerApi, SetUserMobileUsingPOSTRequest, SetOtherUserMobileUsingPOSTRequest } from '@/api/apis';
import { Colleague, UserMobileRequest } from '@/api/models';
import { IconHelper } from '@/components/general/icons/IconHelper';

import { useApiStore } from '@/api/ApiStorePinia';
import { useLoginStore } from '@/components/login/LoginStore';

@Component({
  components: { Cancel, SaveCancel }
})
export default class PhoneNumberEditDialog extends Vue {
  /**
   * Visibility of dialog
   *
   * @type {boolean}
   * @memberof PhoneNumberEditDialog
   */
  @Prop({ default: false })
  public dialogShown: boolean;

  /**
   * The current phone number to edit.
   *
   * @type {string}
   * @memberof PhoneNumberEditDialog
   */
  @Prop()
  public mobilePhoneNumber: string;

  /**
   * The UPN we're editing. Might be the currently logged in user.
   *
   * @type {string}
   * @memberof PhoneNumberEditDialog
   */
  @Prop()
  public upn: string;
  private inCallExecution: boolean = false;

  /**
   * Watcher for the phone number.
   *
   * @private
   * @param {string} newNumber The new number.
   * @memberof PhoneNumberEditDialog
   */
  @Watch('mobilePhoneNumber', { immediate: true })
  private onMobilePhoneNumberChange(newNumber: string): void {
    this.mobileEditField = newNumber;
  }

  /**
   * User tracking
   *
   * @type {string}
   * @memberof PhoneNumberEditDialog
   */
  @Prop({ default: 'PhoneNumberEditDialog' })
  public track: string;

  /**
   * Gets the upn of the current user.
   *
   * @readonly
   * @type {boolean}
   * @memberof PhoneNumberEditDialog
   */
  get getCurrentUserUpn(): string {
    const loginStore = useLoginStore();
    return loginStore.getCurrentUserUpn;
  }

  /**
   * Gets a colleague name by UPN.
   *
   * @param {string} upn The upn of the colleague.
   * @return {*}  {Colleague}
   */
  private getColleagueName(upn: string): string {
    const loginStore = useLoginStore();
    const colleague: Colleague = loginStore.getColleague(upn);

    if (colleague) {
      return colleague.displayName;
    }

    return '';
  }

  /**
   * Getter to determine whether we are editing the current user or whether the admin is editing a different user.
   *
   * @readonly
   * @type {boolean}
   * @memberof PhoneNumberEditDialog
   */
  get editingColleague(): boolean {
    return this.getCurrentUserUpn !== this.upn;
  }

  /**
   * Gets the title of the dialog especially when we're editing someone else.
   *
   * @readonly
   * @type {string}
   * @memberof PhoneNumberEditDialog
   */
  get colleagueTitle(): string {
    return String(
      this.$t('general.menus.settings.phonenumber.colleaguenumber', { colleague: this.getColleagueName(this.upn) })
    );
  }

  /**
   * Rules for the phone number.
   *
   * @memberof PhoneNumberEditDialog
   */
  phoneNumberRules = [
    v => ValidationHelper.isPhoneNumber(v, true) || ValidationHelper.getErrorText(ValidationHelper.PHONE_NUMBER),
    v =>
      ValidationHelper.isPhoneLengthValid(v, true) || ValidationHelper.getErrorText(ValidationHelper.PHONE_NUMBER_SHORT)
  ];

  /**
   * Second property for the v-model.
   *
   * @private
   * @type {string}
   * @memberof PhoneNumberEditDialog
   */
  private mobileEditField: string = '';

  /**
   * Responsable for activating save button.
   *
   * @readonly
   * @memberof PhoneNumberEditDialog
   */
  get saveDisabled(): boolean {
    if (this.inCallExecution) {
      return true;
    }

    return !ValidationHelper.isPhoneNumber(this.mobileEditField, true);
  }

  /**
   * Cancel button state. While we execute the call, the user cannot cancel.
   *
   * @readonly
   * @type {boolean}
   * @memberof PhoneNumberEditDialog
   */
  get cancelDisabled(): boolean {
    return this.inCallExecution;
  }

  /**
   * Method which saves the new entered phone number. Save event tells parent to refresh data.
   *
   * @private
   * @memberof PhoneNumberEditDialog
   */
  private onPhoneNumberSave(): void {
    if (!this.mobileEditField || this.mobileEditField?.length < 4) {
      this.clearPhoneNumber();
    } else {
      this.savePhoneNumber();
    }
  }

  /**
   * Saves a phone number for the user.
   *
   * @private
   * @memberof PhoneNumberEditDialog
   */
  private savePhoneNumber(): void {
    this.inCallExecution = true;

    const userMobileRequest: UserMobileRequest = {
      mobile: this.mobileEditField.trim(),
      upn: this.upn
    };

    if (this.editingColleague) {
      this.savePhoneNumberForColleague(userMobileRequest);
    } else {
      this.savePhoneNumberForMyself(userMobileRequest);
    }
  }

  /**
   * Clears a phone number.
   *
   * @private
   * @memberof PhoneNumberEditDialog
   */
  private clearPhoneNumber(): void {
    this.inCallExecution = true;

    const userMobileRequest: UserMobileRequest = {
      mobile: '',
      upn: this.upn
    };

    if (this.editingColleague) {
      this.savePhoneNumberForColleague(userMobileRequest);
    } else {
      this.savePhoneNumberForMyself(userMobileRequest);
    }
  }

  /**
   * Saves a number for "myself".
   *
   * @private
   * @param {UserMobileRequest} userMobileRequest The request object.
   * @memberof PhoneNumberEditDialog
   */
  private savePhoneNumberForMyself(userMobileRequest: UserMobileRequest): void {
    const logger = this.$log.prefix('PhoneNumberEditDialog::savePhoneNumberForMyself');
    logger.debug('Saving my phone number ...');

    const apiStore = useApiStore();
    const userControllerApi: UserControllerApi = apiStore.getUserControllerApi;

    const request: SetUserMobileUsingPOSTRequest = {
      userMobileRequest: userMobileRequest
    };

    userControllerApi
      .setUserMobileUsingPOST(request)
      .toPromise()
      .then(response => {
        if (response.mobileSet) {
          logger.debug('Saved phone number successfully, updating store...', { mobileNumberResponse: response });

          const loginStore = useLoginStore();
          loginStore.setMobileNumber(this.upn, response);
        }
      })
      .catch(reason => {
        logger.error('Cannot save phone number', reason);
      })
      .finally(() => {
        this.$emit('evtClose');

        this.inCallExecution = false;
      });
  }

  /**
   * Saves the phone number for a colleague.
   *
   * @private
   * @param {UserMobileRequest} userMobileRequest The user mobile request.
   * @memberof PhoneNumberEditDialog
   */
  private savePhoneNumberForColleague(userMobileRequest: UserMobileRequest): void {
    const logger = this.$log.prefix('PhoneNumberEditDialog::savePhoneNumberForColleague');
    logger.debug('Saving phone number for colleague', { upn: userMobileRequest.upn });

    const apiStore = useApiStore();
    const userControllerApi: UserControllerApi = apiStore.getUserControllerApi;

    const request: SetUserMobileUsingPOSTRequest = {
      userMobileRequest: userMobileRequest
    };

    userControllerApi
      .setOtherUserMobileUsingPOST(request)
      .toPromise()
      .then(response => {
        if (response.mobileSet) {
          logger.debug('Saved phone number successfully, updating store...', { mobileNumberResponse: response });

          const loginStore = useLoginStore();
          loginStore.setMobileNumber(this.upn, response);
        }
      })
      .catch(reason => {
        logger.error('Cannot save phone number', reason);
      })
      .finally(() => {
        this.$emit('evtClose');

        this.inCallExecution = false;
      });
  }

  /**
   * Cancels the dialog, sends evtCancel.
   *
   * @private
   * @memberof PhoneNumberEditDialog
   */
  private onEditCancel(): void {
    this.$emit('evtClose');
  }

  /**
   * Gets the IconHelper class.
   *
   * @readonly
   * @type {IconHelper}
   * @memberof PhoneNumberEditDialog
   */
  get IconHelper(): IconHelper {
    return IconHelper;
  }
}
