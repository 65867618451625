var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-space-between": "", wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-card-title", { staticClass: "bus_propertytitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.applianceinfo.properties")))
              ])
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-flex",
            { staticClass: "align-items: end", attrs: { shrink: "" } },
            [
              _vm.isAdminUser
                ? _c("Edit", {
                    attrs: { editDisabled: !_vm.licensed, track: _vm.track },
                    on: {
                      evtEdit: function($event) {
                        return _vm.showEditor()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.name ? _vm.editableData.name : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.order")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  !_vm.editableData.orderDate
                    ? _vm.SHOW_BLANK
                    : _vm.$format.date(_vm.editableData.orderDate)
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.inventory")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.inventoryNumber
                    ? _vm.editableData.inventoryNumber
                    : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.model")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.model
                    ? _vm.editableData.model
                    : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.serialnumber")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.serialNumber
                    ? _vm.editableData.serialNumber
                    : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _vm.isTwoZoneAppliance(_vm.editableData.serialNumber)
        ? _c(
            "v-layout",
            [
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.$t("biz.applianceinfo.zone")))
                ])
              ]),
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    _vm._s(_vm.getZoneType(_vm.editableData.virtualFridgeId))
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.editableData.measureSiteType ===
      _vm.MeasureSiteMeasureSiteTypeEnum.SMARTMODULEFRIDGE
        ? _c(
            "v-layout",
            [
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.$t("general.version")))
                ])
              ]),
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    _vm._s(
                      _vm.editableData.softwareVersion
                        ? _vm.editableData.softwareVersion
                        : _vm.SHOW_BLANK
                    )
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("biz.applianceinfo.article")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  _vm.editableData.articleNumber
                    ? _vm.editableData.articleNumber
                    : _vm.SHOW_BLANK
                )
              )
            ])
          ])
        ],
        1
      ),
      _vm.editableData.measureSiteType ===
      _vm.MeasureSiteMeasureSiteTypeEnum.MODBUSFRIDGE
        ? _c(
            "v-layout",
            [
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.$t("biz.applianceinfo.modbus")))
                ])
              ]),
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    _vm._s(
                      _vm.editableData.modbusAddress
                        ? _vm.editableData.modbusAddress
                        : _vm.SHOW_BLANK
                    )
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.editableData.measureSiteType ===
      _vm.MeasureSiteMeasureSiteTypeEnum.BINDERFRIDGE
        ? _c(
            "v-layout",
            [
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.$t("general.mac")))
                ])
              ]),
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(
                    _vm._s(
                      _vm.editableData.macAddress
                        ? _vm.editableData.macAddress
                        : _vm.SHOW_BLANK
                    )
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.hasTimezone
        ? _c(
            "v-layout",
            [
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.$t("general.timezone.title")))
                ])
              ]),
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c("p", { staticClass: "bus_normaltext" }, [
                  _vm._v(_vm._s(_vm.timezone))
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        [
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(_vm._s(_vm.$t("general.comment")))
            ])
          ]),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c("p", { staticClass: "bus_normaltext" }, [
              _vm._v(
                _vm._s(
                  !_vm.editableData.comment
                    ? _vm.SHOW_BLANK
                    : _vm.editableData.comment
                )
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700px" },
          model: {
            value: _vm.editorShown,
            callback: function($$v) {
              _vm.editorShown = $$v
            },
            expression: "editorShown"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c("v-card-title", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("biz.applianceinfo.properties")))
              ]),
              _c(
                "v-layout",
                { attrs: { column: "", "pa-3": "" } },
                [
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))]
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    staticStyle: { height: "100px" },
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.name
                      ),
                      rules: _vm.applianceNameRules,
                      type: "string",
                      required: ""
                    },
                    model: {
                      value: _vm.editableDataLocal.name,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal, "name", $$v)
                      },
                      expression: "editableDataLocal.name"
                    }
                  }),
                  _c("p", {
                    staticClass: "ml-2 mb-2 mt-1 hint",
                    domProps: { innerHTML: _vm._s(_vm.getNameHint()) }
                  }),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        lazy: "",
                        transition: "scale-transition",
                        "offset-y": "",
                        "full-width": "",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "margin-bottom": "0px",
                                    "font-weight": "bold"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("biz.applianceinfo.order"))
                                  )
                                ]
                              ),
                              _c(
                                "v-text-field",
                                _vm._g(
                                  {
                                    staticClass: "default selected",
                                    attrs: {
                                      value: !_vm.editableData.orderDate
                                        ? _vm.SHOW_BLANK
                                        : _vm.$format.date(_vm.orderDate),
                                      readonly: ""
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.datePickerShown,
                        callback: function($$v) {
                          _vm.datePickerShown = $$v
                        },
                        expression: "datePickerShown"
                      }
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          locale: _vm.$i18n.locale,
                          max: _vm.currentDate
                        },
                        on: {
                          input: function($event) {
                            return _vm.updateOrderDate()
                          }
                        },
                        model: {
                          value: _vm.orderDate,
                          callback: function($$v) {
                            _vm.orderDate = $$v
                          },
                          expression: "orderDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.inventory")))]
                  ),
                  _c("v-text-field", {
                    staticClass: "default selected errorborder",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.inventoryNumber
                      ),
                      rules: _vm.inventoryNumberRules,
                      type: "string",
                      required: ""
                    },
                    model: {
                      value: _vm.editableDataLocal.inventoryNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal, "inventoryNumber", $$v)
                      },
                      expression: "editableDataLocal.inventoryNumber"
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-bottom": "0px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.comment")))]
                  ),
                  _c("v-textarea", {
                    staticClass: "defaulttextarea selectedtextarea",
                    attrs: {
                      clearable: _vm.iconHelper.clearableIcon(
                        _vm.editableDataLocal.comment
                      ),
                      type: "string"
                    },
                    model: {
                      value: _vm.editableDataLocal.comment,
                      callback: function($$v) {
                        _vm.$set(_vm.editableDataLocal, "comment", $$v)
                      },
                      expression: "editableDataLocal.comment"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("SaveCancel", {
                    attrs: {
                      saveDisabled: _vm.saveDisabled,
                      track: _vm.track,
                      icon: false
                    },
                    on: {
                      evtSave: function($event) {
                        return _vm.onSave()
                      },
                      evtCancel: function($event) {
                        return _vm.onCancel()
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }