var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c(
        "div",
        { staticClass: "window-container" },
        [
          _c(
            "v-window",
            {
              staticStyle: { height: "inherit" },
              attrs: { touchless: "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 5 } },
                [
                  _c("SmartModuleBaseInfo", {
                    attrs: {
                      name: _vm.name,
                      timezone: _vm.timezone,
                      comment: _vm.comment
                    },
                    on: {
                      "update:name": function($event) {
                        _vm.name = $event
                      },
                      "update:timezone": function($event) {
                        _vm.timezone = $event
                      },
                      "update:comment": function($event) {
                        _vm.comment = $event
                      },
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 6 } },
                [
                  _c("ConfigureDeviceStep", {
                    attrs: {
                      zonesModel: true,
                      track: _vm.track + "::ConfigureDeviceStep",
                      building: _vm.building,
                      department: _vm.department,
                      level: _vm.level,
                      room: _vm.room,
                      applianceInfo: _vm.applianceInfo
                    },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      },
                      evtHideErrorDialog: function($event) {
                        return _vm.changeSteps()
                      },
                      "update:building": function($event) {
                        _vm.building = $event
                      },
                      "update:department": function($event) {
                        _vm.department = $event
                      },
                      "update:level": function($event) {
                        _vm.level = $event
                      },
                      "update:room": function($event) {
                        _vm.room = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                { staticStyle: { height: "inherit" }, attrs: { value: 7 } },
                [
                  _c("GroupSelectionStep", {
                    attrs: {
                      track: _vm.track + "::GroupSelectionStep",
                      groupId: _vm.groupId
                    },
                    on: {
                      evtCancel: function($event) {
                        _vm.cancelDialogShown = true
                      },
                      "update:groupId": function($event) {
                        _vm.groupId = $event
                      },
                      "update:group-id": function($event) {
                        _vm.groupId = $event
                      },
                      evtFinishStep: _vm.finishStep
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "navigation-place" },
            [
              _c("v-footer", { attrs: { color: "white" } }, [
                _c(
                  "div",
                  {
                    staticClass: "navigation-content",
                    attrs: { "grid-list-lg": "" }
                  },
                  [
                    _c("BackNextMenu", {
                      attrs: {
                        stepCount: 7,
                        step: _vm.step,
                        stepNextExemptions: [7],
                        nextDisabled: !_vm.isNextEnabled,
                        previousDisabled: _vm.isPreviousDisabled
                      },
                      on: {
                        "update:step": function($event) {
                          _vm.step = $event
                        },
                        evtNextStepExemption: _vm.handleNextStep
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "finalStep",
                          fn: function() {
                            return [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value:
                                        "SmartModuleLanding::BaseInfoSteps::FinalStep",
                                      expression:
                                        "'SmartModuleLanding::BaseInfoSteps::FinalStep'"
                                    }
                                  ],
                                  attrs: { depressed: "", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.finishStep()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("general.next")))]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c("OkCancelDialog", {
            attrs: {
              dialogShown: _vm.cancelDialogShown,
              title: _vm.$t("biz.setup.abort.title"),
              message: _vm.$t("biz.setup.abort.description"),
              track: "BaseInfoSteps::AbortSetup"
            },
            on: {
              evtOkPressed: function($event) {
                return _vm.confirmAbort()
              },
              evtCancelPressed: function($event) {
                return _vm.cancelAbort()
              }
            }
          })
        ],
        1
      ),
      _c("SmartModuleErrorDialog", {
        attrs: {
          dialogShown: _vm.errorShown,
          errorType: _vm.errorType,
          serialNumber: _vm.currentSerialNumber,
          track:
            "SmartModuleLanding::BaseInfoSteps::OnboardingError::" +
            _vm.errorType
        },
        on: {
          evtClose: function($event) {
            return _vm.closeErrorDialog()
          },
          evtRetry: function($event) {
            return _vm.retryPressed()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }