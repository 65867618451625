var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "" } },
        [
          _vm.sessionRenewShown
            ? _c("SessionRenewDialog", {
                attrs: { dialogShown: _vm.sessionRenewShown },
                on: {
                  evtClose: function($event) {
                    return _vm.onClose()
                  }
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "window-container" },
            [
              _c(
                "v-window",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFinishStep,
                      expression: "showFinishStep"
                    }
                  ],
                  staticStyle: { height: "inherit" }
                },
                [
                  _c("BaseWindowPage", {
                    attrs: {
                      finishStep: true,
                      trackCancel: "SetupSensor::Close"
                    },
                    on: {
                      evtCancel: function($event) {
                        return _vm.confirmAbort()
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "biz.sensors.setup.measuresite.finishsetup.title"
                                  )
                                )
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "finish",
                          fn: function() {
                            return [
                              _c("div", { staticClass: "finishstep-image" }, [
                                _c(
                                  "div",
                                  { staticClass: "finishstep-subtitle" },
                                  [
                                    _c("v-flex", {
                                      staticStyle: { "padding-bottom": "20px" },
                                      attrs: { "pt-2": "", xs10: "" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "biz.sensors.setup.measuresite.finishsetup.subtitle"
                                          )
                                        )
                                      }
                                    }),
                                    _vm.licensed
                                      ? _c("v-flex", {
                                          attrs: { xs10: "", "pt-2": "" },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "biz.sensors.setup.measuresite.finishsetup.alarmsettinghint"
                                              )
                                            )
                                          }
                                        })
                                      : _c("v-flex", {
                                          attrs: { "pt-2": "", xs10: "" },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "biz.sensors.setup.measuresite.finishsetup.buylicensehint"
                                              )
                                            )
                                          }
                                        }),
                                    _vm.licensed
                                      ? _c(
                                          "v-flex",
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                staticStyle: {
                                                  "padding-top": "200px"
                                                },
                                                attrs: { column: "" }
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "track",
                                                        rawName: "v-track",
                                                        value:
                                                          "SetupSensor::ToDashboard",
                                                        expression:
                                                          "'SetupSensor::ToDashboard'"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "finishstep-btn-link",
                                                    attrs: {
                                                      depressed: "",
                                                      color: "secondary",
                                                      href: "/",
                                                      outline: ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.home.goto"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "track",
                                                        rawName: "v-track",
                                                        value:
                                                          "SetupSensor::RestartSensorOnboarding",
                                                        expression:
                                                          "'SetupSensor::RestartSensorOnboarding'"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "finishstep-btn-link",
                                                    attrs: {
                                                      depressed: "",
                                                      color: "secondary",
                                                      outline: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.startOnboarding()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "biz.sensors.setup.measuresite.finishsetup.addadditional"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "track",
                                                        rawName: "v-track",
                                                        value:
                                                          "SetupSensor::CreateAlarmProfile",
                                                        expression:
                                                          "'SetupSensor::CreateAlarmProfile'"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "finishstep-alarm-settings",
                                                    attrs: {
                                                      depressed: "",
                                                      color: "primary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.goToSensorAlarmProfilePage()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "biz.sensors.setup.measuresite.finishsetup.alarmsetting"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.licensed
                                      ? _c(
                                          "v-flex",
                                          {
                                            staticStyle: {
                                              "padding-top": "200px"
                                            }
                                          },
                                          [
                                            _c("RedeemVoucher", {
                                              attrs: {
                                                virtualFridgeId:
                                                  _vm.measureSite
                                                    .virtualFridgeId
                                              },
                                              on: {
                                                evtRedeemVoucher: function(
                                                  $event
                                                ) {
                                                  return _vm.updateMeasureSiteLicense()
                                                },
                                                evtOnLicenseAssigned: function(
                                                  $event
                                                ) {
                                                  return _vm.updateMeasureSiteLicense()
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3739654741
                    )
                  })
                ],
                1
              ),
              _c(
                "v-window",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showFinishStep,
                      expression: "!showFinishStep"
                    }
                  ],
                  staticStyle: { height: "inherit" },
                  attrs: { touchless: "" },
                  model: {
                    value: _vm.step,
                    callback: function($$v) {
                      _vm.step = $$v
                    },
                    expression: "step"
                  }
                },
                [
                  _c(
                    "v-window-item",
                    { staticStyle: { height: "inherit" }, attrs: { value: 1 } },
                    [
                      _c("SelectBsdbStep", {
                        attrs: {
                          track: "SetupSensor::SelectBsdbStep",
                          isAppliance: false
                        },
                        on: {
                          evtGatewaySelected: _vm.onGatewaySelected,
                          evtBsdbNotFound: function($event) {
                            _vm.showBsdbNotFound = true
                          },
                          evtCancel: function($event) {
                            _vm.cancelDialogShown = true
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    { staticStyle: { height: "inherit" }, attrs: { value: 2 } },
                    [
                      _c("SensorPreparationStep", {
                        attrs: { track: "SetupSensor::SensorPreparationStep" },
                        on: {
                          evtCancel: function($event) {
                            _vm.cancelDialogShown = true
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    { staticStyle: { height: "inherit" }, attrs: { value: 3 } },
                    [
                      _c("PairingPreparationStep", {
                        attrs: { track: "SetupSensor::PairingPreparationStep" },
                        on: {
                          evtSensorPaired: function($event) {
                            _vm.showSensorPaired = true
                          },
                          evtCancel: function($event) {
                            _vm.cancelDialogShown = true
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    { staticStyle: { height: "inherit" }, attrs: { value: 4 } },
                    [
                      _c("PairingStep", {
                        attrs: {
                          gateway: _vm.selectedGateway,
                          step: _vm.step,
                          track: "SetupSensor::PairingStep"
                        },
                        on: { evtPairingActivated: _vm.onActivePairingMode }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    { staticStyle: { height: "inherit" }, attrs: { value: 5 } },
                    [
                      _c("FoundSensorStep", {
                        attrs: {
                          gateway: _vm.selectedGateway,
                          step: _vm.step,
                          initialSensorId: _vm.pairedSensorId,
                          track: "SetupSensor::FoundSensorStep"
                        },
                        on: { evtSensorSelected: _vm.sensorSelected }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    { staticStyle: { height: "inherit" }, attrs: { value: 6 } },
                    [
                      _c("SensorStatusStep", {
                        attrs: {
                          gateway: _vm.selectedGateway,
                          sensor: _vm.sensor,
                          step: _vm.step,
                          samplingRate: _vm.samplingRate,
                          track: "SetupSensor::SensorStatusStep"
                        },
                        on: {
                          "update:samplingRate": function($event) {
                            _vm.samplingRate = $event
                          },
                          "update:sampling-rate": function($event) {
                            _vm.samplingRate = $event
                          },
                          evtNextEnable: _vm.onStrengthChange
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    { staticStyle: { height: "inherit" }, attrs: { value: 7 } },
                    [
                      _c("SensorPropertiesStep", {
                        ref: "sensorPropertiesStep",
                        attrs: {
                          gateway: _vm.selectedGateway,
                          sensor: _vm.sensor,
                          correlationId: _vm.correlationId,
                          sampling: _vm.samplingRate,
                          readyFlag: _vm.readyFlag,
                          step: _vm.step,
                          track: "SetupSensor::SensorPropertiesStep"
                        },
                        on: {
                          evtMeasureSiteChanged: _vm.onMeasureSiteChanged,
                          evtMeasureSiteNotFound: function($event) {
                            _vm.showMeasureSiteNotFound = true
                          },
                          evtSensorNameChanged: _vm.onSensorNameChanged,
                          evtSensorAssigned: _vm.finishSensorOnboarding,
                          "update:readyFlag": function($event) {
                            _vm.readyFlag = $event
                          },
                          "update:ready-flag": function($event) {
                            _vm.readyFlag = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "navigation-place" },
                [
                  _c("v-footer", { attrs: { color: "white" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "navigation-content",
                        attrs: { "grid-list-lg": "" }
                      },
                      [
                        _c("BackNextMenu", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showFinishStep,
                              expression: "!showFinishStep"
                            }
                          ],
                          attrs: {
                            stepCount: 7,
                            step: _vm.step,
                            stepNextExemptions: [4, 7],
                            nextDisabled: _vm.nextDisabled,
                            previousDisabled: _vm.isPreviousDisabled
                          },
                          on: {
                            "update:step": function($event) {
                              _vm.step = $event
                            },
                            evtNextStepExemption: _vm.handleNextStep
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "finalStep",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        directives: [
                                          {
                                            name: "track",
                                            rawName: "v-track",
                                            value:
                                              "SetupSensor::FinishSensorOnboarding",
                                            expression:
                                              "'SetupSensor::FinishSensorOnboarding'"
                                          }
                                        ],
                                        attrs: {
                                          disabled:
                                            !_vm.isPaired || _vm.isAssigning,
                                          depressed: "",
                                          color: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.finishAssignment()
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("general.finish")))]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2806422715
                          )
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("SensorError", {
            attrs: {
              sensorId: _vm.pairedSensorId,
              gatewaySerialNumber: _vm.selectedGatewaySerial,
              dialogShown: _vm.errorShown,
              errorType: _vm.errorType
            },
            on: {
              evtRetry: function($event) {
                return _vm.onRetry()
              }
            }
          }),
          _c("MeasureSiteNotFoundDialog", {
            attrs: { dialogShown: _vm.showMeasureSiteNotFound },
            on: {
              evtClose: function($event) {
                _vm.showMeasureSiteNotFound = false
              }
            }
          }),
          _c("SensorNotFoundDialog", {
            attrs: { dialogShown: _vm.showSensorNotFound },
            on: {
              evtClose: function($event) {
                _vm.showSensorNotFound = false
              }
            }
          }),
          _c("SensorPairedDialog", {
            attrs: { dialogShown: _vm.showSensorPaired },
            on: {
              evtClose: function($event) {
                _vm.showSensorPaired = false
              }
            }
          }),
          _c("BsdbNotFoundDialog", {
            attrs: {
              showBsdbNotFound: _vm.showBsdbNotFound,
              sensorOnboarding: true,
              dialogShown: _vm.showBsdbNotFound
            },
            on: {
              evtClose: function($event) {
                _vm.showBsdbNotFound = false
              }
            }
          }),
          _c("OkCancelDialog", {
            attrs: {
              dialogShown: _vm.cancelDialogShown,
              title: _vm.$t("biz.setup.abort.title"),
              message: _vm.$t("biz.setup.abort.description"),
              track: "SetupSensor::AbortSetup"
            },
            on: {
              evtOkPressed: function($event) {
                return _vm.confirmAbort()
              },
              evtCancelPressed: function($event) {
                return _vm.cancelAbort()
              }
            }
          }),
          !_vm.loaded
            ? _c("v-progress-circular", {
                attrs: { color: "white", indeterminate: "", size: "48" }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }