var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { "max-width": "none" } },
    [
      _vm.dataReady && !_vm.userHasAppliances
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "65vh" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("DataMissingDisplay", {
                attrs: {
                  interestedIn: _vm.pageRouteHelper.REPORTS,
                  selectedApplianceZone: _vm.selectedApplianceZone
                },
                on: { evtReportsCreated: _vm.reportsCreated }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.dataReady
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "65vh" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.loading")))
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { staticStyle: { width: "100%" }, attrs: { row: "" } },
            [
              _c(
                "v-flex",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dataReady && _vm.userHasAppliances,
                      expression: "dataReady && userHasAppliances"
                    }
                  ],
                  staticStyle: {
                    "border-right": "1px solid var(--v-neutral20-base)",
                    width: "30%",
                    "min-width": "350px"
                  }
                },
                [
                  _c(
                    "v-flex",
                    [
                      _c("v-text-field", {
                        staticClass: "default selected",
                        attrs: {
                          clearable: _vm.iconHelper.clearableIcon(
                            _vm.searchString
                          ),
                          placeholder: _vm.$t("general.search") + "..."
                        },
                        scopedSlots: _vm._u(
                          [
                            !_vm.iconHelper.clearableIcon(_vm.searchString)
                              ? {
                                  key: "append",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        { staticClass: "searchicon" },
                                        [_vm._v("search")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.searchString,
                          callback: function($$v) {
                            _vm.searchString = $$v
                          },
                          expression: "searchString"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "justify-center": "",
                        "align-center": "",
                        column: ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        [
                          _vm.isAdminUser
                            ? _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value:
                                        "ApplianceReports::AutomatedReportsSettings",
                                      expression:
                                        "'ApplianceReports::AutomatedReportsSettings'"
                                    }
                                  ],
                                  attrs: { depressed: "", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openReportSettingsWizard()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "report-settings-label" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "biz.report.reportsettings.title"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.activateFilters
                    ? _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "", "pl-2": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "", lg6: "", "pb-0": "" } },
                            [
                              _c("DevicesFilter", {
                                staticClass: "ma-2",
                                attrs: {
                                  showAppliancesWithSensors: true,
                                  track: "ApplianceReports::DevicesFilter"
                                },
                                on: { evtFilterDevices: _vm.onFilterDevices }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { md12: "", lg6: "", "pb-0": "" } },
                            [
                              _c("ReporterFilter", {
                                staticClass: "ma-2",
                                attrs: { reports: _vm.filteredReports },
                                on: { evtFilterReporter: _vm.onReporterFilter }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("ReportList", {
                    ref: "reportList",
                    attrs: {
                      activateFilters: _vm.activateFilters,
                      reportSettings: _vm.reportSettings,
                      reports: _vm.filteredReports,
                      selectedReports: _vm.selectedReports,
                      selectedReport: _vm.selectedReport,
                      selectedFridgeId: _vm.virtualFridgeId,
                      track: "ApplianceReports::ReportList"
                    },
                    on: {
                      "update:selectedReports": function($event) {
                        _vm.selectedReports = $event
                      },
                      "update:selected-reports": function($event) {
                        _vm.selectedReports = $event
                      },
                      evtOkPressed: function($event) {
                        return _vm.onConfirmDeleteReport()
                      },
                      evtCancelPressed: function($event) {
                        return _vm.onCancelDeleteReport()
                      },
                      evtDeleted: _vm.deleteReports,
                      evtSelected: _vm.selectionChanged,
                      evtSelectAll: function($event) {
                        return _vm.selectAllReports()
                      }
                    }
                  }),
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _vm.showMore
                        ? _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: "ApplianceReports::LoadMoreReports",
                                  expression:
                                    "'ApplianceReports::LoadMoreReports'"
                                }
                              ],
                              staticClass: "mt-3 mb-3",
                              attrs: { color: "primary", depressed: "" },
                              on: {
                                click: function($event) {
                                  return _vm.loadMoreReports()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("biz.report.showmore")))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("ReportPreview", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.dataReady && _vm.userHasAppliances,
                    expression: "dataReady && userHasAppliances"
                  }
                ],
                attrs: {
                  selectedApplianceZone: _vm.selectedApplianceZone,
                  noReportsGenerated: _vm.reports.length === 0,
                  loadedPdf: _vm.loadedPdf,
                  selectedReport: _vm.selectedReport,
                  track: "ApplianceReports::ReportPreview",
                  dialogShown: _vm.reportFailedInfoShown
                },
                on: {
                  evtOkPressed: function($event) {
                    return _vm.onConfirmDeleteReport()
                  },
                  evtSelected: _vm.selectionChanged,
                  evtPrint: function($event) {
                    return _vm.printReport()
                  },
                  evtDeleteSingle: function($event) {
                    return _vm.deleteSingle()
                  },
                  evtDownloadReport: function($event) {
                    return _vm.downloadReport()
                  },
                  evtReportsCreated: _vm.reportsCreated,
                  evtReportFailed: _vm.onReportFailed
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("AutomatedReportSettingsDialog", {
        attrs: {
          editorShown: _vm.reportsSettingsShown,
          reportSettings: _vm.reportSettings
        },
        on: {
          evtSave: _vm.saveReportsSettingsDialog,
          evtClose: function($event) {
            return _vm.closeReportsSettingsDialog()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }