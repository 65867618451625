var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "grid-list-lg": "", fluid: "", "pa-0": "", "fill-height": "" } },
    [
      _vm.dataIsMissing
        ? _c(
            "v-layout",
            {
              attrs: {
                column: "",
                "align-center": "",
                "justify-center": "",
                "fill-height": ""
              }
            },
            [
              _c("DataMissingDisplay", {
                attrs: { interestedIn: _vm.pageRouteHelper.MEASURE_SITES },
                on: {
                  evtMeasureSitesData: function($event) {
                    _vm.dataIsMissing = false
                  }
                }
              })
            ],
            1
          )
        : _vm.allGroupsHidden && _vm.appliances.length != 0
        ? _c(
            "v-layout",
            {
              attrs: {
                column: "",
                "align-center": "",
                "justify-center": "",
                "fill-height": ""
              }
            },
            [
              _c("v-icon", { attrs: { size: "50" } }, [
                _vm._v(_vm._s(_vm.IconHelper.getApplianceMissingIcon()))
              ]),
              _c("p", { staticClass: "title mt-2" }, [
                _vm._v(_vm._s(_vm.$t("biz.nodatascreen.nomeasuresites.title")))
              ]),
              _c("p", { staticClass: "subtitle" }, [
                _vm._v(_vm._s(_vm.$t("biz.groups.filternoresult")))
              ])
            ],
            1
          )
        : _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-layout",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.dataIsMissing,
                      expression: "!dataIsMissing"
                    }
                  ],
                  attrs: { column: "", "align-start": "", "justify-start": "" }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "mb-1" },
                    [
                      _vm.filterItems(_vm.incompleteSetupSmartModuleAppliances)
                        .length > 0
                        ? _c(
                            "v-flex",
                            { staticClass: "font-weight-bold" },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticStyle: {
                                    "text-transform": "uppercase",
                                    color: "bus_grey"
                                  },
                                  attrs: { "pl-0": "" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("general.setupincomplete")
                                      ) +
                                      "\n          "
                                  )
                                ]
                              ),
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                _vm._l(
                                  _vm.filterItems(
                                    _vm.incompleteSetupSmartModuleAppliances
                                  ),
                                  function(item) {
                                    return _c(
                                      "div",
                                      { key: item.name },
                                      [
                                        _c("ExtendableItem", {
                                          attrs: {
                                            measureSite: item,
                                            hasSensors: _vm.hasCompanySensors
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "mb-1" },
                    [
                      _vm.filterItems(_vm.unlicensedAppliances).length > 0
                        ? _c(
                            "v-flex",
                            { staticClass: "font-weight-bold" },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticStyle: {
                                    "text-transform": "uppercase",
                                    color: "bus_grey"
                                  },
                                  attrs: { "pl-0": "" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("biz.groups.unlicensed.title")
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                _vm._l(
                                  _vm.filterItems(_vm.unlicensedAppliances),
                                  function(item) {
                                    return _c(
                                      "div",
                                      { key: item.name },
                                      [
                                        _c("ExtendableItem", {
                                          attrs: {
                                            measureSite: item,
                                            hasSensors: _vm.hasCompanySensors
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  !_vm.groupsFiltered && _vm.isAdminUser
                    ? _c(
                        "v-layout",
                        { attrs: { row: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticStyle: { margin: "4px 7px" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value: "CustomerGroups::ShowGroupEditor",
                                      expression:
                                        "'CustomerGroups::ShowGroupEditor'"
                                    }
                                  ],
                                  attrs: { depressed: "", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showEditor()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("biz.groups.editor")))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticStyle: { margin: "4px 7px", flex: "none" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value: "CustomerGroups::AddAppliances",
                                      expression:
                                        "'CustomerGroups::AddAppliances'"
                                    }
                                  ],
                                  attrs: { depressed: "", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addAppliance()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("biz.groups.addappliance"))
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.groupVisible(_vm.defaultGroup)
                    ? _c(
                        "v-flex",
                        { staticClass: "mb-1" },
                        [
                          _vm.filterItems(_vm.defaultGroup.appliances).length >
                          0
                            ? _c(
                                "v-flex",
                                { staticClass: "font-weight-bold" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    _vm._l(
                                      _vm.filterItems(
                                        _vm.defaultGroup.appliances
                                      ),
                                      function(item) {
                                        return _c(
                                          "div",
                                          { key: item.name },
                                          [
                                            _c("ExtendableItem", {
                                              attrs: {
                                                measureSite: item,
                                                hasSensors:
                                                  _vm.hasCompanySensors
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.userGroups, function(group) {
                    return _c(
                      "v-flex",
                      { key: group.id, staticClass: "mb-1" },
                      [
                        _vm.groupVisible(group)
                          ? [
                              _c(
                                "v-flex",
                                { staticClass: "font-weight-bold" },
                                [_vm._v(_vm._s(group.name))]
                              ),
                              _c(
                                "v-flex",
                                [
                                  _vm.filterItems(group.appliances).length > 0
                                    ? _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        _vm._l(
                                          _vm.filterItems(group.appliances),
                                          function(item) {
                                            return _c(
                                              "v-flex",
                                              {
                                                key: item.name,
                                                staticClass: "pa-0",
                                                attrs: { shrink: "" }
                                              },
                                              [
                                                _c("ExtendableItem", {
                                                  attrs: {
                                                    measureSite: item,
                                                    hasSensors:
                                                      _vm.hasCompanySensors
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  !_vm.groupsFiltered && _vm.isAdminUser
                    ? _c(
                        "v-flex",
                        { staticClass: "font-weight-bold" },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: {
                                "text-transform": "uppercase",
                                color: "bus_grey"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("biz.groups.emptygroups")))]
                          ),
                          _vm._l(_vm.emptyGroups, function(group) {
                            return _c(
                              "v-flex",
                              {
                                key: group.id,
                                staticClass: "font-weight-bold",
                                staticStyle: { margin: "16px 16px 19px" }
                              },
                              [_vm._v(_vm._s(group.name))]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                2
              ),
              !_vm.dataIsMissing
                ? _c("GroupsEditor", {
                    attrs: {
                      editorShown: _vm.editorShown,
                      title: _vm.$t("biz.groups.editor")
                    },
                    on: {
                      evtSave: _vm.onSave,
                      evtCancel: function($event) {
                        return _vm.onCancel()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }