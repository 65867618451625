var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseWindowPage", {
    attrs: { dialogShown: _vm.cancelDialogShown },
    on: {
      evtCancel: function($event) {
        return _vm.abortProcess()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(" " + _vm._s(_vm.$t("biz.setup.twozones.title")))]
        },
        proxy: true
      },
      {
        key: "subtitle",
        fn: function() {
          return [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("biz.setup.twozones.text")) + "\n    "
            ),
            _c(
              "v-layout",
              { attrs: { "fill-height": "", row: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { "fill-height": "", xs8: "" } },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "margin-bottom": "0px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))]
                    ),
                    _c("v-text-field", {
                      staticClass: "default selected errorborder mt-4",
                      staticStyle: { height: "75px" },
                      attrs: {
                        rules: _vm.applianceNameRules,
                        type: "string",
                        clearable: _vm.iconHelper.clearableIcon(_vm.name),
                        placeholder: _vm.$t("biz.applianceinfo.name")
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    }),
                    _c("p", {
                      staticClass: "ml-2 mb-2 mt-1",
                      staticStyle: {
                        color: "var(--v-bus_black_high_opacity-base)",
                        "font-size": "14px"
                      },
                      domProps: { innerHTML: _vm._s(_vm.getNameHint()) }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("p", {
              staticClass: "bus_normaltext mt-4",
              domProps: {
                innerHTML: _vm._s(_vm.$t("biz.setup.twozones.text2"))
              }
            }),
            _c(
              "v-layout",
              {
                attrs: {
                  row: "",
                  nowrap: "",
                  "align-center": "",
                  "justify-left": "",
                  "mt-3": ""
                }
              },
              [
                _c(
                  "v-card",
                  {
                    class: _vm.freezerSelected ? "primary" : "grey lighten-1",
                    staticStyle: { cursor: "pointer" },
                    attrs: { hover: "", height: "85px", width: "150px" },
                    on: {
                      click: [
                        function($event) {
                          return _vm.setZone(false)
                        },
                        function($event) {
                          _vm.freezerSelected = !_vm.freezerSelected
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          "align-center": "",
                          "justify-center": "",
                          "mt-1": ""
                        }
                      },
                      [
                        _c(
                          "v-img",
                          {
                            attrs: {
                              src: require("@/assets/cooling@3x.png"),
                              "max-width": "45"
                            }
                          },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  slot: "placeholder",
                                  "fill-height": "",
                                  "align-center": "",
                                  "justify-center": "",
                                  "ma-0": ""
                                },
                                slot: "placeholder"
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "grey lighten-5"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { "mt-2": "" } },
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "font-weight-bold",
                            staticStyle: { color: "white" },
                            attrs: { "justify-center": "" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("biz.setup.twozones.cooler"))
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card",
                  {
                    class: !_vm.freezerSelected ? "primary" : "grey lighten-1",
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      hover: "",
                      height: "85px",
                      width: "150px",
                      depressed: ""
                    },
                    on: {
                      click: [
                        function($event) {
                          return _vm.setZone(true)
                        },
                        function($event) {
                          _vm.freezerSelected = !_vm.freezerSelected
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          "align-center": "",
                          "justify-center": "",
                          "mt-1": ""
                        }
                      },
                      [
                        _c(
                          "v-img",
                          {
                            attrs: {
                              src: require("@/assets/freezing@3x.png"),
                              "max-width": "45"
                            }
                          },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  slot: "placeholder",
                                  "fill-height": "",
                                  "align-center": "",
                                  "justify-center": "",
                                  "ma-0": ""
                                },
                                slot: "placeholder"
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "grey lighten-5"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { "mt-2": "" } },
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "font-weight-bold",
                            staticStyle: { color: "white" },
                            attrs: { "justify-center": "" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("biz.setup.twozones.freezer"))
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }