var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: {
      title: _vm.$t("biz.dashboard.alarms.alarmcomments"),
      message: _vm.$t("biz.dashboard.alarms.existingcomments"),
      dialogShown: _vm.dialogShown,
      track: _vm.track
    },
    on: {
      evtClose: function($event) {
        return _vm.onClosePressed()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "additionalcontent",
        fn: function() {
          return [
            _c(
              "v-layout",
              { staticClass: "body-2", attrs: { column: "" } },
              [
                _c(
                  "v-layout",
                  { attrs: { row: "" } },
                  [
                    _c("v-flex", { attrs: { xs3: "" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("biz.dashboard.alarms.devicename")) + ":"
                      )
                    ]),
                    _c("v-flex", [_vm._v(_vm._s(_vm.selectedAlarm.deviceName))])
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  { attrs: { row: "" } },
                  [
                    _c("v-flex", { attrs: { xs3: "" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("biz.dashboard.alarms.typeofalarm")) + ":"
                      )
                    ]),
                    _c("v-flex", [_vm._v(_vm._s(_vm.selectedAlarm.task))])
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return _vm._l(_vm.comments, function(comment, i) {
            return _c(
              "v-card",
              {
                key: i,
                staticClass: "grey lighten-3 mb-3 body-2",
                attrs: { column: "", flat: "" }
              },
              [
                _c(
                  "v-expansion-panel",
                  [
                    _c(
                      "v-expansion-panel-content",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "CommentDialog::ToggleExpand",
                            expression: "'CommentDialog::ToggleExpand'"
                          }
                        ],
                        staticClass: "grey lighten-3 mt-1"
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "grey lighten-3",
                            attrs: { slot: "header", flat: "" },
                            slot: "header"
                          },
                          [
                            _c(
                              "v-layout",
                              { attrs: { row: "" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-flex",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "text-truncate mr-3",
                                                    attrs: { xs12: "" }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    _vm._s(comment.commentator)
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(comment.commentator))
                                    ])
                                  ]
                                ),
                                _c("v-flex", { attrs: { xs6: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$format.dateTime(comment.commentTime)
                                    )
                                  )
                                ]),
                                _c("v-flex", { attrs: { xs1: "" } })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("div", [
                          _c(
                            "p",
                            { staticClass: "ma-2 ml-4 grey lighten-3 body-2" },
                            [_vm._v(_vm._s(_vm.getReasonText(comment.reason)))]
                          )
                        ]),
                        _c("div", [
                          _c(
                            "p",
                            {
                              staticClass:
                                "resizable ma-2 ml-4 grey lighten-3 body-2"
                            },
                            [_vm._v(_vm._s(comment.comment))]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          })
        },
        proxy: true
      },
      {
        key: "buttons",
        fn: function() {
          return [
            _c("v-spacer"),
            _c("Add", {
              attrs: { icon: false, track: _vm.track },
              on: {
                evtAdd: function($event) {
                  return _vm.onAddPressed()
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }