// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AutomatedReportSettingsRequest
 */
export interface AutomatedReportSettingsRequest {
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsRequest
   */
  createdBy?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsRequest
   */
  frequency?: AutomatedReportSettingsRequestFrequencyEnum;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsRequest
   */
  language?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsRequest
   */
  name?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsRequest
   */
  recipientEmail?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsRequest
   */
  reportVariant?: AutomatedReportSettingsRequestReportVariantEnum;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsRequest
   */
  startDate?: string;
  /**
   * @type {string}
   * @memberof AutomatedReportSettingsRequest
   */
  timezone?: string;
  /**
   * @type {Array<string>}
   * @memberof AutomatedReportSettingsRequest
   */
  virtualFridgeIds?: Array<string>;
}

/**
 * @export
 * @enum {string}
 */
export enum AutomatedReportSettingsRequestFrequencyEnum {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly'
}
/**
 * @export
 * @enum {string}
 */
export enum AutomatedReportSettingsRequestReportVariantEnum {
  HACCP = 'HACCP',
  TEMPCONFORMITY = 'TEMPCONFORMITY'
}
