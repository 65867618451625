// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SupportInfoLicense
 */
export interface SupportInfoLicense {
  /**
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license1?: string;
  /**
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license2?: string;
  /**
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license3?: string;
  /**
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license4?: string;
  /**
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license5?: string;
  /**
   * @type {string}
   * @memberof SupportInfoLicense
   */
  license6?: string;
}
