var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { padding: "15px" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      _c("FeedbackDialog", {
        attrs: {
          dialogShown: _vm.showFeedbackDialog,
          preselect: "FAQ",
          track: "'FAQs::FeedbackDialog'"
        },
        on: {
          evtFeedbackDialogClose: function($event) {
            _vm.showFeedbackDialog = false
          }
        }
      }),
      _c(
        "v-layout",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c(
            "v-flex",
            { staticStyle: { "align-self": "center" }, attrs: { xs4: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: "FAQs::GoBack",
                          expression: "'FAQs::GoBack'"
                        }
                      ],
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { flat: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.goBack()
                        }
                      }
                    },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { color: "secondary", size: "40" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "white" } },
                            [_vm._v("keyboard_arrow_left")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "px-2",
                      staticStyle: { "align-self": "center" }
                    },
                    [
                      _c("v-layout", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("biz.main.help")))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-container",
        { attrs: { "fill-height": "", "grid-list-lg": "", fluid: "" } },
        [
          _c("CriticalDevicesPage", {
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "v-card",
                      { attrs: { "fill-height": "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { "pt-4": "", "pl-4": "", "pb-0": "" } },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.$t("biz.help.faq")))
                            ])
                          ]
                        ),
                        _c(
                          "v-layout",
                          { attrs: { row: "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs3: "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      column: "",
                                      "ml-3": "",
                                      "mt-3": ""
                                    }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "default selected",
                                      attrs: {
                                        clearable: _vm.iconHelper.clearableIcon(
                                          _vm.searchString
                                        ),
                                        placeholder:
                                          _vm.$t("general.search") + "..."
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          !_vm.iconHelper.clearableIcon(
                                            _vm.searchString
                                          )
                                            ? {
                                                key: "append",
                                                fn: function() {
                                                  return [
                                                    _c("v-icon", [
                                                      _vm._v("search")
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            : null
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.searchString,
                                        callback: function($$v) {
                                          _vm.searchString = $$v
                                        },
                                        expression: "searchString"
                                      }
                                    }),
                                    _c(
                                      "v-list",
                                      { staticClass: "scroll-y" },
                                      _vm._l(_vm.currentFaqsGroups, function(
                                        faqsGroup
                                      ) {
                                        return _c(
                                          "v-list-tile",
                                          {
                                            key: faqsGroup.faqGroupId,
                                            class: _vm.formatSelectedFaqsGroup(
                                              faqsGroup
                                            ),
                                            staticStyle: {
                                              "margin-top": "1px",
                                              "padding-left": "12px"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.changeSelect(
                                                  faqsGroup
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-tile-title", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  faqsGroup.name
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { staticClass: "bus_normaltext mt-4" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.help.customerfeedback.faqtext"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { staticClass: "ma-0 pa-0" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            directives: [
                                              {
                                                name: "track",
                                                rawName: "v-track",
                                                value: "FAQs::ShowFeedback",
                                                expression:
                                                  "'FAQs::ShowFeedback'"
                                              }
                                            ],
                                            attrs: {
                                              depressed: "",
                                              outline: "",
                                              color: "secondary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.showFeedback()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "biz.help.customerfeedback.contactus"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-divider", { attrs: { vertical: "" } }),
                            _c(
                              "v-flex",
                              { attrs: { "ml-3": "", xs9: "" } },
                              [
                                _c(
                                  "v-list",
                                  {
                                    staticClass: "scroll-y mt-2",
                                    staticStyle: { height: "80vh" }
                                  },
                                  _vm._l(
                                    _vm.filteredQuestions(
                                      _vm.selectedFaqsGroup
                                    ),
                                    function(item, index) {
                                      return _c(
                                        "v-flex",
                                        { key: index, attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "bus_propertytitle"
                                            },
                                            [_vm._v(_vm._s(item.questionTitle))]
                                          ),
                                          _c("v-flex", {
                                            staticClass: "bus_normaltext",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                "<p>" + item.answer + "</p>"
                                              )
                                            }
                                          }),
                                          item.faqsGroupId === 800
                                            ? _c(
                                                "div",
                                                [
                                                  _c("ContactInfo", {
                                                    attrs: {
                                                      shownAsDialog: false
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }