var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FilterMenu", {
    attrs: { filterFlag: _vm.filter, track: "StatusFilter::FilterMenu" },
    on: { evtApplyFilter: _vm.onApplyFilter },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.status")))]
        },
        proxy: true
      },
      {
        key: "subTitle",
        fn: function() {
          return [
            _c("div", { staticStyle: { color: "bus_grey" } }, [
              _vm._v(_vm._s(_vm.filterStatus))
            ])
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "v-flex",
              {
                staticClass: "ma-3",
                staticStyle: {
                  "font-weight": "bold",
                  "text-transform": "uppercase"
                }
              },
              [_vm._v(_vm._s(_vm.$t("biz.dashboard.alarms.statustitle")))]
            ),
            _c(
              "v-radio-group",
              {
                attrs: { mandatory: false },
                model: {
                  value: _vm.selectedValue,
                  callback: function($$v) {
                    _vm.selectedValue = $$v
                  },
                  expression: "selectedValue"
                }
              },
              [
                _c("v-radio", {
                  staticClass: "ma-2 pl-2",
                  attrs: {
                    color: "secondary",
                    label: _vm.$t("general.all"),
                    value: _vm.allStatuses
                  }
                }),
                _c("v-radio", {
                  staticClass: "ma-2 pl-2",
                  attrs: {
                    color: "secondary",
                    label: _vm.$t("general.active"),
                    value: _vm.onStatus
                  }
                }),
                _c("v-radio", {
                  staticClass: "ma-2 pl-2",
                  attrs: {
                    color: "secondary",
                    label: _vm.$t("general.inactive"),
                    value: _vm.offStatus
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }