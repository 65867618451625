var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "", height: "100%" } },
    [
      !_vm.dataReady
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "65vh" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.loading")))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady && !_vm.selectedSensorAlarmProfile
        ? _c(
            "v-layout",
            { attrs: { "mt-4": "", column: "" } },
            [
              _c("p", { staticClass: "ml-3" }, [
                _vm._v(
                  _vm._s(_vm.$t("biz.sensors.alarmsetting.create.explanation"))
                )
              ]),
              _c(
                "v-flex",
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "track",
                          rawName: "v-track",
                          value: _vm.track + "::AskCreate",
                          expression: "track + '::AskCreate'"
                        }
                      ],
                      attrs: { depressed: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.showProfileCreationWizard()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("general.create")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady && _vm.selectedSensorAlarmProfile
        ? _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs10: "", "pl-4": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _vm.isTemperatureSensor()
                            ? _c("TemperatureAlarm", {
                                attrs: {
                                  editableData: _vm.editableData,
                                  track: _vm.track + "::TemperatureAlarm"
                                },
                                on: { evtSave: _vm.saveChanges }
                              })
                            : _vm._e(),
                          _vm.isDoorSensor()
                            ? _c("DoorAlarmDelay", {
                                attrs: {
                                  editableData: _vm.editableData,
                                  track: _vm.track + "::DoorAlarmDelay"
                                },
                                on: { evtSave: _vm.saveChanges }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticStyle: { "padding-top": "52px" } },
                        [
                          _c("BatteryAlarmLevel", {
                            attrs: {
                              editableData: _vm.editableData,
                              track: _vm.track + "::BatteryAlarmLevel"
                            },
                            on: { evtSave: _vm.saveChanges }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticStyle: { "padding-top": "52px" } },
                        [
                          _c("SensorUnavailableAlarm", {
                            attrs: {
                              editableData: _vm.editableData,
                              track: _vm.track + "::SensorUnavailableAlarm"
                            },
                            on: { evtSave: _vm.saveChanges }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticStyle: { "padding-top": "52px" } },
                        [
                          _c("BsdbUnavailableAlarm", {
                            attrs: {
                              editableData: _vm.editableData,
                              gateway: _vm.gateway,
                              track: _vm.track + "::BsdbUnavailableAlarm"
                            },
                            on: { evtSave: _vm.saveChanges }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticStyle: { "padding-top": "52px" } },
                        [
                          _c("ReminderPeriod", {
                            attrs: {
                              editableData: _vm.editableData,
                              track: _vm.track + "::ReminderPeriod"
                            },
                            on: { evtSave: _vm.saveChanges }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticStyle: { "padding-top": "52px" } },
                        [
                          _c("AlarmNotification", {
                            attrs: {
                              allUsers: _vm.allUsers,
                              editableData: _vm.editableData,
                              track: _vm.track + "::AlarmNotification"
                            },
                            on: { evtSave: _vm.saveChanges }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("AlarmProfileCreationWizard", {
        attrs: {
          showWizard: _vm.showWizard,
          currentSensor: _vm.currentSensor,
          track: _vm.track + "::AlarmSettingCreationWizard"
        },
        on: {
          "update:showWizard": function($event) {
            _vm.showWizard = $event
          },
          "update:show-wizard": function($event) {
            _vm.showWizard = $event
          },
          evtCloseWizard: function($event) {
            _vm.showWizard = false
          },
          evtSensorProfileCreated: _vm.sensorAlarmProfileCreated
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }