var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Manuals", {
    attrs: {
      installationManuals: _vm.installationManuals,
      instructionManuals: _vm.instructionManuals
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }