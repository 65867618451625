var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "700px", "min-width": "600px" },
      model: {
        value: _vm.openLiebherrApplianceDialog,
        callback: function($$v) {
          _vm.openLiebherrApplianceDialog = $$v
        },
        expression: "openLiebherrApplianceDialog"
      }
    },
    [
      _c("BaseWindowItem", {
        attrs: { trackCancel: "MeasureSiteLiebherrAppliance::Close" },
        on: {
          evtCancel: function($event) {
            return _vm.closeDialog()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "biz.sensors.setup.measuresite.datacards.titleliebherrappliance"
                    )
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("div", { staticClass: "font-weight-black" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "biz.sensors.setup.measuresite.datacards.properties"
                      )
                    )
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "description",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "biz.sensors.setup.measuresite.datacards.descriptionliebherrappliance"
                    )
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-container",
                  { attrs: { "pb-0": "" } },
                  [
                    _c(
                      "v-layout",
                      [
                        _c(
                          "v-layout",
                          { attrs: { column: "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { "mr-4": "" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "bus_required_field" },
                                  [
                                    _vm._v(
                                      "* " + _vm._s(_vm.$t("general.required"))
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "biz.sensors.setup.properties.name"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected errorborder",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForLiebherrAppliance.name
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.sensors.setup.properties.name"
                                    ),
                                    rules: _vm.nameRules,
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForLiebherrAppliance.name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForLiebherrAppliance,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForLiebherrAppliance.name"
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "bus_required_field" },
                                  [
                                    _vm._v(
                                      "* " + _vm._s(_vm.$t("general.required"))
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "biz.sensors.setup.properties.serial"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value:
                                        _vm.ValidationHelper
                                          .MASK_APPLIANCE_SERIAL,
                                      expression:
                                        "ValidationHelper.MASK_APPLIANCE_SERIAL"
                                    }
                                  ],
                                  staticClass: "default selected errorborder",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForLiebherrAppliance
                                        .serialNumber
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.sensors.setup.properties.serial"
                                    ),
                                    rules: _vm.serialNumberRules,
                                    color: _vm.textColor,
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForLiebherrAppliance
                                        .serialNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForLiebherrAppliance,
                                        "serialNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForLiebherrAppliance.serialNumber"
                                  }
                                }),
                                _vm.alreadyTaken
                                  ? _c(
                                      "v-layout",
                                      {
                                        staticStyle: {
                                          color: "bus_red",
                                          "margin-top": "-25px",
                                          "margin-bottom": "10px",
                                          "margin-left": "12px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.onboarding.stepper.notavailable"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.doesNotExist
                                  ? _c(
                                      "v-layout",
                                      {
                                        staticStyle: {
                                          color: "bus_red",
                                          "margin-top": "-25px",
                                          "margin-bottom": "10px",
                                          "margin-left": "12px",
                                          "font-size": "12px"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.onboarding.stepper.notfoundserial"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("biz.applianceinfo.building")
                                      )
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForLiebherrAppliance.building
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.applianceinfo.building"
                                    ),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForLiebherrAppliance
                                        .building,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForLiebherrAppliance,
                                        "building",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForLiebherrAppliance.building"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-layout",
                          { attrs: { column: "" } },
                          [
                            _c(
                              "v-flex",
                              [
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("biz.applianceinfo.department")
                                      )
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForLiebherrAppliance
                                        .department
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.applianceinfo.department"
                                    ),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForLiebherrAppliance
                                        .department,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForLiebherrAppliance,
                                        "department",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForLiebherrAppliance.department"
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("biz.applianceinfo.level"))
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass: "default selected",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForLiebherrAppliance.level
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.applianceinfo.floor"
                                    ),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForLiebherrAppliance.level,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForLiebherrAppliance,
                                        "level",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForLiebherrAppliance.level"
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "0px",
                                      "font-weight": "bold"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("biz.applianceinfo.room"))
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  staticClass:
                                    "defaulttextarea selectedtextarea",
                                  attrs: {
                                    clearable: _vm.iconHelper.clearableIcon(
                                      _vm.localDataForLiebherrAppliance
                                        .roomNumber
                                    ),
                                    placeholder: _vm.$t(
                                      "biz.applianceinfo.room"
                                    ),
                                    type: "string",
                                    required: ""
                                  },
                                  model: {
                                    value:
                                      _vm.localDataForLiebherrAppliance
                                        .roomNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.localDataForLiebherrAppliance,
                                        "roomNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "localDataForLiebherrAppliance.roomNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-layout",
                      [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "margin-bottom": "0px",
                              "font-weight": "bold"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("general.comment")))]
                        ),
                        _c("v-textarea", {
                          staticClass: "defaulttextarea selectedtextarea",
                          attrs: {
                            clearable: _vm.iconHelper.clearableIcon(
                              _vm.localDataForLiebherrAppliance.comment
                            ),
                            placeholder: _vm.$t("general.comment"),
                            type: "string",
                            required: ""
                          },
                          model: {
                            value: _vm.localDataForLiebherrAppliance.comment,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.localDataForLiebherrAppliance,
                                "comment",
                                $$v
                              )
                            },
                            expression: "localDataForLiebherrAppliance.comment"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "Manuals::OpenInstruction",
                            expression: "'Manuals::OpenInstruction'"
                          }
                        ],
                        staticStyle: { "margin-left": "-2px" },
                        attrs: {
                          disabled: _vm.noApplianceInfo,
                          depressed: "",
                          outline: "",
                          color: "secondary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showAppliancePicture()
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "pr-1",
                            attrs: { color: "secondary" }
                          },
                          [_vm._v("help_outline")]
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("biz.onboarding.stepper.checkappliance")
                            ) +
                            "\n        "
                        )
                      ],
                      1
                    ),
                    _c("SimpleDialog", {
                      attrs: {
                        title: _vm.$t(
                          "biz.sensors.setup.measuresite.datacards.nonconnectable"
                        ),
                        dialogShown: _vm.dialogShown,
                        dialogWidth: "700px"
                      },
                      on: {
                        evtClose: function($event) {
                          _vm.dialogShown = false
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "content",
                          fn: function() {
                            return [
                              _c("SetupApplianceInfo", {
                                attrs: {
                                  applianceInfo: _vm.applianceInfo
                                    ? _vm.applianceInfo
                                    : "",
                                  serialNumber:
                                    _vm.localDataForLiebherrAppliance
                                      .serialNumber
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  {
                    staticStyle: { "justify-content": "flex-end" },
                    attrs: { "mb-2": "" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "track",
                            rawName: "v-track",
                            value: "MeasureSiteLiebherrAppliance::Next",
                            expression: "'MeasureSiteLiebherrAppliance::Next'"
                          }
                        ],
                        attrs: {
                          depressed: "",
                          disabled:
                            !_vm.localDataForLiebherrAppliance.name ||
                            _vm.noApplianceInfo ||
                            _vm.alreadyTaken,
                          color: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showGroupDialog()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("general.next")))]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("ChooseGroupDialog", {
        attrs: { openGroupDialog: _vm.openGroupDialog },
        on: { evtGroupDialogSave: _vm.groupSelected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }