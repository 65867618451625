// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { LicenseOverviewResponse } from './';

/**
 * @export
 * @interface UnassignmentResultResponse
 */
export interface UnassignmentResultResponse {
  /**
   * @type {LicenseOverviewResponse}
   * @memberof UnassignmentResultResponse
   */
  licenseOverviewResponse?: LicenseOverviewResponse;
  /**
   * @type {string}
   * @memberof UnassignmentResultResponse
   */
  result?: UnassignmentResultResponseResultEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UnassignmentResultResponseResultEnum {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}
