var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "", row: "", wrap: "" } },
    [
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dataReady,
              expression: "dataReady"
            }
          ],
          attrs: { sm12: "", lg6: "", "pr-0": "" }
        },
        [
          _c(
            "v-container",
            { attrs: { "pr-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { "pr-0": "" } },
                    [
                      _c("GeneralInfo", {
                        attrs: {
                          licensed: _vm.isSensorLicensed(_vm.sensorId),
                          editableData: _vm.editableSensor,
                          track: "SensorInfo::GeneralInfo"
                        },
                        on: { evtSave: _vm.saveSensorInfo }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticStyle: { margin: "52px 0px" } },
                    [
                      _c("Manufacturer", {
                        attrs: {
                          sensor: _vm.editableSensor,
                          track: "SensorInfo::Manufacturer"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          attrs: { sm12: "", lg6: "", "pr-0": "" }
        },
        [
          _c(
            "v-container",
            { attrs: { "pr-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c("SamplingRateEditor", {
                        attrs: {
                          licensed: _vm.isSensorLicensed(_vm.sensorId),
                          sensor: _vm.editableSensor,
                          track: "SensorInfo::SamplingRateEditor"
                        },
                        on: { evtSave: _vm.saveSensorSamplingRate }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }