var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32px",
        height: "32px",
        viewBox: "0 0 32 32",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("g", [
            _c("g", { staticStyle: { fill: "var(--v-secondary-base)" } }, [
              _c("circle", { attrs: { cx: "16", cy: "16", r: "16" } })
            ]),
            _c(
              "g",
              {
                staticStyle: { fill: "var(--v-bus_white-base)" },
                attrs: { transform: "translate(4.000000, 4.000000)" }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M7,2 C8.65685425,2 10,3.34314575 10,5 L9.99996476,7.57684712 C9.34790304,8.11512527 8.84520487,8.82504625 8.56917651,9.64396657 L8.56917651,9.64396657 L8.50577612,9.84797975 L8.31368343,9.94082922 C6.92569762,10.6540904 6,12.0716317 6,13.6818182 C6,16.0378236 7.96037525,17.9090909 10.3333333,17.9090909 L10.3333333,17.9090909 L11.9173823,17.9100375 C11.4894976,20.2369491 9.45064011,22 7,22 C4.23857625,22 2,19.7614237 2,17 C2,15.3642694 2.7854695,13.9119951 3.99980567,12.9997798 L4,5 C4,3.34314575 5.34314575,2 7,2 Z M7,4 C6.44771525,4 6,4.44771525 6,5 L6,9 L8,9 L8,5 C8,4.44771525 7.55228475,4 7,4 Z"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M9.6498054,11.5516364 L10.3609012,11.4545455 L10.3609012,10.7121212 C10.5496016,9.45286051 11.6841498,8.48484848 13.0555556,8.48484848 C13.3148168,8.48484848 13.565613,8.51944434 13.8031539,8.58406357 C14.3247402,7.64199174 15.3616052,7 16.5555556,7 C18.2737748,7 19.6666667,8.32957862 19.6666667,9.96969697 C19.6666667,10.0008747 19.6661633,10.0319403 19.665164,10.0628866 C21.0077698,10.3920556 22,11.5551179 22,12.9393939 C22,14.5795123 20.6071081,15.9090909 18.8888889,15.9090909 L10.3333333,15.9090909 C9.04466892,15.9090909 8,14.9119069 8,13.6818182 C8,12.67875 8.694651,11.8305515 9.6498054,11.5516364 Z"
                  }
                })
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }