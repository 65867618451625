var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseWindowPage", {
    attrs: { trackCancel: "BsdbLanData::Close" },
    on: {
      evtCancel: function($event) {
        return _vm.onCancelProcess()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _vm._v(
              _vm._s(_vm.$t("biz.setup.sch_serialnumber.bsdbserial.title"))
            )
          ]
        },
        proxy: true
      },
      {
        key: "description",
        fn: function() {
          return [
            _vm._v(_vm._s(_vm.$t("biz.setup.sch_serialnumber.bsdbserial.text")))
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "v-layout",
              { attrs: { row: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { xs6: "" } },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "margin-bottom": "0px",
                          "margin-top": "30px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("biz.general.items.serial")))]
                    ),
                    _c("v-text-field", {
                      staticClass: "default selected errorborder",
                      attrs: {
                        clearable: _vm.iconHelper.clearableIcon(
                          _vm.serialNumber
                        ),
                        rules: _vm.bsdbSerialRules,
                        type: "string",
                        required: "",
                        "error-messages": _vm.errorMessages,
                        placeholder: _vm.BSDB_NAME_START
                      },
                      model: {
                        value: _vm.serialNumber,
                        callback: function($$v) {
                          _vm.serialNumber = $$v
                        },
                        expression: "serialNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-img",
                  {
                    attrs: {
                      src: require("@/assets/bsdb_serialnumber.png"),
                      contain: "",
                      "max-height": "180"
                    }
                  },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          slot: "placeholder",
                          "fill-height": "",
                          "align-center": "",
                          "justify-center": "",
                          "ma-0": ""
                        },
                        slot: "placeholder"
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "grey lighten-5" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-flex", { staticClass: "bus_normaltext pt-4" }, [
              _vm._v(_vm._s(_vm.$t("biz.setup.sch_serialnumber.bsdbname.text")))
            ]),
            _c(
              "v-flex",
              { staticStyle: { "margin-top": "10px" }, attrs: { xs12: "" } },
              [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "margin-bottom": "0px",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))]
                ),
                _c("v-text-field", {
                  staticClass: "default selected errorborder",
                  attrs: {
                    clearable: _vm.iconHelper.clearableIcon(_vm.name),
                    placeholder: _vm.$t("biz.applianceinfo.name"),
                    rules: _vm.bsdbNameRules,
                    type: "string",
                    required: ""
                  },
                  model: {
                    value: _vm.name,
                    callback: function($$v) {
                      _vm.name = $$v
                    },
                    expression: "name"
                  }
                })
              ],
              1
            ),
            _c("v-flex", { staticClass: "bus_normaltext pt-4" }, [
              _vm._v(_vm._s(_vm.$t("biz.onboarding.start.timezonetext")))
            ]),
            _c(
              "v-flex",
              { staticStyle: { "margin-top": "10px" }, attrs: { xs12: "" } },
              [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "margin-bottom": "10px",
                      "font-weight": "bold"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("general.timezone.title")))]
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c("TimeZoneSelector", {
                      on: { evtTimeZoneChange: _vm.onTimeZoneChanged }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }