var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: {
      title: _vm.$t("general.menus.usermenu.logo.changelogo"),
      message: _vm.$t("general.menus.usermenu.logo.uploadlogo"),
      dialogShown: _vm.dialogShown,
      buttonExit: false,
      track: _vm.track + "::UploadLogo"
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "", "pa-": "" } },
              [
                _c("v-flex", { attrs: { xs12: "", "pb-5": "" } }, [
                  _c("p", { staticClass: "bus_normaltext mb-3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("general.menus.usermenu.logo.maxsizemessage")
                      )
                    )
                  ]),
                  _c(
                    "form",
                    { attrs: { enctype: "multipart/form-data" } },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "v-btn v-btn--depressed theme--light secondary ma-0",
                          staticStyle: { cursor: "pointer" },
                          attrs: { for: "file-input" }
                        },
                        [
                          _c("div", { staticClass: "v-btn__content" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("general.menus.usermenu.logo.selectfile")
                              )
                            )
                          ])
                        ]
                      ),
                      _c("ValidationProvider", {
                        ref: "provider",
                        attrs: { rules: "file_size:60|image_format" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var validate = ref.validate
                              var errors = ref.errors
                              return [
                                _c("input", {
                                  staticStyle: { visibility: "hidden" },
                                  attrs: {
                                    type: "file",
                                    accept: "image/*",
                                    id: "file-input"
                                  },
                                  on: { change: _vm.handleFileChange }
                                }),
                                _c("p", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(errors[0]))
                                ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "v-flex",
                  { attrs: { xs6: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { column: "" } },
                      [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("general.menus.usermenu.logo.currentlogo")
                            )
                          )
                        ]),
                        _vm.currentImage
                          ? _c("img", {
                              staticClass: "companyimage",
                              attrs: {
                                src: _vm.currentImage,
                                alt: _vm.$t(
                                  "general.menus.usermenu.logo.currentlogo"
                                )
                              }
                            })
                          : _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("general.menus.usermenu.logo.nologo")
                                )
                              )
                            ]),
                        _vm.currentImage
                          ? _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "track",
                                    rawName: "v-track",
                                    value: _vm.track + "::DeleteImage",
                                    expression: "track + '::DeleteImage'"
                                  }
                                ],
                                staticClass: "ml-0 mt-4",
                                staticStyle: { width: "72px" },
                                attrs: {
                                  color: "secondary",
                                  depressed: "",
                                  outline: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showDeleteDialog = true
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("general.delete")))]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-flex", { attrs: { xs6: "" } }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("general.menus.usermenu.logo.newlogo"))
                    )
                  ]),
                  _vm.filePreview
                    ? _c("img", {
                        staticClass: "companyimage",
                        attrs: {
                          src: _vm.filePreview,
                          alt: _vm.$t("general.menus.usermenu.logo.newlogo")
                        }
                      })
                    : _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("general.menus.usermenu.logo.notselected")
                          )
                        )
                      ])
                ]),
                _vm.errorMessage
                  ? _c("v-flex", { attrs: { xs12: "" } }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("general.menus.usermenu.logo.uploaderror")
                          )
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "buttons",
        fn: function() {
          return [
            _c("v-spacer"),
            _c("SaveCancel", {
              staticClass: "buttons mr-2 mb-2",
              attrs: {
                icon: false,
                track: _vm.track,
                saveDisabled: _vm.saveDisabled
              },
              on: {
                evtSave: function($event) {
                  return _vm.onSavePressed()
                },
                evtCancel: function($event) {
                  return _vm.onCancelPressed()
                }
              }
            }),
            _c("DeleteDialog", {
              attrs: {
                dialogShown: _vm.showDeleteDialog,
                message: _vm.$t("general.menus.usermenu.logo.deletelogo"),
                track: _vm.track + "::DeleteDialog"
              },
              on: {
                evtDeletePressed: function($event) {
                  return _vm.deleteImage()
                },
                evtCancelPressed: function($event) {
                  _vm.showDeleteDialog = false
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }