var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: { title: _vm.title, dialogShown: _vm.dialogShown, track: _vm.track },
    on: {
      evtClose: function($event) {
        return _vm.closeErrorDialog()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("p", [
              _c("span", {
                staticClass: "bus_normaltext",
                domProps: { innerHTML: _vm._s(_vm.messageText) }
              })
            ]),
            _vm.isConnectionError
              ? _c("div", [
                  _c("div", { staticClass: "bus_normaltext pa-1" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "biz.smartmoduleinfo.connectivity.connectionerrordescription"
                          )
                        ) +
                        "\n      "
                    )
                  ]),
                  _vm.isLANOnboardingFlow
                    ? _c("div", {
                        staticClass: "bus_normaltext",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "biz.smartmoduleinfo.connectivity.connectionrequirementlan"
                            )
                          )
                        }
                      })
                    : _c("div", {
                        staticClass: "bus_normaltext",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "biz.smartmoduleinfo.connectivity.connectionrequirementwifi"
                            )
                          )
                        }
                      }),
                  _vm.isLANOnboardingFlow
                    ? _c(
                        "div",
                        {
                          attrs: {
                            "align-center": "",
                            "justify-center": "",
                            "mt-1": ""
                          }
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/lan_error_basic@1x.png")
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("v-img", {
                            attrs: {
                              src: require("@/assets/wifi_error@1x.png")
                            }
                          })
                        ],
                        1
                      )
                ])
              : _vm._e(),
            _vm.contactsShown
              ? _c("ContactInfo", { attrs: { serialNumber: _vm.serialNumber } })
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "buttons",
        fn: function() {
          return [
            _c(
              "v-layout",
              { attrs: { row: "", nowrap: "" } },
              [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: "SmartModuleErrorDialog::Help",
                        expression: "'SmartModuleErrorDialog::Help'"
                      }
                    ],
                    attrs: { depressed: "", outline: "", color: "secondary" },
                    on: {
                      click: function($event) {
                        return _vm.onHelpPressed()
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "pl-0", attrs: { color: "secondary" } },
                      [_vm._v("help_outline")]
                    ),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.help")) +
                        "\n      "
                    )
                  ],
                  1
                ),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: "SmartModuleErrorDialog::AbortCM006Onboarding",
                        expression:
                          "'SmartModuleErrorDialog::AbortCM006Onboarding'"
                      }
                    ],
                    attrs: { depressed: "", color: "secondary", outline: "" },
                    on: {
                      click: function($event) {
                        return _vm.closeErrorDialog()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("general.cancel")))]
                ),
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: "SmartModuleErrorDialog::Retry",
                        expression: "'SmartModuleErrorDialog::Retry'"
                      }
                    ],
                    attrs: { depressed: "", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.onRetryPressed()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("general.retry")))]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }