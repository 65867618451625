var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", row: "" } },
        [
          _vm.selectedReports.length > 0
            ? _c("div", { staticClass: "ml-3 pl-2 mt-4" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$tc(
                        "biz.report.reportselected",
                        _vm.selectedReports.length
                      )
                    ) +
                    "\n    "
                )
              ])
            : _vm._e(),
          _c("v-spacer"),
          _c(
            "v-flex",
            {
              staticClass: "d-flex justify-space-between align-center mt-0 mb-0"
            },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::DeleteReport",
                      expression: "track + '::DeleteReport'"
                    }
                  ],
                  attrs: {
                    depressed: "",
                    outline: "",
                    color: "secondary",
                    disabled: _vm.isDisable()
                  },
                  on: {
                    click: function($event) {
                      return _vm.deleteReport()
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      style: _vm.bulkIconStyle,
                      attrs: { size: "20px" }
                    },
                    [_vm._v(_vm._s(_vm.getDeleteIcon()))]
                  ),
                  _c("span", { staticClass: "hide" }, [
                    _vm._v(_vm._s(_vm.$t("general.delete")))
                  ])
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::DownloadReports",
                      expression: "track + '::DownloadReports'"
                    }
                  ],
                  attrs: {
                    depressed: "",
                    outline: "",
                    color: "secondary",
                    disabled: _vm.isDisable()
                  },
                  on: {
                    click: function($event) {
                      return _vm.downloadReports()
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-1",
                      style: _vm.bulkIconStyle,
                      attrs: { size: "20" }
                    },
                    [_vm._v(_vm._s(_vm.getDownloadIcon()))]
                  ),
                  _c("span", { staticClass: "hide" }, [
                    _vm._v("PDF " + _vm._s(_vm.$t("general.download")))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          staticClass: "ml-2",
          staticStyle: { height: "40px", width: "100%" },
          attrs: { row: "" }
        },
        [
          _c(
            "v-list-tile-avatar",
            { staticClass: "ml-2", staticStyle: { width: "10%" } },
            [
              _c("v-checkbox", {
                attrs: {
                  color: "secondary",
                  indeterminate: _vm.indeterminateCheckbox
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.selectAllReports()
                  }
                },
                model: {
                  value: _vm.selectedCheckbox,
                  callback: function($$v) {
                    _vm.selectedCheckbox = $$v
                  },
                  expression: "selectedCheckbox"
                }
              })
            ],
            1
          ),
          _c("v-flex", { staticClass: "pl-0", staticStyle: { width: "38%" } }, [
            _c("p", { staticStyle: { "font-weight": "500" } }, [
              _vm._v(_vm._s(_vm.$t("general.communication.name")))
            ])
          ]),
          _c(
            "v-flex",
            {
              staticClass: "pl-0",
              staticStyle: { width: "40%", "margin-left": "-21px" }
            },
            [
              _c("p", { staticStyle: { "font-weight": "500" } }, [
                _vm._v(_vm._s(_vm.$t("biz.report.filter.reporter")))
              ])
            ]
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-layout",
        { staticClass: "report-container ml-2", attrs: { column: "" } },
        [
          _c(
            "v-list",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.automatedReportEntry,
                  expression: "automatedReportEntry"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: { "three-line": "" }
            },
            [
              _c(
                "v-list-tile",
                [
                  _c(
                    "v-list-tile-action",
                    { staticClass: "ml-2", staticStyle: { width: "10%" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", ripple: "" },
                          on: {
                            click: function($event) {
                              _vm.openHelpDialog = true
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "blue", size: "25" } },
                            [_vm._v("$vuetify.icons.bus_navigation_help")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "pl-0", staticStyle: { width: "38%" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pl-0 long-text",
                          staticStyle: { color: "var(--v-neutral40-base)" }
                        },
                        [_vm._v(_vm._s(_vm.reportName))]
                      ),
                      [
                        _c(
                          "div",
                          {
                            staticClass: "pl-0 pr-0 sub-title long-text",
                            staticStyle: { color: "var(--v-neutral40-base)" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.applianceNames.toString()) +
                                "\n            "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "pl-0 pr-0 sub-title long-text",
                            staticStyle: { color: "var(--v-neutral40-base)" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "biz.report.reportsettings.scheduledfor"
                                  )
                                ) +
                                ": " +
                                _vm._s(_vm.automatedReportFrequency) +
                                "\n            "
                            )
                          ]
                        )
                      ]
                    ],
                    2
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "pl-0 mb-1",
                      staticStyle: {
                        width: "40%",
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mt-5 sub-title long-text",
                          staticStyle: { color: "var(--v-neutral40-base)" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.createdBy) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list",
            { staticStyle: { width: "100%" }, attrs: { "three-line": "" } },
            _vm._l(_vm.sortedReports, function(item) {
              return _c(
                "v-list-tile",
                {
                  key: item.reportId,
                  class: _vm.formatSelectedReport(item),
                  on: {
                    click: function($event) {
                      return _vm.changeSelect(item)
                    }
                  }
                },
                [
                  _c(
                    "v-list-tile-avatar",
                    { staticClass: "ml-2", staticStyle: { width: "10%" } },
                    [
                      _c("v-checkbox", {
                        attrs: { value: item.reportId, color: "secondary" },
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "pl-0", staticStyle: { width: "38%" } },
                    [
                      _c("div", { staticClass: "pl-0 long-text" }, [
                        _vm._v(_vm._s(item.reportName))
                      ]),
                      [
                        _c(
                          "div",
                          { staticClass: "pl-0 pr-0 sub-title long-text" },
                          [_vm._v(_vm._s(item.deviceName))]
                        ),
                        _c(
                          "div",
                          { staticClass: "pl-0 pr-0 sub-title long-text" },
                          [_vm._v(_vm._s(_vm.getReportDate(item)))]
                        )
                      ]
                    ],
                    2
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "pl-0 mb-1",
                      staticStyle: {
                        width: "40%",
                        display: "flex",
                        "justify-content": "space-between"
                      }
                    },
                    [
                      _c("div", { staticClass: "mt-5 sub-title long-text" }, [
                        _vm._v(_vm._s(item.userName))
                      ]),
                      _c("div", { staticClass: "report-status" }, [
                        !_vm.isCreated(item)
                          ? _c("div", { staticClass: "sub-title" }, [
                              _vm._v(
                                _vm._s(_vm.getRequestStatusDescription(item))
                              )
                            ])
                          : _vm._e(),
                        item.isManuallyCreated != null &&
                        !item.isManuallyCreated
                          ? _c(
                              "div",
                              { staticStyle: { "align-self": "end" } },
                              [
                                _c("span", { staticClass: "auto-report-tag" }, [
                                  _vm._v("A")
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("OkCancelDialog", {
        attrs: {
          dialogShown: _vm.deleteReportShown,
          title: _vm.$t("biz.report.deletereport.title"),
          message: _vm.$t("biz.report.deletereport.message"),
          track: _vm.track + "::DeleteReportDialog"
        },
        on: {
          evtOkPressed: function($event) {
            return _vm.confirmDeleteReport()
          },
          evtCancelPressed: function($event) {
            return _vm.cancelDeleteReport()
          }
        }
      }),
      _c("SimpleDialog", {
        attrs: {
          dialogShown: _vm.openHelpDialog,
          title: _vm.$t("biz.report.reportsettings.helptitle"),
          message: _vm.$t("biz.report.reportsettings.helpdesc"),
          track: "track + '::ReportHelpDialog'"
        },
        on: {
          evtClose: function($event) {
            return _vm.closeHelpDialog()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  { attrs: { column: "", xs12: "", "mb-5": "" } },
                  [
                    _c(
                      "v-flex",
                      {
                        staticStyle: { "margin-top": "5px" },
                        attrs: { xs7: "" }
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "bus_normaltext",
                            staticStyle: {
                              "margin-bottom": "20px",
                              "text-align": "-webkit-center"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("biz.report.reportsettings.details")
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "bus_normaltext",
                            staticStyle: { color: "var(--v-neutral40-base)" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "biz.report.reportsettings.autoreportname"
                                  )
                                ) +
                                ": " +
                                _vm._s(_vm.reportName) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "bus_normaltext",
                            staticStyle: { color: "var(--v-neutral40-base)" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "biz.report.reportsettings.scheduledfor"
                                  )
                                ) +
                                ": " +
                                _vm._s(_vm.automatedReportFrequency) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "bus_normaltext",
                            staticStyle: { color: "var(--v-neutral40-base)" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("biz.report.filter.reporter")) +
                                ": " +
                                _vm._s(_vm.createdBy) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "bus_normaltext",
                            staticStyle: { color: "var(--v-neutral40-base)" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("biz.general.items.devices")) +
                                ": " +
                                _vm._s(_vm.applianceNames.toString()) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm.recipientEmail
                          ? _c(
                              "div",
                              {
                                staticClass: "bus_normaltext",
                                staticStyle: {
                                  color: "var(--v-neutral40-base)"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "general.communication.recipientemail"
                                      )
                                    ) +
                                    ": " +
                                    _vm._s(_vm.recipientEmail) +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }