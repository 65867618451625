// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SmartModuleZoneState } from './';

/**
 * @export
 * @interface SmartModuleState
 */
export interface SmartModuleState {
  /**
   * @type {Array<string>}
   * @memberof SmartModuleState
   */
  errorCodes?: Array<string>;
  /**
   * @type {Array<string>}
   * @memberof SmartModuleState
   */
  errorTypes?: Array<string>;
  /**
   * @type {Date}
   * @memberof SmartModuleState
   */
  lastTemperatureUpdate?: Date;
  /**
   * @type {string}
   * @memberof SmartModuleState
   */
  state?: string;
  /**
   * @type {string}
   * @memberof SmartModuleState
   */
  virtualFridgeId?: string;
  /**
   * @type {Array<SmartModuleZoneState>}
   * @memberof SmartModuleState
   */
  zones?: Array<SmartModuleZoneState>;
}
