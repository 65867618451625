var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value: _vm.track + "::Ok",
              expression: "track + '::Ok'"
            }
          ],
          attrs: { depressed: "", color: "primary", disabled: _vm.okDisabled },
          on: { click: _vm.onOkPressed }
        },
        [_vm._v(_vm._s(_vm.$t("general.ok")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }