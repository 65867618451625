var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    [
      _c(
        "v-layout",
        {
          attrs: {
            column: "",
            "align-center": "",
            "justify-center": "",
            "fill-height": ""
          }
        },
        [
          _c("v-icon", { attrs: { size: "50" } }, [
            _vm._v(_vm._s(_vm.dataMissingDisplayIcon))
          ]),
          _c("p", { staticClass: "title mt-2" }, [
            _vm._v(_vm._s(_vm.dataMissingDisplayTitle))
          ]),
          _c("p", { staticClass: "subtitle" }, [
            _vm._v(_vm._s(_vm.dataMissingDisplayDesc))
          ]),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.deviceManagementButton(_vm.interestedIn),
                  expression: "deviceManagementButton(interestedIn)"
                },
                {
                  name: "track",
                  rawName: "v-track",
                  value: "DataMissingDisplay::ToDeviceManagement",
                  expression: "'DataMissingDisplay::ToDeviceManagement'"
                }
              ],
              attrs: { depressed: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.toDeviceManagement()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("biz.settings.devicemanagement")))]
          ),
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.newReportButton(_vm.interestedIn),
                  expression: "newReportButton(interestedIn)"
                },
                {
                  name: "track",
                  rawName: "v-track",
                  value: "DataMissingDisplay::NewReport",
                  expression: "'DataMissingDisplay::NewReport'"
                }
              ],
              staticClass: "ma-0 pa-0 px-3 mr-1",
              attrs: { depressed: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.createReportShown = true
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("biz.report.newreport")))]
          )
        ],
        1
      ),
      _c("CreateReport", {
        attrs: {
          editorShown: _vm.createReportShown,
          selectedApplianceZone: _vm.selectedApplianceZone
        },
        on: {
          evtClose: function($event) {
            return _vm.closeDialog()
          },
          evtReportsCreated: _vm.reportCreated
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }