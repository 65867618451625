var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c("rect", { attrs: { x: "0", y: "0", width: "24", height: "24" } }),
          _c(
            "g",
            {
              attrs: {
                transform: "translate(2.500000, 8.500000)",
                stroke: "#000000"
              }
            },
            [
              _c("polygon", {
                attrs: { points: "7 14 0 14 0 -1.60677027e-13 7 0 7 6.3" }
              }),
              _c("line", { attrs: { x1: "5", y1: "8.5", x2: "5", y2: "10" } }),
              _c("line", { attrs: { x1: "5", y1: "4", x2: "5", y2: "5.5" } }),
              _c("line", { attrs: { x1: "0", y1: "6.95", x2: "7", y2: "7" } })
            ]
          ),
          _c(
            "g",
            {
              attrs: {
                transform: "translate(9.500000, 15.500000)",
                stroke: "#000000"
              }
            },
            [
              _c("polygon", {
                attrs: { points: "12 7 0 7 0 -8.03385136e-14 12 0 12 3.15" }
              })
            ]
          ),
          _c("polyline", {
            attrs: {
              stroke: "#000000",
              points: "16.5 1.5 16.5 4.5 14.5 7.5 18.5 7.5 16.5 4.5"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }