import { render, staticRenderFns } from "./TermsOfUseDialog.vue?vue&type=template&id=25aaca64&"
import script from "./TermsOfUseDialog.ts?vue&type=script&lang=ts&"
export * from "./TermsOfUseDialog.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/hau-bus-ui_master/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25aaca64')) {
      api.createRecord('25aaca64', component.options)
    } else {
      api.reload('25aaca64', component.options)
    }
    module.hot.accept("./TermsOfUseDialog.vue?vue&type=template&id=25aaca64&", function () {
      api.rerender('25aaca64', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/documentation/dialogs/TermsOfUseDialog.vue"
export default component.exports