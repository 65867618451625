var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("title", [_vm._v("delete")]),
      _c("defs", [
        _c("rect", { attrs: { x: "0", y: "0", width: "285", height: "153" } })
      ]),
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            { attrs: { transform: "translate(-252.000000, -10.000000)" } },
            [
              _c("g", [
                _c("mask", { attrs: { fill: "white" } }, [
                  _c("use", { attrs: { "xlink:href": "#path-1" } })
                ]),
                _c("rect", {
                  attrs: {
                    stroke: "#D4D4D6",
                    x: "0.5",
                    y: "0.5",
                    width: "284",
                    height: "152"
                  }
                }),
                _c(
                  "text",
                  {
                    attrs: {
                      mask: "url(#mask-2)",
                      "font-family": "HelveticaNeue-Medium, Helvetica Neue",
                      "font-size": "14",
                      "font-weight": "400",
                      "line-spacing": "20",
                      fill: "#171E3D"
                    }
                  },
                  [
                    _c("tspan", { attrs: { x: "98", y: "28" } }, [
                      _vm._v("123.45.678.9")
                    ])
                  ]
                )
              ]),
              _c(
                "g",
                { attrs: { transform: "translate(252.000000, 10.000000)" } },
                [
                  _c("rect", {
                    attrs: { x: "0", y: "0", width: "24", height: "24" }
                  }),
                  _c(
                    "g",
                    {
                      attrs: {
                        transform: "translate(3.000000, 2.500000)",
                        stroke: "#003259"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M15.5,3 L15.5,17.25 C15.5,18.2164983 14.7164983,19 13.75,19 L4.25,19 C3.28350169,19 2.5,18.2164983 2.5,17.25 L2.5,3"
                        }
                      }),
                      _c("line", {
                        attrs: { x1: "0", y1: "3", x2: "18", y2: "3" }
                      }),
                      _c("polyline", {
                        attrs: { points: "5.5 2.98 5.5 0 12.5 0 12.5 2.98" }
                      }),
                      _c("line", {
                        attrs: { x1: "6.5", y1: "5.5", x2: "6.5", y2: "16.5" }
                      }),
                      _c("line", {
                        attrs: { x1: "11.5", y1: "5.5", x2: "11.5", y2: "16.5" }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }