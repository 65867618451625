var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c("BaseWindowPage", {
        attrs: { finishStep: true, trackCancel: _vm.track + "::Close" },
        on: {
          evtCancel: function($event) {
            return _vm.confirmAbort()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("v-flex", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("biz.onboarding.success.title")) + " "
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "finish",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "finishstep-bottom-layout",
                    class: [
                      _vm.isSmartModule
                        ? "finishstep-smart-module-img"
                        : "finish-step-appliance-img"
                    ]
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "finishstep-subtitle" },
                      [
                        _c("v-flex", { attrs: { "pl-2": "", "pt-2": "" } }, [
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.$t("biz.onboarding.success.title"))
                            )
                          ])
                        ]),
                        !_vm.licensed
                          ? _c("v-flex", {
                              staticStyle: { "padding-bottom": "100px" },
                              attrs: { "pl-2": "", "pt-2": "", xs10: "" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("biz.onboarding.success.license")
                                )
                              }
                            })
                          : _vm.licensed && !_vm.isTwoZone
                          ? _c("v-flex", {
                              staticStyle: { "padding-bottom": "100px" },
                              attrs: { "pl-2": "", "pt-2": "", xs10: "" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("biz.onboarding.success.alarmdesc")
                                )
                              }
                            })
                          : _vm.licensed &&
                            _vm.isTwoZone &&
                            !_vm.twoZonesOnboarded
                          ? _c("v-flex", {
                              staticStyle: { "padding-bottom": "100px" },
                              attrs: { "pl-2": "", "pt-2": "", xs10: "" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("biz.onboarding.success.alarmdesc2")
                                )
                              }
                            })
                          : _vm.twoZonesOnboarded
                          ? _c("v-flex", {
                              staticStyle: { "padding-bottom": "100px" },
                              attrs: { "pl-2": "", "pt-2": "", xs10: "" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "biz.onboarding.success.bothzonesonboarded"
                                  )
                                )
                              }
                            })
                          : _vm._e(),
                        _vm.isFullAdminUser
                          ? _c(
                              "v-flex",
                              [
                                !_vm.licensed
                                  ? _c(
                                      "div",
                                      { staticStyle: { "margin-left": "8px" } },
                                      [
                                        _c("RedeemVoucher", {
                                          attrs: {
                                            virtualFridgeId:
                                              _vm.savedVirtualFridgeId
                                          },
                                          on: {
                                            evtRedeemVoucher:
                                              _vm.onVoucherRedeem,
                                            evtOnLicenseAssigned:
                                              _vm.onLicenseAssigned
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-btn",
                                      {
                                        directives: [
                                          {
                                            name: "track",
                                            rawName: "v-track",
                                            value:
                                              _vm.track +
                                              "::AlarmConfiguration",
                                            expression:
                                              "track + '::AlarmConfiguration'"
                                          }
                                        ],
                                        staticClass:
                                          "finishstep-alarm-settings",
                                        attrs: {
                                          depressed: "",
                                          color: "primary",
                                          target: "_blank"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.configureAlarm()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.onboarding.success.alarmbutton"
                                            )
                                          )
                                        )
                                      ]
                                    )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.licensed
                          ? _c(
                              "v-flex",
                              { staticStyle: { "padding-top": "5px" } },
                              [
                                !_vm.isTwoZone
                                  ? _c(
                                      "v-btn",
                                      {
                                        directives: [
                                          {
                                            name: "track",
                                            rawName: "v-track",
                                            value:
                                              _vm.track + "::StartOnboarding",
                                            expression:
                                              "track + '::StartOnboarding'"
                                          }
                                        ],
                                        attrs: {
                                          depressed: "",
                                          color: "secondary",
                                          outline: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.startOnboarding()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.onboarding.success.addadditionalappliance"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isSecondZoneOnboardingShown
                          ? _c(
                              "v-flex",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "track",
                                        rawName: "v-track",
                                        value: _vm.track + "::StartOnboarding",
                                        expression:
                                          "track + '::StartOnboarding'"
                                      }
                                    ],
                                    attrs: {
                                      depressed: "",
                                      color: "secondary",
                                      outline: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.startSecondZoneOnboarding()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "biz.onboarding.success.addsecondzone"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }