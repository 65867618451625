// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface CreateGatewayResponse
 */
export interface CreateGatewayResponse {
  /**
   * @type {string}
   * @memberof CreateGatewayResponse
   */
  error?: CreateGatewayResponseErrorEnum;
  /**
   * @type {boolean}
   * @memberof CreateGatewayResponse
   */
  successful?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum CreateGatewayResponseErrorEnum {
  GATEWAYISNOTREACHABLE = 'GATEWAY_IS_NOT_REACHABLE',
  GATEWAYISNOTRECOGNIZED = 'GATEWAY_IS_NOT_RECOGNIZED',
  GATEWAYPASSWORDWRONG = 'GATEWAY_PASSWORD_WRONG',
  CREATEGATEWAYALARMPROFILEERROR = 'CREATE_GATEWAY_ALARM_PROFILE_ERROR',
  REMOVEGATEWAYALARMPROFILEERROR = 'REMOVE_GATEWAY_ALARM_PROFILE_ERROR',
  CONFIGUREGATEWAYERROR = 'CONFIGURE_GATEWAY_ERROR',
  GATEWAYBELONGSTOANOTHERCOMPANY = 'GATEWAY_BELONGS_TO_ANOTHER_COMPANY',
  GATEWAYALREADYREGISTERED = 'GATEWAY_ALREADY_REGISTERED'
}
