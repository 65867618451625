// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GatewayNetworkState, IBotGatewayState } from './';

/**
 * @export
 * @interface ExtendedGatewayState
 */
export interface ExtendedGatewayState {
  /**
   * @type {boolean}
   * @memberof ExtendedGatewayState
   */
  connected?: boolean;
  /**
   * @type {string}
   * @memberof ExtendedGatewayState
   */
  gatewayId?: string;
  /**
   * @type {IBotGatewayState}
   * @memberof ExtendedGatewayState
   */
  ibot?: IBotGatewayState;
  /**
   * @type {Date}
   * @memberof ExtendedGatewayState
   */
  lastActivityTime?: Date;
  /**
   * @type {string}
   * @memberof ExtendedGatewayState
   */
  model?: string;
  /**
   * @type {string}
   * @memberof ExtendedGatewayState
   */
  name?: string;
  /**
   * @type {GatewayNetworkState}
   * @memberof ExtendedGatewayState
   */
  network?: GatewayNetworkState;
  /**
   * @type {string}
   * @memberof ExtendedGatewayState
   */
  schSoftwareUpdateState?: string;
  /**
   * @type {string}
   * @memberof ExtendedGatewayState
   */
  schSoftwareVersion?: string;
  /**
   * @type {string}
   * @memberof ExtendedGatewayState
   */
  serialNumber?: string;
  /**
   * @type {Date}
   * @memberof ExtendedGatewayState
   */
  stateUpdatedTime?: Date;
}
