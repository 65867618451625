// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Appliance } from './';

/**
 * @export
 * @interface ApplianceZone
 */
export interface ApplianceZone {
  /**
   * @type {Appliance}
   * @memberof ApplianceZone
   */
  appliance?: Appliance;
  /**
   * @type {string}
   * @memberof ApplianceZone
   */
  applianceType?: ApplianceZoneApplianceTypeEnum;
  /**
   * @type {string}
   * @memberof ApplianceZone
   */
  applianceZoneType?: ApplianceZoneApplianceZoneTypeEnum;
  /**
   * @type {string}
   * @memberof ApplianceZone
   */
  name?: string;
  /**
   * @type {string}
   * @memberof ApplianceZone
   */
  thingId?: string;
  /**
   * @type {string}
   * @memberof ApplianceZone
   */
  virtualFridgeId?: string;
  /**
   * @type {number}
   * @memberof ApplianceZone
   */
  zoneIndex?: number;
  /**
   * @type {string}
   * @memberof ApplianceZone
   */
  zonePosition?: ApplianceZoneZonePositionEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum ApplianceZoneApplianceTypeEnum {
  ROOM = 'ROOM',
  MODBUSFRIDGE = 'MODBUS_FRIDGE',
  NOTCONNECTABLEFRIDGE = 'NOT_CONNECTABLE_FRIDGE',
  THIRDPARTYFRIDGE = 'THIRD_PARTY_FRIDGE',
  BINDERFRIDGE = 'BINDER_FRIDGE',
  SMARTMODULEFRIDGE = 'SMART_MODULE_FRIDGE'
}
/**
 * @export
 * @enum {string}
 */
export enum ApplianceZoneApplianceZoneTypeEnum {
  COOLER = 'COOLER',
  FREEZER = 'FREEZER'
}
/**
 * @export
 * @enum {string}
 */
export enum ApplianceZoneZonePositionEnum {
  TOP = 'TOP',
  MIDDLE = 'MIDDLE',
  BOTTOM = 'BOTTOM'
}
