var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: _vm.track + "::Close",
          dialogShown: _vm.cancelDialogShown
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("biz.onboarding.smartmodule.setuptitle"))
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("div", { staticClass: "bus_subheading" }, [
                  _vm._v(
                    _vm._s(_vm.$t("biz.onboarding.smartmodule.setuptitle"))
                  )
                ]),
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("biz.onboarding.smartmodule.setupdescription")
                    )
                  }
                }),
                _c(
                  "v-img",
                  {
                    staticClass: "bus_img_center pt-5",
                    attrs: {
                      contain: "",
                      "max-height": "400px",
                      src: require("@/assets/insert_smartmodule@1x.png")
                    }
                  },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          slot: "placeholder",
                          "fill-height": "",
                          "align-center": "",
                          "justify-center": "",
                          "ma-0": ""
                        },
                        slot: "placeholder"
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "grey lighten-5" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }