var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "p",
        { staticStyle: { "margin-bottom": "0px", "font-weight": "bold" } },
        [_vm._v(_vm._s(_vm.$t("biz.report.selectappliance")))]
      ),
      _vm.unLicensedAppliances
        ? _c("v-select", {
            staticClass: "default selected",
            attrs: {
              chips: "",
              items: _vm.appliances,
              disabled: _vm.applianceSelectionDisabled,
              placeholder: _vm.$t("general.appliance"),
              "menu-props": { bottom: true, offsetY: true },
              "item-text": "name",
              "item-value": "value[0]",
              "return-object": "",
              "full-width": ""
            },
            on: {
              change: function($event) {
                return _vm.onApplianceSelection()
              }
            },
            model: {
              value: _vm.selectedAppliance,
              callback: function($$v) {
                _vm.selectedAppliance = $$v
              },
              expression: "selectedAppliance"
            }
          })
        : _vm._e(),
      _vm.unAssignedLicenses
        ? _c("div", [
            _c("div", { staticClass: "bus_normaltext pl-4" }, [
              _vm._v(_vm._s(_vm.$t("biz.licenseassignment.subtitle")))
            ]),
            _c(
              "div",
              { staticClass: "pl-4 pr-4 pt-2 pb-4" },
              [
                _c(
                  "v-layout",
                  {
                    staticStyle: {
                      height: "28vh",
                      overflow: "auto",
                      padding: "1rem"
                    },
                    attrs: { row: "" }
                  },
                  [
                    _c(
                      "v-flex",
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-list",
                              { attrs: { "two-line": "" } },
                              [
                                _vm._l(_vm.unAssignedLicenseItems, function(
                                  license,
                                  index
                                ) {
                                  return [
                                    _c(
                                      "v-list-tile",
                                      {
                                        key: license.entitlementId,
                                        class: _vm.highlightSelected(
                                          license.entitlementId
                                        ),
                                        staticStyle: {
                                          "padding-left": "12px",
                                          "margin-top": "0px"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onLicenseSelection(
                                              license
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getMappedLicenseName(
                                                    license
                                                  )
                                                )
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "100%",
                                                  display: "flex",
                                                  "justify-content":
                                                    "space-between"
                                                }
                                              },
                                              [
                                                license.startDate
                                                  ? _c(
                                                      "v-list-tile-sub-title",
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "general.startdate"
                                                              )
                                                            ) +
                                                            ": " +
                                                            _vm._s(
                                                              _vm.$format.date(
                                                                license.startDate
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                license.endDate
                                                  ? _c(
                                                      "v-list-tile-sub-title",
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "general.enddate"
                                                            )
                                                          ) +
                                                            ": " +
                                                            _vm._s(
                                                              _vm.$format.date(
                                                                license.endDate
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-divider", { key: index })
                                  ]
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }