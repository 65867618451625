var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    {
      staticClass: "scroll-y ml-2 pt-2",
      staticStyle: { "overflow-x": "hidden", height: "85vh" },
      attrs: { "two-line": "" }
    },
    _vm._l(_vm.currentMessages, function(message) {
      return _c(
        "v-list-tile",
        {
          directives: [
            {
              name: "track",
              rawName: "v-track",
              value:
                "Messages::MessagesList::SelectMessage(" +
                message.messageName +
                ")",
              expression:
                "'Messages::MessagesList::SelectMessage(' + message.messageName + ')'"
            }
          ],
          key: message.messageName,
          class: _vm.formatSelectedMessage(message),
          staticStyle: { "margin-top": "1px" },
          on: {
            click: function($event) {
              return _vm.setDisplayedMessage(message)
            }
          }
        },
        [
          _vm.edit
            ? _c(
                "v-list-tile-avatar",
                [
                  _c("v-checkbox", {
                    attrs: {
                      value: message.messageName,
                      multiple: "",
                      "return-object": "",
                      color: "secondary"
                    },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-list-tile-content",
            [
              _c("v-list-tile-title", { class: _vm.getHeaderFont(message) }, [
                _vm._v(_vm._s(message.messageTitle))
              ]),
              _c("v-list-tile-sub-title", [
                _vm._v(_vm._s(_vm.$format.dateTime(message.messageDate)))
              ])
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }