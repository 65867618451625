var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SimpleDialog", {
    attrs: {
      dialogWidth: "700px",
      title: _vm.$t("biz.sensors.sensorpaireddialog.title"),
      dialogShown: _vm.dialogShown,
      track: "SensorPairedDialog"
    },
    on: { evtClose: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("v-flex", {
              staticClass: "bus_normaltext",
              domProps: {
                innerHTML: _vm._s(_vm.$t("biz.sensors.sensorpaireddialog.text"))
              }
            }),
            _c(
              "v-flex",
              [
                _c("v-img", {
                  staticClass: "mt-5",
                  attrs: {
                    src: require("@/assets/reset_sensor.png"),
                    contain: ""
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }