var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              staticStyle: { fill: "var(--v-secondary-base)" },
              attrs: { transform: "translate(-815.000000, -160.000000)" }
            },
            [
              _c(
                "g",
                { attrs: { transform: "translate(815.000000, 160.000000)" } },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M5.84524313,12.831758 C6.56262949,12.831758 7.14418605,13.3925347 7.14418605,14.0842878 L7.14418605,21.2863338 C7.14418605,21.6039456 7.02158814,21.8939463 6.81953484,22.1147349 L6.81945032,22.5388636 C6.81945032,23.0351212 6.42038599,23.4415028 5.91481703,23.4759022 L5.84524313,23.4782609 L1.29894292,23.4782609 C0.784296178,23.4782609 0.362855906,23.0934557 0.327181793,22.6059515 L0.324735729,22.5388636 L0.324651205,22.1147349 C0.122597902,21.8939463 0,21.6039456 0,21.2863338 L0,14.0842878 C0,13.3925347 0.581556553,12.831758 1.29894292,12.831758 L5.84524313,12.831758 Z M24,16.4914934 L24,23.3673598 L9,23.3673598 L9,16.4914934 L24,16.4914934 Z M15.6139535,18.4347826 L11.6139535,18.4347826 L11.6139535,21.4347826 L15.6139535,21.4347826 L15.6139535,18.4347826 Z M22.0883721,18.4347826 L18.0883721,18.4347826 L18.0883721,21.4347826 L22.0883721,21.4347826 L22.0883721,18.4347826 Z M13.6139535,0.52173913 L13.6137835,5.1773661 L15.7770648,7.97106031 C15.9365044,8.17698217 15.8068129,8.46968891 15.5593629,8.50341247 L15.5116279,8.50661626 L10.0465116,8.50661626 C9.78501128,8.50661626 9.63055863,8.22602257 9.75452625,8.01060326 L9.78107476,7.97106031 L11.9440161,5.1769225 L11.944186,0.52173913 L13.6139535,0.52173913 Z"
                    }
                  })
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }