var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: "SmartModuleNetworkActivation::Close",
          dialogShown: _vm.cancelDialogShown
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("biz.onboarding.applianceonboarding.title1"))
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "description",
            fn: function() {
              return [
                _c("v-layout", { attrs: { column: "" } }, [
                  _vm.isWiFi
                    ? _c("div", { staticClass: "bus_subheading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "biz.onboarding.smartmodule.activatewifionboarding"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    : _c("div", { staticClass: "bus_subheading" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "biz.onboarding.smartmodule.activatelanonboarding"
                            )
                          )
                        )
                      ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "v-layout",
                  { attrs: { column: "" } },
                  [
                    _vm.isWiFi
                      ? _c("div", {
                          staticClass: "bus_normaltext",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "biz.onboarding.smartmodule.wifionboardingdesc"
                              )
                            )
                          }
                        })
                      : _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "biz.onboarding.smartmodule.lanonboardingdesc"
                              )
                            )
                          }
                        }),
                    _c(
                      "v-tabs",
                      {
                        staticStyle: { "margin-top": "20px" },
                        attrs: { centered: "" },
                        model: {
                          value: _vm.selectedTab,
                          callback: function($$v) {
                            _vm.selectedTab = $$v
                          },
                          expression: "selectedTab"
                        }
                      },
                      [
                        _c(
                          "v-tab",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value:
                                  "SmartModuleNetworkActivation::ColorDisplay",
                                expression:
                                  "'SmartModuleNetworkActivation::ColorDisplay'"
                              }
                            ],
                            key: "1"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "biz.onboarding.smartmodule.colordisplay"
                                )
                              )
                            )
                          ]
                        ),
                        _c(
                          "v-tab",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value:
                                  "SmartModuleNetworkActivation::Monochrome",
                                expression:
                                  "'SmartModuleNetworkActivation::Monochrome'"
                              }
                            ],
                            key: "2"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "biz.onboarding.smartmodule.monochromedisplay"
                                )
                              )
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.selectedTab,
                          callback: function($$v) {
                            _vm.selectedTab = $$v
                          },
                          expression: "selectedTab"
                        }
                      },
                      [
                        _vm.isWiFi
                          ? _c(
                              "v-tab-item",
                              { key: "1" },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-img",
                                      {
                                        staticClass: "bus_img_center pt-4",
                                        attrs: {
                                          contain: "",
                                          src: require("@/assets/activate_wifi@3x.png")
                                        }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              slot: "placeholder",
                                              "fill-height": "",
                                              "align-center": "",
                                              "justify-center": "",
                                              "ma-0": ""
                                            },
                                            slot: "placeholder"
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              staticStyle: {
                                                color: "var(--v-neutral-base)"
                                              },
                                              attrs: { indeterminate: "" }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c(
                              "v-tab-item",
                              { key: "1" },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-img",
                                      {
                                        staticClass: "bus_img_center pt-4",
                                        attrs: {
                                          contain: "",
                                          src: require("@/assets/activate_lan@3x.png")
                                        }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              slot: "placeholder",
                                              "fill-height": "",
                                              "align-center": "",
                                              "justify-center": "",
                                              "ma-0": ""
                                            },
                                            slot: "placeholder"
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              staticStyle: {
                                                color: "var(--v-neutral-base)"
                                              },
                                              attrs: { indeterminate: "" }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                        _vm.isWiFi
                          ? _c(
                              "v-tab-item",
                              { key: "2" },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-img",
                                      {
                                        staticClass: "bus_img_center pt-4",
                                        attrs: {
                                          contain: "",
                                          src: require("@/assets/activate_wifi_advanced@3x.png")
                                        }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              slot: "placeholder",
                                              "fill-height": "",
                                              "align-center": "",
                                              "justify-center": "",
                                              "ma-0": ""
                                            },
                                            slot: "placeholder"
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              staticStyle: {
                                                color: "var(--v-neutral-base)"
                                              },
                                              attrs: { indeterminate: "" }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c(
                              "v-tab-item",
                              { key: "2" },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { flat: "" } },
                                  [
                                    _c(
                                      "v-img",
                                      {
                                        staticClass: "bus_img_center pt-4",
                                        attrs: {
                                          contain: "",
                                          src: require("@/assets/activate_lan_advanced@3x.png")
                                        }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              slot: "placeholder",
                                              "fill-height": "",
                                              "align-center": "",
                                              "justify-center": "",
                                              "ma-0": ""
                                            },
                                            slot: "placeholder"
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              staticStyle: {
                                                color: "var(--v-neutral-base)"
                                              },
                                              attrs: { indeterminate: "" }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }