// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SmartModuleSystemSettingsRequest
 */
export interface SmartModuleSystemSettingsRequest {
  /**
   * @type {boolean}
   * @memberof SmartModuleSystemSettingsRequest
   */
  enabled?: boolean;
  /**
   * @type {number}
   * @memberof SmartModuleSystemSettingsRequest
   */
  offlineAlarmDelayInSeconds?: number;
  /**
   * @type {boolean}
   * @memberof SmartModuleSystemSettingsRequest
   */
  offlineAlarmEnabled?: boolean;
  /**
   * @type {string}
   * @memberof SmartModuleSystemSettingsRequest
   */
  virtualFridgeId?: string;
}
