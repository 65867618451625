var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "", "grid-list-lg": "", "pa-0": "" } },
    [
      _vm.dataIsMissing
        ? _c(
            "v-flex",
            [
              _c("DataMissingDisplay", {
                attrs: { interestedIn: _vm.pageRouteHelper.SCH },
                on: {
                  evtBsdbData: function($event) {
                    _vm.dataIsMissing = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.dataIsMissing
        ? _c(
            "v-layout",
            { attrs: { column: "", "align-start": "" } },
            [
              _vm.unlicensedAppliances.length > 0
                ? _c(
                    "v-flex",
                    [
                      _c(
                        "v-card",
                        { staticClass: "justify-start ma-2" },
                        [
                          _c("BsdbStructure", {
                            attrs: {
                              title: _vm.$t("biz.groups.unlicensed.title"),
                              appliances: _vm.unlicensedAppliances
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.gateways, function(gateway) {
                return _c(
                  "v-flex",
                  { key: gateway.gatewayId },
                  [
                    _c(
                      "v-card",
                      { staticClass: "justify-start ma-2" },
                      [
                        _c(
                          "v-layout",
                          { attrs: { row: "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "d-flex align-center",
                                attrs: {
                                  "ml-3": "",
                                  "mb-2": "",
                                  "mt-2": "",
                                  shrink: ""
                                }
                              },
                              [
                                _c("GatewayItem", {
                                  attrs: { gateway: gateway }
                                })
                              ],
                              1
                            ),
                            _c("v-flex", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.hasStructure(gateway),
                                  expression: "hasStructure(gateway)"
                                }
                              ],
                              staticStyle: {
                                "border-right":
                                  "1px solid var(--v-neutral20-base)"
                              },
                              attrs: { shrink: "", "pl-1": "" }
                            }),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "ma-2": "",
                                  "pl-1": ""
                                }
                              },
                              _vm._l(_vm.hubWithLicensedAppliances, function(
                                item
                              ) {
                                return _c(
                                  "div",
                                  { key: item.virtualFridgeId },
                                  [
                                    _c("ExtendableItem", {
                                      attrs: {
                                        measureSite: item,
                                        hasSensors: _vm.hasCompanySensors
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm.appliancesWithoutHub && _vm.appliancesWithoutHub.length > 0
                ? _c(
                    "v-flex",
                    [
                      _c(
                        "v-card",
                        { staticClass: "justify-start ma-2" },
                        [
                          _c("BsdbStructure", {
                            attrs: {
                              title: _vm.$t("biz.group.applianceswithouthub"),
                              appliances: _vm.appliancesWithoutHub
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }