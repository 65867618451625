var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isNotFullyOnBoarded
    ? _c(
        "div",
        [
          _c("CardTileLayout", {
            attrs: { licensed: false },
            scopedSlots: _vm._u(
              [
                {
                  key: "main",
                  fn: function() {
                    return [
                      _c("v-layout", { attrs: { row: "" } }, [
                        _c(
                          "span",
                          [
                            _c("MainTileLayout", {
                              attrs: { setupIncomplete: true },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "type",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "tile",
                                            attrs: {
                                              "align-center": "",
                                              "justify-center": ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.IconHelper.getNotFullyOnBoardedIcon()
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "info",
                                    fn: function() {
                                      return [
                                        _c("InfoTile", {
                                          attrs: {
                                            title: _vm.thingTitle,
                                            alertText: _vm.$t(
                                              "general.setupincomplete"
                                            )
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "alerticon",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "customdeleteicon",
                                            on: {
                                              click: function($event) {
                                                _vm.showDeleteConfirmationDialog = true
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.IconHelper.getDeleteOutlineIcon()
                                                )
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3972347563
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "setup",
                  fn: function() {
                    return [
                      _c(
                        "v-card",
                        {
                          staticStyle: { height: "42px" },
                          attrs: { flat: "" },
                          on: { click: _vm.continueSetup }
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "setuporactivelicense" },
                            [_vm._v(_vm._s(_vm.$t("general.continuesetup")))]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3373353785
            )
          }),
          _c("OkCancelDialog", {
            attrs: {
              dialogShown: _vm.showDeleteConfirmationDialog,
              title: _vm.$t("biz.onboarding.smartmodule.deletesmartmodule"),
              message: _vm.$t(
                "biz.onboarding.smartmodule.deletesmartmoduledesc"
              ),
              track: "SmartModuleItem::AskDelete"
            },
            on: {
              evtOkPressed: function($event) {
                return _vm.deleteSmartModule()
              },
              evtCancelPressed: function($event) {
                _vm.showDeleteConfirmationDialog = false
              }
            }
          }),
          _c("OkDialog", {
            attrs: {
              dialogShown: _vm.showDeletedDialog,
              title: _vm.$t("biz.applianceinfo.deletedtitle"),
              message: _vm.$t("biz.applianceinfo.deletedmessage"),
              track: "SmartModuleItem::Deleted"
            },
            on: {
              evtOkPressed: function($event) {
                _vm.showDeletedDialog = false
              }
            }
          }),
          _c("ErrorWithHelpDialog", {
            attrs: {
              dialogShown: _vm.showDeleteError,
              title: _vm.$t("biz.onboarding.error.deletetitle"),
              message: _vm.$t("biz.onboarding.error.deletemessage"),
              track: "SmartModuleItem::DeleteErrorAppliance"
            },
            on: {
              evtCancelPressed: function($event) {
                _vm.showDeleteError = false
              },
              evtRetryPressed: function($event) {
                return _vm.deleteSmartModule()
              }
            }
          })
        ],
        1
      )
    : _vm.licenseState !== _vm.LicenseState.LICENSED
    ? _c(
        "div",
        [
          _c("CardTileLayout", {
            attrs: { licensed: false },
            scopedSlots: _vm._u([
              {
                key: "main",
                fn: function() {
                  return [
                    _c("v-layout", { attrs: { row: "" } }, [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toAppliance()
                            }
                          }
                        },
                        [
                          _c("MainTileLayout", {
                            attrs: { licensed: false, hasSensors: true },
                            scopedSlots: _vm._u([
                              {
                                key: "type",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "tile",
                                        attrs: {
                                          "align-center": "",
                                          "justify-center": ""
                                        }
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.IconHelper.getUnlicensedIcon()
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "info",
                                fn: function() {
                                  return [
                                    _vm.licenseState === _vm.LicenseState.LOCKED
                                      ? _c("InfoTile", {
                                          attrs: {
                                            title: _vm.name,
                                            alertText: _vm.$t(
                                              "biz.groups.locked.alert"
                                            ),
                                            sensorText: _vm.sensorText
                                          }
                                        })
                                      : _c("InfoTile", {
                                          attrs: {
                                            title: _vm.name,
                                            alertText: _vm.$t(
                                              "biz.groups.unlicensed.alert"
                                            ),
                                            sensorText: _vm.sensorText
                                          }
                                        })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "setup",
                fn: function() {
                  return [
                    _vm.licenseState !== _vm.LicenseState.LOCKED
                      ? _c(
                          "v-card",
                          {
                            staticStyle: { height: "42px" },
                            attrs: { flat: "" },
                            on: { click: _vm.activateLicense }
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "setuporactivelicense" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("biz.vouchers.activatelicense"))
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    : _c(
        "div",
        [
          _c("CardTileLayout", {
            class: _vm.smartModuleTileClass,
            attrs: {
              licensed: true,
              twoZone: false,
              hasSensors: _vm.hasSensors,
              measureSite: _vm.measureSite,
              isSmartModuleTile: "true"
            },
            scopedSlots: _vm._u([
              {
                key: "main",
                fn: function() {
                  return [
                    _c(
                      "v-layout",
                      {
                        attrs: { row: "" },
                        on: {
                          click: function($event) {
                            return _vm.toAppliance()
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: { "margin-top": "8px", width: "100%" }
                          },
                          [
                            _c("MainTileLayout", {
                              attrs: {
                                licensed: true,
                                hasSensors: false,
                                showType: false
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "info",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "align-center": "",
                                            "justify-center": ""
                                          }
                                        },
                                        [
                                          _c("InfoTile", {
                                            attrs: {
                                              title: _vm.name,
                                              alertText:
                                                _vm.smartModuleAlertText,
                                              showType: false
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "alerticon",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-right": "90px"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.smartModuleAlarmIcon)
                                            )
                                          ]),
                                          _c(
                                            "span",
                                            { staticClass: "alertcount" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.applianceLevelAlarmsCount
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "type",
                                  fn: function() {
                                    return _vm._l(_vm.applianceZones, function(
                                      zone,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticStyle: {
                                                "padding-top": "10px",
                                                "padding-right": "4px"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        "align-center": "",
                                                        "justify-center": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          class: _vm.getSmartModuleZoneClass(
                                                            zone
                                                          )
                                                        },
                                                        [
                                                          _vm.hasZoneAlarm(zone)
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "zone-error-icon"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.getZoneAlarmIcon(
                                                                          zone
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "5px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getSmartModuleApplianceState(
                                                                    zone
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  },
                                  proxy: true
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "sensors",
                fn: function() {
                  return [
                    _vm.hasSensors
                      ? _c(
                          "div",
                          [
                            _c("SensorTileList", {
                              staticStyle: { margin: "10px 6px 0px 0px" },
                              attrs: { sensors: _vm.measureSiteSensors }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }