var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { attrs: { "primary-title": "" } }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("general.menus.settings.reminder.header")))
            ])
          ]),
          _c("v-card-text", { staticClass: "bus_normaltext" }, [
            _vm.admin
              ? _c("p", {
                  staticStyle: { margin: "0px" },
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("general.menus.settings.reminder.mobiletextadmin")
                    )
                  }
                })
              : _c("p", {
                  staticStyle: { margin: "0px" },
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("general.menus.settings.reminder.mobiletext")
                    )
                  }
                })
          ]),
          _c(
            "v-card-actions",
            { staticStyle: { padding: "16px !important" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "track",
                      rawName: "v-track",
                      value: _vm.track + "::Close",
                      expression: "track + '::Close'"
                    }
                  ],
                  attrs: { depressed: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("general.ok")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }