var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseWindowPage", {
        attrs: {
          trackCancel: _vm.track + "::Close",
          dialogShown: _vm.cancelDialogShown,
          readyFlag: _vm.scanCompleted
        },
        on: {
          evtCancel: function($event) {
            return _vm.abortProcess()
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.scanCompleted
              ? {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v(
                        _vm._s(
                          _vm.$t("biz.onboarding.connectappliance.title")
                        ) +
                          " " +
                          _vm._s(_vm.selectedGatewayName)
                      )
                    ]
                  },
                  proxy: true
                }
              : null,
            _vm.scanCompleted
              ? {
                  key: "subtitle",
                  fn: function() {
                    return [
                      _vm.newAppliances.length > 0
                        ? _c("div", {
                            staticClass: "font-weight-black",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "biz.onboarding.connectappliance.description"
                                )
                              )
                            }
                          })
                        : _c("div", {
                            staticClass: "font-weight-black",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "biz.onboarding.connectappliance.description1"
                                )
                              )
                            }
                          })
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "content",
              fn: function() {
                return [
                  _vm.scanRunning
                    ? _c(
                        "v-container",
                        {
                          staticStyle: { height: "38vh" },
                          attrs: {
                            "fill-height": "",
                            fluid: "",
                            "justify-center": ""
                          }
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              color: "secondary",
                              indeterminate: "",
                              size: "36"
                            }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "ml-3",
                              staticStyle: { color: "primary" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "biz.onboarding.connectappliance.searchmodbus"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.scanCompleted && !_vm.gatewayConfig
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "ma-3" },
                          [
                            _c("h2", { staticClass: "mb-3" }, [
                              _vm._v(
                                _vm._s(_vm.$t("biz.onboarding.scan.scanfailed"))
                              )
                            ]),
                            _c("v-flex", {
                              staticClass: "bus_normaltext",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("biz.onboarding.scan.scanerror")
                                )
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.scanCompleted && _vm.gatewayConfig
                    ? _c(
                        "v-list",
                        { attrs: { "three-line": "" } },
                        [
                          _vm._l(_vm.newAppliances, function(item) {
                            return [
                              _c(
                                "v-list-tile",
                                {
                                  key: item.modbusAddress,
                                  class: _vm.highlightSelected(
                                    item.modbusAddress
                                  ),
                                  staticStyle: {
                                    "margin-top": "1px",
                                    "padding-left": "12px"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectModbus(
                                        item.modbusAddress
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "biz.onboarding.scan.table.address"
                                            )
                                          )
                                        )
                                      ]),
                                      _c("v-list-tile-sub-title", [
                                        _vm._v(_vm._s(item.modbusAddress))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("CheckFirmwareDialog", {
                    attrs: {
                      hubCheckRunning: _vm.hubCheckRunning,
                      showSchUpdateNeeded: _vm.showSchUpdateNeeded,
                      showSchOffline: _vm.showSchOffline
                    }
                  }),
                  _c("ErrorWithHelpDialog", {
                    attrs: {
                      dialogShown: _vm.showScanError,
                      title: _vm.$t("biz.onboarding.error.scantitle"),
                      message: _vm.$t("biz.onboarding.error.scanmessage"),
                      track: "FreeModbusStep::StartScanError"
                    },
                    on: {
                      evtCancelPressed: function($event) {
                        return _vm.onErrorOkay()
                      },
                      evtRetryPressed: function($event) {
                        return _vm.retryScan()
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }