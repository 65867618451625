var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800px", persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { column: "", "align-center": "" } },
                [
                  _c("v-flex", [
                    _c("h3", [
                      _vm._v(
                        _vm._s(_vm.$t("general.home.supportedbrowsers.title"))
                      )
                    ]),
                    _c("p", { staticClass: "bus_normaltext mt-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("general.home.supportedbrowsers.description")
                        )
                      )
                    ])
                  ]),
                  _c(
                    "v-layout",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { "mt-5": "", row: "", wrap: "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs3: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "", "align-center": "" } },
                            [
                              _c("v-img", {
                                staticClass: "browser-img",
                                attrs: {
                                  src: require("@/assets/chrome.png"),
                                  contain: ""
                                }
                              }),
                              _c("p", { staticClass: "browser-name" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "general.home.supportedbrowsers.chrome"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value:
                                        "SupportBrowserDialog::DownloadChrome",
                                      expression:
                                        "'SupportBrowserDialog::DownloadChrome'"
                                    }
                                  ],
                                  attrs: {
                                    target: "_blank",
                                    href: "https://www.google.com/chrome/",
                                    depressed: "",
                                    outline: "",
                                    color: "primary"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("general.download")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs3: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "", "align-center": "" } },
                            [
                              _c("v-img", {
                                staticClass: "browser-img",
                                attrs: {
                                  src: require("@/assets/firefox.png"),
                                  contain: ""
                                }
                              }),
                              _c("p", { staticClass: "browser-name" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "general.home.supportedbrowsers.firefox"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value:
                                        "SupportBrowserDialog::DownloadMozilla",
                                      expression:
                                        "'SupportBrowserDialog::DownloadMozilla'"
                                    }
                                  ],
                                  attrs: {
                                    target: "_blank",
                                    href: "https://www.mozilla.org",
                                    depressed: "",
                                    outline: "",
                                    color: "primary"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("general.download")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs3: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "", "align-center": "" } },
                            [
                              _c("v-img", {
                                staticClass: "browser-img",
                                attrs: {
                                  src: require("@/assets/edge.png"),
                                  contain: ""
                                }
                              }),
                              _c("p", { staticClass: "browser-name" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "general.home.supportedbrowsers.edge"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value:
                                        "SupportBrowserDialog::DownloadMSEdge",
                                      expression:
                                        "'SupportBrowserDialog::DownloadMSEdge'"
                                    }
                                  ],
                                  attrs: {
                                    target: "_blank",
                                    href:
                                      "https://www.microsoft.com/windows/microsoft-edge",
                                    depressed: "",
                                    outline: "",
                                    color: "primary"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("general.download")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs3: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "", "align-center": "" } },
                            [
                              _c("v-img", {
                                staticClass: "browser-img",
                                attrs: {
                                  src: require("@/assets/safari.png"),
                                  contain: ""
                                }
                              }),
                              _c("p", { staticClass: "browser-name" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "general.home.supportedbrowsers.safari"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "track",
                                      rawName: "v-track",
                                      value:
                                        "SupportBrowserDialog::DownloadSafari",
                                      expression:
                                        "'SupportBrowserDialog::DownloadSafari'"
                                    }
                                  ],
                                  attrs: {
                                    target: "_blank",
                                    href: "https://www.apple.com/safari/",
                                    depressed: "",
                                    outline: "",
                                    color: "primary"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("general.download")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }