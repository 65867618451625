var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { "mt-5": "", column: "", "align-center": "" } },
        [
          _c("v-icon", { attrs: { size: "80px" } }, [
            _vm._v(_vm._s(_vm.getAssignApplianceIcon()))
          ]),
          _c(
            "v-flex",
            { staticClass: "title text-md-center", attrs: { "mt-5": "" } },
            [_vm._v(_vm._s(_vm.$t("biz.groupeditor.assignappliance")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }