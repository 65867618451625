var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.dialogWidth, persistent: "" },
      model: {
        value: _vm.dialogShown,
        callback: function($$v) {
          _vm.dialogShown = $$v
        },
        expression: "dialogShown"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-layout",
            { attrs: { row: "", nowrap: "", "pl-4": "" } },
            [
              _c("v-flex", { staticClass: "pt-3", attrs: { grow: "" } }, [
                _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
              ]),
              _c("Cancel", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.buttonExit,
                    expression: "buttonExit"
                  }
                ],
                staticClass: "mr-1",
                attrs: { track: _vm.track },
                on: {
                  evtCancel: function($event) {
                    return _vm.onClosePressed()
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-flex",
            { staticStyle: { "max-height": "90vh", "overflow-y": "auto" } },
            [
              _c(
                "v-layout",
                { staticClass: "bus_normaltext pl-4 mt-3 mb-2 pr-4" },
                [
                  _c("div", {
                    staticClass: "message bus_normaltext",
                    domProps: { innerHTML: _vm._s(_vm.message) }
                  })
                ]
              ),
              _c(
                "v-layout",
                { staticClass: "pl-4 mt-2 mr-4" },
                [_vm._t("additionalcontent")],
                2
              ),
              _c(
                "v-layout",
                { staticClass: "pl-4 pt-3 pr-4", attrs: { column: "" } },
                [_vm._t("content")],
                2
              ),
              _c("v-card-actions", [_vm._t("buttons")], 2)
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }