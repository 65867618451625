var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SimpleDialog", {
        attrs: {
          dialogWidth: "600px",
          title: _vm.$t("general.home.profile.session"),
          message: _vm.$tc(
            "biz.onboarding.start.automaticlogout",
            _vm.expirationToken
          ),
          dialogShown: _vm.dialogShown,
          track: "SessionRenewDialog::Close"
        },
        on: {
          evtClose: function($event) {
            return _vm.close()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function() {
              return [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: "SessionRenewDialog::Close",
                        expression: "'SessionRenewDialog::Close'"
                      }
                    ],
                    attrs: { color: "secondary", depressed: "", outline: "" },
                    on: {
                      click: function($event) {
                        return _vm.close()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("general.ok")))]
                ),
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "track",
                        rawName: "v-track",
                        value: "SessionRenewDialog::ShowUserMenu",
                        expression: "'SessionRenewDialog::ShowUserMenu'"
                      }
                    ],
                    attrs: { color: "primary", depressed: "" },
                    on: {
                      click: function($event) {
                        return _vm.showUserMenu()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("general.configure")))]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("UserMenu", {
        attrs: { menuShown: _vm.userMenuShown },
        on: {
          evtClose: function($event) {
            _vm.userMenuShown = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }