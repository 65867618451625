// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired } from '../runtime';
import { ErrorDto, KafkaParams } from '../models';

export interface SendKafkaMessageUsingPOSTRequest {
  kafkaParams: KafkaParams;
}

/**
 * no description
 */
export class TestControllerApi extends BaseAPI {
  /**
   * downloadApi
   * Download a list of used microservice calls
   */
  downloadApiUsingGET = (): Observable<Blob> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Blob>({
      path: '/public/api/download',
      method: 'GET',
      headers,
      responseType: 'blob'
    });
  };

  /**
   * sendKafkaMessage
   * Sends a Kafka message
   */
  sendKafkaMessageUsingPOST = (requestParameters: SendKafkaMessageUsingPOSTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'kafkaParams', 'sendKafkaMessageUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/test/kafka/send',
      method: 'POST',
      headers,
      body: requestParameters.kafkaParams
    });
  };
}
