// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HistorySensorAlarm } from './';

/**
 * @export
 * @interface DoorSensorAlarmPoint
 */
export interface DoorSensorAlarmPoint {
  /**
   * @type {Date}
   * @memberof DoorSensorAlarmPoint
   */
  x?: Date;
  /**
   * @type {Array<HistorySensorAlarm>}
   * @memberof DoorSensorAlarmPoint
   */
  y?: Array<HistorySensorAlarm>;
}
