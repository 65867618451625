var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseWindowPage", {
    attrs: { dialogShown: _vm.cancelDialogShown },
    on: {
      evtCancel: function($event) {
        return _vm.abortProcess()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _vm._v(_vm._s(_vm.$t("biz.smartmoduleinfo.properties.title")))
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("h3", { staticStyle: { color: "black" } }, [
              _vm._v(_vm._s(_vm.$t("biz.smartmoduleinfo.properties.subtitle1")))
            ]),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("biz.smartmoduleinfo.properties.description1")
                  ) +
                  "\n    "
              )
            ]),
            _c(
              "p",
              {
                staticStyle: { "margin-bottom": "0px", "font-weight": "bold" }
              },
              [_vm._v(_vm._s(_vm.$t("biz.applianceinfo.name")))]
            ),
            _c("v-text-field", {
              staticClass: "default selected errorborder",
              attrs: {
                clearable: _vm.iconHelper.clearableIcon(_vm.name),
                type: "string"
              },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            }),
            _c("br"),
            _c("h3", { staticStyle: { color: "black" } }, [
              _vm._v(_vm._s(_vm.$t("biz.smartmoduleinfo.properties.subtitle2")))
            ]),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("biz.smartmoduleinfo.properties.description2")
                  ) +
                  "\n    "
              )
            ]),
            _c(
              "p",
              {
                staticStyle: { "margin-bottom": "0px", "font-weight": "bold" }
              },
              [_vm._v(_vm._s(_vm.$t("general.timezone.title")))]
            ),
            _c("v-autocomplete", {
              staticClass: "default selected",
              staticStyle: { "font-size": "14px" },
              attrs: {
                chips: "",
                "return-object": "",
                items: _vm.timezones,
                "item-text": "display",
                "full-width": "",
                "menu-props": { bottom: true, offsetY: true }
              },
              model: {
                value: _vm.timezone,
                callback: function($$v) {
                  _vm.timezone = $$v
                },
                expression: "timezone"
              }
            }),
            _c(
              "p",
              {
                staticStyle: { "margin-bottom": "0px", "font-weight": "bold" }
              },
              [_vm._v(_vm._s(_vm.$t("general.comment")))]
            ),
            _c("v-textarea", {
              staticClass: "defaulttextarea selectedtextarea",
              attrs: {
                clearable: _vm.iconHelper.clearableIcon(_vm.comment),
                type: "string"
              },
              model: {
                value: _vm.comment,
                callback: function($$v) {
                  _vm.comment = $$v
                },
                expression: "comment"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }