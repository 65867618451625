var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseWindowPage", {
    attrs: { dialogShown: _vm.cancelDialogShown },
    on: {
      evtCancel: function($event) {
        return _vm.abortProcess()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(_vm._s(_vm.$t("biz.onboarding.stepper.groupheader")))]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("SimpleGroupEditor", {
              on: {
                evtGroupSelected: _vm.groupSelected,
                evtGroupCreating: function($event) {
                  _vm.groupCreating = true
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }