var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "measuresitebanner", class: _vm.color },
    [
      _c("v-icon", { staticClass: "icon", style: _vm.iconStyle }, [
        _vm._v(_vm._s(_vm.icon))
      ]),
      _c("p", { staticClass: "header" }, [
        _vm._v(_vm._s(_vm.getApplianceName(_vm.applianceZone)))
      ]),
      _vm.licensed
        ? _c(
            "v-layout",
            { staticClass: "text", attrs: { column: "" } },
            [
              _c("div", [
                _c("p", { staticClass: "alarmtext" }, [
                  _vm._v(_vm._s(_vm.alarmText(_vm.applianceZone)))
                ])
              ]),
              _c(
                "v-layout",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end",
                    "margin-top": "4px"
                  }
                },
                [
                  _vm.isSmartCoolingHubAppliance
                    ? _c("p", { staticClass: "state" }, [
                        _vm._v("\n        " + _vm._s(_vm.state) + "\n      ")
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.TypeChecker.isApplianceWithTemperature(_vm.applianceZone) &&
      _vm.licensed
        ? _c("p", { staticClass: "update" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("general.actualization")) +
                ": " +
                _vm._s(_vm.$format.dateTimeShort(_vm.since)) +
                "\n  "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }