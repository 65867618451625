var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticStyle: { width: "100%" },
      attrs: { "fill-height": "", column: "" }
    },
    [
      !_vm.dataReady
        ? _c(
            "v-layout",
            {
              staticStyle: { height: "65vh" },
              attrs: { column: "", "align-center": "", "justify-center": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary", size: "48" }
              }),
              _c("p", { staticClass: "bus_normaltext mt-5" }, [
                _vm._v(_vm._s(_vm.$t("general.actions.loading")))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.dataReady && _vm.selectedProfile
        ? _c(
            "v-layout",
            {
              attrs: {
                "align-start": "",
                column: "",
                "pt-2": "",
                "pl-4": "",
                "pr-2": "",
                "pb-3": ""
              }
            },
            [
              _c(
                "v-flex",
                { staticStyle: { width: "100%" }, attrs: { xs12: "" } },
                [
                  _vm.isCloudProfileSelected()
                    ? _c("CloudAlarmDetails", {
                        ref: "cloudDetails",
                        attrs: {
                          virtualFridgeId: _vm.virtualFridgeId,
                          selectedFridgeType: _vm.selectedFridgeType,
                          gatewayId: _vm.selectedGatewayId,
                          track: _vm.track + "::CloudAlarmDetails"
                        },
                        on: {
                          evtAlarmProfileCreated: function($event) {
                            return _vm.loadAlarmProfiles()
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.isSmartModuleProfileSelected()
                    ? _c("SmartModuleAlarmDetails", {
                        attrs: {
                          virtualFridgeId: _vm.selectedProfile.virtualFridgeId,
                          selectedFridgeType: _vm.selectedFridgeType,
                          track: _vm.track + "::SmartModuleAlarmDetails"
                        },
                        on: {
                          evtSmartModuleProfileUpdated:
                            _vm.refreshSmartModuleProfile
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticStyle: { width: "100%" },
                  attrs: { "pa-0": "", xs12: "", "ml-0": "" }
                },
                [
                  _c("MeasureSiteAlarmProfile", {
                    attrs: {
                      virtualFridgeId: _vm.virtualFridgeId,
                      hasAlarmProfile: true,
                      extraPadding: false
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }