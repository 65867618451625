// tslint:disable
/**
 * SmartMonitoring Dashboard API
 * The backend service used by the UI2.0 of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OnboardingInfo } from './';

/**
 * @export
 * @interface SmartModuleResponse
 */
export interface SmartModuleResponse {
  /**
   * @type {string}
   * @memberof SmartModuleResponse
   */
  applianceSerialNumber?: string;
  /**
   * @type {string}
   * @memberof SmartModuleResponse
   */
  name?: string;
  /**
   * @type {OnboardingInfo}
   * @memberof SmartModuleResponse
   */
  onboardingInfo?: OnboardingInfo;
  /**
   * @type {string}
   * @memberof SmartModuleResponse
   */
  onboardingState?: SmartModuleResponseOnboardingStateEnum;
  /**
   * @type {Date}
   * @memberof SmartModuleResponse
   */
  timestamp?: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum SmartModuleResponseOnboardingStateEnum {
  VOID = 'VOID',
  AWAITONBOARDING = 'AWAIT_ONBOARDING',
  ONBOARDINGSTARTED = 'ONBOARDING_STARTED',
  ONBOARDED = 'ONBOARDED'
}
