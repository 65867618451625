var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "90px",
        height: "96px",
        viewBox: "0 0 60 64",
        version: "1.1"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd"
          }
        },
        [
          _c(
            "g",
            {
              staticStyle: { fill: "var(--v-neutral40-base)" },
              attrs: { transform: "translate(-1111.000000, -214.000000)" }
            },
            [
              _c(
                "g",
                { attrs: { transform: "translate(1077.000000, 183.000000)" } },
                [
                  _c(
                    "g",
                    { attrs: { transform: "translate(24.000000, 24.000000)" } },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M39.7008214,7 C51.4180804,7 60.9167957,16.4987153 60.9167957,28.2159743 C60.9167957,32.4944844 59.6503169,36.4771926 57.471798,39.80966 L69.846152,61.2435311 L58.9409102,60.5402191 L54.0973754,70.3360915 L41.9595668,49.3131289 C41.2173988,49.391672 40.4638137,49.4319486 39.7008214,49.4319486 C39.1305814,49.4319486 38.565596,49.4094515 38.0067042,49.3652962 L25.8987766,70.3360915 L21.0552418,60.5402191 L10.15,61.2435311 L22.2495591,40.2846305 C19.8757254,36.8585507 18.4848471,32.699691 18.4848471,28.2159743 C18.4848471,16.4987153 27.9835623,7 39.7008214,7 Z M39.7008214,13.0617069 C31.3313506,13.0617069 24.546554,19.8465036 24.546554,28.2159743 C24.546554,36.5854451 31.3313506,43.3702417 39.7008214,43.3702417 C48.0702921,43.3702417 54.8550887,36.5854451 54.8550887,28.2159743 C54.8550887,19.8465036 48.0702921,13.0617069 39.7008214,13.0617069 Z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }