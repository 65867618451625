var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseWindowPage", {
    attrs: { trackCancel: _vm.track + "::Close" },
    on: {
      evtCancel: function($event) {
        _vm.cancelDialogShown = true
      }
    },
    scopedSlots: _vm._u(
      [
        _vm.readyFlag
          ? {
              key: "title",
              fn: function() {
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("biz.sensors.setup.properties.title")))
                  ])
                ]
              },
              proxy: true
            }
          : {
              key: "title",
              fn: function() {
                return [
                  _vm.assignmentInProgress
                    ? _c("span")
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("biz.sensors.setup.properties.loadingtitle")
                          )
                        )
                      ])
                ]
              },
              proxy: true
            },
        {
          key: "subtitle",
          fn: function() {
            return [
              _vm.readyFlag
                ? _c("div", {
                    staticClass: "font-weight-black",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("biz.sensors.setup.properties.subtitle")
                      )
                    }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "content",
          fn: function() {
            return [
              _c(
                "v-container",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.readyFlag,
                      expression: "!readyFlag"
                    }
                  ],
                  staticStyle: { "min-height": "300px" },
                  attrs: { "fill-height": "", fluid: "", "justify-center": "" }
                },
                [
                  _c("v-progress-circular", {
                    attrs: { color: "secondary", indeterminate: "", size: "36" }
                  }),
                  _vm.assignmentInProgress
                    ? _c(
                        "span",
                        {
                          staticClass: "ml-3",
                          staticStyle: { color: "primary" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "biz.sensors.setup.properties.assignsensor"
                              )
                            )
                          )
                        ]
                      )
                    : _c(
                        "span",
                        {
                          staticClass: "ml-3",
                          staticStyle: { color: "primary" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "biz.sensors.setup.properties.savesamplingrate"
                              )
                            )
                          )
                        ]
                      )
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.readyFlag,
                      expression: "readyFlag"
                    }
                  ],
                  staticClass: "mt-1",
                  staticStyle: { "min-height": "300px" }
                },
                [
                  _c("v-flex", { staticClass: "bus_normaltext pt-2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("biz.sensors.setup.properties.description"))
                    )
                  ]),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "", "pt-3": "" } },
                        [
                          _c(
                            "v-flex",
                            [
                              _c("div", { staticClass: "bus_required_field" }, [
                                _vm._v(
                                  "* " + _vm._s(_vm.$t("general.required"))
                                )
                              ]),
                              _c("v-text-field", {
                                staticClass: "default selected errorborder",
                                attrs: {
                                  clearable: _vm.iconHelper.clearableIcon(
                                    _vm.sensorName
                                  ),
                                  rules: _vm.sensorNameRules,
                                  label: _vm.$t(
                                    "biz.sensors.setup.properties.name"
                                  ),
                                  type: "string",
                                  required: ""
                                },
                                model: {
                                  value: _vm.sensorName,
                                  callback: function($$v) {
                                    _vm.sensorName = $$v
                                  },
                                  expression: "sensorName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            [
                              _c("v-textarea", {
                                staticClass: "defaulttextarea selectedtextarea",
                                staticStyle: { "font-size": "14px" },
                                attrs: {
                                  label: _vm.$t("general.comment"),
                                  clearable: _vm.iconHelper.clearableIcon(
                                    _vm.sensorComment
                                  )
                                },
                                model: {
                                  value: _vm.sensorComment,
                                  callback: function($$v) {
                                    _vm.sensorComment = $$v
                                  },
                                  expression: "sensorComment"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "mt-1" },
                        [
                          _c("div", { staticClass: "font-weight-black mb-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("biz.sensors.setup.properties.assign")
                              )
                            )
                          ]),
                          _c(
                            "v-flex",
                            { staticClass: "bus_normaltext pt-2 pb-3" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "biz.sensors.setup.properties.measuresitedescription"
                                  )
                                )
                              )
                            ]
                          ),
                          _c("v-select", {
                            staticClass: "default selected",
                            attrs: {
                              chips: "",
                              items: _vm.measureSiteData,
                              "item-text": "name",
                              "item-disabled": "disableSensorAdd",
                              "menu-props": { bottom: true, offsetY: true },
                              "return-object": "",
                              "full-width": "",
                              placeholder: _vm.$t(
                                "biz.sensors.setup.properties.selectmeasuresite"
                              )
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", {
                                          domProps: {
                                            innerHTML: _vm._s(data.item.name)
                                          }
                                        }),
                                        _c("v-list-tile-sub-title", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              data.item.sensorText
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.selectedMeasureSite,
                              callback: function($$v) {
                                _vm.selectedMeasureSite = $$v
                              },
                              expression: "selectedMeasureSite"
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value: _vm.track + "::AddMeasureSite",
                                  expression: "track + '::AddMeasureSite'"
                                }
                              ],
                              staticStyle: {
                                "max-height": "94px",
                                "margin-left": "0px"
                              },
                              attrs: { depressed: "", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.showCreateMeasureSiteDialog()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "biz.sensors.setup.properties.addmeasuresite"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "track",
                                  rawName: "v-track",
                                  value:
                                    _vm.track + "::HelpMeasureSiteNotFound",
                                  expression:
                                    "track + '::HelpMeasureSiteNotFound'"
                                }
                              ],
                              staticStyle: { "margin-left": "0px" },
                              attrs: {
                                depressed: "",
                                color: "secondary",
                                outline: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showMeasureSiteNotFoundDialog()
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "pr-1",
                                  attrs: { color: "secondary" }
                                },
                                [_vm._v("help_outline")]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "biz.sensors.setup.properties.notfoundmeasuresite"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("SensorError", {
                attrs: {
                  sensorId: _vm.sensor.sensorId,
                  gatewaySerialNumber: _vm.gateway.serialNumber,
                  dialogShown: _vm.errorShown,
                  errorType: _vm.errorType
                },
                on: {
                  evtAbort: function($event) {
                    return _vm.onAbort()
                  },
                  evtRetry: function($event) {
                    return _vm.onRetry()
                  }
                }
              }),
              _c("OkCancelDialog", {
                attrs: {
                  dialogShown: _vm.cancelDialogShown,
                  title: _vm.$t("biz.setup.abort.title"),
                  message: _vm.$t("biz.setup.abort.description"),
                  track: _vm.track + "::AbortSetup"
                },
                on: {
                  evtOkPressed: function($event) {
                    return _vm.onAbort()
                  },
                  evtCancelPressed: function($event) {
                    _vm.cancelDialogShown = false
                  }
                }
              }),
              _c("CreateMeasureSite", {
                attrs: {
                  dialogShown: _vm.createMeasureSite,
                  track: _vm.track + "::CreateMeasureSite"
                },
                on: {
                  evtCancel: function($event) {
                    _vm.createMeasureSite = false
                  },
                  evtMeasureSiteCreated: _vm.measureSiteCreated
                }
              })
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }