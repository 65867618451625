var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700px", "min-width": "600px", persistent: "" },
      model: {
        value: _vm.showWizard,
        callback: function($$v) {
          _vm.showWizard = $$v
        },
        expression: "showWizard"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "max-width": "700px", "min-width": "600px" } },
        [
          _c("BaseWindowItem", {
            attrs: { trackCancel: "AlarmProfileCreationWizard::CloseWizard" },
            on: {
              evtCancel: function($event) {
                return _vm.closeWizard()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c("div", { staticClass: "mb-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "biz.sensors.setup.measuresite.finishsetup.alarmsetting"
                          )
                        )
                      )
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "description",
                fn: function() {
                  return undefined
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "v-window",
                      {
                        model: {
                          value: _vm.step,
                          callback: function($$v) {
                            _vm.step = $$v
                          },
                          expression: "step"
                        }
                      },
                      [
                        _c(
                          "v-window-item",
                          { attrs: { value: 1 } },
                          [
                            _c("EditCloudAlarms", {
                              attrs: {
                                track: _vm.track + "::Edit",
                                cloudAlarms: _vm.initialCloudAlarms
                              },
                              on: {
                                evtWizardCloudAlarm:
                                  _vm.collectEditedCloudAlarms
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-window-item",
                          { attrs: { value: 2 } },
                          [
                            _c("EditUnavailability", {
                              attrs: {
                                track: _vm.track + "::Edit",
                                editableData: _vm.initialSystemData
                              },
                              on: {
                                evtWizardUnavailability:
                                  _vm.collectEditedSystemData
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "biz.dashboard.alarms.smartmodulesystemalarm"
                                          )
                                        )
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "description",
                                  fn: function() {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "biz.dashboard.alarms.smartmodulesystemalarmtext"
                                            )
                                          )
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("BackNextMenu", {
            attrs: {
              stepCount: 2,
              step: _vm.step,
              previousDisabled: _vm.step === 1
            },
            on: {
              "update:step": function($event) {
                _vm.step = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "finalStep",
                fn: function() {
                  return [
                    _vm.step === 2
                      ? _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "track",
                                rawName: "v-track",
                                value:
                                  _vm.track + "::createSmartModuleAlarmProfile",
                                expression:
                                  "track + '::createSmartModuleAlarmProfile'"
                              }
                            ],
                            attrs: { depressed: "", color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.createSmartModuleAlarmProfile()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("general.create")))]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }