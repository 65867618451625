var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "30px",
        height: "30px",
        version: "1.1",
        viewBox: "0 0 30 30",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: "matrix(1.937609, 0, 0, 1.937609, -8.38791, 2.397791)"
          }
        },
        [
          _c("path", {
            staticStyle: { fill: "var(--v-secondary-base)" },
            attrs: {
              "fill-rule": "evenodd",
              stroke: "none",
              "stroke-width": "1",
              d:
                "M 12 6.504 C 13.528 6.504 14.91 7.127 15.906 8.134 L 14.93 9.1 C 14.183 8.345 13.146 7.878 12 7.878 C 10.881 7.878 9.866 8.324 9.123 9.047 L 8.165 8.064 C 9.155 7.099 10.508 6.504 12 6.504 Z M 12 9.252 C 12.764 9.252 13.455 9.564 13.953 10.067 L 12 12 L 10.082 10.032 C 10.577 9.549 11.254 9.252 12 9.252 Z"
            }
          }),
          _c("path", {
            staticStyle: { fill: "var(--v-secondary-base)" },
            attrs: {
              "fill-rule": "nonzero",
              stroke: "none",
              "stroke-width": "1",
              d:
                "M 12 3.756 C 14.292 3.756 16.365 4.691 17.859 6.2 L 16.882 7.167 C 15.637 5.909 13.91 5.13 12 5.13 C 10.135 5.13 8.444 5.873 7.206 7.079 L 6.247 6.095 C 7.732 4.648 9.762 3.756 12 3.756 Z M 12 1.008 C 15.056 1.008 17.82 2.255 19.812 4.267 L 18.835 5.234 C 17.092 3.473 14.674 2.382 12 2.382 C 9.389 2.382 7.021 3.422 5.288 5.111 L 4.329 4.127 C 6.31 2.197 9.016 1.008 12 1.008 Z",
              opacity: "0.1"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }